import { t } from 'utils/translate';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import { insertIf } from 'utils/insertIf';

export default class TimeNavigationUI extends UIStore {
  @computed get items() {
    return {
      id: 'time',
      icon: 'timesheet',
      label: t('Time'),
      link: this.authorization.canReviewAndApproveTimeCards
        ? '/timecards'
        : '/my-time/summary',
      subnav: [
        ...insertIf(this.authorization.canReviewAndApproveTimeCards, [
          {
            id: 'timecards',
            label: t('Time cards'),
            icon: 'time-card',
            link: '/timecards'
          }
        ]),
        ...insertIf(this.authorization.canViewMyTime, [
          {
            id: 'my-time',
            label: t('My time'),
            icon: 'user',
            links: [
              {
                label: t('Summary'),
                path: {
                  pathname: `/my-time/summary`
                }
              },
              {
                label: t('Entries'),
                path: {
                  pathname: `/my-time/entries`
                }
              }
            ]
          }
        ]),
        ...insertIf(this.authorization.hasTimeClockAccess, [
          {
            id: 'review',
            label: t('Review'),
            icon: 'checklists',
            links: [
              {
                label: t('Photo ID'),
                path: {
                  pathname: `/review/photo-id`
                }
              },
              {
                label: t('Clock questions'),
                path: {
                  pathname: `/review/time-clock-questions`
                }
              }
            ]
          }
        ])
      ]
    };
  }
}

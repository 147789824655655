import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const workerMapFiltersFormPlugins = extendedPlugins;

const workerMapFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const workerMapFiltersFormFields = [
  'classificationFilters[]',
  'roleFilters[]',
  'certificationFilters[]',
  'shiftsFilters[]',
  'groupFilters[]'
];

const workerMapFiltersFormRules = {
  classificationFilters: 'array',
  roleFilters: 'array',
  certificationFilters: 'array',
  shiftFilters: 'array',
  groupFilters: 'array'
};

class WorkerMapFiltersForm extends MobxReactForm {}

export {
  WorkerMapFiltersForm,
  workerMapFiltersFormRules,
  workerMapFiltersFormFields,
  workerMapFiltersFormOptions,
  workerMapFiltersFormPlugins
};

import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';

import Industries from 'stores/collections/company/Industries';
import SubIndustries from 'stores/collections/company/SubIndustries';
import EmployeeRanges from 'stores/collections/company/EmployeeRanges';

import parseGeolocation from 'utils/parseGeolocation';
import geocodeAddress from 'utils/geocodeAddress';

import CompanyInfoForm from 'forms/companyInfo';
import isDataURL from 'utils/isDataURL';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import searchedPlaceReaction from 'utils/searchedPlaceReaction';
import countryReaction from 'utils/countryReaction';

export default class CompanyInfoUI extends UIStore {
  @observable companyInfoForm;
  @observable searchedPlace;

  constructor(options) {
    super(options);
    this.companyInfoForm = null;
    this.searchedPlace = null;

    this.industries = new Industries(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.subIndustries = new SubIndustries(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.employeeRanges = new EmployeeRanges(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    await this.fetchOptions();
    this.setupForm();
    this.blockHistoryIfFormChanged();
    this.setupReactions();
  }

  @action.bound async fetchOptions() {
    await Promise.all([
      this.industries.fetch(),
      this.subIndustries.fetch(),
      this.employeeRanges.fetch()
    ]);
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.form = null;
    this.searchedPlace = null;
    this.companyInfoForm = null;
    this.industries.clear();
    this.subIndustries.clear();
    this.employeeRanges.clear();
  }

  @action.bound resetForm() {
    this.searchedPlace = null;
    this.companyInfoForm = null;
    this.setupForm();
    this.setupReactions();
  }

  @action.bound
  setupForm() {
    this.companyInfoForm = new CompanyInfoForm(
      {
        fields: CompanyInfoForm.fields,
        rules: CompanyInfoForm.rules,
        labels: CompanyInfoForm.labels,
        values: this.company.informationFormValues,
        defaults: this.company.informationFormValues
      },
      {
        options: CompanyInfoForm.options,
        plugins: CompanyInfoForm.plugins
      }
    );
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.companyInfoForm && this.companyInfoForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound
  saveForm(e) {
    e.preventDefault();
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditCompanyInfo')
      .then(() => {
        this.companyInfoForm.submit({
          onSuccess: this.submitCompanyInfoSuccess,
          onError: e => {
            console.error(this.companyInfoForm.errors());
          }
        });
      });
  }

  @action.bound
  async submitCompanyInfoSuccess() {
    this.clearValidationDetails();

    const formValues = this.companyInfoValues;

    // Attempt to geocode address
    const geocodedAddress = await geocodeAddress(
      Object.assign({}, formValues.address, {
        country: `${formValues.address.country}`
      })
    );

    if (geocodedAddress) {
      formValues.address.geolocation = geocodedAddress.location;
      formValues.address.postCode =
        formValues.address.postCode || geocodedAddress.postCode;
    }

    try {
      await this.company.save(formValues, {
        wait: true
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Company info saved')
      });

      this.clearSearchedPlace();
      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed
  get companyInfoValues() {
    const form = this.companyInfoForm.values();

    const formValues = {
      name: form.name,
      phone: form.phone,
      address: form.address,
      employeeRange: form.employeeRange,
      industry: form.industry,
      subIndustries: form.subIndustries,
      preferences: {
        languageId: parseInt(form.language),
        primaryColor: form.primaryColor
      },
      email: form.email,
      ccEmails: form.ccEmails
    };

    if (isDataURL(form.companyLogo)) {
      formValues.logo = {
        base64: form.companyLogo
      };
    }

    return formValues;
  }

  @action.bound
  setSearchedPlace(place) {
    this.searchedPlace = parseGeolocation(place);
  }

  @action.bound
  clearSearchedPlace() {
    this.searchedPlace = null;
  }

  @action.bound
  setupReactions() {
    this.reactToSearchedPlace = searchedPlaceReaction(
      this,
      this.companyInfoForm
    );

    this.reactToCountry = countryReaction(this, this.companyInfoForm);
  }

  @action.bound tearDownReactions() {
    this.reactToSearchedPlace && this.reactToSearchedPlace();
    this.reactToCountry && this.reactToCountry();
  }

  @computed get languageOptions() {
    return [
      { id: 1, code: 'en-US', name: 'English' },
      { id: 2, code: 'es-MX', name: 'Español' },
      { id: 3, code: 'pt-BR', name: 'Português' }
    ];
  }

  @computed
  get disableResetButton() {
    return this.company.saving;
  }

  @computed
  get disableSaveButton() {
    return !this.authorization.canEditCompanyInfo || this.company.saving;
  }

  @computed get stateOptions() {
    const value = this.companyInfoForm.$('address.state').value;

    if (!value || this.regions.isValidUsState(value)) {
      return this.regions.asOptions;
    }

    return [{ name: t('Unrecognized: ') + value, value: value }].concat(
      this.regions.asOptions
    );
  }

  @computed get selectedState() {
    return this.stateOptions.find(state => {
      return state.value === this.companyInfoForm.$('address.state').value;
    });
  }
}

import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import Equipment from 'stores/collections/Equipment';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

import EquipmentAddNewUI from './EquipmentAddNewUI';

import request from 'axios';
import moment from 'moment-timezone';
import { t } from 'utils/translate';

import debounce from 'lodash.debounce';
import uniqBy from 'lodash.uniqby';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  EquipmentFiltersForm,
  equipmentFiltersFormRules,
  equipmentFiltersFormFields,
  equipmentFiltersFormOptions,
  equipmentFiltersFormPlugins
} from 'forms/equipment/equipmentFilters';

import EquipmentTypes from 'stores/collections/EquipmentTypes';
import EquipmentMakes from 'stores/collections/EquipmentMakes';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_DEPLOYMENT_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentAddUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable searchQuery;

  @observable equipmentFiltersForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.page = 1;
    this.pageSize = 20;
    this.searchQuery = '';

    // Check boxes
    this.selectedEquipment = observable([]);

    // Equipment collection
    this.equipment = new Equipment(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentAddNewUI = new EquipmentAddNewUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Filters
    this.equipmentFiltersForm = null;

    this.typeFilters = observable([]);
    this.makeFilters = observable([]);
    this.deployedToFilters = observable([]);
    this.conditionFilters = observable([]);

    this.fetchEquipmentDebounced = debounce(this.fetchEquipment, BASE_DEBOUNCE);

    this.equipmentTypes = new EquipmentTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentMakes = new EquipmentMakes(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEquipment();

    this.equipmentTypes.fetch({
      params: {
        limit: 10000 // Safe number
      }
    });

    this.equipmentMakes.fetch({
      params: {
        limit: 10000 // Safe number
      }
    });
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchEquipmentDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      types: this.typeFilters.map(typeFilter => typeFilter.value),
      makes: this.makeFilters.map(makeFilter => makeFilter.value),
      conditions: this.conditionFilters.length
        ? this.conditionFilters.map(conditionFilter => conditionFilter.value)
        : ['AVAILABLE', 'DEPLOYED', 'UNDER_MAINTENANCE'],
      deployedTo: this.deployedToFilters.map(
        deployedToFilter => deployedToFilter.value
      )
    };
  }

  @action.bound async fetchEquipment() {
    this.equipment.cancelRequest();
    this.equipment.reset();

    try {
      await this.equipment.fetchWithPost({
        url: `${this.equipment.url()}/search`,
        params: this.params
      });

      this.parent.refetchEquipmentDeployments();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipment() {
    return this.equipment.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipment.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.selectedEquipment.clear();

    this.searchQuery = '';
    this.equipment.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasEquipment &&
      !this.hasAppliedFilters
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasAppliedFilters) &&
      !this.hasEquipment
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound refetchEquipment() {
    this.loading = true;
    if (!this.hasEquipment) {
      this.setPage(null, 1);
      this.fetchEquipment();
    } else {
      this.fetchEquipment();
    }
  }

  @action.bound
  cancelEquipmentAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment`,
      search: this.baseQueryParams
    });
  }

  // Check boxes
  @computed get hasSelectedEquipment() {
    return this.selectedEquipment.length > 0;
  }

  @computed get hasNoSelectedEquipment() {
    return this.selectedEquipment.length === 0;
  }

  @computed get allEquipmentSelected() {
    if (!this.hasSelectedEquipment) return false;

    return (
      this.equipment.models.filter(equipment => {
        return this.findSelectedEquipment(equipment);
      }).length === this.equipment.length
    );
  }

  findSelectedEquipment = equipment => {
    return this.selectedEquipment.find(
      selectedEquipment => selectedEquipment.uuid === equipment.uuid
    );
  };

  @action.bound toggleSelectAllEquipment() {
    if (this.allEquipmentSelected) {
      this.equipment.models.forEach(equipment => {
        this.selectedEquipment.remove(this.findSelectedEquipment(equipment));
      });
    } else {
      this.equipment.models.forEach(equipment => {
        if (!this.findSelectedEquipment(equipment)) {
          this.selectedEquipment.push(equipment);
        }
      });
    }
  }

  @action.bound toggleSelectEquipment(equipment) {
    const selectedEquipment = this.findSelectedEquipment(equipment);

    if (selectedEquipment) {
      this.selectedEquipment.remove(selectedEquipment);
    } else {
      this.selectedEquipment.push(equipment);
    }
  }

  @action.bound addNewEquipment() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/add-new`,
      search: this.baseQueryParams
    });
  }

  @action.bound async saveEquipment() {
    this.saving = true;
    const payload = this.selectedEquipment.map(equipment => {
      return {
        projectUuid: this.project.uuid,
        equipment: { uuid: equipment.uuid },
        startDate: moment().format('YYYY-MM-DD')
      };
    });
    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/equipment/deployments/batch`,
        payload
      );

      this.selectedEquipment.forEach(equipment => {
        callTrack(EQUIPMENT_DEPLOYMENT_ADDED, {
          equipment_type: equipment.type,
          ownership: equipment.owned
        });

        return;
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment deployments created')
      });

      this.cancelEquipmentAdd();
      this.parent.refetchEquipmentDeployments();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound showFilters() {
    this.showModal('EquipmentFiltersDialog');
    this.setupEquipmentFiltersForm();
  }

  @action.bound async hideEquipmentFiltersDialog() {
    await this.hideActiveModal();

    this.equipmentFiltersForm = null;
    this.projectSelectorUI.tearDown();
  }

  @action.bound clearEquipmentFilters() {
    this.hideEquipmentFiltersDialog();
    this.typeFilters.clear();
    this.makeFilters.clear();
    this.deployedToFilters.clear();
    this.conditionFilters.clear();
  }

  @action.bound setupEquipmentFiltersForm() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.equipmentFiltersForm = new EquipmentFiltersForm(
      {
        fields: equipmentFiltersFormFields,
        rules: equipmentFiltersFormRules,
        values: {
          typeFilters: this.typeFilters.slice(),
          makeFilters: this.makeFilters.slice(),
          deployedToFilters: this.deployedToFilters.slice(),
          conditionFilters: this.conditionFilters.slice()
        }
      },
      {
        options: equipmentFiltersFormOptions,
        plugins: equipmentFiltersFormPlugins
      }
    );
  }

  @action.bound submitEquipmentFiltersForm(event) {
    event.preventDefault();

    this.equipmentFiltersForm.submit({
      onSuccess: this.submitEquipmentFiltersFormSuccess,
      onError: this.submitEquipmentFiltersFormError
    });
  }

  @action.bound submitEquipmentFiltersFormSuccess() {
    const values = this.equipmentFiltersForm.values();

    this.typeFilters.replace(values.typeFilters);
    this.makeFilters.replace(values.makeFilters);
    this.deployedToFilters.replace(values.deployedToFilters);
    this.conditionFilters.replace(values.conditionFilters);

    this.hideEquipmentFiltersDialog();
  }

  @action.bound submitEquipmentFiltersFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.typeFilters.length) {
      counter += 1;
    }

    if (this.makeFilters.length) {
      counter += 1;
    }

    if (this.deployedToFilters.length) {
      counter += 1;
    }

    if (this.conditionFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed
  get equipmentTypeOptions() {
    const typesList = this.equipmentTypes.models.map(type => {
      return {
        value: type.name,
        title: type.name,
        category: type.category
      };
    });

    return uniqBy(typesList, 'value');
  }

  @computed
  get equipmentMakeOptions() {
    return this.equipmentMakes.models.map(make => {
      return {
        value: make.make,
        title: make.make
      };
    });
  }

  @computed get conditionOptions() {
    return [
      { value: 'AVAILABLE', title: t('Available') },
      { value: 'DEPLOYED', title: t('Deployed') }
    ];
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentProjectsFormPlugins = extendedPlugins;

const smallEquipmentProjectsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentProjectsFormFields = [
  'projects[].projectUuid',
  'projects[].projectName',
  'projects[].totalUnitsPercentString',
  'projects[].projectDeployedUnits'
];

const smallEquipmentProjectsFormLabels = {
  'projects[].projectDeployedUnits': t('units deployed')
};

const smallEquipmentProjectsFormValues = {
  'projects[].projectName': '',
  'projects[].totalUnitsPercentString': '',
  'projects[].projectDeployedUnits': ''
};

const smallEquipmentProjectsFormRules = {
  'projects[].projectDeployedUnits': 'numeric|min:0'
};

class SmallEquipmentProjectsForm extends MobxReactForm {}

export {
  SmallEquipmentProjectsForm,
  smallEquipmentProjectsFormOptions,
  smallEquipmentProjectsFormFields,
  smallEquipmentProjectsFormRules,
  smallEquipmentProjectsFormValues,
  smallEquipmentProjectsFormLabels,
  smallEquipmentProjectsFormPlugins
};

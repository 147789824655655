import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentProjectsFiltersFormPlugins = extendedPlugins;

const smallEquipmentProjectsFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentProjectsFiltersFormFields = ['groups'];

const smallEquipmentProjectsFiltersFormLabels = {
  groups: t('groups')
};

const smallEquipmentProjectsFiltersFormValues = {
  groups: []
};

const smallEquipmentProjectsFiltersFormRules = {
  groups: ''
};

class SmallEquipmentProjectsFiltersForm extends MobxReactForm {}

export {
  SmallEquipmentProjectsFiltersForm,
  smallEquipmentProjectsFiltersFormOptions,
  smallEquipmentProjectsFiltersFormFields,
  smallEquipmentProjectsFiltersFormRules,
  smallEquipmentProjectsFiltersFormValues,
  smallEquipmentProjectsFiltersFormLabels,
  smallEquipmentProjectsFiltersFormPlugins
};

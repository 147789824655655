import { RakenCollection } from '../lib';
import SmallEquipmentSnapshotReport from '../models/SmallEquipmentSnapshotReport';

export default class SmallEquipmentSnapshotReports extends RakenCollection {
  model() {
    return SmallEquipmentSnapshotReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/reports/scheduled`;
  }
}

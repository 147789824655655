import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const projectTemplateTimeCaptureFormPlugins = extendedPlugins;

const projectTemplateTimeCaptureFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const projectTemplateTimeCaptureFormFields = [
  'enableKiosk',
  'enableTimeClock',
  'enableTaskSwitching',
  'enableCrewGrouping',
  'enableSegments',
  'timeCardsStartAndEndTime',
  'requirePhotoId',
  'requirePhotoIdOnClockInOutAndBreaks',
  'clockInChecklistTemplateUuid',
  'clockOutChecklistTemplateUuid'
];

const projectTemplateTimeCaptureFormLabels = {
  projectRadius: t('project radius')
};

const projectTemplateTimeCaptureFormRules = {
  enableKiosk: 'boolean',
  enableTimeClock: 'boolean',
  enableTaskSwitching: 'boolean',
  enableCrewGrouping: 'boolean',
  enableSegments: 'boolean',
  timeCardsStartAndEndTime: 'boolean',
  requirePhotoId: 'boolean',
  requirePhotoIdOnClockInOutAndBreaks:
    'string|required|in:NOT_REQUIRED,REQUIRED_ON_CLOCK_IN_AND_OUT,REQUIRED_ON_CLOCK_IN_AND_OUT_AND_BREAKS',
  clockInChecklistTemplateUuid: 'string',
  clockOutChecklistTemplateUuid: 'string'
};

const projectTemplateTimeCaptureFormValues = {
  projectRadius: '',
  requirePhotoIdOnClockInOutAndBreaks: 'NOT_REQUIRED'
};

class ProjectTemplateTimeCaptureForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ProjectTemplateTimeCaptureForm,
  projectTemplateTimeCaptureFormOptions,
  projectTemplateTimeCaptureFormFields,
  projectTemplateTimeCaptureFormRules,
  projectTemplateTimeCaptureFormValues,
  projectTemplateTimeCaptureFormPlugins,
  projectTemplateTimeCaptureFormLabels
};

import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';

import { action, computed, observable, reaction, runInAction } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import PayTypes from 'stores/collections/PayTypes';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class IntegrationQBDPayTypeMappingUI extends UIStore {
  @observable loading;

  @observable sortField;
  @observable sortDirection;

  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = true;

    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.paginatedPayTypes = new PayTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchPayTypesDebounced = debounce(this.fetchPayTypes, BASE_DEBOUNCE);
  }

  @computed get payTypes() {
    return this.paginatedPayTypes;
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @computed get payTypesUrl() {
    if (this.isSuperAdmin) {
      return `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/companies/${this.company.uuid}/paytypes`;
    }

    return this.payTypes.url();
  }

  @action.bound setup() {
    this.fetchPayTypes();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortName: this.sortField,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      externalId: '*'
    };
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchPayTypesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchPayTypes() {
    this.payTypes.cancelRequest();
    this.payTypes.clear();

    await this.payTypes.fetch({
      url: this.payTypesUrl,
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @action.bound
  clearUIState() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.payTypes.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'name';
    this.sortDirection = 'asc';
  }

  @computed get hasPayTypes() {
    return this.payTypes.hasModels;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.payTypes.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  setSearchQuery(query) {
    this.page = 1;
    this.searchQuery = query;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.hasPayTypes;
  }
}

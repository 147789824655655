import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import ComputerEaseDirectAPIJob from 'stores/models/integrations/ComputerEaseDirectAPIJob';

export default class ComputerEaseDirectAPIJobs extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1030/job`;
  }

  model() {
    return ComputerEaseDirectAPIJob;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}

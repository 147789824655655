import { observer } from 'mobx-react';
import React from 'react';

import { Form } from 'raken-ui';

import { Button, TextField } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const AppConnectForm = observer(({ uiStore }) => {
  const {
    company,
    user,
    password
  } = uiStore.activeIntegration.connectPlaceholders;

  return (
    <Form data-qa="form_app-connect" onSubmit={uiStore.submitActiveForm}>
      <TextField
        placeholder={company}
        dataQA="companyName"
        value={uiStore.activeForm.$('companyName').value}
        onChange={uiStore.activeForm.$('companyName').sync}
        error={uiStore.activeForm.$('companyName').error}
        helperText={uiStore.activeForm.$('companyName').error}
        fullWidth
        autoFocus
      />

      <TextField
        placeholder={user}
        dataQA="username"
        value={uiStore.activeForm.$('username').value}
        onChange={uiStore.activeForm.$('username').sync}
        error={uiStore.activeForm.$('username').error}
        helperText={uiStore.activeForm.$('username').error}
        fullWidth
      />

      <TextField
        placeholder={password}
        dataQA="password"
        value={uiStore.activeForm.$('password').value}
        onChange={uiStore.activeForm.$('password').sync}
        error={uiStore.activeForm.$('password').error}
        helperText={uiStore.activeForm.$('password').error}
        type="password"
        fullWidth
      />

      <Button disabled={uiStore.saving} type="submit" fullWidth dataQA="submit">
        {uiStore.saving ? t('Signing in') : t('Sign in')}
      </Button>
    </Form>
  );
});

export default AppConnectForm;

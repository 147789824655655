import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import {
  SettingsTrackTimeForm,
  settingsTrackTimeFormOptions,
  settingsTrackTimeFormFields,
  settingsTrackTimeFormRules,
  settingsTrackTimeFormValues,
  settingsTrackTimeFormPlugins,
  settingsTrackTimeFormLabels,
  settingsTrackTimeFormDisabled
} from 'forms/project/settingsTrackTime';
import alertErrorHandler from 'utils/alertErrorHandler';
import errorHandler from 'utils/errorHandler';
import { t } from 'utils/translate';
import {
  PROJECT_UPDATED,
  TRACK_TIME_ADDED,
  OVERTIMERULES_PROJECTSETTINGSUPDATE
} from 'utils/segmentAnalytics/eventSpec';

export default class SettingsTrackTimeUI extends SettingsChildUI {
  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsTrackTimeFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsTrackTimeForm(
      {
        fields: settingsTrackTimeFormFields,
        rules: settingsTrackTimeFormRules,
        disabled: settingsTrackTimeFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsTrackTimeFormLabels
      },
      {
        options: settingsTrackTimeFormOptions,
        plugins: settingsTrackTimeFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    if (!values.timeCardsStartAndEndTime) {
      values.enableKiosk = false;
      values.enableTimeClock = false;
    }
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(projectData, {
        wait: true
      });
      this.handleSaveSuccess();

      if (this.project.timeCardsStartAndEndTime) {
        callTrack(TRACK_TIME_ADDED, {
          company_level: false
        });
      }
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Track time settings saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    this.navigationUI.projectSelectorUI.resetProjectOptions();
    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @action.bound
  async fetchOverTimeRules() {
    try {
      await this.overTimeRules.fetch({
        params: {
          limit: 10000
        },
        add: true,
        remove: false,
        update: true
      });

      this.rootStore.companyOverTimeRulesFetched = true;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  updateOverTimeRuleType(option) {
    if (option?.id) {
      // Handle autocomplete keyboard delete button where no option is set but update still runs.
      this.form.$('overtimeRuleSetUuid').set(option.id);
    }
    callTrack(OVERTIMERULES_PROJECTSETTINGSUPDATE);
    return;
  }

  @action.bound
  updateCostCodesOnTimeCardsOption(value) {
    this.form.$('costCodesOnTimeCards').set(value.id);
  }

  @computed get headerTitle() {
    return t('Manage time tracking requirements');
  }

  @computed get overTimeRulesSelectValue() {
    const searchModels = this.overTimeRules.models.find(
      model => model.uuid === this.form?.$('overtimeRuleSetUuid').value
    );

    if (searchModels) {
      return searchModels;
    }

    return this.overTimeRules.models.find(model => model.default);
  }

  @computed get overTimeRulesValueHasDisabledPayTypes() {
    return this.overTimeRulesSelectValue?.rules?.some(rule =>
      rule.payType.hiddenProjects.find(
        hiddenProject => hiddenProject.uuid === this.project.uuid
      )
    );
  }

  @computed get disableSaveButton() {
    return this.overTimeRulesValueHasDisabledPayTypes;
  }

  @computed get overTimeRulesSelectValueDefault() {
    return this.overTimeRulesSelectValue?.rules.find(rule => rule.default);
  }

  @computed get hasOverTimeRulesWriteAccess() {
    return this.authorization.canEditOverTimeRules;
  }

  @computed get overTimeRules() {
    return this.rootStore.overTimeRules;
  }

  @computed get formattedRuleName() {
    return this.rootStore.overTimeRulesUI.overTimeRulesType.find(
      type => type.id === this.overTimeRulesSelectValue?.type
    )?.title;
  }

  @computed get hasClassificationsWriteAccess() {
    return this.authorization.canCUDClassifications;
  }

  @computed get classificationsOnTimeCardsOptions() {
    return [
      {
        value: 'NOT_REQUIRED',
        name: t('Classifications not required on time cards')
      },
      {
        value: 'REQUIRED_ON_ONE',
        name: t('Classifications required on one or more time entries')
      },
      {
        value: 'REQUIRED_ON_ALL',
        name: t('Classifications required on all time entries')
      }
    ];
  }
  @computed get classificationsEditableOnTimeCardsOptions() {
    return [
      {
        value: true,
        name: t('Allow editing of classifications on time cards')
      },
      {
        value: false,
        name: t("Don't allow editing of classifications on time cards")
      }
    ];
  }

  @computed get selectedClassificationsOnTimeCardsOption() {
    return this.classificationsOnTimeCardsOptions.find(
      option => option.value === this.form.$('classificationsOnTimeCards').value
    );
  }

  @computed get selectedClassificationsEditableOnTimeCardsOption() {
    return this.classificationsEditableOnTimeCardsOptions.find(
      option =>
        option.value === this.form.$('classificationsEditableOnTimeCards').value
    );
  }

  @computed
  get costCodesOnTimeCardsOptions() {
    return [
      { title: t('Cost code not required'), id: 'NOT_REQUIRED' },
      {
        title: t('Cost code required on one or more time entries'),
        id: 'REQUIRED_ON_ONE'
      },
      {
        title: t('Cost codes required on all time entries'),
        id: 'REQUIRED_ON_ALL'
      }
    ];
  }

  @computed get selectedCostCodesOnTimeCardsOption() {
    return this.costCodesOnTimeCardsOptions.find(
      option => option.id === this.form.$('costCodesOnTimeCards').value
    );
  }

  @computed
  get breaksRequirementOptions() {
    return [
      {
        title: t('Require start & end time'),
        id: 'REQUIRE_START_AND_END_TIME'
      },
      {
        title: t('Require start time & duration'),
        id: 'REQUIRE_START_TIME_AND_DURATION'
      },
      {
        title: t('Require duration only'),
        id: 'REQUIRE_DURATION_ONLY'
      }
    ];
  }

  @computed get breaksRequirementValue() {
    return this.breaksRequirementOptions.find(
      option => option.id === this.form.$('breakSetting').value
    );
  }

  @computed get hasBreaksWriteAccess() {
    return this.authorization.canEditBreaks;
  }

  @computed get hasCostCodesWriteAccess() {
    return this.authorization.canCUDCostCodes;
  }

  @computed get hasTrackTimeWriteAccess() {
    return this.authorization.canEditTrackTime;
  }

  @computed get hasWriteAccess() {
    return (
      this.hasTrackTimeWriteAccess ||
      this.hasClassificationsWriteAccess ||
      this.hasOverTimeRulesWriteAccess ||
      this.hasCostCodesWriteAccess
    );
  }

  @computed get title() {
    return t('Track time settings');
  }

  @computed get manualEntryTip() {
    return {
      url: `${this.assetsURL}/images/company-settings/example-of-manual-entry.png`
    };
  }

  @computed get startAndEndTimeTip() {
    return {
      url: `${this.assetsURL}/images/company-settings/example-of-start-and-end-time.png`
    };
  }

  @computed get showKioskTimeClockOnWarningText() {
    return (
      !this.form.$('timeCardsStartAndEndTime').value &&
      (this.project.settingsFormValues.enableTimeClock ||
        this.project.settingsFormValues.enableKiosk)
    );
  }

  @computed get kioskTimeClockOnWarningText() {
    if (
      this.project.settingsFormValues.enableTimeClock &&
      this.project.settingsFormValues.enableKiosk
    ) {
      return t(
        'Kiosk and Time Clock are currently enabled for this project. Changing to calculate hours via manual entry will disable the Kiosk and Time Clock features.'
      );
    }

    if (this.project.settingsFormValues.enableTimeClock) {
      return t(
        'Time Clock is currently enabled for this project. Changing to calculate hours via manual entry will disable the Time Clock feature'
      );
    }

    if (this.project.settingsFormValues.enableKiosk) {
      return t(
        'Kiosk is currently enabled for this project. Changing to calculate hours via manual entry will disable the Kiosk feature.'
      );
    }
    return '';
  }

  @computed get overTimeRulesValueHasDisabledPayTypesWarningText() {
    if (this.overTimeRulesValueHasDisabledPayTypes) {
      return t(
        'The overtime rule selected contains pay types that are disabled. Enable these pay types before updating your overtime rules.'
      );
    }
    return '';
  }
}

import UIStore from './UIStore';
import SmallEquipment from 'stores/collections/equipment/SmallEquipment';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class SmallEquipmentSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.fetchSmallEquipment = debounce(
      this.fetchSmallEquipment,
      BASE_DEBOUNCE
    );

    this.smallEquipment = new SmallEquipment(null, {
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.fetchSmallEquipment();
    this.setupReactions();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchSmallEquipment();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.smallEquipment.reset();
    this.loading = false;
    this.searchQuery = '';
  }

  @computed get options() {
    return this.smallEquipment.models.map(smallEquipment => {
      return {
        uuid: smallEquipment.uuid,
        name: smallEquipment.name
      };
    });
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.smallEquipment.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreSmallEquipment() {
    return this.smallEquipment.totalElements > this.smallEquipment.length;
  }

  @computed get params() {
    return {
      query: this.searchQuery,
      limit: 80,
      sortField: 'name',
      sortDirection: 'ASC'
    };
  }

  @action.bound
  async fetchSmallEquipment(options) {
    this.loading = true;

    try {
      await this.smallEquipment.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfSmallEquipment().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfSmallEquipment() {
    if (this.smallEquipment.fetching || !this.hasMoreSmallEquipment) return;

    try {
      this.smallEquipment.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.smallEquipment.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}

import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class SmallEquipmentProject extends Model {
  get restAttributes() {
    return [
      'projectName',
      'projectUuid',
      'projectDeployedUnits',
      'totalUnitsPercent'
    ];
  }

  @computed get formValues() {
    return {
      projectName: this.projectName,
      projectUuid: this.projectUuid,
      projectDeployedUnits: this.projectDeployedUnits,
      totalUnitsPercentString: this.totalUnitsPercentString
    };
  }

  @computed get totalUnitsPercentString() {
    return `${this.totalUnitsPercent}%`;
  }
}

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import SageIntacctEmployeeMatches from 'stores/collections/integrations/SageIntacctEmployeeMatches';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

import { t } from 'utils/translate';

export default class IntegrationSageIntacctEmployeeMatchesUI extends UIStore {
  @observable pageSize;
  @observable page;
  @observable loading;

  @observable activeTab;

  constructor(options) {
    super(options);

    this.employeeMatches = new SageIntacctEmployeeMatches(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.pageSize = 10;
    this.page = 1;
    this.loading = true;

    this.activeTab = 'matches';

    this.selectedConfirmedMatches = observable([]);
    this.selectedConflictedMatches = observable([]);

    this.fetchEmployeeMatchesDebounced = debounce(
      this.fetchEmployeeMatches,
      BASE_DEBOUNCE
    );
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get hasEmployeeMatches() {
    return this.employeeMatches.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEmployeeMatches();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEmployeeMatches();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchEmployeeMatches() {
    this.loading = true;
    this.clearValidationDetails();
    try {
      await this.employeeMatches.fetch({
        params: pickBy(this.params, identity)
      });

      if (this.employeeMatches.nextOffset) {
        await this.fetchNextEmployeeMatches();
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchNextEmployeeMatches() {
    await this.employeeMatches.fetchNextPage();

    if (this.this.employeeMatches.nextOffset) {
      this.fetchNextEmployeeMatches();
    }
  }

  @computed get params() {
    return {
      limit: 1000
    };
  }

  @computed get confirmedMatches() {
    return this.employeeMatches.models.filter(
      employeeMatch => !employeeMatch.message
    );
  }

  @computed get conflictedMatches() {
    return this.employeeMatches.models.filter(
      employeeMatch => employeeMatch.message
    );
  }

  @computed
  get paginatedConfirmedMatches() {
    return this.confirmedMatches.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed
  get paginatedConflictedMatches() {
    return this.conflictedMatches.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed get hasConfirmedMatches() {
    return this.confirmedMatches.length > 0;
  }

  @computed get hasConflictedMatches() {
    return this.conflictedMatches.length > 0;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @computed
  get totalPages() {
    if (this.activeTab === 'conflicts') {
      return Math.ceil(this.conflictedMatches.length / this.pageSize);
    }

    return Math.ceil(this.confirmedMatches.length / this.pageSize);
  }

  @computed get hasSelectedConfirmedMatches() {
    return this.selectedConfirmedMatches.length > 0;
  }

  @action.bound
  toggleSelectConfirmedMatch(employeeMatch) {
    if (this.selectedConfirmedMatches.includes(employeeMatch)) {
      this.selectedConfirmedMatches.remove(employeeMatch);
    } else {
      this.selectedConfirmedMatches.push(employeeMatch);
    }
  }

  @computed get hasSelectedConflictedMatches() {
    return this.selectedConflictedMatches.length > 0;
  }

  @action.bound
  toggleSelectConflictedMatch(employeeMatch) {
    if (this.selectedConflictedMatches.includes(employeeMatch)) {
      this.selectedConflictedMatches.remove(employeeMatch);
    } else {
      this.selectedConflictedMatches.push(employeeMatch);
    }
  }

  @action.bound clearAllSelectedEmployeeMatches() {
    this.selectedConfirmedMatches.clear();
  }

  @action.bound clearAllSelectedConflictedMatches() {
    this.selectedConflictedMatches.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  getPayload = (options = { all: false }) => {
    if (this.activeTab === 'conflicts') {
      if (options.all) {
        return this.conflictedMatches.map(match => match.patchValues);
      }

      return this.selectedConflictedMatches.map(match => match.patchValues);
    } else {
      if (options.all) {
        return this.confirmedMatches.map(match => match.patchValues);
      }

      return this.selectedConfirmedMatches.map(match => match.patchValues);
    }
  };

  @action.bound async saveMatches(options = { all: false }) {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    const payload = this.getPayload(options);

    try {
      await request.patch(
        `ra/companies/${this.activeCompany.uuid}/members/externalIds`,
        payload
      );

      this.parent.sageIntacctEmployeeMappings.clear();

      await this.parent.fetchSageIntacctEmployeeMappings();

      this.cancelSageIntacctEmployeeMatches();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Employee matches confirmed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelSageIntacctEmployeeMatches() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1024/employees`
      );
    } else {
      history.push('/company/integrations/1024/employees');
    }
  }

  @action.bound setActiveTab(event, tab) {
    this.page = 1;
    this.activeTab = tab;
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.page = 1;
    this.activeTab = 'matches';
    this.selectedConfirmedMatches.clear();
    this.selectedConflictedMatches.clear();
    this.employeeMatches.clear();
    this.clearValidationDetails();
  }
}

import React from 'react';
import { observer } from 'mobx-react';

import { Avatar, Box } from '@raken/athens-web/lib';

import CompanyNavigationUserMenu from './CompanyNavigationUserMenu';

const CompanyNavigationFooter = observer(({ navigationUI }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      width="100%"
    >
      <Box onClick={navigationUI.toggleUserMenu}>
        <Avatar size={40} src={navigationUI.me.avatarImage} />
      </Box>

      <CompanyNavigationUserMenu
        uiStore={navigationUI}
        store={navigationUI.rootStore}
        location={location}
      />
    </Box>
  );
});

export default CompanyNavigationFooter;

import React from 'react';
import debounce from 'lodash.debounce';
import { Text, Link } from '@raken/athens-web/lib';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import request from 'axios';
import Checklists from 'stores/collections/checklists/Checklists';
import ProjectChildUI from 'stores/ui/project/ProjectChildUI';

import ChecklistTemplateSelectUI from './ChecklistTemplateSelectUI';
import ChecklistAddUI from './ChecklistAddUI';
import ChecklistEditUI from './ChecklistEditUI';
import ChecklistBatchExportUI from './ChecklistBatchExportUI';
import moment from 'moment-timezone';
import history from 'utils/history';
import {
  ChecklistFiltersForm,
  checklistFilterFormValues,
  checklistFiltersFormOptions,
  checklistFiltersFormFields,
  checklistFiltersFormLabels,
  checklistFiltersFormPlugins
} from 'forms/checklistFilters';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import errorHandler from 'utils/errorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class ChecklistsUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedChecklist;
  @observable appliedFiltersCount;
  @observable filterForm;
  @observable filterValues;
  @observable checklistNames;
  @observable selectedObservationsRaised;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedChecklist = null;
    this.selectedObservationsRaised = null;
    this.sortField = 'checklistDate';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.appliedFiltersCount = null;
    this.filterForm = null;
    this.filterValues = {};
    this.page = 1;
    this.pageSize = 20;
    this.status = 'DRAFT,ACTIVE,SCHEDULED';
    this.checklistNames = [];

    // Checklists collection
    this.checklists = new Checklists(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistAddUI = new ChecklistAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistEditUI = new ChecklistEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateSelectUI = new ChecklistTemplateSelectUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistBatchExportUI = new ChecklistBatchExportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchChecklistsDebounced = debounce(
      this.fetchChecklists,
      BASE_DEBOUNCE
    );
  }

  @computed
  get checklistCompletedUI() {
    return this.rootStore.companyUI?.checklistCompletedUI;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchChecklists();
    this.projectLocationSelectorUI.setup(this.project?.uuid);
    // Prevent 403 request if no permission to create new checklists
    if (this.authorization.canCUDChecklists) {
      this.checklistTemplateSelectUI.fetchChecklistTemplates('COMPANY,RAKEN', [
        'ACTIVE'
      ]);
    }
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.projectLocationSelectorUI.tearDown();
    this.clearFilters();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchChecklistsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      projectUuids: this.project?.uuid,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      status: this.status,
      excludeSignature: true,
      containsOpenObservations:
        this.filterValues.observationsStatus &&
        this.filterValues.observationsStatus !== 'CLOSED'
          ? true
          : false,
      containsClosedObservations:
        this.filterValues.observationsStatus &&
        this.filterValues.observationsStatus !== 'OPEN'
          ? true
          : false,
      names: this.filterValues.checklistNames
        ? this.filterValues.checklistNames.map(
            checklistName => checklistName.name
          )
        : [],
      locationUuids: this.filterValues.locations
        ? this.filterValues.locations.map(location => location.uuid)
        : [],
      checklistDateAfter: this.filterValues.startDate,
      checklistDateBefore: this.filterValues.endDate
    };
  }

  @computed get hasRequiredParams() {
    return this.params.projectUuids;
  }

  @action.bound async fetchChecklists() {
    if (!this.hasRequiredParams) return;

    this.checklists.cancelRequest();
    this.checklists.clear();

    try {
      await this.checklists.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasChecklists() {
    return this.checklists.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklists.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.checklists.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'checklistDate';
    this.sortDirection = 'desc';
    this.selectedChecklist = null;
    this.checklistNames = [];
    this.selectedObservationsRaised = null;
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasChecklists &&
      !this.hasFilters
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasFilters) &&
      !this.hasChecklists
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async showBatchExportModal() {
    await this.authorization.checkFeatureAccess('CUDChecklists');
    this.checklistBatchExportUI.showBatchExportModal();
  }

  @action.bound async addChecklist() {
    await this.authorization.checkFeatureAccess('CUDChecklists');

    history.push({
      pathname: `${this.project.viewUrl}/checklists/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editChecklist(checklist) {
    if (checklist.status !== 'ACTIVE') {
      await this.authorization.checkFeatureAccess('ModifyChecklists');
    }

    history.push({
      pathname: `${this.project.viewUrl}/checklists/${checklist.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteChecklist(checklist) {
    await this.authorization.checkFeatureAccess('CUDChecklists');

    if (checklist.companyUuid !== this.company.uuid) {
      this.authorization.showModal('featureAccess');
      return;
    }

    this.selectedChecklist = checklist;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteChecklist() {
    await this.hideActiveModal();

    this.selectedChecklist = null;
  }

  @action.bound async confirmDeleteChecklist() {
    this.saving = true;

    try {
      await this.selectedChecklist.destroy({ wait: true });

      this.refetchChecklists();

      await this.hideActiveModal();

      this.selectedChecklist = null;

      if (this.checklistEditUI.entryForEdit) {
        this.checklistEditUI.cancelChecklistEdit({
          skipCancelCheck: true,
          redirect: true
        });
      }

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Checklist deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.clearFilters();
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistsDebounced();
  }

  @action.bound refetchChecklists() {
    this.loading = true;
    this.clearFilters();

    if (!this.hasChecklists) {
      this.setPage(null, 1);
      this.fetchChecklists();
    } else {
      this.fetchChecklists();
    }
  }

  @action.bound async fetchChecklistNames() {
    try {
      const response = await request.get(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.company.uuid
        }/checklists/namesGroupedByType`,
        {
          params: {
            projectUuids: this.project?.uuid,
            statuses: 'DRAFT,ACTIVE,SCHEDULED'
          }
        }
      );
      this.checklistNames = response.data;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  @computed get checklistNamesOptions() {
    return this.checklistNames.collection?.slice().flatMap(item => {
      return item.checklists.map(checklist => {
        const name = checklist;
        const type = item.type;
        return {
          name,
          type
        };
      });
    });
  }

  @action.bound showObservationsRaised(observations) {
    this.showModal('observationsRaised');

    this.selectedObservationsRaised = Object.values(
      observations
        .map(observation => {
          const textColor =
            observation.category === 'POSITIVE' ? 'green' : 'red';
          return {
            questionText:
              observation.collection.parent.collection.parent.questionText,
            label: (
              <Text rakenColor={textColor}>
                <Link
                  isExternal
                  target="_blank"
                  href={`${this.project.viewUrl}/observations/${observation.uuid}/view`}
                >
                  {`${observation.label} (${observation.status.name})`}
                </Link>
              </Text>
            )
          };
        })
        .reduce((acc, obj) => {
          const { questionText, label } = obj;
          acc[questionText] = acc[questionText] || {
            questionText,
            labels: []
          };
          acc[questionText].labels.push(label);
          return acc;
        }, {})
    );
  }

  @action.bound async hideObservationsRaised() {
    await this.hideActiveModal();
    this.selectedObservationsRaised = null;
  }

  @computed get observationRaisedDialogTitle() {
    return t('Observations raised');
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }
  @action.bound async setupFiltering() {
    await this.fetchChecklistNames();
    if (this.filterForm) return;
    this.filterForm = new ChecklistFiltersForm(
      {
        fields: checklistFiltersFormFields,
        labels: checklistFiltersFormLabels,
        values: checklistFilterFormValues
      },
      {
        options: checklistFiltersFormOptions,
        plugins: checklistFiltersFormPlugins
      }
    );
  }
  @action.bound tearDownFiltering() {
    this.filterForm = null;
  }
  @action setStatus(open, closed) {
    if (open && closed) {
      return 'BOTH';
    }
    if (open) {
      return 'OPEN';
    }
    if (closed) {
      return 'CLOSED';
    }
    return undefined;
  }

  @action.bound showFilters() {
    this.showModal('filters');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound applyFilters(event) {
    event?.preventDefault();
    if (this.filterForm) {
      const { open, closed, ...values } = this.filterForm.values();

      this.filterValues = {
        ...values,
        startDate: values.startDate
          ? moment(values.startDate)
              .startOf('day')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss')
          : '',
        endDate: values.endDate
          ? moment(values.endDate)
              .endOf('day')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss')
          : '',
        observationsStatus: this.setStatus(open, closed)
      };
      this.hideActiveModal();
    }
  }

  @action.bound clearFilters(event) {
    this.filterForm?.update(checklistFilterFormValues);
    this.applyFilters(event);
  }

  @computed
  get hasFilters() {
    return this.filtersCounter > 0;
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.filterValues.observationsStatus) {
      counter++;
    }
    if (this.filterValues.checklistNames?.length > 0) {
      counter++;
    }
    if (this.filterValues.locations?.length > 0) {
      counter++;
    }
    if (this.filterValues.startDate || this.filterValues.endDate) {
      counter++;
    }
    return counter;
  }

  @computed get locationOptions() {
    return this.projectLocationSelectorUI.options;
  }

  getActions = checklist => {
    if (checklist.status === 'DRAFT' || checklist.status === 'SCHEDULED') {
      const actions = [
        {
          title: t('Resume checklist'),
          onClick: () => {
            this.editChecklist(checklist);
          }
        },
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteChecklist(checklist);
          }
        }
      ];

      return actions;
    }

    const actions = [
      {
        title: t('View'),
        onClick: () => {
          this.editChecklist(checklist);
        }
      },
      {
        title: t('Download PDF'),
        onClick: () => {
          this.checklistCompletedUI.downloadChecklistPdf(checklist);
        }
      },
      {
        title: t('Share'),
        onClick: () => {
          this.checklistCompletedUI.openShareCompletedChecklistModal(checklist);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteChecklist(checklist);
        }
      }
    ];

    return actions;
  };
}

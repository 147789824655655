import { computed, action, observable } from 'mobx';
import request from 'axios';
import UIStore from 'stores/ui/UIStore';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import download from 'utils/download';
import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

export default class DirectoryContactsActionsUI extends UIStore {
  @observable selectedContact;
  @observable selectedContactAction;
  @observable selectedContactCallback;
  @observable selectedContactRole;

  constructor(options) {
    super(options);
    this.selectedContact = null;
    this.selectedContactAction = null;
    this.selectedContactCallback = null;
    this.selectedContactRole = null;
    this.selectedContacts = observable([], { deep: false });
  }

  @computed get contacts() {
    return this.parent.contacts;
  }

  @action showContactProfile(contact) {
    history.push({
      pathname: `${contact.viewUrl}/info`,
      state: { previousPath: location.pathname }
    });
  }

  getContactActions = contact => {
    let actions;

    switch (contact.status) {
      case 'INVITED':
        actions = [
          {
            title: 'Resend invite',
            disabled: contact.lastLogin,
            onClick: () => {
              this.contactAction('RESEND_INVITE', contact);
            }
          }
        ];
        break;
      case 'INACTIVE':
        actions = [
          {
            title: t('Make active'),
            onClick: () => {
              this.contactAction('REACTIVATE', contact);
            },
            disabled: contact.isMe
          },
          {
            title: t('Delete'),
            onClick: () => {
              this.contactAction('DELETE', contact);
            },
            disabled: contact.isMe
          }
        ];
        break;
      case 'DELETED':
        actions = [
          {
            title: t('Make active'),
            onClick: () => {
              this.contactAction('REACTIVATE', contact);
            },
            disabled: contact.isMe
          }
        ];
        break;
      default:
        actions = [
          {
            title: t('Make inactive'),
            onClick: () => {
              this.contactAction('DEACTIVATE', contact);
            },
            disabled: contact.isMe
          },
          {
            title: t('Delete'),
            onClick: () => {
              this.contactAction('DELETE', contact);
            },
            disabled: contact.isMe
          }
        ];
        break;
    }

    return actions;
  };

  @computed get contactActionTitle() {
    if (!this.selectedContactAction) return null;

    switch (this.selectedContactAction) {
      case 'DELETE':
        return t(`Delete contact?`);
      case 'DEACTIVATE':
        return t(`Deactivate contact?`);
      case 'REACTIVATE':
        return t(`Reactivate contact?`);
      case 'RESEND_INVITE':
        return t(`Resend invite?`);
      default:
        return '';
    }
  }

  @computed get contactActionText() {
    if (!this.selectedContactAction) return null;

    switch (this.selectedContactAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete a contact from your account. Doing so will remove their project assignments permanently and allow you to re-use their username. Would you like to proceed?`
        );
      case 'DEACTIVATE':
        return t(
          `You are attempting to deactivate a contact. Doing so will remove their access to Raken and open their seat, but will maintain their project assignments in case they need to be reactivated. Would you like to proceed?`
        );
      case 'REACTIVATE':
        return t(
          `You are attempting to reactivate a contact. Doing so will take up a seat on your account. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for a contact. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get contactActionButtonColor() {
    switch (this.selectedContactAction) {
      case 'DELETE':
      case 'DEACTIVATE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get contactActionButtonText() {
    switch (this.selectedContactAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'DEACTIVATE':
        return t(`Deactivate`);
      case 'REACTIVATE':
        return t(`Reactivate`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      default:
        return '';
    }
  }

  @computed get contactActionButtonTextActive() {
    switch (this.selectedContactAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'DEACTIVATE':
        return t(`Deactivating...`);
      case 'REACTIVATE':
        return t(`Reactivating...`);
      case 'RESEND_INVITE':
        return t(`Resending...`);
      default:
        return '';
    }
  }

  @computed get contactActionNotificationText() {
    switch (this.selectedContactAction) {
      case 'DELETE':
        return t(`Contact deleted`);
      case 'DEACTIVATE':
        return t(`Contact deactivated`);
      case 'REACTIVATE':
        return t(`Contact reactivated`);
      case 'RESEND_INVITE':
        return t(`Invite sent`);
      default:
        return '';
    }
  }

  @action.bound async contactAction(action, contact, callBack) {
    if (action === 'RESEND_INVITE') {
      await this.authorization.checkFeatureAccess('CUDCompanyInvites');
    } else {
      await this.authorization.checkFeatureAccess('CUDCompanyMembers');
    }

    this.selectedContact = contact;
    this.selectedContactAction = action;
    this.selectedContactCallback = callBack;
    this.showModal('ContactActionModal');
  }

  @action.bound
  async cancelContactAction() {
    await this.hideActiveModal();
    this.selectedContact = null;
    this.selectedContactAction = null;
    this.selectedContactCallback = null;
  }

  @action.bound
  async confirmContactAction() {
    try {
      this.clearValidationDetails();
      this.saving = true;

      switch (this.selectedContactAction) {
        case 'DELETE':
          await this.selectedContact.save(
            {
              status: 'DELETED'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('DELETED')
          ) {
            this.selectedContact.collection.remove(this.selectedContact);
          }
          break;
        case 'DEACTIVATE':
          await this.selectedContact.save(
            {
              status: 'INACTIVE'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('INACTIVE')
          ) {
            this.selectedContact.collection.remove(this.selectedContact);
          }
          break;
        case 'REACTIVATE':
          await this.reactivateContact();
          break;
        case 'RESEND_INVITE':
          await this.resendInvite();
          break;
      }

      this.parent.loading = true;

      if (!this.parent.hasContacts) {
        this.parent.setPage(null, 1);
        this.parent.fetchContacts();
      } else {
        this.parent.fetchContacts();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.contactActionNotificationText
      });

      this.parent.refetchSubscription();

      if (this.selectedContactCallback) {
        this.selectedContactCallback();
      }

      this.selectedContact = null;
      this.selectedContactAction = null;
      this.selectedContactCallback = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async reactivateContact() {
    const showContactProjects = this.selectedContact.status === 'DELETED';

    await this.selectedContact.save(
      {
        status: 'ACTIVE',
        allowSubscriptionUpgrade: true
      },
      {
        wait: true,
        stripNonRest: false
      }
    );

    if (showContactProjects) {
      history.push(`${this.selectedContact.viewUrl}/projects`);
    }
  }

  @action.bound
  async resendInvite() {
    await request.post(`/ra/invite/${this.selectedContact.inviteId}`, {
      companyId: this.selectedContact.company.id
    });
  }

  @computed get hasSelectedContacts() {
    return this.selectedContacts.length > 0;
  }

  @computed get allContactsSelected() {
    if (!this.hasSelectedContacts) return false;

    return (
      this.contacts.models.filter(contact => {
        return this.findSelectedContact(contact);
      }).length === this.contacts.length
    );
  }

  findSelectedContact = contact => {
    return this.selectedContacts.find(
      selectedContact => selectedContact.uuid === contact.uuid
    );
  };

  @action.bound toggleSelectAllContacts() {
    if (this.allContactsSelected) {
      this.contacts.models.forEach(contact => {
        this.selectedContacts.remove(this.findSelectedContact(contact));
      });
    } else {
      this.contacts.models.forEach(contact => {
        if (!this.findSelectedContact(contact)) {
          this.selectedContacts.push(contact);
        }
      });
    }
  }

  @action.bound toggleSelectContact(contact) {
    const selectedContact = this.findSelectedContact(contact);

    if (selectedContact) {
      this.selectedContacts.remove(selectedContact);
    } else {
      this.selectedContacts.push(contact);
    }
  }

  @action.bound setSelectedContactRole(role) {
    this.selectedContactRole = role;
  }

  @action.bound
  exportContacts() {
    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/members/csv`,
      xhttpOptions: {
        sendXApiKey: true
      },
      data: {
        uuids: this.selectedContacts.map(contact => contact.uuid),
        type: 'MEMBER'
      }
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedContact = null;
    this.selectedContactAction = null;
    this.selectedContactCallback = null;
    this.selectedContactRole = null;
    this.selectedContacts.clear();
  }

  @computed get seatEstimate() {
    if (!this.subscription || this.selectedContactAction !== 'REACTIVATE')
      return null;

    return seatEstimate(this.subscription, 1, this.isRecurlyBilling);
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(this.subscription, 1, this.isRecurlyBilling);
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const memberDefaultsFormPlugins = extendedPlugins;

const memberDefaultsFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const memberDefaultsFormFields = [
  'defaultShift',
  'defaultShift.uuid',
  'defaultShift.name',
  'defaultCostCode',
  'defaultCostCode.uuid',
  'defaultCostCode.code',
  'defaultCostCode.division',
  'defaultCrewName',
  'defaultCrewName.uuid',
  'defaultCrewName.name'
];

const memberDefaultsFormRules = {};

const memberDefaultsFormLabels = {};

const memberDefaultsFormFieldOptions = {};

class MemberDefaultsForm extends MobxReactForm {}

export {
  MemberDefaultsForm,
  memberDefaultsFormOptions,
  memberDefaultsFormFields,
  memberDefaultsFormLabels,
  memberDefaultsFormRules,
  memberDefaultsFormFieldOptions,
  memberDefaultsFormPlugins
};

import { action, computed } from 'mobx';
import ProjectChildAddUI from './../ProjectChildAddUI';
import EquipmentDeployment from 'stores/models/equipment/EquipmentDeployment';

import {
  EquipmentDeploymentForm,
  equipmentDeploymentFormOptions,
  equipmentDeploymentFormFields,
  equipmentDeploymentFormRules,
  equipmentDeploymentFormValues,
  equipmentDeploymentFormLabels,
  equipmentDeploymentFormPlugins,
  equipmentDeploymentFormRelated
} from 'forms/project/equipmentDeployment';

import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

export default class EquipmentDeploymentAddUI extends ProjectChildAddUI {
  @action.bound setup() {
    this.equipmentSelectorUI.setup();
    this.costCodeSelectorUI.setup();

    this.entryForAdd = new EquipmentDeployment(
      { type: 'EquipmentDeployment' },
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new EquipmentDeploymentForm(
      {
        fields: equipmentDeploymentFormFields,
        rules: equipmentDeploymentFormRules,
        labels: equipmentDeploymentFormLabels,
        values: equipmentDeploymentFormValues,
        related: equipmentDeploymentFormRelated
      },
      {
        options: equipmentDeploymentFormOptions,
        plugins: equipmentDeploymentFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.equipmentSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryAddForm.values();

      const payload = {
        projectUuid: this.projectUuid,
        equipment: {
          uuid: values.equipment.uuid
        },
        startDate: values.startDate,
        endDate: values.endDate,
        costCode: {
          uuid: values.costCode.uuid
        }
      };

      await this.entryForAdd.save(payload, {
        wait: true,
        stripNonRest: false
      });

      this.unblockHistory();
      this.parent.sortByLastCreated();
      this.cancelEquipmentDeploymentAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment deployment created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentDeploymentAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment`,
      search: this.baseQueryParams
    });
  }

  @computed get title() {
    return t('Add equipment');
  }

  @computed get equipmentUI() {
    return this.parent.equipmentUI;
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving ||
        !this.hasWriteAccess ||
        this.entryAddForm.$('equipment.activeProject').value.uuid ===
          this.projectUuid
    );
  }

  @computed get equipmentInUseOrIdleText() {
    if (
      this.entryAddForm.$('equipment.activeProject').value.uuid ===
      this.projectUuid
    ) {
      return t(
        `The selected equipment is already in use or idle on this project.`
      );
    }

    return t(
      `The selected equipment is in use or idle on the ${
        this.entryAddForm.$('equipment.activeProject').value.name
      } project. If you continue, it will be removed from that project.`
    );
  }
}

import React from 'react';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import ChecklistSchedules from 'stores/collections/checklists/ChecklistSchedules';
import ChecklistScheduleAddUI from './ChecklistScheduleAddUI';
import ChecklistScheduleEditUI from './ChecklistScheduleEditUI';

import { Icon } from '@raken/athens-web/lib';
import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CHECKLIST_SCHEDULE_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistSchedulesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedChecklistSchedule;
  @observable projectUuid;
  @observable endDate;
  @observable projectType;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedChecklistSchedule = null;

    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;
    this.projectType = 'MY_TEAM';

    // CheclistSchedules collection
    this.checklistSchedules = new ChecklistSchedules(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistScheduleAddUI = new ChecklistScheduleAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistScheduleEditUI = new ChecklistScheduleEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchChecklistSchedulesDebounced = debounce(
      this.fetchChecklistSchedules,
      BASE_DEBOUNCE
    );
  }

  @action.bound async setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchChecklistSchedules();
    this.projectSelectorUI.setup({
      projectTypes: ['PARENT']
    });
    this.classificationSelectorUI.setup({
      responsibleSort: true
    });
    this.collaboratorSelectorUI.setup();
    await this.fetchChecklistTemplates();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.projectSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.collaboratorSelectorUI.tearDown();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchChecklistSchedulesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get checklistTemplates() {
    return this.rootStore.checklistTemplates;
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @computed
  get checklistScheduleActions() {
    return [
      {
        title: t('My team'),
        action: () => this.addChecklistSchedule('MY_TEAM')
      },
      {
        title: t('Collaborators'),
        action: () => this.addChecklistSchedule('COLLABORATOR')
      }
    ];
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('All active projects'),
        value: 'ACTIVE',
        dataQA: 'all-active-projects'
      },
      {
        label: t('Selected projects'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      },
      {
        label: t('Selected project groups'),
        value: 'SELECTED_GROUPS',
        dataQA: 'selected-project-groups'
      }
    ];
  }

  @action.bound async fetchChecklistSchedules() {
    this.checklistSchedules.cancelRequest();
    this.checklistSchedules.reset();

    try {
      await this.checklistSchedules.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchChecklistTemplates() {
    this.loading = true;

    await this.checklistTemplates.fetch({
      params: {
        scope: ['RAKEN', 'COMPANY'],
        status: ['ACTIVE'],
        limit: 10000
      }
    });
  }

  @computed get hasChecklistSchedules() {
    return this.checklistSchedules.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklistSchedules.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.checklistSchedules.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'asc';
    this.selectedChecklistSchedule = null;
    this.projectType = 'MY_TEAM';
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasChecklistSchedules;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasChecklistSchedules;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDCompanyChecklistSchedules;
  }

  @action.bound setEndDate(endDate) {
    this.endDate = endDate;
  }

  @action.bound getReminderIcon(enableReminder) {
    return enableReminder ? (
      <Icon kind="notifications-loud" rakenColor={'orange'} />
    ) : (
      <Icon kind="notifications-mute" rakenColor={'grey'} />
    );
  }

  @action.bound async addChecklistSchedule(projectType) {
    this.projectType = projectType;
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistSchedules');
    history.push({
      pathname: `/company/checklists/schedules/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editChecklistSchedule(checklistSchedule) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistSchedules');
    history.push({
      pathname: `/company/checklists/schedules/${checklistSchedule.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteChecklistSchedule(checklistSchedule) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistSchedules');

    this.selectedChecklistSchedule = checklistSchedule;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteChecklistSchedule() {
    await this.hideActiveModal();

    this.selectedChecklistSchedule = null;
  }

  @action.bound async confirmDeleteChecklistSchedule() {
    this.saving = true;

    try {
      await this.selectedChecklistSchedule.destroy({ wait: true });

      this.refetchChecklistSchedules();

      await this.hideActiveModal();

      this.selectedChecklistSchedule = null;

      callTrack(CHECKLIST_SCHEDULE_DELETED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Schedule deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistSchedulesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistSchedulesDebounced();
  }

  @action.bound refetchChecklistSchedules() {
    this.loading = true;
    if (!this.hasChecklistSchedules) {
      this.setPage(null, 1);
      this.fetchChecklistSchedules();
    } else {
      this.fetchChecklistSchedules();
    }
  }

  @action.bound setProjectUuid(id) {
    this.projectUuid = id;
  }

  getChecklistScheduleActions = checklistSchedule => {
    return [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editChecklistSchedule(checklistSchedule);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteChecklistSchedule(checklistSchedule);
        }
      }
    ];
  };
}

import {
  action,
  observable,
  computed,
  reaction,
  runInAction,
  toJS
} from 'mobx';

import UIStore from './UIStore';

import TimesheetFiltersUI from './TimesheetFiltersUI';
import TimesheetsReportUI from './TimesheetsReportUI';
import TimesheetsAddUI from './TimesheetsAddUI';
import TimesheetsEditUI from './TimesheetsEditUI';
import TimesheetSettings from '../models/TimesheetSettings';

// Accounting Integrations
import IntegrationsAccountingConfiguration from 'stores/models/integrations/IntegrationsAccountingConfiguration';

import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import moment from 'moment-timezone';
import arrayMove from 'utils/arrayMove';
import orderBy from 'lodash.orderby';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import download from 'utils/download';
import { callTrack } from 'utils/segmentIntegration';

import {
  TIMECARD_REPORT_DOWNLOADED,
  TIMESHEET_APPROVED,
  TIMESHEET_UNAPPROVED,
  INTEGRATION_TIME_SYNCED
} from 'utils/segmentAnalytics/eventSpec';

import request from 'axios';
import { t } from 'utils/translate';
import history from 'utils/history';

const MAX_ERROR_COUNT_PER_DRY_RUN = 20;
const MAX_TIME_SHEET_COUNT_PER_DRY_RUN = 300;
export default class TimesheetsUI extends UIStore {
  @observable searchQuery;
  @observable page;
  @observable pageSize;
  @observable searchMode;
  @observable sendingReminder;

  @observable signed;
  @observable fetchingAllTimecards;
  @observable timeSheetToEditOrViewSignature;
  @observable tempColumnsSettings;
  @observable verifyingWorker;
  @observable clockedInAlertModalType;

  @observable checkingExportStatus;
  @observable loadingPageData;
  @observable checkingExportProgress;

  constructor(options) {
    super(options);

    //pagination
    this.page = 1;
    this.pageSize = 20;
    this.searchQuery = '';
    this.searchMode = false;

    this.sendingReminder = false;

    this.clockedInAlertModalType = null;

    // Export
    this.checkingExportStatus = false;

    // Signature pad state
    this.signed = false;
    this.timeSheetToEditOrViewSignature = null;

    this.timeSheetUuidToApprove = null; //We use this to hold through the sign modal if approving from the row.
    this.fetchingAllTimecards = false;

    //Timeshet Filters
    this.timesheetFiltersUI = new TimesheetFiltersUI({
      rootStore: this.rootStore,
      parent: this
    });

    //Timesheet settings
    this.timesheetSettings = new TimesheetSettings(null, {
      rootStore: this.rootStore
    });

    this.tempColumnsSettings = [];

    //Timesheet Report
    this.timesheetsReportUI = new TimesheetsReportUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timesheetsAddUI = new TimesheetsAddUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timesheetsEditUI = new TimesheetsEditUI({
      rootStore: this.rootStore,
      parent: this
    });

    // Integrations configuration
    this.integrationsAccountingConfiguration = new IntegrationsAccountingConfiguration(
      null,
      {
        rootStore: this.rootStore,
        parent: this
      }
    );

    // Running
    this.verifyingWorker = false;
    this.loadingPageData = false;
  }

  @computed get title() {
    return t('Time cards');
  }

  @action.bound setup() {
    if (!this.authorization.canViewTimesheets) return;

    this.loadingPageData = true;
    this.fetchTimesheetsPageData();
    this.shiftSelectorUI.fetchShifts();
    this.integrationsAccountingConfiguration.fetch();
  }

  @action.bound tearDown() {
    this.cancelAllReactions();
    this.resetParamsToDefault();
  }

  @computed
  get timesheetSummary() {
    return this.rootStore.timesheetSummary;
  }

  @computed
  get timesheets() {
    if (this.hasTimesheets) {
      return this.timesheetSummary.timesheets.models;
    }
    return [];
  }

  @computed
  get hasTimesheets() {
    return this.timesheetSummary.timesheets?.hasModels;
  }

  @computed get loading() {
    return (
      this.timesheetSummary.fetching ||
      this.timesheetSettings.fetching ||
      this.loadingPageData
    );
  }

  //Fetching and Pagination
  @computed
  get requestParams() {
    const params = {
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize,
      from: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      to: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      status: this.filterValues.statusFilters,
      integrationSyncStatus: this.filterValues.syncStatusFilters,
      projects: this.filterValues.projectFilters,
      payTypes: this.filterValues.payTypeFilters,
      shifts: this.filterValues.shiftFilters,
      classifications: this.filterValues.classificationFilters,
      costCodes: this.filterValues.costCodeFilters,
      workers: this.filterValues.workerFilters,
      eventOrigins: this.filterValues.eventOriginFilters,
      timeClockStatus: this.filterValues.timeClockStatusFilters,
      onlyViolations: this.filterValues.violationsFilters,
      onlyNoGps: this.filterValues.noGpsFilters,
      employeeGroups: this.filterValues.employeeGroupFilters
    };

    return params;
  }

  @computed
  get timeCardsRequestParams() {
    const params = {
      query: this.searchQuery,
      status: this.filterValues.statusFilters,
      integrationSyncStatus: this.filterValues.syncStatusFilters,
      projects: this.filterValues.projectFilters,
      payTypes: this.filterValues.payTypeFilters,
      shifts: this.filterValues.shiftFilters,
      classifications: this.filterValues.classificationFilters,
      costCodes: this.filterValues.costCodeFilters,
      workers: this.filterValues.workerFilters,
      eventOrigins: this.filterValues.eventOriginFilters,
      timeClockStatus: this.filterValues.timeClockStatusFilters,
      onlyViolations: this.filterValues.violationsFilters,
      onlyNoGps: this.filterValues.noGpsFilters,
      employeeGroups: this.filterValues.employeeGroupFilters
    };

    return params;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.timesheetSummary.page?.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
  }

  setupSearchReaction() {
    this.cancelSearchReaction = reaction(
      () => this.searchQuery,
      query => {
        if (this.cancelParamsReaction) {
          this.cancelParamsReaction();
        }

        this.page = 1;
        this.searchMode = Boolean(query);

        this.fetchTimesheets()
          .then(() => {
            this.expandAllTimesheetsWithCondition();
            this.setupParamsReaction();
          })
          .catch(error => alertErrorHandler(error, this.setValidationDetails));
      },
      { delay: 1500 }
    );
  }

  @action.bound
  clearSearchQuery() {
    this.cancelAllReactions();
    this.page = 1;
    this.searchQuery = '';
    this.searchMode = false;

    this.fetchTimesheets()
      .then(() => {
        this.expandAllTimesheetsWithCondition();
        this.setupAllReactions();
      })
      .catch(error => alertErrorHandler(error, this.setValidationDetails));
  }

  setupParamsReaction() {
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }

    this.cancelParamsReaction = reaction(
      () => this.requestParams,
      async requestParams => {
        this.paramsReaction();
      }
    );
  }

  async paramsReaction() {
    try {
      await this.fetchTimesheets();

      this.expandAllTimesheetsWithCondition();
      this.loadingPageData = false;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  cancelAllReactions() {
    if (this.cancelSearchReaction) {
      this.cancelSearchReaction();
    }
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }
  }

  setupAllReactions() {
    this.setupParamsReaction();
    this.setupSearchReaction();
  }

  @action.bound
  async fetchTimesheets() {
    this.timesheetSummary.fetching = true;

    if (this.hasExpandedTimesheet) {
      if (this.allTimesheetsExpanded) {
        this.expandAllTimesheetsAfterFetching = true;
      }
      this.collapseAllTimesheets();
    }

    const fetchParams = pickBy(
      { ...this.requestParams, query: this.searchQuery },
      identity
    );

    return request
      .post(
        `${this.rootStore.urlMicroService('performanceTracking')}/timesheets`,
        fetchParams
      )
      .then(
        response => {
          runInAction(() => {
            this.timesheetSummary.set(response.data);
            this.timesheetSummary.fetching = false;
          });
        },
        error => {
          this.timesheetSummary.fetching = false;
          alertErrorHandler(error, this.setValidationDetails);
        }
      );
  }

  @computed
  get allTimesheetsSelected() {
    return !this.timesheets.find(timesheet => !timesheet.selected);
  }

  @computed
  get hasSelectedTimeSheetsOrTimeCards() {
    return this.timesheets.find(
      timesheet =>
        timesheet.selected ||
        timesheet.timeCards.models.find(timecard => timecard.selected)
    );
  }

  @computed
  get hasPartlySelectedTimesheets() {
    return (
      !this.allTimesheetsSelected &&
      !!this.timesheets.find(timesheet => timesheet.selected)
    );
  }

  @computed
  get hasPartlySelectedTimeCards() {
    return !!this.timesheets.find(
      timesheet => timesheet.hasPartlySelectedTimecards
    );
  }

  @action.bound
  sign() {
    this.signed = true;
  }

  @action.bound
  unSign() {
    this.signed = false;
  }

  @action.bound
  unSignSync() {
    this.signed = false;
  }

  @computed get bulkActionParams() {
    const params = {
      from: moment(this.timesheetSummary.dateRange.from)
        .utc()
        .format('YYYY-MM-DD'),
      to: moment(this.timesheetSummary.dateRange.to)
        .utc()
        .format('YYYY-MM-DD'),
      query: this.searchQuery,
      workers: this.filterValues.workerFilters,
      projects: this.filterValues.projectFilters,
      payTypes: this.filterValues.payTypeFilters,
      shifts: this.filterValues.shiftFilters,
      classifications: this.filterValues.classificationFilters,
      costCodes: this.filterValues.costCodeFilters,
      status: this.filterValues.statusFilters,
      integrationSyncStatus: this.filterValues.syncStatusFilters,
      eventOrigins: this.filterValues.eventOriginFilters,
      timeClockStatus: this.filterValues.timeClockStatusFilters,
      onlyViolations: this.filterValues.violationsFilters,
      onlyNoGps: this.filterValues.noGpsFilters,
      employeeGroups: this.filterValues.employeeGroupFilters
    };

    if (this.hasSelectedTimeSheetsOrTimeCards) {
      params.timeSheets = this.timesheets
        .filter(
          timesheet =>
            timesheet.selected || timesheet.hasPartlySelectedTimecards
        )
        .map(timesheet => {
          return {
            timeSheetUuid: timesheet.id,
            timeCards: timesheet.timeCards.models
              .filter(timecard => timecard.selected)
              .map(timecard => timecard.id)
          };
        });
    }

    return params;
  }

  @computed
  get hasSelectedOnlyClockedInTimecards() {
    const selectedTimeCards = this.timesheets
      .filter(
        timesheet => timesheet.selected || timesheet.hasPartlySelectedTimecards
      )
      .map(timesheet => {
        if (timesheet.timeCards.models.length) {
          return timesheet.timeCards.models.filter(
            timecard => timecard.selected
          );
        } else {
          // User has an un opened timesheet selected with no timecards
          return {
            timeCardDetails: { hasKioskActivity: false }
          };
        }
      })
      .flat();

    return Boolean(
      selectedTimeCards.length &&
        selectedTimeCards.every(timecard => timecard.isClockedIn)
    );
  }

  @action.bound
  async openTimesheetsApprovalSignModal(uuid) {
    await this.authorization.checkFeatureAccess('ReviewAndApproveTimeCards');

    if (uuid) {
      // If we send a uuid then we are approving just one timesheet from the row ellipsis button
      this.timeSheetUuidToApprove = uuid;
    }

    // If we are approving only timecards that are in the clocked in status show the user the error modal.
    if (this.hasSelectedOnlyClockedInTimecards) {
      this.clockedInAlertModalType = 'approve';

      this.showModal('timesheetsClockedInWarningModal');
      return;
    }

    this.showModal('timesheetsApprovalsSignModal');
  }

  @action.bound
  closeTimesheetsApprovalsSignModal(clearUuid = false) {
    if (clearUuid) {
      this.timeSheetUuidToApprove = null;
    }
    return this.hideActiveModal();
  }

  @action.bound
  async bulkToggleApprovals(signature) {
    await this.authorization.checkFeatureAccess('ReviewAndApproveTimeCards');

    const requestParams = signature
      ? Object.assign({}, this.bulkActionParams, {
          signature: signature
        })
      : this.bulkActionParams;

    this.bulkApproveSendRequest(requestParams, Boolean(signature));
  }

  @action.bound
  sendSingleApproval(signature, uuid) {
    this.closeTimesheetsApprovalsSignModal();

    const params = {
      from: moment(this.timesheetSummary.dateRange.from)
        .utc()
        .format('YYYY-MM-DD'),
      to: moment(this.timesheetSummary.dateRange.to)
        .utc()
        .format('YYYY-MM-DD'),
      query: '',
      workers: [],
      projects: [],
      status: signature ? 'APPROVED' : 'UNAPPROVED',
      timeSheets: [
        {
          timeSheetUuid: this.timeSheetUuidToApprove || uuid,
          timeCards: []
        }
      ]
    };

    if (signature) {
      // Signature will only be passed on approval
      params.signature = signature;
    }

    this.timeSheetUuidToApprove = null;
    this.bulkApproveSendRequest(params, Boolean(signature));
  }

  @action.bound
  async bulkApproveSendRequest(params, approveOrUnapprove) {
    const url = `${this.rootStore.urlMicroService('performanceTracking')}${
      approveOrUnapprove ? '/timesheets/approve' : '/timesheets/unapprove'
    }`;

    const event = approveOrUnapprove
      ? TIMESHEET_APPROVED
      : TIMESHEET_UNAPPROVED;

    callTrack(event);

    this.saving = true;

    try {
      await request.post(url, params);

      this.saving = false;

      this.closeTimesheetsApprovalsSignModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: approveOrUnapprove
          ? t('Approvals Complete')
          : t('Unapprovals Complete')
      });

      // Fetch to display the updated status
      this.fetchTimesheets()
        .then(() => {
          this.expandAllTimesheetsWithCondition();
          this.setupAllReactions();
        })
        .catch(error => errorHandler(error, this.notifications.pushError));
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.saving = false;
    }
  }

  @computed get disableActions() {
    return this.loading || !this.hasTimesheets || this.saving;
  }

  @computed get enableTimesheetsExport() {
    return Boolean(this.integrationsAccountingConfiguration.active);
  }

  @action.bound async checkTimesheetsExportStatus() {
    this.clearValidationDetails();

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/export?dryRun=true`;

    this.checkingExportStatus = true;
    this.checkingExportProgress = null;

    try {
      const failedTimesheets = [];

      if (this.bulkActionParams.timeSheets?.length) {
        // Send a single request for the dry-run check on selected timesheets, based on the assumption that user selection through the UI will not involve an excessive number of timesheets.
        const response = await request.post(url, this.bulkActionParams);
        failedTimesheets.push(...(response.data?.failed || []));
      } else {
        let nextOffset = 0;
        while (
          nextOffset !== undefined &&
          failedTimesheets.length <= MAX_ERROR_COUNT_PER_DRY_RUN
        ) {
          const pageUrl = `${url}&offset=${nextOffset}&limit=${MAX_TIME_SHEET_COUNT_PER_DRY_RUN}`;
          const response = await request.post(pageUrl, this.bulkActionParams);
          if (!this.checkingExportStatus) {
            return; //user closed the dialog
          }
          failedTimesheets.push(...(response.data?.failed || []));
          nextOffset = response.data?.page?.nextOffset;
          if (nextOffset) {
            this.checkingExportProgress = {
              processed: nextOffset,
              total: response.data?.page?.totalElements
            };
          }
        }
      }

      if (failedTimesheets.length > 0) {
        let errorMessage;
        if (failedTimesheets.length > MAX_ERROR_COUNT_PER_DRY_RUN) {
          failedTimesheets.splice(MAX_ERROR_COUNT_PER_DRY_RUN);
          errorMessage = t(
            `More than ${MAX_ERROR_COUNT_PER_DRY_RUN} time cards, including those listed below, are not ready to be synced. Please correct them and try again.`
          );
        } else {
          errorMessage = t(
            'The following time cards are not ready to be synced. Please correct them and try again.'
          );
        }
        this.setValidationDetails([
          {
            field: 'message',
            fieldMessage: errorMessage
          },
          ...failedTimesheets.map(failed => ({
            fieldKey: `${failed.uuid}-${failed.date}-${failed.reasons.join(
              ','
            )}`,
            fieldUrl: `/timecards/${failed.timesheetUuid}/timecard/${failed.uuid}`,
            fieldMessage: `${failed.worker.name} - ${
              failed.project.name
            } - ${moment(failed.date).format(
              'ddd, MMM D, YYYY'
            )} (${failed.reasons.join(',')})`
          }))
        ]);
      }
    } catch (error) {
      const status = error?.response?.status;
      if (this.checkingExportStatus && status !== 401) {
        const fieldMessage =
          (status
            ? t('Request failed with status code ') + status + '.'
            : t('Request failed due to an unknown network error.')) +
          ' ' +
          t(
            'Please try again later. If the problem persists, please contact Raken support.'
          );
        this.setValidationDetails([
          {
            field: 'message',
            fieldMessage
          }
        ]);
      }
    } finally {
      // Reset the flag to indicate that the export status check is complete
      this.checkingExportStatus = false;
      this.checkingExportProgress = null;
    }
  }

  @action.bound async openTimesheetsExportModal() {
    // If we are approving only timecards that are in the clocked in status show the user the error modal.
    if (this.hasSelectedOnlyClockedInTimecards) {
      this.clockedInAlertModalType = 'sync';

      this.showModal('timesheetsClockedInWarningModal');
      return;
    }

    await this.authorization.checkFeatureAccess('SyncIntegrationTime');

    this.checkTimesheetsExportStatus();

    this.showModal('timesheetsExportModal');
  }

  @action.bound cancelTimesheetsExport() {
    this.checkingExportStatus = false;
    this.hideActiveModal(false);
  }

  @action.bound async confirmTimesheetsExport() {
    this.clearValidationDetails();

    this.saving = true;

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/timesheets/export`;

    this.saving = true;

    try {
      await request.post(url, this.bulkActionParams);

      this.hideActiveModal().then(() => {
        runInAction(() => {
          callTrack(INTEGRATION_TIME_SYNCED, {
            integration_name: this.integrationsAccountingConfiguration?.name
          });

          this.notifications.pushNotification({
            snackbar: 'warning',
            icon: 'notification',
            title: t('Sync in progress')
          });

          // Fetch to display the updated status
          this.fetchTimesheets()
            .then(() => {
              this.expandAllTimesheetsWithCondition();
              this.setupAllReactions();
            })
            .catch(error => errorHandler(error, this.notifications.pushError));
        });
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  toggleTimesheetsSelection() {
    runInAction(() => {
      const selected = !this.allTimesheetsSelected;
      this.timesheets.forEach(timesheet => {
        timesheet.setSelected(selected);
      });
    });
  }

  viewWorkerReport(timesheet) {
    download({
      store: this.rootStore,
      url:
        this.rootStore.appConfig.tracking_api_url +
        `/timesheets/${timesheet.timeSheetUuid}/pdf`,
      xhttpOptions: {
        sendXApiKey: true
      },
      mode: 'generatePDFHtml'
    });
  }

  @computed
  get dateRangeFrom() {
    return moment(this.timesheetSummary.dateRange.from)
      .utc()
      .format('YYYY-MM-DD');
  }

  @computed
  get dateRangeTo() {
    return moment(this.timesheetSummary.dateRange.to)
      .utc()
      .format('YYYY-MM-DD');
  }

  bulkDownloadPDF() {
    callTrack(TIMECARD_REPORT_DOWNLOADED, { file_type: 'pdf' });

    const data = {
      workers: this.filterValues.workerFilters,
      projects: this.filterValues.projectFilters,
      payTypes: this.filterValues.payTypeFilters,
      shifts: this.filterValues.shiftFilters,
      classifications: this.filterValues.classificationFilters,
      costCodes: this.filterValues.costCodeFilters,
      eventOrigins: this.filterValues.eventOriginFilters,
      timeClockStatus: this.filterValues.timeClockStatusFilters,
      onlyViolations: this.filterValues.violationsFilters,
      onlyNoGps: this.filterValues.noGpsFilters,
      status: this.filterValues.statusFilters,
      integrationSyncStatus: this.filterValues.syncStatusFilters,
      from: this.dateRangeFrom,
      to: this.dateRangeTo,
      typeFile: 'pdf',
      employeeGroups: this.filterValues.employeeGroupFilters
    };

    download({
      store: this.rootStore,
      url:
        this.rootStore.appConfig.tracking_api_url + '/timesheets/summary/pdf',
      xhttpOptions: {
        sendXApiKey: true
      },
      data,
      mode: 'generatePDFHtml'
    });
  }

  bulkDownloadXlsOrCsv(type) {
    callTrack(TIMECARD_REPORT_DOWNLOADED, { file_type: type });

    const url = `${this.rootStore.appConfig.tracking_api_url}/timesheets/reports/csv`;

    const data = {
      workers: this.filterValues.workerFilters,
      projects: this.filterValues.projectFilters,
      payTypes: this.filterValues.payTypeFilters,
      shifts: this.filterValues.shiftFilters,
      classifications: this.filterValues.classificationFilters,
      costCodes: this.filterValues.costCodeFilters,
      eventOrigins: this.filterValues.eventOriginFilters,
      timeClockStatus: this.filterValues.timeClockStatusFilters,
      onlyViolations: this.filterValues.violationsFilters,
      onlyNoGps: this.filterValues.noGpsFilters,
      status: this.filterValues.statusFilters,
      integrationSyncStatus: this.filterValues.syncStatusFilters,
      from: this.dateRangeFrom,
      to: this.dateRangeTo,
      typeFile: type,
      employeeGroups: this.filterValues.employeeGroupFilters
    };

    download({
      store: this.rootStore,
      url,
      xhttpOptions: {
        sendXApiKey: true
      },
      data
    });
  }

  @action.bound
  resetParamsToDefault() {
    this.searchQuery = '';
    this.searchMode = false;
    this.timesheetFiltersUI.clearAllFilters(); //includes reset to page 1
  }

  @computed get filterValues() {
    return {
      projectFilters: this.timesheetFiltersUI.projectFilters.map(
        project => project.value
      ),
      payTypeFilters: this.timesheetFiltersUI.payTypeFilters.map(
        payType => payType.value
      ),
      shiftFilters: this.timesheetFiltersUI.shiftFilters.map(
        shift => shift.value
      ),
      classificationFilters: this.timesheetFiltersUI.classificationFilters.map(
        classification => classification.value
      ),
      costCodeFilters: this.timesheetFiltersUI.costCodeFilters.map(
        costCode => costCode.value
      ),
      employeeGroupFilters: this.timesheetFiltersUI.employeeGroupFilters.map(
        group => group.value
      ),
      workerFilters: this.timesheetFiltersUI.workerFilters.map(
        worker => worker.value
      ),
      statusFilters: this.timesheetFiltersUI.statusFilters.value,
      syncStatusFilters: this.timesheetFiltersUI.syncStatusFilters.value,
      eventOriginFilters: this.timesheetFiltersUI.eventOriginFilters.map(
        eventOrigin => eventOrigin.value
      ),
      timeClockStatusFilters: this.timesheetFiltersUI.timeClockStatusFilters
        .value,
      violationsFilters: this.timesheetFiltersUI.violationsFilters,
      noGpsFilters: this.timesheetFiltersUI.noGpsFilters
    };
  }

  @action.bound
  openTimesheetsReminderAlertModal() {
    /**
     * If settings signoff is not enabled show the modal which tells the user to turn it on to enable this feature
     */
    if (
      !this.rootStore.companyUI?.companyProductionUI.settingsSignOff.enabled
    ) {
      return this.showModal('timesheetsSettingsSignOffDisabledModal');
    }

    return this.showModal('timesheetsReminderAlertModal');
  }

  @action.bound
  redirectToWorkerSignOffSettings() {
    this.hideTimesheetsReminderAlertModal();
    const url =
      this.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.company.preferences.timePolicyStatus === 'ENABLED'
        ? '/company/time/approvals'
        : '/company/production';
    history.push(url);
  }

  @action.bound
  hideTimesheetsReminderAlertModal() {
    return this.hideActiveModal();
  }

  @computed
  get workersToRemindCount() {
    if (this.hasSelectedTimeSheetsOrTimeCards || this.allTimesheetsSelected) {
      return this.timesheets.filter(
        timesheet => timesheet.selected || timesheet.hasPartlySelectedTimecards
      ).length;
    }

    return this.timesheetSummary.noOfUnsignedTimeSheets;
  }

  @action.bound
  async remindIndividualWorker(workerUuid) {
    await this.authorization.checkFeatureAccess('ReviewAndApproveTimeCards');

    /**
     * If settings signoff is not enabled show the modal which tells the user to turn it on to enable this feature
     */

    if (
      !this.rootStore.companyUI?.companyProductionUI.settingsSignOff.enabled
    ) {
      return this.showModal('timesheetsSettingsSignOffDisabledModal');
    }

    this.remindWorkers([workerUuid]);
  }

  @action.bound
  remindWorkers(workerUuids = []) {
    const params = {
      workers: workerUuids,
      from: moment(this.timesheetSummary.dateRange.from)
        .utc()
        .format('YYYY-MM-DD'),
      to: moment(this.timesheetSummary.dateRange.to)
        .utc()
        .format('YYYY-MM-DD')
    };

    // If we have no workerUuids set we can apply the filters or selected users.
    if (workerUuids.length === 0) {
      if (this.hasSelectedTimeSheetsOrTimeCards || this.allTimesheetsSelected) {
        params.workers = this.timesheets
          .filter(
            timesheet =>
              timesheet.selected || timesheet.hasPartlySelectedTimecards
          )
          .map(timesheet => {
            return timesheet.worker.uuid;
          });
      } else {
        params.query = this.searchQuery;
        params.workers = this.filterValues.workerFilters;
        params.projects = this.filterValues.projectFilters;
        params.payTypes = this.filterValues.payTypeFilters;
        params.shifts = this.filterValues.shiftFilters;
        params.classifications = this.filterValues.classificationFilters;
        params.costCodes = this.filterValues.costCodeFilters;
        params.employeeGroups = this.filterValues.employeeGroupFilters;
        params.status = this.filterValues.statusFilters;
        params.integrationSyncStatus = this.filterValues.syncStatusFilters;
        params.eventOrigins = this.filterValues.eventOriginFilters;
        params.timeClockStatus = this.filterValues.timeClockStatusFilters;
        params.onlyViolations = this.filterValues.violationsFilters;
        params.onlyNoGps = this.filterValues.noGpsFilters;
        params.employeeGroupFilters = this.filterValues.employeeGroupFilters;
      }
    }

    this.sendingReminder = true;

    request
      .post(
        this.rootStore.appConfig.tracking_api_url +
          '/timesheets/send-reminders',
        params
      )
      .then(() => {
        this.sendingReminder = false;

        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title:
            this.workersToRemindCount === 1 || workerUuids.length === 1
              ? t('Worker Reminder Sent')
              : t('Workers Reminders Sent')
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      })
      .finally(() => {
        this.hideTimesheetsReminderAlertModal();
      });
  }

  @computed
  get hasExpandedTimesheet() {
    return Boolean(this.timesheets.find(timesheet => timesheet.expanded));
  }

  @computed
  get allTimesheetsExpanded() {
    const expandedTimesheetsNumber = this.timesheets.filter(
      timesheet => timesheet.expanded
    ).length;
    const timesheetsNumber = this.timesheets.length;

    return expandedTimesheetsNumber === timesheetsNumber;
  }

  @action.bound
  collapseAllTimesheets() {
    this.timesheets.forEach(timesheet => {
      timesheet.expanded = false;
    });
  }

  @action.bound
  async expandAllTimesheets() {
    const promiseArr = [];
    this.timesheets.forEach(timesheet => {
      const promise = timesheet.toggleTimeCards(this.timeCardsRequestParams);
      promiseArr.push(promise);
    });
    try {
      this.fetchingAllTimecards = true;
      await Promise.all(promiseArr);
      this.fetchingAllTimecards = false;
    } catch (error) {
      this.collapseAllTimesheets();
      this.fetchingAllTimecards = false;
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  expandAllTimesheetsWithCondition() {
    if (this.expandAllTimesheetsAfterFetching) {
      this.expandAllTimesheets();
      this.expandAllTimesheetsAfterFetching = false;
    }
  }

  @action.bound
  async toggleAllTimesheets() {
    if (this.hasExpandedTimesheet) {
      this.collapseAllTimesheets();
    } else {
      this.expandAllTimesheets();
    }
  }

  @action.bound
  getWorkerSignature() {
    const url = `/timesheets/${this.timeSheetToEditOrViewSignature.id}/sign`;

    return request
      .get(this.rootStore.appConfig.tracking_api_url + url)
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  async patchWorkerSignature(signature) {
    const url = `/timesheets/${this.timeSheetToEditOrViewSignature.id}/sign`;
    this.verifyingWorker = true;

    const params = {
      signature: signature
    };

    request
      .patch(this.rootStore.appConfig.tracking_api_url + url, params)
      .then(() => {
        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Worker Signature Updated')
        });
        // Find original timesheet object and mark it as signed so we don't have to refetch
        this.timesheets.find(
          timesheet => timesheet.id === this.timeSheetToEditOrViewSignature.id
        ).signed = true;
        this.closeWorkerSignModal();
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      })
      .finally(() => {
        this.verifyingWorker = false;
      });
  }

  @action.bound
  async openWorkerSignModal(timesheet) {
    await this.authorization.checkFeatureAccess('ReviewAndApproveTimeCards');

    this.timeSheetToEditOrViewSignature = {
      workerFullName: timesheet.workerFullName,
      ...timesheet
    };
    if (timesheet.signed) {
      this.getWorkerSignature().then(res => {
        this.timeSheetToEditOrViewSignature.signature = res.data.signature;
        this.showModal('timesheetsWorkerSignModal');
      });
    } else {
      this.showModal('timesheetsWorkerSignModal');
    }
  }

  @action.bound
  async closeWorkerSignModal() {
    await this.hideActiveModal();
    this.timeSheetToEditOrViewSignature = null;
  }

  //Columns Settings
  @action.bound
  fetchSettings() {
    return this.timesheetSettings.fetch({
      reset: true
    });
  }

  @action.bound
  openTimesheetsCustomizeTableModal() {
    this.tempColumnsSettings = orderBy(this.timesheetSettings.columns, [
      'order'
    ]);
    this.showModal('timesheetsCustomizeTable');
  }

  @action.bound
  closeTimesheetsCustomizeTableModal() {
    this.hideActiveModal().then(() => {
      this.tempColumnsSettings = [];
    });
  }

  @action.bound
  moveColumn(oldIndex, newIndex) {
    const updatedColumnsOrder = arrayMove(
      toJS(this.tempColumnsSettings),
      oldIndex,
      newIndex
    );

    updatedColumnsOrder.forEach((column, index) => (column.order = index + 1));
    this.tempColumnsSettings = updatedColumnsOrder;
  }

  @action.bound
  toggleColumnVisibility(updatedColumn) {
    for (let column of this.tempColumnsSettings) {
      if (column.name === updatedColumn.name) {
        column.hidden = !updatedColumn.hidden;
        break;
      }
    }
  }

  @action.bound
  saveColumnsOrderLocally(columns) {
    localStorage.setItem(`timesheetColumns`, JSON.stringify(columns));
  }

  @action.bound
  saveColumnsOrder() {
    this.saveColumnsOrderLocally(this.tempColumnsSettings);

    this.timesheetSettings.columns = this.tempColumnsSettings;

    this.closeTimesheetsCustomizeTableModal();
  }

  @action.bound
  orderColumnsByDefault() {
    this.tempColumnsSettings = orderBy(this.timesheetSettings.defaultColumns, [
      'order'
    ]);

    this.saveColumnsOrderLocally(this.tempColumnsSettings);
    return;
  }

  @action.bound
  fetchTimesheetsPageData() {
    this.fetchSettings().then(() => {
      this.timesheetFiltersUI.syncTimesheetSettingsWithFilters();
      this.setupAllReactions();
      this.paramsReaction();
      this.rootStore.companyUI.companyProductionUI.fetchSignOffProcess();
    });
  }

  @computed
  get customOrderColumns() {
    const customOrderColumns = this.timesheetSettings.visibleColumns.filter(
      column => {
        if (column.name !== 'DATE' && column.name !== 'WORKER_NAME')
          return column;
      }
    );
    return orderBy(customOrderColumns, ['order']);
  }

  @action.bound
  pushTimeCardUrl = timecard => {
    history.push(
      `/timecards/${timecard.parentTimesheet.id}/timecard/${timecard.id}`
    );
  };

  @action.bound
  pushTimeCardCreateUrl() {
    history.push('/timecards/create');
    this.collapseAllTimesheets();
  }

  @computed get timesheetsReportIntegration() {
    const integration = this.timesheetSettings.integrations?.find(
      integration => {
        return integration.flatFileBased;
      }
    );

    return integration;
  }

  @computed
  get timesheetsReportIntegrationOption() {
    if (this.timesheetsReportIntegration?.service.providerRef) {
      const { name, id } = this.timesheetsReportIntegration.service.providerRef;

      return {
        title: name.toUpperCase(),
        onClick: () => this.bulkDownloadXlsOrCsv(id)
      };
    }

    return null;
  }

  @computed get hasErpIntegration() {
    return this.timesheetSettings.hasErpIntegration;
  }

  @computed get erpIntegrationName() {
    return this.timesheetSettings.erpIntegrationName;
  }

  @computed get showAbandonedTimeCardsAlertBanner() {
    return this.timesheetSummary.noOfAbandonedTimeCards > 0;
  }

  @action.bound setFiltersToAbandoned() {
    this.page = 1;

    this.timesheetFiltersUI.timeClockStatusFilters = {
      title: t('Abandoned'),
      value: 'ABANDONED'
    };
  }
}

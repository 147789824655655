import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import difference from 'lodash.difference';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';
import ProjectMemberships from '../collections/ProjectMemberships';

import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class TeamWorkersProjectsUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable deleting;
  @observable isDefault;
  @observable loading;

  @observable showSelectAllActiveProjectsAlert;
  @observable allActiveProjectsSelected;

  constructor(options) {
    super(options);

    this.paginatedProjects = new ProjectMemberships(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk select all active projects
    this.showSelectAllActiveProjectsAlert = false;
    this.allActiveProjectsSelected = false;

    this.loading = true;

    this.selectedProjects = observable([]);
    this.selectedFilterOptions = observable([]);
    this.isDefault = false;

    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get memberUuid() {
    return this.entryForEdit.uuid;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setup() {
    this.setIsDefault();
    this.setSelectedProjects();
    this.setupReactions();
    this.fetchProjects();
    this.blockHistoryIfSelectedProjectsChanged();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.unblockHistory();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @computed get selectedProjectsChanged() {
    if (this.entryForEdit.projectUuids.length !== this.selectedProjects.length)
      return true;

    return (
      difference(
        this.entryForEdit.projectUuids.slice(),
        this.selectedProjects.slice()
      ).length > 0
    );
  }

  @action.bound blockHistoryIfSelectedProjectsChanged() {
    this.unblock = history.block((location, action) => {
      if (this.selectedProjectsChanged) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    this.clearSelectAllActiveProjects();
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get isMember() {
    if (
      !this.selectedFilterOptions.length ||
      this.selectedFilterOptions.length === 2
    ) {
      return null;
    }

    if (this.selectedFilterOptions.find(option => option.id === 'assigned')) {
      return 'true';
    }

    if (this.selectedFilterOptions.find(option => option.id === 'unassigned')) {
      return 'false';
    }

    return null;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      projectStates: 'ACTIVE',
      isMember: this.isMember
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setIsDefault() {
    this.isDefault = this.entryForEdit.isDefault;
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound setSelectedProjects() {
    this.selectedProjects.replace(this.entryForEdit.projectUuids.slice());
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    this.clearSelectAllActiveProjects();

    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.clearSelectAllActiveProjects();

      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .filter(project => !project.disabled)
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      if (this.page === 1) {
        this.showSelectionExpandedAlert = true;
      }

      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @computed get activeProjectsNotOnPage() {
    return this.paginatedProjects.totalElements - this.paginatedProjects.length;
  }

  @action.bound selectAllActiveProjects() {
    this.allActiveProjectsSelected = true;
  }

  @computed get allActiveProjectsAlertText() {
    if (this.allActiveProjectsSelected) {
      return t(
        `{activeProjectsCount} projects are selected. Would you like to clear your selection?`,
        {
          templateStrings: {
            activeProjectsCount: this.paginatedProjects.totalElements
          }
        }
      );
    }

    return t(
      `All {activeProjectsOnPage} projects on this page are selected. Would you like to expand the selection to include {activeProjectsNotOnPage} projects not shown?`,
      {
        templateStrings: {
          activeProjectsOnPage: this.paginatedProjects.length,
          activeProjectsNotOnPage: this.activeProjectsNotOnPage
        }
      }
    );
  }

  @action.bound clearSelectAllActiveProjects() {
    this.showSelectAllActiveProjectsAlert = false;
    this.allActiveProjectsSelected = false;
  }

  @action.bound clearAllSelectedProjects() {
    this.clearSelectAllActiveProjects();
    this.selectedProjects.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound async saveProjects() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    let payload;

    if (this.allActiveProjectsSelected) {
      payload = {
        isDefault: this.isDefault,
        addToActiveProjects: true
      };
    } else {
      payload = {
        isDefault: this.isDefault,
        projectUuids: this.selectedProjects.slice()
      };
    }

    try {
      await this.entryForEdit.save(payload, {
        wait: true,
        stripNonRest: !this.allActiveProjectsSelected
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Worker projects saved')
      });

      this.refetchAfterSave();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async refetchAfterSave() {
    this.clearUIState();
    this.setIsDefault();
    await this.fetchProjects();
    this.setSelectedProjects();
  }

  @action.bound cancelProjects() {
    this.setIsDefault();
    this.setSelectedProjects();
    this.clearSearchQuery();
    this.clearSelectAllActiveProjects();
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.searchQuery = '';
    this.isDefault = false;
    this.selectedProjects.clear();
    this.selectedFilterOptions.clear();
    this.projects.clear();
    this.clearValidationDetails();
    this.clearSelectAllActiveProjects();
  }

  @computed get filterOptions() {
    return [
      { id: 'assigned', title: 'Assigned' },
      { id: 'unassigned', title: 'Unassigned' }
    ];
  }

  @action.bound selectFilterOptions(options) {
    this.selectedFilterOptions.replace(options);
    this.page = 1;
  }
}

import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';

export default class Classification extends Model {
  @observable new = false;

  get restAttributes() {
    return ['workerType', 'name', 'uuid', 'externalId'];
  }

  get urlRoot() {
    return `/ra/companies/${this.company.uuid}/classifications`;
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @action.bound clearNew() {
    this.new = false;
  }

  @computed get entityId() {
    const parts = (this.externalId || '').split('|');
    return parts.length > 1 ? parts.pop() : null;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.collection?.parent?.activeCompany;
    }
    return this.rootStore.me.company;
  }
}

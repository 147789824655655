import moment from 'moment-timezone';
import ReportEntry from './ReportEntry';
import { computed, action, observable } from 'mobx';
import Form from 'stores/models/forms/Form';

import bytesToSize from 'utils/bytesToSize';

import { t } from 'utils/translate';

export default class Attachment extends ReportEntry {
  @observable preview;
  @observable uploadProgress;

  constructor(attributes, options) {
    super(attributes, options);
    this.thumbnailPollingCount = 0;
    this.uploadProgress = 0;

    if (!attributes.thumbUrl && !Boolean(options.skipThumbnailPolling)) {
      this.pollForThumbURL();
    }
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'desc',
      'fileSize',
      'fileName',
      'createdTimestamp',
      'updatedTimestamp',
      'uploadDate',
      'takenDate',
      'contentUrl',
      'mediaType',
      'contentType',
      'thumbUrl',
      'project',
      'reportId',
      'reportDate',
      'pdfs',
      'fileName',
      'teamId',
      'teamUuid',
      'segmentUuid',
      'createdByRef',
      'companyUuid',
      'coverOrder',
      'isFavorite',
      'mediaAI'
    ];
  }

  urlRoot = '/ra/media';

  idAttribute() {
    return 'uuid';
  }

  @computed
  get title() {
    return t('Attachments');
  }

  @action.bound
  setPreview(preview) {
    this.preview = preview;
  }

  /**
   * When uploading new image there is a short "downtime" until the thumbnail is generated.
   * During that time the new image cannot be edited because the image isn't
   * available yet in S3 and the backend.
   *
   * @improvement opportunity
   */
  @action.bound
  pollForThumbURL() {
    this.thumbUrlPoller = setInterval(this.fetchThumbUrl, 10000);
  }

  @action.bound
  async fetchThumbUrl() {
    if (this.parent instanceof Form) return;

    if (this.thumbnailPollingCount + 1 > 10) {
      clearInterval(this.thumbUrlPoller);
      return;
    }

    this.thumbnailPollingCount += 1;

    try {
      const response = await this.fetch({
        url: `/ra/media/${this.uuid}`,
        reset: false
      });

      if (response.thumbUrl) {
        if (this.preview) {
          window.URL.revokeObjectURL(this.preview);
        }

        clearInterval(this.thumbUrlPoller);
      }
    } catch (error) {
      if (error.response.status === 404) {
        clearInterval(this.thumbUrlPoller);
      }
    }
  }

  @computed
  get thumbnail() {
    if (this.thumbUrl) return this.thumbUrl;

    switch (this.mediaType) {
      case 'IMAGE':
        return this.preview
          ? this.preview
          : `${this.rootStore.assetsURL}/svg/attachments/IMAGE.svg`;
      case 'DOCUMENT':
        switch (this.contentType) {
          // Word Docs
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          case 'application/vnd.ms-word.document.macroEnabled.12':
          case 'application/vnd.ms-word.template.macroEnabled.12':
            return `${this.rootStore.assetsURL}/svg/attachments/DOC.svg`;
          // Excel
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          case 'application/vnd.ms-excel.template.macroEnabled.12':
          case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            return `${this.rootStore.assetsURL}/svg/attachments/XLS.svg`;
          // PPT
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
            return `${this.rootStore.assetsURL}/svg/attachments/PPT.svg`;
          // PDF
          case 'application/pdf':
            return `${this.rootStore.assetsURL}/svg/attachments/PDF.svg`;
          default:
            return `${this.rootStore.assetsURL}/svg/attachments/DOC.svg`;
        }
      case 'AUDIO':
        return `${this.rootStore.assetsURL}/svg/attachments/MP3.svg`;
      case 'VIDEO':
        switch (this.contentType) {
          case 'video/x-m4v':
            return `${this.rootStore.assetsURL}/svg/attachments/M4V.svg`;
          case 'video/mp4':
            return `${this.rootStore.assetsURL}/svg/attachments/MP4.svg`;
          default:
            return `${this.rootStore.assetsURL}/svg/attachments/MOV.svg`;
        }
      default:
        return this.thumbUrl;
    }
  }

  @computed
  get takenTime() {
    return moment(this.takenDate)
      .tz(this.timezone)
      .format('h:mmA');
  }

  @computed
  get takenDateFormatted() {
    return moment(this.takenDate)
      .tz(this.timezone)
      .format('YYYY-MM-DD');
  }

  @computed
  get takenOrUploaded() {
    if (this.takenDate) {
      return `${this.takenTime} ${
        !this.timezoneSameAsLocal ? this.timezoneAbbr : ''
      } - ${this.takenDateFormatted}`;
    }

    return this.lastUpdatedFormatted;
  }

  @computed
  get takenOrUploadedDate() {
    if (this.takenDate) {
      return this.takenDate;
    }

    return this.uploadDate;
  }

  @computed
  get fileSizeFormatted() {
    return bytesToSize(this.fileSize);
  }

  @computed get relatedCompany() {
    return this.collection.parent?.company;
  }

  @computed
  get externalTeamEntry() {
    if (this.collection.parent) {
      return (
        this.collection.parent.externalTeamEntry ||
        this.collection.parent.externalTeamEntryByUuid
      );
    }

    // Used for the gallery.
    return this.teamUuid !== this.rootStore.projectUI?.projectUuid;
  }

  @computed
  get canEditOrDelete() {
    // Tasks have different rules....
    if (this.collection.parent?.taskType === 'STANDARD') {
      return this.collection.parent.canEdit;
    }

    // Incident attachments can't be edited by users
    if (this.collection.parent?.cannotEditAttachment) return false;

    return (
      (this.rootStore.me.isAdmin || this.rootStore.me.isProjectMember) &&
      !this.externalTeamEntry &&
      !this.collection.parent?.isLockedForEditing
    );
  }

  @action.bound
  undoSoftDeleteAttachment(attachment, index) {
    this.collection.add(attachment, {
      at: index
    });
  }

  @action.bound
  confirmAttachmentDelete(attachment) {
    if (
      attachment.collection &&
      attachment.collection.parent.constructor.name === 'MaterialLog'
    ) {
      attachment
        .destroy({
          url: `${attachment.collection.uploadUrl}/${attachment.uuid}`
        })
        .then(() => {
          attachment = null;
        });
    } else {
      attachment.destroy().then(() => {
        attachment = null;
      });
    }
  }

  @action.bound
  async confirmDeleteAttachment(attachment) {
    await attachment.destroy({
      wait: true
    });

    attachment = null;
  }

  @action.bound
  softDeleteAttachment(type) {
    const originalIndex = this.collection.models.indexOf(this);

    this.collection.remove(this);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.undoSoftDeleteAttachment(this, originalIndex);
      },
      onDismiss: () => {
        this.confirmAttachmentDelete(this);
      },
      title: t('{type} Deleted', {
        templateStrings: {
          type: t(type)
        }
      })
    });
  }

  @action.bound setUploadProgress(progress) {
    this.uploadProgress = progress;
  }

  @computed get hasMediaAIMetadata() {
    return this.mediaAI?.keywords || this.mediaAI?.description;
  }
}

// Media Types
export const IMAGE = 'IMAGE';
export const DOCUMENT = 'DOCUMENT';
export const AUDIO = 'AUDIO';
export const VIDEO = 'VIDEO';
export const MEDIA_TYPES = {
  IMAGE,
  DOCUMENT,
  AUDIO,
  VIDEO
};

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const advancedFormPlugins = extendedPlugins;

const advancedFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const advancedFormFields = [
  'dateFormat',
  'showTimeCardsDivision',
  'trackMemberTime',
  'requireClassificationsOnTimeCards',
  'restrictClassificationsEditsOnTimeCards'
];

const advancedFormRules = {
  dateFormat: 'string',
  showTimeCardsDivision: 'boolean',
  trackMemberTime: 'string',
  requireClassificationsOnTimeCards: 'boolean',
  restrictClassificationsEditsOnTimeCards: 'boolean'
};

const advancedFormValues = {
  dateFormat: 'MM/dd',
  showTimeCardsDivision: true,
  trackMemberTime: 'assignedWorkersAndAssignedTeamMembers',
  requireClassificationsOnTimeCards: false,
  restrictClassificationsEditsOnTimeCards: true
};

const advancedFormLabels = {
  dateFormat: t('date display in reports'),
  showTimeCardsDivision: t('cost code display in reports'),
  trackMemberTime: t('employee lists on time cards'),
  requireClassificationsOnTimeCards: t('require classifications on time cards'),
  restrictClassificationsEditsOnTimeCards: t(
    'restrict classification editing on time cards'
  )
};

class AdvancedForm extends MobxReactForm {}

export {
  AdvancedForm,
  advancedFormOptions,
  advancedFormFields,
  advancedFormRules,
  advancedFormLabels,
  advancedFormValues,
  advancedFormPlugins
};

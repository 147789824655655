import ToolboxTalksUI from './ToolboxTalks';
import { observable, action, computed, reaction } from 'mobx';
import ScheduleBulks from 'stores/collections/ScheduleBulks';
import Projects from 'stores/collections/Projects';
import request from 'axios';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import orderBy from 'lodash.orderby';
import debounce from 'lodash.debounce';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';
import unionWith from 'lodash.unionwith';
import bytesToSize from 'utils/bytesToSize';
import moment from 'moment';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import ScheduledTalks from 'stores/collections/ScheduledTalks';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  ProjectsForScheduleForm,
  projectsForScheduleFormOptions,
  projectsForScheduleFormFields,
  projectsForScheduleFormRules,
  projectsForScheduleFormPlugins,
  projectsForScheduleFormValues
} from 'forms/projectsForSchedule';

import {
  ScheduledTalksForm,
  scheduledTalksFormOptions,
  scheduledTalksFormFields,
  scheduledTalksFormRules,
  scheduledTalksFormPlugins
} from 'forms/scheduledTalks';

import {
  ScheduleBulkForm,
  scheduleBulkFormOptions,
  scheduleBulkFormFields,
  scheduleBulkFormRules,
  scheduleBulkFormPlugins,
  scheduleBulkFormLabels,
  scheduleBulkFormValues
} from 'forms/scheduleBulk';

import {
  RenameTalkForm,
  renameTalkFormFields,
  renameTalkFormRules,
  renameTalkFormLabels,
  renameTalkFormOptions,
  renameTalkFormPlugins
} from 'forms/renameTalk';

import { callTrack } from 'utils/segmentIntegration';

import { TBT_SCHEDULED } from 'utils/segmentAnalytics/eventSpec';

export default class CompanyToolboxTalksUI extends ToolboxTalksUI {
  @observable tab;
  @observable talkForDelete;
  @observable talkForRename;
  @observable talkForSchedule;
  @observable scheduledTalksForm;
  @observable scheduleBulkForm;
  @observable talksAreScheduling;
  @observable searchQuery;
  @observable scheduleBulks;
  @observable scheduleBulkTabs;
  @observable selectAllForBulk;
  @observable bulkLoading;
  @observable saveFormValues;
  @observable page;
  @observable sortField;
  @observable sortDirection;
  @observable talkIdInEditMode;
  @observable updateModalShown;
  @observable searchMode;
  @observable activeTalk;

  @observable talksForUploading = [];
  @observable talksForReplacing = [];

  constructor(options) {
    super(options);

    this.tab = 'all';
    this.companyLevel = true;

    // Rename
    this.talkForRename = null;

    // Delete
    this.talkForDelete = null;
    this.selectedScheduledTalks = observable([]);

    // Schedule
    this.talkForSchedule = null;
    this.talksAreScheduling = false;
    this.talkIdInEditMode = null;

    this.activeTalk = null;

    // TODO. Eventually we will use ProjectSelectorUI
    // For now this replaces rootStore > projects
    this.projects = new Projects(null, {
      rootStore: this.rootStore
    });

    // Bulk Schedule
    this.scheduleBulks = new ScheduleBulks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // All Scheduled Talks
    this.allScheduledTalks = new ScheduledTalks(null, {
      rootStore: this.rootStore
    });

    this.scheduleBulkTabs = ['select'];
    this.scheduledTalksForm = null;
    this.scheduleBulkForm = null;
    this.selectAllForBulk = false;
    this.bulkLoading = false;
    this.saveFormValues = null;

    // Params
    this.sortField = 'talk.name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.searchMode = false;
    this.page = 1;
    this.limit = 20;

    //Talks Updating
    this.updateModalShown = false;

    this.searchTalks = debounce(this.searchTalks, BASE_DEBOUNCE);
    this.setupAllReactions();
  }

  @computed
  get talks() {
    return this.rootStore.toolboxTalks;
  }

  @computed get memberUuid() {
    return this.rootStore.me.uuid;
  }

  @computed
  get talkType() {
    return this.tab === 'all' ? 'talks' : 'scheduledTalks';
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound
  clearSearchQuery() {
    this.cancelAllReactions();
    this.searchQuery = '';
    this.page = 1;
    this.searchMode = false;

    this.tab === 'all'
      ? this.fetchTalks().then(() => {
          this.setupAllReactions();
        })
      : this.fetchAllScheduledTalks().then(() => {
          this.setupAllReactions();
        });
  }

  @computed get hasSearchQuery() {
    return this.searchQuery.length > 0;
  }

  @action.bound
  switchTabByLocation(section = 'all') {
    this.tab = section;
    this.clearData();

    if (section === 'all') {
      this.fetchTalks();
    } else {
      this.fetchAllScheduledTalks();
    }

    this.redirectUploadTalk = false;
  }

  @computed
  get requestParams() {
    return {
      page: this.page - 1,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      limit: this.limit
    };
  }

  setupParamsReaction() {
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }

    this.cancelParamsReaction = reaction(
      () => this.requestParams,
      requestParams => {
        this.tab === 'all' ? this.fetchTalks() : this.fetchAllScheduledTalks();
      }
    );
  }

  setupSearchReaction() {
    this.cancelSearchReaction = reaction(
      () => this.searchQuery,
      query => {
        if (this.cancelParamsReaction) {
          this.cancelParamsReaction();
        }

        this.searchMode = Boolean(query);
        this.page = 1;
        this.searchTalks();
      }
    );
  }

  cancelAllReactions() {
    if (this.cancelSearchReaction) {
      this.cancelSearchReaction();
    }
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }
  }

  @action.bound async searchTalks() {
    if (this.tab === 'all') {
      await this.fetchTalks();
      this.searchMode = false;
      this.setupParamsReaction();
    } else {
      await this.fetchAllScheduledTalks();
      this.searchMode = false;
      this.setupParamsReaction();
    }
  }

  setupAllReactions() {
    this.setupParamsReaction();
    this.setupSearchReaction();
  }

  @action.bound
  fetchTalks() {
    const fetchParams = {
      ...this.requestParams,
      include: 'countUpcoming&countPast',
      query: this.searchQuery,
      hasPast: !(this.tab === 'all' || this.tab === 'upcoming'),
      hasUpcoming: !(this.tab === 'all' || this.tab === 'past')
    };

    if (this.tab === 'past') {
      fetchParams.statuses = 'ACTIVE,DELETED';
    }

    return this.talks.fetch({
      params: fetchParams
    });
  }

  @action.bound
  fetchAllScheduledTalks() {
    return new Promise((resolve, reject) => {
      this.allScheduledTalks
        .fetch({
          params: {
            ...this.requestParams,
            query: this.searchQuery,
            talkId: ''
          },
          url: `${this.allScheduledTalks.url()}/${this.tab}`
        })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject();
        });
    });
  }

  @computed get allTalks() {
    return orderBy(
      this.talks.models,
      ['new', ({ name }) => name.toLocaleLowerCase()],
      ['desc', 'asc']
    );
  }

  @computed get totalPages() {
    if (this.tab === 'all') {
      return Math.ceil(this.talks.getPagingKey('totalElements') / this.limit);
    }

    return Math.ceil(
      this.allScheduledTalks.getPagingKey('totalElements') / this.limit
    );
  }

  checkUserAccessToScheduledTalkProject = talk => {
    return new Promise(resolve => {
      if (this.me.projectUuids.includes(talk.project.uuid)) {
        resolve(true);
      } else {
        this.authorization.showModal('featureAccess');
      }
    });
  };

  @action.bound
  async showDeleteTalkModal(talk) {
    if (this.talkType === 'scheduledTalks') {
      await this.authorization.checkFeatureAccess('DeleteScheduleToolboxTalks');
      await this.checkUserAccessToScheduledTalkProject(talk);
    } else {
      await this.authorization.checkFeatureAccess('UploadToolboxTalks');
    }

    this.talkForDelete = talk;

    this.showModal('DeleteModal');
  }

  @action.bound
  async confirmDeleteTalk() {
    try {
      this.saving = true;

      if (this.tab === 'all') {
        await request.patch(
          `${this.urlMicroService('toolbox')}/tbt/company/talks/batch`,
          {
            talkIds: [this.talkForDelete.uuid]
          }
        );
      } else {
        await this.talkForDelete.destroy({
          wait: true
        });
      }

      if (!this.hasTalks) {
        this.page = 1;
      }

      if (this.tab === 'all') {
        this.fetchTalks();
      } else {
        this.fetchAllScheduledTalks();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk deleted')
      });

      this.talkForDelete = null;
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async cancelDeleteTalk() {
    await this.hideActiveModal();
    this.talkForDelete = null;
  }

  @computed get deleteTalkText() {
    if (!this.talkForDelete) return null;

    if (this.talkForDelete.hasUpcomingTalks) {
      return t(
        'This talk has been scheduled on your projects for future dates. Deleting this talk will remove all future instances of this talk on your projects.'
      );
    }

    return t('Please confirm that you want to delete this {talkStatus} talk.', {
      templateStrings: {
        talkStatus: this.talkForDelete.talkStatus
          ? this.talkForDelete.talkStatus.toLowerCase()
          : ''
      }
    });
  }

  @action.bound
  clearData() {
    this.cancelAllReactions();
    this.selectedScheduledTalks.clear();
    this.scheduledTalkForm = null;
    this.scheduledTalksForm = null;
    this.talkForRename = null;
    this.talkForDelete = null;
    this.talkForSchedule = null;
    this.searchQuery = '';
    this.sortField = 'talk.name';
    this.sortDirection = 'ASC';
    this.page = 1;

    if (this.allScheduledTalks) this.allScheduledTalks.reset();
    if (this.talks) this.talks.reset();

    this.setupAllReactions();
  }

  @action.bound
  initProjectsForScheduleForm() {
    this.fetchProjects();
    this.groupSelectorUI.setup();
    this.projectsForScheduleForm = new ProjectsForScheduleForm(
      {
        fields: projectsForScheduleFormFields,
        rules: projectsForScheduleFormRules,
        values: projectsForScheduleFormValues
      },
      {
        options: projectsForScheduleFormOptions,
        plugins: projectsForScheduleFormPlugins
      }
    );
  }

  @action.bound fetchProjects() {
    if (this.projects.length) return;

    this.projects.reset();

    this.projects.fetch({
      url: `ra/companies/${this.company.uuid}/projects`,
      params: {
        limit: 10000,
        projectStates: 'ACTIVE'
      }
    });
  }

  @computed
  get sortedProjects() {
    return orderBy(
      this.projects.models.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      }),
      [project => project.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get allActiveProjectsSelected() {
    return (
      this.projectsForScheduleForm.$('projects').size ===
      this.sortedProjects.length
    );
  }

  @action.bound
  toggleAllActiveProjects() {
    if (this.allActiveProjectsSelected) {
      this.projectsForScheduleForm.selectProjects([]);
    } else {
      this.projectsForScheduleForm.selectProjects([...this.sortedProjects]);
    }
  }

  @action.bound
  initScheduledTalksForm(values) {
    this.scheduledTalksForm = new ScheduledTalksForm(
      {
        fields: scheduledTalksFormFields,
        rules: scheduledTalksFormRules,
        values: values
      },
      {
        options: scheduledTalksFormOptions,
        plugins: scheduledTalksFormPlugins
      }
    );
  }

  @action.bound
  async updateSchedule(talk, scheduledTalk) {
    await this.authorization.checkFeatureAccess('ScheduleToolboxTalks');

    if (this.talkType === 'scheduledTalks') {
      await this.checkUserAccessToScheduledTalkProject(scheduledTalk);
    }

    this.talkForSchedule = talk;

    this.initScheduledTalksForm({
      scheduledTalks: [
        {
          uuid: scheduledTalk.uuid,
          projectName: scheduledTalk.project.name,
          scheduleDate: scheduledTalk.scheduleDateFormatted,
          groups: [scheduledTalk.group]
        }
      ]
    });

    this.showModal('companyScheduleTalk');
  }

  @action.bound
  async openCompanyScheduleTalkModal(talk) {
    await this.authorization.checkFeatureAccess('ScheduleToolboxTalks');

    this.talkForSchedule = talk;
    this.initProjectsForScheduleForm();
    this.initScheduledTalksForm();

    this.showModal('companyScheduleTalk');
  }

  checkedInProjectForScheduleForm(uuid) {
    return Boolean(
      this.projectsForScheduleForm
        .$('projects')
        .values()
        .find(selectedProject => uuid === selectedProject.uuid)
    );
  }

  @computed
  get projectForScheduleFormIsInvalid() {
    return Boolean(
      this.projectsForScheduleForm.$('projects').check('hasError') ||
        this.projectsForScheduleForm.$('projects').check('isPristine') ||
        this.projectsForScheduleForm.$('scheduleDate').check('hasError') ||
        this.projectsForScheduleForm.$('scheduleDate').check('isPristine')
    );
  }

  @computed
  get hasProjectsForSchedule() {
    return Boolean(this.scheduledTalksForm.$('scheduledTalks').values().length);
  }

  @action.bound
  async scheduleTalks() {
    const {
      projects,
      groups,
      scheduleDate
    } = this.projectsForScheduleForm.values();

    const newScheduledTalksFormData = projects.map(project => ({
      uuid: project.value,
      projectName: project.name,
      scheduleDate: scheduleDate,
      groups
    }));

    this.scheduledTalksForm.update({
      scheduledTalks: unionWith(
        newScheduledTalksFormData,
        this.scheduledTalksForm.$('scheduledTalks').values(),
        isEqual
      )
    });

    this.projectsForScheduleForm.reset();

    //Workaround. reset() doesn't set the projects field to a default empty array.
    // It resets nested object values insted
    this.projectsForScheduleForm.update({
      projects: [],
      groups: []
    });

    this.projectsForScheduleForm.each(field => {
      field.resetValidation();
    });
  }

  @action.bound
  createOrUpdateSchedule(e) {
    e.preventDefault();
    this.scheduledTalksForm.submit({
      onSuccess: () => {
        this.submitScheduledTalksForm();
      },
      onError: () => {
        console.log(this.scheduledTalksForm.errors());
      }
    });
  }

  @action.bound
  async submitScheduledTalksForm() {
    const formValues = this.scheduledTalksForm.values();
    let method = '';
    let url = '';
    let payload = '';

    if (this.tab === 'all') {
      method = 'post';
      url = `${this.urlMicroService('toolbox')}/tbt/company/project-talks`;
      payload = {
        projects: [],
        talk: {
          uuid: this.talkForSchedule.uuid
        }
      };

      formValues.scheduledTalks.forEach(scheduledTalk => {
        payload.projects.push({
          projectUuid: scheduledTalk.uuid,
          scheduleDate: scheduledTalk.scheduleDate,
          groups: scheduledTalk.groups.map(g => ({ uuid: g.uuid }))
        });
      });
    }

    if (this.tab === 'upcoming') {
      method = 'patch';
      url = `${this.urlMicroService('toolbox')}/tbt/company/project-talks/${
        formValues.scheduledTalks[0].uuid
      }`;

      payload = {
        scheduleDate: formValues.scheduledTalks[0].scheduleDate
      };
    }

    try {
      this.talksAreScheduling = true;
      const scheduleTalk = await request[method](url, payload);

      if (this.tab === 'upcoming') {
        this.allScheduledTalks.updateOrAdd(scheduleTalk.data);
      }

      this.cancelScheduleTalk();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk Successfully Scheduled')
      });

      if (this.tab === 'all') {
        this.reportAnalytics(payload.projects.length);
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.talksAreScheduling = false;
    }
  }

  @action.bound
  handleScheduleBulk() {
    this.initScheduleBulkForm();

    this.scheduleBulks.fetch().then(() => {
      this.showModal('scheduleBulk');
      this.fetchTalksWhole();
    });
  }

  @action.bound
  async initScheduleBulkForm() {
    this.scheduleBulkForm = new ScheduleBulkForm(
      {
        fields: scheduleBulkFormFields,
        rules: scheduleBulkFormRules,
        labels: scheduleBulkFormLabels,
        values: {
          ...scheduleBulkFormValues,
          fromDate: moment().format('YYYY-MM-DD')
        }
      },
      {
        options: scheduleBulkFormOptions,
        plugins: scheduleBulkFormPlugins,
        rootStore: this.rootStore,
        parent: this
      }
    );

    await Promise.all([
      this.scheduleBulkForm.fetchProjectMemberships(),
      this.scheduleBulkForm.fetchGroups()
    ]);

    return this.scheduleBulkForm;
  }

  @computed
  get isExistingScheduleBulk() {
    return Boolean(this.scheduleBulkForm.$('uuid').value);
  }

  @action.bound
  handleScheduleBulkNextToFieldName(uuid) {
    this.handleExistScheduleBulk(uuid);
    this.handleScheduleBulkNext('summary');
  }

  @action.bound
  async handleExistScheduleBulk(uuid) {
    const form = await this.initScheduleBulkForm();
    const bulkSchedule = this.scheduleBulks.get(uuid);

    bulkSchedule.scheduleData.startDate = moment(
      bulkSchedule.scheduleData.startDate
    ).format('YYYY-MM-DD');

    bulkSchedule.scheduleData.finishDate = moment(
      bulkSchedule.scheduleData.finishDate
    ).format('YYYY-MM-DD');

    form.update({
      ...pick(bulkSchedule, ['uuid', 'name', 'projects', 'default']),
      ...pick(bulkSchedule.scheduleData, [
        'startDate',
        'timeFrame',
        'days',
        'type',
        'finishDate',
        'occurrenceCount'
      ]),
      fromDate: moment().format('YYYY-MM-DD')
    });

    form.handleUpdateTalks(bulkSchedule);
    form.handleUpdateSelectedTalks(bulkSchedule);
    form.handleUpdateProjects(bulkSchedule);
    form.handleUpdateGroups(bulkSchedule);
  }

  @action.bound
  handleScheduleBulkNew() {
    this.scheduleBulkForm.update({
      uuid: ''
    });

    this.handleScheduleBulkNext('fieldName');
  }

  @action.bound
  handleScheduleBulkNext(step) {
    const form = this.scheduleBulkForm;

    this.scheduleBulkTabs.push(step);

    if (step === 'createSelectTalks') {
      form.handleUpdateTalks();
    }

    if (step === 'createOrderTalks') {
      form.handleUpdateSelectedTalks();
    }

    if (step === 'createScheduleDetails') {
      form.reAssignOrderNumbersByInputs();
    }

    if (step === 'createSelectProjects') {
      form.handleUpdateProjects();
    }

    if (step === 'createSelectGroups') {
      form.handleUpdateGroups();
    }

    form.update({
      search: ''
    });
  }

  @action.bound
  handleScheduleBulkEdit(step) {
    const form = this.scheduleBulkForm;

    this.saveFormValues = this.scheduleBulkForm.values();

    this.scheduleBulkTabs.push(step);

    form.update({
      search: ''
    });
  }

  @action.bound
  handleSave(key) {
    const form = this.scheduleBulkForm;
    this.saveFormValues = null;
    this.backToPreviousTab();

    if (key === 'editSelectTalks') {
      form.handleUpdateSelectedTalks();
    }

    if (key === 'editOrderTalks') {
      this.scheduleBulkForm.reAssignOrderNumbersByInputs();
    }
  }

  @action.bound
  handleNotSave() {
    this.scheduleBulkForm.update({
      ...this.saveFormValues
    });

    this.backToPreviousTab();
  }

  @action.bound
  backToPreviousTab() {
    if (this.activeTab === 'createOrderTalks') {
      this.scheduleBulkForm.reAssignOrderNumbersByInputs();
    }

    this.scheduleBulkTabs.pop();
  }

  @computed
  get isValidScheduleBulkDetails() {
    const form = this.scheduleBulkForm;

    const timeFrame = form.$('timeFrame');
    const type = form.$('type');
    let isValidDays = false;
    let isValidType = false;

    if (timeFrame.value === 'WEEKLY') {
      isValidDays = Boolean(form.$('days').values().length);
    } else {
      isValidDays = true;
    }

    if (type.value === 'NEVER') {
      isValidType = true;
    }

    if (type.value === 'AFTER_DATE') {
      isValidType = !form.$('finishDate').isEmpty;
    }

    if (type.value === 'AFTER_N_TIMES') {
      isValidType = !form.$('occurrenceCount').check('hasError');
    }

    return Boolean(form.isValid && isValidDays && isValidType);
  }

  @computed
  get activeTab() {
    if (this.scheduleBulkTabs.length === 0) {
      return '';
    }

    return this.scheduleBulkTabs[this.scheduleBulkTabs.length - 1];
  }

  @computed
  get titleBulkSchedule() {
    switch (this.activeTab) {
      case 'createSelectTalks':
      case 'editSelectTalks':
        return t('Select Talks');
      case 'createOrderTalks':
      case 'editOrderTalks':
        return t('Order Talks');
      case 'createScheduleDetails':
      case 'editScheduleDetails':
        return t('Schedule Details');
      case 'createSelectProjects':
      case 'editSelectProjects':
        return t('Select Projects');
      case 'createSelectGroups':
      case 'editSelectGroups':
        return t('Select Groups (optional)');
      case 'summary':
        return t('Summary');
      case 'editFieldName':
        return t('Schedule Name');
      default:
        return '';
    }
  }

  @action.bound
  handleHideScheduleBulkModal() {
    return this.hideActiveModal().then(() => {
      this.scheduleBulkTabs = ['select'];
    });
  }

  @action.bound
  handleResetScheduleBulkForm() {
    this.scheduleBulkForm.reset();
    this.scheduleBulkForm.update({
      selectedTalks: []
    });
  }

  @action.bound
  showSuccessSchedulingNotification(talksNumber) {
    this.notifications.pushNotification({
      showUndo: false,
      title:
        talksNumber > 1
          ? t('Talks Successfully Scheduled')
          : t('Talk Successfully Scheduled')
    });
  }

  @action.bound
  async handleSubmitScheduleBulkForm(defaultBulk = false) {
    const form = this.scheduleBulkForm;
    const {
      name,
      selectedTalks,
      startDate,
      timeFrame,
      days,
      type,
      finishDate,
      occurrenceCount
    } = form.values();

    const payload = {
      name: name,
      talks: selectedTalks.map(talk => ({
        uuid: talk.uuid,
        order: talk.order
      })),
      scheduleData: {
        startDate: moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        timeFrame: timeFrame,
        days: days,
        type: type,
        finishDate:
          finishDate && moment(finishDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        occurrenceCount: occurrenceCount,
        dayOfTheMonth: Number(moment(startDate, 'YYYY-MM-DD').format('D'))
      },
      projectUuids: form.selectedProjects.map(project => project.uuid),
      groupUuids: form.selectedGroups.map(group => group.uuid),
      default: defaultBulk || form.$('default').value
    };

    const scheduleTalksCount =
      payload.projectUuids.length * payload.talks.length;
    const talksNumber = payload.talks.length + payload.projectUuids.length - 1;

    if (!defaultBulk) {
      this.bulkLoading = true;
    }

    let method = '';
    let url = '';

    if (this.isExistingScheduleBulk) {
      method = 'patch';
      url = `${this.rootStore.urlMicroService(
        'toolbox'
      )}/tbt/company/batch-schedule/${form.$('uuid').value}`;
    } else {
      method = 'post';
      url = `${this.rootStore.urlMicroService(
        'toolbox'
      )}/tbt/company/batch-schedule`;
    }

    try {
      const response = await request[method](url, payload);

      const confirmedBulkSchedule = response.data;
      const scheduleBulk = this.scheduleBulks.get(confirmedBulkSchedule.uuid);

      if (scheduleBulk) {
        scheduleBulk.set(confirmedBulkSchedule, {
          reset: true
        });
      }

      this.reportAnalytics(scheduleTalksCount);

      if (!defaultBulk) {
        if (this.tab === 'upcoming') {
          let intervalCount = 0;

          const checkUpcomingInterval = setInterval(() => {
            intervalCount = ++intervalCount;

            method = 'get';
            url = `${this.rootStore.urlMicroService(
              'toolbox'
            )}/tbt/company/batch-schedule`;

            request[method](url).then(response => {
              const newBulkSchedule = response.data.find(
                bulkSchedule => bulkSchedule.uuid === confirmedBulkSchedule.uuid
              );

              /* If for some reasons there is no positive answer ('GENERATED') from the /bulk-schedule endpoint within 1 min (40*1500):
               Do not freeze the screen, close modal, refetch Upcoming talks, because POST request for bulk scheduling is actually succeed.*/

              if (
                intervalCount === 40 ||
                newBulkSchedule.upcomingTalksStatus === 'GENERATED'
              ) {
                clearInterval(checkUpcomingInterval);

                this.fetchAllScheduledTalks()
                  .then(() => {
                    this.handleHideScheduleBulkModal().then(() => {
                      this.bulkLoading = false;
                      this.handleResetScheduleBulkForm();
                      this.showSuccessSchedulingNotification(talksNumber);
                    });
                  })
                  .catch(error => {
                    this.handleHideScheduleBulkModal().then(() => {
                      this.bulkLoading = false;
                      this.handleResetScheduleBulkForm();
                      errorHandler(error, this.notifications.pushError);
                    });
                  });
              }
            });
          }, 1500);
        } else {
          this.handleHideScheduleBulkModal().then(() => {
            this.bulkLoading = false;
            this.handleResetScheduleBulkForm();
            this.showSuccessSchedulingNotification(talksNumber);
          });
        }
      }
    } catch (error) {
      this.bulkLoading = false;
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleChangeBulkDefault(scheduleBulk) {
    if (scheduleBulk.default) return;

    this.scheduleBulks.models.forEach(model => {
      model.default = model.uuid === scheduleBulk.uuid;
    });

    this.handleExistScheduleBulk(scheduleBulk.uuid);
    this.handleSubmitScheduleBulkForm(true);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  sortByColumn(column) {
    if (this.sortField === column) {
      this.sortDirection === 'ASC'
        ? (this.sortDirection = 'DESC')
        : (this.sortDirection = 'ASC');
    } else {
      this.sortField = column;
    }
  }

  @action.bound
  setTalkToEditMode(id) {
    this.talkIdInEditMode = id;
  }

  @action.bound
  selectedScheduledTalkItem(talk) {
    return this.selectedScheduledTalks.find(t => t.id === talk.id);
  }

  @action.bound
  toggleScheduledTalk(scheduledTalk) {
    if (
      this.selectedScheduledTalks
        .map(talk => talk.id)
        .includes(scheduledTalk.id)
    ) {
      this.selectedScheduledTalks.remove(
        this.selectedScheduledTalkItem(scheduledTalk)
      );
    } else {
      this.selectedScheduledTalks.push(scheduledTalk);
    }
  }

  @action.bound
  toggleSelectAllScheduledTalks() {
    if (this.allScheduledTalksSelected) {
      this.selectedScheduledTalks.clear();
      return;
    }

    this.allScheduledTalks.models.forEach(scheduledTalk => {
      if (
        !this.selectedScheduledTalks.map(t => t.id).includes(scheduledTalk.id)
      ) {
        this.selectedScheduledTalks.push(scheduledTalk);
      }
    });
  }

  @computed
  get allScheduledTalksSelected() {
    return (
      this.allScheduledTalks.models.length ===
      this.selectedScheduledTalks.length
    );
  }

  @action.bound
  softDeleteBulkSchedule(id, editMode = false) {
    const bulkSchedule = this.scheduleBulks.get(id);

    const originalIndex = this.talks.models.indexOf(bulkSchedule);

    this.scheduleBulks.remove(bulkSchedule);

    if (editMode) {
      this.handleHideScheduleBulkModal();
    }

    this.notifications.pushNotification({
      onUndo: () => {
        this.cancelDeleteBulkSchedule(originalIndex, bulkSchedule);
      },
      onDismiss: () => {
        this.confirmDeleteBulkSchedule(bulkSchedule);
      },
      title: t('Schedule Successfully Deleted')
    });
  }

  @action.bound
  cancelDeleteBulkSchedule(originalIndex, bulkSchedule) {
    this.scheduleBulks.add(bulkSchedule, {
      at: originalIndex
    });
  }

  @action.bound
  async confirmDeleteBulkSchedule(bulkSchedule) {
    bulkSchedule
      .destroy({
        wait: true
      })
      .then(() => {
        if (this.tab === 'upcoming') {
          this.fetchAllScheduledTalks();
        }
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  reportAnalytics(scheduleTalksCount) {
    const projectId = null;
    const projectName = null;
    const projectLevel = false;

    callTrack(TBT_SCHEDULED, {
      project_id: projectId,
      project_name: projectName,
      company_level: this.companyLevel,
      project_level: projectLevel,
      members_count: this.subscription.seatsInUse,
      count: scheduleTalksCount
    });
  }

  @computed
  get requestUpgradeSent() {
    /* Access to a plan can only be requested once per user every 60 days */
    if (!this.rootStore.me.settings.requestUpgradeSafetySent) return false;
    const lastRequestSentTimePlus60Days = moment
      .utc(this.rootStore.me.settings.requestUpgradeSafetySent)
      .add(60, 'days');

    return lastRequestSentTimePlus60Days.isAfter(moment.utc());
  }

  @computed get fetchingTalks() {
    return this.talks.fetching || this.allScheduledTalks.fetching;
  }

  @computed get hasTalks() {
    if (this.tab === 'all') {
      return this.talks.hasTalks;
    }

    return this.allScheduledTalks.hasScheduledTalks;
  }

  @computed get showEmptyState() {
    return !this.hasTalks && !this.fetchingTalks && !this.hasSearchQuery;
  }

  @computed get showEmptySearchState() {
    return !this.fetchingTalks && !this.hasTalks && !this.searchMode;
  }

  @action.bound showAttendeeListModal(talk) {
    this.activeTalk = talk;
    this.showModal('attendee-list');
  }

  @action.bound hideAttendeeListModal() {
    this.hideActiveModal().then(() => {
      this.activeTalk = null;
    });
  }

  @action.bound async addToolboxTalk() {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    history.push({
      pathname: `/company/toolbox-talks/all/add`
    });
  }

  @action.bound cancelToolboxTalksAdd() {
    history.push({
      pathname: `/company/toolbox-talks/all`
    });
  }

  @action.bound
  async tearDown() {
    this.fetchTalks();
    this.talksForUploading.clear();
    this.talksForReplacing.clear();
    this.groupSelectorUI.tearDown();
  }

  @action.bound
  handleTalkUploading = fileObj => {
    const { file } = fileObj;

    //file size validation
    if (file.size > 15728640) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 15MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });
      return;
    }

    //check if a talk file exists
    this.talks
      .checkIfTalkExists(file.name)
      .then(() => {
        this.showReplaceAlert(file);
      })
      .catch(() => {
        this.talksForUploading.push(file);
      });
  };

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();
    this.rootStore.notificationsUI.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound
  showReplaceAlert(file) {
    this.talksForReplacing.push(file);
  }

  @action.bound
  confirmFileReplace(file) {
    this.talksForReplacing.remove(file);
    this.talksForUploading.push(file);
  }

  @computed
  get hasTalksForUploading() {
    return this.talksForUploading.length > 0;
  }

  @action.bound
  cancelFileReplace(file) {
    this.talksForReplacing.remove(file);
  }

  @computed
  get scheduleTalkTitle() {
    const title = this.tab === 'upcoming' ? t('Edit Talk') : t('Schedule Talk');
    return `${title}: ${this.talkForSchedule.name}`;
  }

  @computed
  get scheduleSubmitText() {
    if (this.talksAreScheduling) return t('Scheduling...');
    return this.tab === 'all' ? t('Schedule') : t('Save');
  }

  @computed
  get disableScheduleSubmitButton() {
    return (
      this.talksAreScheduling ||
      this.scheduledTalksForm.isPristine ||
      !this.hasProjectsForSchedule
    );
  }

  @action.bound
  async cancelScheduleTalk() {
    await this.hideActiveModal();

    if (this.scheduledTalksForm) {
      this.scheduledTalksForm = null;
    }

    if (this.projectsForScheduleForm) {
      this.scheduledTalksForm = null;
    }

    this.talkForSchedule = null;
    this.talksAreScheduling = false;
  }

  @computed
  get minimumScheduleDate() {
    return moment().format('YYYY-MM-DD');
  }

  @action.bound async renameTalk(talk) {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    this.talkForRename = talk;

    this.renameTalkForm = new RenameTalkForm(
      {
        fields: renameTalkFormFields,
        rules: renameTalkFormRules,
        labels: renameTalkFormLabels,
        values: talk.formValues
      },
      {
        options: renameTalkFormOptions,
        plugins: renameTalkFormPlugins
      }
    );

    this.showModal('RenameModal');
  }

  @action.bound submitRenameTalkForm(event) {
    event.preventDefault();

    this.renameTalkForm.submit({
      onSuccess: this.submitRenameTalkFormSuccess,
      onError: this.submitRenameTalkFormError
    });
  }

  @action.bound async submitRenameTalkFormSuccess() {
    this.saving = true;

    try {
      const values = this.renameTalkForm.values();

      await this.talkForRename.save(
        {
          name: values.name
        },
        {
          wait: true
        }
      );

      this.hideActiveModal();

      this.talkForRename = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk renamed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitRenameTalkFormError() {
    console.error(this.renameTalkForm.errors());
  }

  @action.bound async cancelRenameTalk() {
    await this.hideActiveModal();

    this.renameTalkForm = null;
    this.talkForRename = null;
  }

  @computed get renameTalkTitle() {
    return t('Rename talk');
  }

  @computed get renameSubmitText() {
    if (this.saving) {
      return t('Saving');
    }

    return t('Rename');
  }

  @computed get selectedGroupUuids() {
    return this.projectsForScheduleForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentBulkDeploymentFormPlugins = extendedPlugins;

const smallEquipmentBulkDeploymentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentBulkDeploymentFormFields = [
  'project',
  'deployments[].uuid',
  'deployments[].name',
  'deployments[].totalUnits',
  'deployments[].availableUnits',
  'deployments[].numberOfUnits'
];

const smallEquipmentBulkDeploymentFormLabels = {
  project: t('deploy to'),
  'deployments[].numberOfUnits': t('deploy units')
};

const smallEquipmentBulkDeploymentFormValues = {
  project: '',
  'deployments[].uuid': '',
  'deployments[].name': '',
  'deployments[].totalUnits': '',
  'deployments[].availableUnits': '',
  'deployments[].numberOfUnits': ''
};

const smallEquipmentBulkDeploymentFormRules = {
  project: 'required',
  'deployments[].numberOfUnits': 'numeric|min:0'
};

class SmallEquipmentBulkDeploymentForm extends MobxReactForm {}

export {
  SmallEquipmentBulkDeploymentForm,
  smallEquipmentBulkDeploymentFormOptions,
  smallEquipmentBulkDeploymentFormFields,
  smallEquipmentBulkDeploymentFormRules,
  smallEquipmentBulkDeploymentFormValues,
  smallEquipmentBulkDeploymentFormLabels,
  smallEquipmentBulkDeploymentFormPlugins
};

import moment from 'moment';
import { action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const projectsForScheduleFormPlugins = extendedPlugins;

const projectsForScheduleFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  showErrorsOnReset: false
};

const projectsForScheduleFormFields = [
  'projects[]',
  'projects[].name',
  'projects[].value',
  'scheduleDate',
  'groups[]',
  'groups[].name',
  'groups[].uuid'
];

const projectsForScheduleFormRules = {
  'projects[]': 'required',
  scheduleDate: 'string|required|afterOrTodayDate:YYYY-MM-DD',
  groups: 'array',
  'groups[].name': 'string',
  'groups[].uuid': 'string'
};

const projectsForScheduleFormValues = {
  projects: [],
  scheduleDate: ''
};

class ProjectsForScheduleForm extends MobxReactForm {
  @action.bound
  selectProjects(selectedProjects) {
    this.update({
      projects: selectedProjects
    });
  }

  @action.bound
  selectDate(date) {
    this.$('scheduleDate').set(moment(date).format('YYYY-MM-DD'));
  }
}

export {
  ProjectsForScheduleForm,
  projectsForScheduleFormOptions,
  projectsForScheduleFormFields,
  projectsForScheduleFormRules,
  projectsForScheduleFormPlugins,
  projectsForScheduleFormValues
};

import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

export default class EquipmentMaintenanceSchedule extends Model {
  get restAttributes() {
    return [
      'type',
      'name',
      'step',
      'measurementType',
      'startingValue',
      'nextMilestone',
      'uuid'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get formValues() {
    return {
      type: this.type,
      name: this.name,
      step: this.step,
      measurementType: this.measurementType,
      startingValue: this.startingValue
    };
  }

  @computed get formattedMeasurementType() {
    return this.collection.parent.measurementTypeOptions.find(
      option => option.value === this.measurementType
    )?.title;
  }

  @computed get formattedMaintenanceInterval() {
    if (this.type === 'CUMULATIVE') {
      return `${t('Every')} ${Number(this.step).toLocaleString()} ${
        this.formattedMeasurementType
      }`;
    } else {
      return `${t('At')} ${Number(this.step).toLocaleString()} ${
        this.formattedMeasurementType
      }`;
    }
  }

  @computed get formattedStartingValue() {
    if (!this.startingValue) {
      return `0 ${this.formattedMeasurementType}`;
    } else {
      return `${Number(this.startingValue).toLocaleString()} ${
        this.formattedMeasurementType
      }`;
    }
  }

  @computed get formattedNextMilestone() {
    return `${Number(this.nextMilestone).toLocaleString()} ${
      this.formattedMeasurementType
    }`;
  }
}

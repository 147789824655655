import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable } from 'mobx';

import {
  RoundingForm,
  RoundingFormFields,
  RoundingFormRules,
  RoundingFormOptions,
  RoundingFormPlugins
} from 'forms/rounding';

import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';

export default class PolicyRoundingUI extends PolicyChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    this.initRoundingForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound
  initRoundingForm() {
    this.form = new RoundingForm(
      {
        fields: RoundingFormFields,
        rules: RoundingFormRules,
        values: this.parent.entryForEdit?.roundingRule
      },
      {
        options: RoundingFormOptions,
        plugins: RoundingFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.form = null;
  }

  @computed get clockInOutRoundingOptions() {
    return [
      { title: t('None'), value: 'NONE' },
      { title: t('Nearest interval'), value: 'NEAREST_INTERVAL' },
      { title: t('Round up'), value: 'ROUND_UP' },
      { title: t('Round down'), value: 'ROUND_DOWN' },
      { title: t('Employee favorable'), value: 'EMPLOYEE_FAVORABLE' }
    ];
  }

  @computed get timeCardRoundingModeValue() {
    return this.clockInOutRoundingOptions.find(
      option => option.value === this.form?.$('timeCardRoundingMode').value
    );
  }

  @computed get timeCardRoundingIntervalValue() {
    return this.roundingIntervalOptions.find(
      option => option.value === this.form?.$('timeCardRoundingInterval').value
    );
  }

  @computed get roundingIntervalOptions() {
    if (this.form?.$('timeCardRoundingMode').value === 'NONE') {
      return [{ title: t('None'), value: 0 }];
    } else {
      return [
        { title: t('5 minutes'), value: 5 },
        { title: t('6 minutes'), value: 6 },
        { title: t('10 minutes'), value: 10 },
        { title: t('15 minutes'), value: 15 },
        { title: t('30 minutes'), value: 30 }
      ];
    }
  }

  @action.bound setTimeCardRoundingMode(option) {
    const initialFormValue = this.form.$('timeCardRoundingMode').value;
    this.form.$('timeCardRoundingMode').set(option.value);

    if (option.value === 'NONE' || initialFormValue === 'NONE') {
      this.form
        .$('timeCardRoundingInterval')
        .set(this.roundingIntervalOptions[0].value);
    }

    if (option.value === 'EMPLOYEE_FAVORABLE') {
      this.form.$('breakRounding').set(false);
    }
  }

  @action.bound submitRoundingForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitRoundingFormSuccess,
      onError: this.submitRoundingFormError
    });
  }

  @action.bound submitRoundingFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitRoundingFormSuccess() {
    const values = this.form.values();

    try {
      await this.entryForEdit.save(
        { roundingRule: values },
        {
          wait: true
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.parent.parent.refetchPolicies();
    this.initRoundingForm();

    this.moveToNextStepIfSettingUpDefault('Rounding');

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Rounding Rules Updated ')}`
    });
  }

  @computed get disableSaveButton() {
    return this.entryForEdit.saving;
  }
}

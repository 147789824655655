import UIStore from 'stores/ui/UIStore';
import { action, computed, observable, runInAction, reaction } from 'mobx';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import errorHandler from 'utils/errorHandler';
import Checklists from 'stores/collections/checklists/Checklists';
import {
  EmailChecklistForm,
  emailChecklistFormOptions,
  emailChecklistFormFields,
  emailChecklistFormRules,
  emailChecklistFormLabels,
  emailChecklistFormPlugins
} from 'forms/emailChecklist';
import ChecklistBatchExportUI from './ChecklistBatchExportUI';
import request from 'axios';
import { t } from 'utils/translate';
import downloadURI from 'utils/downloadURI';
import download from 'utils/download';

import { callTrack } from 'utils/segmentIntegration';
import {
  CHECKLIST_PDF_DOWNLOADED,
  CHECKLIST_SHARED
} from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistCompletedUI extends UIStore {
  @observable searchQuery;
  @observable page;
  @observable offset;
  @observable limit;
  @observable sortField;
  @observable sortDirection;
  @observable status;
  @observable shareCompletedChecklistForm;
  @observable selectedChecklist;
  @observable loading;

  constructor(options) {
    super(options);

    // Pagination
    this.page = 1;
    this.pageSize = 20;
    this.sortField = 'completedTimestamp';
    this.sortDirection = 'desc';
    this.status = 'ACTIVE';
    this.searchQuery = '';

    this.checklistToDelete = null;
    this.completedChecklistToShare = null;
    this.shareCompletedChecklistForm = null;
    this.searchMode = false;
    this.selectedChecklist = null;
    this.loading = true;

    this.checklists = new Checklists(null, {
      rootStore: this.rootStore
    });

    this.checklistBatchExportUI = new ChecklistBatchExportUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed
  get requestParams() {
    return {
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize,
      status: this.status,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      excludeSignature: true
    };
  }

  @action.bound
  resetParamsToDefault() {
    this.page = 1;
    this.pageSize = 20;
    this.sortField = 'completedTimestamp';
    this.sortDirection = 'desc';
    this.status = 'ACTIVE';
    this.searchQuery = '';
    this.searchMode = false;
  }

  @action.bound resetLoadingState() {
    this.loading = true;
  }

  @action.bound
  async fetchChecklists() {
    this.loading = true;

    const fetchParams = pickBy(
      { ...this.requestParams, query: this.searchQuery },
      identity
    );

    await this.checklists.fetch({
      params: fetchParams
    });

    this.loading = false;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklists.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;
  }

  setupParamsReaction() {
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }
    this.cancelParamsReaction = reaction(
      () => this.requestParams,
      requestParams => {
        this.fetchChecklists().catch(error =>
          errorHandler(error, this.notifications.pushError)
        );
      }
    );
  }

  setupSearchReaction() {
    this.cancelSearchReaction = reaction(
      () => this.searchQuery,
      query => {
        if (this.cancelParamsReaction) {
          this.cancelParamsReaction();
        }

        this.page = 1;
        this.searchMode = Boolean(query);

        this.fetchChecklists()
          .then(() => {
            this.setupParamsReaction();
          })
          .catch(error => errorHandler(error, this.notifications.pushError));
      },
      { delay: 1500 }
    );
  }

  cancelAllReactions() {
    if (this.cancelSearchReaction) {
      this.cancelSearchReaction();
    }
    if (this.cancelParamsReaction) {
      this.cancelParamsReaction();
    }
  }

  setupAllReactions() {
    this.setupParamsReaction();
    this.setupSearchReaction();
  }

  @action.bound
  clearSearchQuery() {
    this.cancelAllReactions();
    this.resetParamsToDefault();

    this.fetchChecklists()
      .then(() => {
        this.setupAllReactions();
      })
      .catch(error => errorHandler(error, this.notifications.pushError));
  }

  @computed
  get completedChecklists() {
    return this.checklists.models;
  }

  @computed
  get hasCompletedChecklists() {
    return this.checklists.hasModels;
  }

  @computed
  get hasCompletedTotalElements() {
    return this.checklists.totalElements;
  }

  @computed
  get fetchingChecklists() {
    return this.checklists.fetching;
  }

  @action.bound
  sortByColumn(name) {
    if (name === this.sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortDirection = 'asc';
    }
    this.sortField = name;
    this.page = 1;
  }

  @action.bound
  openShareCompletedChecklistModal(checklist) {
    runInAction(() => {
      this.completedChecklistUuidToShare = checklist.uuid;
      this.shareCompletedChecklistForm = new EmailChecklistForm(
        {
          fields: emailChecklistFormFields,
          rules: emailChecklistFormRules,
          labels: emailChecklistFormLabels,
          values: {
            emails: []
          }
        },
        {
          options: emailChecklistFormOptions,
          plugins: emailChecklistFormPlugins
        }
      );
      this.showModal('shareCompletedChecklist');
    });
  }

  @action.bound
  cancelShareCompletedChecklist() {
    this.hideActiveModal().then(() => {
      this.completedChecklistUuidToShare = null;
      this.shareCompletedChecklistForm = null;
    });
  }

  @action.bound
  shareCompletedPDF(checklist) {
    const payload = {
      emails: this.shareCompletedChecklistForm.$('emails').value,
      locale: this.me.settings.languageCode || 'en-US'
    };

    request
      .post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.company.uuid
        }/checklists/${this.completedChecklistUuidToShare}/email`,
        payload
      )
      .then(() => {
        this.cancelShareCompletedChecklist();
        this.rootStore.notificationsUI.pushNotification({
          snackbar: 'warning',
          icon: 'notification',
          title: t('Completed Checklist Sent')
        });
        callTrack(CHECKLIST_SHARED);
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  @action.bound
  async softDeleteCompletedChecklist(checklist) {
    await this.authorization.checkFeatureAccess('CUDChecklists');

    checklist
      ? (this.checklistToDelete = checklist)
      : (this.checklistToDelete = this.selectedChecklist);

    this.showModal('deleteCompletedChecklist');
  }

  @action.bound
  confirmDeleteCompletedChecklist() {
    this.checklistToDelete
      .destroy({ wait: true })
      .then(() => {
        this.hideActiveModal().then(() => {
          this.checklistToDelete = null;
          if (!this.hasCompletedChecklists && this.page !== 1) {
            this.page = this.totalPages - 1;
          } else {
            this.fetchChecklists();
          }
          if (
            this.checklistProjectViewerUI.activeModalPage ===
            'checklistViewerPage'
          ) {
            this.checklistProjectViewerUI.closeChecklistProjectViewer();
          }
        });
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  cancelDeleteCompletedChecklist() {
    this.hideActiveModal().then(() => {
      this.checklistToDelete = null;
    });
  }

  @computed
  get emailListIsValid() {
    return this.shareCompletedChecklistForm?.isValid;
  }

  @action.bound
  downloadChecklistPdf = checklist => {
    if (checklist.hasPdf) {
      downloadURI(checklist.pdfUrl, null, true);
    } else {
      download({
        store: this.rootStore,
        url: `${this.rootStore.appConfig.safety_api_url}/companies/${checklist.companyUuid}/checklists/${checklist.uuid}/pdf`,
        xhttpOptions: {
          sendXApiKey: true
        },
        method: 'GET',
        fileUrlAttr: 'pdfUrl'
      });
    }

    callTrack(CHECKLIST_PDF_DOWNLOADED, {
      project_id: checklist.projectUuid,
      checklist_name: checklist.name
    });
  };

  @action.bound async showBatchExportModal() {
    await this.authorization.checkFeatureAccess('CUDChecklists');
    this.checklistBatchExportUI.showBatchExportModal();
  }
}

import MobxReactForm from 'mobx-react-form';
import { computed } from 'mobx';
import extendedPlugins from '../utils/extendedPlugins';
import trimObject from '../../utils/trimObject';

const settingsTimeCaptureFormPlugins = extendedPlugins;

const settingsTimeCaptureFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const settingsTimeCaptureFormFields = [
  'enableKiosk',
  'enableTimeClock',
  'enableTaskSwitching',
  'enableCrewGrouping',
  'enableSegments',
  'timeCardsStartAndEndTime',
  'requirePhotoId',
  'requirePhotoIdOnClockInOutAndBreaks',
  'clockInChecklistTemplateUuid',
  'clockOutChecklistTemplateUuid'
];

const settingsTimeCaptureFormRules = {
  enableKiosk: 'boolean',
  enableTimeClock: 'boolean',
  enableTaskSwitching: 'boolean',
  enableCrewGrouping: 'boolean',
  enableSegments: 'boolean',
  timeCardsStartAndEndTime: 'boolean',
  requirePhotoId: 'boolean',
  requirePhotoIdOnClockInOutAndBreaks:
    'string|required|in:NOT_REQUIRED,REQUIRED_ON_CLOCK_IN_AND_OUT,REQUIRED_ON_CLOCK_IN_AND_OUT_AND_BREAKS',
  clockInChecklistTemplateUuid: 'string',
  clockOutChecklistTemplateUuid: 'string'
};

const settingsTimeCaptureFormDisabled = {};

const settingsTimeCaptureFormLabels = {};

const settingsTimeCaptureFormValues = {
  enableKiosk: false,
  enableTimeClock: false,
  enableTaskSwitching: true,
  enableCrewGrouping: true,
  requirePhotoId: false,
  requirePhotoIdOnClockInOutAndBreaks: 'NOT_REQUIRED',
  clockInChecklistTemplateUuid: '',
  clockOutChecklistTemplateUuid: ''
};

class SettingsTimeCaptureForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }

  @computed
  get timeCaptureEnabled() {
    return this.$('timeCardsStartAndEndTime').value;
  }
}

export {
  SettingsTimeCaptureForm,
  settingsTimeCaptureFormOptions,
  settingsTimeCaptureFormFields,
  settingsTimeCaptureFormRules,
  settingsTimeCaptureFormValues,
  settingsTimeCaptureFormLabels,
  settingsTimeCaptureFormPlugins,
  settingsTimeCaptureFormDisabled
};

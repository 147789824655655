import { action, computed } from 'mobx';

import history from 'utils/history';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import SmallEquipmentAddUI from 'stores/ui/company/equipment/smallEquipment/SmallEquipmentAddUI';

export default class ProjectSmallEquipmentAddUI extends SmallEquipmentAddUI {
  constructor(options) {
    super(options);
  }

  @action.bound
  async submitSmallEquipmentAddFormSuccess() {
    this.saving = true;

    try {
      await this.entryForAdd.save(this.form.values());

      // Add the new contact at the end
      this.parent.smallEquipmentActionsUI.smallEquipmentBulkDeploymentForm
        .$('deployments')
        .add({
          uuid: this.entryForAdd.uuid,
          name: this.entryForAdd.name,
          totalUnits: this.entryForAdd.totalUnits,
          availableUnits: this.entryForAdd.availableUnits,
          numberOfUnits: 0
        });

      const currentDeployments = this.parent.smallEquipmentActionsUI.smallEquipmentBulkDeploymentForm
        .$('deployments')
        .values();
      const newFirstDeployment = currentDeployments.pop(); // Remove the last added contact
      currentDeployments.unshift(newFirstDeployment); // Add it to the beginning

      // Update the field with the reordered array
      this.parent.smallEquipmentActionsUI.smallEquipmentBulkDeploymentForm
        .$('deployments')
        .set(currentDeployments);

      this.smallEquipmentActionsUI.setSmallEquipmentBulkDeploymentValidation();

      this.onClose();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Small equipment added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound onClose() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/small-equipment`
    });
  }

  @computed get smallEquipmentActionsUI() {
    return this.parent.smallEquipmentActionsUI;
  }

  @action.bound async deploySmallEquipment() {
    this.smallEquipmentActionsUI.bulkDeployAllSmallEquipment();
  }
}

import { observer } from 'mobx-react';
import React from 'react';

import { Form } from 'raken-ui';

import { Button, TextField } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const AppConnectFormComputerEaseDirectAPI = observer(({ uiStore }) => {
  return (
    <Form data-qa="form_app-connect" onSubmit={uiStore.submitActiveForm}>
      <TextField
        placeholder={t('Refresh token')}
        dataQA="refreshToken"
        value={uiStore.activeForm.$('refreshToken').value}
        onChange={uiStore.activeForm.$('refreshToken').sync}
        error={uiStore.activeForm.$('refreshToken').error}
        helperText={uiStore.activeForm.$('refreshToken').error}
        fullWidth
        autoFocus
      />

      <Button disabled={uiStore.saving} type="submit" fullWidth dataQA="submit">
        {uiStore.saving ? t('Signing in') : t('Sign in')}
      </Button>
    </Form>
  );
});

export default AppConnectFormComputerEaseDirectAPI;

import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class ComputerEaseDirectAPIJobMatch extends Model {
  get restAttributes() {
    return ['raken', 'ce', 'message'];
  }

  @computed get patchValues() {
    return {
      uuid: this.raken.uuid,
      externalId: this.ce.id
    };
  }
}

import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';
import ObservationTypes from 'stores/collections/ObservationTypes';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import alertErrorHandler from 'utils/alertErrorHandler';
import uniqBy from 'lodash.uniqby';
import { t } from 'utils/translate';
import history from 'utils/history';
import {
  ObservationTypesFiltersForm,
  observationTypesFiltersFormRules,
  observationTypesFiltersFormFields,
  observationTypesFiltersFormOptions,
  observationTypesFiltersFormPlugins
} from 'forms/observationTypesFilters';
import ObservationTypeAddUI from './ObservationTypeAddUI';
import ObservationTypeEditUI from './ObservationTypeEditUI';
import { callTrack } from 'utils/segmentIntegration';
import { OBSERVATION_TYPE_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class ObservationTypesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable selectedObservationType;
  @observable observationTypesFiltersForm;
  @observable classOptions;
  @observable typeOptions;

  constructor(options) {
    super(options);
    this.selectedObservationType = null;
    this.loading = true;
    this.sortField = null;
    this.sortDirection = null;
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;
    this.observationTypesFiltersForm = null;
    this.typeFilters = observable([]);
    this.typeClassFilters = observable([]);

    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchObservationsDebounced = debounce(
      this.fetchObservations,
      BASE_DEBOUNCE
    );

    this.form = null;
    this.saving = false;

    this.observationTypeAddUI = new ObservationTypeAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.observationTypeEditUI = new ObservationTypeEditUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchObservations();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchObservationsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async setInitialOptions() {
    const observationTypesOptions = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    await observationTypesOptions.fetch({
      params: this.params
    });

    const classList = observationTypesOptions.models.map(type => {
      return {
        value: type.typeClass,
        title: type.typeClass
      };
    });

    const typeList = observationTypesOptions.models.map(type => {
      return {
        value: type.type,
        title: type.type
      };
    });
    this.classOptions = uniqBy(classList, 'value');
    this.typeOptions = uniqBy(typeList, 'value');
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField ? [this.sortField] : null,
      sortDirection: this.sortDirection ? this.sortDirection : null,
      typeNames: this.typeFilters.map(typeFilter => typeFilter.value),
      classes: this.typeClassFilters.map(typeClass => typeClass.value)
    };
  }

  @action.bound async fetchObservations() {
    this.observationTypes.cancelRequest();
    this.observationTypes.reset();
    try {
      await this.observationTypes.fetchWithPost({
        url: `${this.observationTypes.url()}/search`,
        params: this.params
      });

      if (!this.classOptions || !this.typeOptions) {
        await this.setInitialOptions();
      }
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasObservations() {
    return this.observationTypes.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.observationTypes.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.observationTypes.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'type';
    this.sortDirection = 'asc';
    this.observationTypesFiltersForm = null;
    this.clearObservationTypesFilters();
    this.selectedObservationType = null;
    this.classOptions = null;
    this.typeOptions = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasObservations;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasObservations;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addObservationType() {
    await this.authorization.checkFeatureAccess('CUDCompanyObservationTypes');

    history.push({
      pathname: `/company/observations/types/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async addObservationSubtype() {
    await this.authorization.checkFeatureAccess('CUDCompanyObservationTypes');

    history.push({
      pathname: `/company/observations/types/add-sub-type`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editObservationType(observationType) {
    await this.authorization.checkFeatureAccess('CUDCompanyObservationTypes');
    history.push({
      pathname: `/company/observations/types/${observationType.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteObservationType(observationType) {
    await this.authorization.checkFeatureAccess('CUDCompanyObservationTypes');

    this.selectedObservationType = observationType;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteObservationType() {
    await this.hideActiveModal();
    this.selectedObservationType = null;
  }

  @action.bound async confirmDeleteObservationType() {
    this.saving = true;

    try {
      await this.selectedObservationType.destroy({ wait: true });
      this.refetchObservations();
      await this.hideActiveModal();
      this.selectedObservationType = null;
      callTrack(OBSERVATION_TYPE_DELETED);

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Observation type deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchObservationsDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'modifyDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchObservationsDebounced();
  }

  @action.bound refetchObservations() {
    this.loading = true;
    if (!this.hasObservations) {
      this.setPage(null, 1);
      this.fetchObservations();
    } else {
      this.fetchObservations();
    }
  }

  @action.bound showFilters() {
    this.showModal('TypesFiltersDialog');
    this.setupObservationTypesFiltersForm();
  }

  @action.bound async hideObservationTypesFiltersDialog() {
    await this.hideActiveModal();
    this.observationTypesFiltersForm = null;
  }

  @action.bound clearObservationTypesFilters() {
    this.hideObservationTypesFiltersDialog();
    this.typeClassFilters.clear();
    this.typeFilters.clear();
  }

  @action.bound setupObservationTypesFiltersForm() {
    this.observationTypesFiltersForm = new ObservationTypesFiltersForm(
      {
        fields: observationTypesFiltersFormFields,
        rules: observationTypesFiltersFormRules,
        values: {
          typeClassFilters: this.typeClassFilters.slice(),
          typeFilters: this.typeFilters.slice()
        }
      },
      {
        options: observationTypesFiltersFormOptions,
        plugins: observationTypesFiltersFormPlugins
      }
    );
  }

  @action.bound submitObservationTypesFiltersForm(event) {
    event.preventDefault();

    this.observationTypesFiltersForm.submit({
      onSuccess: this.submitObservationTypesFiltersFormSuccess,
      onError: this.submitObservationTypesFiltersFormError
    });
  }

  @action.bound submitObservationTypesFiltersFormSuccess() {
    const values = this.observationTypesFiltersForm.values();

    this.typeClassFilters.replace(values.typeClassFilters);
    this.typeFilters.replace(values.typeFilters);

    this.hideObservationTypesFiltersDialog();
  }

  @action.bound submiObservationTypesFiltersFormError() {
    console.error(this.observationTypesFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.typeFilters.length) {
      counter += 1;
    }

    if (this.typeClassFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCreateObservations;
  }

  getObservationTypeActions = type => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editObservationType(type);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteObservationType(type);
        }
      }
    ];
  };

  @computed get addNewActions() {
    return [
      {
        title: t('Type'),
        onClick: () => {
          this.addObservationType();
        }
      },
      {
        title: t('Subtype'),
        onClick: () => {
          this.addObservationSubtype();
        }
      }
    ];
  }
}

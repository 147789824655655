import { observable, action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import {
  SmallEquipmentForm,
  smallEquipmentFormLabels,
  smallEquipmentFormValues,
  smallEquipmentFormRules,
  smallEquipmentFormFields,
  smallEquipmentFormOptions,
  smallEquipmentFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipment';

import SmallEquipment from 'stores/models/equipment/SmallEquipment';
import history from 'utils/history';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { SMALL_EQUIPMENT_CREATED } from 'utils/segmentAnalytics/eventSpec';

export default class SmallEquipmentAddUI extends UIStore {
  @observable entryForAdd;
  @observable form;
  @observable saving;

  constructor(options) {
    super(options);

    this.entryForAdd = null;
    this.form = null;
    this.saving = false;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new SmallEquipment(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.form = new SmallEquipmentForm(
      {
        fields: smallEquipmentFormFields,
        rules: smallEquipmentFormRules,
        labels: smallEquipmentFormLabels,
        values: {
          ...smallEquipmentFormValues,
          companyUuid: this.rootStore.me.company.uuid
        }
      },
      {
        options: smallEquipmentFormOptions,
        plugins: smallEquipmentFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action clearUIState() {
    this.entryForAdd = null;
    this.form = null;
    this.nextUrl = null;
    this.saving = false;
    this.clearValidationDetails();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.form.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound onClose() {
    history.push({
      pathname: `/company/equipment/small-equipment`
    });
  }

  @action.bound
  async submitSmallEquipmentForm(e) {
    e.preventDefault();
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'CRUDOwnedEquipment'
    );

    this.saving = true;
    this.form.submit({
      onSuccess: this.submitSmallEquipmentAddFormSuccess,
      onError: e => {
        this.saving = false;
        console.error(this.form.errors());
      }
    });
  }

  @action.bound
  async submitSmallEquipmentAddFormSuccess() {
    const values = this.form.values();
    try {
      await this.entryForAdd.save(values);

      this.parent.sortByLastCreated();
      this.onClose();

      callTrack(SMALL_EQUIPMENT_CREATED, {
        small_equipment_name: values.name,
        units: values.quantity
      });

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Small equipment added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }
}

import { computed, action, observable, reaction, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import request from 'axios';

import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import history from 'utils/history';

import {
  SmallEquipmentProjectsForm,
  smallEquipmentProjectsFormOptions,
  smallEquipmentProjectsFormFields,
  smallEquipmentProjectsFormRules,
  smallEquipmentProjectsFormLabels,
  smallEquipmentProjectsFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentProjects';

import {
  SmallEquipmentProjectsFiltersForm,
  smallEquipmentProjectsFiltersFormOptions,
  smallEquipmentProjectsFiltersFormFields,
  smallEquipmentProjectsFiltersFormRules,
  smallEquipmentProjectsFiltersFormLabels,
  smallEquipmentProjectsFiltersFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentProjectsFilters';

import SmallEquipmentProjects from 'stores/collections/equipment/SmallEquipmentProjects';

import { t } from 'utils/translate';

export default class SmallEquipmentProjectsUI extends UIStore {
  @observable smallEquipmentProjectsForm;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable showFiltersModal;
  @observable smallEquipmentProjectsFiltersForm;

  constructor(options) {
    super(options);

    this.smallEquipmentProjectsForm = null;

    this.loading = true;
    this.saving = false;

    this.sortField = 'projectName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.showFiltersModal = false;
    this.smallEquipmentProjectsFiltersForm = null;
    this.groupFilters = observable([]);

    // Equipment Projects collection
    this.smallEquipmentProjects = new SmallEquipmentProjects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSmallEquipmentProjectsDebounced = debounce(
      this.fetchSmallEquipmentProjects,
      BASE_DEBOUNCE
    );
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      projectGroupUuids: this.groupFilters.map(group => group.uuid).join(',')
    };
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async setup() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');
    await this.fetchSmallEquipmentProjects();

    this.setupReactions();
  }

  @action.bound async tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound setupForm() {
    this.smallEquipmentProjectsForm = new SmallEquipmentProjectsForm(
      {
        fields: smallEquipmentProjectsFormFields,
        rules: smallEquipmentProjectsFormRules,
        labels: smallEquipmentProjectsFormLabels,
        values: {
          projects: this.smallEquipmentProjects.models?.map(value => {
            return { ...value.formValues, unitsDeployed: 0 };
          })
        }
      },
      {
        options: smallEquipmentProjectsFormOptions,
        plugins: smallEquipmentProjectsFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentProjectsForm
      .$('projects')
      .fields.forEach(project =>
        project
          .$('projectDeployedUnits')
          .set(
            'rules',
            `numeric|min:0|max:${Number(this.entryForEdit.availableUnits) +
              project.$('projectDeployedUnits').value}`
          )
      );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.smallEquipmentProjectsForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.setupForm();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchSmallEquipmentProjectsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound async fetchSmallEquipmentProjects() {
    this.loading = true;
    this.smallEquipmentProjectsForm = null;

    this.smallEquipmentProjects.cancelRequest();
    this.smallEquipmentProjects.reset();

    try {
      await this.smallEquipmentProjects.fetch({
        params: this.params
      });

      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasSmallEquipmentProjects() {
    return this.smallEquipmentProjects.hasModels;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasSmallEquipmentProjects;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.smallEquipmentProjects.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.smallEquipmentProjectsForm = null;
    this.tearDownReactions();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.showFiltersModal = false;
    this.smallEquipmentProjectsFiltersForm = null;
    this.smallEquipmentProjects.clear();
  }

  @action.bound submitEquipmentProjectsForm(event) {
    event.preventDefault();

    this.smallEquipmentProjectsForm.submit({
      onSuccess: this.submitEquipmentProjectsFormSuccess,
      onError: this.submitEquipmentProjectsFormError
    });
  }

  @action.bound async submitEquipmentProjectsFormSuccess() {
    this.saving = true;

    const data = this.smallEquipmentProjectsForm
      .values()
      .projects.map(project => {
        return {
          projectUuid: project.projectUuid,
          quantity: Number(project.projectDeployedUnits)
        };
      });

    this.loading = true;
    this.smallEquipmentProjectsForm = null;

    try {
      await request.patch(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/smallequipment/${this.entryForEdit.uuid}/deployed`,
        data
      );
      this.fetchSmallEquipmentProjects();

      await this.entryForEdit.fetch();
      this.resetForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Small equipment saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentProjectsFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  // Filters
  @action.bound showFilters() {
    this.groupSelectorUI.setup();

    this.showFiltersModal = true;

    this.smallEquipmentProjectsFiltersForm = new SmallEquipmentProjectsFiltersForm(
      {
        fields: smallEquipmentProjectsFiltersFormFields,
        rules: smallEquipmentProjectsFiltersFormRules,
        labels: smallEquipmentProjectsFiltersFormLabels,
        values: {
          groups: this.groupFilters ? this.groupFilters.slice() : []
        }
      },
      {
        options: smallEquipmentProjectsFiltersFormOptions,
        plugins: smallEquipmentProjectsFiltersFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async cancelProjectsFilters() {
    this.showFiltersModal = false;

    this.groupSelectorUI.tearDown();
  }

  @action.bound clearAllProjectsFilters() {
    this.groupFilters.clear();

    this.cancelProjectsFilters();
  }

  @action.bound submitProjectsFiltersSmallEquipmentForm(event) {
    event.preventDefault();

    this.smallEquipmentProjectsFiltersForm.submit({
      onSuccess: this.submitProjectsFiltersSmallEquipmentFormSuccess,
      onError: this.submitProjectsFiltersSmallEquipmentFormError
    });
  }

  @action.bound submitProjectsFiltersSmallEquipmentFormSuccess() {
    const values = this.smallEquipmentProjectsFiltersForm.values();

    this.groupFilters.replace(values.groups);

    this.cancelProjectsFilters();
  }

  @action.bound submitProjectsFiltersSmallEquipmentFormError() {
    console.error(this.smallEquipmentProjectsFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.groupFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }
}

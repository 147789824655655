import React, { useRef } from 'react';
import { Observer } from 'mobx-react';
import { t } from 'utils/translate';

import {
  Box,
  Dialog,
  Button,
  Grid,
  TextField,
  Select,
  DatePicker,
  Alert
} from '@raken/athens-web/lib';

import LocationSearchField from 'athens-components/LocationSearchField';
import GroupSelector from 'athens-components/GroupSelector';
import ErpIntegrationWarning from 'athens-components/ErpIntegrationWarning';

const ValidationError = function(props) {
  const { uiStore, onError } = props;

  if (uiStore.hasValidationDetails) {
    onError && onError();

    return (
      <Box mb={6} width="100%">
        <Alert>
          {uiStore.validationDetails.map(item => item.fieldMessage)}
        </Alert>
      </Box>
    );
  }

  return null;
};

const ProjectCreationModal = function(props) {
  const dialogRef = useRef();

  return (
    <Observer>
      {() => {
        const { uiStore } = props;
        const { projectCreationForm } = uiStore;

        if (!projectCreationForm) return null;

        return (
          <Dialog
            open={uiStore.activeModal === 'ProjectCreationModal'}
            fullWidth
            maxWidth={'sm'}
            PaperProps={{ ref: dialogRef }}
            disableEnforceFocus
            dataQA="project-creation"
            onClose={uiStore.closeProjectCreationLink}
          >
            <Dialog.Title
              dataQA="project-creation-title"
              withClose
              onClose={uiStore.closeProjectCreationLink}
            >
              {t('New project')}
            </Dialog.Title>

            <form onSubmit={uiStore.submitProjectCreationForm}>
              <Dialog.Content>
                {uiStore.hasErpIntegration && (
                  <Box mb={6}>
                    <ErpIntegrationWarning
                      uiStore={uiStore}
                      resourceType={'projects'}
                    />
                  </Box>
                )}

                <ValidationError
                  uiStore={uiStore}
                  onError={() => {
                    dialogRef.current.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  }}
                />

                <Box mx={-3}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <TextField
                          label={t('Name')}
                          placeholder={t('Name this project')}
                          onChange={projectCreationForm.$('name').sync}
                          helperText={projectCreationForm.$('name').error}
                          error={projectCreationForm.$('name').hasError}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          fullWidth
                          autoFocus
                          dataQA="project-name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <TextField
                          label={t('Job #')}
                          placeholder={t('Job #')}
                          onChange={projectCreationForm.$('projectNo').sync}
                          helperText={projectCreationForm.$('projectNo').error}
                          error={projectCreationForm.$('projectNo').hasError}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          fullWidth
                          dataQA="job-number"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <LocationSearchField
                          onChange={geocode => {
                            uiStore.setSearchedPlace(geocode);
                          }}
                          rootStore={uiStore.rootStore}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          dataQA={'location-search'}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <TextField
                          value={
                            projectCreationForm.$('address.streetAddress').value
                          }
                          onChange={
                            projectCreationForm.$('address.streetAddress').sync
                          }
                          label={t('Street address')}
                          placeholder={t('Street address')}
                          helperText={
                            projectCreationForm.$('address.streetAddress').error
                          }
                          error={
                            projectCreationForm.$('address.streetAddress')
                              .hasError
                          }
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          fullWidth
                          dataQA="street-address"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <TextField
                          value={projectCreationForm.$('address.city').value}
                          onChange={projectCreationForm.$('address.city').sync}
                          label={t('City')}
                          placeholder={t('City')}
                          helperText={
                            projectCreationForm.$('address.city').error
                          }
                          error={projectCreationForm.$('address.city').hasError}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          fullWidth
                          dataQA="city"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        {projectCreationForm.$('address.country').value !==
                          'US' && (
                          <TextField
                            value={projectCreationForm.$('address.state').value}
                            onChange={
                              projectCreationForm.$('address.state').sync
                            }
                            label={t('State')}
                            placeholder={t('State')}
                            helperText={
                              projectCreationForm.$('address.state').error
                            }
                            error={
                              projectCreationForm.$('address.state').hasError
                            }
                            disabled={!uiStore.authorization.canEditProjectInfo}
                            fullWidth
                            dataQA="state"
                          />
                        )}

                        {projectCreationForm.$('address.country').value ===
                          'US' && (
                          <Select
                            label={t('State')}
                            fullWidth
                            placeholder={t('State')}
                            error={
                              projectCreationForm.$('address.state').hasError
                            }
                            helperText={
                              projectCreationForm.$('address.state').error
                            }
                            value={uiStore.selectedState}
                            onChange={(e, option) => {
                              projectCreationForm.update({
                                'address.state': option?.value
                              });
                            }}
                            options={uiStore.stateOptions}
                            optionTitle={'name'}
                            dataQA="state"
                            autocomplete
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <TextField
                          value={
                            projectCreationForm.$('address.postCode').value
                          }
                          onChange={
                            projectCreationForm.$('address.postCode').sync
                          }
                          label={t('Zip code')}
                          placeholder={t('Zip code')}
                          helperText={
                            projectCreationForm.$('address.postCode').error
                          }
                          error={
                            projectCreationForm.$('address.postCode').hasError
                          }
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          fullWidth
                          dataQA="postcode"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <Select
                          value={uiStore.countries.asOptions.find(
                            country =>
                              country.value ===
                              projectCreationForm.$('address.country').value
                          )}
                          onChange={(e, option) => {
                            projectCreationForm.update({
                              'address.country': option.value
                            });
                          }}
                          options={uiStore.countries.asOptions}
                          optionTitle={'name'}
                          fullWidth
                          label={t('Country')}
                          placeholder={t('Country')}
                          dropdownWidth="350px"
                          helperText={
                            projectCreationForm.$('address.country').error
                          }
                          error={
                            projectCreationForm.$('address.country').hasError
                          }
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          dataQA="country"
                          noOptionsText={t('No options')}
                          autocomplete
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <DatePicker
                          format={'YYYY-MM-DD'}
                          value={
                            projectCreationForm.$('startDate').value || null
                          }
                          onChange={projectCreationForm.$('startDate').sync}
                          label={t('Project start date')}
                          placeholder={'YYYY-MM-DD'}
                          helperText={projectCreationForm.$('startDate').error}
                          error={projectCreationForm.$('startDate').hasError}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          autoOk
                          dataQA="project-start-date"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <DatePicker
                          format={'YYYY-MM-DD'}
                          value={projectCreationForm.$('endDate').value || null}
                          minDate={
                            projectCreationForm.$('startDate').value ||
                            undefined
                          }
                          label={t('Project end date')}
                          placeholder={'YYYY-MM-DD'}
                          onChange={projectCreationForm.$('endDate').sync}
                          helperText={projectCreationForm.$('endDate').error}
                          error={projectCreationForm.$('endDate').hasError}
                          disabled={!uiStore.authorization.canEditProjectInfo}
                          autoOk
                          dataQA="project-end-date"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box px={3}>
                        <GroupSelector
                          groupSelectorUI={uiStore.groupSelectorUI}
                          multiple
                          label={t('Project groups')}
                          fullWidth
                          onChange={(event, selectedGroups) => {
                            projectCreationForm.update({
                              groups: selectedGroups
                            });
                          }}
                          groupBy={option => option.groupClass.name}
                          value={uiStore.selectedGroupOptions}
                          dataQA={'project-groups'}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box px={3}>
                        <Select
                          value={
                            uiStore.projectTemplates?.selectOptions.find(
                              option =>
                                option.value ===
                                uiStore.projectCreationForm.$('templateId')
                                  .value
                            ) || null
                          }
                          options={
                            uiStore.projectTemplates?.selectOptions || []
                          }
                          optionTitle={'title'}
                          label={t('Project template')}
                          placeholder={t('Project template')}
                          onChange={(e, option) => {
                            uiStore.projectCreationForm.update({
                              templateId: option.value
                            });
                          }}
                          disabled={uiStore.projectTemplateSelectDisabled}
                          fullWidth
                          dataQA="project-templates"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Dialog.Content>

              <Dialog.Actions>
                <Button
                  rakenColor="white"
                  onClick={uiStore.closeProjectCreationLink}
                  dataQA="project-creation-cancel"
                >
                  {t('Cancel')}
                </Button>

                <Button
                  type="submit"
                  rakenColor="orange"
                  disabled={uiStore.projectTemplates.fetching || uiStore.saving}
                  dataQA="project-creation-save"
                >
                  {uiStore.saving ? t('Please wait...') : t('Save')}
                </Button>
              </Dialog.Actions>
            </form>
          </Dialog>
        );
      }}
    </Observer>
  );
};

export default ProjectCreationModal;

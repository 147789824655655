import { action } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';

import SmallEquipmentDeployments from 'stores/collections/equipment/SmallEquipmentDeployments';

import SmallEquipmentUI from 'stores/ui/company/equipment/smallEquipment/SmallEquipmentUI';

import ProjectSmallEquipmentActionsUI from './ProjectSmallEquipmentActionsUI';
import ProjectSmallEquipmentAddUI from './ProjectSmallEquipmentAddUI';

import history from 'utils/history';

export default class ProjectSmallEquipmentUI extends SmallEquipmentUI {
  constructor(options) {
    super(options);

    this.smallEquipmentActionsUI = new ProjectSmallEquipmentActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.smallEquipmentAddUI = new ProjectSmallEquipmentAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Equipment collection
    this.smallEquipment = new SmallEquipmentDeployments(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async fetchSmallEquipment() {
    this.smallEquipment.cancelRequest();
    this.smallEquipment.reset();
    try {
      await this.smallEquipment.fetch({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.rootStore.me.company.uuid}/projects/${
          this.project.uuid
        }/smallequipment/deployed`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async addSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    history.push({
      pathname: `${this.project.viewUrl}/equipment/small-equipment/add`
    });
  }
}

import { observable, action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import {
  SmallEquipmentForm,
  smallEquipmentFormLabels,
  smallEquipmentFormRules,
  smallEquipmentFormFields,
  smallEquipmentFormOptions,
  smallEquipmentFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipment';

import history from 'utils/history';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class SmallEquipmentEditUI extends UIStore {
  @observable saving;
  @observable form;

  constructor(options) {
    super(options);

    this.form = null;
    this.saving = false;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.form = new SmallEquipmentForm(
      {
        fields: smallEquipmentFormFields,
        rules: {
          ...smallEquipmentFormRules,
          quantity: `required|numeric|cannotBeLessThanTotalUnitsDeployed:${this.entryForEdit.totalDeployedUnits}`
        },
        labels: smallEquipmentFormLabels,
        values: {
          ...this.entryForEdit.formValues,
          initialName: this.entryForEdit.name,
          companyUuid: this.rootStore.me.company.uuid
        }
      },
      {
        options: smallEquipmentFormOptions,
        plugins: smallEquipmentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.form.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action clearUIState() {
    this.form = null;
    this.saving = false;
    this.clearValidationDetails();
  }

  @action.bound
  async submitSmallEquipmentForm(e) {
    e.preventDefault();
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'CRUDOwnedEquipment'
    );
    this.form.submit({
      onSuccess: this.submitSmallEquipmentEditFormSuccess,
      onError: e => {
        console.error(this.form.errors());
      }
    });
  }

  @action.bound
  async submitSmallEquipmentEditFormSuccess() {
    this.saving = true;
    try {
      await this.entryForEdit.save(this.form.values());

      this.parent.parent.sortByLastCreated();
      this.resetForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Small equipment saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }
}

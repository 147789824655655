import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';
import moment from 'moment';
import request from 'axios';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import errorHandler from 'utils/errorHandler';

export default class SettingsSignOff extends Model {
  url() {
    return '/rules';
  }

  get restAttributes() {
    return [
      'id',
      'businessUUID',
      'enabled',
      'scheduleData',
      'signOffDisclaimer',
      'timeType',
      'weekFirstDay',
      'approvalNotificationsEnabled',
      'approvalScheduleData'
    ];
  }

  @computed
  get firstDueDate() {
    const actionDayOfWeek = this.scheduleData?.actionDayOfWeek - 1; // -1 is different server and momentjs format

    if (Array.isArray(this.scheduleData?.firstDueDate?.slice())) {
      return moment(this.scheduleData.firstDueDate.join('/')).format(
        'dd, MMM DD, YYYY'
      );
    }

    if (this.scheduleData?.firstDueDate) {
      return moment(this.scheduleData.firstDueDate).format('dd, MMM DD, YYYY');
    }

    /*take default day this week or next week*/
    if (moment().isoWeekday() <= actionDayOfWeek) {
      return moment()
        .day(actionDayOfWeek)
        .format('dd, MMM DD, YYYY');
    } else {
      return moment()
        .day(actionDayOfWeek + 7)
        .format('dd, MMM DD, YYYY');
    }
  }

  @computed
  get actionDayOfWeek() {
    if (!this.scheduleData?.actionDayOfWeek) return null;

    return this.scheduleData.actionDayOfWeek === 1
      ? 6
      : this.scheduleData.actionDayOfWeek - 2;
  }

  @computed
  get actionDayOfMonth() {
    if (!this.scheduleData?.actionDayOfMonth) return null;

    return this.scheduleData.actionDayOfMonth - 1;
  }

  @computed
  get time() {
    if (!this.scheduleData?.time) return null;

    return {
      hour: ('0' + this.scheduleData.time.hour).slice(-2),
      minute: ('0' + this.scheduleData.time.minute).slice(-2),
      period:
        this.scheduleData?.time.hour >= 12 && this.scheduleData?.time.hour < 24
          ? 'PM'
          : 'AM'
    };
  }

  // Approval
  @computed
  get approvalFirstDueDate() {
    const actionDayOfWeek = this.approvalScheduleData?.actionDayOfWeek - 1; // -1 is different server and momentjs format

    if (Array.isArray(this.approvalScheduleData?.firstDueDate?.slice())) {
      return moment(this.approvalScheduleData.firstDueDate.join('/')).format(
        'dd, MMM DD, YYYY'
      );
    }

    if (this.approvalScheduleData?.firstDueDate) {
      return moment(this.approvalScheduleData.firstDueDate).format(
        'dd, MMM DD, YYYY'
      );
    }

    /*take default day this week or next week*/
    if (moment().isoWeekday() <= actionDayOfWeek) {
      return moment()
        .day(actionDayOfWeek)
        .format('dd, MMM DD, YYYY');
    } else {
      return moment()
        .day(actionDayOfWeek + 7)
        .format('dd, MMM DD, YYYY');
    }
  }

  @computed
  get approvalActionDayOfWeek() {
    if (!this.approvalScheduleData?.actionDayOfWeek) return null;

    return this.approvalScheduleData.actionDayOfWeek === 1
      ? 6
      : this.approvalScheduleData.actionDayOfWeek - 2;
  }

  @computed
  get approvalActionDayOfMonth() {
    if (!this.approvalScheduleData?.actionDayOfMonth) return null;

    return this.approvalScheduleData.actionDayOfMonth - 1;
  }

  @computed
  get approvalTime() {
    if (!this.approvalScheduleData?.time) return { hour: 9, minute: 0 };

    return {
      hour: ('0' + this.approvalScheduleData.time.hour).slice(-2),
      minute: ('0' + this.approvalScheduleData.time.minute).slice(-2),
      period:
        this.approvalScheduleData?.time.hour >= 12 &&
        this.approvalScheduleData?.time.hour < 24
          ? 'PM'
          : 'AM'
    };
  }

  @computed
  get formValues() {
    const scheduleData = pickBy(
      {
        firstDueDate: this.firstDueDate,
        actionDayOfWeek: this.actionDayOfWeek,
        actionDayOfMonth: this.actionDayOfMonth,
        time: this.time,
        lastDayNotification: this.scheduleData?.lastDayNotification
      },
      value => value !== undefined || value !== null
    );

    const approvalScheduleData = pickBy(
      {
        firstDueDate: this.approvalFirstDueDate,
        actionDayOfWeek: this.approvalActionDayOfWeek,
        actionDayOfMonth: this.approvalActionDayOfMonth,
        time: this.approvalTime,
        lastDayNotification: this.approvalScheduleData?.lastDayNotification
      },
      value => value !== undefined || value !== null
    );

    return pickBy(
      {
        id: this.id,
        businessUUID: this.businessUUID,
        enabled: this.enabled,
        scheduleData: scheduleData,
        approvalNotificationsEnabled:
          this.approvalNotificationsEnabled || false,
        approvalScheduleData: approvalScheduleData,
        signOffDisclaimer: this.signOffDisclaimer,
        timeType: this.timeType,
        weekFirstDay: this.weekFirstDay
      },
      identity
    );
  }

  @action.bound
  save(data = {}, options) {
    const url = `${this.rootStore.appConfig.tracking_api_url}/signoff/rules/${data.id}`;

    return new Promise((resolve, reject) => {
      request
        .put(url, data)
        .then(res => {
          this.set(res.data.signOffRule, {
            ...options
          });

          return resolve(res);
        })
        .catch(error => {
          errorHandler(error, this.rootStore.notificationsUI.pushError);
          reject(error);
        });
    });
  }

  @computed
  get apiValues() {
    const scheduleData = pickBy(
      {
        firstDueDate: moment(this.firstDueDate).format('YYYY-MM-DD'),
        actionDayOfWeek: this.actionDayOfWeek,
        actionDayOfMonth: this.actionDayOfMonth,
        time: this.time,
        lastDayNotification: this.scheduleData?.lastDayNotification
      },
      value => value !== undefined || value !== null
    );

    const approvalScheduleData = pickBy(
      {
        firstDueDate: moment(this.approvalFirstDueDate).format('YYYY-MM-DD'),
        actionDayOfWeek: this.approvalActionDayOfWeek,
        actionDayOfMonth: this.approvalActionDayOfMonth,
        time: this.approvalTime,
        lastDayNotification: this.approvalScheduleData?.lastDayNotification
      },
      value => value !== undefined || value !== null
    );

    return pickBy(
      {
        id: this.id,
        businessUUID: this.businessUUID,
        enabled: this.enabled,
        scheduleData: scheduleData,
        approvalNotificationsEnabled:
          this.approvalNotificationsEnabled || false,
        approvalScheduleData: approvalScheduleData,
        signOffDisclaimer: this.signOffDisclaimer,
        timeType: this.timeType,
        weekFirstDay: this.weekFirstDay
      },
      identity
    );
  }
}

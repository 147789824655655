import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

export default class IncidentReport300301 extends Model {
  get restAttributes() {
    return [
      'uuid',
      'year',
      'caseNo',
      'companyUuid',
      'projectUuid',
      'projectName',
      'incidentTitle',
      'status',
      'isRecordable',
      'completedByName',
      'createdBy',
      'createdTimestamp',
      'updatedBy',
      'updatedTimestamp',
      'incidentDate',
      'incidentTime',
      'incidentLocation',
      'injuryOrIllnessType',
      'employeeName',
      'employeeJobTitle',
      'employeeGender',
      'employeeDateOfBirth',
      'employeeDateOfHire',
      'descriptionOfInjuryOrIllness',
      'activityPriorToIncident',
      'recountOfIncident',
      'affectOnBody',
      'sourceOfHarm',
      'isTreatedInTheEmergencyRoom',
      'isHospitalized',
      'incidentOutcome',
      'daysAwayFromWork',
      'daysOnJobTransferOrRestriction',
      'dateOfDeath',
      'totalQuestionCount',
      'answeredQuestionCount'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get caseHeader() {
    return t(`Case #${this.caseNo}`);
  }

  @computed get caseSubHeader() {
    let result = [];

    if (this.incidentTitle) {
      result.push(this.incidentTitle);
    }

    if (this.incidentOutcome) {
      result.push(this.incidentOutcomeMapping);
    }

    return t(result.join(' • '));
  }

  @computed get incidentTypeMapping() {
    const INCIDENT_TYPE_MAP = {
      1: t('Injury'),
      2: t('Skin disorder'),
      3: t('Respiratory condition'),
      4: t('Poisoning'),
      5: t('Hearing loss')
    };

    return INCIDENT_TYPE_MAP[this.injuryOrIllnessType];
  }

  @computed get incidentOutcomeMapping() {
    const INCIDENT_OUTCOME_MAP = {
      1: t('Death'),
      2: t('Days away from work'),
      3: t('Job transfer/restricted work activity'),
      4: t('Other')
    };

    return INCIDENT_OUTCOME_MAP[this.incidentOutcome];
  }

  @computed get formValues() {
    return {
      caseNumber: this.caseNo,
      date: this.incidentDate,
      jobTitle: this.employeeJobTitle,
      gender: this.employeeGender,
      dateOfBirth: this.employeeDateOfBirth,
      dateOfHire: this.employeeDateOfHire,
      dateOfDeath: this.dateOfDeath,
      timeOfIncident: this.incidentTime,
      incidentLocation: this.incidentLocation,
      incidentType: this.incidentTypeMapping,
      incidentDescription: this.descriptionOfInjuryOrIllness,
      incidentOutcome: this.incidentOutcomeMapping
    };
  }
}

import { computed, action, observable, reaction, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import request from 'axios';

import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  SmallEquipmentLostStolenForm,
  smallEquipmentLostStolenFormOptions,
  smallEquipmentLostStolenFormFields,
  smallEquipmentLostStolenFormRules,
  smallEquipmentLostStolenFormLabels,
  smallEquipmentLostStolenFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentLostStolen';

import {
  SmallEquipmentLostStolenFiltersForm,
  smallEquipmentLostStolenFiltersFormOptions,
  smallEquipmentLostStolenFiltersFormFields,
  smallEquipmentLostStolenFiltersFormRules,
  smallEquipmentLostStolenFiltersFormLabels,
  smallEquipmentLostStolenFiltersFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentLostStolenFilters';

import SmallEquipmentDeployments from 'stores/collections/equipment/SmallEquipmentDeployments';

import { callTrack } from 'utils/segmentIntegration';
import { SMALL_EQUIPMENT_LOST } from 'utils/segmentAnalytics/eventSpec';

export default class SmallEquipmentLostStolenUI extends UIStore {
  @observable smallEquipmentLostStolenForm;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable showFiltersModal;
  @observable smallEquipmentLostStolenFiltersForm;

  constructor(options) {
    super(options);

    this.smallEquipmentLostStolenForm = null;

    this.loading = false;
    this.saving = false;

    this.sortField = 'projectName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.showFiltersModal = false;
    this.smallEquipmentLostStolenFiltersForm = null;
    this.filtersEquipment = observable([]);
    this.filtersProjects = observable([]);

    // Equipment Deployments collection
    this.smallEquipmentDeployments = new SmallEquipmentDeployments(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSmallEquipmentDeploymentsDebounced = debounce(
      this.fetchSmallEquipmentDeployments,
      BASE_DEBOUNCE
    );
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      equipmentUuids: this.filtersEquipment
        .map(smallEquipment => smallEquipment.uuid)
        .join(','),
      projectUuids: this.filtersProjects
        .map(smallEquipment => smallEquipment.value)
        .join(',')
    };
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async markLostStolenSmallEquipment(selectedSmallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.filtersEquipment.replace([
      {
        name: selectedSmallEquipment.name,
        uuid: selectedSmallEquipment.uuid
      }
    ]);

    this.showModal('SmallEquipmentLostStolenModalPage');

    await this.fetchSmallEquipmentDeployments();

    this.setupReactions();

    this.setupForm();
  }

  @action.bound async bulkMarkLostStolenSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.filtersEquipment.replace(
      this.parent.selectedSmallEquipment.map(smallEquipment => {
        return {
          name: smallEquipment.name,
          uuid: smallEquipment.uuid
        };
      })
    );

    this.showModal('SmallEquipmentLostStolenModalPage');

    await this.fetchSmallEquipmentDeployments();

    this.setupReactions();

    this.setupForm();
  }

  @action.bound setupForm() {
    this.smallEquipmentLostStolenForm = new SmallEquipmentLostStolenForm(
      {
        fields: smallEquipmentLostStolenFormFields,
        rules: smallEquipmentLostStolenFormRules,
        labels: smallEquipmentLostStolenFormLabels,
        values: {
          lostStolen: this.smallEquipmentDeployments.models?.map(value => {
            return { ...value.formValues, unitsLostStolen: 0 };
          })
        }
      },
      {
        options: smallEquipmentLostStolenFormOptions,
        plugins: smallEquipmentLostStolenFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentLostStolenForm
      .$('lostStolen')
      .fields.forEach(lostStolen =>
        lostStolen
          .$('unitsLostStolen')
          .set(
            'rules',
            `numeric|min:0|max:${lostStolen.$('projectDeployedUnits').value}`
          )
      );
  }

  @action.bound async cancelLostStolenSmallEquipment() {
    await this.hideActiveModal();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchSmallEquipmentDeploymentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound async fetchSmallEquipmentDeployments() {
    this.loading = true;
    this.smallEquipmentLostStolenForm = null;

    this.smallEquipmentDeployments.cancelRequest();
    this.smallEquipmentDeployments.reset();

    try {
      await this.smallEquipmentDeployments.fetch({
        params: this.params
      });

      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasSmallEquipmentDeployments() {
    return this.smallEquipmentDeployments.hasModels;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasSmallEquipmentDeployments;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.smallEquipmentDeployments.totalElements / this.pageSize
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.smallEquipmentLostStolenForm = null;
    this.tearDownReactions();
    this.searchQuery = '';
    this.page = 1;
    this.loading = false;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.showFiltersModal = false;
    this.smallEquipmentLostStolenFiltersForm = null;
  }

  @action.bound submitEquipmentLostStolenForm(event) {
    event.preventDefault();

    this.smallEquipmentLostStolenForm.submit({
      onSuccess: this.submitEquipmentLostStolenFormSuccess,
      onError: this.submitEquipmentLostStolenFormError
    });
  }

  @action.bound async submitEquipmentLostStolenFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentLostStolenForm.values();

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/smallequipment/writeoff/batch`,
        values.lostStolen
          .map(lostStolen => {
            return {
              equipmentUuid: lostStolen.equipmentUuid,
              projectUuid: lostStolen.projectUuid,
              quantity: Number(lostStolen.unitsLostStolen)
            };
          })
          .filter(lostStolen => lostStolen.quantity)
      );

      values.lostStolen.forEach(lostStolen => {
        callTrack(SMALL_EQUIPMENT_LOST, {
          small_equipment_name: lostStolen.equipmentName,
          units_withdrawn: lostStolen.unitsLostStolen
        });
      });

      this.parent.selectedSmallEquipment.clear();

      this.cancelLostStolenSmallEquipment();

      this.parent.parent.refetchSmallEquipment();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentLostStolenFormError() {
    console.error(this.smallEquipmentLostStolenFiltersForm.errors());
  }

  // Filters
  @action.bound showFilters() {
    // Setup filter selectors
    this.smallEquipmentSelectorUI.setup();
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.showFiltersModal = true;

    this.smallEquipmentLostStolenFiltersForm = new SmallEquipmentLostStolenFiltersForm(
      {
        fields: smallEquipmentLostStolenFiltersFormFields,
        rules: smallEquipmentLostStolenFiltersFormRules,
        labels: smallEquipmentLostStolenFiltersFormLabels,
        values: {
          smallEquipment: this.filtersEquipment.slice(),
          projects: this.filtersProjects ? this.filtersProjects.slice() : []
        }
      },
      {
        options: smallEquipmentLostStolenFiltersFormOptions,
        plugins: smallEquipmentLostStolenFiltersFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async cancelLostStolenFilters() {
    this.showFiltersModal = false;

    this.smallEquipmentSelectorUI.tearDown();
    this.projectSelectorUI.tearDown();
  }

  @action.bound clearAllLostStolenFilters() {
    this.filtersEquipment.clear();
    this.filtersProjects.clear();

    this.cancelLostStolenFilters();
  }

  @action.bound submitLostStolenFiltersSmallEquipmentForm(event) {
    event.preventDefault();

    this.smallEquipmentLostStolenFiltersForm.submit({
      onSuccess: this.submitLostStolenFiltersSmallEquipmentFormSuccess,
      onError: this.submitLostStolenFiltersSmallEquipmentFormError
    });
  }

  @action.bound submitLostStolenFiltersSmallEquipmentFormSuccess() {
    const values = this.smallEquipmentLostStolenFiltersForm.values();

    this.filtersEquipment.replace(values.smallEquipment);
    this.filtersProjects.replace(values.projects);

    this.cancelLostStolenFilters();
  }

  @action.bound submitLostStolenFiltersSmallEquipmentFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.filtersEquipment.length) {
      counter += 1;
    }

    if (this.filtersProjects.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }
}

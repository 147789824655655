import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable } from 'mobx';
import { t } from 'utils/translate';

import {
  OverTimeRuleForm,
  overTimeRuleFormFields,
  overTimeRuleFormOptions,
  overTimeRuleFormPlugins
} from 'forms/overTimeRule';

import alertErrorHandler from 'utils/alertErrorHandler';

import OverTimeRule from 'stores/models/OverTimeRule';

export default class PolicyOverTimeUI extends PolicyChildEditUI {
  @observable form;
  @observable loading;

  constructor(options) {
    super(options);

    this.form = null;
    this.loading = false;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed
  get overTimeRulesType() {
    return [
      { id: 'DAILY', title: t('Daily overtime rules') },
      { id: 'WEEKLY', title: t('Weekly overtime rules') },
      { id: 'BOTH', title: t('Daily & weekly overtime rules') }
    ];
  }

  @computed
  get nonSelectedPayTypes() {
    const selectedPayTypeUuids = this.form.$('rules').value.map(rule => {
      return rule.payType.uuid;
    });
    selectedPayTypeUuids.push(this.form.$('default').value.payType?.uuid);

    return this.rootStore.payTypeSelectorUI.options.filter(
      payType => !selectedPayTypeUuids.includes(payType.uuid)
    );
  }

  @action.bound
  async setUpOverTimeRules() {
    this.loading = true;

    this.ruleSet = new OverTimeRule(this.entryForEdit.overtimeRuleSet, {
      rootStore: this.rootStore
    });

    try {
      await this.rootStore.payTypeSelectorUI.fetchPayTypes();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.initOverTimeRulesForm();
    this.loading = false;
  }

  @action.bound
  getRulesFromType(type) {
    if (type === 'DAILY') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].dailyLimit': 'numeric|min:0|max:24|required'
      };
    }

    if (type === 'WEEKLY') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].weeklyLimit': 'numeric|min:0|max:168|required'
      };
    }

    if (type === 'BOTH') {
      return {
        ruleSet: 'string',
        rules: 'array',
        default: 'required',
        name: 'required',
        'rules[].dailyLimit': 'numeric|min:0|max:24|required',
        'rules[].weeklyLimit': 'numeric|min:0|max:168|required'
      };
    }

    return {
      ruleSet: 'string',
      rules: 'array'
    };
  }

  @action.bound
  initOverTimeRulesForm() {
    this.ruleSet.type =
      this.overTimeRulesType.find(
        ruleSet => ruleSet.id === this.ruleSet.type
      ) || null;

    this.form = new OverTimeRuleForm(
      {
        fields: overTimeRuleFormFields,
        rules: this.getRulesFromType(this.ruleSet.type?.id),
        values: this.ruleSet.formValues
      },
      {
        options: overTimeRuleFormOptions,
        plugins: overTimeRuleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  resetForm() {
    this.setUpOverTimeRules();
  }

  @action.bound
  updateOverTimeRuleType(option) {
    const formValues = {
      ...this.form.values(),
      type: option
    };

    this.form = new OverTimeRuleForm(
      {
        fields: overTimeRuleFormFields,
        rules: this.getRulesFromType(option.id),
        values: formValues
      },
      {
        options: overTimeRuleFormOptions,
        plugins: overTimeRuleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  addRule() {
    this.form.update({
      rules: [
        ...this.form.$('rules').value,
        {
          order: this.form.$('rules').value.length + 1,
          payType:
            this.nonSelectedPayTypes.find(
              payType => payType.name === 'Regular Time'
            ) || this.nonSelectedPayTypes[0], // Default to regular time for the first rule if we can otherwise use the first rule in the list
          dailyLimit: '',
          weeklyLimit: ''
        }
      ]
    });
  }

  @action.bound
  deleteRule(keyToDelete) {
    this.form.update({
      rules: [
        ...this.form
          .$('rules')
          .value.filter((rule, key) => {
            return key !== keyToDelete;
          })
          .map((rule, key) => {
            return { ...rule, order: key + 1 };
          })
      ]
    });
  }

  @action.bound
  setup() {
    this.setUpOverTimeRules();
    this.blockHistoryIfFormChanged();
  }

  @action.bound
  tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  async clearUIState() {
    this.ruleSet = null;
    this.form = null;
  }

  @action.bound submitOverTimeRulesForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitOverTimeRulesFormSuccess,
      onError: this.submitOverTimeRulesError
    });
  }

  @action.bound submitOverTimeRulesError() {
    console.log(this.form.errors());
  }

  @action.bound
  async saveRuleSet(values) {
    // Clean up opposite values for DAILY and WEEKLY
    if (values.type.id === 'DAILY') {
      values.rules.forEach(rule => {
        rule.weeklyLimit = '';
      });
    }

    if (values.type.id === 'WEEKLY') {
      values.rules.forEach(rule => {
        rule.dailyLimit = '';
      });
    }

    const payload = {
      rules: [
        ...values.rules,
        {
          uuid: values.default.uuid,
          order: values.rules.length + 1,
          payType: {
            uuid: values.default.payType.uuid,
            name: values.default.payType.name
          },
          default: true
        }
      ],
      name: values.name,
      type: values.type.id,
      uuid: values.uuid
    };

    try {
      await this.entryForEdit.save(
        { overtimeRuleSet: payload },
        {
          wait: true
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  async submitOverTimeRulesFormSuccess() {
    const values = this.form.values();

    await this.saveRuleSet(values);

    this.parent.parent.refetchPolicies();
    await this.setUpOverTimeRules();

    this.moveToNextStepIfSettingUpDefault('Overtime rules');

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Overtime Rules Updated ')}`
    });
  }

  @computed
  get disableSaveButton() {
    return (
      !this.form ||
      this.form.hasError ||
      this.overTimeRuleNameTaken ||
      this.entryForEdit.saving ||
      !this.form.$('default').value
    );
  }
}

import { action, observable, computed } from 'mobx';
import ProjectChildUI from './ProjectChildUI';
import Attachment from '../../models/Attachment';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

export default class ProjectChildEditUI extends ProjectChildUI {
  @observable entryForEdit;
  @observable entryEditForm;

  @observable nextUrl;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
    this.nextUrl = null;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    // Override in extended store
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.entryEditForm.reset();
    this.entryEditForm.each(field => {
      field.resetValidation();
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    if (this.entryEditForm?.tearDownReactions) {
      this.entryEditForm.tearDownReactions();
    }

    this.nextUrl = null;
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get disableCancelButton() {
    return Boolean(
      this.saving || this.hasAttachmentsUploading || !this.hasWriteAccess
    );
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving || this.hasAttachmentsUploading || !this.hasWriteAccess
    );
  }

  @computed get saveButtonText() {
    if (this.saving) {
      return t('Saving...');
    }

    if (this.hasAttachmentsUploading) {
      return t('Uploading files...');
    }

    return t('Save');
  }

  @computed get hasAttachmentsUploading() {
    return this.entryForEdit?.attachments?.models.find(
      attachment => attachment.isNew
    );
  }

  @action.bound async uploadAttachment(uploadItem) {
    await this.authorization.checkFeatureAccess('UploadAttachments');

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    this.entryForEdit.attachments.add(previewAttachment);

    try {
      await this.entryForEdit.attachments.uploadVersionTwo({
        file: file,
        analytics: {
          module: 'Attachments',
          project: {
            id: this.project.uuid,
            name: this.project.name
          }
        },
        previewAttachment: previewAttachment,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      // Remove the preview model now that the new one is ready
      this.entryForEdit.attachments.remove(previewAttachment);
    }
  }
}

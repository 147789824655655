import { computed, action } from 'mobx';
import User from '../User';
import moment from 'moment';
import { t } from 'utils/translate';

class SuperAdminUser extends User {
  get restAttributes() {
    return [
      'uuid',
      'employeeId',
      'type',
      'username',
      'firstName',
      'lastName',
      'phone',
      'email',
      'permissions',
      'isCollaborator',
      'isPending',
      'role',
      'signature',
      'title',
      'company',
      'inviteId',
      'inviteSentTimestamp',
      'lastLogin',
      'projectTeamIds',
      'invitedBy',
      'invitedTo',
      'status',
      'createdDate',
      'avatar',
      'userId',
      'isViolator',
      'ssoBypass'
    ];
  }

  @computed
  get fullName() {
    const firstName =
      this.firstName && this.firstName !== 'null' ? this.firstName : '';
    const lastName =
      this.lastName && this.lastName !== 'null' ? this.lastName : '';

    return `${firstName} ${lastName}`;
  }

  url() {
    if (this.inviteId) {
      return `/ra/sadmin/users/${this.id}`;
    } else {
      return `/ra/sadmin/users/${this.id}`;
    }
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(attributes) {
    return attributes;
  }

  @computed
  get lastLoginFormatted() {
    if (this.lastLogin) {
      return moment(this.lastLogin).format(`h:mm A on YYYY-MM-DD`);
    }

    return null;
  }

  @computed
  get avatarImage() {
    return (this.avatar && this.avatar.thumbUrl) || null;
  }

  @computed get initials() {
    return `${this.firstName
      ?.toUpperCase()
      .charAt(0)} ${this.lastName?.toUpperCase().charAt(0)}`;
  }

  @computed
  get createdDateFormatted() {
    return moment(this.createdDate).format('YYYY-MM-DD');
  }

  @computed
  get formValues() {
    return {
      username: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      role: this.role,
      images: this.images,
      status: this.status,
      avatar: this.avatar,
      ssoBypass: this.ssoBypass
    };
  }

  @computed
  get isWorker() {
    return this.role === 'ROLE_WORKER';
  }

  @computed get statusFormatted() {
    if (this.inviteId) {
      return t('Invited');
    }

    switch (this.status) {
      case 'DELETED':
        return t('Deleted');
      case 'INACTIVE':
        return t('Inactive');
      case 'INVITED':
        return t('Invited');
      default:
        return t('Active');
    }
  }

  @computed get statusColor() {
    if (this.inviteId) {
      return 'orange';
    }

    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'DELETED':
        return 'red';
      case 'INACTIVE':
        return 'grey';
      case 'INVITED':
        return 'orange';
      default:
        return 'green';
    }
  }
}

export default SuperAdminUser;

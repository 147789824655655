import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { RootStoreContext } from 'contexts/storeContext';

import Carousel from './Carousel';

export default observer(props => {
  const rootStore = useContext(RootStoreContext);

  return (
    <Carousel
      {...props}
      onItemClick={index => {
        rootStore.mediaViewerUI.open({
          items: props.items,
          index
        });
      }}
    />
  );
});

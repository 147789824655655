import { action, computed, observable, toJS, runInAction } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import {
  SettingsSegmentsForm,
  settingsSegmentsFormOptions,
  settingsSegmentsFormFields,
  settingsSegmentsFormRules,
  settingsSegmentsFormValues,
  settingsSegmentsFormPlugins,
  settingsSegmentsFormLabels,
  settingsSegmentsFormDisabled
} from 'forms/project/settingsSegments';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import {
  PROJECT_UPDATED,
  SEGMENTS_ENABLED,
  SEGMENTS_DISABLED
} from 'utils/segmentAnalytics/eventSpec';
import {
  SegmentsAddForm,
  segmentsAddFormOptions,
  segmentsAddFormFields,
  segmentsAddFormRules,
  segmentsAddFormLabels,
  segmentsAddFormPlugins
} from 'forms/segmentsAdd';
import request from 'axios';
import errorHandler from 'utils/errorHandler';
import isEqual from 'lodash.isequal';

export default class SettingsSegmentsUI extends SettingsChildUI {
  @observable segmentsInitialValues;
  @observable scSegmentsToggleState;

  constructor(options) {
    super(options);

    this.segmentsAddForm = null;
    this.segmentToDelete = null;

    this.scSegmentsToggleState = null;
  }

  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  initSegmentForm() {
    this.segmentsAddForm = new SegmentsAddForm(
      {
        fields: segmentsAddFormFields,
        rules: segmentsAddFormRules,
        labels: segmentsAddFormLabels,
        values: {
          name: ''
        }
      },
      {
        options: segmentsAddFormOptions,
        plugins: segmentsAddFormPlugins
      }
    );
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsSegmentsFormValues,
      this.project?.combinedSettingsFormValues,
      this.validSegmentsDefaults
    );

    this.form = new SettingsSegmentsForm(
      {
        fields: settingsSegmentsFormFields,
        rules: settingsSegmentsFormRules,
        disabled: settingsSegmentsFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsSegmentsFormLabels
      },
      {
        options: settingsSegmentsFormOptions,
        plugins: settingsSegmentsFormPlugins,
        parent: this
      }
    );

    this.segmentsInitialValues = this.form.segmentsDefinition;

    this.initSegmentForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound
  postNewSegments() {
    const postData = {
      segments: this.form.$('enableSegments').value
        ? Object.values(this.form.$('segments').value)
        : []
    };

    return request
      .post(`ra/projects/${this.project.teamId}/segmentSets`, postData)
      .then(
        response => {
          runInAction(() => {
            this.initSegmentForm();
            return (this.project.segmentSets = [
              ...this.project.segmentSets,
              ...[response.data]
            ]);
          });
        },
        error => {
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  mixPanelSegmentsStatusUpdate() {
    const segmentEnabled = this.form.$('enableSegments').value;

    if (segmentEnabled) {
      callTrack(SEGMENTS_ENABLED, {
        project_id: this.project.id,
        project_name: this.project.name,
        segment_count: this.project.latestSegmentsCount
      });
    } else {
      callTrack(SEGMENTS_DISABLED, {
        project_id: this.project.id,
        project_name: this.project.name
      });
    }
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default'];
    const projectData = omit(values, ommitedValues);

    // send a new segment set if segments or transition time has changed
    const sendSegmentsRequest = this.segmentsDefinitionChanged;

    if (sendSegmentsRequest) {
      this.segmentsInitialValues = this.form.segmentsDefinition;
      await this.postNewSegments();

      this.mixPanelSegmentsStatusUpdate();
    }

    try {
      await this.project.save(projectData, {
        wait: true
      });
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Segments settings saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    this.navigationUI.projectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get headerTitle() {
    return t('Submit multiple daily reports each day with Segments');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditSegmentSettings;
  }

  @computed
  get currentFormChanged() {
    if (!this.form) return null;

    return (
      this.form.check('isDirty') ||
      this.form.$('questions').size !==
        this.project.questionFormValues.length ||
      this.form.$('collaboratorQuestions').size !==
        this.project.collaboratorQuestionFormValues.length
    );
  }

  @computed
  get segmentsDefinitionChanged() {
    return (
      JSON.stringify(this.segmentsInitialValues) !==
      JSON.stringify(this.form.segmentsDefinition)
    );
  }

  @computed get disableSaveButton() {
    return Boolean(
      this.saving ||
        !this.hasWriteAccess ||
        this.form.segmentLengthLessThanTwo ||
        !this.currentFormChanged
    );
  }

  @computed
  get addFormNameNotUnique() {
    const segmentNames = this.form.$('segments').value.map(value => {
      return value.name;
    });

    const duplicateName = segmentNames.find(
      name => name === this.segmentsAddForm.$('name').value
    );

    if (this.form.$('enableSegments').value) {
      return duplicateName;
    } else {
      return false;
    }
  }

  @computed
  get validSegmentsDefaults() {
    return {
      segments: this.project?.combinedSettingsFormValues.segments.filter(
        ({ name }) => name
      )
    };
  }

  @action.bound
  resetForm() {
    this.resetSegmentsAddForm();
    this.clearValidationDetails();
    this.form.init(toJS(this.currentFormInitialValues));
  }

  @action.bound
  resetSegmentsAddForm() {
    this.segmentsAddForm.$('name').reset();
    this.segmentsAddForm.$('name').resetValidation();
  }

  @action.bound
  disableSegments() {
    this.hideSegmentAlertModal();

    this.segmentsAddForm.$('name').resetValidation();
    this.disableScSegments();
    this.form.update({
      enableSegments: false
    });
  }

  @action.bound
  deleteSegment() {
    const values = this.form.$('segments').value.filter(segment => {
      return !isEqual(segment, this.segmentToDelete.value);
    });

    this.form.update({
      segments: values
    });

    this.form.$('segments').map(segment => {
      segment.$('name').set('rules', 'required|between:1,60');
    });

    this.hideSegmentAlertModal();
  }

  @action.bound
  addNewSegment() {
    this.segmentsAddForm.submit({
      onSuccess: this.addNewFormFieldForNew,
      onError: this.addNewSegmentError
    });
  }

  @action.bound
  addNewFormFieldForNew() {
    this.form.update({
      segments: [
        ...this.form.$('segments').value,
        ...[{ name: this.segmentsAddForm.$('name').value }]
      ]
    });

    this.form.$('segments').map(segment => {
      segment.$('name').set('rules', 'required|between:1,60');
    });

    this.resetSegmentsAddForm();
  }

  @action.bound
  addNewSegmentError() {
    console.log(this.segmentsAddForm.errors());
  }

  @action.bound
  enableSegments() {
    if (!this.project.enableSegments) {
      this.authorization.checkFeatureAccess('EnableSegments').then(() => {
        this.form.update({
          enableSegments: true
        });
      });
    } else {
      this.form.update({
        enableSegments: true
      });
    }
  }

  @action.bound
  toggleSegments() {
    if (!this.form.$('enableSegments').value) {
      if (this.project.enableSegments) {
        this.openSegmentAlertModal(null);
      } else {
        this.disableSegments();
      }
    } else {
      this.enableSegments();
    }
  }

  @action.bound enableScSegments() {
    this.form.update({
      enableScSegments: true
    });
  }

  @action.bound disableScSegments() {
    this.form.update({
      enableScSegments: false
    });
  }

  @action.bound
  toggleScSegments() {
    if (!this.form.$('enableScSegments').value) {
      if (this.project.enableScSegments) {
        this.openSegmentScAlertModal(false);
      } else {
        this.disableScSegments();
      }
    } else {
      if (!this.project.enableScSegments && this.project.hasSegments) {
        this.openSegmentScAlertModal(true);
      } else {
        this.enableScSegments();
      }
    }
  }

  @action.bound
  openSegmentScAlertModal(toggleState) {
    this.scSegmentsToggleState = toggleState;
    this.showModal('SegmentScAlertModal');
  }

  @action.bound
  openSegmentAlertModal(segment) {
    this.showModal('SegmentsTableDeleteModal');
    // If we pass segmentToDelete as null it will put the modal in the state to show the disable sements warning
    this.segmentToDelete = segment;
  }

  @action.bound
  hideSegmentAlertModal() {
    this.hideActiveModal().then(() => {
      this.segmentToDelete = null;
    });
  }

  @action.bound
  hideSegmentScAlertModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.form.update({
          enableScSegments: this.scSegmentsToggleState
        });

        this.scSegmentsToggleState = null;
      });
    });
  }

  @computed get title() {
    return t('Segments settings');
  }
}

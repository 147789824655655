import UIStore from './UIStore';
import ProjectsMinimal from '../collections/ProjectsMinimal';
import { action, observable, computed, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class ProjectSelectorUI extends UIStore {
  @observable projectStates;
  @observable projectTypes;
  @observable filterProjectsQuery;
  @observable searchingForProject;

  constructor(options) {
    super(options);

    //Project Options Collection
    this.projectOptions = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });

    this.defaultProjectStates = options.projectStates || [
      'ACTIVE',
      'INACTIVE',
      'DELETED'
    ];

    this.defaultProjectTypes = options.projectTypes || ['PARENT', 'CHILD'];

    //Query params
    this.projectStates = this.defaultProjectStates;
    this.projectTypes = this.defaultProjectTypes;

    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.limit = 80;
    this.filterProjectsQuery = '';

    this.searchingForProject = false;

    /**
     * We can use this to fetch a list of projects for a certain user.
     * To do so we can pass a uuid to the setup function.
     */
    this.userUuid = null;

    this.fetchProjectOptionsDebounced = debounce(
      this.fetchProjectOptions,
      BASE_DEBOUNCE
    );

    this.tearDownProjectOptionsSearchReaction = reaction(
      () => this.filterProjectsQuery,
      query => {
        this.fetchProjectOptionsDebounced();
      }
    );
  }

  @computed get url() {
    if (this.isSuperAdmin) {
      return `ra/sadmin/companies/${this.superAdminUI.superAdminCompaniesUI.activeCompany.uuid}/projects`;
    }

    if (this.userUuid) {
      return `ra/companies/${this.rootStore.me.company.uuid}/members/${this.userUuid}/projects`;
    }

    return `ra/companies/${this.rootStore.me.company.uuid}/projects`;
  }

  @computed
  get projectOptionsRequestParams() {
    // Super admin accepts projectStatuses not projectStates
    if (this.isSuperAdmin) {
      return {
        sortField: this.sortField,
        sortDirection: this.sortDirection,
        query: this.filterProjectsQuery,
        limit: this.limit,
        projectStatuses: this.projectStates.join()
      };
    }

    if (this.userUuid) {
      return {
        honorSettings: true,
        sortField: this.sortField,
        sortDirection: this.sortDirection,
        query: this.filterProjectsQuery,
        limit: this.limit,
        projectStates: this.projectStates.join(),
        projectTypes: this.projectTypes.join()
      };
    }

    return {
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      query: this.filterProjectsQuery,
      limit: this.limit,
      projectStates: this.projectStates.join(),
      projectTypes: this.projectTypes.join()
    };
  }

  @action.bound
  async fetchProjectOptions(options) {
    try {
      await this.projectOptions.fetch({
        url: this.url,
        params: this.projectOptionsRequestParams
      });
      this.searchingForProject = false;
    } catch (error) {
      console.error(error);
    }
  }

  fetchNextProjectOptions = async projectsAutocomplete => {
    const dropdown = projectsAutocomplete.current;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.projectOptions.fetchNextPage()?.then(() => {
        projectsAutocomplete.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get projectOptionsForRender() {
    if (this.projectOptions.hasModels) {
      const projectOptions = this.projectOptions.models.map(projectOption => ({
        value: projectOption.optionIdValue,
        name: projectOption.name,
        startDate: projectOption.startDate,
        endDate: projectOption.endDate,
        projectNo: projectOption.projectNo,
        legacyId: projectOption.id // Used for some legacy APIs.  Eventually deprecate
      }));

      return projectOptions;
    }

    return [];
  }

  @action.bound
  setProjectOptionsSearchQuery(value) {
    this.searchingForProject = true;
    this.filterProjectsQuery = value;
  }

  @action.bound
  clearProjectOptionsSearchQuery() {
    this.filterProjectsQuery = '';
  }

  @action.bound
  clearProjectOptions() {
    this.projectOptions.reset();
  }

  @action.bound
  setParamsToDefault() {
    this.projectStates = this.defaultProjectStates;
    this.projectTypes = this.defaultProjectTypes;
    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.limit = 80;
    this.filterProjectsQuery = '';
  }

  @action.bound resetProjectOptions() {
    this.clearProjectOptions();
    this.setParamsToDefault();
    this.fetchProjectOptionsDebounced();
  }

  @action.bound setProjectStates(projectStates) {
    this.projectStates = projectStates;
  }

  @action.bound setProjectTypes(projectTypes) {
    this.projectTypes = projectTypes;
  }

  // TODO. Move options out of constructor and favour using this setup method to match the other selectors
  @action.bound async setup(options = {}) {
    this.projectStates = options.projectStates || [
      'ACTIVE',
      'INACTIVE',
      'DELETED'
    ];

    this.userUuid = options.userUuid || null;

    this.projectTypes = options.projectTypes || ['PARENT', 'CHILD'];

    await this.fetchProjectOptions();
  }

  // TODO: Create setup and teardown reactions
  // @action.bound setupReactions() {
  //   this.reactToParams = reaction(
  //     () => this.filterProjectsQuery,
  //     params => {
  //       this.fetchProjectOptions();
  //     }
  //   );
  // }
  //
  // @action.bound tearDownReactions() {
  //   this.reactToParams && this.reactToParams();
  // }

  // TODO. Remove old cleanup methods and favour using this tearDown method to match the other selectors
  @action.bound tearDown(options = {}) {
    this.filterProjectsQuery = '';
    this.projectOptions.clear();
    this.projectStates = this.defaultProjectStates;
    this.projectTypes = this.defaultProjectTypes;
  }
}

import UIStore from './UIStore';
import { computed, action, observable } from 'mobx';
import request from 'axios';

import alertErrorHandler from 'utils/alertErrorHandler';

import crossOriginDownload from 'utils/crossOriginDownload';

import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';

import {
  PHOTO_ID_APPROVE,
  PHOTO_ID_DOWNLOAD_PHOTO,
  PHOTO_ID_EMPLOYEE_PHOTO_DETAILS
} from 'utils/segmentAnalytics/eventSpec';

export default class PhotoIdMediaViewerUI extends UIStore {
  @observable items;
  @observable selectedIndex;
  @observable mediaViewerProps;
  @observable isOpen;
  @observable isApprove;
  @observable isEdit;
  @observable saving;

  constructor(options) {
    super(options);

    this.selectedIndex = 0;
    this.mediaViewerProps = {};
    this.isOpen = false;
    this.isEdit = false;
    this.isApprove = false;
    this.saving = false;

    this.saving = false;
  }

  @action.bound
  reset() {
    this.selectedIndex = 0;
    this.mediaViewerProps = {};
    this.isOpen = false;
    this.isEdit = false;
    this.isApprove = false;
    this.saving = false;
  }

  @action.bound
  open(params = {}) {
    const { items, index = 0, props = {} } = params;

    callTrack(PHOTO_ID_EMPLOYEE_PHOTO_DETAILS);

    this.items = items;
    this.selectedIndex = index || 0;
    this.mediaViewerProps = props || {};
    this.isOpen = true;
  }

  @computed
  get currentMedia() {
    return this.items.at(this.selectedIndex);
  }

  @action.bound
  handleClose() {
    this.reset();
  }

  @computed
  get hasItems() {
    return this.items?.hasModels;
  }

  @computed
  get lastItem() {
    return this.selectedIndex === this.items.length - 1;
  }

  @computed
  get hasNext() {
    return this.selectedIndex < this.items.length - 1;
  }

  @computed
  get hasPrevious() {
    return this.selectedIndex > 0;
  }

  @action.bound
  handleNext() {
    if (this.isOpen && this.hasNext) {
      this.selectedIndex += 1;
    }
  }

  @action.bound
  handlePrevious() {
    if (this.isOpen && this.hasPrevious) {
      this.selectedIndex -= 1;
    }
  }

  @action.bound
  handleDownload() {
    return new Promise((resolve, reject) => {
      if (this.currentMedia) {
        crossOriginDownload(
          `${this.currentMedia.contentUrl}?download`,
          this.currentMedia?.fileName,
          () => {
            callTrack(PHOTO_ID_DOWNLOAD_PHOTO);
            resolve();
          }
        );
      } else {
        reject();
      }
    });
  }

  @action.bound
  async openApprovePhoto() {
    await this.authorization.checkFeatureAccess('ReviewAndApproveTimeCards');

    this.isApprove = true;
  }

  @action.bound
  closeApprovePhoto() {
    this.isApprove = false;
  }

  @action.bound
  async submitApprovePhoto() {
    this.saving = true;

    try {
      const response = await request.patch(
        `${this.rootStore.urlMicroService('performanceTracking')}/photo-ids/${
          this.currentMedia.uuid
        }/approve`
      );

      this.currentMedia.set(response.data);

      this.closeApprovePhoto();

      callTrack(PHOTO_ID_APPROVE);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${t('Approved by')} ${this.me.fullName}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}

import request from 'axios';
import moment from 'moment';
import identity from 'lodash.identity';
import pickBy from 'lodash.pickby';
import { observable, action, computed, reaction } from 'mobx';
import UIStore from '../UIStore';

import Project from '../../models/Project';
import qs from 'querystringify';
import { t } from 'utils/translate';
import history from 'utils/history';

// Daily Logs
import WorkLogsUI from './WorkLogsUI';
import TimeCardsUI from './TimeCardsUI';
import NotesUI from './NotesUI';
import AttachmentsUI from './AttachmentsUI';
import SurveyUI from './SurveyUI';

// Production
import MaterialLogsUI from './MaterialLogsUI';
import EquipmentDeploymentsUI from './equipment/EquipmentDeploymentsUI';
import ProjectSmallEquipmentUI from './equipment/smallEquipment/ProjectSmallEquipmentUI';
import MapUI from './MapUI';
import UserEventsMapUI from './UserEventsMapUI';

// Checklists
import ChecklistsUI from './checklists/ChecklistsUI';

// Toolbox Talks
import ToolboxTalksUI from './ToolboxTalksUI';

// Digital Forms
import FormsUI from './forms/FormsUI';

// Documents
import DocumentsUI from 'stores/ui/project/documents/DocumentsUI';

// Tasks
import TasksUI from './TasksUI';

// Settings
import SettingsUI from './SettingsUI';

// Ancillary
import ProjectDashboardUI from '../ProjectDashboardUI';
import ProjectDirectoryUI from '../ProjectDirectoryUI';
import MediaGalleryUI from '../MediaGalleryUI';
import ProjectComplianceUI from '../ProjectComplianceUI';
import ProjectProductionInsightsUI from '../ProjectProductionInsightsUI';

// Weather
import WeatherUI from '../WeatherUI';

// Calendar
import CalendarUI from './CalendarUI';

// Preview
import PreviewUI from './PreviewUI';

// Sign
import SignUI from './SignUI';

// Unsign
import UnsignUI from './UnsignUI';

// Transfer
import TransferUI from './TransferUI';

import NoWorkDoneUI from './NoWorkDoneUI';

// Email
import EmailUI from './EmailUI';

// Observations
import ObservationsUI from './ObservationsUI';

// Incidents
import IncidentsUI from 'stores/ui/project/incidents/IncidentsUI';

// Insights
import SafetyInsightsUI from './SafetyInsightsUI';

// Report Summary Model
import ReportSummary from '../../models/ReportSummary';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class ProjectUI extends UIStore {
  @observable loading;

  @observable project;
  @observable date;
  @observable segmentUuid;
  @observable pathName;
  @observable fetchingReportSummary;

  @observable mode;

  constructor(options) {
    super(options);

    this.loading = true;

    this.pathName = null;

    this.project = null;
    this.date = null;
    this.segmentUuid = null;

    this.mode = 'project';

    // Daily Logs

    this.workLogsUI = new WorkLogsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.timeCardsUI = new TimeCardsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.notesUI = new NotesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.mapUI = new MapUI({ parent: this, rootStore: this.rootStore });

    this.userEventsMapUI = new UserEventsMapUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.attachmentsUI = new AttachmentsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.surveyUI = new SurveyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.materialLogsUI = new MaterialLogsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentDeploymentsUI = new EquipmentDeploymentsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectSmallEquipmentUI = new ProjectSmallEquipmentUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistsUI = new ChecklistsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Toolbox Talks  UI
    this.toolboxTalksUI = new ToolboxTalksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.formsUI = new FormsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.documentsUI = new DocumentsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Tasks  UI
    this.tasksUI = new TasksUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Settings
    this.settingsUI = new SettingsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Ancillary
    this.projectDashboardUI = new ProjectDashboardUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectDirectoryUI = new ProjectDirectoryUI({
      parent: this,
      rootStore: this.rootStore
    });

    // TODO rename class to ProjectGalleryUI
    this.projectGalleryUI = new MediaGalleryUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectComplianceUI = new ProjectComplianceUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectProductionInsightsUI = new ProjectProductionInsightsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Weather UI
    this.weatherUI = new WeatherUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Calendar UI
    this.calendarUI = new CalendarUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Sign UI
    this.previewUI = new PreviewUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Sign UI
    this.signUI = new SignUI({
      parent: this,
      rootStore: this.rootStore
    });

    // UnSign UI
    this.unsignUI = new UnsignUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Transfer UI
    this.transferUI = new TransferUI({
      parent: this,
      rootStore: this.rootStore
    });

    // No Work Done UI
    this.noWorkDoneUI = new NoWorkDoneUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Email UI
    // TODO can rename once post Super Daily 2.0
    this.emailReportUI = new EmailUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Report Summary
    this.reportSummary = new ReportSummary(null, {
      rootStore: this.rootStore
    });

    // Observations
    this.observationsUI = new ObservationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Incidents
    this.incidentsUI = new IncidentsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Insights
    this.safetyInsightsUI = new SafetyInsightsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchingReportSummary = false;
  }

  @computed get emailUI() {
    return this.emailReportUI;
  }

  @action.bound async setup(projectUuid, search) {
    const params = qs.parse(search);

    if (this.projectUuid === projectUuid) {
      this.setDate(params.date);
      this.setSegmentUuid(params.segmentUuid, params.action);
      return;
    }

    this.setProject(
      new Project(
        {
          uuid: projectUuid
        },
        {
          rootStore: this.rootStore
        }
      )
    );

    this.project?.cancelRequest();

    try {
      this.loading = true;

      await this.project.fetch({
        url: `${this.project.urlRoot}/${projectUuid}`
      });

      this.setDate(params.date);
      this.setSegmentUuid(params.segmentUuid, params.action);
      this.loading = false;

      if (!this.project.isActive) {
        this.showModal('ProjectActivate');
      }
    } catch (error) {
      if (error.response?.status === 403) {
        this.loading = false;
        this.showModal('ProjectAccess');
      } else {
        history.replace('/projects');
      }
    }
  }

  // Used to allow external areas access
  // to project level features
  @action.bound setMode(mode = 'project') {
    this.mode = mode;
  }

  @action.bound setProject(project) {
    this.project = project;
  }

  @action.bound
  setPathName(location) {
    this.pathName = location.pathname;
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.project = null;
    this.date = null;
    this.segmentUuid = null;
    this.weatherUI.clearUIState();
    this.loading = true;
    this.activeModal = null;
    this.mode = 'project';
  }

  @action.bound setupReactions() {
    this.cancelReactToWeatherParams = reaction(
      () => this.weatherParams,
      () => {
        if (this.weatherParams.date && this.weatherParams.address) {
          this.weatherUI.setProjectAndDate(this.project, this.date);
        }
      }
    );

    this.cancelReactToReportParams = reaction(
      () => this.reportParams,
      () => {
        if (this.reportParams) {
          this.fetchReportSummary();
        }
      }
    );
  }

  @action.bound tearDownReactions() {
    this.cancelReactToWeatherParams && this.cancelReactToWeatherParams();
    this.cancelReactToReportParams && this.cancelReactToReportParams();
  }

  @computed get projectUuid() {
    return this.project?.uuid;
  }

  @computed get projectId() {
    return this.project?.id;
  }

  @computed get weatherParams() {
    return {
      address: this.project?.address,
      date: this.date
    };
  }

  @computed get reportParams() {
    if (!this.project) return null;

    if (this.loading || !this.hasBaseQueryParams) return null;

    return pickBy(
      {
        projectUuid: this.project.uuid,
        segmentUuid: this.segmentUuid,
        reportDate: this.date
      },
      identity
    );
  }

  @computed get projectAsSelectOption() {
    if (!this.project || this.project.isNew) return null;

    return {
      value: this.project.uuid,
      name: this.project.name,
      startDate: this.project.startDate,
      endDate: this.project.endDate,
      projectNo: this.project.projectNo
    };
  }

  @action.bound async fetchReportSummary() {
    this.fetchingReportSummary = true;

    try {
      const response = await request.get(
        `ra/companies/${this.company.uuid}/reports`,
        {
          params: {
            fromDate: this.date,
            toDate: this.date,
            segmentUuids: this.segmentUuid,
            projectUuids: this.projectUuid
          }
        }
      );

      if (response.data.collection.length) {
        this.reportSummary.set(response.data.collection[0], {
          reset: true
        });
      } else {
        this.reportSummary.clear();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.fetchingReportSummary = false;
    }
  }

  @action.bound setDate(date) {
    if (!date) return;

    this.date = date;
  }

  @action.bound setSegmentUuid(segmentUuid = undefined, action) {
    if (segmentUuid === this.segmentUuid) return;

    if (
      !this.hasActiveSegments ||
      !this.activeSegments.some(segment => segment.uuid === segmentUuid)
    ) {
      this.segmentUuid = null;

      if (action) {
        history.replace({
          search: `${this.baseQueryParams}&action=${action}`
        });
      } else {
        history.replace({
          search: this.baseQueryParams
        });
      }
    } else {
      this.segmentUuid = segmentUuid;
      localStorage.setItem(`segment-${this.project.id}`, segmentUuid);
    }
  }

  @action.bound navigateToPreviousDay() {
    const date = moment(this.date).subtract(1, 'day');

    this.navigateToDate(date);
  }

  @action.bound navigateToNextDay() {
    const date = moment(this.date).add(1, 'day');

    this.navigateToDate(date);
  }

  @computed get disableNavigateToNextDay() {
    return moment(this.date).isSameOrAfter(moment(), 'd');
  }

  @action.bound navigateToToday() {
    const date = moment();

    this.navigateToDate(date);
  }

  @computed get disableNavigateToToday() {
    return moment(this.date).isSame(moment(), 'd');
  }

  @action.bound navigateToDate(date) {
    history.push({
      search: `?date=${date.format('YYYY-MM-DD')}`
    });
  }

  @action.bound navigateToSegment(segmentUuid) {
    history.push({
      search: `?date=${this.date}&segmentUuid=${segmentUuid}`
    });
  }

  @computed get hasBaseQueryParams() {
    if (this.defaultSegmentUuid) {
      return this.segmentUuid && this.date;
    }

    return this.date;
  }

  @computed get baseQueryParamsValues() {
    let values = {};

    if (this.mode === 'timesheets') {
      values.projectUuid = this.projectUuid;
    }

    values.date = this.date || moment().format('YYYY-MM-DD');
    values.segmentUuid = this.segmentUuid || this.defaultSegmentUuid;

    return values;
  }

  @computed get baseQueryParams() {
    return qs.stringify(pickBy(this.baseQueryParamsValues, identity));
  }

  @computed
  get activeSegments() {
    return this.project?.getActiveSegmentsForDate(this.date);
  }

  @computed
  get hasActiveSegments() {
    return this.activeSegments?.length > 0;
  }

  @computed get defaultSegmentUuid() {
    if (!this.hasActiveSegments) return null;

    const uuid = this.activeSegments.find(segment => {
      return (
        segment.uuid === localStorage.getItem(`segment-${this.project.id}`)
      );
    })?.uuid;

    return uuid || this.activeSegments[0].uuid;
  }

  @computed
  get selectedSegment() {
    return this.activeSegments?.find(
      segment => segment.uuid === this.segmentUuid
    );
  }

  @computed get segmentOptions() {
    return this.activeSegments?.map(segment => {
      return {
        value: segment.uuid,
        name: segment.name
      };
    });
  }

  @computed get selectedSegmentOption() {
    return this.segmentOptions.find(
      option => option.value === this.segmentUuid
    );
  }

  @action.bound
  async activateProject() {
    this.saving = true;

    try {
      await this.project.save(
        {
          projectState: 'ACTIVE'
        },
        {
          wait: true
        }
      );

      this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Project activated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelActivateProject() {
    await this.hideActiveModal();

    history.push({
      pathname: `/projects`
    });
  }

  @action.bound async cancelAccessProject() {
    await this.hideActiveModal();

    history.push({
      pathname: `/projects`
    });
  }

  @action.bound async accessProject() {
    this.saving = true;

    try {
      await this.me.save(
        {
          projectUuids: [...this.me.projectUuids.slice(), this.project.uuid]
        },
        {
          wait: true
        }
      );

      await this.hideActiveModal();

      window.location.reload();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get hasReport() {
    return this.reportSummary.uuid;
  }

  @computed get isMissedDate() {
    if (this.reportSummary?.uuid) return false;

    if (moment(this.date).isSameOrAfter(moment(), 'day')) return false;

    if (moment(this.date).isBefore(moment(this.project.startDate), 'day'))
      return false;

    return this.project.intervals?.includes(moment(this.date).day() + 1);
  }

  @computed get enableSignRoute() {
    return (
      this.reportSummary?.status === 'UNSIGNED' ||
      this.reportSummary?.status === 'CREATED'
    );
  }

  @computed get enableUnsignRoute() {
    return this.reportSummary?.status === 'COMPLETED';
  }

  @computed get enableEmailRoute() {
    return this.reportSummary?.status;
  }

  @action.bound
  openWeatherModal() {
    this.weatherUI.showModal('project');
  }

  @action.bound
  async closeWeatherModal() {
    await this.weatherUI.hideActiveModal();
  }
}

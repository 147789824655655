import { action, computed, observable, runInAction, reaction } from 'mobx';
import moment from 'moment';
import debounce from 'lodash.debounce';
import UIStore from 'stores/ui/UIStore';
import TimeCards from 'stores/collections/workLogs/TimeCards';

import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class MyTimeTimeCardsByDateUI extends UIStore {
  @observable date;
  @observable loading;
  @observable selectedTimeCard;

  constructor(options) {
    super(options);

    this.date = null;
    this.loading = true;
    this.selectedTimeCard = null;

    this.timeCards = new TimeCards(null, {
      rootStore: this.rootStore
    });

    this.fetchTimeCardsDebounced = debounce(this.fetchTimeCards, BASE_DEBOUNCE);
  }

  @computed get myTimeTimeCardAddUI() {
    return this.parent.myTimeTimeCardAddUI;
  }

  @computed get myTimeTimeCardAddPoliciesUI() {
    return this.parent.myTimeTimeCardAddPoliciesUI;
  }

  @action.bound setup(date) {
    this.setupReactions();
    this.setDate(date);
    this.fetchTimeCards();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTimeCardsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get hasTimeCards() {
    return this.timeCards.hasModels;
  }

  @computed get hasRequiredParams() {
    return (
      this.params.fromDate && this.params.toDate && this.params.workerUuids
    );
  }

  @action.bound async fetchTimeCards() {
    if (!this.hasRequiredParams) return;

    this.timeCards.cancelRequest();
    this.timeCards.clear();

    try {
      await this.timeCards.fetch({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/timecards`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound setDate(date) {
    this.date = moment(date).format('YYYY-MM-DD');
  }

  @action.bound moveToPreviousDate() {
    const date = moment(this.date, 'YYYY-MM-DD')
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    history.push(`/my-time/summary/${date}`);
  }

  @action.bound moveToNextDate() {
    const date = moment(this.date, 'YYYY-MM-DD')
      .add(1, 'day')
      .format('YYYY-MM-DD');

    history.push(`/my-time/summary/${date}`);
  }

  @action.bound clearUIState() {
    this.date = null;
    this.selectedTimeCard = null;
    this.timeCards.clear();
    this.loading = true;
  }

  @computed get params() {
    return {
      fromDate: this.date,
      toDate: this.date,
      workerUuids: this.me.workerUuid,
      sortField: 'date',
      sortDirection: 'asc',
      limit: 10000
    };
  }

  @computed get dateIsToday() {
    return moment(this.date, 'YYYY-MM-DD').isSame(moment(), 'day');
  }

  @computed get dateIsAfterToday() {
    return moment(this.date, 'YYYY-MM-DD').isAfter(moment(), 'day');
  }

  @computed get dateFormatted() {
    return moment(this.date).format('ddd, MMM DD');
  }

  @computed get disableMoveToNextDate() {
    return this.loading || this.dateIsToday || this.dateIsAfterToday;
  }

  getActions = timeCard => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editTimeCard(timeCard);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteTimeCard(timeCard);
        }
      }
    ];
  };

  @action.bound async deleteTimeCard(timeCard) {
    await this.authorization.checkFeatureAccess('EditMyTime');

    this.selectedTimeCard = timeCard;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTimeCard() {
    await this.hideActiveModal();
    this.selectedTimeCard = null;
  }

  @action.bound async confirmDeleteTimeCard() {
    this.saving = true;

    try {
      await this.selectedTimeCard.destroy({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/timecards/${
          this.selectedTimeCard.uuid
        }`,
        wait: true
      });

      this.refetchAfterAdd();

      await this.hideActiveModal();

      this.selectedTimeCard = null;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Time card deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async editTimeCard(timeCard) {
    await this.authorization.checkFeatureAccess('EditMyTime');

    history.push(`/my-time/entries/${timeCard.id}`);
  }

  @action.bound async addTimeCard(
    event,
    date = moment(this.date).format('YYYY-MM-DD')
  ) {
    event.stopPropagation();

    await this.authorization.checkFeatureAccess('EditMyTime');

    history.push({
      pathname: `/my-time/summary/${date}/add`,
      search: `?date=${this.date}`
    });
  }

  @action.bound refetchAfterAdd() {
    this.loading = true;
    this.fetchTimeCards();
    this.parent.parent.refetchPayPeriods();
  }

  @action.bound cancelTimeCardAdd() {
    history.push({
      pathname: `/my-time/summary/${this.date}`
    });
  }
}

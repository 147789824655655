import MobxReactForm from 'mobx-react-form';

import extendedPlugins from './utils/extendedPlugins';

const RoundingFormPlugins = extendedPlugins;

const RoundingFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const RoundingFormFields = [
  'timeCardRoundingMode',
  'timeCardRoundingInterval',
  'breakRounding'
];

const RoundingFormRules = {};

class RoundingForm extends MobxReactForm {}

export {
  RoundingForm,
  RoundingFormFields,
  RoundingFormRules,
  RoundingFormOptions,
  RoundingFormPlugins
};

import orderBy from 'lodash.orderby';
import { action, computed, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import Classifications from 'stores/collections/Classifications';

import IntegrationComputerEaseDirectAPIClassImportUI from './IntegrationComputerEaseDirectAPIClassImportUI';

import errorHandler from 'utils/errorHandler';
import history from 'utils/history';

import IntegrationIds from 'fixtures/integrationIds';

export default class IntegrationComputerEaseDirectAPIClassMappingUI extends UIStore {
  @observable loading;
  // Mappings
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  @observable showImportAlert;

  constructor(options) {
    super(options);

    this.loading = false;

    this.showImportAlert = false;

    // Mappings
    this.searchQuery = '';
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.computerEaseDirectAPIClassMappings = new Classifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.integrationComputerEaseDirectAPIClassImportUI = new IntegrationComputerEaseDirectAPIClassImportUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound async setup() {
    this.loading = true;

    await this.fetchComputerEaseDirectAPIClassMappings();

    this.loading = false;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound
  clearUIState() {
    this.showImportAlert = false;
    this.loading = false;
    this.page = 1;
  }

  @action.bound importComputerEaseDirectAPIClasses() {
    history.push(
      `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/classes/import`
    );
  }
  // Mappings
  @action.bound
  async fetchComputerEaseDirectAPIClassMappings() {
    try {
      await this.computerEaseDirectAPIClassMappings.fetch({
        params: {
          sortField: 'name',
          sortDirection: 'asc',
          limit: 10000,
          externalId: '*'
        }
      });

      if (this.computerEaseDirectAPIClassMappings.nextOffset) {
        this.fetchNextComputerEaseDirectAPIClassMappings();
      }
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async fetchNextComputerEaseDirectAPIClassMappings() {
    await this.computerEaseDirectAPIClassMappings.fetchNextPage();

    if (this.computerEaseDirectAPIClassMappings.nextOffset) {
      this.fetchNextComputerEaseDirectAPIClassMappings();
    }
  }

  @computed get mappingsLoading() {
    return this.computerEaseDirectAPIClassMappings.fetching;
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get mappings() {
    return this.computerEaseDirectAPIClassMappings.models;
  }

  @computed get sortedMappings() {
    if (this.sortField === 'isNew') {
      return orderBy(
        this.mappings,
        [this.sortField, 'name'],
        [this.sortDirection]
      );
    }

    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed get searchedMappings() {
    const query = this.searchQuery.toLowerCase();

    if (query) {
      return this.sortedMappings.filter(
        mapping => mapping.name.toLowerCase().indexOf(query) >= 0
      );
    }

    return this.sortedMappings;
  }

  @computed get hasSearchedMappings() {
    return this.searchedMappings.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedMappings.length / this.pageSize);
  }

  @computed
  get paginatedMappings() {
    return this.searchedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed get showTable() {
    return this.hasSearchedMappings || this.mappingsLoading;
  }

  @computed get showEmptySearchState() {
    return (
      this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @computed get showEmptyState() {
    return (
      !this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(page) {
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }
}

import { action, computed } from 'mobx';

import ProjectChildUI from './ProjectChildUI';

import { t } from 'utils/translate';
import history from 'utils/history';
import { callTrack } from 'utils/segmentIntegration';

import { MAP_EDIT_TIME_CARD_CLICKED } from 'utils/segmentAnalytics/eventSpec';

export default class UserEventsMapEventsViewUI extends ProjectChildUI {
  constructor(options) {
    super(options);
  }

  @computed
  get timeCardEvents() {
    return this.parent.timeCardEvents;
  }

  @computed
  get workerToView() {
    return this.parent.workerToView;
  }

  @action.bound
  setup(workerUuid) {
    // if we have a workerUuid Open event view
    this.parent.workerToView = this.timeCardEvents.models[0]?.workers.find(
      worker => worker.workerDetails.workerUuid === workerUuid
    );

    this.parent.fetchTimeCardEvents();
    this.parent.clearPins();
  }

  eventNameFormated(eventType) {
    switch (eventType) {
      case 'TIME_CARD_STARTED':
        return t('Clock in');
      case 'TIME_CARD_ENDED':
        return t('Clock out');
      case 'BREAK_STARTED':
        return t('Break start');
      case 'BREAK_ENDED':
        return t('Break end');
      case 'TASK_CHANGED':
        return t('Task change');
      case 'CREW_CHANGED':
        return t('Crew change');
      case 'COMMENT_ADDED':
        return t('Note added');
      case 'PAYROLL_NOTE_UPDATED':
        return t('Note updated');
      default:
        return null;
    }
  }

  getEventIcon(eventType) {
    switch (eventType) {
      case 'TIME_CARD_STARTED':
        return 'checkmark';
      case 'TIME_CARD_ENDED':
        return 'close';
      case 'BREAK_STARTED':
        return 'pause';
      case 'BREAK_ENDED':
        return 'play';
      case 'TASK_CHANGED':
        return 'list';
      case 'CREW_CHANGED':
        return 'close';
      case 'COMMENT_ADDED':
        return 'report';
      case 'PAYROLL_NOTE_UPDATED':
        return 'report';
      default:
        return null;
    }
  }

  linkToTimesheetsEdit(timeCard) {
    callTrack(MAP_EDIT_TIME_CARD_CLICKED);

    history.push(
      `/timecards/${timeCard.timesheetUuid}/timecard/${timeCard.uuid}`
    );
  }

  @computed get workerToViewTimeCards() {
    if (!this.workerToView) return [];

    const worker = this.timeCardEvents.models[0]?.workers.find(
      worker =>
        worker.workerDetails.uuid === this.workerToView.workerDetails.uuid
    );

    return worker ? worker.timeCards : [];
  }

  @action.bound
  selectEventFromSidebar(event) {
    this.parent.clearSelected();
    this.parent.selectEvent(event);

    const workerCluster = this.parent.clusterer.clusters.find(cluster => {
      const markersInCluster = cluster.markers;
      return markersInCluster.includes(event.pin);
    });

    if (!workerCluster) return;

    if (workerCluster.markers.length > 1) {
      workerCluster.marker.setIcon(
        `data:image/svg+xml;base64,${this.parent.clusterIconSelected}`
      );
    } else {
      workerCluster.marker.setIcon(this.parent.getPinIcon(event));
    }

    this.parent.map.panTo(event.pin.getPosition());
  }

  @computed
  get workerToViewFullName() {
    return `${this.workerToView.workerDetails.firstName} ${this.workerToView.workerDetails.lastName}`;
  }

  @computed get projectTimezone() {
    return this.parent.projectTimezone;
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const insightsQualityControlDisplayFiltersFormPlugins = extendedPlugins;

const insightsQualityControlDisplayFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const insightsQualityControlDisplayFiltersFormFields = [
  'checklistSummary',
  'checklistCompletion',
  'checklistCompletionByWorkUnit',
  'checklistFailedResponses',
  'insightsObservationsRaisedByType',
  'insightsObservationsRaisedByTeamMember',
  'insightsIssuesObservedHoursWorked',
  'insightsPositiveNegativeObservations'
];

const insightsQualityControlDisplayFiltersFormRules = {
  checklistSummary: 'boolean',
  checklistCompletion: 'boolean',
  checklistCompletionByWorkUnit: 'boolean',
  checklistFailedResponses: 'boolean',
  insightsObservationsRaisedByType: 'boolean',
  insightsObservationsRaisedByTeamMember: 'boolean',
  insightsIssuesObservedHoursWorked: 'boolean',
  insightsPositiveNegativeObservations: 'boolean'
};

const insightsQualityControlDisplayFiltersFormLabels = {
  checklistSummary: t('Quality overview'),
  checklistCompletion: t('Quality checklist completion'),
  checklistCompletionByWorkUnit: t('Quality checklist completion by work unit'),
  checklistFailedResponses: t('Top 5 checklist deficiencies'),
  insightsObservationsRaisedByType: t('Quality issues raised by type'),
  insightsObservationsRaisedByTeamMember: t(
    'Quality observations raised by team member'
  ),
  insightsIssuesObservedHoursWorked: t(
    'Quality issues observed / hours worked'
  ),
  insightsPositiveNegativeObservations: t(
    'Positive vs. negative quality observations by company'
  )
};

class InsightsQualityControlDisplayFiltersForm extends MobxReactForm {}

export {
  InsightsQualityControlDisplayFiltersForm,
  insightsQualityControlDisplayFiltersFormRules,
  insightsQualityControlDisplayFiltersFormFields,
  insightsQualityControlDisplayFiltersFormLabels,
  insightsQualityControlDisplayFiltersFormOptions,
  insightsQualityControlDisplayFiltersFormPlugins
};

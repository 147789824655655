import SuperAdminCompany from 'stores/models/superAdmin/SuperAdminCompany';
import UIStore from 'stores/ui/UIStore';
import { action, observable, computed } from 'mobx';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import changeUsernameReaction from 'utils/changeUsernameReaction';
import { getAllRoles } from 'utils/roles';
import request from 'axios';

import {
  UserInfoForm,
  userInfoFormOptions,
  userInfoFormFields,
  userInfoFormRules,
  userInfoFormLabels,
  userInfoFormPlugins
} from 'forms/superAdmin/userInfoForm';

import {
  UserInfoFormInvitedUser,
  userInfoFormInvitedUserOptions,
  userInfoFormInvitedUserFields,
  userInfoFormInvitedUserRules,
  userInfoFormInvitedUserLabels,
  userInfoFormInvitedUserPlugins
} from 'forms/superAdmin/userInfoFormInvitedUser';

import {
  ResetPasswordForm,
  resetPasswordFormOptions,
  resetPasswordFormFields,
  resetPasswordFormRules,
  resetPasswordFormLabels,
  resetPasswordFormPlugins
} from 'forms/superAdmin/resetPasswordForm';

export default class SuperAdminUserInfoUI extends UIStore {
  @observable activeForm;
  @observable nextURL;
  @observable superAdminResetPasswordForm;
  @observable company;

  constructor(options) {
    super(options);

    this.superAdminResetPasswordForm = null;

    this.activeForm = null;
    this.nextURL = null;
    this.company = null;
  }

  @action.bound async setup() {
    await this.fetchCompany();

    this.setupUserInfoForm();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToUsername = changeUsernameReaction(
      this.activeForm.$('username')
    );
  }

  @action.bound tearDownReactions() {
    this.reactToUsername && this.reactToUsername();
  }

  @action.bound clearUIState() {
    this.activeForm = null;
    this.nextURL = null;
    this.superAdminResetPasswordForm = null;
  }

  @computed
  get activeUser() {
    return this.parent.activeUser;
  }

  @computed
  get isInvitedUser() {
    return Boolean(this.activeUser.inviteId);
  }

  @computed
  get disableUserRoleInput() {
    return this.activeUser.isWorker;
  }

  @action.bound
  toggleIsUserActive() {
    this.activeForm
      .$('status')
      .set(
        this.activeForm.$('status').value === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      );
  }

  @action.bound
  toggleIsUserSSOBypass() {
    this.activeForm.$('ssoBypass').set(!this.activeForm.$('ssoBypass').value);
  }

  @action.bound
  setupUserInfoForm() {
    if (this.isInvitedUser) {
      this.activeForm = new UserInfoFormInvitedUser(
        {
          fields: userInfoFormInvitedUserFields,
          rules: userInfoFormInvitedUserRules,
          values: Object.assign({}, this.activeUser.formValues, {
            companyUuid: this.activeUser.company?.uuid,
            uuid: this.activeUser?.uuid
          }),
          labels: userInfoFormInvitedUserLabels
        },
        {
          options: userInfoFormInvitedUserOptions,
          plugins: userInfoFormInvitedUserPlugins
        }
      );
    } else {
      this.activeForm = new UserInfoForm(
        {
          fields: userInfoFormFields,
          rules: userInfoFormRules,
          values: Object.assign({}, this.activeUser.formValues, {
            companyUuid: this.activeUser.company?.uuid,
            uuid: this.activeUser?.uuid
          }),
          labels: userInfoFormLabels
        },
        {
          options: userInfoFormOptions,
          plugins: userInfoFormPlugins
        }
      );
    }

    this.blockHistoryIfFormChanged();
  }

  @action.bound async fetchCompany() {
    this.company = new SuperAdminCompany(
      { uuid: this.activeUser.company?.uuid },
      {
        rootStore: this.rootStore
      }
    );

    this.company.fetch();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.activeForm && this.activeForm.check('isDirty')) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formIsValid() {
    return Boolean(this.activeForm && this.activeForm.check('isValid'));
  }

  @computed
  get userRoleOptions() {
    return getAllRoles();
  }

  @action.bound
  resetUserInfoForm() {
    this.clearValidationDetails();
    this.activeForm.reset();
    this.activeForm.each(field => {
      field.resetValidation();
    });

    if (this.activeForm.$('images').value[0]) {
      this.activeForm.$('images').del(0);
    }
  }

  @action.bound
  openDeleteUserModal() {
    this.showModal('superAdminUserDeleteModal');
  }

  @action.bound
  openResetPhotoIDModal() {
    this.showModal('superAdminResetPhotoIDModal');
  }

  @action.bound
  async superAdminResetPhotoID() {
    this.saving = true;
    try {
      await request.delete(
        `ra/sadmin/users/${this.activeUser.id}/reference-photos`
      );
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Photo ID reset')
      });

      this.hideActiveModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  superAdminDeleteActiveUser() {
    this.hideActiveModal();
    if (this.activeUser.inviteId) {
      request
        .delete(`ra/sadmin/invites/${this.activeUser.id}`)
        .then(() => {
          this.superAdminDeleteActiveUserRedirectAndNotification();
        })
        .catch(error => {
          errorHandler(error, this.rootStore.notificationsUI.pushError);
        });
    } else {
      this.activeUser
        .destroy()
        .then(() => {
          this.superAdminDeleteActiveUserRedirectAndNotification();
        })
        .catch(error => {
          errorHandler(error, this.rootStore.notificationsUI.pushError);
        });
    }
  }

  @action.bound
  superAdminDeleteActiveUserRedirectAndNotification() {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      window.history.push('/');
    }
    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: this.isInvitedUser ? t('Invite deleted') : t('User deleted')
    });
  }

  @action.bound
  submitUserInfoForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.isInvitedUser) {
      this.activeForm.submit({
        onSuccess: this.submitUserInvitedSuccess,
        onError: this.submitUserInfoError
      });
    } else {
      this.activeForm.submit({
        onSuccess: this.submitUserInfoSuccess,
        onError: this.submitUserInfoError
      });
    }
  }

  @action.bound
  async submitUserInfoSuccess() {
    this.saving = true;
    // Save the active user
    const { role, ...values } = this.activeForm.trimmedValues();
    /**
     * TODO Need to figure out what we do with images vs avatar and the BE.
     * Plus if we do this this way the BE doesn't like been sent the same role if it hasn't changed and fails on validation.
     */
    const avatar = this.activeForm.$('images').value?.length
      ? {
          base64: this.activeForm.$('images').value
        }
      : undefined;
    const payload =
      role === this.activeUser.role
        ? Object.assign({}, values, {
            avatar
          })
        : Object.assign({}, values, {
            avatar,
            role
          });

    try {
      await this.activeUser.save(payload, {
        wait: true
      });

      // Show the user a notification
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${this.activeUser.fullName}'s details were updated.`
      });

      this.unblockHistory();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitUserInfoError() {
    console.log(this.activeForm.errors());
  }

  @action.bound
  async submitUserInvitedSuccess() {
    this.saving = true;

    try {
      // Save the invited user
      const {
        firstName,
        lastName,
        phone,
        username,
        role,
        password
      } = this.activeForm.trimmedValues();

      const response = await request.post(
        `/ra/sadmin/users/${this.activeUser.id}/activate`,
        {
          firstName,
          lastName,
          phone,
          username,
          role,
          password
        }
      );

      this.activeUser.set(Object.assign({}, response.data, { inviteId: null }));

      // Show the user a notification
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${this.activeUser.fullName} was activated.`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  showExitModal(nextURL) {
    this.showModal('DiscardChangesModal');
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    return this.hideActiveModal().then(() => {
      this.nextURL = null;
    });
  }

  @action.bound
  discardExitModal() {
    return this.hideActiveModal().then(() => {
      this.moveToNextURL();
    });
  }

  @action.bound unblockHistory() {
    this.unblock && this.unblock();
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }

  /*
   * Reset password
   */
  @action.bound
  openSuperAdminResetPasswordModal() {
    this.superAdminResetPasswordForm = new ResetPasswordForm(
      {
        fields: resetPasswordFormFields,
        rules: resetPasswordFormRules,
        labels: resetPasswordFormLabels,
        values: {}
      },
      {
        options: resetPasswordFormOptions,
        plugins: resetPasswordFormPlugins
      }
    );

    this.showModal('SuperAdminUserResetPasswordModal');
  }

  @action.bound
  closeSuperAdminResetPasswordModal() {
    return this.hideActiveModal().then(() => {
      this.superAdminResetPasswordForm = null;
    });
  }

  @action.bound
  submitSuperAdminResetPasswordForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.superAdminResetPasswordForm.submit({
      onSuccess: this.submitSuperAdminResetPasswordFormSuccess,
      onError: this.submitSuperAdminResetPasswordFormError
    });
  }

  @action.bound
  async submitSuperAdminResetPasswordFormSuccess() {
    this.sending = true;

    try {
      await request.patch(`/ra/sadmin/users/${this.activeUser.id}/password`, {
        password: this.superAdminResetPasswordForm.$('password').value
      });

      await this.closeSuperAdminResetPasswordModal();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${this.activeUser.fullName}'s password was updated.`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.sending = false;
    }
  }

  @action.bound
  submitSuperAdminResetPasswordFormError(values) {
    console.log(this.superAdminResetPasswordForm.error());
  }

  @computed
  get disableSSOForTrialAccount() {
    if (!this.company.subscription?.onTrial) return false;

    return this.company.onPerformancePlan;
  }
}

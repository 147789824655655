import moment from 'moment';
import React, { Suspense, lazy, Fragment, useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import provideStyle from '@raken/athens-web/lib';
import { RootStoreContext } from 'contexts/storeContext';

import { Box } from '@raken/athens-web/lib';

/**
 * App Authentication
 */
import AppConnect from './pages/connect/AppConnect';
import AppConnectSuccess from './pages/connect/AppConnectSuccess';
import AppConnectFailure from './pages/connect/AppConnectFailure';

import RouteWithProps from './components/RouteWithProps';
import Notifications from './components/Notifications';
import Onboarding from './components/onboarding/Index';
import Authorization from './components/authorization/Index';
import ProjectCreationModal from './components/ProjectCreationModal';
import CompanyNavigation from 'athens-components/CompanyNavigation/CompanyNavigation';
import SuperAdminHeader from './pages/superAdmin/components/Header';
import ImpersonateWrapper from 'athens-components/ImpersonateWrapper';
import MediaViewer from './athens-components/MediaViewer';
import media from 'utils/mediaTemplate';
import ReactLazyPreload from './utils/ReactLazyPreload';
import DebugModalPage from './pages/debug/DebugModalPage';
import SessionExpired from 'components/SessionExpired';

/**
 * Client Facing Application Chunks
 */

const Activity = ReactLazyPreload(() => import('./pages/activity/Index'));
const ReportActivity = ReactLazyPreload(() =>
  import('./pages/reportActivity/Index')
);
const LiveViews = ReactLazyPreload(() => import('./pages/liveViews/Index'));

const Insights = ReactLazyPreload(() => import('./pages/insights/Index'));
const Projects = ReactLazyPreload(() => import('./pages/projects/Index'));

const Project = ReactLazyPreload(() => import('./pages/project/Index'));

const Company = ReactLazyPreload(() => import('./pages/company/Index'));

const Team = ReactLazyPreload(() => import('./pages/team/Team'));

const DirectoryUsers = ReactLazyPreload(() =>
  import('./pages/directory/users/DirectoryUsers')
);
const DirectoryCompanies = ReactLazyPreload(() =>
  import('./pages/directory/companies/DirectoryCompanies')
);
const DirectoryContacts = ReactLazyPreload(() =>
  import('./pages/directory/contacts/DirectoryContacts')
);

const Workers = ReactLazyPreload(() => import('./pages/team/Workers'));

const Timesheets = ReactLazyPreload(() => import('./pages/timesheets/Index'));
const MyTime = ReactLazyPreload(() => import('./pages/myTime/Index'));
const Messaging = ReactLazyPreload(() => import('./pages/messaging/Index'));

/**
 * Worker Page Chunk
 */
const Worker = ReactLazyPreload(() => import('./pages/worker/Index'));

/**
 * Super Admin Chunks
 */

const SuperAdminUsers = lazy(() =>
  import('./pages/superAdmin/users/SuperAdminUsers')
);

const SuperAdminSuperUsers = lazy(() =>
  import('./pages/superAdmin/superUsers/SuperAdminSuperUsers')
);

const SuperAdminCompanies = lazy(() =>
  import('./pages/superAdmin/companies/SuperAdminCompanies')
);

const SuperAdminProjects = lazy(() =>
  import('./pages/superAdmin/projects/SuperAdminProjects')
);

const SuperAdminDemos = lazy(() =>
  import('./pages/superAdmin/demos/SuperAdminDemos')
);

const redirectToTodaysWorklog = ({ match, location }) => {
  const date = match.params.date || moment().format('YYYY-MM-DD');

  return redirect({
    pathname: `/project/${match.params.id}/${date}/worklog`,
    search: location.search
  });
};

const redirect = params => {
  return <Redirect to={{ pathname: params.pathname, search: params.search }} />;
};

const ContentWrapper = styled.div`
  padding-top: '0';
  ${media.desktop`
 padding-top: '0';`};
`;

const CompanyContent = observer(({ store }) => {
  return (
    <ContentWrapper>
      <Suspense fallback={<Fragment />}>
        <Switch>
          <RouteWithProps store={store} exact path="/" component={Activity} />

          <RouteWithProps
            store={store}
            exact
            path="/report-activity"
            component={ReportActivity}
          />

          {!store.authorizationUI.disableIntegrationsForTrialAccount && (
            <RouteWithProps
              store={store}
              exact
              path="/live-views"
              component={LiveViews}
            />
          )}

          <RouteWithProps
            store={store}
            path="/insights/:section?"
            component={Insights}
          />

          <RouteWithProps
            store={store}
            exact
            path="/(projects|projects/create|projects/map|projects/map/workers|map|map/workers)"
            component={Projects}
          />

          <RouteWithProps
            store={store}
            path="/projects/:id/:section?"
            component={Project}
            uiStore={store.projectUI}
          />

          <RouteWithProps
            store={store}
            path={['/company-settings', '/company']}
            component={Company}
          />

          {store.featureFlags.FF_COMPANY_CONTACTS && (
            <RouteWithProps
              store={store}
              uiStore={store.directoryUsersUI}
              path="/team/:id?"
              component={DirectoryUsers}
            />
          )}

          {store.featureFlags.FF_COMPANY_CONTACTS && (
            <RouteWithProps
              store={store}
              uiStore={store.directoryCompaniesUI}
              path="/companies"
              component={DirectoryCompanies}
            />
          )}

          {store.featureFlags.FF_COMPANY_CONTACTS && (
            <RouteWithProps
              store={store}
              uiStore={store.directoryContactsUI}
              path="/contacts"
              component={DirectoryContacts}
            />
          )}

          {!store.featureFlags.FF_COMPANY_CONTACTS && (
            <RouteWithProps
              store={store}
              uiStore={store.teamMembersUI}
              path="/team/:id?"
              component={Team}
            />
          )}

          <RouteWithProps
            store={store}
            path="/workers"
            component={Workers}
            uiStore={store.teamWorkersUI}
          />

          <RouteWithProps
            store={store}
            path={['/timesheets', '/timecards', '/review']}
            component={Timesheets}
          />

          {store.authorizationUI.canViewMyTime && (
            <RouteWithProps
              store={store}
              uiStore={store.myTimeUI}
              path="/my-time"
              component={MyTime}
            />
          )}

          <Route path={`/project/:id`} exact render={redirectToTodaysWorklog} />

          <Route
            path={`/project/:id/:date([0-9]{4}-[0-9]{2}-[0-9]{2})`}
            exact
            render={redirectToTodaysWorklog}
          />

          <RouteWithProps
            store={store}
            path="/connect"
            component={AppConnect}
            exact
          />

          <RouteWithProps
            store={store}
            path="/connect/success"
            component={AppConnectSuccess}
            exact
          />

          <RouteWithProps
            store={store}
            path="/connect/failure"
            component={AppConnectFailure}
            exact
          />

          <RouteWithProps
            store={store}
            path="/session-expired"
            component={SessionExpired}
            exact
          />

          <RouteWithProps
            store={store}
            uiStore={store.messagingUI}
            path="/messaging/:action?"
            component={Messaging}
          />

          <Redirect to={'/'} />
        </Switch>
      </Suspense>
    </ContentWrapper>
  );
});

const CompanyPortals = observer(({ store }) => {
  if (store.sessionExpired || store.maintenanceMode) return null;

  return (
    <Fragment>
      <Onboarding uiStore={store.onboardingUI} />
      <ProjectCreationModal uiStore={store.projectCreationUI} />
      <Notifications uiStore={store.notificationsUI} />
      <Authorization store={store} uiStore={store.authorizationUI} />

      <MediaViewer
        uiStore={store.mediaViewerUI}
        {...store.mediaViewerUI.mediaViewerProps}
      />
    </Fragment>
  );
});

export default observer(({ location }) => {
  const store = useContext(RootStoreContext);

  useEffect(() => {
    if (location.pathname.indexOf('/connect') > -1) {
      store.setAppConnectMode(true);
    }

    window.onload = () => {
      // Pre load ReactLazyPreload
      Activity.preload();
      Insights.preload();
      Projects.preload();
      Project.preload();
      Company.preload();
      if (store.featureFlags.FF_COMPANY_CONTACTS) {
        DirectoryUsers.preload();
      } else {
        Team.preload();
      }
    };
  }, [store, location.pathname]);

  // Do not boot until we have the current user data and subscription.
  if (store.loading) return null;

  if (store.isSuperAdmin) {
    return provideStyle(
      <>
        {!store.hideMainNavigation && (
          <SuperAdminHeader store={store} location={location} />
        )}

        <Suspense fallback={<Fragment />}>
          <Box minWidth={store.hideMainNavigation ? 0 : 1024}>
            <Switch>
              <RouteWithProps
                store={store}
                uiStore={store.superAdminUI.superAdminUsersUI}
                path="/users"
                component={SuperAdminUsers}
              />

              <RouteWithProps
                store={store}
                path="/super-users"
                uiStore={store.superAdminUI.superAdminSuperUsersUI}
                component={SuperAdminSuperUsers}
              />

              <RouteWithProps
                store={store}
                uiStore={store.superAdminUI.superAdminCompaniesUI}
                path="/companies"
                component={SuperAdminCompanies}
              />

              <RouteWithProps
                store={store}
                uiStore={store.superAdminUI.superAdminProjectsUI}
                path="/projects"
                component={SuperAdminProjects}
              />

              <RouteWithProps
                uiStore={store.superAdminUI.superAdminDemosUI}
                path="/demos"
                component={SuperAdminDemos}
              />

              <RouteWithProps
                store={store}
                path="/connect"
                component={AppConnect}
                exact
              />

              <RouteWithProps
                store={store}
                path="/connect/success"
                component={AppConnectSuccess}
                exact
              />

              <RouteWithProps
                store={store}
                path="/connect/failure"
                component={AppConnectFailure}
                exact
              />

              <RouteWithProps
                store={store}
                path="/session-expired"
                component={SessionExpired}
                exact
              />

              <Redirect to="/users" />
            </Switch>
            <Notifications uiStore={store.notificationsUI} />
          </Box>
        </Suspense>
      </>
    );
  }

  if (store.isWorker) {
    return provideStyle(
      <Suspense fallback={<Fragment />}>
        <RouteWithProps store={store} path="/" component={Worker} />
      </Suspense>
    );
  }

  return provideStyle(
    <Fragment>
      {store.hideMainNavigation ? (
        <CompanyContent store={store} />
      ) : (
        <>
          <CompanyNavigation
            avatarImg={store.me.avatarImage}
            headerUI={store.headerUI}
            navigationUI={store.navigationUI}
            location={location}
          >
            <CompanyContent store={store} />
          </CompanyNavigation>
        </>
      )}
      <CompanyPortals store={store} />
      {store.isSudoing && <ImpersonateWrapper store={store} />}
      {store.showDebugSection && <DebugModalPage debugUI={store.debugUI} />}
    </Fragment>
  );
});

import omit from 'lodash.omit';
import sumBy from 'lodash.sumby';
import { action, computed, reaction } from 'mobx';
import getSeriesColorHex from '../../utils/getSeriesColorHex';
import sortProjectMeasuresByProjectNameOrStat from './utils/sortProjectMeasuresByProjectNameOrStat';

import InsightsChart from './InsightsChart';
import ProjectMeasures from '../collections/ProjectMeasures';

export default class InsightsOpenTasks extends InsightsChart {
  constructor(attributes, options) {
    super(attributes, options);

    this.projectMeasures = new ProjectMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params?.projectIds || params?.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.projectMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/currentOpenTasks';
  }

  @action.bound
  parse(attributes) {
    this.parseProjectMeasures(attributes.projectMeasures);

    return {
      ...omit(attributes, ['projectMeasures'])
    };
  }

  @action.bound
  parseProjectMeasures(projectMeasures) {
    this.projectMeasures.reset(projectMeasures || []);
    this.fillEmptyProjectMeasures();
  }

  @action.bound
  fillEmptyProjectMeasures() {
    this.insightsUI.filteredProjects.forEach((project, index) => {
      const projectMeasure = this.projectMeasures.models.find(
        projectMeasure => {
          return projectMeasure.project.id === project.parentProjectId;
        }
      );

      if (!projectMeasure) {
        this.projectMeasures.add({
          project: {
            id: project.parentProjectId,
            name: project.name
          },
          stats: {
            openTasks: 0,
            openTasksPercent: 0
          }
        });
      }
    });
  }

  @computed
  get projectMeasuresTotal() {
    return sumBy(
      this.projectMeasures.models,
      measure => measure.stats.openTasks
    );
  }

  @computed
  get selectedProjectMeasures() {
    const projectMeasures = [];

    this.chartProjectIds.forEach((id, index) => {
      const selectedMeasure = this.projectMeasures.models.find(
        projectMeasure => projectMeasure.project.id === id
      );

      if (selectedMeasure) {
        selectedMeasure.index = index;
        projectMeasures.push(selectedMeasure);
      }
    });

    return projectMeasures;
  }

  @computed
  get selectedProjectMeasuresTotal() {
    return sumBy(
      this.selectedProjectMeasures,
      measure => measure.stats.openTasks
    );
  }

  @computed
  get sortedSelectedProjectMeasures() {
    return sortProjectMeasuresByProjectNameOrStat(
      this.selectedProjectMeasures,
      this.sortField,
      this.sortDirection
    );
  }

  @computed
  get nonSelectedProjectMeasures() {
    return this.projectMeasures.models
      .filter(projectMeasure => {
        return !this.chartProjectIds.includes(projectMeasure.project.id);
      })
      .map((projectMeasure, index) => {
        projectMeasure.index = index;
        return projectMeasure;
      });
  }

  @computed
  get nonSelectedProjectMeasuresTotal() {
    return sumBy(
      this.nonSelectedProjectMeasures,
      measure => measure.stats.openTasks
    );
  }

  @computed
  get sortedNonSelectedProjectMeasures() {
    return sortProjectMeasuresByProjectNameOrStat(
      this.nonSelectedProjectMeasures,
      this.sortField,
      this.sortDirection
    );
  }

  @computed
  get hasDataWithValues() {
    return this.projectMeasuresTotal > 0;
  }

  @computed
  get chartSeries() {
    const projectMeasures = [];

    this.chartProjects.forEach(project => {
      const projectMeasure = this.projectMeasures.models.find(
        projectMeasure => projectMeasure.project.id === project.parentProjectId
      );

      if (projectMeasure && projectMeasure.stats.openTasks > 0) {
        projectMeasures.push(projectMeasure);
      }
    });

    const series = projectMeasures.map((projectMeasure, index) => {
      return {
        id: projectMeasure.project.id,
        name: projectMeasure.project.name,
        percent: projectMeasure.stats.openTasksPercent,
        url: projectMeasure.taskUrl,
        color: getSeriesColorHex(
          this.chartProjectIds.indexOf(projectMeasure.project.id)
        ),
        data: [
          {
            x: index + 1,
            y: index % 2,
            z: projectMeasure.stats.openTasks,
            percent: projectMeasure.stats.openTasksPercent
          }
        ]
      };
    });

    return series;
  }

  @computed
  get hasChartData() {
    return this.chartSeries.length > 0;
  }
}

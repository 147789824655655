import React from 'react';
import { observer } from 'mobx-react';
import { Link } from '@raken/athens-web/lib';

import { HOME_BUTTON_CLICKED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

const CompanyNavigationHeader = observer(() => {
  return (
    <Link
      onClick={() => {
        callTrack(HOME_BUTTON_CLICKED);
      }}
      to={{
        pathname: '/'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="26"
        viewBox="0 0 40 26"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4464 15.3783C39.4739 16.1272 39.1094 16.8462 38.4565 17.3311C37.5088 18.0075 36.425 18.5229 35.2608 18.8507C33.7651 19.2923 32.3225 19.8611 30.9536 20.549C29.2162 21.4703 27.5549 22.4973 25.9821 23.6225C24.0456 25.0092 21.8139 25.5411 19.4215 25.6665C15.8811 25.8374 12.3314 25.5402 8.89229 24.7851C6.39134 24.2456 3.92512 23.6073 1.9452 22.0496C0.0825172 20.5832 -0.121553 18.8887 1.68469 17.4184C3.01332 16.3319 3.25212 15.0895 3.33896 13.6914C3.33896 13.4559 3.33896 13.2127 3.37804 12.9772C4.0988 8.01165 7.71997 4.12888 12.2443 2.00133C13.6931 1.31075 15.2539 0.817524 16.8727 0.538648C17.8264 0.38795 18.7915 0.299061 19.7601 0.272705C20.4505 0.272705 21.1061 0.272705 21.7314 0.272705C21.7314 0.272705 19.0437 0.534849 17.5848 1.58722C16.1259 2.6396 15.8481 3.65778 16.4038 3.32725C19.873 1.34408 23.6331 0.139734 27.8752 0.538648C29.5468 0.698214 32.0304 1.35547 33.0855 2.48763C31.8263 2.76497 29.9854 2.84095 28.7175 3.33105C27.0111 3.99591 26.4858 4.45181 26.4858 4.86972C26.4858 5.09767 28.0445 4.67596 29.7466 4.36823C30.9438 4.15335 32.1594 4.02627 33.3808 3.98831C34.9482 3.98831 35.5083 4.36823 36.2681 5.24964C37.7346 7.01776 38.6976 9.06653 39.0774 11.2258C39.2872 12.603 39.4104 13.9892 39.4464 15.3783Z"
          fill="#C8C7C6"
        />
      </svg>
    </Link>
  );
});

export default CompanyNavigationHeader;

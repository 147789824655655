import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable, runInAction, reaction } from 'mobx';

import request from 'axios';

import {
  ShiftForm,
  shiftFormOptions,
  shiftFormFields,
  shiftFormRules,
  shiftFormPlugins
} from 'forms/shift';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

import Shifts from 'stores/collections/Shifts';
import Shift from 'stores/models/Shift';

export default class PolicyShiftsUI extends PolicyChildEditUI {
  @observable form;
  @observable saving;
  @observable loading;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.form = null;
    this.shiftForEdit = null;
    this.saving = false;
    this.loading = true;

    this.page = 1;
    this.pageSize = 25;

    this.shifts = new Shifts(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.fetchShifts();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound
  clearUIState() {
    this.page = 1;
    this.loading = true;
    this.shifts.clear();
    this.clearValidationDetails();
    this.shiftForEdit = null;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed
  get totalPages() {
    return Math.ceil(this.shifts.totalElements / this.pageSize);
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize
    };
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchShifts();
        });
      }
    );
  }

  @action.bound
  async fetchShifts() {
    this.loading = true;

    this.shifts.cancelRequest();
    this.shifts.clear();

    await this.shifts.fetch({
      url: `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/companies/${this.rootStore.me.company.uuid}/shifts?timePolicyUuid=${
        this.entryForEdit.uuid
      }`,
      params: { ...this.params }
    });

    this.loading = false;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound addShift() {
    const shift = new Shift(
      {},
      {
        rootStore: this.rootStore
      }
    );
    this.editShift(shift);
  }

  @action.bound
  editShift(shift) {
    this.shiftForEdit = shift;
    this.initPolicyShiftForm();
  }

  @action.bound cancelShiftEdit() {
    this.form = null;
    this.shiftForEdit = null;
    this.clearValidationDetails();
  }

  @action.bound
  initPolicyShiftForm() {
    const { name, code } = this.shiftForEdit;

    this.form = new ShiftForm(
      {
        fields: shiftFormFields,
        rules: shiftFormRules,
        values: {
          name,
          code
        }
      },
      {
        options: shiftFormOptions,
        plugins: shiftFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @computed get shiftEditModalTitle() {
    return this.shiftForEdit.isNew ? `Add shift` : `Edit shift`;
  }

  @action.bound submitShiftEditForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.shiftForEdit.uuid
        ? this.submitShiftEditFormSuccess
        : this.submitShiftAddFormSuccess,
      onError: this.submitShiftEditFormError
    });
  }

  @action.bound submitShiftEditFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitShiftAddFormSuccess() {
    this.saving = true;
    try {
      await this.shiftForEdit.save(
        { ...this.form.values() },
        {
          wait: true
        }
      );

      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/shifts/${
          this.shiftForEdit.uuid
        }?enabled=true`
      );

      this.fetchShifts();
      this.cancelShiftEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${t('Shift added')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async submitShiftEditFormSuccess() {
    this.saving = true;

    try {
      await this.shiftForEdit.save(
        { ...this.form.values() },
        {
          wait: true
        }
      );

      this.saving = false;

      this.fetchShifts();
      this.cancelShiftEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${t('Shift updated')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async toggleShiftEnabled(shift) {
    shift.policyEnabled = !shift.policyEnabled;
    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/shifts/${shift.uuid}?enabled=${
          shift.policyEnabled
        }`
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Shift updated')}`
    });
  }

  @action.bound async deleteShift(shift) {
    this.shiftForDelete = shift;

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteShift() {
    await this.hideActiveModal();

    this.shiftForDelete = null;
  }

  @action.bound async confirmDeleteShift() {
    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/shifts/${
          this.shiftForDelete.uuid
        }?enabled=false`
      );

      await this.shiftForDelete.destroy({ wait: true });

      this.fetchShifts();
      this.hideActiveModal();

      this.shiftForDelete = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Shift deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}

import moment from 'moment';
import { action, computed } from 'mobx';
import qs from 'querystringify';
import history from 'utils/history';

import UIStore from './UIStore';

export default class TimesheetsAddUI extends UIStore {
  @action.bound setup() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });
    this.projectUI.setMode('timesheets');
  }

  @action.bound tearDown() {
    this.projectSelectorUI.tearDown();
    this.projectUI.tearDown();
  }

  @computed get workLogAddUI() {
    return this.projectUI.timeCardsUI.workLogAddUI;
  }

  @computed get workLogAddCrewUI() {
    return this.workLogAddUI.workLogAddCrewUI;
  }

  @action.bound navigate(search) {
    const params = qs.parse(search);

    if (params.projectUuid && params.date) {
      if (params.segmentUuid) {
        this.projectUI.setup(
          params.projectUuid,
          `?date=${params.date}&segmentUuid=${params.segmentUuid}`
        );
      } else {
        this.projectUI.setup(params.projectUuid, `?date=${params.date}`);
      }
    }
  }

  @action.bound async setSelectedProject(option) {
    if (this.selectedProjectOption.value === option.value) return;

    history.push({
      search: `?projectUuid=${option.value}&date=${this.selectedDate}`
    });
  }

  @computed get selectedProjectOption() {
    return {
      value: this.projectUI.project?.uuid,
      name: this.projectUI.project?.name
    };
  }

  @action.bound setSelectedDate(value) {
    if (this.selectedDate === value) return;

    history.push({
      search: `?projectUuid=${this.projectUI.projectUuid}&date=${moment(
        value
      ).format('YYYY-MM-DD')}`
    });
  }

  @computed get selectedDate() {
    return this.projectUI.date || moment().format('YYYY-MM-DD');
  }

  @computed get hasActiveSegments() {
    return this.projectUI.project && this.projectUI.hasActiveSegments;
  }

  @computed get segmentOptions() {
    return this.projectUI.segmentOptions;
  }

  @computed get selectedSegmentOption() {
    return this.projectUI.selectedSegmentOption;
  }

  @action.bound setSelectedSegment(option) {
    if (this.selectedSegmentOption.uuid === option.value) return;

    history.push({
      search: `?projectUuid=${this.projectUI.projectUuid}&date=${this.projectUI.date}&segmentUuid=${option.value}`
    });
  }

  @computed get showEmptyState() {
    return !this.loading && (!this.projectUI.project || !this.projectUI.date);
  }

  @computed get loading() {
    return this.projectUI.project?.fetching;
  }

  @computed get showUI() {
    return this.projectUI.project && this.projectUI.date;
  }
}

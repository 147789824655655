import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import {
  observationTypeFormFields,
  observationTypeFormOptions,
  observationTypeFormPlugins,
  observationTypeFormRules,
  observationTypeFormLabels,
  observationTypeFormRelated,
  ObservationTypeForm
} from 'forms/observationType';

import ObservationType from 'stores/models/ObservationType';
import ObservationTypes from 'stores/collections/ObservationTypes';
import uniqBy from 'lodash.uniqby';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class ObservationTypeEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound async setup(id) {
    this.fetchObservations();
    this.fetchEntry(id);
  }

  @action.bound async fetchObservations() {
    this.loading = true;
    this.observationTypes.cancelRequest();
    this.observationTypes.reset();
    try {
      await this.observationTypes.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      const model = new ObservationType(
        {
          uuid: uuid
        },
        {
          rootStore: this.rootStore
        }
      );
      await model.fetch();
      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelObservationTypeEdit();
    }
  }

  @computed get hasObservations() {
    return this.observationTypes.hasModels;
  }

  @computed get observationClassOptions() {
    const classesList = this.observationTypes.models.map(type => {
      return {
        value: type.typeClass,
        name: type.typeClass
      };
    });
    return uniqBy(classesList, 'name');
  }

  @computed get observationTypeOptions() {
    const typesList = this.observationTypes.models.map(type => {
      return {
        value: type.type,
        name: type.type,
        class: type.typeClass
      };
    });
    return uniqBy(typesList, 'value');
  }

  @computed get disableSave() {
    return this.saving;
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
    this.entryEditForm = new ObservationTypeForm(
      {
        fields: observationTypeFormFields,
        rules: observationTypeFormRules,
        labels: observationTypeFormLabels,
        values: this.entryForEdit.formValues,
        related: observationTypeFormRelated
      },
      {
        options: observationTypeFormOptions,
        plugins: observationTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @action.bound
  async submitObservationTypesEditSuccess() {
    const {
      typeName,
      subType,
      negative,
      positive
    } = this.entryEditForm.trimmedValues();

    this.clearValidationDetails();
    this.saving = true;

    try {
      await this.entryForEdit.save(
        {
          type: typeName,
          subType,
          negative,
          positive
        },
        {
          wait: true
        }
      );

      this.parent.sortByLastUpdated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation type edited')
      });
      this.cancelObservationTypeEdit();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitObservationTypesEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitObservationTypesEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  cancelObservationTypeEdit() {
    history.push({
      pathname: `/company/observations/types`,
      search: this.baseQueryParams
    });
  }
}

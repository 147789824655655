import { RakenCollection } from '../lib';
import EquipmentLogReport from '../models/EquipmentLogReport';

export default class EquipmentLogReports extends RakenCollection {
  model() {
    return EquipmentLogReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${
      this.rootStore.me.company.uuid
    }/reports/equipment/logs/scheduled`;
  }
}

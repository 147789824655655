import UIStore from './UIStore';
import { computed, action } from 'mobx';

import ProjectDashboardActivityUI from './ProjectDashboardActivityUI';
import ProjectDashboardInsightsUI from './ProjectDashboardInsightsUI';

import history from 'utils/history';

export default class ProjectDashboardUI extends UIStore {
  constructor(options) {
    super(options);

    // ProjectDashboardActivity
    this.projectDashboardActivityUI = new ProjectDashboardActivityUI({
      rootStore: this.rootStore,
      parent: this
    });

    // ProjectDashboardActivity
    this.projectDashboardInsightsUI = new ProjectDashboardInsightsUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @action.bound cancelDashboard() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.parent.baseQueryParams
    });
  }
}

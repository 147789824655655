const usersData = {
  page: {
    previous: 'http://example.com/users?page=1',
    self: 'http://example.com/users?page=2',
    next: 'http://example.com/users?page=3'
  },
  summary: {
    collaborators: 20,
    members: 10
  },
  collection: [
    {
      id: 1,
      type: 'User',
      title: 'Mr',
      firstName: 'Nick',
      lastName: 'Ryall',
      email: 'nick.ryall@gmail.com',
      phone: '021552497',
      lastLogin: '2017-06-21T03:44:45+0000',
      company: {
        id: 1
      }
    },
    {
      id: 2,
      type: 'User',
      title: 'Mr',
      firstName: 'John',
      lastName: 'Jones',
      email: 'john.jones@gmail.com',
      phone: '021552497',
      lastLogin: '2017-06-21T03:44:45+0000',
      isPending: true,
      company: {
        id: 2
      }
    },
    {
      id: 3,
      type: 'User',
      title: 'Mr',
      firstName: 'Tim',
      lastName: 'Smith',
      email: 'tim.smith@gmail.com',
      phone: '021552497',
      company: {
        id: 2
      }
    },
    {
      id: 4,
      type: 'User',
      title: 'Mr',
      firstName: 'Jane',
      lastName: 'Aire',
      email: 'jane.aire@gmail.com',
      phone: '021552497',
      lastLogin: '2017-06-21T03:44:45+0000',
      company: {
        id: 3
      }
    }
  ]
};

const companiesData = {
  page: {
    previous: 'http://example.com/companies?page=1',
    self: 'http://example.com/companies?page=2',
    next: 'http://example.com/companies?page=3'
  },
  collection: [
    {
      id: 1,
      name: 'BiznessName1',
      status: 'ACTIVE',
      images: [
        {
          type: 'Media',
          id: 57313,
          createdTimestamp: 1500266713000,
          createdBy: 26478,
          fileName: 'raken.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/icon-2001270203581133964805.png',
          thumbUrl:
            'http://cdn.rakenapp.com/images/icon-2001270203581133964805.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 5215,
          uploadDate: '2017-07-17T04:45:13+0000',
          takenDate: '2017-06-21T03:44:45+0000'
        }
      ],
      hasSuperDaily: true,
      billingPlan: 'Performance',
      address: {
        type: 'Address',
        streetAddress: '333 N Wilton Pl',
        city: 'Plaucheville',
        state: 'LA',
        postCode: '71362',
        country: 'US',
        timezone: 'America/Chicago',
        geolocation: {
          lat: 30.984297,
          lng: -91.96233
        }
      },
      preferences: {
        weekFirstDay: 7,
        primaryColor: '#4e5939',
        fahrenheit: true,
        registration_completed: true,
        showTimeCardsDivision: false,
        secondaryColor: '#000000',
        dateFormat: 'MM/dd'
      },
      subscription: {
        active: true,
        autoRenew: true,
        billingPlanKey: 'performance',
        billingPlanName: 'Performance',
        companyId: 22649,
        deleting: false,
        endDate: '2020-03-18',
        fetching: false,
        forceDowngrade: false,
        periodType: 'ANNUAL',
        pricePerSeat: 444,
        pricePerSeatToday: 417.31,
        saving: false,
        seats: 6,
        seatsInUse: 6,
        startDate: '2019-03-19',
        subscriptionState: 'ACTIVE',
        trialEndDate: undefined,
        type: 'Subscription',
        upcomingBillingAmount: 266400,
        upcomingBillingDate: '2020-03-19',
        upcomingBillingPlanKey: undefined,
        upcomingPeriodType: undefined,
        upcomingSubscriptionPlan: undefined
      }
    },
    {
      id: 2,
      name: 'BiznessName2',
      status: 'ACTIVE',
      images: [
        {
          type: 'Media',
          id: 57313,
          createdTimestamp: 1500266713000,
          createdBy: 26478,
          fileName: 'raken.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/icon-2001270203581133964805.png',
          thumbUrl:
            'http://cdn.rakenapp.com/images/icon-2001270203581133964805.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 5215,
          uploadDate: '2017-07-17T04:45:13+0000',
          takenDate: '2017-06-21T03:44:45+0000'
        }
      ],
      hasSuperDaily: true,
      billingPlan: 'Professional',
      address: {
        type: 'Address',
        streetAddress: '59 Rua Road',
        city: 'Auckland',
        state: 'Auckland',
        postCode: '0603',
        country: 'NZ',
        timezone: 'Auckland/Pacific',
        geolocation: {
          lat: -36.9131917,
          lng: 174.65976360000002
        }
      },
      preferences: {
        weekFirstDay: 7,
        primaryColor: '#4e5939',
        fahrenheit: true,
        registration_completed: true,
        showTimeCardsDivision: false,
        secondaryColor: '#000000'
      }
    }
  ]
};

const projectsData = {
  collection: [
    {
      id: 2,
      uuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4',
      teamId: 2,
      type: 'Project',
      projectState: 'ACTIVE',
      startDate: '2017-05-23T04:36:49+00:00',
      createdTimestamp: '2017-05-23T04:36:49+00:00',
      name: 'Lincoln Place Apartments',
      projectNo: '124',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '1050 Frederick St',
        city: 'Venice',
        state: 'CA',
        postCode: '90291',
        timezone: 'America/Los_Angeles',
        country: 'US',
        geolocation: {
          lat: 33.9992489,
          lng: -118.4602105
        }
      },
      overallStats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 3,
      uuid: '5dd39cc7-dd82-4ad2-b67f-29e2977c94d4',
      teamId: 3,
      type: 'Project',
      projectState: 'INACTIVE',
      name: 'Wilshire Grand Center',
      startDate: '2015-01-15T04:36:49+00:00',
      createdTimestamp: '2015-01-15T04:36:49+00:00',
      projectNo: '125',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '900 Wilshire Blvd',
        city: 'Los Angeles',
        state: 'CA',
        postCode: '92007',
        country: 'US',
        geolocation: {
          lat: 34.0501216,
          lng: -118.2595763
        },
        timezone: 'America/Los_Angeles'
      },
      overallStats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 4,
      uuid: '75e58cb2-9cef-4c8e-9b55-6d7102e1a1d2',
      teamId: 4,
      type: 'Project',
      projectState: 'DELETED',
      startDate: '2015-03-11T04:36:49+00:00',
      createdTimestamp: '2015-03-11T04:36:49+00:00',
      name: 'Village at Westfield Topanga',
      projectNo: '126',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '96600 Topanga Canyon Blvd',
        city: 'Canoga Park',
        state: 'CA',
        postCode: '91303',
        country: 'US',
        geolocation: {
          lat: 34.1900772,
          lng: -118.6042674
        },
        timezone: 'America/Los_Angeles'
      },
      overallStats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 5,
      uuid: 'd0b8c10a-48cb-442f-a03a-e0faaf365c44',
      teamId: 5,
      type: 'Project',
      projectState: 'INACTIVE',
      startDate: '2016-04-11T04:36:49+00:00',
      createdTimestamp: '2015-03-11T04:36:49+00:00',
      name: 'Torrance Memorial Medical Center',
      projectNo: '127',
      streetAddress: '3330 Lomita Blvd',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        city: 'Torrance',
        state: 'CA',
        postCode: '90505',
        country: 'US',
        geolocation: {
          lat: 33.8119157,
          lng: -118.3434945
        },
        timezone: 'America/Los_Angeles'
      },
      overallStats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 6,
      uuid: '876f023b-3c99-4404-a91e-dc26daea7f9e',
      teamId: 6,
      type: 'Project',
      projectState: 'ACTIVE',
      startDate: '2016-08-18T04:36:49+00:00',
      createdTimestamp: '2016-08-18T04:36:49+00:00',
      name: 'Rose Bowl Rennovation',
      projectNo: '128',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '1001 Rose Bowl Dr',
        city: 'Pasadena',
        state: 'CA',
        postCode: '91103',
        country: 'US',
        geolocation: {
          lat: 34.1624472,
          lng: -118.1665604
        },
        timezone: 'America/Los_Angeles'
      },
      stats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 7,
      uuid: 'a5896491-271a-4b2e-84e5-da25b2492e70',
      teamId: 7,
      type: 'Project',
      projectState: 'ACTIVE',
      startDate: '2016-12-18T04:36:49+00:00',
      createdTimestamp: '2016-12-18T04:36:49+00:00',
      name: 'Jordan High School',
      projectNo: '128',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '6500 Atlantic Ave',
        city: 'Long Beach',
        state: 'CA',
        postCode: '90805',
        country: 'US',
        geolocation: {
          lat: 33.8709466,
          lng: -118.1850493
        },
        timezone: 'America/Los_Angeles'
      },
      stats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 8,
      uuid: 'ebab7873-8bb2-42d4-aedb-970b5f2dfe8c',
      teamId: 8,
      type: 'Project',
      projectState: 'ACTIVE',
      startDate: '2016-10-25T04:36:49+00:00',
      name: 'The Camden Apartments',
      createdTimestamp: '2016-12-18T04:36:49+00:00',
      projectNo: '129',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '1540 N. Vine St',
        city: 'Hollywood',
        state: 'CA',
        postCode: '90028',
        country: 'US',
        geolocation: {
          lat: 34.0989441,
          lng: -118.3266888
        },
        timezone: 'America/Los_Angeles'
      },
      stats: {
        workers: 85,
        hours: 125000
      }
    },
    {
      id: 9,
      uuid: '8d082e60-43a5-4280-a4b8-7225ee1ff5ce',
      teamId: 9,
      projectState: 'ACTIVE',
      startDate: '2016-09-25T04:36:49+00:00',
      createdTimestamp: '2016-09-25T04:36:49+00:00',
      name: 'Sunset And Gordon Residential Tower 789',
      projectNo: '130',
      projectImage: {
        type: 'Media',
        contentUrl: '/images/project.jpg'
      },
      address: {
        streetAddress: '1528 Gordon St',
        city: 'Hollywood',
        state: 'Los Angeles',
        postCode: '90028',
        country: 'US',
        geolocation: {
          lat: 34.098977,
          lng: -118.319287
        },
        timezone: 'America/Los_Angeles'
      },
      stats: {
        workers: 85,
        hours: 125000
      }
    }
  ]
};

const questionCategoriesData = {
  collection: [
    {
      id: 1000,
      name: 'General',
      displayName: 'General Site Conditions',
      value: 'CATEGORY_GENERAL'
    },
    {
      id: 1001,
      name: 'Delays',
      displayName: 'Schedule Delays',
      value: 'CATEGORY_DELAYS'
    },
    {
      id: 1002,
      name: 'Weather',
      displayName: 'Weather Delays',
      value: 'CATEGORY_DELAYS_WEATHER'
    },
    {
      id: 1003,
      name: 'Safety',
      displayName: 'Site Safety',
      value: 'CATEGORY_SAFETY'
    },
    {
      id: 1004,
      name: 'Inspection',
      displayName: 'Inspection',
      value: 'CATEGORY_INSPECTION'
    },
    {
      id: 1005,
      name: 'Visitors',
      displayName: 'Site Visitors',
      value: 'CATEGORY_VISITORS'
    },
    {
      id: 1006,
      name: 'Equipment',
      displayName: 'Equipment & Deliveries',
      value: 'CATEGORY_EQUIPMENT'
    },
    {
      id: 1007,
      name: 'Environmental',
      displayName: 'Environmental Concerns',
      value: 'CATEGORY_ENVIRONMENTAL'
    }
  ]
};

const allTimeStatsByProjectData = {
  type: 'Insights',
  rollupProjectMeasureStats: {
    type: 'ProjectMeasureStats',
    workers: 1115.0,
    hours: 24806.0,
    safetyIncidents: 5,
    delays: 4,
    dailyReports: 116,
    missedDailies: 1709,
    dailiesCompliancePercent: 8,
    openTasks: 333,
    createdTasks: 2,
    openTasksPercent: 0,
    totalProjectDays: 4248
  },
  projectMeasures: [
    {
      type: 'ProjectMeasure',
      project: {
        id: 2,
        type: 'Project',
        name: 'Lincoln Place Apartments'
      },
      stats: {
        type: 'ProjectMeasureStats',
        workers: 78.0,
        hours: 668.0,
        safetyIncidents: 3,
        delays: 1,
        dailyReports: 54,
        missedDailies: 1222,
        dailiesCompliancePercent: 4,
        openTasks: 333,
        createdTasks: 1,
        openTasksPercent: 0,
        totalProjectDays: 2550
      },
      teamMeasures: []
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 6,
        type: 'Project',
        name: 'Rose Bowl Rennovation'
      },
      stats: {
        type: 'ProjectMeasureStats',
        workers: 21.0,
        hours: 113.25,
        safetyIncidents: 1,
        delays: 1,
        dailyReports: 20,
        missedDailies: 91,
        dailiesCompliancePercent: 18,
        openTasks: 0,
        createdTasks: 1,
        openTasksPercent: 0,
        totalProjectDays: 222
      },
      teamMeasures: []
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 7,
        type: 'Project',
        name: 'Jordan High School'
      },
      stats: {
        type: 'ProjectMeasureStats',
        workers: 2.0,
        hours: 16.0,
        safetyIncidents: 0,
        delays: 0,
        dailyReports: 12,
        missedDailies: 176,
        dailiesCompliancePercent: 6,
        openTasks: 0,
        createdTasks: 0,
        openTasksPercent: 0,
        totalProjectDays: 378
      },
      teamMeasures: []
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 8,
        type: 'Project',
        name: 'The Camden Apartments'
      },
      stats: {
        type: 'ProjectMeasureStats',
        workers: 0.0,
        hours: 0.0,
        safetyIncidents: 0,
        delays: 0,
        dailyReports: 11,
        missedDailies: 29,
        dailiesCompliancePercent: 28,
        openTasks: 0,
        createdTasks: 0,
        openTasksPercent: 0,
        totalProjectDays: 662
      },
      teamMeasures: []
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 9,
        type: 'Project',
        name: 'Sunset And Gordon Residential Tower'
      },
      stats: {
        type: 'ProjectMeasureStats',
        workers: 0.0,
        hours: 0.0,
        safetyIncidents: 0,
        delays: 0,
        dailyReports: 11,
        missedDailies: 29,
        dailiesCompliancePercent: 28,
        openTasks: 0,
        createdTasks: 0,
        openTasksPercent: 0,
        totalProjectDays: 82
      },
      teamMeasures: []
    }
  ]
};

const complianceByProjectData = {
  type: 'Insights',
  projectMeasures: [
    {
      type: 'ProjectMeasure',
      project: {
        id: 2,
        type: 'Project',
        name: 'Lincoln Place Apartments'
      },
      stats: {
        type: 'ProjectMeasureStats',
        dailyReports: 0,
        missedDailies: 8,
        dailiesCompliancePercent: 0
      },
      teamMeasures: [
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 2,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 0,
            missedDailies: 8,
            dailiesCompliancePercent: 0,
            company: {
              id: 1,
              name: 'BiznessName1',
              status: 'ACTIVE'
            }
          }
        },
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 11,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 0,
            missedDailies: 8,
            dailiesCompliancePercent: 0,
            company: {
              id: 2,
              name: 'BiznessName2',
              status: 'ACTIVE'
            }
          }
        }
      ]
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 6,
        type: 'Project',
        name: 'Rose Bowl Rennovation'
      },
      stats: {
        type: 'ProjectMeasureStats',
        dailyReports: 0,
        missedDailies: 13,
        dailiesCompliancePercent: 0
      },
      teamMeasures: [
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 6,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 0,
            missedDailies: 13,
            dailiesCompliancePercent: 0,
            company: {
              id: 1,
              name: 'BiznessName1',
              status: 'ACTIVE'
            }
          }
        }
      ]
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 7,
        type: 'Project',
        name: 'Jordan High School'
      },
      stats: {
        type: 'ProjectMeasureStats',
        dailyReports: 1,
        missedDailies: 12,
        dailiesCompliancePercent: 8
      },
      teamMeasures: [
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 7,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 1,
            missedDailies: 12,
            dailiesCompliancePercent: 8,
            company: {
              id: 1,
              name: 'BiznessName1',
              status: 'ACTIVE'
            }
          }
        },
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 12,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 1,
            missedDailies: 12,
            dailiesCompliancePercent: 8,
            company: {
              id: 3,
              name: 'BiznessName3',
              status: 'ACTIVE'
            }
          }
        }
      ]
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 8,
        type: 'Project',
        name: 'The Camden Apartments'
      },
      stats: {
        type: 'ProjectMeasureStats',
        dailyReports: 0,
        missedDailies: 18,
        dailiesCompliancePercent: 0
      },
      teamMeasures: [
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 8,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 0,
            missedDailies: 18,
            dailiesCompliancePercent: 0,
            company: {
              id: 1,
              name: 'BiznessName1',
              status: 'ACTIVE'
            }
          }
        },
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 13,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 0,
            missedDailies: 18,
            dailiesCompliancePercent: 0,
            company: {
              id: 4,
              name: 'BiznessName4',
              status: 'ACTIVE'
            }
          }
        }
      ]
    },
    {
      type: 'ProjectMeasure',
      project: {
        id: 9,
        name: 'Sunset And Gordon Residential Tower'
      },
      stats: {
        type: 'ProjectMeasureStats',
        dailyReports: 4,
        missedDailies: 25,
        dailiesCompliancePercent: 14
      },
      teamMeasures: [
        {
          type: 'ProjectTeamMeasure',
          projectTeamId: 9,
          stats: {
            type: 'ProjectMeasureStats',
            dailyReports: 4,
            missedDailies: 25,
            dailiesCompliancePercent: 14,
            company: {
              id: 1,
              name: 'BiznessName1',
              status: 'ACTIVE'
            }
          }
        }
      ]
    }
  ]
};

const topUsersData = {
  type: 'Insights',
  userMeasures: [
    {
      type: 'UserMeasureStats',
      id: 26552,
      numberOfActivities: 45,
      signedReports: 2,
      compliancePercent: -2150,
      company: {
        id: 1,
        name: 'BiznessName1'
      },
      user: {
        id: 1,
        type: 'User',
        title: 'Mr',
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick.ryall@gmail.com',
        phone: '021552497',
        lastLogin: '2017-06-21T03:44:45+0000',
        company: {
          id: 1
        }
      }
    },
    {
      type: 'UserMeasureStats',
      id: 27425,
      numberOfActivities: 303,
      signedReports: 21,
      compliancePercent: -1343,
      company: {
        id: 1,
        name: 'BiznessName2'
      },
      user: {
        id: 2,
        type: 'User',
        title: 'Mr',
        firstName: 'John',
        lastName: 'Jones',
        email: 'john.jones@gmail.com',
        phone: '021552497',
        lastLogin: '2017-06-21T03:44:45+0000',
        isPending: true,
        company: {
          id: 2
        }
      }
    },
    {
      type: 'UserMeasureStats',
      id: 28553,
      numberOfActivities: 1,
      signedReports: 0,
      compliancePercent: 0,
      company: {
        id: 1,
        name: 'BiznessName2'
      },
      user: {
        id: 3,
        type: 'User',
        title: 'Mr',
        firstName: 'Tim',
        lastName: 'Smith',
        email: 'tim.smith@gmail.com',
        phone: '021552497',
        company: {
          id: 2
        }
      }
    }
  ]
};

const attachmentActivityData = {
  collection: [
    {
      type: 'AttachmentActivity',
      id: 1627459,
      createdTimestamp: 1512429246000,
      createdBy: 1,
      project: {
        id: 2,
        uuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4',
        teamId: 2,
        type: 'Project',
        name: 'Lincoln Place Apartments',
        address: {
          streetAddress: '1050 Frederick St',
          city: 'Venice',
          state: 'CA',
          postCode: '90291',
          timezone: 'America/Los_Angeles',
          country: 'US',
          geolocation: {
            lat: 33.9992489,
            lng: -118.4602105
          }
        }
      },
      company: {
        id: 1
      },
      pdfs: {
        daily:
          'https://alpha-priv.rakenapp.com//projects/reports/cardiff-mall_2017-12-03_5796b2883ea7b057.pdf'
      },
      reportDate: '2017-12-03',
      reportStatus: 'UNSIGNED',
      updatedBy: 0,
      fileName: 'Cardiff Mall_20171204_151407.jpg.jpg',
      mediaType: 'IMAGE',
      contentUrl:
        'https://s3.amazonaws.com/raken-dev/2017/12/8ffdf9a1-2001-459e-9a09-049a3e5f9f88',
      thumbUrl:
        'https://s3.amazonaws.com/raken-dev/2017/12/8ffdf9a1-2001-459e-9a09-049a3e5f9f88_thumb.jpg',
      contentType: 'image/jpeg',
      tags: 'null',
      fileSize: 0,
      uploadDate: 1512429245000,
      takenDate: 1512429248000,
      empty: false
    },
    {
      type: 'AttachmentActivity',
      id: 1627458,
      createdTimestamp: 1512429243000,
      createdBy: 2,
      project: {
        id: 2,
        uuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4',
        teamId: 2,
        type: 'Project',
        name: 'Lincoln Place Apartments',
        address: {
          streetAddress: '1050 Frederick St',
          city: 'Venice',
          state: 'CA',
          postCode: '90291',
          timezone: 'America/Los_Angeles',
          country: 'US',
          geolocation: {
            lat: 33.9992489,
            lng: -118.4602105
          }
        }
      },
      company: {
        id: 2
      },
      projectTeamId: 62453,
      reportId: 611650,
      pdfs: {
        daily:
          'https://alpha-priv.rakenapp.com//projects/reports/cardiff-mall_2017-12-03_5796b2883ea7b057.pdf'
      },
      reportDate: '2017-12-03',
      reportStatus: 'UNSIGNED',
      updatedBy: 0,
      fileName: 'Cardiff Mall_20171204_151404.jpg.jpg',
      mediaType: 'IMAGE',
      contentUrl:
        'https://s3.amazonaws.com/raken-dev/2017/12/6ba8e8ad-5ec7-4e47-b886-a9269497b7b7',
      thumbUrl:
        'https://s3.amazonaws.com/raken-dev/2017/12/6ba8e8ad-5ec7-4e47-b886-a9269497b7b7_thumb.jpg',
      contentType: 'image/jpeg',
      tags: 'null',
      fileSize: 0,
      uploadDate: 1512429243000,
      takenDate: 1512429244000,
      empty: false
    }
  ]
};

const surveyActivityData = {
  collection: [
    {
      type: 'SurveyActivity',
      id: 4505662,
      createdTimestamp: 1509082933000,
      updatedTimestamp: 1509406479000,
      updatedBy: 27425,
      createdBy: 27425,
      project: {
        id: 3,
        teamId: 3,
        type: 'Project',
        name: 'Wilshire Grand Center',
        address: {
          streetAddress: '900 Wilshire Blvd',
          city: 'Los Angeles',
          state: 'CA',
          postCode: '92007',
          country: 'US',
          geolocation: {
            lat: 34.0501216,
            lng: -118.2595763
          },
          timezone: 'America/Los_Angeles'
        }
      },
      company: {
        id: 1
      },
      projectTeamId: 59552,
      reportId: 608331,
      pdfs: {
        daily:
          'https://alpha-priv.rakenapp.com//projects/reports/make-office-building_2017-10-26_b4a008a331f657b4.pdf'
      },
      reportDate: '2017-10-26',
      reportStatus: 'COMPLETED',
      questionId: 167766,
      desc: 'Delay',
      answer: 'NO',
      question: {
        type: 'ReportQuestion',
        id: 167766,
        tag: 3,
        category: 'CATEGORY_DELAYS',
        question: 'Any schedule delays occur?',
        required: true
      },
      empty: false
    },
    {
      type: 'SurveyActivity',
      id: 4492718,
      createdTimestamp: 1507614235000,
      updatedTimestamp: 1507614331000,
      updatedBy: 2,
      createdBy: 27425,
      project: {
        id: 3,
        teamId: 3,
        type: 'Project',
        name: 'Wilshire Grand Center',
        address: {
          streetAddress: '900 Wilshire Blvd',
          city: 'Los Angeles',
          state: 'CA',
          postCode: '92007',
          country: 'US',
          geolocation: {
            lat: 34.0501216,
            lng: -118.2595763
          },
          timezone: 'America/Los_Angeles'
        }
      },
      company: {
        id: 2,
        name: 'Fortis Construction'
      },
      projectTeamId: 59790,
      reportId: 606164,
      pdfs: {
        daily:
          'https://alpha-priv.rakenapp.com//projects/reports/florida-hospital_2017-10-09_3c5fff2d395bd1a1.pdf'
      },
      reportDate: '2017-10-09',
      reportStatus: 'UNSIGNED',
      questionId: 169936,
      desc:
        'there was too much rain which caused the footing pour to be delayed',
      answer: 'YES',
      question: {
        type: 'ReportQuestion',
        id: 169936,
        tag: 1,
        category: 'CATEGORY_DELAYS',
        question: 'Any equipment delays?',
        required: true
      },
      empty: false
    },
    {
      type: 'SurveyActivity',
      id: 4492719,
      createdTimestamp: 1507614235000,
      updatedTimestamp: 1507614331000,
      updatedBy: 27425,
      createdBy: 27425,
      project: {
        id: 2,
        uuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4',
        teamId: 2,
        type: 'Project',
        name: 'Lincoln Place Apartments',
        address: {
          streetAddress: '1050 Frederick St',
          city: 'Venice',
          state: 'CA',
          postCode: '90291',
          timezone: 'America/Los_Angeles',
          country: 'US',
          geolocation: {
            lat: 33.9992489,
            lng: -118.4602105
          }
        }
      },
      company: {
        id: 2
      },
      projectTeamId: 59790,
      reportId: 606164,
      pdfs: {
        daily:
          'https://alpha-priv.rakenapp.com//projects/reports/florida-hospital_2017-10-09_3c5fff2d395bd1a1.pdf'
      },
      reportDate: '2017-10-09',
      reportStatus: 'UNSIGNED',
      questionId: 169937,
      desc:
        'there was too much rain which caused the footing pour to be delayed',
      answer: 'YES',
      question: {
        type: 'ReportQuestion',
        id: 169937,
        tag: 2,
        category: 'CATEGORY_DELAYS_WEATHER',
        question: 'Did weather cause any delays?',
        required: true
      },
      empty: false
    }
  ]
};

const missedDailiesData = {
  page: {
    offset: 0,
    limit: 10,
    totalElements: 1000,
    next: '/ra/dashboard/misseddailies?offset=100&limit=10'
  },
  collection: [
    {
      dateFor: '2017-12-11',
      notified: false,
      project: {
        id: 2
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-11'
    },
    {
      dateFor: '2017-12-08',
      notified: false,
      project: {
        id: 3
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-08'
    },
    {
      dateFor: '2017-12-07',
      notified: false,
      project: {
        id: 4
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-07'
    },
    {
      dateFor: '2017-12-06',
      notified: false,
      project: {
        id: 5
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-06'
    },
    {
      dateFor: '2017-12-05',
      notified: false,
      project: {
        id: 6
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-05'
    },
    {
      dateFor: '2017-12-04',
      notified: false,
      project: {
        id: 7
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-04'
    },
    {
      dateFor: '2017-12-01',
      notified: false,
      project: {
        id: 4
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-12-01'
    },
    {
      dateFor: '2017-12-01',
      notified: false,
      project: {
        id: 3
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 3,
      id: '3_2017-12-01'
    },
    {
      dateFor: '2017-11-30',
      notified: false,
      project: {
        id: 2
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-11-30'
    },
    {
      dateFor: '2017-11-29',
      notified: false,
      project: {
        id: 5
      },
      company: {
        id: 1,
        name: 'raken'
      },
      projectTeamId: 4,
      id: '4_2017-11-29'
    }
  ]
};

const tasksData = {
  summary: {
    open: 8,
    completed: 4,
    deleted: 6
  },
  collection: [
    {
      id: 231792,
      createdTimestamp: 1519256638000,
      createdBy: 1,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc: 'Do Something',
      status: 'OPEN',
      dueDate: '2018-02-22',
      attachments: [
        {
          id: 2022259,
          createdTimestamp: 1519256970000,
          createdBy: 28425,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/391f8427-5924-49fb-bae0-ece9d9e6892f',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/391f8427-5924-49fb-bae0-ece9d9e6892f_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 174697,
          uploadDate: 1519256970000,
          takenDate: 1515699363000
        },
        {
          id: 2022260,
          createdTimestamp: 1519256986000,
          createdBy: 28425,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e4dcc7e5-6603-4331-8c3f-94c9c68ddbfe',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e4dcc7e5-6603-4331-8c3f-94c9c68ddbfe_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 116947,
          uploadDate: 1519256986000,
          takenDate: 1515699363000
        },
        {
          id: 2022656,
          createdTimestamp: 1519291428000,
          createdBy: 28425,
          fileName: '27356397_946808065457796_3248636085611993088_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/4b57c07f-488a-461c-bd83-4638a7f80f1b',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/4b57c07f-488a-461c-bd83-4638a7f80f1b_thumb.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 234962,
          uploadDate: 1519291427000,
          takenDate: 1517036531000
        },
        {
          id: 2022657,
          createdTimestamp: 1519291428000,
          createdBy: 28425,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/1d763c77-a139-4dcc-8492-83a2965c0313',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/1d763c77-a139-4dcc-8492-83a2965c0313_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 202932,
          uploadDate: 1519291427000,
          takenDate: 1515699363000
        },
        {
          id: 2022658,
          createdTimestamp: 1519291429000,
          createdBy: 28425,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/876baad9-59c0-4ba7-ba11-e69af876be23',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/876baad9-59c0-4ba7-ba11-e69af876be23_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 116626,
          uploadDate: 1519291428000,
          takenDate: 1515699363000
        },
        {
          id: 2022659,
          createdTimestamp: 1519291429000,
          createdBy: 28425,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/13e79cb8-09f1-47b7-a190-d16dde0bc0b1',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/13e79cb8-09f1-47b7-a190-d16dde0bc0b1_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 174697,
          uploadDate: 1519291429000,
          takenDate: 1515699363000
        },
        {
          id: 2022660,
          createdTimestamp: 1519291430000,
          createdBy: 28425,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/cb69702d-b8c6-4f3f-899a-60a873b02b83',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/cb69702d-b8c6-4f3f-899a-60a873b02b83_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 116947,
          uploadDate: 1519291430000,
          takenDate: 1515699363000
        },
        {
          id: 2034545,
          createdTimestamp: 1519416656000,
          createdBy: 28425,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/832d331c-966f-43b6-822b-47120d9fb102',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/832d331c-966f-43b6-822b-47120d9fb102_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 103716,
          uploadDate: 1519416655000,
          takenDate: 1515699363000
        },
        {
          id: 2034546,
          createdTimestamp: 1519418955000,
          createdBy: 28425,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/3d5fa9bc-dc71-4262-8cfd-06f0db9c420c',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/3d5fa9bc-dc71-4262-8cfd-06f0db9c420c_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 217161,
          uploadDate: 1519418954000,
          takenDate: 1515699363000
        },
        {
          id: 2034547,
          createdTimestamp: 1519452984000,
          createdBy: 28425,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/8f6ad291-0e9f-4519-bfcc-36d443609ae3',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/8f6ad291-0e9f-4519-bfcc-36d443609ae3_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 142862,
          uploadDate: 1519452984000,
          takenDate: 1515699363000
        },
        {
          id: 2034548,
          createdTimestamp: 1519589295000,
          createdBy: 28425,
          fileName: 'project.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/2fc8e0f8-5a8a-4934-92b6-6684b7e25d8f',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/2fc8e0f8-5a8a-4934-92b6-6684b7e25d8f_thumb.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 493323,
          uploadDate: 1519589294000,
          takenDate: 1519328623000
        }
      ],
      creator: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231796,
      createdTimestamp: 1519260634000,
      updatedTimestamp: 1519790167000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'thdrthdrthrth rthgrdth rsthesrhdrthrthrt Nullam quis risus eget urna mollis ornare vel eu leo. Curabitur blandit tempus porttitor. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam id dolor id nibh ultricies vehicula ut id elit. Donec ullamcorper nulla non metus auctor fringilla.',
      status: 'COMPLETED',
      dueDate: '2018-02-14',
      attachments: [
        {
          id: 2034543,
          createdTimestamp: 1519416463000,
          createdBy: 28425,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/5c3693c9-ba69-46bf-8e57-6fddd9635a26',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/5c3693c9-ba69-46bf-8e57-6fddd9635a26_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 186278,
          uploadDate: 1519416463000,
          takenDate: 1515699363000
        },
        {
          id: 2034544,
          createdTimestamp: 1519416550000,
          createdBy: 28425,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e607e86a-98ec-433c-b84d-13a1225331dd',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e607e86a-98ec-433c-b84d-13a1225331dd_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 217161,
          uploadDate: 1519416550000,
          takenDate: 1515699363000
        },
        {
          id: 2070109,
          createdTimestamp: 1519630815000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519630815000
        },
        {
          id: 2081988,
          createdTimestamp: 1519680958000,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 174697,
          uploadDate: 1519680957000
        },
        {
          id: 2081989,
          createdTimestamp: 1519681105000,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 174697,
          uploadDate: 1519681105000
        },
        {
          id: 2081990,
          createdTimestamp: 1519682783000,
          fileName: 'project-9.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161064,
          uploadDate: 1519682782000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231798,
      createdTimestamp: 1519261029000,
      updatedTimestamp: 1519791486000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Etiam porta sem malesuada magna mollis euismod. Maecenas faucibus mollis interdum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. ',
      status: 'COMPLETED',
      dueDate: '2018-03-23',
      attachments: [
        {
          id: 2022663,
          createdTimestamp: 1519291872000,
          createdBy: 28425,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/6f54b462-e82a-44f5-a909-0608ba085933',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/6f54b462-e82a-44f5-a909-0608ba085933_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 174697,
          uploadDate: 1519291871000,
          takenDate: 1515699363000
        },
        {
          id: 2022664,
          createdTimestamp: 1519291872000,
          createdBy: 28425,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/615c5de2-69f2-40a3-8b17-91f33e96beb3',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/615c5de2-69f2-40a3-8b17-91f33e96beb3_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 186278,
          uploadDate: 1519291871000,
          takenDate: 1515699363000
        },
        {
          id: 2022665,
          createdTimestamp: 1519291873000,
          createdBy: 28425,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/d33f0611-8b72-4dc8-8dae-d818f643bb06',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/d33f0611-8b72-4dc8-8dae-d818f643bb06_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 116626,
          uploadDate: 1519291873000,
          takenDate: 1515699363000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231899,
      createdTimestamp: 1519333390000,
      updatedTimestamp: 1519793530000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
      status: 'OPEN',
      dueDate: '2018-02-22',
      attachments: [
        {
          id: 2022678,
          createdTimestamp: 1519333391000,
          createdBy: 28425,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7619b5fe-4c50-4948-becf-785e44c7fbcc',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7619b5fe-4c50-4948-becf-785e44c7fbcc_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 174697,
          uploadDate: 1519333391000,
          takenDate: 1515699363000
        },
        {
          id: 2022679,
          createdTimestamp: 1519333391000,
          createdBy: 28425,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/79deaa6d-fc9a-46c9-b9db-ec2d44fddaa1',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/79deaa6d-fc9a-46c9-b9db-ec2d44fddaa1_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 186278,
          uploadDate: 1519333391000,
          takenDate: 1515699363000
        },
        {
          id: 2022680,
          createdTimestamp: 1519333394000,
          createdBy: 28425,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/822c834e-7b43-4a11-96b5-6dbca9439a61',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/822c834e-7b43-4a11-96b5-6dbca9439a61_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 103716,
          uploadDate: 1519333394000,
          takenDate: 1515699363000
        },
        {
          id: 2022681,
          createdTimestamp: 1519333395000,
          createdBy: 28425,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/25e1d1d0-19ee-4da7-be14-207bde2e146f',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/25e1d1d0-19ee-4da7-be14-207bde2e146f_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 142862,
          uploadDate: 1519333394000,
          takenDate: 1515699363000
        },
        {
          id: 2022682,
          createdTimestamp: 1519333397000,
          createdBy: 28425,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/37d929ae-8904-4d89-8c39-e60e020fef7b',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/37d929ae-8904-4d89-8c39-e60e020fef7b_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 159998,
          uploadDate: 1519333397000,
          takenDate: 1515699363000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231900,
      createdTimestamp: 1519333414000,
      updatedTimestamp: 1519793525000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. ',
      status: 'COMPLETED',
      dueDate: '2018-02-22',
      attachments: [
        {
          id: 2022683,
          createdTimestamp: 1519333416000,
          createdBy: 28425,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/32a330dd-b454-4eb0-97c8-2e272f31a89c',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/32a330dd-b454-4eb0-97c8-2e272f31a89c_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 103716,
          uploadDate: 1519333416000,
          takenDate: 1515699363000
        },
        {
          id: 2022684,
          createdTimestamp: 1519333416000,
          createdBy: 28425,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/88c46731-c243-4c54-898c-35331f18e3ae',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/88c46731-c243-4c54-898c-35331f18e3ae_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 142862,
          uploadDate: 1519333416000,
          takenDate: 1515699363000
        },
        {
          id: 2022685,
          createdTimestamp: 1519333417000,
          createdBy: 28425,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/12e834ed-c9d2-4e09-b8ee-ef5410138d8b',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/12e834ed-c9d2-4e09-b8ee-ef5410138d8b_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 159998,
          uploadDate: 1519333416000,
          takenDate: 1515699363000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231901,
      createdTimestamp: 1519333435000,
      updatedTimestamp: 1519793839000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      status: 'OPEN',
      dueDate: '2018-02-22',
      attachments: [
        {
          id: 2022686,
          createdTimestamp: 1519333438000,
          createdBy: 28425,
          fileName: 'project-3.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/93f6c149-d507-49cb-bdc3-d88f573fb39c',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/93f6c149-d507-49cb-bdc3-d88f573fb39c_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 119984,
          uploadDate: 1519333437000,
          takenDate: 1515699363000
        },
        {
          id: 2022687,
          createdTimestamp: 1519333438000,
          createdBy: 28425,
          fileName: 'project-4.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/aac449cc-4f85-4d4e-8466-6dae568f06ff',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/aac449cc-4f85-4d4e-8466-6dae568f06ff_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 112407,
          uploadDate: 1519333437000,
          takenDate: 1515699363000
        },
        {
          id: 2022688,
          createdTimestamp: 1519333442000,
          createdBy: 28425,
          fileName: 'project-5.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/a5dbbe16-f4ba-4e1b-a2ed-8e9f9f283d5f',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/a5dbbe16-f4ba-4e1b-a2ed-8e9f9f283d5f_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 150017,
          uploadDate: 1519333442000,
          takenDate: 1515699363000
        },
        {
          id: 2022689,
          createdTimestamp: 1519333443000,
          createdBy: 28425,
          fileName: 'project-6.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7d725a18-0a03-4327-9dc9-32e2b5f2e99e',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7d725a18-0a03-4327-9dc9-32e2b5f2e99e_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 225199,
          uploadDate: 1519333443000,
          takenDate: 1515699363000
        },
        {
          id: 2022690,
          createdTimestamp: 1519333448000,
          createdBy: 28425,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/3dbc69d3-95c6-4dac-8601-9908db50cdeb',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/3dbc69d3-95c6-4dac-8601-9908db50cdeb_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 161177,
          uploadDate: 1519333447000,
          takenDate: 1515699363000
        },
        {
          id: 2022691,
          createdTimestamp: 1519333449000,
          createdBy: 28425,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/48d95f41-f5ee-40d7-9854-cc16d7879eb2',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/48d95f41-f5ee-40d7-9854-cc16d7879eb2_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 217161,
          uploadDate: 1519333448000,
          takenDate: 1515699363000
        },
        {
          id: 2022692,
          createdTimestamp: 1519333453000,
          createdBy: 28425,
          fileName: 'project-9.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/8a1532f6-8b5a-4ebd-89bb-d61bf959ec7d',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/8a1532f6-8b5a-4ebd-89bb-d61bf959ec7d_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 161064,
          uploadDate: 1519333452000,
          takenDate: 1515699363000
        },
        {
          id: 2022693,
          createdTimestamp: 1519333454000,
          createdBy: 28425,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e29f62d9-ef81-465b-b689-5297e2a91973',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/e29f62d9-ef81-465b-b689-5297e2a91973_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 202932,
          uploadDate: 1519333454000,
          takenDate: 1515699363000
        },
        {
          id: 2022694,
          createdTimestamp: 1519333459000,
          createdBy: 28425,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/70ceb4b0-3b76-483b-a1aa-533e99e9e934',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/70ceb4b0-3b76-483b-a1aa-533e99e9e934_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 174697,
          uploadDate: 1519333458000,
          takenDate: 1515699363000
        },
        {
          id: 2022695,
          createdTimestamp: 1519333459000,
          createdBy: 28425,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/6c48fb65-38d7-43e7-8338-f5b6f6b1cf32',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/6c48fb65-38d7-43e7-8338-f5b6f6b1cf32_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 186278,
          uploadDate: 1519333459000,
          takenDate: 1515699363000
        },
        {
          id: 2022696,
          createdTimestamp: 1519333463000,
          createdBy: 28425,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/9bb6dcf4-c7f4-4845-b5aa-4c3018dd71ec',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/9bb6dcf4-c7f4-4845-b5aa-4c3018dd71ec_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 142862,
          uploadDate: 1519333462000,
          takenDate: 1515699363000
        },
        {
          id: 2022697,
          createdTimestamp: 1519333463000,
          createdBy: 28425,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7cbd136e-f1c2-4387-8179-484eb221801f',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/7cbd136e-f1c2-4387-8179-484eb221801f_thumb.png',
          contentType: 'image/png',
          etag: 'image/png',
          fileSize: 103716,
          uploadDate: 1519333462000,
          takenDate: 1515699363000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231902,
      createdTimestamp: 1519339891000,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Curabitur blandit tempus porttitor. Maecenas faucibus mollis interdum. Donec id elit non mi porta gravida at eget metus. Curabitur blandit tempus porttitor. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      status: 'OPEN',
      dueDate: '2018-02-14',
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 231903,
      createdTimestamp: 1519340098000,
      updatedTimestamp: 1519790514000,
      updatedBy: 28425,
      createdBy: 28425,
      assignee: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Etiam porta sem malesuada magna mollis euismod. ',
      status: 'COMPLETED',
      dueDate: '2018-02-07',
      attachments: [
        {
          id: 2070105,
          createdTimestamp: 1519630228000,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 202932,
          uploadDate: 1519630228000
        },
        {
          id: 2070107,
          createdTimestamp: 1519630643000,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/sample_project2513660425531102369.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/sample_project5850339161677215457_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 103716,
          uploadDate: 1519630642000
        },
        {
          id: 2070111,
          createdTimestamp: 1519633108000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519633107000
        },
        {
          id: 2070112,
          createdTimestamp: 1519633122000,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116626,
          uploadDate: 1519633122000
        },
        {
          id: 2070113,
          createdTimestamp: 1519633186000,
          fileName: '27356397_946808065457796_3248636085611993088_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note18733947097530721252.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note14870073127328902750_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 234962,
          uploadDate: 1519633185000
        },
        {
          id: 2075655,
          createdTimestamp: 1519634641000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519634641000
        },
        {
          id: 2077523,
          createdTimestamp: 1519634851000,
          fileName: 'project-1.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/2of4330114095889975124.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/2of48981664494261231160_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 210139,
          uploadDate: 1519634850000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 234801,
      createdTimestamp: 1519357332000,
      updatedTimestamp: 1519456464000,
      createdBy: 28425,
      assignee: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Integer posuere erat a ante venenatis dapibus posuere velit aliquet.',
      status: 'OPEN',
      dueDate: '2018-02-28',
      attachments: [
        {
          id: 2070099,
          createdTimestamp: 1519628626000,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 103716,
          uploadDate: 1519628626000
        },
        {
          id: 2070100,
          createdTimestamp: 1519628627000,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note18733947097530721252.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note14870073127328902750_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 186278,
          uploadDate: 1519628626000
        },
        {
          id: 2070101,
          createdTimestamp: 1519628627000,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 142862,
          uploadDate: 1519628626000
        },
        {
          id: 2070102,
          createdTimestamp: 1519628658000,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note36203895636269515783.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note37394333796173285710_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116626,
          uploadDate: 1519628658000
        },
        {
          id: 2070103,
          createdTimestamp: 1519628685000,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116947,
          uploadDate: 1519628684000
        },
        {
          id: 2070104,
          createdTimestamp: 1519629461000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_27513590827971964555.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_24279985981259047024_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519629460000
        },
        {
          id: 2070106,
          createdTimestamp: 1519630502000,
          fileName: 'project-5.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 150017,
          uploadDate: 1519630502000
        },
        {
          id: 2070108,
          createdTimestamp: 1519630748000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_18296360811545794461.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_1170218693028856066_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519630748000
        },
        {
          id: 2070110,
          createdTimestamp: 1519632293000,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_18296360811545794461.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_1170218693028856066_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 217161,
          uploadDate: 1519632293000
        },
        {
          id: 2081970,
          createdTimestamp: 1519635375000,
          fileName: '27355631_946807742124495_4965316223629530571_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 243633,
          uploadDate: 1519635375000
        },
        {
          id: 2081971,
          createdTimestamp: 1519678894000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/2of4330114095889975124.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/2of48981664494261231160_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519678893000
        },
        {
          id: 2081972,
          createdTimestamp: 1519678911000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note36203895636269515783.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note37394333796173285710_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519678911000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 234802,
      createdTimestamp: 1519584610000,
      updatedTimestamp: 1519713056000,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc:
        'Cras mattis consectetur purus sit amet fermentum. Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Maecenas faucibus mollis interdum.',
      status: 'OPEN',
      dueDate: '2018-02-14',
      attachments: [
        {
          id: 2081979,
          createdTimestamp: 1519679808000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519679807000
        },
        {
          id: 2081980,
          createdTimestamp: 1519679808000,
          fileName: 'project-9.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/2of4330114095889975124.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/2of48981664494261231160_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161064,
          uploadDate: 1519679807000
        },
        {
          id: 2081981,
          createdTimestamp: 1519679808000,
          fileName: 'project-1.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 210139,
          uploadDate: 1519679808000
        },
        {
          id: 2081982,
          createdTimestamp: 1519679808000,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/3of4906770848267608849.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/3of46524640087418067237_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 217161,
          uploadDate: 1519679808000
        },
        {
          id: 2081983,
          createdTimestamp: 1519679809000,
          fileName: '27355631_946807742124495_4965316223629530571_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_31405889427230014606.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_32313696375159670004_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 243633,
          uploadDate: 1519679808000
        },
        {
          id: 2081984,
          createdTimestamp: 1519679809000,
          fileName: '27356397_946808065457796_3248636085611993088_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 234962,
          uploadDate: 1519679808000
        },
        {
          id: 2081985,
          createdTimestamp: 1519679810000,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/sample_project2513660425531102369.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/sample_project5850339161677215457_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116626,
          uploadDate: 1519679809000
        },
        {
          id: 2081986,
          createdTimestamp: 1519679810000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519679809000
        },
        {
          id: 2081987,
          createdTimestamp: 1519679810000,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note18733947097530721252.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note14870073127328902750_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116947,
          uploadDate: 1519679809000
        },
        {
          id: 2116683,
          createdTimestamp: 1519713035000,
          fileName: 'project-2.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-5_13787907808943212979.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-5_1903361965090704416_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 293175,
          uploadDate: 1519713034000
        },
        {
          id: 2116684,
          createdTimestamp: 1519716687000,
          createdBy: 28425,
          fileName:
            'Hays CV - Paul Bickerstaff - DevOps Lead Engineer at Raken.pdf',
          mediaType: 'DOCUMENT',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/9c9cf5d5-198d-4eab-b4a9-c20edbd113bc',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/9c9cf5d5-198d-4eab-b4a9-c20edbd113bc_thumb.jpg',
          contentType: 'application/pdf',
          etag: 'application/pdf',
          fileSize: 525333,
          uploadDate: 1519716687000,
          takenDate: 1518412902000
        },
        {
          id: 2116685,
          createdTimestamp: 1519716691000,
          createdBy: 28425,
          fileName:
            'Hays CV - Paul Bickerstaff - DevOps Lead Engineer at Raken.pdf',
          mediaType: 'DOCUMENT',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/840204c2-eb5c-4fda-8698-ea1a0fe942e3',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/840204c2-eb5c-4fda-8698-ea1a0fe942e3_thumb.jpg',
          contentType: 'application/pdf',
          etag: 'application/pdf',
          fileSize: 525333,
          uploadDate: 1519716691000,
          takenDate: 1518412902000
        },
        {
          id: 2116686,
          createdTimestamp: 1519716699000,
          createdBy: 28425,
          fileName: 'Comspek_Lisett_Edstrom_Raken.pdf',
          mediaType: 'DOCUMENT',
          contentUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/abcedb0f-7ec8-4fda-9574-0de9ddd095e5',
          thumbUrl:
            'https://s3.amazonaws.com/raken-dev/2018/02/abcedb0f-7ec8-4fda-9574-0de9ddd095e5_thumb.jpg',
          contentType: 'application/pdf',
          etag: 'application/pdf',
          fileSize: 96638,
          uploadDate: 1519716699000,
          takenDate: 1518034211000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 243561,
      createdTimestamp: 1519629017000,
      createdBy: 28425,
      assignee: {
        id: 1,
        title: 'Other',
        firstName: 'User ',
        lastName: 'Two',
        email: 'testing@nickryall.com.au',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc: 'huuyuiyuiyiuyiu',
      status: 'OPEN',
      dueDate: '2018-02-09',
      attachments: [
        {
          id: 2081973,
          createdTimestamp: 1519679787000,
          fileName: 'project-9.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/3of4906770848267608849.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/3of46524640087418067237_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161064,
          uploadDate: 1519679787000
        },
        {
          id: 2081974,
          createdTimestamp: 1519679787000,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 174697,
          uploadDate: 1519679787000
        },
        {
          id: 2081975,
          createdTimestamp: 1519679787000,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/sample_project2513660425531102369.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/sample_project5850339161677215457_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 186278,
          uploadDate: 1519679787000
        },
        {
          id: 2081976,
          createdTimestamp: 1519679787000,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 217161,
          uploadDate: 1519679787000
        },
        {
          id: 2081977,
          createdTimestamp: 1519679787000,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 142862,
          uploadDate: 1519679787000
        },
        {
          id: 2081978,
          createdTimestamp: 1519679788000,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 202932,
          uploadDate: 1519679787000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    },
    {
      id: 246459,
      createdTimestamp: 1519682920000,
      createdBy: 28425,
      assignee: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      },
      projectTeam: {
        id: 2,
        createdTimestamp: 1519256168000,
        updatedTimestamp: 1519684273000,
        updatedBy: 28425,
        projectTeamType: 'GENERAL_CONTRACTOR',
        projectState: 'ACTIVE',
        startDate: '2018-02-22'
      },
      desc: 'A New Task',
      status: 'OPEN',
      dueDate: '2018-02-15',
      attachments: [
        {
          id: 2081991,
          createdTimestamp: 1519682929000,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_27513590827971964555.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_24279985981259047024_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 142862,
          uploadDate: 1519682928000
        },
        {
          id: 2081992,
          createdTimestamp: 1519682936000,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116626,
          uploadDate: 1519682935000
        },
        {
          id: 2081993,
          createdTimestamp: 1519682945000,
          fileName: 'project-16.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_31405889427230014606.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_32313696375159670004_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116626,
          uploadDate: 1519682945000
        },
        {
          id: 2081994,
          createdTimestamp: 1519682986000,
          fileName: 'project-14.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 103716,
          uploadDate: 1519682985000
        },
        {
          id: 2081995,
          createdTimestamp: 1519683069000,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note18733947097530721252.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note14870073127328902750_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116947,
          uploadDate: 1519683068000
        },
        {
          id: 2081996,
          createdTimestamp: 1519683078000,
          fileName: 'project-17.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_27513590827971964555.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_24279985981259047024_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 116947,
          uploadDate: 1519683078000
        },
        {
          id: 2081997,
          createdTimestamp: 1519683094000,
          fileName: 'project-1.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 210139,
          uploadDate: 1519683093000
        },
        {
          id: 2081998,
          createdTimestamp: 1519683399000,
          fileName: '27356397_946808065457796_3248636085611993088_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note36203895636269515783.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note37394333796173285710_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 234962,
          uploadDate: 1519683398000
        },
        {
          id: 2081999,
          createdTimestamp: 1519683498000,
          fileName: 'project-6.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/2of4330114095889975124.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/2of48981664494261231160_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 225199,
          uploadDate: 1519683498000
        },
        {
          id: 2082000,
          createdTimestamp: 1519683499000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519683498000
        },
        {
          id: 2082001,
          createdTimestamp: 1519683499000,
          fileName: 'project-8.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note36203895636269515783.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note37394333796173285710_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 217161,
          uploadDate: 1519683499000
        },
        {
          id: 2082002,
          createdTimestamp: 1519683500000,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note-55361666267164854370.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note-53201153980349449426_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 202932,
          uploadDate: 1519683499000
        },
        {
          id: 2082003,
          createdTimestamp: 1519683500000,
          fileName: 'project-5.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_31405889427230014606.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_32313696375159670004_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 150017,
          uploadDate: 1519683500000
        },
        {
          id: 2082004,
          createdTimestamp: 1519683501000,
          fileName: 'project-11.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 174697,
          uploadDate: 1519683501000
        },
        {
          id: 2082005,
          createdTimestamp: 1519683502000,
          fileName: 'project-12.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 186278,
          uploadDate: 1519683502000
        },
        {
          id: 2082006,
          createdTimestamp: 1519683503000,
          fileName: 'project-13.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 142862,
          uploadDate: 1519683502000
        },
        {
          id: 2082007,
          createdTimestamp: 1519683505000,
          fileName: 'project-9.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_31405889427230014606.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_32313696375159670004_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161064,
          uploadDate: 1519683504000
        },
        {
          id: 2116679,
          createdTimestamp: 1519698248000,
          fileName: 'project-7.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note2_27513590827971964555.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note2_24279985981259047024_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 161177,
          uploadDate: 1519698247000
        },
        {
          id: 2116680,
          createdTimestamp: 1519698266000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note4_25118742640241369016.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note4_24054733106168064890_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519698266000
        },
        {
          id: 2116681,
          createdTimestamp: 1519698447000,
          fileName: 'project-15.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/note42102028453036478982.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/note41455971677377454757_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 159998,
          uploadDate: 1519698446000
        },
        {
          id: 2116682,
          createdTimestamp: 1519698843000,
          fileName: 'project-10.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 202932,
          uploadDate: 1519698842000
        },
        {
          id: 2116687,
          createdTimestamp: 1519763032000,
          fileName: 'Screen Shot 2018-02-21 at 7.45.21 AM.png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/2of4330114095889975124.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/2of48981664494261231160_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 570149,
          uploadDate: 1519763031000
        },
        {
          id: 2128688,
          createdTimestamp: 1519782142000,
          fileName: 'Screen Shot 2018-02-16 at 11.08.29 AM (2).png',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/4of46556216477715952641.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/4of44712337837978741276_200x200.jpg',
          contentType: 'image/x-png',
          etag: 'image/x-png',
          fileSize: 590384,
          uploadDate: 1519782142000
        },
        {
          id: 2140662,
          createdTimestamp: 1519792024000,
          createdBy: 28425,
          fileName: '27356397_946808065457796_3248636085611993088_o.jpg',
          mediaType: 'IMAGE',
          contentUrl:
            'http://cdn.rakenapp.com/images/1of43723713942425261047.jpg',
          thumbUrl:
            'http://cdn.rakenapp.com/images/1of43130605674440874364_200x200.jpg',
          contentType: 'image/jpeg',
          etag: 'image/jpeg',
          fileSize: 234962,
          uploadDate: 1519792023000
        }
      ],
      creator: {
        id: 28425,
        firstName: 'Nick',
        lastName: 'Ryall',
        email: 'nick@rakenapp.com',
        phone: '(021) 552-497 '
      }
    }
  ]
};

const standardWorkLogsData = [
  {
    type: 'StandardWorklog',
    id: 5117697,
    uuid: '4761802a-719c-49f3-9db1-ec409cada6b7',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '01 Bobs Building',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117698,
    uuid: 'f1580216-af2a-4916-881b-73b774719be8',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '02 Carpenters',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117699,
    uuid: 'c1d2df34-8574-46e5-bfe9-a9293610e8ae',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '03 Carpenters',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117700,
    uuid: '9035af3a-5ca9-4871-b29c-4b98afb69389',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '06 JR',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5123290,
    uuid: 'fc83112c-6ede-402d-bb21-ccb548115ed7',
    createdTimestamp: 1522904169000,
    updatedTimestamp: 1522904169000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'Another Day',
    workerCount: 2,
    totalHours: 1.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '07 JR',
    hours: 0.5,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5365950,
    uuid: '20c0e0cc-015d-49f4-859c-09213e52e24b',
    createdTimestamp: 1524538506000,
    updatedTimestamp: 1524538506000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'Timber',
    workerCount: 2,
    totalHours: 8.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '14 Otis Redding',
    hours: 4.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117701,
    uuid: '4ce62421-f43a-4ce6-8644-f232c3f8fc97',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '15 ro',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117702,
    uuid: 'b08d9659-d129-467b-bced-ee10875ca50d',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '16 Rodney Dangerfield',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5117703,
    uuid: '9716c209-2afe-4ec4-abe2-f93813e83a7b',
    createdTimestamp: 1522898335000,
    updatedBy: 0,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '17 Rodney Dangerfield',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5123288,
    uuid: 'c5c0f729-4eb5-4b18-bc59-e979b5e6605d',
    createdTimestamp: 1522901963000,
    updatedTimestamp: 1522901963000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'drthdrth',
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'STANDARD',
    subcontractor: '18 Rodney Dangerfield',
    hours: 0.0,
    teamId: 2
  },
  {
    type: 'StandardWorklog',
    id: 5123289,
    uuid: 'b25b4cc7-ec12-425b-9fe6-bbfdd2a57897',
    createdTimestamp: 1522902000000,
    updatedTimestamp: 1522902000000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'My Description',
    workerCount: 2,
    totalHours: 1.0,
    teamId: 2,
    attachments: [
      {
        type: 'Media',
        id: 3096598,
        createdTimestamp: 1522901974000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-4.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/489aac5e-cd3d-4c67-9d70-bf804472b552',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/489aac5e-cd3d-4c67-9d70-bf804472b5526130489526130880843_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 112407,
        uploadDate: 1522901972000
      },
      {
        type: 'Media',
        id: 3096599,
        createdTimestamp: 1522901975000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-5.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/d939960d-5480-4dc2-819f-ab3ffb97ddda',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/d939960d-5480-4dc2-819f-ab3ffb97ddda6979884076205606946_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 150017,
        uploadDate: 1522901972000
      },
      {
        type: 'Media',
        id: 3096600,
        createdTimestamp: 1522901975000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-3.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/e39a56d7-b281-48e7-bc4d-d192c26cc67c',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/e39a56d7-b281-48e7-bc4d-d192c26cc67c5327837176237524150_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 119984,
        uploadDate: 1522901972000
      },
      {
        type: 'Media',
        id: 3096601,
        createdTimestamp: 1522901975000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-6.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/780f944d-849e-487d-b0a8-2602fe01462a',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/780f944d-849e-487d-b0a8-2602fe01462a6707951499703208143_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 225199,
        uploadDate: 1522901973000
      },
      {
        type: 'Media',
        id: 3096602,
        createdTimestamp: 1522901975000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-2.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/d28acc93-ab62-4b86-9e0e-94acde1ab330',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/04/d28acc93-ab62-4b86-9e0e-94acde1ab3305321811336869408232_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 211345,
        uploadDate: 1522901973000
      }
    ],
    workLogType: 'STANDARD',
    subcontractor: '19 Rodney Dangerfield',
    hours: 0.5
  }
];

const timeCardLogsData = [
  {
    type: 'PersonWorklog',
    id: 5647210,
    uuid: 'de2841bf-cdc4-45b9-af13-9478ed002d18',
    createdTimestamp: 1525835370000,
    updatedTimestamp: 1526268153000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'My Description',
    workerCount: 1,
    totalHours: 23.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 916123,
      worker: {
        type: 'Worker',
        id: 459787,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '04 Jane',
        lastName: 'Eyre',
        projectId: 83711,
        employeeId: '12345'
      },
      classification: {
        type: 'Classification',
        id: 81546,
        name: 'Superintendent',
        status: 'DELETED'
      },
      costCodes: [
        {
          type: 'TimeCostCode',
          id: 1126943,
          costCode: {
            type: 'CostCode',
            division: 'Heavy',
            code: '1200',
            description: 'Meta'
          },
          regularTime: 8.0,
          overTime: 4.0,
          doubleTime: 11.0
        }
      ],
      regularTime: 8.0,
      overTime: 4.0,
      doubleTime: 11.0,
      split: true,
      totalHours: 23.0
    }
  },
  {
    type: 'PersonWorklog',
    id: 5647211,
    uuid: '2be27359-0c75-44a1-a6bf-014877d911a9',
    createdTimestamp: 1525843132000,
    updatedTimestamp: 1526264817000,
    updatedBy: 28425,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 916143,
      worker: {
        type: 'Worker',
        id: 258371,
        workerType: 'PERSON',
        status: 'DELETED',
        firstName: '05 John',
        lastName: 'Ryall',
        classificationId: 1129648,
        classificationName: 'My Classification'
      },
      classification: {
        type: 'Classification',
        id: 93625,
        name: 'Project Engineer',
        status: 'DELETED'
      },
      costCodes: [
        {
          type: 'TimeCostCode',
          id: 1121691,
          costCode: {
            type: 'CostCode',
            uuid: '053fa48a-aa1a-11e9-a2a3-2a2ae2dbcce4',
            division: 'Another Onw',
            code: '1234',
            description: 'rtdhdrthr'
          },
          regularTime: 0.0,
          overTime: 0.0,
          doubleTime: 0.0
        }
      ],
      regularTime: 0.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: true,
      totalHours: 0.0
    }
  },
  {
    type: 'PersonWorklog',
    id: 5369368,
    uuid: '0dbc9232-97ef-4547-97f4-4c7df1eb4086',
    createdTimestamp: 1524646858000,
    updatedTimestamp: 1526271080000,
    updatedBy: 28425,
    createdBy: 28425,
    workerCount: 0,
    totalHours: 0.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 740464,
      worker: {
        type: 'Worker',
        id: 459788,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '08 Monde',
        lastName: 'Cande',
        projectId: 83711,
        employeeId: '1234567'
      },
      classification: {
        type: 'Classification',
        id: 975724,
        name: 'Front End',
        status: 'DELETED'
      },
      costCodes: [],
      regularTime: 0.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: true,
      totalHours: 0.0
    }
  },
  {
    type: 'PersonWorklog',
    id: 5647232,
    uuid: 'cd1d63e9-c45b-49ab-b14a-527ce1f504d2',
    createdTimestamp: 1525857518000,
    updatedTimestamp: 1526259624000,
    updatedBy: 28425,
    createdBy: 28425,
    workerCount: 1,
    totalHours: 5.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 916150,
      worker: {
        type: 'Worker',
        id: 459788,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '09 Monde',
        lastName: 'Cande',
        projectId: 83711,
        employeeId: '1234567'
      },
      costCodes: [],
      regularTime: 5.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: false,
      totalHours: 5.0
    }
  },
  {
    type: 'CrewWorklog',
    id: 5765789,
    uuid: 'c1c8f406-3dd4-4298-bee0-bfe38d74b2ec',
    createdTimestamp: 1526159033000,
    updatedTimestamp: 1526159033000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc:
      'Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor fringilla. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Etiam porta sem malesuada magna mollis euismod. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed posuere consectetur est at lobortis.',
    workerCount: 2,
    totalHours: 48.0,
    attachments: [],
    workLogType: 'CREW',
    teamId: 2,
    name: '10 My Awesome Crew',
    timeCards: [
      {
        type: 'TimeCard',
        id: 991850,
        worker: {
          type: 'Worker',
          id: 258371,
          workerType: 'PERSON',
          status: 'DELETED',
          firstName: 'John',
          lastName: 'Ryall',
          classificationId: 1129648,
          classificationName: 'My Classification'
        },
        costCodes: [],
        regularTime: 24.0,
        overTime: 0.0,
        doubleTime: 0.0,
        split: false,
        totalHours: 24.0
      },
      {
        type: 'TimeCard',
        id: 991851,
        worker: {
          type: 'Worker',
          id: 438005,
          workerType: 'PERSON',
          status: 'ACTIVE',
          firstName: 'Latest',
          lastName: 'Worker'
        },
        costCodes: [],
        regularTime: 24.0,
        overTime: 0.0,
        doubleTime: 0.0,
        split: false,
        totalHours: 24.0
      }
    ]
  },
  {
    type: 'CrewWorklog',
    id: 5342331,
    uuid: '710a581e-94d6-46d1-8544-b992e418a5a1',
    createdTimestamp: 1524456130000,
    updatedTimestamp: 1525837851000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc:
      'Vestibulum id ligula porta felis euismod semper.  Vestibulum id ligula porta felis euismod semper. ',
    workerCount: 1,
    totalHours: 3.0,
    attachments: [],
    workLogType: 'CREW',
    name: '11 My Crews',
    teamId: 2,
    timeCards: [
      {
        type: 'TimeCard',
        id: 916128,
        worker: {
          type: 'Worker',
          id: 258371,
          workerType: 'PERSON',
          status: 'DELETED',
          firstName: 'John',
          lastName: 'Ryall',
          classificationId: 1129648,
          classificationName: 'My Classification'
        },
        classification: {
          type: 'Classification',
          id: 5571,
          name: 'Journeyman',
          status: 'DELETED'
        },
        costCodes: [
          {
            type: 'TimeCostCode',
            id: 1015998,
            costCode: {
              type: 'CostCode',
              uuid: '053fa48a-aa1a-11e9-a2a3-2a2ae2dbcce4',
              division: 'Another Onw',
              code: '1234',
              description: 'rtdhdrthr'
            },
            regularTime: 3.0,
            overTime: 0.0,
            doubleTime: 0.0
          },
          {
            type: 'TimeCostCode',
            id: 1015999,
            costCode: {
              type: 'CostCode',
              uuid: '22eb2a04-aa1a-11e9-a2a3-2a2ae2dbcce4',
              division: 'Division',
              code: '1000',
              description: 'Tests'
            },
            regularTime: 0.0,
            overTime: 0.0,
            doubleTime: 0.0
          }
        ],
        regularTime: 3.0,
        overTime: 0.0,
        doubleTime: 0.0,
        split: true,
        totalHours: 3.0
      }
    ]
  },
  {
    type: 'PersonWorklog',
    id: 5545252,
    uuid: '3250d8f5-f459-49e9-8335-c7977d57f2ac',
    createdTimestamp: 1525252416000,
    updatedTimestamp: 1526269195000,
    updatedBy: 28425,
    createdBy: 28425,
    workerCount: 1,
    totalHours: 11.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 850408,
      worker: {
        type: 'Worker',
        id: 438011,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '12 New',
        lastName: 'Roeler'
      },
      classification: {
        type: 'Classification',
        id: 975719,
        name: 'My New Classification',
        status: 'DELETED'
      },
      costCodes: [],
      regularTime: 11.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: false,
      totalHours: 11.0
    }
  },
  {
    type: 'PersonWorklog',
    id: 5369377,
    uuid: 'e071b249-b2c9-4597-aae8-6532299fddb3',
    createdTimestamp: 1524647065000,
    updatedTimestamp: 1526068543000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'Lorem Ipsum Dolor',
    workerCount: 1,
    totalHours: 5.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 740468,
      worker: {
        type: 'Worker',
        id: 1975,
        workerType: 'USER',
        firstName: '13 Nick',
        lastName: 'Ryall'
      },
      costCodes: [],
      regularTime: 5.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: false,
      totalHours: 5.0
    }
  },
  {
    type: 'CrewWorklog',
    id: 5342332,
    uuid: '44d6cf52-8578-4d6e-b90a-7d9550430ce1',
    createdTimestamp: 1524475129000,
    updatedTimestamp: 1525857143000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'My Description',
    workerCount: 1,
    totalHours: 2.0,
    attachments: [],
    workLogType: 'CREW',
    name: '20 Second Crews',
    teamId: 2,
    timeCards: [
      {
        type: 'TimeCard',
        id: 916149,
        worker: {
          type: 'Worker',
          id: 459787,
          workerType: 'PERSON',
          status: 'ACTIVE',
          firstName: 'Jane',
          lastName: 'Eyre',
          projectId: 83711,
          employeeId: '12345'
        },
        classification: {
          type: 'Classification',
          id: 9666,
          name: 'Apprentice',
          status: 'DELETED'
        },
        costCodes: [],
        regularTime: 2.0,
        overTime: 0.0,
        doubleTime: 0.0,
        split: true,
        totalHours: 2.0
      }
    ]
  },
  {
    type: 'PersonWorklog',
    id: 5802657,
    uuid: '837eb24d-ed3b-4256-a35a-41ce259d4d11',
    createdTimestamp: 1526270771000,
    updatedTimestamp: 1526518857000,
    updatedBy: 28425,
    createdBy: 28425,
    workDesc: 'Some work got done.',
    workerCount: 1,
    totalHours: 24.0,
    teamId: 2,
    attachments: [
      {
        type: 'Media',
        id: 4295950,
        createdTimestamp: 1526332450000,
        updatedTimestamp: 1526343092000,
        updatedBy: 28425,
        createdBy: 28425,
        fileName: 'thingo.jpg',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/98590ecf-3561-46c3-a2ba-3a4ff77eddf1',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/98590ecf-3561-46c3-a2ba-3a4ff77eddf15209656250990039079_thumb.jpg',
        contentType: 'image/jpeg',
        etag: 'image/jpeg',
        fileSize: 1217658,
        uploadDate: 1526332449000,
        takenDate: 1523293741000
      },
      {
        type: 'Media',
        id: 4336511,
        createdTimestamp: 1526347914000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-5.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/10284dd5-b23c-468c-99cb-8970231c2f40',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/10284dd5-b23c-468c-99cb-8970231c2f403709029620048604204_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 150017,
        uploadDate: 1526347913000
      },
      {
        type: 'Media',
        id: 4336512,
        createdTimestamp: 1526347915000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-6.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/f1f50879-35a5-423f-9c25-7a6341002a06',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/f1f50879-35a5-423f-9c25-7a6341002a062339683049046221190_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 225199,
        uploadDate: 1526347913000
      },
      {
        type: 'Media',
        id: 4336513,
        createdTimestamp: 1526347915000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-7.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/a3df529b-3e6d-4351-8ab1-77ba428dac79',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/a3df529b-3e6d-4351-8ab1-77ba428dac79382211848930648482_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 161177,
        uploadDate: 1526347913000
      },
      {
        type: 'Media',
        id: 4543918,
        createdTimestamp: 1526438479000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-4.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/e51b7313-d835-45cc-9285-81c1ee436c20',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/e51b7313-d835-45cc-9285-81c1ee436c203149218518453674726_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 112407,
        uploadDate: 1526438476000
      },
      {
        type: 'Media',
        id: 4543919,
        createdTimestamp: 1526438480000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-6.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/c40bd366-a214-411f-a49d-ba73ed693aec',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/c40bd366-a214-411f-a49d-ba73ed693aec6434808008299723829_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 225199,
        uploadDate: 1526438477000
      },
      {
        type: 'Media',
        id: 4543920,
        createdTimestamp: 1526438480000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-5.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/a920b85f-cd49-4d8e-a0a6-d5822af673f9',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/a920b85f-cd49-4d8e-a0a6-d5822af673f93936548855912408996_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 150017,
        uploadDate: 1526438477000
      },
      {
        type: 'Media',
        id: 4543921,
        createdTimestamp: 1526438480000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-3.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/ddf55aae-9b32-4f93-8b8a-cc0eb530c461',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/ddf55aae-9b32-4f93-8b8a-cc0eb530c4617757663255038730520_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 119984,
        uploadDate: 1526438476000
      },
      {
        type: 'Media',
        id: 4543922,
        createdTimestamp: 1526438480000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-1.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/1fb1d081-a76b-4791-aa62-f941ff388939',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/1fb1d081-a76b-4791-aa62-f941ff3889397736856797145801287_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 210139,
        uploadDate: 1526438477000
      },
      {
        type: 'Media',
        id: 4543923,
        createdTimestamp: 1526438481000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-2.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/7ef68307-36c2-489e-858e-77a0aa44c138',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/7ef68307-36c2-489e-858e-77a0aa44c1383283081475340047902_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 211345,
        uploadDate: 1526438477000
      },
      {
        type: 'Media',
        id: 4543924,
        createdTimestamp: 1526438482000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-7.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/71b25a9b-7db8-492e-b3ae-8876f239176e',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/71b25a9b-7db8-492e-b3ae-8876f239176e4393052799336815588_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 161177,
        uploadDate: 1526438480000
      },
      {
        type: 'Media',
        id: 4543925,
        createdTimestamp: 1526438483000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-9.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/6df65752-0416-4709-b5fe-f14399f93a4e',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/6df65752-0416-4709-b5fe-f14399f93a4e9219636888517651561_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 161064,
        uploadDate: 1526438481000
      },
      {
        type: 'Media',
        id: 4543926,
        createdTimestamp: 1526438483000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-8.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/515e2765-fe78-467e-b6fd-8c96040c7863',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/515e2765-fe78-467e-b6fd-8c96040c78638744574903390909257_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 217161,
        uploadDate: 1526438481000
      },
      {
        type: 'Media',
        id: 4543927,
        createdTimestamp: 1526438483000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-10.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/ad625400-8e17-4f17-a0e0-54155f04b068',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/ad625400-8e17-4f17-a0e0-54155f04b0685921864363587315536_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 202932,
        uploadDate: 1526438481000
      },
      {
        type: 'Media',
        id: 4543928,
        createdTimestamp: 1526438484000,
        updatedBy: 0,
        createdBy: 28425,
        fileName: 'project-11.png',
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/e7249885-c870-4647-abd8-21f58b01dcbe',
        thumbUrl:
          'https://s3.amazonaws.com/raken-dev/2018/05/e7249885-c870-4647-abd8-21f58b01dcbe6737939108954808476_thumb.png',
        contentType: 'image/png',
        etag: 'image/png',
        fileSize: 174697,
        uploadDate: 1526438482000
      }
    ],
    workLogType: 'PERSON',
    timeCard: {
      type: 'TimeCard',
      id: 1015763,
      worker: {
        type: 'Worker',
        id: 422757,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '21 Tim',
        lastName: 'Smith'
      },
      classification: {
        type: 'Classification',
        id: 975724,
        name: 'Front End',
        status: 'DELETED'
      },
      costCodes: [],
      regularTime: 24.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: false,
      totalHours: 24.0
    }
  },
  {
    type: 'PersonWorklog',
    id: 5632165,
    uuid: '22ef6728-5482-4e19-afe7-3f619108da59',
    createdTimestamp: 1525669785000,
    updatedTimestamp: 1526269662000,
    updatedBy: 28425,
    createdBy: 28425,
    workerCount: 1,
    totalHours: 11.0,
    attachments: [],
    workLogType: 'PERSON',
    teamId: 2,
    timeCard: {
      type: 'TimeCard',
      id: 906527,
      worker: {
        type: 'Worker',
        id: 422755,
        workerType: 'PERSON',
        status: 'ACTIVE',
        firstName: '22 Tony',
        lastName: 'Montana'
      },
      classification: {
        type: 'Classification',
        id: 975723,
        name: 'My Class',
        status: 'DELETED'
      },
      costCodes: [],
      regularTime: 11.0,
      overTime: 0.0,
      doubleTime: 0.0,
      split: false,
      totalHours: 11.0
    }
  }
];

const projectMembersData = [
  {
    id: 27353,
    username: 'eterehov+60@neklo.com',
    phone: '(375) 297-7665',
    company: {
      id: 21965,
      name: 'raken stage',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Eugene',
    lastName: 'Terehov',
    images: [],
    lastLogin: 1511478558000,
    projectTeamIds: [2]
  },
  {
    id: 26421,
    username: 'eterehov@neklo.com',
    phone: '+16421552497',
    company: {
      id: 21980,
      name: 'Neklo_Test',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Eugene',
    lastName: 'Test1',
    images: [
      {
        id: 1581763,
        createdTimestamp: 1497853585000,
        updatedBy: 0,
        createdBy: 26421,
        createdByRef: {
          id: 26421,
          name: 'eterehov@neklo.com',
          firstName: 'Eugene',
          lastName: 'Test1',
          active: true
        },
        mediaType: 'IMAGE',
        contentUrl:
          'https://s3.amazonaws.com/raken-stag/2017/06/7995b4fd-a6ab-4950-bf15-538f5a1baeb4',
        thumbUrl:
          'https://s3.amazonaws.com/raken-stag/2017/06/7995b4fd-a6ab-4950-bf15-538f5a1baeb4_thumb.jpg',
        contentType: 'image/jpeg',
        etag: 'image/jpeg',
        fileSize: 0,
        uploadDate: 1497835585000
      }
    ],
    lastLogin: 1536885999000,
    projectTeamIds: [2]
  },
  {
    id: 26489,
    username: 'anastasia.lyutarevich+867@neklo.com',
    phone: '(123) 456-    ',
    company: {
      id: 21980,
      name: 'Neklo_Test',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Nora',
    lastName: 'Melloni',
    images: [],
    lastLogin: 1517391533000,
    teamId: 56603
  },
  {
    id: 27715,
    username: 'nickw+2@rakenapp.com',
    phone: '(888) 888-8888',
    company: {
      id: 22863,
      name: 'TEST_MARKETING',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Nick',
    lastName: 'Warner',
    images: [],
    lastLogin: 1532038580000,
    projectTeamIds: [2]
  },
  {
    id: 28425,
    username: 'nick@rakenapp.com',
    phone: '(021) 552-497 ',
    company: {
      id: 23470,
      name: 'RakenNZ',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Nick',
    lastName: 'Ryall',
    images: [],
    lastLogin: 1533261132000,
    projectTeamIds: [2]
  },
  {
    id: 42334,
    username: 'trent@rakenapp.com',
    company: {
      id: 28060,
      name: 'Gibson Group intranet',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Trenton',
    lastName: 'Lowe',
    images: [],
    inviteId: 42334,
    projectTeamIds: [2]
  },
  {
    id: 43938,
    username: 'trent@foobar.com',
    company: {
      id: 327290,
      name: 'Fortis Construction | NEW',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Trent',
    lastName: 'Hogan',
    images: [],
    inviteId: 43938,
    projectTeamIds: [2]
  },
  {
    id: 44826,
    username: 'zing@zoom.com',
    company: {
      id: 338638,
      name: 'Fortis Constr',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Zing',
    lastName: '',
    images: [],
    inviteId: 44826,
    projectTeamIds: [2]
  },
  {
    id: 45380,
    username: 'rick@rick.com',
    company: {
      id: 345387,
      name: 'My New Companys',
      members: [],
      status: 'ACTIVE',
      images: []
    },
    role: 'ROLE_ACCOUNT_ADMIN',
    firstName: 'Rick',
    lastName: 'Ryall',
    images: [],
    inviteId: 45380,
    projectTeamIds: [2]
  }
];

const companyMembersData = {
  page: {
    offset: 0,
    limit: 9,
    totalElements: 9
  },
  collection: [
    {
      type: 'User',
      id: 56640,
      username: 'test_pending@neklo.com',
      uuid: 'cb1dc940-11bf-45de-bc31-7b19d09d85f3',
      isPending: true,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'A',
      lastName: 'new',
      images: [],
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 26490,
      username: 'anastasia.lyutarevich+868@neklo.com',
      phone: '123456',
      uuid: 'a02204c5-6719-4d27-89e7-3c2ecf287e64',
      isPending: false,
      role: 'ROLE_PROJECT_MEMBER',
      firstName: 'Aira',
      lastName: 'Zah',
      images: [],
      lastLogin: 1504248239000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 26421,
      username: 'eterehov@neklo.com',
      phone: '+16421552497',
      uuid: '5d7546af-5b40-4a79-bc7f-0ea92504e27e',
      isPending: false,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'Eugene',
      lastName: 'Test1',
      images: [],
      lastLogin: 1536885999000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 458216,
      username: 'kim@rakenapp.com',
      phone: '(642) 111-6765',
      uuid: 'd04e3b83-3a60-4831-a4a8-40b47ab535c4',
      isPending: false,
      role: 'ROLE_PROJECT_MEMBER',
      firstName: 'Kim',
      lastName: 'Hue',
      images: [],
      lastLogin: 1534720442000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 26489,
      username: 'anastasia.lyutarevich+867@neklo.com',
      phone: '(123) 456-    ',
      uuid: 'dc67daee-bfa3-4fc7-b4e4-7a272108ea7e',
      isPending: false,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'Nora',
      lastName: 'Melloni',
      images: [],
      lastLogin: 1517391533000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 29354,
      username: 'maxim.cherny+sc21@neklo.com',
      phone: '(375) 257-4932',
      uuid: '891e9afd-6b0d-4254-b367-3715bb72a796',
      isPending: true,
      role: 'ROLE_PROJECT_MEMBER',
      firstName: 'SC21',
      lastName: 'Cherny',
      images: [],
      lastLogin: 1535512817000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 26849,
      username: 'adminewrw@tet.com',
      phone: '(   )    -    ',
      uuid: '05f480b5-c0c1-4313-b5fd-5a7288dc5e62',
      isPending: false,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'erwerw',
      lastName: 'werwer',
      images: [],
      lastLogin: 1522315644000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 26442,
      username: 'polyansky_03_04@neklo.com',
      phone: '(555)    -    ',
      uuid: 'e10aba6a-13cf-4419-ab2d-c126c5f5e5f8',
      isPending: false,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'swf',
      lastName: 'wf',
      images: [],
      lastLogin: 1457143453000,
      company: {
        id: 1
      }
    },
    {
      type: 'User',
      id: 629038,
      username: 'yuanlin1110+token@gmail.com',
      phone: '+1111',
      uuid: '68c14be9-3133-4dc5-8401-62dd441d431e',
      isPending: false,
      role: 'ROLE_ACCOUNT_ADMIN',
      firstName: 'yuan',
      lastName: '123',
      images: [],
      lastLogin: 1536880204000,
      company: {
        id: 1
      }
    }
  ]
};

const companyPermissionsData = {
  collection: [
    {
      role: 'ROLE_ACCOUNT_ADMIN',
      roleName: 'Account Administrator',
      description: 'Automatically added to every new project created',
      enabled: true,
      permissionEnum: 'AUTO_ADD_NEW_PROJECT'
    },
    {
      role: 'ROLE_ACCOUNT_ADMIN',
      roleName: 'Account Administrator',
      description: 'Can create new projects',
      enabled: true,
      permissionEnum: 'CUD_PROJECTS'
    },
    {
      role: 'ROLE_ADMIN',
      roleName: 'Project Administrator',
      description: 'Automatically added to every new project created',
      enabled: true,
      permissionEnum: 'AUTO_ADD_NEW_PROJECT'
    },
    {
      role: 'ROLE_ADMIN',
      roleName: 'Project Administrator',
      description: 'Can create new projects',
      enabled: true,
      permissionEnum: 'CUD_PROJECTS'
    },
    {
      role: 'ROLE_ADMIN',
      roleName: 'Project Administrator',
      description: 'Can invite new Team Members to my company',
      enabled: false,
      permissionEnum: 'INVITE_NEW_TEAM_MEMBERS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can invite Collaborators to projects',
      enabled: true,
      permissionEnum: 'INVITE_COLLABORATORS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can use the automatic email feature',
      enabled: false,
      permissionEnum: 'USE_AUTOMATIC_EMAIL_FEATURE'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can modify daily report settings',
      enabled: false,
      permissionEnum: 'MODIFY_DAILY_REPORT_SETTINGS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can create/edit/delete Cost Codes',
      enabled: true,
      permissionEnum: 'CUD_COST_CODES'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can create new projects',
      enabled: true,
      permissionEnum: 'CUD_PROJECTS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can invite new Team Members to my company',
      enabled: false,
      permissionEnum: 'INVITE_NEW_TEAM_MEMBERS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can create/edit/delete Workers',
      enabled: true,
      permissionEnum: 'CUD_WORKERS'
    },
    {
      role: 'ROLE_PROJECT_MEMBER',
      roleName: 'Project Member',
      description: 'Can modify Survey Question settings',
      enabled: false,
      permissionEnum: 'MODIFY_SURVEY_QUESTION_SETTINGS'
    }
  ]
};

const toolboxTalksData = {
  page: {
    offset: 0,
    limit: 10,
    totalElements: 2
  },
  collection: [
    {
      uuid: 'ffbab577-8307-42b3-a3b3-034497c54d65',
      name: 'lorem ipsum1',
      document: {
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/processed/2019/01/23/dc646c58-2754-4b5a-bd30-b26dfadcc161'
      },
      countUpcoming: 0,
      countPast: 5,
      lastPresentedDate: '2019-02-02',
      status: 'ACTIVE'
    },
    {
      uuid: '730d8769-e8bb-48d8-811e-1a02a9f94f3b',
      name: 'lorem ipsum2',
      document: {
        contentUrl:
          'https://s3.amazonaws.com/raken-dev/processed/2019/01/23/dc646c58-2754-4b5a-bd30-b26dfadcc161'
      },
      countUpcoming: 2,
      countPast: 0,
      lastPresentedDate: '2019-02-02',
      status: 'DELETED'
    }
  ]
};

const superAdminCompaniesData = {
  page: {
    offset: 0,
    limit: 2,
    totalElements: 2
  },
  collection: [
    {
      id: 95893,
      uuid: '3c58d8ed-fe22-4946-b35c-13520a2f91bc',
      name: 'Kings Inc.',
      status: 'ACTIVE',
      subdomain: 'kings-inc',
      subscription: {
        startDate: '2018-06-02',
        status: 'ACTIVE',
        billingPlan: 'annual',
        pricePerSeat: '37'
      }
    },
    {
      id: 78915,
      uuid: '2aff3868-3e8a-4d93-bae6-225de7fd544d',
      name: 'RCB',
      status: 'ACTIVE',
      subdomain: 'rcb',
      subscription: {
        startDate: '2018-02-05',
        status: 'ACTIVE',
        billingPlan: 'monthly',
        pricePerSeat: '30'
      }
    }
  ]
};

const costCodesData = {
  page: {
    totalElements: 3
  },
  collection: [
    {
      type: 'CostCode',
      id: 11,
      uuid: '403aae84-aa1c-11e9-a2a3-2a2ae2dbcce4',
      division: '02 General - Small Build',
      code: '0 2100',
      description: 'Cleanup',
      projects: [
        {
          projectId: 360618,
          projectStatus: 'ACTIVE'
        },
        {
          projectId: 1085,
          budgetedHours: 1504.0,
          projectStatus: 'ACTIVE'
        }
      ],
      isDefault: true
    },
    {
      type: 'CostCode',
      id: 13,
      uuid: '88eb09f4-aa1b-11e9-a2a3-2a2ae2dbcce4',
      division: '02 General - Big Build',
      code: '01-600',
      description: 'Medium repair',
      projects: [
        {
          projectId: 360647,
          projectStatus: 'ACTIVE'
        },
        {
          projectId: 360618,
          projectStatus: 'ACTIVE'
        },
        {
          projectId: 1085,
          budgetedHours: 1504.0,
          projectStatus: 'ACTIVE'
        }
      ],
      isDefault: true
    },
    {
      type: 'CostCode',
      id: 94,
      uuid: '6421e3e6-aa1f-11e9-a2a3-2a2ae2dbcce4',
      division: '01 GENERAL REQUIREMENTS',
      code: '01-600',
      description: 'Equipment  Rental',
      projects: [],
      isDefault: true
    },
    {
      type: 'CostCode',
      id: 31,
      uuid: '19913728-aa1f-11e9-a2a3-2a2ae2dbcce4',
      division: '07  Thermal and Moisture Protection',
      code: 'code: 0 7050',
      description: 'Damproofing and Waterproofing',
      projects: [],
      isDefault: true
    }
  ]
};

const integrationsData = {
  collection: [
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://app.box.com/api/oauth2/authorize?response_type=code&client_id=mfcfakrmgi9tsamy942nfzaur5nzzze4'
          },
          providerUrl: {
            href: 'https://www.box.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1000,
          name: 'Box'
        },
        companyRef: {
          id: 23170,
          name: 'NZ General'
        },
        status: 'NOT_CONNECTED',
        slug: 'box'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'CLOUD'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://www.dropbox.com/1/oauth2/authorize?response_type=code&redirect_uri=https://develop.rakenapp.com/apps/dropbox/authorize&client_id=jgc4qxd2pzwuhxj'
          },
          providerUrl: {
            href: 'https://www.dropbox.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1001,
          name: 'Dropbox'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'dropbox'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'CLOUD'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/drive&redirect_uri=https://develop.rakenapp.com/apps/drive/authorize&client_id=477139597865-ah00spcfbgj1s8f7e2dvq5gqc9s4vf9t.apps.googleusercontent.com'
          },
          providerUrl: {
            href: 'https://www.google.com/drive/'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1002,
          name: 'Google Drive'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'google-drive'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'CLOUD'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://us-partner-integrations.egnyte.com/services/oauth/code?scope=Egnyte.filesystem&redirect_uri=SPECIFY_FOR_EACH_ENVIRONMENT&client_id=SPECIFY_FOR_EACH_ENVIRONMENT'
          },
          providerUrl: {
            href: 'http://www.egnyte.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1003,
          name: 'Egnyte'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'egnyte'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'CLOUD'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'SPECIFY_FOR_EACH_ENVIRONMENT&client_id=002b02edbd221bc6ebda5f5fcac40eeeb724c761d9b1a0617a853fdd4e079162&redirect_uri=SPECIFY_FOR_EACH_ENVIRONMENT'
          },
          providerUrl: {
            href: 'http://www.procore.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1004,
          name: 'Procore'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'procore'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'INTEGRATION'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href: '/apps/prolog/login-dialog?app.id=1005&client_id=null'
          },
          providerUrl: {
            href: 'https://www.trimble.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1005,
          name: 'Trimble Prolog'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'trimble-prolog'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'INTEGRATION'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&scope=files.readwrite%20offline_access&client_id=SPECIFY_FOR_EACH_ENVIRONMENT&redirect_uri=SPECIFY_FOR_EACH_ENVIRONMENT'
          },
          providerUrl: {
            href: 'https://onedrive.live.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1006,
          name: 'Microsoft OneDrive'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'microsoft-onedrive'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'CLOUD'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://developer.api.autodesk.com/authentication/v1/authorize?response_type=code&scope=data:create%20data:read%20data:write&client_id=SPECIFY_FOR_EACH_ENVIRONMENT&redirect_uri=SPECIFY_FOR_EACH_ENVIRONMENT'
          },
          providerUrl: {
            href: 'https://bim360.autodesk.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1007,
          name: 'Autodesk BIM 360'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'autodesk-bim-360'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'INTEGRATION'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          providerUrl: {
            href: 'https://www.foundationsoft.com'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1008,
          name: 'Foundation'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'foundation'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'INTEGRATION'
    },
    {
      service: {
        type: 'CloudService',
        _links: {
          authUrl: {
            href:
              'https://us1.aconex.com/Logon?Action=StartOAuth&client_id=SPECIFY_FOR_THIS_ENVIRONMENT&redirect_uri=SPECIFY_FOR_THIS_ENVIRONMENT'
          },
          providerUrl: {
            href:
              'https://www.oracle.com/industries/construction-engineering/aconex-products.html'
          }
        },
        createdBy: -1,
        providerRef: {
          id: 1009,
          name: 'Oracle Aconex'
        },
        companyRef: {
          id: 1,
          name: 'BiznessName1'
        },
        status: 'NOT_CONNECTED',
        slug: 'oracle-aconex'
      },
      hasExternal: false,
      external: [],
      mappings: [],
      type: 'INTEGRATION'
    }
  ]
};

const checklistTemplatesData = {
  collection: [
    {
      businessUuid: 'efa505ce-f3d1-4c47-9e6a-bb48f6c2ad47',
      checklistTemplateSections: [
        {
          checklistTemplateQuestions: [
            {
              position: 1,
              questionText: 'Checklist template question 1',
              responseType: 'CHECKBOX',
              uuid: '9476441b-b971-497a-9ad2-4188d9327ccd'
            }
          ],
          description: 'Checklist 1 section 1 description',
          position: 1,
          title: 'Checklist section 1 title',
          uuid: 'f76f2a80-7e33-4af0-b2c8-ea4a4bb46733'
        }
      ],
      createdBy: 'f2c6e1f7-716f-47df-a267-8478da81a8a8',
      createdTimestamp: 0,
      description: 'Checklist template 1 description',
      modifiedBy: 'f2c6e1f7-716f-47df-a267-8478da81a8a8',
      updatedTimestamp: 0,
      name: 'Checklist template 1',
      status: 'DRAFT',
      uuid: '815e55d2-b9a2-401f-babd-92644486df0e',
      scope: 'COMPANY'
    },
    {
      businessUuid: '0a5fca60-6d49-472d-a2d9-97c763e24045',
      checklistTemplateSections: [
        {
          checklistTemplateQuestions: [
            {
              position: 1,
              questionText: 'Checklist template question 1',
              responseType: 'CHECKBOX',
              uuid: '41114523-fc4c-4fe6-bf08-9ad138459254'
            },
            {
              position: 2,
              questionText: 'Checklist template question 2',
              responseType: 'YES_NO_NA',
              uuid: 'b17ef26e-72f1-432e-b8c1-7d64f9c8955a'
            }
          ],
          description: 'Checklist 2 section 1 description',
          position: 1,
          title: 'Checklist 2 section 1 title',
          uuid: '41114523-fc4c-4fe6-bf08-9ad138459254'
        }
      ],
      createdBy: '30f0d493-4dcb-49e6-8cb1-9440f3187725',
      createdTimestamp: 0,
      description: 'Checklist template 2 description',
      modifiedBy: '30f0d493-4dcb-49e6-8cb1-9440f3187725',
      updatedTimestamp: 0,
      name: 'Checklist template 2',
      status: 'DRAFT',
      uuid: '02da664f-6b0e-4eb1-b866-cdd5d91111a2',
      scope: 'COMPANY'
    },
    {
      businessUuid: 'c5f20fbd-2d41-401c-aecf-8493f76c9c7f',
      checklistTemplateSections: [
        {
          checklistTemplateQuestions: [
            {
              position: 1,
              questionText: 'Checklist template 3 question 1',
              responseType: 'CHECKBOX',
              uuid: '41114523-fc4c-4fe6-bf08-9ad138459254'
            }
          ],
          description: 'Checklist 3 section 1 description',
          position: 1,
          title: 'Checklist 3 section 1 title',
          uuid: '65d52f66-f92c-4741-b471-ec970087daef'
        },
        {
          checklistTemplateQuestions: [
            {
              position: 2,
              questionText: 'Checklist template 3 question 1',
              responseType: 'TEXT',
              uuid: '0230ebb1-798d-4947-833e-0edcc8bd56f4'
            }
          ],
          description: 'Checklist 3 section 2 description',
          position: 1,
          title: 'Checklist 3 section 1 title',
          uuid: 'fe3ad5ec-ef45-4442-adf3-1f7384e1302e'
        }
      ],
      createdBy: '7f725d7c-c388-4709-85ac-02972f3d5686',
      createdTimestamp: 0,
      description: 'Checklist template 3 description',
      modifiedBy: '7f725d7c-c388-4709-85ac-02972f3d5686',
      updatedTimestamp: 0,
      name: 'Random checklist template',
      status: 'DRAFT',
      uuid: '4550acb6-4b84-4f28-83a4-6df8c8c6b57a',
      scope: 'COMPANY'
    }
  ]
};

const checklistProjectData = {
  collection: [
    {
      uuid: 'f01ce088-6677-4fde-9f37-abe84b916531',
      templateUuid: '815e55d2-b9a2-401f-babd-92644486df0e',
      checklistSections: [
        {
          uuid: '101bc659-450a-40bb-86dc-10ca2bff4083',
          checklistQuestions: [
            {
              uuid: 'ae6c7358-2c48-4163-9bb0-6f337f9735b5',
              checklistResponses: [],
              questionText:
                'Were there any people who were taking up into the tornado?',
              responseType: 'YES_NO_NA',
              position: 2
            },
            {
              uuid: 'c76f3ff8-4d28-48b5-a6ca-29a136ec370e',
              checklistResponses: [],
              questionText:
                'Were there a sufficient number of cows taken up into the tornado?',
              responseType: 'YES_NO_NA',
              position: 1
            },
            {
              uuid: '1ab58049-7ded-4254-b4d0-49ecf5fbf8d1',
              checklistResponses: [],
              questionText:
                'Please describe the total amount of items taken into the tornado?',
              responseType: 'TEXT',
              position: 3
            }
          ],
          position: 2,
          title: 'Advanced Tornado Info',
          description:
            'More detailed and specific details about the tornado in question'
        },
        {
          uuid: '81193ade-d4a6-40f1-80f8-61b695f95abe',
          checklistQuestions: [
            {
              uuid: '2a55a535-9d36-41e5-8edc-29d17e9c3b81',
              checklistResponses: [],
              questionText: 'Please describe the clean up process?',
              responseType: 'TEXT',
              position: 5
            },
            {
              uuid: '1b648bc2-eb0c-4c07-9a95-bdaea3748427',
              checklistResponses: [],
              questionText: 'Clean up trash',
              responseType: 'CHECKBOX',
              position: 2
            },
            {
              uuid: '18aee5e6-bb68-4cca-a342-96f5a337c822',
              checklistResponses: [],
              questionText: 'Clean up cows',
              responseType: 'CHECKBOX',
              position: 1
            },
            {
              uuid: 'e53300ae-3b60-4108-b384-e87dfe9749c9',
              checklistResponses: [],
              questionText: 'Clean up houses',
              responseType: 'CHECKBOX',
              position: 3
            },
            {
              uuid: '98774c40-f1e1-4ae3-8862-fcf804aa5462',
              checklistResponses: [],
              questionText: 'Clean up candy',
              responseType: 'CHECKBOX',
              position: 4
            }
          ],
          position: 3,
          title: 'Clean up',
          description: 'Details about the clean up of the tornado damage'
        },
        {
          uuid: 'e42b9822-64fb-4429-bb61-305360d396a8',
          checklistQuestions: [
            {
              uuid: 'fc10af6b-1457-4b27-be6a-3e3dc1ae2128',
              checklistResponses: [],
              questionText:
                'Vehicula phasellus magnis torquent pharetra suspendisse feugiat integer vivamus, netus arcu bibendum interdum tristique mi morbi porttitor aenean, dapibus per justo montes enim sodales elementum. Placerat gravida nibh torquent class orci enim malesuada platea blandit nulla vitae, augue tempus leo proin conubia sapien hendrerit vivamus a aliquet. Odio nascetur cras morbi viverra facilisis quam nulla venenatis aenean, orci vivamus tempus accumsan fames elementum faucibus at nam gravida, laoreet lacinia euismod feugiat rutrum dapibus id curae. Fusce dis et felis interdum luctus proin enim eu, cum phasellus morbi vel eros praesent amet sem dictumst, iaculis rhoncus litora quisque massa conubia nulla.',
              responseType: 'TEXT',
              position: 5
            },
            {
              uuid: 'f73a0dbf-674b-43d0-bf69-7b41360c5a76',
              checklistResponses: [],
              questionText:
                'Massa neque porttitor posuere euismod accumsan magna justo cubilia nec torquent lacinia',
              responseType: 'CHECKBOX',
              position: 4
            },
            {
              uuid: '1164c653-783b-4d56-8109-ec381c9c8a20',
              checklistResponses: [],
              questionText:
                'Id mauris dolor est proin quam fringilla arcu habitasse torquent, lobortis sed adipiscing parturient convallis sit ultricies faucibus varius risus, enim vehicula ullamcorper nunc accumsan lacus viverra inceptos. Parturient hac est justo viverra pretium maecenas commodo pharetra eget ullamcorper nunc sociis vitae, ut ultrices volutpat per conubia morbi cum a pulvinar congue massa ligula.',
              responseType: 'CHECKBOX',
              position: 2
            },
            {
              uuid: '74c34a1d-70be-4947-9ba4-4839cf07d756',
              checklistResponses: [],
              questionText:
                'Tempus congue faucibus nibh ornare augue nisi in donec id, bibendum sit accumsan tortor dis nulla dui dictum, venenatis eu mauris placerat molestie iaculis potenti vitae.',
              responseType: 'CHECKBOX',
              position: 1
            },
            {
              uuid: 'a78baab3-5628-4fac-a20a-4f1192e456ac',
              checklistResponses: [],
              questionText:
                'Ligula maecenas a facilisi ac sapien nisi cursus aptent consequat bibendum, nascetur adipiscing lacus potenti dis porttitor odio placerat vivamus urna, massa dolor pulvinar ridiculus elementum viverra rhoncus lacinia purus. Tincidunt tempor consequat pulvinar congue cras curae gravida, primis enim varius lectus leo nisi, dis at curabitur dictum ligula lacus. Inceptos praesent dictum tincidunt ad sapien id vulputate, pharetra nisi aptent pellentesque morbi metus sed, penatibus leo potenti vel felis adipiscing.',
              responseType: 'CHECKBOX',
              position: 3
            }
          ],
          position: 4,
          title:
            'Varius parturient ipsum donec quam dolor neque arcu duis, a in habitant aenean cum suspendisse scelerisque.',
          description:
            'Odio fringilla iaculis tellus enim vulputate dignissim purus facilisis ut, himenaeos ad magnis nunc sed faucibus per posuere nibh, curae ipsum hendrerit quis ornare phasellus ultricies sem. Netus justo nam nostra magnis felis per vestibulum elementum quis primis, parturient litora nulla placerat fames donec odio ac pharetra. Feugiat luctus accumsan pellentesque fermentum purus sagittis viverra mi molestie gravida, ligula urna mollis sapien hendrerit dignissim vehicula donec elit, bibendum mattis condimentum magnis placerat venenatis auctor eros turpis.'
        },
        {
          uuid: 'def61180-fa2d-493d-a03f-e05aa68b9eca',
          checklistQuestions: [
            {
              uuid: '67934663-92c5-4a87-a707-ea0c57ad794f',
              checklistResponses: [],
              questionText:
                'Is the tornado big enough that you would fear for your lives?',
              responseType: 'YES_NO_NA',
              position: 2
            },
            {
              uuid: '1f947517-cada-4c08-b9e9-c326c53e1264',
              checklistResponses: [],
              questionText:
                'Describe the size, shape, sound, and smell of the tornado?',
              responseType: 'TEXT',
              position: 3
            },
            {
              uuid: '53289908-ad9a-429d-acbb-58ab401b2e52',
              checklistResponses: [],
              questionText: 'Is there a tornado?',
              responseType: 'CHECKBOX',
              position: 1
            }
          ],
          position: 1,
          title: 'Tornado Basics',
          description: 'Edited by AA (RAJ) at 10;34'
        }
      ],
      businessUuid: '1e1f536c-45e6-4375-9dde-9fa388590b3b',
      name: 'Uno',
      status: 'ACTIVE',
      createdBy: {
        firstName: 'Mila',
        lastName: 'A',
        fullName: 'Mila A',
        uuid: 'c97e8f29-5dd6-4493-bf07-e0206c8a82a2'
      },
      createdTimestamp: 1597968266000,
      modifiedBy: {
        firstName: 'Mila',
        lastName: 'A',
        fullName: 'Mila A',
        uuid: 'c97e8f29-5dd6-4493-bf07-e0206c8a82a2'
      },
      updatedTimestamp: 1597968266000
    }
  ],
  page: { offset: 0, limit: 1, totalElements: 1 }
};

const companyWorkersData = {
  page: { totalElements: 22 },
  collection: [
    {
      type: 'Worker',
      id: 135879,
      workerType: 'USER',
      firstName: 'Andrew',
      lastName: 'Watt',
      classificationName: 'Superintendent',
      email: 'andrew@rakenapp.com',
      phoneNumber: '+19495009881',
      code: '57a91a31db08609',
      workerUuid: 'efe036ee-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      employeeId: '12345',
      projects: []
    },
    {
      type: 'Worker',
      id: 259208,
      workerType: 'USER',
      firstName: 'Ashley',
      lastName: 'Zeller',
      classificationName: '',
      email: 'ashley+10@rakenapp.com',
      phoneNumber: '+17605793676',
      code: '69SFN9oFTrPRwTX',
      workerUuid: '8cb8ef60-cb17-4084-b66d-21b119db3812',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 46512,
      workerType: 'USER',
      firstName: 'Betzi',
      lastName: 'Sawchuk',
      classificationName: 'Other',
      email: 'betzi@rakenapp.com',
      phoneNumber: '+19897981556',
      code: '085df4d5a370218',
      workerUuid: 'ef5a0789-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 165875,
      workerType: 'USER',
      firstName: 'David',
      lastName: 'Nash',
      classificationName: '',
      email: 'davidandnash@gmail.com',
      phoneNumber: '+15555555555',
      code: '671491b57560ac2',
      workerUuid: 'f0703050-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 259261,
      workerType: 'PERSON',
      firstName: 'Jack',
      lastName: 'Policar',
      classificationId: 131073,
      classificationName: 'Apprentice',
      email: '',
      phoneNumber: '',
      code: '3pMYtydDphQHPTf',
      workerUuid: 'cc5035f0-0860-4cb0-9242-a0ec3190c967',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 215345,
      workerType: 'USER',
      firstName: 'James',
      lastName: 'Watt',
      classificationName: '',
      email: 'james.watt@rakenapp.com',
      phoneNumber: '+17603091643',
      code: '7NozCXx2z10bKYM',
      workerUuid: 'df784bb0-e918-43fa-8a6f-2a1f518d04ee',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 258671,
      workerType: 'PERSON',
      firstName: 'Jennifer',
      lastName: 'Miranda',
      classificationId: 65538,
      classificationName: 'Journeyman',
      code: 'wMAusLxrdBID0To',
      workerUuid: 'd9d6fff4-cd71-4ab0-ab72-6d7b7011a29a',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 200142,
      workerType: 'USER',
      firstName: 'Jennifer',
      lastName: 'Moreno',
      classificationName: '',
      email: 'jennifer@rakenapp.com',
      phoneNumber: '+17609366146',
      code: 'RMiaLWoTWzblMI0',
      workerUuid: '3c5b5d1b-095c-44e2-8d34-9d17af6c8e46',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 17,
      workerType: 'USER',
      firstName: 'Jimmy',
      lastName: 'Johnson',
      classificationName: 'Executive',
      email: 'wt@rakenapp.com',
      phoneNumber: '+14155161632',
      code: '70efdf2ec9b0860',
      workerUuid: 'eea116f4-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 16,
      workerType: 'USER',
      firstName: 'Joe',
      lastName: 'Burns',
      classificationName: 'Project Engineer',
      email: 'wtdemo@rakenapp.com',
      phoneNumber: '(626) 788-4242',
      code: 'c74d97b01eae257',
      workerUuid: 'eea11360-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 25335,
      workerType: 'USER',
      firstName: 'Kyle',
      lastName: 'Slager',
      email: 'kyleslager@rakenapp.com',
      phoneNumber: '(760) 223-1234',
      code: '6aa899171430d12',
      workerUuid: 'ef05e39d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 150977,
      workerType: 'PERSON',
      firstName: 'Leroy',
      lastName: 'Brown',
      classificationId: 131073,
      classificationName: 'Apprentice',
      phoneNumber: '+1123456789',
      code: '2f1ceb52ec1d2fe',
      workerUuid: 'f0340e8d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 150978,
      workerType: 'PERSON',
      firstName: 'Michael',
      lastName: 'Jones',
      classificationId: 196608,
      classificationName: 'Laborer',
      code: '171f6fd52195fe3',
      workerUuid: 'f0340f6f-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 216224,
      workerType: 'USER',
      firstName: 'Nathan',
      lastName: 'William',
      classificationName: '',
      email: 'nate@rakenapp.com',
      phoneNumber: '+12483534455',
      code: 'H5zhNZ4wBIDcKj7',
      workerUuid: '958ae23c-c4b1-40ff-80ff-5c551b7c3bf9',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 188556,
      workerType: 'USER',
      firstName: 'Nick',
      lastName: 'Johnson',
      classificationName: 'Other',
      email: 'navah+1@rakenapp.com',
      phoneNumber: '+11234567890',
      code: 'b79243bb4284b32',
      workerUuid: 'f0da332c-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 259223,
      workerType: 'USER',
      firstName: 'Nicolette',
      lastName: 'Affre',
      classificationName: '',
      email: 'nicolette+1@rakenapp.com',
      phoneNumber: '+17608140339',
      code: 'BlKNnNvGj0cXQqI',
      workerUuid: '79b0cb89-5fb1-4c5b-854a-279ee6ba89e4',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 134938,
      workerType: 'USER',
      firstName: 'Rob',
      lastName: 'Morgan',
      classificationName: 'Other',
      email: 'rob@rakenapp.com',
      phoneNumber: '(812) 000-1858',
      code: '16cb713c3de0f6c',
      workerUuid: 'efdc7289-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 150980,
      workerType: 'PERSON',
      firstName: 'Scott',
      lastName: 'Mescudi',
      classificationId: 801222,
      classificationName: 'Mechanic',
      code: '0c9055ea09f7eed',
      workerUuid: 'f0341139-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 50874,
      workerType: 'USER',
      firstName: 'Thiago',
      lastName: 'Nascimento',
      classificationName: '',
      email: 'thifava1@rakenapp.com',
      phoneNumber: '(951) 941-0762',
      code: '3ea2d6df3c986db',
      workerUuid: 'ef6b664e-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 150981,
      workerType: 'PERSON',
      firstName: 'Thoma',
      lastName: 'DeLonge',
      classificationId: 280678,
      classificationName: 'General Foreman',
      code: '6e1608d1d90c525',
      workerUuid: 'f0341219-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 243938,
      workerType: 'USER',
      firstName: 'Thomas',
      lastName: 'Brazier',
      classificationName: '',
      email: 'thomas@rakenapp.com',
      phoneNumber: '+17608078546',
      code: 'XBrZQikG6qmgCwi',
      workerUuid: '935dee3b-0950-47bf-be6c-3126baffbb63',
      isDefault: true,
      projects: []
    },
    {
      type: 'Worker',
      id: 50869,
      workerType: 'USER',
      firstName: 'Timothy',
      lastName: 'Smith',
      classificationName: 'Other',
      email: 'tim@rakenapp.com',
      phoneNumber: '+18582521145',
      code: 'f4e25c2741ea44d',
      workerUuid: 'ef6b603a-b274-11e8-b4f6-02ea884ec0de',
      isDefault: true,
      projects: []
    }
  ]
};

const projectWorkersData = {
  page: { totalElements: 42 },
  collection: [
    {
      type: 'Worker',
      id: 190497,
      workerType: 'PERSON',
      firstName: 'Adam',
      lastName: 'Herget',
      classificationId: 819780,
      classificationName: 'Estimator',
      code: '22a349de05879a1',
      workerUuid: 'f0e0dbc4-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 193311,
      workerType: 'PERSON',
      firstName: 'Betty',
      lastName: 'Johnson',
      classificationId: 348834,
      classificationName: 'Project Manager',
      email: '',
      phoneNumber: '',
      code: 'a3c93328a68ab33',
      workerUuid: 'f0eb8f21-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 170424,
      workerType: 'PERSON',
      firstName: 'Billy',
      lastName: 'Bob',
      classificationId: 280678,
      classificationName: 'General Foreman',
      code: '6c4d4d296cc7a8e',
      workerUuid: 'f0816524-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 177117,
      workerType: 'PERSON',
      firstName: 'Bull',
      lastName: 'Duram',
      classificationId: 801221,
      classificationName: 'Plumber',
      code: '15bae9fa92cfa79',
      workerUuid: 'f0af357c-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 177449,
      workerType: 'PERSON',
      firstName: 'Chris',
      lastName: 'Copley',
      classificationId: 801222,
      classificationName: 'Mechanic',
      email: '',
      phoneNumber: '',
      code: 'd550ffec8707f27',
      workerUuid: 'f0b071c2-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 185302,
      workerType: 'PERSON',
      firstName: 'Derrick',
      lastName: 'Haase',
      classificationId: 761662,
      classificationName: 'Senior Project Manager',
      code: '201c4d96ad1d55d',
      workerUuid: 'f0d06f33-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 173251,
      workerType: 'PERSON',
      firstName: 'Ed',
      lastName: 'Fox',
      classificationId: 744058,
      classificationName: 'Electrical Contracting',
      email: '',
      phoneNumber: '',
      code: 'bac69d980e5d5c0',
      workerUuid: 'f0a0ec4f-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 254736,
      workerType: 'PERSON',
      firstName: 'Equipment',
      lastName: 'Pieces',
      email: '',
      phoneNumber: '',
      code: 't5vMYxNbGmfl9Xy',
      workerUuid: '24ae8901-7dc1-4a68-8bd5-411c284668f3',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 194046,
      workerType: 'PERSON',
      firstName: 'Flatbed',
      lastName: 'Flatbed',
      email: '',
      phoneNumber: '',
      code: '47f2ec605d24481',
      workerUuid: 'f0eea1c0-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 209300,
      workerType: 'PERSON',
      firstName: 'Gregg',
      lastName: 'Fields',
      classificationId: 280678,
      classificationName: 'General Foreman',
      email: '',
      phoneNumber: '',
      code: 'Vt2m9iFPwbMoxV2',
      workerUuid: '497d1931-0d62-4ed3-aa72-e355bdb95828',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 190781,
      workerType: 'PERSON',
      firstName: 'Gus',
      lastName: 'Revollar',
      code: '219855b4821fdd0',
      workerUuid: 'f0e1d99d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 159964,
      workerType: 'PERSON',
      firstName: 'Hyrum',
      lastName: 'Pitt',
      classificationId: 282642,
      classificationName: 'PE',
      code: '5f9c5c0388f02dd',
      workerUuid: 'f0591a13-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 209255,
      workerType: 'PERSON',
      firstName: 'Jeff',
      lastName: 'Smith',
      classificationId: 780891,
      classificationName: 'Foreman - Hourly',
      email: '',
      phoneNumber: '',
      code: 'O4hR9zaT5B9yYIs',
      workerUuid: 'b72d7c55-407a-4f3e-90df-9a8f45432cae',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 176405,
      workerType: 'PERSON',
      firstName: 'Jerry',
      lastName: 'Smith',
      classificationId: 196608,
      classificationName: 'Laborer',
      code: '21c39dcd622f505',
      workerUuid: 'f0ac76f8-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 213007,
      workerType: 'PERSON',
      firstName: 'Jo',
      lastName: 'Doe',
      email: '',
      phoneNumber: '',
      code: 't73e3aRsFxJUEfa',
      workerUuid: '9dfe53db-40d8-41e9-b921-19f083b2c23e',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 209202,
      workerType: 'PERSON',
      firstName: 'Joe',
      lastName: 'Johnson',
      email: '',
      phoneNumber: '',
      code: 'qr06L3NTdjkme4j',
      workerUuid: '66aec5c0-93c0-4455-b822-73e15a0eb890',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 175164,
      workerType: 'PERSON',
      firstName: 'John',
      lastName: 'Amith',
      classificationId: 748363,
      classificationName: 'trucker',
      code: '49ccfa959657fca',
      workerUuid: 'f0a80fc3-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 197093,
      workerType: 'PERSON',
      firstName: 'Jon',
      lastName: 'Doe',
      email: '',
      phoneNumber: '',
      code: '396b1b281ca1111',
      workerUuid: 'f0fc11b2-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 259308,
      workerType: 'PERSON',
      firstName: 'Jorge',
      lastName: 'Perez',
      email: '',
      phoneNumber: '',
      code: 'NigZP2f095wssbi',
      workerUuid: '2b50d055-6f7d-42bd-9705-fbb942e55e63',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 188657,
      workerType: 'PERSON',
      firstName: 'Kevin',
      lastName: 'Lewis',
      email: '',
      phoneNumber: '',
      code: '0ade663735bc36f',
      workerUuid: 'f0da92a5-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 242532,
      workerType: 'PERSON',
      firstName: 'Merle',
      lastName: 'Louck',
      classificationId: 410227,
      classificationName: 'Superintendent',
      email: '',
      phoneNumber: '',
      code: 'RkUVm2ZPlIQOC1H',
      workerUuid: '542c3f38-69da-4963-9bbc-c59bf7d43265',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 188828,
      workerType: 'PERSON',
      firstName: 'Mike',
      lastName: 'Harowitz',
      employeeId: '11128272',
      email: '',
      phoneNumber: '',
      code: '1e6917279e6b176',
      workerUuid: 'f0db388d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 161634,
      workerType: 'PERSON',
      firstName: 'Mike',
      lastName: 'Harrington',
      classificationId: 280678,
      classificationName: 'General Foreman',
      code: 'bb81747d267b2b0',
      workerUuid: 'f05fbf3d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 178076,
      workerType: 'PERSON',
      firstName: 'Mike',
      lastName: 'Henderson',
      code: '09be75c14d8c2d7',
      workerUuid: 'f0b2c52d-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 174354,
      workerType: 'PERSON',
      firstName: 'Mike',
      lastName: 'Jones',
      classificationId: 744058,
      classificationName: 'Electrical Contracting',
      code: '9e91cabd8613d04',
      workerUuid: 'f0a5195b-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 220389,
      workerType: 'PERSON',
      firstName: 'Narseal',
      lastName: 'Batise',
      email: '',
      phoneNumber: '',
      code: 'sAyFPdIkc8h1So9',
      workerUuid: '0f6231a9-181e-4378-91e0-5342f78a58da',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 137449,
      workerType: 'PERSON',
      firstName: 'Nate',
      lastName: 'Wolverton',
      classificationId: 744058,
      classificationName: 'Electrical Contracting',
      email: '',
      phoneNumber: '',
      code: '62c949565ba8f06',
      workerUuid: 'efe67edc-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 191270,
      workerType: 'PERSON',
      firstName: 'Nick',
      lastName: 'Stuart',
      email: '',
      phoneNumber: '',
      code: 'dcc8c7318e5a860',
      workerUuid: 'f0e3a7ae-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 171731,
      workerType: 'PERSON',
      firstName: 'Phillip',
      lastName: 'Wroughton',
      email: '',
      phoneNumber: '',
      code: '5ca75853affe475',
      workerUuid: 'f091ce5e-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 214858,
      workerType: 'PERSON',
      firstName: 'Plumber',
      lastName: 'SK',
      classificationId: 801221,
      classificationName: 'Plumber',
      email: '',
      phoneNumber: '',
      code: '9iWalga8r0ZwOF1',
      workerUuid: '49e59249-ae3a-42be-93a5-99a7a0071add',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 240083,
      workerType: 'PERSON',
      firstName: 'Richard',
      lastName: 'Burnette',
      classificationId: 287441,
      classificationName: 'Executive',
      email: '',
      phoneNumber: '',
      code: 'xPaiM7FUGTwa1NF',
      workerUuid: 'ad5aed5b-4988-4871-ab06-04c1a1aab1ab',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 188789,
      workerType: 'PERSON',
      firstName: 'Rolf',
      lastName: 'Hohmann',
      classificationId: 280678,
      classificationName: 'General Foreman',
      email: '',
      phoneNumber: '',
      code: 'c293f07ffddacbe',
      workerUuid: 'f0db1859-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 188664,
      workerType: 'PERSON',
      firstName: 'Russell',
      lastName: 'Tillery',
      email: '',
      phoneNumber: '',
      code: '16be36a07647743',
      workerUuid: 'f0da9999-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 170282,
      workerType: 'PERSON',
      firstName: 'Ryan',
      lastName: 'Dobbins',
      code: '515a44dd0104924',
      workerUuid: 'f080dc33-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 196749,
      workerType: 'PERSON',
      firstName: 'Scot',
      lastName: 'M',
      email: '',
      phoneNumber: '',
      code: '4b671036a886364',
      workerUuid: 'f0fa9443-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 169326,
      workerType: 'PERSON',
      firstName: 'Steven',
      lastName: 'Hersh',
      classificationId: 280678,
      classificationName: 'General Foreman',
      email: '',
      phoneNumber: '',
      code: '760ffb003b0533e',
      workerUuid: 'f07cf7c1-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 173329,
      workerType: 'PERSON',
      firstName: 'Tim',
      lastName: 'Robinson',
      code: '39cfa1313a43e11',
      workerUuid: 'f0a14660-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 181147,
      workerType: 'PERSON',
      firstName: 'Trent',
      lastName: 'Bonnett',
      classificationId: 761662,
      classificationName: 'Senior Project Manager',
      email: '',
      phoneNumber: '',
      code: '0a142c0882d775c',
      workerUuid: 'f0bdd953-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 169102,
      workerType: 'PERSON',
      firstName: 'Troy',
      lastName: 'Tulowitzki',
      classificationId: 65538,
      classificationName: 'Journeyman',
      code: 'b833cc8ab27ebe3',
      workerUuid: 'f07c20aa-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 193307,
      workerType: 'PERSON',
      firstName: 'Truck',
      lastName: '#12455',
      classificationId: 784795,
      classificationName: 'Truck/Equipment',
      email: '',
      phoneNumber: '',
      code: '634ee9fb87a2031',
      workerUuid: 'f0eb8bb3-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 213461,
      workerType: 'PERSON',
      firstName: 'Urmimala',
      lastName: 'Das',
      classificationId: 832966,
      classificationName: 'Employee',
      email: '',
      phoneNumber: '',
      code: 'gfFI4O3kgFKuAZX',
      workerUuid: '655fc620-6e9b-4940-ac98-5edd40d1114c',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    },
    {
      type: 'Worker',
      id: 175095,
      workerType: 'PERSON',
      firstName: 'Valencia',
      lastName: 'R',
      classificationId: 65538,
      classificationName: 'Journeyman',
      code: '5c9d0fcb7ad8e99',
      workerUuid: 'f0a7d348-b274-11e8-b4f6-02ea884ec0de',
      isDefault: false,
      projects: [{ projectUuid: 'e0ad692c-aa32-11e9-a2a3-2a2ae2dbcce4' }]
    }
  ]
};

export {
  usersData,
  companiesData,
  projectsData,
  questionCategoriesData,
  allTimeStatsByProjectData,
  complianceByProjectData,
  topUsersData,
  attachmentActivityData,
  surveyActivityData,
  missedDailiesData,
  tasksData,
  standardWorkLogsData,
  timeCardLogsData,
  companyMembersData,
  projectMembersData,
  companyPermissionsData,
  toolboxTalksData,
  superAdminCompaniesData,
  costCodesData,
  integrationsData,
  checklistTemplatesData,
  checklistProjectData,
  companyWorkersData,
  projectWorkersData
};

import orderBy from 'lodash.orderby';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import request from 'axios';
import debounce from 'lodash.debounce';
import { observable, action, computed, runInAction, reaction } from 'mobx';
import { t } from 'utils/translate';
import errorHandler from 'utils/errorHandler';
import axiosDownload from 'utils/axiosDownload';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';

import {
  CostCodeForm,
  costCodeFormOptions,
  costCodeFormFields,
  costCodeFormRules,
  costCodeFormLabels,
  costCodeFormPlugins
} from 'forms/costCode';

export default class CompanyCostCodesUI extends UIStore {
  @observable uploadProgress;
  @observable mode;
  @observable costCodeForm;
  @observable searchQuery;
  @observable entryForEdit;
  @observable pageSize;
  @observable page;
  @observable sortDirection;

  constructor(options) {
    super(options);

    this.companyProductionUI = options.companyProductionUI;

    this.selectedCostCodes = observable([]);
    this.uploadProgress = 0;

    // Divisions
    this.divisions = observable([]);
    this.fetchDivisions = debounce(this.fetchDivisions, 250);

    // Editing
    this.entryForEdit = null;

    // Search
    this.searchQuery = '';

    // Sorting
    this.sortDirection = 'asc';

    // Pagination
    this.pageSize = 20;
    this.page = 0;

    this.costCodeForm = new CostCodeForm(
      {
        fields: costCodeFormFields,
        rules: costCodeFormRules,
        labels: costCodeFormLabels
      },
      {
        options: costCodeFormOptions,
        plugins: costCodeFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.fetchCostCodesDebounced = debounce(this.fetchCostCodes, BASE_DEBOUNCE);
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchCostCodesDebounced();
      }
    );

    this.reactToPage = reaction(
      () => this.page,
      page => {
        if (listRef) {
          window.scrollTo({
            top: listRef.offsetTop,
            behavior: 'smooth'
          });
        }
      }
    );
  }

  tearDownReactions() {
    this.reactToParams();
    this.reactToPage();
  }

  @action.bound
  setMode(mode) {
    this.mode = mode;
  }

  @action.bound
  setDefaultMode() {
    this.mode = this.hasCostCodes || this.disableFields ? 'manual' : 'upload';
  }

  @action.bound
  async fetchCostCodes(setDefaultMode) {
    if (setDefaultMode) {
      this.costCodes.reset();
    }

    await this.costCodes.fetch({
      params: pickBy(this.params, identity)
    });

    if (setDefaultMode) {
      this.setDefaultMode();
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 0;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 0;
  }

  @action.bound
  setPage(page) {
    this.page = page.selected;
    this.selectedCostCodes.clear();
  }

  @action.bound clearPage() {
    this.page = 0;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: this.page * this.pageSize,
      query: this.searchQuery,
      isDefault: true,
      sortField: 'division,code',
      sortDirection: this.sortDirection.toUpperCase()
    };
  }

  @computed get sortedCostCodes() {
    return orderBy(this.costCodes.models, 'new', 'desc');
  }

  @computed
  get totalPages() {
    return Math.ceil(this.costCodes.totalElements / this.pageSize);
  }

  @action.bound
  sortByColumn() {
    // Clear New Item at the top of list on sort.
    this.costCodes.clearNew();

    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @computed
  get costCodeFormIsInValid() {
    if (
      !this.costCodeForm ||
      (this.costCodeForm.existingCostCode &&
        this.costCodeForm.existingCostCode.isDefault)
    )
      return true;

    return Boolean(
      this.costCodeForm.check('hasError') ||
        this.costCodeForm.$('division').check('isPristine') ||
        this.costCodeForm.$('code').check('isPristine')
    );
  }

  @action.bound async saveNewCostCode(values) {
    let costCode;

    if (this.costCodeForm.existingCostCode) {
      costCode = this.costCodeForm.existingCostCode;

      this.costCodes.add(costCode, {
        unshift: true
      });

      try {
        await costCode.save({
          isDefault: true,
          description: values.description
        });
      } catch (error) {
        errorHandler(error, this.notificationsUI.pushError);
      }
    } else {
      try {
        costCode = await this.costCodes.create(
          Object.assign(values, {
            isDefault: true
          }),
          {
            unshift: true
          }
        );
      } catch (error) {
        errorHandler(error, this.notificationsUI.pushError);
      }
    }

    costCode.setAsNew();

    this.rootStore.notificationsUI.pushNotification({
      showUndo: false,
      title: t('Cost Code Created')
    });
  }

  @computed get hasSelectedCostCodes() {
    return this.selectedCostCodes.length > 0;
  }

  @action.bound
  toggleSelectCostCode(costCode) {
    if (this.selectedCostCodes.find(uuid => uuid === costCode.uuid)) {
      this.selectedCostCodes.remove(costCode.uuid);
    } else {
      this.selectedCostCodes.push(costCode.uuid);
    }
  }

  @computed
  get allCostCodesSelected() {
    return (
      this.hasCostCodes &&
      this.selectedCostCodes.length === this.costCodes.length
    );
  }

  @action.bound
  toggleSelectAllCostCodes() {
    if (this.allCostCodesSelected) {
      this.selectedCostCodes.clear();
    } else {
      this.selectedCostCodes.replace(
        this.costCodes.models.map(costCode => costCode.uuid)
      );
    }
  }

  @action.bound async softDeleteCostCode(costCode) {
    const originalIndex = costCode.collection.models.indexOf(costCode);

    this.cancelEntryEdit();

    costCode.collection.remove(costCode);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteCostCode(costCode, originalIndex);
      },
      onDismiss: () => {
        this.confirmDeleteCostCode(costCode);
      },
      title: t('Cost Code Deleted')
    });
  }

  @action.bound
  cancelDeleteCostCode(costCode, index) {
    this.rootStore.costCodes.add(costCode, {
      at: index
    });
  }

  @action.bound
  async confirmDeleteCostCode(costCode) {
    try {
      await costCode.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notificationsUI.pushError);
    }

    if (!this.hasCostCodes) {
      this.setPage({
        selected: 0
      });
    } else if (this.totalPages > 1) {
      this.fetchCostCodes();
    }
  }

  @action.bound
  deleteSelectedCostCodes() {
    this.cancelEntryEdit();

    const costCodes = this.costCodes.models.filter(costCode =>
      this.selectedCostCodes.includes(costCode.uuid)
    );

    this.softDeleteCostCodes(costCodes);
    this.selectedCostCodes.clear();
  }

  @action.bound async softDeleteCostCodes(costCodes) {
    this.costCodes.remove(costCodes);

    this.rootStore.notificationsUI.pushNotification({
      onUndo: () => {
        this.cancelDeleteCostCodes(costCodes);
      },
      onDismiss: () => {
        this.confirmDeleteCostCodes(costCodes);
      },
      title: t('Cost Codes Deleted')
    });
  }

  @action.bound
  cancelDeleteCostCodes(costCodes) {
    this.costCodes.add(costCodes);
  }

  @action.bound
  async confirmDeleteCostCodes(costCodes) {
    const costCodeUuids = costCodes.map(costCode => costCode.uuid);

    try {
      await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/delete`,
        costCodeUuids
      );

      if (!this.hasCostCodes) {
        this.setPage({
          selected: 0
        });

        this.fetchCostCodes();
      } else if (this.totalPages > 1) {
        this.fetchCostCodes();
      }
    } catch (error) {
      errorHandler(error, this.notificationsUI.pushError);
    }
  }

  // Divisions
  @action.bound
  fetchDivisions(query = '') {
    this.divisions.clear();

    if (query.length < 2) {
      return;
    }

    request
      .get(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/costcodes/divisions`,
        {
          params: {
            query: query
          }
        }
      )
      .then(response => {
        if (response.data.collection) {
          this.divisions.replace(response.data.collection);
        }
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  upload(files) {
    const formData = new FormData();
    formData.append('file', files[0]);

    const config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        this.setUploadProgress(percentCompleted);
      },
      transformRequest: [
        function(data, headers) {
          delete headers.post['content-type'];
          return data;
        }
      ]
    };

    return new Promise((resolve, reject) => {
      request
        .post(
          `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/costcodes/import?limit=20&offset=0`,
          formData,
          config
        )
        .then(
          response => {
            runInAction(() => {
              this.costCodes.set(response.data);
              this.clearUploadProgress();
              this.setMode('manual');

              this.notifications.pushNotification({
                showUndo: false,
                title: t('Cost Codes successfully updated.')
              });
            });

            resolve(response.data);
          },
          error => {
            this.clearUploadProgress();
            errorHandler(error, this.notifications.pushError);
          }
        );
    });
  }

  @action.bound
  setUploadProgress(progress) {
    this.uploadProgress = progress;
  }

  @action.bound
  clearUploadProgress() {
    this.uploadProgress = 0;
  }

  @action.bound
  uploadRejected() {
    this.notifications.pushNotification({
      showUndo: false,
      title: 'Please upload a valid .CSV file.'
    });
  }

  @action.bound async setEntryForEdit(costCode) {
    this.entryForEdit = costCode;

    this.entryEditForm = new CostCodeForm(
      {
        fields: costCodeFormFields,
        rules: costCodeFormRules,
        labels: costCodeFormLabels,
        values: costCode.formValues,
        disabled: {
          code: true
        }
      },
      {
        options: costCodeFormOptions,
        plugins: costCodeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelEntryEdit() {
    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  checkIfEntryDisabled(entry) {
    return this.entryForEdit && this.entryForEdit.uuid !== entry.uuid;
  }

  @action.bound async submitEntryEditForm() {
    if (
      this.entryEditForm.existingCostCode &&
      this.entryEditForm.existingCostCode.uuid !== this.entryForEdit.uuid
    )
      return;

    this.divisions.clear();

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound submitEntryEditFormSuccess() {
    const { division, description } = this.entryEditForm.trimmedValues();

    this.entryForEdit.save({
      division,
      description
    });

    this.cancelEntryEdit();
  }

  @action.bound submitEntryEditFormError() {
    console.log(this.entryEditForm.errors());
  }

  @action.bound
  exportCompanyCostCodesCSV() {
    axiosDownload(
      `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/costcodes/export`,
      `${t('CostCodes')}.csv`
    );
  }

  @action.bound clearUIState() {
    this.entryForEdit = null;
    this.searchQuery = '';
    this.selectedCostCodes.clear();
    this.divisions.clear();
    this.page = 0;
  }

  @computed get disableFields() {
    return (
      (this.company.disableLegacySettings &&
        !this.company.hideLegacySettings) ||
      !this.authorization.canCUDCostCodes
    );
  }
}

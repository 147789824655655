import { action, computed } from 'mobx';
import ChecklistTemplateStartUI from './company/checklists/ChecklistTemplateStartUI.js';
import ChecklistType from 'stores/models/checklists/ChecklistType.js';

import history from 'utils/history';

export default class TimeClockQuestionsChecklistTemplateStartUI extends ChecklistTemplateStartUI {
  constructor(options) {
    super(options);

    this.timeClockQuestionsLayout = true;
  }

  @action.bound async setup() {
    this.setupForm();

    this.rakenChecklistTemplates.fetch({
      url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
        this.rootStore.me.company.uuid
      }/checklist-templates/checklist-types/${
        this.rootStore.appConfig.timeClockChecklistType
      }`
    });

    const type = new ChecklistType(
      {
        uuid: this.rootStore.appConfig.timeClockChecklistType
      },
      { parent: this, rootStore: this.rootStore }
    );

    await type.fetch();

    this.checklistTypeSelectorUI.checklistTypes.add(type);

    this.entryStartForm.$('checklistType').set({
      uuid: type.uuid,
      typeName: type.typeName,
      typeClass: type.typeClass
    });
  }

  @action.bound
  cancelStartChecklistTemplate() {
    history.push({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates`,
      search: this.baseQueryParams
    });
  }

  @action.bound async addChecklistTemplate() {
    history.push({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates/add`,
      search: this.baseQueryParams
    });
  }

  @computed
  get timesheetsPath() {
    return '/review';
  }
}

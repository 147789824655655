import {
  TIMECARD_ADDED,
  TIMECARD_EDITED,
  TIMECARD_VIEWED
} from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';
import { MEDIA_TYPES } from 'stores/models/Attachment';

export default (event, workLog, source, createAnotherTimeCard = false) => {
  const isDataRequired =
    event === TIMECARD_VIEWED ||
    event === TIMECARD_EDITED ||
    event === TIMECARD_ADDED;

  if (isDataRequired) {
    const { type, costCodeCount, attachments, workDesc } = workLog;

    const hasDescription = Boolean(workDesc);
    const countPhotos = attachments.models.filter(
      attachment => attachment.mediaType === MEDIA_TYPES.IMAGE
    ).length;
    const countVideos = attachments.models.filter(
      attachment => attachment.mediaType === MEDIA_TYPES.VIDEO
    ).length;
    const countFiles = attachments.models.filter(
      attachment => attachment.mediaType === MEDIA_TYPES.DOCUMENT
    ).length;

    callTrack(event, {
      timecard_type: type,
      cost_code_selected: Boolean(costCodeCount),
      description_entered: hasDescription,
      photos_attached: countPhotos,
      videos_attached: countVideos,
      files_attached: countFiles,
      source: source,
      create_another_time_card: createAnotherTimeCard
    });
  } else {
    callTrack(event);
  }
};

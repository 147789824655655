import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class SmallEquipmentDeployment extends Model {
  get restAttributes() {
    return [
      'equipmentUuid',
      'equipmentName',
      'projectName',
      'projectUuid',
      'availableUnits',
      'projectDeployedUnits'
    ];
  }

  @computed get formValues() {
    return {
      equipmentUuid: this.equipmentUuid,
      equipmentName: this.equipmentName,
      projectName: this.projectName,
      projectUuid: this.projectUuid,
      projectDeployedUnits: this.projectDeployedUnits
    };
  }
}

import IncidentReportSubmission from 'stores/models/incidents/IncidentReportSubmission';
import { RakenCollection } from '../../lib';

export default class IncidentReportSubmissions extends RakenCollection {
  model() {
    return IncidentReportSubmission;
  }

  url() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/incident-reports/submission/logs`;
  }
}

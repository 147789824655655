import moment from 'moment';
import { action, computed, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import MyTimeTimeCardEditUI from './MyTimeTimeCardEditUI';

import { t } from 'utils/translate';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { TIMECARD_DELETED } from 'utils/segmentAnalytics/eventSpec';
import sendTimeCardEvent from 'utils/segmentAnalytics/sendTimeCardEvent';

export default class MyTimeTimeCardsUI extends UIStore {
  @observable selectedTimeCard;

  constructor(options) {
    super(options);

    this.selectedTimeCard = null;

    this.myTimeTimeCardEditUI = new MyTimeTimeCardEditUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get title() {
    return t('Entries');
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.selectedTimeCard = null;
  }

  @computed get myTimeTimeCardAddUI() {
    return this.parent.myTimeTimeCardAddUI;
  }

  @computed get myTimeTimeCardAddPoliciesUI() {
    return this.parent.myTimeTimeCardAddPoliciesUI;
  }

  @computed get loading() {
    return this.parent.loading;
  }

  @computed get timeCards() {
    return this.parent.timeCards;
  }

  @computed get hasTimeCards() {
    return this.parent.hasTimeCards;
  }

  @computed get showEmptyState() {
    return this.parent.showEmptyState;
  }

  @computed get showUI() {
    return this.parent.showUI;
  }

  @computed get selectedPayPeriodDates() {
    return this.parent.selectedPayPeriodDates;
  }

  getActions = timeCard => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editTimeCard(timeCard);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteTimeCard(timeCard);
        }
      }
    ];
  };

  @action.bound async deleteTimeCard(timeCard) {
    await this.authorization.checkFeatureAccess('EditMyTime');

    this.selectedTimeCard = timeCard;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTimeCard() {
    await this.hideActiveModal();
    this.selectedTimeCard = null;
  }

  @action.bound async confirmDeleteTimeCard() {
    this.saving = true;

    try {
      await this.selectedTimeCard.destroy({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/timecards/${
          this.selectedTimeCard.uuid
        }`,
        wait: true
      });

      this.parent.refetchTimeCards();

      await this.hideActiveModal();

      sendTimeCardEvent(TIMECARD_DELETED, this.selectedTimeCard, 'my_time');

      this.selectedTimeCard = null;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Time card deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  async editTimeCard(timeCard) {
    await this.authorization.checkFeatureAccess('EditMyTime');

    history.push(`/my-time/entries/${timeCard.id}`);
  }

  @action.bound async addTimeCard(event, date = moment().format('YYYY-MM-DD')) {
    event.stopPropagation();

    await this.authorization.checkFeatureAccess('EditMyTime');

    history.push({
      pathname: `/my-time/entries/add`,
      search: `?date=${date}`
    });
  }

  @action.bound refetchAfterAdd() {
    this.parent.refetchPayPeriods();
  }

  @action.bound cancelTimeCardAdd() {
    history.push({
      pathname: `/my-time/entries`
    });
  }
}

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import ComputerEaseDirectAPIJobs from 'stores/collections/integrations/ComputerEaseDirectAPIJobs';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import { t } from 'utils/translate';
import IntegrationIds from 'fixtures/integrationIds';

export default class IntegrationComputerEaseDirectAPIJobImportUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.computerEaseDirectAPIJobs = new ComputerEaseDirectAPIJobs(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.selectedJobs = observable([]);

    this.searchQuery = '';

    this.fetchJobsDebounced = debounce(this.fetchJobs, BASE_DEBOUNCE);
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get jobs() {
    return this.computerEaseDirectAPIJobs;
  }

  @computed get hasJobs() {
    return this.jobs.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchJobs();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchJobs();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchJobsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchJobs() {
    this.loading = true;

    await this.jobs.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get params() {
    return {
      limit: 50,
      unlinkedOnly: true
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.jobs.totalElements / this.pageSize);
  }

  @computed get hasSelectedJobs() {
    return this.selectedJobs.length > 0;
  }

  @action.bound
  toggleSelectJob(jobId) {
    if (this.selectedJobs.find(id => id === jobId)) {
      this.selectedJobs.remove(jobId);
    } else {
      this.selectedJobs.push(jobId);
    }
  }

  @computed
  get allJobsSelected() {
    return (
      this.hasJobs &&
      this.jobs.models.every(job => this.selectedJobs.includes(job.id))
    );
  }

  @action.bound
  toggleSelectAllJobs() {
    if (this.allJobsSelected) {
      this.selectedJobs.replace(
        this.selectedJobs.filter(id => {
          return !this.jobs.models.map(job => job.id).includes(id);
        })
      );
    } else {
      this.jobs.models.forEach(job => {
        if (!this.selectedJobs.includes(job.id)) {
          this.selectedJobs.push(job.id);
        }
      });
    }
  }

  @computed get someJobsSelected() {
    return this.hasSelectedJobs && !this.allJobsSelected;
  }

  @action.bound clearAllSelectedJobs() {
    this.selectedJobs.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound async confirmImportComputerEaseDirectAPIJobs(
    options = { all: false }
  ) {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    try {
      const { data } = await request.post(`${this.jobs.url()}/sync`, {
        externalIds: options.all ? ['*'] : this.selectedJobs.slice()
      });

      if (options.all) {
        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Job import started')
        });
      } else {
        const successfulCount = data.collection.filter(
          syncResult => syncResult.successful === true
        ).length;

        const failedCount = data.collection.length - successfulCount;

        const title = formatIntegrationSyncMessage(
          'Job import',
          successfulCount,
          failedCount
        );

        const snackbar = successfulCount > 0 ? 'warning' : 'error';

        this.notifications.pushNotification({
          snackbar,
          icon: 'checkmark',
          title
        });
      }

      this.parent.showImportAlert = true;

      this.cancelImportComputerEaseDirectAPIJobs();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelImportComputerEaseDirectAPIJobs() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/jobs`
      );
    } else {
      history.push(
        `/company/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/jobs`
      );
    }
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.searchQuery = '';
    this.selectedJobs.clear();
    this.jobs.clear();
    this.clearValidationDetails();
  }
}

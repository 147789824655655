import omit from 'lodash.omit';
import { action, computed } from 'mobx';
import AnswersActivity from '../collections/AnswersActivity';
import ReportEntry from './ReportEntry';

import { t } from 'utils/translate';

export default class SurveyActivity extends ReportEntry {
  get restAttributes() {
    return [
      'id',
      'uuid',
      'type',
      'report',
      'reportId',
      'reportDate',
      'createdTimestamp',
      'updatedTimestamp',
      'segmentUuid',
      'teamId',
      'activityViewUrl',
      'activitySegmentName'
    ];
  }

  get restAttributeDefaults() {
    return {
      activityViewUrl: '/'
    };
  }

  @action.bound parse(attributes) {
    super.parse(attributes);
    this.parseAnswers(attributes);

    return {
      ...omit(attributes, [
        'attachments',
        'createdBy',
        'updatedBy',
        'createdByRef',
        'updatedByRef',
        'company',
        'project',
        'answers'
      ])
    };
  }

  @action.bound parseAnswers(attributes) {
    this.answers = new AnswersActivity(attributes.answers, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed
  get title() {
    return t('Survey');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }
}

import request from 'axios';
import { computed, action, observable, runInAction } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';
import moment from 'moment';

import CompanySubscriptionBillingUI from 'stores/ui/CompanySubscriptionBillingUI';

import {
  SubscriptionEditForm,
  subscriptionEditFormOptions,
  subscriptionEditFormFields,
  subscriptionEditFormRules,
  subscriptionEditFormLabels,
  subscriptionEditFormPlugins
} from 'forms/superAdmin/subscriptionEdit';

import {
  SubscriptionForm,
  subscriptionFormOptions,
  subscriptionFormFields,
  subscriptionFormRules,
  subscriptionFormValues,
  subscriptionFormLabels,
  subscriptionFormPlugins
} from 'forms/subscription';

import {
  SubscriptionEditTrialForm,
  subscriptionEditTrialFormOptions,
  subscriptionEditTrialFormFields,
  subscriptionEditTrialFormRules,
  subscriptionEditTrialFormLabels,
  subscriptionEditTrialFormPlugins
} from 'forms/superAdmin/subscriptionEditTrial';

import { t } from 'utils/translate';

export default class SuperAdminCompanySubscriptionBillingUI extends CompanySubscriptionBillingUI {
  @observable showEditSubscriptionForm;
  @observable paying;
  @observable refundProcessing;
  @observable sortDirection;
  @observable sortField;
  @observable subscriptionStartDate;
  @observable subscriptionForm;

  constructor(options) {
    super(options);

    this.showEditSubscriptionForm = false;
    this.paying = false;
    this.refundProcessing = false;
    this.cardToDelete = null;

    this.sortDirection = 'asc';
    this.sortField = 'paymentDateFormatted';

    this.subscriptionStartDate = '';
    // Forms
    this.subscriptionForm = null;
  }

  @computed
  get company() {
    return this.parent.activeCompany;
  }

  @computed
  get subscription() {
    return (this.company && this.company.subscription) || {};
  }

  @computed get subscriptionStates() {
    return [
      { id: 'ACTIVE', name: 'Active' },
      { id: 'CANCELLED', name: 'Cancelled' },
      { id: 'TRIAL', name: 'Trial' },
      { id: 'PAST_DUE', name: 'Past Due' },
      { id: 'ON_HOLD', name: 'On Hold' }
    ];
  }

  @computed get billingPlatforms() {
    return [{ id: 'CHECK', name: 'Check' }];
  }

  @computed get scheduledPlanOptions() {
    return [
      { id: '', name: 'Keep Current Plan' },
      { id: 'performance', name: 'Upgrade to Performance' }
    ];
  }

  @computed get plans() {
    return [
      {
        type: 'BillingPlan',
        id: 1001,
        name: 'Basic',
        planKey: 'basic'
      },
      {
        type: 'BillingPlan',
        id: 1002,
        name: 'Professional',
        planKey: 'pro'
      },
      {
        type: 'BillingPlan',
        id: 1003,
        name: 'Performance',
        planKey: 'performance'
      },
      {
        type: 'BillingPlan',
        id: 1000,
        name: 'Starter',
        planKey: 'starter'
      }
    ];
  }

  @computed get planOptions() {
    return this.plans.map(plan => {
      return { id: plan.planKey, name: plan.name };
    });
  }

  @computed get paymentTypeOptions() {
    return [{ id: 'CHECK', name: 'Check' }];
  }

  @action.bound
  sortByColumn(fieldName) {
    if (this.sortField === fieldName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortField = fieldName;
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  openCancelAccountModal() {
    this.showModal('superAdminCancelAccount');
  }

  @action.bound
  cancelSubscription() {
    this.hideActiveModal();
    const payload = Object.assign({}, this.subscription.asFormValues, {
      subscriptionState: 'CANCELLED'
    });
    this.subscription
      .save(payload, {
        wait: true,
        reset: true,
        method: 'put'
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      });
  }

  @action.bound
  editSubscription() {
    this.showEditSubscriptionForm = true;
  }

  @action.bound
  instantiateSubscriptionEditForm() {
    this.subscriptionForm = new SubscriptionEditForm(
      {
        fields: subscriptionEditFormFields,
        rules: subscriptionEditFormRules,
        labels: subscriptionEditFormLabels,
        values: this.subscription.subscriptionEditFormValues
      },
      {
        options: subscriptionEditFormOptions,
        plugins: subscriptionEditFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  updateBillingPeriodType(periodType) {
    this.subscriptionForm.update({
      periodType: periodType
    });
  }

  @action.bound
  updateAutoRenew(bool) {
    this.subscriptionForm.update({
      autoRenew: bool
    });
  }

  @action.bound
  clearSubscriptionEditForm() {
    this.subscriptionForm.reset();
    this.showEditSubscriptionForm = false;
  }

  @action.bound
  submitSubscriptionEditForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      this.subscription.onAnnual &&
      this.subscriptionForm.$('periodType').value === 'MONTHLY'
    ) {
      this.showModal('superAdminAnnualToMonthlyModal');
      return;
    }

    this.subscriptionForm.submit({
      onSuccess: this.submitSubscriptionEditFormSuccess,
      onError: this.submitSubscriptionEditFormError
    });
  }

  @action.bound
  approveAnnualToMonthly() {
    this.hideActiveModal();
    this.submitSubscriptionEditFormSuccess();
  }

  @action.bound
  submitSubscriptionEditFormSuccess() {
    let payload = Object.assign(
      {
        forceDowngrade: true,
        upcomingPeriodType: null
      },
      this.subscription.asFormValues,
      this.subscriptionForm.values(),
      {
        salesRepresentative: {
          id: 1,
          name: 'House'
        }
      },
      {
        startDate: moment(this.subscriptionForm.$('startDate').value).format(
          'YYYY-MM-DD'
        )
      },
      {
        endDate: moment(this.subscriptionForm.$('endDate').value).format(
          'YYYY-MM-DD'
        )
      },
      {
        promoEndDate: this.subscriptionForm.$('isPromo').value
          ? moment(this.subscriptionForm.$('promoEndDate').value).format(
              'YYYY-MM-DD'
            )
          : null
      },
      {
        trialEndDate: moment(
          this.subscriptionForm.$('trialEndDate').value
        ).format('YYYY-MM-DD')
      }
    );

    // don't send scheduledPlan if no promoEndDate since backend has:
    // constraint [null] ConstraintViolationException: could not execute statement
    if (
      Boolean(this.subscriptionForm.$('isPromo').value) &&
      Boolean(this.subscriptionForm.$('scheduledBillingPlanKey').value)
    ) {
      payload.scheduledPlan = {
        upcomingBillingPlanKey: this.subscriptionForm.$(
          'scheduledBillingPlanKey'
        ).value,
        transitionDate: moment(
          this.subscriptionForm.$('promoEndDate').value
        ).format('YYYY-MM-DD')
      };
    } else {
      delete payload.scheduledPlan;
    }

    this.clearValidationDetails();
    this.saving = true;

    this.subscription
      .save(payload, {
        wait: true,
        reset: true,
        method: 'put'
      })
      .then(() => {
        runInAction(async () => {
          this.saving = false;

          this.notifications.pushNotification({
            snackbar: 'warning',
            icon: 'checkmark',
            title: t('Subscription updated')
          });
        });
      })
      .catch(error => {
        runInAction(() => {
          this.saving = false;
          alertErrorHandler(error, this.setValidationDetails);
        });
      });
  }

  @action.bound
  submitSubscriptionEditFormError() {
    console.log(this.subscriptionForm.errors());
  }

  /**
   * Trial
   */
  @action.bound
  openEditTrialModal() {
    this.showModal('superAdminEditTrialModal');

    this.editTrialForm = new SubscriptionEditTrialForm(
      {
        fields: subscriptionEditTrialFormFields,
        rules: subscriptionEditTrialFormRules,
        labels: subscriptionEditTrialFormLabels,
        values: {
          trialEndDate: this.subscriptionForm.$('trialEndDate').value
        }
      },
      {
        options: subscriptionEditTrialFormOptions,
        plugins: subscriptionEditTrialFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound submitEditTrialForm(e) {
    e.preventDefault();
    this.editTrialForm.submit({
      onSuccess: this.submitEditTrialFormSuccess,
      onError: this.submitEditTrialFormError
    });
  }

  @action.bound submitEditTrialFormSuccess() {
    const { trialEndDate } = this.editTrialForm.values();

    this.hideActiveModal();

    this.subscriptionForm.$('trialEndDate').set(trialEndDate);
  }

  @action.bound submitEditTrialForError() {
    console.log(this.editTrialForm.errors());
    this.cancelEditTrialForm();
  }

  @action.bound
  resetEditTrialEndDate() {
    this.subscriptionForm
      .$('trialEndDate')
      .set(this.subscription.asFormValues.trialEndDate);
  }

  @action.bound
  cancelEditTrialForm() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.resetEditTrialEndDate();
      });
    });
  }

  @computed
  get formattedSubscriptionState() {
    return this.subscription.subscriptionState.replace('_', ' ');
  }

  @computed
  get extendedPermissions() {
    return this.parent.extendedPermissions;
  }

  @action.bound
  openSubscriptionDateModal() {
    const date = this.company?.subscription?.startDate || '';
    this.subscriptionStartDate = moment(date).format('YYYY-MM-DD');
    this.showModal('editSubscriptionDate');
  }

  @action.bound
  handleSaveSubscriptionDateModal() {
    return new Promise(async resolve => {
      try {
        this.saving = true;

        await request.post(
          `/ra/sadmin/companies/${this.company.id}/billing/update-subscription-start-date`,
          { subscriptionStartDate: this.subscriptionStartDate }
        );

        // Refetch company so that we can display the new subscription date
        await this.parent.fetchCompany(this.company.id);

        resolve(true);
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
        resolve(false);
      } finally {
        this.saving = false;
        this.hideActiveModal();
      }
    });
  }

  @action.bound
  addSeatsClick() {
    const { subscription } = this;

    this.showModal('addSeats');

    this.subscriptionForm = new SubscriptionForm(
      {
        fields: subscriptionFormFields,
        rules: subscriptionFormRules,
        labels: subscriptionFormLabels,
        values: Object.assign(
          {},
          subscriptionFormValues,
          subscription.asFormValues,
          {
            seats: 0
          }
        )
      },
      {
        options: subscriptionFormOptions,
        plugins: subscriptionFormPlugins
      }
    );
  }

  @action.bound
  addSeatsSubmit() {
    this.subscriptionForm.submit({
      onSuccess: this.addSeatsSubmitSuccess,
      onError: this.subscriptionSubmitError
    });
  }

  @action.bound
  addSeatsSubmitSuccess() {
    const values = this.subscriptionForm.values();

    const { subscription } = this;

    const payload = {
      ...subscription.asFormValues,
      seats: parseInt(subscription.seats, 10) + parseInt(values.seats, 10),
      periodType: subscription.upcomingPeriodType || subscription.periodType,
      billingPlanKey: subscription.billingPlanKey,
      billingPlanName: subscription.billingPlanName
    };

    subscription
      .save(payload, {
        wait: true,
        method: 'put'
      })
      .then(model => {
        runInAction(() => {
          this.hideActiveModal();

          this.notifications.pushNotification({
            snackbar: 'warning',
            icon: 'checkmark',
            title: t('Seats added')
          });
        });
      })

      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      });
  }

  @computed get showCompanyAddOns() {
    return this.subscriptionForm.$('billingPlanKey').value === 'performance';
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

const observationTypesFiltersFormPlugins = extendedPlugins;

const observationTypesFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const observationTypesFiltersFormFields = [
  'typeClassFilters[]',
  'typeFilters[]'
];

const observationTypesFiltersFormRules = {
  typeFilters: 'array',
  typeClassFilters: 'array'
};

class ObservationTypesFiltersForm extends MobxReactForm {}

export {
  ObservationTypesFiltersForm,
  observationTypesFiltersFormRules,
  observationTypesFiltersFormFields,
  observationTypesFiltersFormOptions,
  observationTypesFiltersFormPlugins
};

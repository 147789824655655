import UIStore from './UIStore';
import { action, computed, observable, runInAction, reaction } from 'mobx';
import history, { historyState } from 'utils/history';
import { t } from 'utils/translate';
import { callTrack } from 'utils/segmentIntegration';
import {
  UPGRADE_PLAN_REQUESTED,
  UPGRADE_PLAN_VIEWED
} from 'utils/segmentAnalytics/eventSpec';

export default class AuthorizationUI extends UIStore {
  @observable pageName;
  @observable featureName;

  // Used to manually show modal to manage plan.
  @observable featureUnavailable;

  constructor(options) {
    super(options);

    this.pageName = null;
    this.featureName = null;

    this.featureUnavailable = false;

    this.cancelReactToDenyAccessToPage = reaction(
      () => this.denyAccessToPage,
      denyAccessToPage => {
        if (denyAccessToPage) {
          callTrack(UPGRADE_PLAN_VIEWED, {
            feature_name: this.pageName
          });
        }
      }
    );
  }

  @computed
  get project() {
    return this.projectUI?.project;
  }

  @action.bound setFeatureUnavailable(value) {
    this.featureUnavailable = value;
  }

  @action.bound
  setPageName(pageName) {
    this.setFeatureName(`View${pageName}`);
    this.pageName = pageName;
  }

  @computed
  get denyAccessToPage() {
    return Boolean(
      !this.me.hasRequiredModals &&
        this.pageName &&
        !this[`canView${this.pageName}`]
    );
  }

  @computed
  get basicRequiredMessage() {
    return t(
      'This feature is only available to paying members. Please upgrade your plan to unlock this feature.'
    );
  }

  @computed
  get professionalRequiredMessage() {
    return t(
      'This feature is only available to Professional and Performance plan members. Please upgrade your plan to unlock this feature.'
    );
  }

  @computed
  get performanceRequiredMessage() {
    return t(
      'This feature is only available to Performance Plan members. Please upgrade your plan to unlock this feature.'
    );
  }

  @computed
  get planUpgradeRequired() {
    switch (this.featureName) {
      case 'DownloadPdf':
      case 'InviteCollaborators':
      case 'ViewProjectSummaryReports':
      case 'ApplyProjectTemplate':
      case 'EditDailyReportRecipients':
      case 'DownloadManpowerReport':
      case 'DownloadMediaGallery':
      case 'BulkDownload':
      case 'CreateProjectTemplates':
      case 'ViewToolboxTalks':
      case 'EnableSegments':
      case 'ViewSegmentReports':
      case 'CUDChecklists':
      case 'CreateObservations':
      case 'CreateIncidents':
      case 'CUDForms':
      case 'SelectCoverPhotos':
      case 'FavoriteMedia':
      case 'ScheduleSmallEquipmentReports':
      case 'ViewChecklistReports':
      case 'ViewObservationReports':
      case 'ViewProductionReports':
        if (!this.onPerformancePlan) {
          return this.performanceRequiredMessage;
        } else {
          return false;
        }
      case 'EditCrewWorkLog':
      case 'CreateCrewWorkLog':
      case 'EditDailyReportSettings':
      case 'EditSurveyQuestions':
      case 'ScheduleTimeCardReports':
      case 'EditCloudServices':
      case 'EditIntegrations':
      case 'EditProjectNotificationSettings':
      case 'NotifyMissedDailies':
      case 'ViewInsights':
      case 'EditProjectTemplates':
      case 'EditCompanyRoles':
      case 'EditWorkers':
      case 'EditWorkerProjects':
      case 'CUDCostCodes':
      case 'CUDBudgets':
      case 'EditBreaks':
      case 'EditPayTypes':
      case 'EditTrackTime':
      case 'EditOverTimeRules':
      case 'CUDClassifications':
      case 'CUDMaterials':
        if (!this.onPerformancePlan && !this.onProfessionalPlan) {
          return this.professionalRequiredMessage;
        } else {
          return false;
        }
      case 'PromoteWorkers':
      case 'AddCompanyMembers':
      case 'ViewActivity':
      case 'CreateProjects':
        if (
          !this.onPerformancePlan &&
          !this.onProfessionalPlan &&
          !this.onBasicPlan
        ) {
          return this.basicRequiredMessage;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  @computed
  get onStarterPlan() {
    return this.billingPlan && this.billingPlan === 'Starter';
  }

  @computed
  get onBasicPlan() {
    return this.billingPlan && this.billingPlan === 'Basic';
  }

  @computed
  get onProfessionalPlan() {
    return this.billingPlan && this.billingPlan === 'Professional';
  }

  @computed
  get onPerformancePlan() {
    return (
      (this.company && this.company.isPromo) ||
      (this.billingPlan && this.billingPlan === 'Performance')
    );
  }

  @computed get canFavoriteMedia() {
    if (!this.onPerformancePlan) return false;
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @action.bound
  setFeatureName(featureName) {
    this.featureName = featureName;
  }

  @computed
  get checkPageAccess() {
    return this.denyAccessToPage;
  }

  @action.bound
  checkFeatureAccess(featureName) {
    return new Promise(resolve => {
      if (this.rootStore.isSuperAdmin) {
        resolve(true);
        return;
      }

      this.setFeatureName(featureName);

      if (!this.denyAccessToFeature) {
        resolve(true);
      } else {
        callTrack(UPGRADE_PLAN_VIEWED, {
          feature_name: this.featureName
        });

        this.showModal('featureAccess');
      }
    });
  }

  @computed
  get denyAccessToFeature() {
    return this.featureName && !this[`can${this.featureName}`];
  }

  @action.bound
  hideFeatureAccessModal() {
    this.hideActiveModal().then(() => {
      this.featureName = null;
      this.featureUnavailable = false;
    });
  }

  @action.bound
  goToSubscriptionAndBilling() {
    return this.hideActiveModal().then(() => {
      runInAction(() => {
        this.featureName = null;
        history.push('/company/subscription-billing');
      });
    });
  }

  @action.bound
  goToPricing() {
    window.open(new URL('/plans', window.appConfig.website_url), '_blank');
  }

  @action.bound
  sendFeatureAccessRequest(featureName, settingsField) {
    this.me.settings
      .save({
        [settingsField]: Date.now()
      })
      .then(() => {
        callTrack(UPGRADE_PLAN_REQUESTED, {
          feature_name: featureName
        });

        this.notifications.pushNotification({
          showUndo: false,
          title: t('Access Requested'),
          text: t('Your request has been sent to your project administrator.')
        });
      });
  }

  @action.bound
  goToPreviousPage() {
    if (historyState.length !== 0) {
      history.goBack();
    } else {
      history.push('/projects');
    }
  }

  // Rules
  @computed
  get canViewCompany() {
    return this.company && !this.me.isPending;
  }

  @computed
  get canViewTeam() {
    return !this.me.isPending;
  }

  @computed
  get canViewActivity() {
    if (this.me.isCollaborator) return true;

    return this.billingPlan && !this.onStarterPlan;
  }

  @computed
  get canViewInsights() {
    return (
      this.billingPlan && (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed
  get canViewProjects() {
    return true;
  }

  @computed
  get canViewProject() {
    return true;
  }

  @computed
  get canViewTasks() {
    return true;
  }

  @computed
  get canCreateProjects() {
    if (this.me.isPending) return false;

    if (!this.billingPlan) return false;

    if (this.onStarterPlan && this.me.projectUuids.length >= 1) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditProjects() {
    if (this.me.isPending) return false;

    if (!this.billingPlan) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canViewProjectSummaryReports() {
    return this.onPerformancePlan;
  }

  @computed
  get canApplyProjectTemplate() {
    if (!this.onPerformancePlan) return false;
    if (this.project && this.project.isChildProject) return true;

    return this.me.isAdmin;
  }

  @computed
  get canEditProjectInfo() {
    if (!this.project) {
      return this.canCreateProjects;
    }

    if (this.project && this.project.isChildProject) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditProjectRadius() {
    if (this.project.isChildProject) {
      return false;
    }

    return (
      (this.onProfessionalPlan || this.onPerformancePlan) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditProjectImage() {
    if (!this.project) {
      return this.canCreateProjects;
    }

    if (this.project && this.project.isChildProject) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed get canEditProjectPdfLogo() {
    if (!this.project) {
      return this.canCreateProjects;
    }

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditProjectStartEndDate() {
    if (!this.project) {
      return this.canCreateProjects;
    }

    if (this.me.isPending) return true;

    if (this.project && this.project.isChildProject) return true;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditProjectState() {
    if (this.me.isPending) return false;

    if (this.project && this.project.isChildProject && this.project.isDeleted) {
      return false;
    }

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canDeleteProject() {
    if (this.project && this.project.isChildProject) return false;

    return this.canEditProjectState;
  }

  @computed
  get canEditGeneralSettings() {
    if (this.project && !this.project.isActive) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PROJECTS
    );
  }

  @computed
  get canEditDailyReportSettings() {
    if (this.project && !this.project.isActive) return false;

    if (
      !this.onProfessionalPlan &&
      !this.onPerformancePlan &&
      !this.project.isChildProject
    )
      return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.MODIFY_DAILY_REPORT_SETTINGS
    );
  }

  get canEditSurveyQuestions() {
    if (this.project && !this.project.isActive) return false;

    if (
      !this.onProfessionalPlan &&
      !this.onPerformancePlan &&
      !this.project.isChildProject
    )
      return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.MODIFY_SURVEY_QUESTION_SETTINGS
    );
  }

  @computed
  get canEditDailyReportRecipients() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onPerformancePlan) return false;

    if (this.project && this.project.isChildProject) return true;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.USE_AUTOMATIC_EMAIL_FEATURE
    );
  }

  @computed
  get canEditSegmentSettings() {
    if (this.project && !this.project.isActive) return false;

    if (this.project.isChildProject) return false;

    return this.me.isAdmin;
  }

  @computed
  get canEditProjectNotificationSettings() {
    if (this.project && !this.project.isActive) return false;

    return (
      this.me.isAdmin && (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed
  get canEditProjectAccessSettings() {
    if (this.project && !this.project.isActive) return false;

    if (this.project && this.project.isChildProject) return true;

    return this.me.isAdmin;
  }

  // TODO: Remove and replace with canCUDCostCodes following Project Settings 2.0
  @computed
  get canEditCostCodes() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_COST_CODES
    );
  }

  @computed
  get canCUDCostCodes() {
    return this.canEditCostCodes;
  }

  @computed
  get canCUDBudgets() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_BUDGETS
    );
  }

  @computed
  get canCUDClassifications() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_CLASSIFICATIONS
    );
  }

  @computed
  get canEditShifts() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_SHIFTS
    );
  }

  @computed
  get canEditPayTypes() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PAY_TYPES
    );
  }

  @computed
  get canEditBreaks() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_BREAKS
    );
  }

  @computed
  get canEditWorkers() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_WORKERS
    );
  }

  @computed
  get canEditWorkerProjects() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditTrackTime() {
    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditOverTimeRules() {
    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_OVERTIME_RULES
    );
  }

  @computed
  get canEditCloudServices() {
    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    return this.me.isAdmin;
  }

  @computed
  get canEditIntegrations() {
    if (this.isSuperAdmin) return true;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    return this.me.isAdmin;
  }

  @computed
  get canEditSurveyAnswers() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCreateNotes() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditNotes() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canDeleteNotes() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canUploadAttachments() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canVerifyReport() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEmailReport() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canGenerateReport() {
    return this.me.role !== 'ROLE_USER';
  }

  @computed
  get canDownloadTimeCardByWorkerReport() {
    return this.me.isAdmin;
  }

  @computed
  get canCreateWorkLog() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditWorkLog() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canDeleteWorkLog() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCreateCrewWorkLog() {
    return (
      this.canEditWorkLog && (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed
  get canEditCrewWorkLog() {
    return (
      this.canEditWorkLog && (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed
  get canViewCrewWorkLogs() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canInviteCollaborators() {
    return this.onPerformancePlan && this.me.permissions.INVITE_COLLABORATORS;
  }

  @computed
  get canInviteProjectMembers() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canPromoteWorkers() {
    return this.canInviteCompanyMembers && this.me.permissions.CUD_WORKERS;
  }

  @computed
  get canInviteCompanyMembers() {
    return !this.onStarterPlan && this.me.permissions.INVITE_NEW_TEAM_MEMBERS;
  }

  @computed
  get canCUDCompanyInvites() {
    return this.me.isAdmin && this.me.permissions.INVITE_NEW_TEAM_MEMBERS;
  }

  @computed
  get canCUDCompanyMembers() {
    return this.me.isAdmin;
  }

  @computed
  get canCUDCompanyMemberRoles() {
    return this.me.isAccountAdmin;
  }

  @computed
  get canAddCompanyMembers() {
    return !this.onStarterPlan;
  }

  @computed
  get canViewCollaboratorProjectMembers() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canScheduleTimeCardReports() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewProductionReports() {
    return this.onPerformancePlan;
  }

  @computed
  get canScheduleSmallEquipmentReports() {
    return this.onPerformancePlan;
  }

  @computed
  get canViewObservationReports() {
    return this.onPerformancePlan;
  }

  @computed
  get canViewChecklistReports() {
    return this.onPerformancePlan;
  }

  @computed
  get canDownloadManpowerReport() {
    return this.onPerformancePlan;
  }

  @computed
  get canDownloadMediaGallery() {
    return this.onPerformancePlan;
  }

  @computed
  get canRolloverWorklogs() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canTransferReports() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canMarkAsNoWorkDone() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCreateTasks() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditTasks() {
    return true;
  }

  @computed
  get canDeleteTasks() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditProjectTeam() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditCompanyInfo() {
    return this.me.isAccountAdmin;
  }

  @computed
  get canEditPolicies() {
    return this.me.isAccountAdmin;
  }

  @computed
  get canEditCompanyEmails() {
    return this.me.isAccountAdmin;
  }

  @computed
  get canUploadToolboxTalks() {
    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.UPLOAD_TOOLBOX_TALKS
    );
  }

  @computed
  get canScheduleToolboxTalks() {
    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.SCHEDULE_TOOLBOX_TALKS
    );
  }

  @computed
  get canDeleteScheduleToolboxTalks() {
    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.DELETE_SCHEDULED_TOOLBOX_TALKS
    );
  }

  @computed
  get canViewToolboxTalks() {
    return this.onPerformancePlan;
  }

  @computed
  get canEditPDFLogo() {
    return this.me.isAdmin && !this.onStarterPlan;
  }

  @computed
  get canEditCompanyRoles() {
    return (
      this.me.isAdmin && (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed
  get canCreateProjectTemplates() {
    return this.onPerformancePlan && this.me.isAdmin;
  }

  @computed
  get canEditProjectTemplates() {
    if (!this.onPerformancePlan && !this.onProfessionalPlan) return false;

    return this.me.isAdmin;
  }

  @computed
  get canApplyProjectTemplates() {
    if (!this.onPerformancePlan && !this.onProfessionalPlan) return false;

    return this.me.isAdmin;
  }

  @computed
  get canBulkDownload() {
    return this.onPerformancePlan;
  }

  @computed
  get canDownloadMemberDirectory() {
    return this.me.role !== 'ROLE_USER';
  }

  @computed
  get canNotifyMissedDailies() {
    if (!this.onPerformancePlan && !this.onProfessionalPlan) return false;
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canApproveMembers() {
    return (
      this.me.role === 'ROLE_ACCOUNT_ADMIN' ||
      this.me.permissions.INVITE_NEW_TEAM_MEMBERS
    );
  }

  @computed
  get canViewMaterialLogs() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewMaterials() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return true;

    return true;
  }

  @computed
  get canCRUDMaterialLogs() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCUDMaterials() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_MATERIALS
    );
  }

  @computed
  get canViewEquipmentLogs() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewEquipmentDeployments() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewEquipment() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return true;

    return true;
  }

  @computed
  get canViewEquipmentRate() {
    if (!this.me.permissions.CUD_EQUIPMENT_RATES) {
      return false;
    }

    return true;
  }

  @computed
  get canCRUDEquipmentLogs() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCRUDEquipmentDeployments() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canCRUDOwnedEquipment() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onProfessionalPlan && !this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_EQUIPMENT
    );
  }

  @computed
  get canViewMemberProfile() {
    return true;
  }

  @computed
  get isAccountAdmin() {
    return this.me.role === 'ROLE_ACCOUNT_ADMIN';
  }

  @computed
  get canViewBilling() {
    return this.isAccountAdmin;
  }

  @computed
  get canViewProductionTab() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewEquipmentTab() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed get canCUDCompanySafety() {
    return this.me.isAdmin && !this.me.isPending;
  }

  @computed
  get canCreateChecklists() {
    return (
      (this.me.isAdmin || this.me.role === 'ROLE_PROJECT_MEMBER') &&
      !this.me.isPending
    );
  }

  @computed
  get canEditChecklists() {
    return (
      (this.me.isAdmin || this.me.role === 'ROLE_PROJECT_MEMBER') &&
      !this.me.isPending
    );
  }

  @computed
  get canDeleteAllChecklists() {
    return this.me.isAdmin && !this.me.isPending;
  }

  @computed get canCUDCompanyChecklistTypes() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_CHECKLIST_TYPE
    );
  }

  @computed get canCUDCompanyChecklistTemplates() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_CHECKLIST_TEMPLATE
    );
  }

  @computed get canViewRakenSafetyTemplates() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CAN_VIEW_RAKEN_SAFETY_TEMPLATES
    );
  }

  @computed get canCUDCompanyChecklistSchedules() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_CHECKLIST_SCHEDULE
    );
  }

  @computed get canCUDChecklists() {
    if (!this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_SAFETY_CHECKLISTS
    );
  }

  @computed get canCUDIncidents() {
    if (!this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_INCIDENT_REPORTS
    );
  }

  @computed get canCUDSubmitIncidentReports() {
    if (!this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CAN_SUBMIT_INCIDENT_REPORT
    );
  }

  // Slightly different from above - no permission check for Perfomance
  // Allows subs access.
  @computed get canModifyChecklists() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_SAFETY_CHECKLISTS
    );
  }

  @computed get canCUDCompanyCertifications() {
    if (this.me.isPending) return false;

    return (
      this.me.isAdmin || this.me.isProjectMember
      // TODO: && this.me.permissions.CUD_CERTIFICATION
    );
  }

  @computed get canCUDCompanyCertificationTypes() {
    if (this.me.isPending) return false;

    return (
      this.me.isAdmin || this.me.isProjectMember
      // TODO: && this.me.permissions.CUD_CERTIFICATION_TYPE
    );
  }

  @computed
  get canViewProductionInsights() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewSafetyInsights() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewProductionInsightsWithPermission() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_PRODUCTION_INSIGHTS
    );
  }

  @computed
  get canEnterProgressProductionInsights() {
    return (
      (this.me.isAdmin || this.me.role === 'ROLE_PROJECT_MEMBER') &&
      this.me.permissions.USE_PROGRESS_TRACKING_FEATURE
    );
  }

  @computed get canEnableSegments() {
    return this.onPerformancePlan;
  }

  @computed get canViewSegmentReports() {
    if (this.project && this.project.isChildProject) return true;

    return this.onPerformancePlan;
  }

  @computed
  get canViewTimeCards() {
    return this.onProfessionalPlan || this.onPerformancePlan;
  }

  @computed
  get canViewTimesheets() {
    return (
      (this.canReviewTime || this.canReviewAndApproveTimeCards) &&
      (this.onProfessionalPlan || this.onPerformancePlan)
    );
  }

  @computed get canReviewTime() {
    return this.me.permissions.CAN_REVIEW_TIME;
  }

  @computed
  get canReviewAndApproveTimeCards() {
    return this.me.isAdmin && this.me.permissions.APPROVE_TIME_CARDS;
  }

  @computed
  get canModifyKioskSettings() {
    return (
      (this.onProfessionalPlan || this.onPerformancePlan) &&
      this.me.permissions.MODIFY_KIOSK_SETTINGS
    );
  }

  @computed
  get canModifyTimeClockSettings() {
    return this.onPerformancePlan && this.me.permissions.MODIFY_KIOSK_SETTINGS;
  }

  @computed
  get canEditApprovedTimeCards() {
    return this.me.role === 'ROLE_ACCOUNT_ADMIN';
  }

  @computed get canEditIntegrationConfiguration() {
    return this.isSuperAdmin || this.me.role === 'ROLE_ACCOUNT_ADMIN';
  }

  @computed get canSyncIntegrationTime() {
    return this.me.permissions.SYNC_INTEGRATION_TIME;
  }

  @computed
  get canMergeCompanies() {
    return window.appConfig.canMergeCompanies;
  }

  @computed
  get canEditCoupons() {
    return window.appConfig.canEditCoupons;
  }

  @computed get canViewMemberCertifications() {
    return this.onPerformancePlan;
  }

  @computed
  get canViewObservations() {
    return this.onPerformancePlan || this.project.isChildProject;
  }

  @computed
  get canViewIncidents() {
    return this.onPerformancePlan || this.project.isChildProject;
  }

  @computed
  get canViewChecklists() {
    return this.onPerformancePlan || this.project.isChildProject;
  }

  @computed
  get canCreateObservations() {
    return (
      (this.me.isAdmin || this.me.isProjectMember) && this.onPerformancePlan
    );
  }

  @computed get canCUDCompanyObservationTypes() {
    if (!this.onPerformancePlan) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_OBSERVATION_TYPES_SUBTYPES
    );
  }

  @computed
  get canCreateIncidents() {
    return (
      (this.me.isAdmin || this.me.isProjectMember) && this.onPerformancePlan
    );
  }

  @computed get canCUDProjectLocations() {
    if (this.project && this.project.isChildProject) return false;

    return this.canEditProjects;
  }

  @computed
  get canEditPayrollSettings() {
    const allowedUserGroups = new Set(['CS_OPS_ADMIN', 'ENGINEERING']);
    const userGroupNames =
      this.me.userGroups?.filter(userGroup =>
        allowedUserGroups.has(userGroup.name)
      ) || [];
    return userGroupNames.length > 0;
  }

  @computed
  get canViewForms() {
    return this.onPerformancePlan;
  }

  @computed
  get canCUDForms() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_FORMS
    );
  }

  @computed
  get canViewDocuments() {
    return this.onPerformancePlan;
  }

  @computed
  get canCUDDocuments() {
    if (this.project && !this.project.isActive) return false;

    if (!this.onPerformancePlan) return false;

    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_DOCUMENT_STORAGE
    );
  }

  @computed get canCUDGroups() {
    if (this.me.isPending) return false;

    return (
      (this.me.isAdmin || this.me.isProjectMember) &&
      this.me.permissions.CUD_GROUPS
    );
  }

  @computed get hasTimeClockAccess() {
    return (
      this.onPerformancePlan && this.company.companyAddOns?.hasTimeClockAccess
    );
  }

  @computed get disableIntegrationsForTrialAccount() {
    if (!this.company?.subscription?.onTrial) return false;

    return !this.company?.companyAddOns?.enableIntegrationsForTrial;
  }

  @computed
  get canSelectCoverPhotos() {
    if (!this.onPerformancePlan) return false;

    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed
  get canEditMyTime() {
    return this.me.isAdmin || this.me.isProjectMember;
  }

  @computed get canViewMyTime() {
    if (!this.company) return false;

    return this.company.preferences?.trackMemberTime !== 'assignedWorkersOnly';
  }
}

import { t } from 'utils/translate';
import { insertIf } from 'utils/insertIf';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

export default class CompanyNavigationUI extends UIStore {
  @computed get items() {
    if (this.parent.isMobileScreen) {
      return {
        id: 'company',
        icon: 'settings',
        label: t('Company'),
        link: '/company/info',
        subnav: [
          {
            id: 'company-info',
            label: t('Company info'),
            icon: 'info-new',
            link: '/company/info'
          },
          ...insertIf(this.rootStore.authorizationUI.canViewBilling, [
            {
              id: 'company-billing',
              label: t('Billing'),
              icon: 'billings',
              link: '/company/billing'
            }
          ])
        ]
      };
    }

    return {
      id: 'company',
      icon: 'settings',
      label: t('Company'),
      link: '/company/info',
      subnav: [
        {
          id: 'company-info',
          label: t('Company info'),
          icon: 'info-new',
          link: '/company/info'
        },
        ...insertIf(this.me.role === 'ROLE_ACCOUNT_ADMIN', [
          {
            id: 'company-roles',
            label: t('Roles & permissions'),
            icon: 'roles-and-permissions',
            link: '/company/roles'
          }
        ]),
        {
          id: 'company-project-templates',
          label: t('Project templates'),
          icon: 'project-template',
          link: '/company/project-templates'
        },
        ...insertIf(!this.company.hideLegacySettings, [
          {
            id: 'company-production',
            label: t('Production'),
            icon: 'project-production',
            link: '/company/production'
          }
        ]),
        ...insertIf(
          this.featureFlags.FF_SETUP_TIME_POLICIES &&
            this.authorization.canEditPolicies,
          [
            {
              id: 'company-time',
              label: t('Time settings'),
              icon: 'timesheet',
              links: [
                {
                  path: {
                    pathname: '/company/time/policies'
                  },
                  label: t('Policies')
                },
                {
                  path: {
                    pathname: '/company/time/pay-period'
                  },
                  label: t('Pay period')
                },
                {
                  path: {
                    pathname: '/company/time/approvals'
                  },
                  label: t('Approvals')
                },
                {
                  path: {
                    pathname: '/company/time/classifications'
                  },
                  label: t('Classifications')
                },
                {
                  path: {
                    pathname: '/company/time/advanced'
                  },
                  label: t('Advanced')
                }
              ]
            }
          ]
        ),
        ...insertIf(this.company.hideLegacySettings, [
          {
            id: 'company-cost-codes',
            label: t('Cost codes'),
            icon: 'list',
            link: '/company/cost-codes'
          }
        ]),
        ...insertIf(this.company.hideLegacySettings, [
          {
            id: 'company-materials',
            label: t('Materials'),
            icon: 'production',
            link: '/company/materials'
          }
        ]),
        {
          id: 'company-equipment',
          label: t('Equipment'),
          icon: 'equipment',
          links: [
            {
              path: {
                pathname: '/company/equipment'
              },
              label: t('Heavy / on-road'),
              exact: true
            },
            {
              path: {
                pathname: '/company/equipment/small-equipment'
              },
              label: t('Small equipment')
            }
          ]
        },
        {
          id: 'company-groups',
          label: t('Groups'),
          icon: 'groups',
          link: '/company/groups'
        },
        {
          id: 'company-checklists',
          label: t('Checklists'),
          icon: 'checklists',
          links: [
            {
              path: {
                pathname: '/company/checklists/types'
              },
              label: t('Types')
            },
            {
              path: {
                pathname: '/company/checklists/templates'
              },
              label: t('Templates')
            },
            {
              path: {
                pathname: '/company/checklists/completed'
              },
              label: t('Completed')
            },
            {
              path: {
                pathname: '/company/checklists/schedules'
              },
              label: t('Schedules')
            }
          ]
        },
        {
          id: 'company-observations',
          label: t('Observations'),
          icon: 'warning',
          links: [
            {
              path: {
                pathname: '/company/observations/types'
              },
              label: t('Types')
            }
          ]
        },
        {
          id: 'company-incidents',
          label: t('Incidents'),
          icon: 'incidents',
          links: [
            {
              path: {
                pathname: '/company/incidents/all-incidents'
              },
              label: t('All incidents')
            },
            {
              path: {
                pathname: '/company/incidents/submission-settings'
              },
              label: t('Submission settings')
            },
            ...insertIf(this.featureFlags.FF_OSHA_SUBMIT, [
              {
                path: {
                  pathname: '/company/incidents/osha-submissions'
                },
                label: t('Submitted to OSHA')
              }
            ])
          ]
        },
        {
          id: 'company-toolbox-talks',
          label: t('Toolbox talks'),
          icon: 'toolbox-talks',
          links: [
            {
              path: {
                pathname: '/company/toolbox-talks/all'
              },
              label: t('Library')
            },
            {
              path: {
                pathname: '/company/toolbox-talks/past'
              },
              label: t('Past talks')
            },
            {
              path: {
                pathname: '/company/toolbox-talks/upcoming'
              },
              label: t('Upcoming talks')
            }
          ]
        },
        {
          id: 'company-forms',
          label: t('Forms'),
          icon: 'forms',
          links: [
            {
              path: {
                pathname: '/company/forms/templates'
              },
              label: t('Templates')
            },
            {
              path: {
                pathname: '/company/forms/all'
              },
              label: t('All forms')
            }
          ]
        },
        {
          id: 'company-certifications',
          label: t('Certifications'),
          icon: 'certification',
          links: [
            {
              path: {
                pathname: '/company/certifications/types'
              },
              label: t('Types')
            },
            {
              path: {
                pathname: '/company/certifications/certifications'
              },
              label: t('Certifications')
            }
          ]
        },
        {
          id: 'company-integrations',
          label: t('Integrations'),
          icon: 'integration',
          link: '/company/integrations'
        },
        ...insertIf(this.rootStore.authorizationUI.canViewBilling, [
          {
            id: 'company-billing',
            label: t('Billing'),
            icon: 'billings',
            link: '/company/billing'
          }
        ])
      ]
    };
  }
}

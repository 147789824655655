import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { action, computed, observable, reaction } from 'mobx';
import qs from 'querystringify';
import UIStore from 'stores/ui/UIStore';

import Project from 'stores/models/Project';
import TimeEntriesWorkLog from 'stores/models/workLogs/TimeEntriesWorkLog';

import {
  TimeCardForm,
  timeCardFormOptions,
  timeCardFormFields,
  timeCardFormRules,
  timeCardFormValues,
  timeCardFormLabels,
  timeCardFormPlugins
} from 'forms/myTime/timeCard';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import {
  TIMECARD_STARTED,
  TIMECARD_ADDED
} from 'utils/segmentAnalytics/eventSpec';

import sendTimeCardEvent from 'utils/segmentAnalytics/sendTimeCardEvent';

const moment = extendMoment(Moment);

export default class MyTimeTimeCardAddUI extends UIStore {
  @observable entryAddForm;
  @observable entryForAdd;
  @observable loading;
  @observable project;

  @observable previousPath;

  @observable createAnotherTimeCard;

  @observable initiatorUIStore;

  constructor(options) {
    super(options);
    this.entryAddForm = null;
    this.entryForAdd = null;
    this.loading = false;
    this.project = null;
    this.createAnotherTimeCard = false;
    this.initiatorUIStore = null;
  }

  @computed get defaultCostCode() {
    return this.parent.defaultCostCode;
  }

  @action.bound async setup(location, initiatorUIStore) {
    const params = qs.parse(location.search);

    this.initiatorUIStore = initiatorUIStore;

    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.classificationSelectorUI.setup();

    this.entryForAdd = new TimeEntriesWorkLog(null, {
      rootStore: this.rootStore
    });

    sendTimeCardEvent(TIMECARD_STARTED, this.entryForAdd, 'my_time');

    this.setupEntryAddForm(params.date);
    this.setupReactions();
  }

  @action.bound
  setupEntryAddForm(date = moment().format('YYYY-MM-DD')) {
    this.entryAddForm = new TimeCardForm(
      {
        fields: timeCardFormFields,
        rules: timeCardFormRules,
        values: Object.assign(timeCardFormValues, {
          date: date
        }),
        labels: timeCardFormLabels
      },
      {
        options: timeCardFormOptions,
        plugins: timeCardFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.projectSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();

    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToProject = reaction(
      () => this.projectUuid,
      projectUuid => {
        this.setProjectSpecificOptions();
      }
    );

    this.reactToActiveSegments = reaction(
      () => this.hasActiveSegments,
      hasActiveSegments => {
        this.setSegmentSpecificRules();
      }
    );
  }

  @action.bound
  tearDownReactions() {
    this.reactToProject && this.reactToProject();
    this.reactToActiveSegments && this.reactToActiveSegments();
  }

  @computed get projectUuid() {
    return this.entryAddForm.$('project.value').value;
  }

  @action.bound selectProject(option) {
    this.entryAddForm.$('project').set(option || { value: '', name: '' });
  }

  @action.bound selectClassification(option) {
    this.entryAddForm.$('classification').set(option || { uuid: '', name: '' });
  }

  @action.bound selectCostCode(option) {
    this.entryAddForm.$('costCode').set(option || { uuid: '', code: '' });
  }

  @computed get showProjectSpecificOptions() {
    return this.projectUuid && !this.loading;
  }

  @computed
  get activeSegments() {
    const date = moment(this.entryAddForm.$('date').value).format('YYYY-MM-DD');

    if (!this.project || !date) return [];

    return this.project.getActiveSegmentsForDate(date);
  }

  @computed
  get hasActiveSegments() {
    return this.activeSegments?.length > 0;
  }

  @computed get segmentOptions() {
    return this.activeSegments?.map(segment => {
      return {
        uuid: segment.uuid,
        name: segment.name
      };
    });
  }

  @action.bound selectSegment(option) {
    this.entryAddForm.$('segment').set(option || { uuid: '', name: '' });
  }

  @action.bound async setProjectSpecificOptions() {
    if (this.projectUuid) {
      try {
        this.loading = true;

        this.costCodeSelectorUI.setup({
          projectUuid: this.projectUuid
        });

        this.project = new Project(
          { uuid: this.projectUuid },
          {
            rootStore: this.rootStore
          }
        );

        await this.project.fetch();

        this.setProjectSpecificRules();
      } catch (error) {
        alertErrorHandler(error, this.setValidationDetails);
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
      this.project = null;
      this.costCodeSelectorUI.tearDown();
      this.clearProjectSpecificValues();
    }
  }

  @action.bound setProjectSpecificRules() {
    const {
      classificationsOnTimeCards,
      costCodesOnTimeCards,
      timeCardsStartAndEndTime
    } = this.project;

    this.clearProjectSpecificValues();

    this.entryAddForm.set('rules', {
      'costCode.uuid':
        costCodesOnTimeCards === 'NOT_REQUIRED' ? '' : 'required',
      'classification.uuid':
        classificationsOnTimeCards === 'NOT_REQUIRED' ? '' : 'required',
      startTime: timeCardsStartAndEndTime ? 'required' : '',
      endTime: timeCardsStartAndEndTime ? 'required' : '',
      hoursWorked: timeCardsStartAndEndTime
        ? ''
        : 'required|greaterThan0|max:24'
    });
  }

  @action.bound setSegmentSpecificRules() {
    this.entryAddForm
      .$('segment.uuid')
      .set('rules', this.hasActiveSegments ? 'required' : 'string');
  }

  @action.bound clearProjectSpecificValues() {
    if (
      this.defaultCostCode.uuid &&
      this.defaultCostCode.getProjectByUuid(this.projectUuid)
    ) {
      this.entryAddForm.$('costCode').set({
        uuid: this.defaultCostCode.uuid,
        code: this.defaultCostCode.code,
        division: this.defaultCostCode.division
      });
    } else {
      this.entryAddForm.$('costCode').clear();
    }

    if (this.project.classificationsOnTimeCards === 'NOT_REQUIRED') {
      this.entryAddForm.$('classification').clear();
    } else if (this.me.classificationUuid) {
      this.entryAddForm.$('classification').set({
        uuid: this.me.classificationUuid,
        name: this.me.classificationName
      });
    }

    this.entryAddForm.$('costCode.uuid').resetValidation();
    this.entryAddForm.$('classification.uuid').resetValidation();
    this.entryAddForm.$('segment').clear();
    this.entryAddForm.$('segment.uuid').resetValidation();
    this.entryAddForm.$('hoursWorked').resetValidation();
    this.entryAddForm.$('startTime').resetValidation();
    this.entryAddForm.$('endTime').resetValidation();
  }

  @action.bound clearUIState() {
    this.entryAddForm = null;
    this.entryForAdd = null;
    this.loading = false;
    this.project = null;
    this.createAnotherTimeCard = false;
    this.initiatorUIStore = null;
  }

  @action.bound cancelTimeCardAdd() {
    this.initiatorUIStore.cancelTimeCardAdd();
  }

  @action.bound refetchAfterAdd() {
    this.initiatorUIStore.refetchAfterAdd();
  }

  @action.bound submitEntryAddForm(event) {
    event.preventDefault();
    event.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitEntryAddFormSuccess,
      onError: this.submitEntryAddFormError
    });
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      const { timeCardsStartAndEndTime } = this.project;
      const values = this.entryAddForm.values();

      let timeCardPayload = {
        worker: { uuid: this.me.workerUuid },
        allocation: {
          type: 'AUTOMATIC',
          costCode: {
            uuid: values.costCode.uuid
          },
          classification: {
            uuid: values.classification.uuid
          }
        }
      };

      if (timeCardsStartAndEndTime) {
        timeCardPayload.startTime = values.startTime;
        timeCardPayload.endTime = values.endTime;
      } else {
        timeCardPayload.allocation.totalHours = values.hoursWorked;
      }

      let workLogPayload = {
        type: 'CrewWorklogWithTimeEntries',
        name: this.me.fullName,
        projectUuid: this.projectUuid,
        reportDate: moment(values.date).format('YYYY-MM-DD'),
        segmentUuid: values.segment.uuid,
        timeCards: [timeCardPayload]
      };

      await this.entryForAdd.save(workLogPayload, {
        wait: true
      });

      sendTimeCardEvent(
        TIMECARD_ADDED,
        this.entryForAdd,
        'my_time',
        Boolean(this.createAnotherTimeCard)
      );

      this.entryForAdd = new TimeEntriesWorkLog(null, {
        rootStore: this.rootStore
      });

      this.entryForAdd = new TimeEntriesWorkLog(null, {
        rootStore: this.rootStore
      });

      this.refetchAfterAdd();

      if (!this.createAnotherTimeCard) {
        this.cancelTimeCardAdd();
      }

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Time card created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryAddFormError() {
    console.error(this.entryAddForm.errors());
  }

  @computed get startEndTimeRange() {
    const { startTime, endTime } = this.entryAddForm.values();

    if (startTime && endTime) {
      return Math.abs(
        moment
          .range(moment(startTime, 'HH:mm:ss'), moment(endTime, 'HH:mm:ss'))
          .diff('hours')
      );
    }

    return 0;
  }

  @action.bound toggleCreateAnotherTimeCard() {
    this.createAnotherTimeCard = !this.createAnotherTimeCard;
  }
}

import { RakenCollection } from 'stores/lib';

import SmallEquipmentDeployment from 'stores/models/equipment/SmallEquipmentDeployment';

export default class SmallEquipmentDeployments extends RakenCollection {
  model() {
    return SmallEquipmentDeployment;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/smallequipment/deployed`;
  }
}

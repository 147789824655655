import { observer } from 'mobx-react';
import React from 'react';

import { Form } from 'raken-ui';

import { Button, TextField } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const AppConnectFormSageIntacct = observer(({ uiStore }) => {
  const {
    companyId,
    userId,
    password
  } = uiStore.activeIntegration.connectPlaceholders;

  return (
    <Form data-qa="form_app-connect" onSubmit={uiStore.submitActiveForm}>
      <TextField
        placeholder={companyId}
        dataQA="companyId"
        value={uiStore.activeForm.$('companyId').value}
        onChange={uiStore.activeForm.$('companyId').sync}
        error={uiStore.activeForm.$('companyId').error}
        helperText={uiStore.activeForm.$('companyId').error}
        fullWidth
        autoFocus
      />

      <TextField
        placeholder={userId}
        dataQA="userId"
        value={uiStore.activeForm.$('userId').value}
        onChange={uiStore.activeForm.$('userId').sync}
        error={uiStore.activeForm.$('userId').error}
        helperText={uiStore.activeForm.$('userId').error}
        fullWidth
      />

      <TextField
        placeholder={password}
        dataQA="password"
        value={uiStore.activeForm.$('password').value}
        onChange={uiStore.activeForm.$('password').sync}
        error={uiStore.activeForm.$('password').error}
        helperText={uiStore.activeForm.$('password').error}
        type="password"
        fullWidth
      />

      <Button disabled={uiStore.saving} type="submit" fullWidth dataQA="submit">
        {uiStore.saving ? t('Signing in') : t('Sign in')}
      </Button>
    </Form>
  );
});

export default AppConnectFormSageIntacct;

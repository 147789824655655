import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable, runInAction, reaction } from 'mobx';

import request from 'axios';

import {
  PayTypeForm,
  payTypeFormOptions,
  payTypeFormFields,
  payTypeFormRules,
  payTypeFormPlugins
} from 'forms/payType';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

import PayTypes from 'stores/collections/PayTypes';
import PayType from 'stores/models/PayType';

export default class PolicyPayTypesUI extends PolicyChildEditUI {
  @observable form;
  @observable saving;
  @observable loading;
  @observable pageSize;
  @observable page;
  @observable payTypeForEdit;
  @observable payTypeForDelete;
  @observable addingPayType;

  constructor(options) {
    super(options);

    this.form = null;
    this.payTypeForEdit = null;
    this.payTypeForDelete = null;
    this.addingPayType = false;
    this.saving = false;
    this.loading = true;

    this.page = 1;
    this.pageSize = 25;

    this.payTypes = new PayTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    this.fetchPayTypes();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound
  clearUIState() {
    this.page = 1;
    this.loading = true;
    this.payTypes.clear();
    this.clearValidationDetails();
    this.payTypeForEdit = null;
    this.addingPayType = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed
  get totalPages() {
    return Math.ceil(this.payTypes.totalElements / this.pageSize);
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize
    };
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchPayTypes();
        });
      }
    );
  }

  @action.bound
  async fetchPayTypes() {
    this.loading = true;

    this.payTypes.cancelRequest();
    this.payTypes.clear();

    await this.payTypes.fetch({
      url: `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/companies/${this.rootStore.me.company.uuid}/paytypes?timePolicyUuid=${
        this.entryForEdit.uuid
      }`,
      params: { ...this.params }
    });

    this.loading = false;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound addPayType() {
    const payType = new PayType(
      {},
      {
        rootStore: this.rootStore
      }
    );
    this.addingPayType = true;
    this.editPayType(payType);
  }

  @action.bound
  editPayType(payType) {
    this.clearValidationDetails();
    this.payTypeForEdit = payType;

    this.initPolicyPayTypeForm();
  }

  @action.bound cancelPayTypeEdit() {
    this.form = null;
    this.payTypeForEdit = null;
    this.addingPayType = false;
    this.clearValidationDetails();
  }

  @action.bound
  initPolicyPayTypeForm() {
    const { name, code } = this.payTypeForEdit;

    this.form = new PayTypeForm(
      {
        fields: payTypeFormFields,
        rules: payTypeFormRules,
        values: {
          name,
          code
        }
      },
      {
        options: payTypeFormOptions,
        plugins: payTypeFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @computed get payTypeEditModalTitle() {
    return this.addingPayType ? `Add pay type` : `Edit pay type`;
  }

  @action.bound submitPayTypeEditForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.addingPayType
        ? this.submitPayTypeAddFormSuccess
        : this.submitPayTypeEditFormSuccess,
      onError: this.submitPayTypeEditFormError
    });
  }

  @action.bound submitPayTypeEditFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitPayTypeAddFormSuccess() {
    this.saving = true;
    try {
      await this.payTypeForEdit.save(
        { ...this.form.values() },
        {
          wait: true
        }
      );

      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/paytypes/${
          this.payTypeForEdit.uuid
        }?enabled=true`
      );

      this.fetchPayTypes();
      this.cancelPayTypeEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${t('Pay type added')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.addingPayType = false;
      this.saving = false;
    }
  }

  @action.bound
  async submitPayTypeEditFormSuccess() {
    this.saving = true;

    try {
      await this.payTypeForEdit.save(
        { ...this.form.values() },
        {
          wait: true
        }
      );

      this.saving = false;

      this.fetchPayTypes();
      this.cancelPayTypeEdit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: `${t('Pay type updated')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async togglePayTypeEnabled(payType) {
    payType.policyEnabled = !payType.policyEnabled;
    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/paytypes/${
          payType.uuid
        }?enabled=${payType.policyEnabled}`
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Pay type updated')}`
    });
  }

  @action.bound async deletePayType(payType) {
    this.clearValidationDetails();
    this.payTypeForDelete = payType;

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeletePayType() {
    await this.hideActiveModal();

    this.payTypeForDelete = null;
  }

  @action.bound async confirmDeletePayType() {
    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.entryForEdit.uuid}/paytypes/${
          this.payTypeForDelete.uuid
        }?enabled=false`
      );

      await this.payTypeForDelete.destroy({ wait: true });

      this.hideActiveModal();
      this.payTypeForDelete = null;

      this.fetchPayTypes();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Pay type deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}

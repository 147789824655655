import UIStore from './UIStore';
import request from 'axios';
import moment from 'moment-timezone';
import qs from 'querystringify';
import { action, observable, computed, runInAction, reaction } from 'mobx';

import valueNotEmpty from 'utils/formValueNotEmpty';

export default class CompanySubscriptionBillingUI extends UIStore {
  @observable selectedPlan;
  @observable saving;
  @observable loading;
  @observable editingSubscription;

  // Forms

  @observable timeFrameForm;

  constructor(options) {
    super(options);

    this.selectedPlan = null;
    this.saving = false;
    this.loading = true;
    this.editingSubscription = false;

    this.showUpsell = false;

    this.location = null;
  }

  @computed get transactions() {
    return this.company.transactions;
  }

  @action.bound
  setupReactions() {
    this.reactToLocation = reaction(
      () => this.location,
      location => {
        const queryParams = qs.parse(location.search);

        if (queryParams.open === 'manage-account') {
          this.showUpgradeModal();
        } else {
          this.hideUpgradeModal();
        }
      }
    );
  }

  @action.bound
  initializeSubscriptionBillingTab() {
    this.fetchBillingData();
    this.showEditSubscriptionForm = false;
  }

  @action.bound
  showUpgradeModal() {
    this.showModal('upgradeModal');
  }

  @action.bound
  hideUpgradeModal() {
    this.hideActiveModal();
  }

  @action.bound
  cancelSubscription() {
    const { subscription } = this;

    this.saving = true;

    request.delete(subscription.url()).then(response => {
      runInAction(() => {
        subscription.setAttribute('subscriptionState', 'CANCELLED'); // TODO: need checking
        this.saving = false;
        this.activeModal = null;
      });
    });
  }

  @action.bound
  async fetchBillingData() {
    this.loading = true;

    if (!this.company.isInternal) {
      await this.transactions.fetch({
        params: {
          limit: 1000
        }
      });
    }

    this.loading = false;
  }

  @computed
  get trialDaysRemaining() {
    const form = this.subscriptionForm;

    if (!form) return null;

    return Math.max(
      moment(form.$('trialEndDate').value)
        .startOf('day')
        .diff(moment(new Date()).startOf('day'), 'days'),
      0
    );
  }

  @computed
  get hasPromoBanner() {
    if (!this.company) return false;

    const { isPromo } = this.subscription;

    if (!isPromo && this.remainingPromoDays !== 0) return false;

    if (this.remainingPromoDays === null) return false;

    return this.remainingPromoDays >= 0;
  }

  @computed
  get remainingPromoDays() {
    const { promoLeftDaysCount } = this.subscription;

    if (!valueNotEmpty(promoLeftDaysCount)) return null;

    return promoLeftDaysCount;
  }
}

import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { SelectWithSearch, Box, Text, Icon } from '@raken/athens-web/lib';
import { t } from 'utils/translate';
import { useHistory } from 'react-router-dom';
import kebabCase from 'lodash.kebabcase';
import styled from 'styled-components';

import { callTrack } from 'utils/segmentIntegration';

import { QUICK_PROJECT_SELECTED } from 'utils/segmentAnalytics/eventSpec';

const ProjectSelectorBox = styled(Box)`
  border: 1px solid #e4e3e2;
  border-radius: 6px;
  overflow: hidden;
  padding: 0 8px;
`;

const ProjectSelector = observer(({ projectUI, projectSelectorUI }) => {
  const history = useHistory();
  const projectsAutocomplete = useRef();

  return (
    <ProjectSelectorBox>
      <SelectWithSearch
        label={false}
        options={projectSelectorUI.projectOptionsForRender}
        filterOptions={() => {
          return projectSelectorUI.projectOptionsForRender;
        }}
        onChange={(event, option) => {
          if (option) {
            const project = projectSelectorUI.projectOptions.getProjectByOptionIdValue(
              option.value
            );

            callTrack(QUICK_PROJECT_SELECTED, {
              project_name: project.name
            });

            history.push(project.viewUrl);
          }

          if (projectSelectorUI.filterProjectsQuery) {
            projectSelectorUI.resetProjectOptions();
          }
        }}
        onClose={projectSelectorUI.resetProjectOptions}
        optionTitle={'name'}
        onInputChange={(event, value, reason) => {
          if (reason === 'clear' || reason === 'input') {
            projectSelectorUI.setProjectOptionsSearchQuery(value);
          }
        }}
        ListboxProps={{
          ref: projectsAutocomplete,
          onScroll: () =>
            projectSelectorUI.fetchNextProjectOptions(projectsAutocomplete)
        }}
        renderOption={(option, { selected }) => {
          return (
            <Box
              pt={1}
              pb={1}
              data-qa={`option_project-${kebabCase(option.name)}`}
              display="flex"
              alignItems="flex-start"
              justifyContent={'space-between'}
              width="100%"
            >
              <Box>
                <Box>
                  <Text
                    dataQA={`header-project-${kebabCase(option.name)}-name`}
                  >
                    {option.name}
                  </Text>
                </Box>

                {option.projectNo && (
                  <Box>
                    <Text
                      rakenColor="grey"
                      dataQA={`header-project-${kebabCase(
                        option.projectNo
                      )}-company`}
                    >
                      {option.projectNo}
                    </Text>
                  </Box>
                )}
              </Box>

              {selected && (
                <Box ml="auto">
                  <Icon kind="checkmark" rakenColor="grey" />
                </Box>
              )}
            </Box>
          );
        }}
        value={projectUI.projectAsSelectOption}
        lineClamp={2}
        dropdownWidth="350px"
        noBorders
        removeDescriptionSpace
        placeholder={t('Select project')}
        searchPlaceholder={t('Search by name or job #')}
        dataQA="header-project-name"
        noOptionsText={t('No projects found.')}
        fullWidth
      />
    </ProjectSelectorBox>
  );
});

export default ProjectSelector;

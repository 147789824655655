import UIStore from './UIStore';
import { action, computed } from 'mobx';

import request from 'axios';

export default class ReportDownloadUI extends UIStore {
  @action.bound openReportPreview(type = 'daily') {
    this.projectUI.previewUI.showPreviewModal(type);
  }

  @action.bound
  executeDownloadDailyPdf() {
    this.openReportPreview('daily');
  }

  @action.bound
  executeDownloadSuperDailyPdf() {
    this.openReportPreview('super');
  }

  @computed get projectUI() {
    return this.rootStore.projectUI;
  }

  @computed get project() {
    return this.projectUI.project;
  }

  @computed
  get report() {
    return this.projectUI.reportSummary;
  }

  @computed get date() {
    return this.projectUI.date;
  }

  @action.bound
  async fetchPdfUrls() {
    this.loading = true;

    try {
      const response = await request.get(
        `/ra/projects/${this.project.id}/reports/${this.date}/daily-pdfs`
      );

      this.dailyReportUrls.replace(response.data.collection);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchSuperDailyPdfUrls() {
    this.loading = true;

    try {
      const response = request.get(
        `/ra/projects/${this.project.id}/reports/${this.date}/super-pdfs`
      );

      this.dailyReportUrls.replace(response.data.collection);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}

import { RakenCollection } from 'stores/lib';

import SmallEquipmentHistory from 'stores/models/equipment/SmallEquipmentHistory';

export default class SmallEquipmentHistoryCollection extends RakenCollection {
  model() {
    return SmallEquipmentHistory;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/smallequipment/${
      this.parent.entryForEdit.uuid
    }/history`;
  }
}

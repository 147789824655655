import { action } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

import moment from 'moment';

const companyEquipmentFormPlugins = extendedPlugins;

const companyEquipmentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const companyEquipmentFormFields = [
  'name',
  'category',
  'type',
  'equipmentId',
  'make',
  'model',
  'year',
  'serialNumber',
  'condition',
  'owned',
  'photo',
  'endDate',
  'deployedStartDate',
  'deployedEndDate',
  'projectUuid',
  'deploymentUuid',
  'totalHours',
  'idleHours',
  'totalHoursAvailablePerWeek',
  'trackMileage',
  'odometerType',
  'odometerReading',
  'trackFuel',
  'fuelGaugeType',
  'lifeTimeFuelConsumption',
  'supplier',
  'rate',
  'frequency',
  'returnDate'
];

const companyEquipmentFormRules = {
  name: 'required|string|max:100',
  category: 'string',
  type: 'string',
  make: 'string',
  equipmentId: 'string|max:100',
  model: 'string|max:100',
  endDate: 'dateCustom:YYYY-MM-DD',
  deployedStartDate: 'date|required_if:condition,DEPLOYED',
  deployedEndDate: 'date',
  owned: 'boolean',
  projectUuid: 'string|required_if:condition,DEPLOYED',
  totalHours: 'numeric|max:1000000',
  idleHours: 'numeric|max:1000000',
  totalHoursAvailablePerWeek: 'numeric|max:1000000',
  trackMileage: 'boolean',
  odometerType: 'string',
  odometerReading: 'numeric|max:1000000',
  trackFuel: 'boolean',
  fuelGaugeType: 'string',
  lifeTimeFuelConsumption: 'numeric|max:1000000',
  supplier: 'string|max:50',
  rate: 'numeric|greaterThan0|notMoreThanTwoDecimalPlaces|max:99999999.99',
  frequency: 'required_with:rate',
  returnDate: 'date'
};

const companyEquipmentFormLabels = {
  name: t('equipment name'),
  type: t('equipment type'),
  make: t('equipment make'),
  model: t('equipment model'),
  projectUuid: t('project'),
  deployedStartDate: t('start date'),
  deployedEndDate: t('end date'),
  endDate: t('end date'),
  equipmentId: t('equipment id'),
  totalHours: t('total hours meter'),
  idleHours: t('idle hours meter'),
  totalHoursAvailablePerWeek: t('total available hours/week'),
  odometerReading: t('odometer reading'),
  lifeTimeFuelConsumption: t('lifetime fuel consumption')
};

const companyEquipmentFormValues = {
  name: '',
  type: '',
  condition: 'AVAILABLE',
  owned: true,
  totalHours: 0,
  idleHours: 0,
  totalHoursAvailablePerWeek: 40,
  trackMileage: false,
  odometerType: 'MILES',
  odometerReading: 0,
  trackFuel: false,
  fuelGaugeType: 'GALLONS',
  lifeTimeFuelConsumption: 0,
  deployedStartDate: moment().format(`YYYY-MM-DD`)
};

class CompanyEquipmentForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }

  @action.bound updateDeployedStartDate(value) {
    this.$('deployedStartDate').set(
      value ? moment(value).format(`YYYY-MM-DD`) : ``
    );
  }

  @action.bound updateDeployedEndDate(value) {
    this.$('deployedEndDate').set(
      value ? moment(value).format(`YYYY-MM-DD`) : ``
    );
  }
}

export {
  CompanyEquipmentForm,
  companyEquipmentFormLabels,
  companyEquipmentFormValues,
  companyEquipmentFormRules,
  companyEquipmentFormFields,
  companyEquipmentFormOptions,
  companyEquipmentFormPlugins
};

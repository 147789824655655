import omit from 'lodash.omit';
import { when } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const memberFormPlugins = extendedPlugins;

const memberFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const memberFormFields = [
  'username',
  'firstName',
  'lastName',
  'classification',
  'classification.uuid',
  'classification.name',
  'employeeId',
  'phoneNumber',
  'title',
  'role',
  'avatar',
  'company',
  'company.uuid',
  'company.name',
  'companyUuid', // Used to build URL to validation endpoint
  'uuid', // Used in validation endpoint
  'defaultShift',
  'defaultShift.uuid',
  'defaultShift.name',
  'defaultCostCode',
  'defaultCostCode.uuid',
  'defaultCostCode.code',
  'defaultCostCode.division',
  'defaultCrewName',
  'defaultCrewName.uuid',
  'defaultCrewName.name',
  'groups[]',
  'groups[].uuid',
  'ssoBypass'
];

const memberFormRules = {
  username: 'email|max:200|required|username_available',
  firstName: 'string|max:255|required',
  lastName: 'string|max:255|required',
  'classification.uuid': 'string',
  'classification.name': 'string',
  employeeId: 'string|max:100|employeeId_available',
  phoneNumber: 'string|phone_available|max:30',
  title: 'string|max:255',
  role:
    'string|required|in:ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER,ROLE_PROJECT_WORKER',
  avatar: 'string',
  groups: 'array',
  ssoBypass: 'boolean'
};

const memberFormLabels = {
  username: t('email'),
  firstName: t('first name'),
  lastName: t('last name'),
  employeeId: t('employee ID'),
  phoneNumber: t('phone'),
  classification: t('classification'),
  title: t('title'),
  role: t('role'),
  groups: t('employee groups'),
  ssoBypass: t('sso bypass')
};

const memberFormFieldOptions = {
  username: {
    validationDebounceWait: 1000
  },
  employeeId: {
    validationDebounceWait: 1000
  }
};

class MemberForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('companyUuid').set(this.rootStore.me.company.uuid);
      }
    );
  }

  trimmedValues() {
    return omit(trimObject(this.values()), ['companyUuid', 'uuid']);
  }
}

export {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormLabels,
  memberFormRules,
  memberFormFieldOptions,
  memberFormPlugins
};

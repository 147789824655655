import { computed, action, observable } from 'mobx';
import request from 'axios';
import UIStore from 'stores/ui/UIStore';
import ProjectsMinimal from 'stores/collections/ProjectsMinimal';
import DirectoryUsersActionsProjectsUI from './DirectoryUsersActionsProjectsUI';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import download from 'utils/download';
import { seatEstimate, seatEstimateTooltip } from 'utils/billing/seatEstimates';

export default class DirectoryUsersActionsUI extends UIStore {
  @observable selectedUser;
  @observable selectedUserAction;
  @observable selectedUserCallback;
  @observable selectedUserRole;

  constructor(options) {
    super(options);

    // Individual Actions
    this.selectedUser = null;
    this.selectedUserAction = null;
    this.selectedUserCallback = null;
    this.selectedUserRole = null;

    // Bulk Actions
    this.selectedUsers = observable([], { deep: false });

    // Bulk Project Actions
    this.directoryUsersActionsProjectsUI = new DirectoryUsersActionsProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk Project Actions
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });
  }

  @computed get users() {
    return this.parent.users;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action showUserProfile(user) {
    history.push(`${user.viewUrl}/info`);
  }

  getUserActions = user => {
    let actions;

    switch (user.status) {
      case 'INVITED':
        actions = [
          {
            title: 'Resend invite',
            disabled: user.lastLogin,
            onClick: () => {
              this.userAction('RESEND_INVITE', user);
            }
          }
        ];
        break;
      case 'INACTIVE':
        actions = [
          {
            title: t('Make active'),
            onClick: () => {
              this.userAction('REACTIVATE', user);
            },
            disabled: user.isMe
          },
          {
            title: t('Delete'),
            onClick: () => {
              this.userAction('DELETE', user);
            },
            disabled: user.isMe
          }
        ];
        break;
      case 'DELETED':
        actions = [
          {
            title: t('Make active'),
            onClick: () => {
              this.userAction('REACTIVATE', user);
            },
            disabled: user.isMe
          }
        ];
        break;
      default:
        actions = [
          {
            title: t('Make inactive'),
            onClick: () => {
              this.userAction('DEACTIVATE', user);
            },
            disabled: user.isMe
          },
          ...(this.authorization.hasTimeClockAccess &&
          !user.settings.blockTimeClock
            ? [
                {
                  title: t('Disable time clock'),
                  onClick: () => {
                    this.userAction('BLOCK', user);
                  }
                }
              ]
            : []),
          ...(this.authorization.hasTimeClockAccess &&
          user.settings.blockTimeClock
            ? [
                {
                  title: t('Enable time clock'),
                  onClick: () => {
                    this.userAction('UNBLOCK', user);
                  }
                }
              ]
            : []),
          {
            title: t('Delete'),
            onClick: () => {
              this.userAction('DELETE', user);
            },
            disabled: user.isMe
          }
        ];
        break;
    }

    return actions;
  };

  @computed get userActionTitle() {
    if (!this.selectedUserAction) return null;

    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Delete user?`);
      case 'DEACTIVATE':
        return t(`Deactivate user?`);
      case 'REACTIVATE':
        return t(`Reactivate user?`);
      case 'RESEND_INVITE':
        return t(`Resend invite?`);
      case 'BLOCK':
        return t(`Block user from time clock?`);
      case 'UNBLOCK':
        return t(`Unblock user from time clock?`);
      default:
        return '';
    }
  }

  @computed get userActionText() {
    if (!this.selectedUserAction) return null;

    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete a user from your account. Doing so will remove their project assignments permanently and allow you to re-use their username. Would you like to proceed?`
        );
      case 'DEACTIVATE':
        return t(
          `You are attempting to deactivate a user. Doing so will remove their access to Raken and open their seat, but will maintain their project assignments in case they need to be reactivated. Would you like to proceed?`
        );
      case 'REACTIVATE':
        return t(
          `You are attempting to reactivate a user. Doing so will take up a seat on your account. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for a user. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      case 'BLOCK':
        return t(
          `You are attempting to block a user from the time clock. Doing so will revoke their access from using the time clock through the mobile application. You can re enable their access at any time. Would you like to proceed?`
        );
      case 'UNBLOCK':
        return t(
          `You are attempting to unblock a user from the time clock. Doing so will give this user access to the time clock through the mobile application. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get userActionButtonColor() {
    switch (this.selectedUserAction) {
      case 'DELETE':
      case 'DEACTIVATE':
      case 'BLOCK':
      case 'UNBLOCK':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get userActionButtonText() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'DEACTIVATE':
        return t(`Deactivate`);
      case 'REACTIVATE':
        return t(`Reactivate`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'BLOCK':
        return t(`Block from time clock`);
      case 'UNBLOCK':
        return t(`Unblock from time clock`);
      default:
        return '';
    }
  }

  @computed get userActionButtonTextActive() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'DEACTIVATE':
        return t(`Deactivating...`);
      case 'REACTIVATE':
        return t(`Reactivating...`);
      case 'RESEND_INVITE':
        return t(`Resening...`);
      case 'BLOCK':
        return t(`Blocking ...`);
      case 'UNBLOCK':
        return t(`Unblocking ...`);
      default:
        return '';
    }
  }

  @computed get userActionNotificationText() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`User deleted`);
      case 'DEACTIVATE':
        return t(`User deactivated`);
      case 'REACTIVATE':
        return t(`User reactivated`);
      case 'RESEND_INVITE':
        return t(`Invite sent`);
      case 'BLOCK':
        return t(`User blocked`);
      case 'UNBLOCK':
        return t(`User unblocked`);
      default:
        return '';
    }
  }

  @action.bound async userAction(action, user, callBack) {
    if (action === 'RESEND_INVITE') {
      await this.authorization.checkFeatureAccess('CUDCompanyInvites');
    } else {
      await this.authorization.checkFeatureAccess('CUDCompanyMembers');
    }

    this.selectedUser = user;
    this.selectedUserAction = action;
    this.selectedUserCallback = callBack;
    this.showModal('UserActionModal');
  }

  @action.bound
  async cancelUserAction() {
    await this.hideActiveModal();
    this.selectedUser = null;
    this.selectedUserAction = null;
    this.selectedUserCallback = null;
  }

  @action.bound
  async confirmUserAction() {
    try {
      this.clearValidationDetails();
      this.saving = true;

      switch (this.selectedUserAction) {
        case 'DELETE':
          await this.selectedUser.save(
            {
              status: 'DELETED'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('DELETED')
          ) {
            this.selectedUser.collection.remove(this.selectedUser);
          }
          break;
        case 'DEACTIVATE':
          await this.selectedUser.save(
            {
              status: 'INACTIVE'
            },
            { wait: true }
          );

          if (
            !this.parent.statusFilters
              .map(option => option.id)
              .includes('INACTIVE')
          ) {
            this.selectedUser.collection.remove(this.selectedUser);
          }
          break;
        case 'REACTIVATE':
          await this.reactivateUser();
          break;
        case 'RESEND_INVITE':
          await this.resendInvite();
          break;
        case 'BLOCK':
        case 'UNBLOCK':
          await this.selectedUser.save(
            {
              settings: {
                blockTimeClock:
                  this.selectedUserAction === 'BLOCK' ? true : false
              }
            },
            { wait: true, stripNonRest: false }
          );
          break;
      }

      this.parent.loading = true;

      if (!this.parent.hasUsers) {
        this.parent.setPage(null, 1);
        this.parent.fetchUsers();
      } else {
        this.parent.fetchUsers();
      }

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.userActionNotificationText
      });

      this.parent.refetchSubscription();

      if (this.selectedUserCallback) {
        this.selectedUserCallback();
      }

      this.selectedUser = null;
      this.selectedUserAction = null;
      this.selectedUserCallback = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async reactivateUser() {
    const showUserProjects = this.selectedUser.status === 'DELETED';

    await this.selectedUser.save(
      {
        status: 'ACTIVE',
        allowSubscriptionUpgrade: true
      },
      {
        wait: true,
        stripNonRest: false
      }
    );

    if (showUserProjects) {
      history.push(`${this.selectedUser.viewUrl}/projects`);
    }
  }

  @action.bound
  async resendInvite() {
    await request.post(`/ra/invite/${this.selectedUser.inviteId}`, {
      companyId: this.selectedUser.company.id
    });
  }

  // Bulk Actions
  @computed get bulkUserActions() {
    let actions = [];

    if (this.hasSelectedUsers) {
      actions.push({
        title: t('Add to projects'),
        onClick: () => {
          this.bulkUserAction('ADD_PROJECTS');
        },
        rakenColor: 'white',
        icon: 'add'
      });
    }

    if (this.hasSelectedInvitedUsers) {
      actions.push({
        title: t('Resend invite'),
        onClick: () => {
          this.bulkUserAction('RESEND_INVITE');
        },
        rakenColor: 'white',
        icon: 'user-add'
      });
    }

    if (this.hasSelectedActiveUsers) {
      actions.push({
        title: t('Delete'),
        onClick: () => {
          this.bulkUserAction('DELETE');
        },
        disabled: this.onlyLoggedInUserIsSelected,
        rakenColor: 'red',
        icon: 'trash'
      });
    }

    return actions;
  }

  @computed get showBulkActions() {
    return (
      this.hasSelectedUsers ||
      this.hasSelectedActiveUsers ||
      this.hasSelectedInvitedUsers
    );
  }

  @computed get hasSelectedUsers() {
    return this.selectedUsers.length > 0;
  }

  @computed get hasNoSelectedUsers() {
    return this.selectedUsers.length === 0;
  }

  @computed get hasSelectedActiveUsers() {
    return this.selectedUsers.filter(model => model.status === 'ACTIVE').length;
  }

  @computed get hasSelectedInvitedUsers() {
    return this.selectedUsers.filter(model => model.status === 'INVITED')
      .length;
  }

  @computed get onlyLoggedInUserIsSelected() {
    return this.selectedUsers.length === 1 && this.selectedUsers[0].isMe;
  }

  @computed get allUsersSelected() {
    if (!this.hasSelectedUsers) return false;

    return (
      this.users.models.filter(user => {
        return this.findSelectedUser(user);
      }).length === this.users.length
    );
  }

  findSelectedUser = user => {
    return this.selectedUsers.find(
      selectedUser => selectedUser.uuid === user.uuid
    );
  };

  @action.bound toggleSelectAllUsers() {
    if (this.allUsersSelected) {
      this.users.models.forEach(user => {
        this.selectedUsers.remove(this.findSelectedUser(user));
      });
    } else {
      this.users.models.forEach(user => {
        if (!this.findSelectedUser(user)) {
          this.selectedUsers.push(user);
        }
      });
    }
  }

  @action.bound toggleSelectUser(user) {
    const selectedUser = this.findSelectedUser(user);

    if (selectedUser) {
      this.selectedUsers.remove(selectedUser);
    } else {
      this.selectedUsers.push(user);
    }
  }

  @action.bound async bulkUserAction(action) {
    switch (action) {
      case 'RESEND_INVITE':
        await this.authorization.checkFeatureAccess('CUDCompanyInvites');
        break;
      default:
        await this.authorization.checkFeatureAccess('CUDCompanyMembers');
        break;
    }

    this.selectedUserAction = action;
    this.showModal('UserBulkActionModal');
  }

  @action.bound async cancelBulkUserAction() {
    await this.hideActiveModal();
    this.selectedUserAction = null;
    this.selectedUserRole = null;
  }

  @computed get bulkUserActionTitle() {
    if (!this.selectedUserAction) return null;

    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Delete users?`);
      case 'RESEND_INVITE':
        return t(`Resend invites?`);
      case 'ADD_PROJECTS':
        return t(`Add to projects`);
      default:
        return '';
    }
  }

  @computed get bulkUserActionText() {
    if (!this.selectedUserAction) return null;

    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(
          `You are attempting to delete one or more users from your account. Doing so will remove their project assignments permanently and allow you to re-use their username. Would you like to proceed?`
        );
      case 'RESEND_INVITE':
        return t(
          `You are attempting to resend the invite for one or more users. Doing so will send them a new invitation email. Would you like to proceed?`
        );
      default:
        return '';
    }
  }

  @computed get bulkUserActionNoOperationText() {
    if (!this.selectedUserAction) return null;

    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(
          `The following users will be ignored - You, the currently logged in user, and any selected inactive or deleted users will be ignored.`
        );
      case 'RESEND_INVITE':
        return t(
          `Any selected users that have accepted their invite will be ignored.`
        );
      default:
        return '';
    }
  }

  @computed get bulkUserActionButtonColor() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return 'red';
      default:
        return 'orange';
    }
  }

  @computed get bulkUserActionButtonDisabled() {
    return this.saving;
  }

  @computed get bulkUserActionButtonText() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Delete`);
      case 'RESEND_INVITE':
        return t(`Resend`);
      case 'ADD_PROJECTS':
        return t(`Save`);
      default:
        return '';
    }
  }

  @computed get bulkUserActionButtonTextActive() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Deleting...`);
      case 'RESEND_INVITE':
        return t(`Resending...`);
      case 'ADD_PROJECTS':
        return t(`Saving...`);
      default:
        return '';
    }
  }

  @computed get bulkUserActionNotificationText() {
    switch (this.selectedUserAction) {
      case 'DELETE':
        return t(`Users deleted`);
      case 'RESEND_INVITE':
        return t(`Invites sent`);
      case 'ADD_PROJECTS':
        return t(`Users added to projects`);
      default:
        return '';
    }
  }

  @computed get bulkUserActionRoleOptions() {
    return this.parent.roleOptions;
  }

  @action.bound setSelectedUserRole(role) {
    this.selectedUserRole = role;
  }

  @action.bound async confirmBulkUserAction() {
    this.clearValidationDetails();
    this.saving = true;

    try {
      switch (this.selectedUserAction) {
        case 'DELETE':
          await this.bulkDelete();
          break;
        case 'RESEND_INVITE':
          await this.bulkResendInvites();
          break;
        case 'ADD_PROJECTS':
          await this.bulkAddProjects();
          break;
      }

      this.parent.loading = true;
      this.parent.setPage(null, 1);
      this.parent.fetchUsers();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.bulkUserActionNotificationText
      });

      this.parent.refetchSubscription();
      this.selectedUsers.clear();
      this.selectedUserAction = null;
      this.selectedUserRole = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async bulkDelete() {
    const payload = this.selectedUsers
      .filter(user => !user.isMe)
      .map(user => {
        return {
          uuid: user.uuid,
          status: 'DELETED'
        };
      });

    return await request.patch(`${this.users.url()}/batch`, payload);
  }

  @action.bound async bulkResendInvites() {
    const requests = [];

    this.selectedUsers
      .filter(user => user.status === 'INVITED')
      .forEach(user => {
        requests.push(
          request.post(`/ra/invite/${user.inviteId}`, {
            companyId: user.company.id
          })
        );
      });

    return await Promise.all(requests);
  }

  @action.bound async bulkRemoveInvites() {
    const payload = this.selectedUsers
      .filter(user => user.status === 'INVITED')
      .map(user => {
        return {
          uuid: user.uuid
        };
      });

    return await request.delete(`${this.users.url()}/batch`, {
      data: payload
    });
  }

  @action.bound async bulkAddProjects() {
    await this.directoryUsersActionsProjectsUI.addUsersToProjects();
  }

  @action.bound
  exportUsers() {
    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/members/csv`,
      xhttpOptions: {
        sendXApiKey: true
      },
      data: {
        uuids: this.selectedUsers.map(user => user.uuid),
        type: 'MEMBER'
      }
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedUser = null;
    this.selectedUserAction = null;
    this.selectedUserCallback = null;
    this.selectedUserRole = null;
    this.selectedUsers.clear();
  }

  @computed get seatEstimate() {
    if (!this.subscription || this.selectedUserAction !== 'REACTIVATE')
      return null;

    return seatEstimate(this.subscription, 1, this.isRecurlyBilling);
  }

  @computed get seatEstimateTooltip() {
    return seatEstimateTooltip(this.subscription, 1, this.isRecurlyBilling);
  }
}

import UIStore from 'stores/ui/UIStore';
import { action, computed, observable, runInAction } from 'mobx';
import orderBy from 'lodash.orderby';
import errorHandler from 'utils/errorHandler';
import Projects from 'stores/collections/Projects';
import request from 'axios';

import ProjectTemplateEditUI from './ProjectTemplateEditUI';
import ProjectTemplateAddUI from './ProjectTemplateAddUI';
import ProjectTemplateSaveAsUI from './ProjectTemplateSaveAsUI';

import {
  companyProjectTemplateForm,
  companyProjectTemplateFormOptions,
  companyProjectTemplateFormFields,
  companyProjectTemplateFormRules,
  companyProjectTemplateFormValues,
  companyProjectTemplateFormPlugins,
  companyProjectTemplateFormLabels
} from 'forms/companyProjectTemplate';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class ProjectTemplatesUI extends UIStore {
  @observable loading;
  @observable applyingTemplates;
  @observable searchTerm;
  @observable sortDirection;
  @observable companyProjectTemplateForm;
  @observable selectedTemplate;
  @observable exitModalShown;
  @observable applyTemplateModalShown;
  @observable preventEditNoticeModal;
  @observable templateToDelete;

  constructor(options) {
    super(options);
    this.loading = true;
    this.applyingTemplates = false;
    this.searchTerm = '';
    this.sortDirection = 'asc';
    this.companyProjectTemplateForm = null;
    this.selectedTemplate = null;
    this.preventEditNoticeModal = false;
    this.copyCounter = 1;
    this.templateToDelete = null;

    // TODO. Eventually we will use ProjectSelectorUI
    // For now this replaces rootStore > projects
    this.projects = new Projects(null, {
      rootStore: this.rootStore
    });

    this.applyTemplateModalShown = false;
    this.selectedProjects = observable([]);

    this.projectTemplateAddUI = new ProjectTemplateAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateEditUI = new ProjectTemplateEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateSaveAsUI = new ProjectTemplateSaveAsUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    this.fetchTemplates();
    this.fetchProjects();
  }

  @action.bound tearDown() {
    this.projects.clear();
    this.projectTemplates.clear();
    this.loading = true;
  }

  @action.bound fetchProjects() {
    this.projects.fetch({
      url: `ra/companies/${this.company.uuid}/projects`,
      params: {
        limit: 10000,
        projectStates: 'ACTIVE'
      }
    });
  }

  @action.bound
  async fetchTemplates() {
    this.loading = true;
    await this.projectTemplates.fetch({ params: { mc: 'full', mn: 'full' } });
    this.loading = false;
  }

  @action.bound
  sortByName() {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
  }

  @action.bound
  showEditTemplateModal(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditProjectTemplates')
      .then(() => {
        runInAction(() => {
          this.selectedTemplate = template;
          this.initCompanyProjectTemplateForm();

          history.push(`/company/project-templates/${template.id}/info`);
        });
      });
  }

  @action.bound
  clearUIState() {
    this.activeModal = null;
    this.saving = false;
    this.currentFormInitialValues = null;
    this.companyProjectTemplateForm = null;
    this.selectedTemplate = null;
    this.clearValidationDetails();
  }

  @computed get existingTemplateNames() {
    if (this.selectedTemplate) {
      return this.projectTemplates.models
        .filter(template => {
          return template.templateName !== this.selectedTemplate.templateName;
        })
        .map(template => {
          // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
          return `${template.templateName.replace('|', '7C')}`;
        });
    }

    return this.projectTemplates.models.map(template => {
      // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
      return `${template.templateName.replace('|', '7C')}`;
    });
  }

  @action.bound
  initCompanyProjectTemplateForm() {
    if (this.selectedTemplate) {
      this.currentFormInitialValues = Object.assign(
        {},
        companyProjectTemplateFormValues,
        this.selectedTemplate.formValues
      );
    } else {
      this.currentFormInitialValues = {
        ...companyProjectTemplateFormValues,
        collaboratorQuestions: companyProjectTemplateFormValues.questions,
        includeMyCompanyQuestionsForSubcontractors: true
      };
    }

    this.companyProjectTemplateForm = new companyProjectTemplateForm(
      {
        fields: companyProjectTemplateFormFields,
        labels: companyProjectTemplateFormLabels,
        rules: Object.assign(companyProjectTemplateFormRules, {
          templateName: `required|string|not_in:${this.existingTemplateNames.join(
            ','
          )}`
        }),
        values: this.currentFormInitialValues
      },
      {
        options: companyProjectTemplateFormOptions,
        plugins: companyProjectTemplateFormPlugins,
        parent: this
      }
    );
  }

  @action.bound deleteTemplate(template) {
    this.templateToDelete = template;

    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        this.showModal('DeleteModal');
      });
  }

  @action.bound async confirmDeleteTemplate() {
    await this.templateToDelete.destroy({ wait: true });

    this.hideActiveModal().then(() => {
      this.templateToDelete = null;
    });
  }

  @action.bound cancelDeleteTemplate() {
    this.hideActiveModal().then(() => {
      this.templateToDelete = null;
    });
  }

  createUniqTemplateName(baseName) {
    const uniqueName = `Copy of ${baseName}`;

    const notUnique = this.projectTemplates.models.find(
      template => template.templateName === uniqueName
    );

    if (notUnique) {
      return this.createUniqTemplateName(uniqueName);
    } else {
      return uniqueName;
    }
  }

  @action.bound
  duplicateProjectTemplate(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        this.projectTemplateSaveAsUI.showSaveAsModal(template);
      });
  }

  @action.bound
  setSearchTerm(value) {
    this.searchTerm = value;
  }

  @action.bound
  clearSearchTerm() {
    this.searchTerm = '';
  }

  @action.bound
  createNewProjectTemplate(e) {
    e.preventDefault();
    this.rootStore.authorizationUI
      .checkFeatureAccess('CreateProjectTemplates')
      .then(() => {
        this.initCompanyProjectTemplateForm();

        history.push(`/company/project-templates/add`);
      });
  }

  @action.bound
  setTemplateAsDefault(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('EditProjectTemplates')
      .then(() => {
        template
          .save(
            {
              isDefault: true
            },
            {
              wait: true
            }
          )
          .then(() => {
            runInAction(() => {
              this.projectTemplates.models
                .filter(
                  projectTemplate =>
                    projectTemplate.isDefault && projectTemplate !== template
                )
                .forEach(template => {
                  template.isDefault = false;
                });
            });
          });
      });
  }

  @computed
  get sortedTemplates() {
    return orderBy(
      this.projectTemplates.models,
      [projectTemplate => projectTemplate.templateName.toLowerCase()],
      [this.sortDirection]
    );
  }

  @computed
  get shownTemplates() {
    if (!this.searchTerm) {
      return this.sortedTemplates;
    }

    return this.sortedTemplates.filter(
      template =>
        template.templateName
          .toLowerCase()
          .indexOf(this.searchTerm.toLowerCase()) > -1
    );
  }

  @computed get hasShownTemplates() {
    return this.shownTemplates.length > 0;
  }

  //Apply a template to projects
  @computed
  get sortedProjects() {
    return orderBy(
      this.projects.models.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      }),
      [project => project.name.toLowerCase()],
      ['asc']
    );
  }

  @action.bound
  showApplyTemplateModal(template) {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ApplyProjectTemplates')
      .then(() => {
        runInAction(() => {
          this.selectedTemplate = template;
          this.applyTemplateModalShown = true;
        });
      });
  }

  @action.bound
  hideApplyTemplateModal() {
    this.applyTemplateModalShown = false;
  }

  @action.bound
  clearApplyTemplateModalState() {
    this.selectedTemplate = null;
    this.selectedProjects.clear();
  }

  @computed
  get allActiveProjectsSelected() {
    return this.selectedProjects.length === this.sortedProjects.length;
  }

  @action.bound
  toggleAllActiveProjects() {
    if (this.allActiveProjectsSelected) {
      this.selectedProjects.clear();
    } else {
      this.selectedProjects.replace([...this.sortedProjects]);
    }
  }

  @action.bound
  setSelectedProjects(selectedProjects) {
    this.selectedProjects.replace([...selectedProjects]);
  }

  @action.bound
  async applyTemplateToSelectedProjects() {
    const payload = {
      templateId: this.selectedTemplate.id,
      projectUuids: this.selectedProjects.map(project => project.value)
    };

    try {
      this.applyingTemplates = true;
      await request.patch('/ra/projects', payload);

      this.hideApplyTemplateModal();
      this.applyingTemplates = false;

      this.notifications.pushNotification({
        showUndo: false,
        title: t('Apply Template'),
        text: t(
          'The survey section for this template will be applied to your project(s) starting tomorrow.'
        )
      });
    } catch (error) {
      this.applyingTemplates = false;
      errorHandler(error, this.notifications.pushError);
    }
  }
}

import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';
import extendedPlugins from './utils/extendedPlugins';

const oshaSubmit300AFormPlugins = extendedPlugins;

const oshaSubmit300AFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const oshaSubmit300AFormFields = [
  'annualAverageEmployees',
  'totalHoursWorked',
  'noInjuriesIllnesses',
  'totalDeaths',
  'totalDAFWCases',
  'totalDJTRCases',
  'totalOtherCases',
  'totalDAFWDays',
  'totalDJTRDays',
  'totalInjuries',
  'totalSkinDisorders',
  'totalRespiratoryConditions',
  'totalPoisonings',
  'totalHearingLoss',
  'totalOtherIllnesses'
];

const oshaSubmit300AFormLabels = {
  annualAverageEmployees: t('annual average employees'),
  totalHoursWorked: t('total hours worked'),
  noInjuriesIllnesses: t('no injuries illnesses'),
  totalDeaths: t('total deaths'),
  totalDAFWCases: t('total days away from work cases'),
  totalDJTRCases: t('total days of job transfer or restriction'),
  totalOtherCases: t('total other cases'),
  totalDAFWDays: t('total days away from work days'),
  totalDJTRDays: t('total days of job transfer or restriction'),
  totalInjuries: t('total injuries'),
  totalSkinDisorders: t('total skin disorders'),
  totalRespiratoryConditions: t('total respiratory conditions'),
  totalPoisonings: t('total poisonings'),
  totalHearingLoss: t('total hearing loss'),
  totalOtherIllnesses: t('total other illnesses')
};

const oshaSubmit300AFormRules = {
  annualAverageEmployees: 'numeric',
  totalHoursWorked: 'numeric',
  noInjuriesIllnesses: 'numeric',
  totalDeaths: 'numeric',
  totalDAFWCases: 'numeric',
  totalDJTRCases: 'numeric',
  totalOtherCases: 'numeric',
  totalDAFWDays: 'numeric',
  totalDJTRDays: 'numeric',
  totalInjuries: 'numeric',
  totalSkinDisorders: 'numeric',
  totalRespiratoryConditions: 'numeric',
  totalPoisonings: 'numeric',
  totalHearingLoss: 'numeric',
  totalOtherIllnesses: 'numeric'
};

const oshaSubmit300AFormValues = {
  annualAverageEmployees: 0,
  totalHoursWorked: 0,
  noInjuriesIllnesses: 0,
  totalDeaths: 0,
  totalDAFWCases: 0,
  totalDJTRCases: 0,
  totalOtherCases: 0,
  totalDAFWDays: 0,
  totalDJTRDays: 0,
  totalInjuries: 0,
  totalSkinDisorders: 0,
  totalRespiratoryConditions: 0,
  totalPoisonings: 0,
  totalHearingLoss: 0,
  totalOtherIllnesses: 0
};

class OshaSubmit300AForm extends MobxReactForm {}

export {
  OshaSubmit300AForm,
  oshaSubmit300AFormOptions,
  oshaSubmit300AFormFields,
  oshaSubmit300AFormValues,
  oshaSubmit300AFormLabels,
  oshaSubmit300AFormRules,
  oshaSubmit300AFormPlugins
};

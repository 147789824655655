import { t } from 'utils/translate';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import { insertIf } from 'utils/insertIf';

export default class ProjectNavigationUI extends UIStore {
  @computed get projectViewUrl() {
    return this.parent.projectViewUrl;
  }

  @computed get hasProject() {
    return this.parent.hasProject;
  }

  @computed get items() {
    return {
      id: 'projects',
      icon: 'projects',
      label: t('Projects'),
      link: '/projects',
      subnav: [
        ...(!this.hasProject || this.parent.isMobileScreen
          ? [
              {
                id: 'list',
                label: t('List'),
                icon: 'list',
                link: '/projects'
              },
              {
                id: 'map',
                label: t('Map'),
                icon: 'map',
                links: [
                  {
                    label: t('Projects'),
                    exact: true,
                    path: {
                      pathname: `/map`
                    }
                  },
                  {
                    label: t('Employees'),
                    path: {
                      pathname: `/map/workers`
                    }
                  }
                ]
              }
            ]
          : [
              {
                id: 'daily-log',
                label: t('Daily logs'),
                icon: 'daily-log',
                links: [
                  {
                    label: t('Work logs'),
                    path: {
                      pathname: `${this.projectViewUrl}/worklogs`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Notes'),
                    path: {
                      pathname: `${this.projectViewUrl}/notes`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Attachments'),
                    path: {
                      pathname: `${this.projectViewUrl}/attachments`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Survey'),
                    path: {
                      pathname: `${this.projectViewUrl}/survey`,
                      search: this.projectUI.baseQueryParams
                    }
                  }
                ]
              },
              {
                id: 'production',
                label: t('Production'),
                icon: 'project-production',
                links: [
                  {
                    label: t('Time cards'),
                    path: {
                      pathname: `${this.projectViewUrl}/timecards`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Materials'),
                    path: {
                      pathname: `${this.projectViewUrl}/materials`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Equipment'),
                    path: {
                      pathname: `${this.projectViewUrl}/equipment`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  ...(this.authorization.canViewProductionInsightsWithPermission
                    ? [
                        {
                          label: t('Insights'),
                          path: {
                            pathname: `${this.projectViewUrl}/production`,
                            search: this.projectUI.baseQueryParams
                          }
                        }
                      ]
                    : []),
                  ...(this.authorization.onPerformancePlan
                    ? [
                        {
                          label: t('Map'),
                          path: {
                            pathname: `${this.projectViewUrl}/map`,
                            search: this.projectUI.baseQueryParams
                          }
                        }
                      ]
                    : [])
                ]
              },
              {
                id: 'safety',
                label: 'Safety & QC',
                icon: 'safety',
                links: [
                  {
                    label: t('Checklists'),
                    path: {
                      pathname: `${this.projectViewUrl}/checklists`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Toolbox talks'),
                    path: {
                      pathname: `${this.projectViewUrl}/toolbox-talks`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Observations'),
                    path: {
                      pathname: `${this.projectViewUrl}/observations`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Incidents'),
                    path: {
                      pathname: `${this.projectViewUrl}/incidents`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Insights'),
                    path: {
                      pathname: `${this.projectViewUrl}/safety-insights`,
                      search: this.projectUI.baseQueryParams
                    }
                  }
                ]
              },
              {
                id: 'tasks',
                label: t('Tasks'),
                icon: 'tasks',
                link: `${this.projectViewUrl}/tasks`
              },
              {
                id: 'forms',
                label: t('Forms'),
                icon: 'forms',
                link: `${this.projectViewUrl}/forms`
              },
              {
                id: 'documents',
                label: t('Documents'),
                icon: 'documents',
                link: `${this.projectViewUrl}/documents`
              },
              {
                id: 'project-dashboard',
                label: t('Dashboard'),
                icon: 'dashboard',
                links: [
                  {
                    label: t('Activity'),
                    path: {
                      pathname: `${this.projectViewUrl}/dashboard/activity`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Insights'),
                    path: {
                      pathname: `${this.projectViewUrl}/dashboard/insights`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  ...insertIf(
                    !this.authorization.disableIntegrationsForTrialAccount,
                    [
                      {
                        label: t('Live views'),
                        path: {
                          pathname: `${this.projectViewUrl}/dashboard/live-views`,
                          search: this.projectUI.baseQueryParams
                        }
                      }
                    ]
                  )
                ]
              },
              {
                id: 'project-directory',
                label: t('Project directory'),
                icon: 'users',
                links: [
                  {
                    label: t('Team members'),
                    path: {
                      pathname: `${this.projectViewUrl}/directory/team`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Workers'),
                    path: {
                      pathname: `${this.projectViewUrl}/directory/workers`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Collaborators'),
                    path: {
                      pathname: `${this.projectViewUrl}/directory/collaborators`,
                      search: this.projectUI.baseQueryParams
                    }
                  }
                ]
              },
              {
                id: 'compliance',
                label: t('Compliance'),
                icon: 'box',
                links: [
                  {
                    label: t('Completed'),
                    path: {
                      pathname: `${this.projectViewUrl}/compliance/completed`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Missed'),
                    path: {
                      pathname: `${this.projectViewUrl}/compliance/missed`,
                      search: this.projectUI.baseQueryParams
                    }
                  }
                ]
              },
              {
                id: 'projects-gallery',
                label: t('Gallery'),
                icon: 'gallery',
                link: `${this.projectViewUrl}/gallery`
              },
              {
                id: 'settings',
                label: t('Settings'),
                icon: 'settings',
                links: [
                  {
                    label: t('General'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/general`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Daily reporting'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/daily-reporting`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  ...insertIf(!this.company.hideLegacySettings, [
                    {
                      label: t('Payroll and time'),
                      path: {
                        pathname: `${this.projectViewUrl}/settings/payroll-time`,
                        search: this.projectUI.baseQueryParams
                      }
                    }
                  ]),
                  {
                    label: t('Production'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/production-tracking`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Integrations'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/integrations`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Notifications'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/notifications`,
                      search: this.projectUI.baseQueryParams
                    }
                  },
                  {
                    label: t('Photos'),
                    path: {
                      pathname: `${this.projectViewUrl}/settings/photos`,
                      search: this.projectUI.baseQueryParams
                    }
                  }
                ]
              }
            ])
      ]
    };
  }
}

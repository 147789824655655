import request from 'axios';
import { computed, observable, action, reaction, runInAction } from 'mobx';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  SmallEquipmentProjectActionForm,
  smallEquipmentProjectActionFormOptions,
  smallEquipmentProjectActionFormFields,
  smallEquipmentProjectActionFormRules,
  smallEquipmentProjectActionFormValues,
  smallEquipmentProjectActionFormLabels,
  smallEquipmentProjectActionFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentProjectAction';

import {
  SmallEquipmentBulkDeploymentForm,
  smallEquipmentBulkDeploymentFormOptions,
  smallEquipmentBulkDeploymentFormFields,
  smallEquipmentBulkDeploymentFormRules,
  smallEquipmentBulkDeploymentFormLabels,
  smallEquipmentBulkDeploymentFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentBulkDeployment';

import SmallEquipmentActionsUI from 'stores/ui/company/equipment/smallEquipment/SmallEquipmentActionsUI';

import ProjectSmallEquipmentWithdrawUI from './ProjectSmallEquipmentWithdrawUI';
import ProjectSmallEquipmentLostStolenUI from './ProjectSmallEquipmentLostStolenUI';

import SmallEquipment from 'stores/collections/equipment/SmallEquipment';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';

import { callTrack } from 'utils/segmentIntegration';
import {
  SMALL_EQUIPMENT_DEPLOYED,
  SMALL_EQUIPMENT_WITHDRAWN,
  SMALL_EQUIPMENT_LOST
} from 'utils/segmentAnalytics/eventSpec';

export default class ProjectSmallEquipmentActionsUI extends SmallEquipmentActionsUI {
  @observable smallEquipmentBulkDeploymentForm;
  @observable showSearchBulkDeployment;
  @observable searchQuery;

  constructor(options) {
    super(options);

    // Withdraw
    this.smallEquipmentWithdrawUI = new ProjectSmallEquipmentWithdrawUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Lost Stolen
    this.smallEquipmentLostStolenUI = new ProjectSmallEquipmentLostStolenUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Small equipment collection
    this.companySmallEquipment = new SmallEquipment(null, {
      parent: this,
      rootStore: this.rootStore
    });

    // Bulk Actions
    this.selectedSmallEquipment = observable([], { deep: false });

    this.smallEquipmentBulkDeploymentForm = null;
    this.showSearchBulkDeployment = false;

    this.searchQuery = '';

    this.fetchCompanySmallEquipmentDebounced = debounce(
      this.fetchCompanySmallEquipment,
      BASE_DEBOUNCE
    );
  }

  getSmallEquipmentActions = smallEquipment => {
    return [
      {
        title: t('Add units'),
        onClick: () => {
          this.deploySmallEquipment(smallEquipment);
        }
      },
      {
        title: t('Withdraw units'),
        onClick: () => {
          this.withdrawSmallEquipment(smallEquipment);
        }
      },
      {
        title: t('Report lost / stolen'),
        onClick: () => {
          this.reportLostStolenSmallEquipment(smallEquipment);
        }
      }
    ];
  };

  @computed get bulkSmallEquipmentActions() {
    return [
      {
        title: t('Deploy'),
        onClick: () => {
          this.bulkDeploySmallEquipment();
        }
      },
      {
        title: t('Withdraw'),
        onClick: () => {
          this.smallEquipmentWithdrawUI.bulkWithdrawSmallEquipment();
        }
      },
      {
        title: t('Report lost / stolen'),
        onClick: () => {
          this.smallEquipmentLostStolenUI.markLostStolenSmallEquipment();
        }
      }
    ];
  }

  // Checkbox selection
  @computed get smallEquipment() {
    return this.parent.smallEquipment;
  }

  @computed get hasSelectedSmallEquipment() {
    return this.selectedSmallEquipment.length > 0;
  }

  @computed get hasNoSelectedSmallEquipment() {
    return this.selectedSmallEquipment.length === 0;
  }

  @computed get allSmallEquipmentSelected() {
    if (!this.hasSelectedSmallEquipment) return false;

    return (
      this.smallEquipment.models.filter(smallEquipment => {
        return this.findSelectedSmallEquipment(smallEquipment);
      }).length === this.smallEquipment.length
    );
  }

  findSelectedSmallEquipment = smallEquipment => {
    return this.selectedSmallEquipment.find(
      selectedSmallEquipment =>
        selectedSmallEquipment?.equipmentUuid === smallEquipment.equipmentUuid
    );
  };

  @action.bound toggleSelectAllSmallEquipment() {
    if (this.allSmallEquipmentSelected) {
      this.smallEquipment.models.forEach(smallEquipment => {
        this.selectedSmallEquipment.remove(
          this.findSelectedSmallEquipment(smallEquipment)
        );
      });
    } else {
      this.smallEquipment.models.forEach(smallEquipment => {
        if (!this.findSelectedSmallEquipment(smallEquipment)) {
          this.selectedSmallEquipment.push(smallEquipment);
        }
      });
    }
  }

  @action.bound toggleSelectSmallEquipment(smallEquipment) {
    const selectedSmallEquipment = this.findSelectedSmallEquipment(
      smallEquipment
    );

    if (selectedSmallEquipment) {
      this.selectedSmallEquipment.remove(selectedSmallEquipment);
    } else {
      this.selectedSmallEquipment.push(smallEquipment);
    }
  }

  /**
   * Single Deploy
   */

  @action.bound
  async deploySmallEquipment(smallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.smallEquipmentToUpdate = {
      ...smallEquipment,
      uuid: smallEquipment.equipmentUuid
    };

    this.smallEquipmentDeploymentForm = new SmallEquipmentProjectActionForm(
      {
        fields: smallEquipmentProjectActionFormFields,
        rules: {
          ...smallEquipmentProjectActionFormRules,
          numberOfUnits: `required|numeric|min:0|max:${smallEquipment.availableUnits}`
        },
        labels: smallEquipmentProjectActionFormLabels,
        values: {
          ...smallEquipmentProjectActionFormValues,
          project: {
            name: smallEquipment.projectName,
            value: smallEquipment.projectUuid
          }
        }
      },
      {
        options: smallEquipmentProjectActionFormOptions,
        plugins: smallEquipmentProjectActionFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('SmallEquipmentDeploymentModal');
  }

  /**
   * Single Withdraw
   */

  @action.bound async withdrawSmallEquipment(smallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.smallEquipmentToUpdate = {
      ...smallEquipment,
      uuid: smallEquipment.equipmentUuid
    };

    this.smallEquipmentWithdrawForm = new SmallEquipmentProjectActionForm(
      {
        fields: smallEquipmentProjectActionFormFields,
        rules: {
          ...smallEquipmentProjectActionFormRules,
          numberOfUnits: `required|numeric|min:0|max:${smallEquipment.projectDeployedUnits}`
        },
        labels: smallEquipmentProjectActionFormLabels,
        values: {
          ...smallEquipmentProjectActionFormValues,
          project: {
            name: smallEquipment.projectName,
            value: smallEquipment.projectUuid
          }
        }
      },
      {
        options: smallEquipmentProjectActionFormOptions,
        plugins: smallEquipmentProjectActionFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('SmallEquipmentWithdrawModal');
  }

  @action.bound async cancelWithdrawSmallEquipment() {
    await this.hideActiveModal();

    this.smallEquipmentToUpdate = null;
    this.smallEquipmentWithdrawForm = null;
  }

  @action.bound submitWithdrawSmallEquipmentForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.smallEquipmentWithdrawForm.submit({
      onSuccess: this.submitSmallEquipmentWithdrawFormSuccess,
      onError: this.submitSmallEquipmentWithdrawFormError
    });
  }

  @action.bound async submitSmallEquipmentWithdrawFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentWithdrawForm.values();

    try {
      await request.post(
        `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/projects/${values.project.value}/smallequipment/${this.smallEquipmentToUpdate.uuid}/withdraw`,
        {
          quantity: values.numberOfUnits
        }
      );

      callTrack(SMALL_EQUIPMENT_WITHDRAWN, {
        small_equipment_name: this.smallEquipmentToUpdate.equipmentName,
        units_withdrawn: values.numberOfUnits
      });

      this.parent.refetchSmallEquipment();

      this.cancelWithdrawSmallEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment withdrawn.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitSmallEquipmentWithdrawFormError() {
    console.error(this.smallEquipmentWithdrawForm.errors());
  }

  /**
   * Single Lost / Stolen
   */

  @action.bound async reportLostStolenSmallEquipment(smallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.smallEquipmentToUpdate = {
      ...smallEquipment,
      uuid: smallEquipment.equipmentUuid
    };

    this.smallEquipmentLostStolenForm = new SmallEquipmentProjectActionForm(
      {
        fields: smallEquipmentProjectActionFormFields,
        rules: {
          ...smallEquipmentProjectActionFormRules,
          numberOfUnits: `required|numeric|min:0|max:${smallEquipment.projectDeployedUnits}`
        },
        labels: smallEquipmentProjectActionFormLabels,
        values: {
          ...smallEquipmentProjectActionFormValues,
          project: {
            name: smallEquipment.projectName,
            value: smallEquipment.projectUuid
          }
        }
      },
      {
        options: smallEquipmentProjectActionFormOptions,
        plugins: smallEquipmentProjectActionFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('SmallEquipmentLostStolenModal');
  }

  @action.bound async cancelLostStolenSmallEquipment() {
    await this.hideActiveModal();

    this.smallEquipmentToUpdate = null;
    this.smallEquipmentLostStolenForm = null;
  }

  @action.bound submitLostStolenSmallEquipmentForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.smallEquipmentLostStolenForm.submit({
      onSuccess: this.submitSmallEquipmentLostStolenFormSuccess,
      onError: this.submitSmallEquipmentLostStolenFormError
    });
  }

  @action.bound async submitSmallEquipmentLostStolenFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentLostStolenForm.values();

    try {
      await request.post(
        `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/projects/${values.project.value}/smallequipment/${this.smallEquipmentToUpdate.uuid}/writeoff`,
        {
          quantity: values.numberOfUnits
        }
      );

      callTrack(SMALL_EQUIPMENT_LOST, {
        small_equipment_name: this.smallEquipmentToUpdate.equipmentName,
        units_withdrawn: values.numberOfUnits
      });

      this.parent.refetchSmallEquipment();

      this.cancelLostStolenSmallEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment reported lost stolen.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitSmallEquipmentLostStolenFormError() {
    console.error(this.smallEquipmentLostStolenForm.errors());
  }

  /**
   * Bulk  Deployment
   */

  @action.bound async bulkDeploySmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.showSearchBulkDeployment = false;

    this.smallEquipmentBulkDeploymentForm = new SmallEquipmentBulkDeploymentForm(
      {
        fields: smallEquipmentBulkDeploymentFormFields,
        rules: smallEquipmentBulkDeploymentFormRules,
        labels: smallEquipmentBulkDeploymentFormLabels,
        values: {
          project: { name: this.project.name, value: this.project.uuid },
          deployments: this.selectedSmallEquipment.map(smallEquipment => ({
            uuid: smallEquipment.equipmentUuid,
            name: smallEquipment.equipmentName,
            totalUnits: smallEquipment.projectDeployedUnits,
            availableUnits: smallEquipment.availableUnits,
            numberOfUnits: 0
          }))
        }
      },
      {
        options: smallEquipmentBulkDeploymentFormOptions,
        plugins: smallEquipmentBulkDeploymentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.setSmallEquipmentBulkDeploymentValidation();

    this.showModal('SmallEquipmentBulkDeploymentModal');
  }

  @action.bound setSmallEquipmentBulkDeploymentValidation() {
    this.smallEquipmentBulkDeploymentForm
      .$('deployments')
      .fields.forEach(deployment =>
        deployment
          .$('numberOfUnits')
          .set(
            'rules',
            `numeric|min:0|max:${deployment.$('availableUnits').value}`
          )
      );
  }

  @action.bound async submitSmallEquipmentBulkDeploymentFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentBulkDeploymentForm.values();

    try {
      await request.post(
        `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/projects/${values.project.value}/smallequipment/deploy/batch`,
        values.deployments
          .map(deployment => ({
            equipmentUuid: deployment.uuid,
            quantity: Number(deployment.numberOfUnits)
          }))
          .filter(deployment => deployment.quantity > 0)
      );

      values.deployments.forEach(deployment => {
        callTrack(SMALL_EQUIPMENT_DEPLOYED, {
          small_equipment_name: deployment.name,
          units_deployed: deployment.numberOfUnits
        });
      });

      this.parent.refetchSmallEquipment();
      this.cancelBulkDeploySmallEquipment();

      this.clearUIState();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment deployed.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @action.bound async bulkDeployAllSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');
    this.showSearchBulkDeployment = true;

    this.showModal('SmallEquipmentBulkDeploymentModal');

    this.setupReactions();

    await this.fetchCompanySmallEquipment();
  }

  @action.bound async cancelBulkDeploySmallEquipment() {
    await this.hideActiveModal();
    this.tearDownReactions();
    this.smallEquipmentBulkDeploymentForm = null;
    this.showSearchBulkDeployment = false;
    this.searchQuery = '';
  }

  @action.bound async fetchCompanySmallEquipment() {
    this.companySmallEquipment.cancelRequest();
    this.companySmallEquipment.reset();

    this.smallEquipmentBulkDeploymentForm = null;

    try {
      await this.companySmallEquipment.fetch({
        params: this.params
      });

      this.setupSmallEquipmentDeploymentForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound setupSmallEquipmentDeploymentForm() {
    this.smallEquipmentBulkDeploymentForm = new SmallEquipmentBulkDeploymentForm(
      {
        fields: smallEquipmentBulkDeploymentFormFields,
        rules: smallEquipmentBulkDeploymentFormRules,
        labels: smallEquipmentBulkDeploymentFormLabels,
        values: {
          project: { name: this.project.name, value: this.project.uuid },
          deployments: this.companySmallEquipment.models.map(model => {
            return {
              availableUnits: model.availableUnits,
              name: model.name,
              uuid: model.uuid,
              totalUnits: model.totalUnits,
              numberOfUnits: 0
            };
          })
        }
      },
      {
        options: smallEquipmentBulkDeploymentFormOptions,
        plugins: smallEquipmentBulkDeploymentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.setSmallEquipmentBulkDeploymentValidation();
  }

  // Setting up search
  @computed
  get params() {
    return {
      limit: 1000,
      offset: 0,
      query: this.searchQuery,
      sortField: 'name',
      sortDirection: 'asc'
    };
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchCompanySmallEquipmentDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }
}

import { action, computed, observable } from 'mobx';

// TODO Change this to be  survey specific form
import {
  ProjectTemplateSurveyForm,
  projectTemplateSurveyFormOptions,
  projectTemplateSurveyFormFields,
  projectTemplateSurveyFormRules,
  projectTemplateSurveyFormPlugins
} from 'forms/projectTemplates/projectTemplateSurvey';

import {
  QuestionForm,
  questionFormOptions,
  questionFormFields,
  questionFormRules,
  questionFormPlugins
} from 'forms/question';

import { t } from 'utils/translate';
import arrayMove from 'utils/arrayMove';

import uuid from 'uuid-v4';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

import { questionCategoriesData } from 'fixtures/collections';

export default class ProjectTemplateSurveyUI extends ProjectTemplateChildEditUI {
  @observable form;
  @observable questionForm;
  @observable questionsTab;

  constructor(options) {
    super(options);

    this.form = null;
    this.questionForm = null;
    this.questionsTab = 'questions';

    this.saveTitle = t('Project template survey saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.form = null;
    this.questionForm = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateSurveyForm(
      {
        fields: projectTemplateSurveyFormFields,
        rules: projectTemplateSurveyFormRules,
        values: this.selectedTemplate.surveyFormValues
      },
      {
        options: projectTemplateSurveyFormOptions,
        plugins: projectTemplateSurveyFormPlugins,
        parent: this
      }
    );

    this.questionForm = new QuestionForm(
      {
        fields: questionFormFields,
        rules: questionFormRules
      },
      {
        options: questionFormOptions,
        plugins: questionFormPlugins
      }
    );
  }

  @computed
  get categoryOptions() {
    return questionCategoriesData.collection.map(category => {
      return {
        value: category.value,
        name: category.displayName
      };
    });
  }

  @computed
  get questionValues() {
    return this.form.$(this.questionsTab).values();
  }

  @computed
  get questionIsInValid() {
    return (
      this.questionForm.$('category').check('hasError') ||
      this.questionForm.$('question').check('hasError') ||
      this.questionForm.$('category').check('isPristine') ||
      this.questionForm.$('question').check('isPristine')
    );
  }

  @computed
  get onBlur() {
    return () => {
      if (this.questionForm.$('question').check('isEmpty')) {
        this.resetQuestionForm();
      }
    };
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.setupForm();
  }

  @action.bound
  resetQuestionForm() {
    this.questionForm.$('question').reset();
    this.questionForm.$('question').resetValidation();
    this.questionForm.$('category').reset();
    this.questionForm.$('category').resetValidation();
  }

  @action.bound
  showCollaboratorQuestions() {
    this.questionsTab = 'collaboratorQuestions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @action.bound
  showQuestions() {
    this.questionsTab = 'questions';
    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @computed get title() {
    return t('Survey questions');
  }

  @action.bound
  handleAddNewQuestion() {
    const { includeMyCompanyQuestionsForSubcontractors } = this.form.values();
    const UUID = uuid();

    if (this.questionIsInValid) return;

    const values = this.form.$(this.questionsTab).values();

    values.unshift(
      Object.assign({}, this.questionForm.values(), {
        uuid: UUID,
        readOnly: false
      })
    );

    this.form.update({
      [this.questionsTab]: values
    });

    if (
      this.questionsTab === 'questions' &&
      includeMyCompanyQuestionsForSubcontractors
    ) {
      const values = this.form.$('collaboratorQuestions').values();

      values.unshift(
        Object.assign({}, this.questionForm.values(), {
          uuid: UUID,
          readOnly: false
        })
      );

      this.form.update({
        collaboratorQuestions: values
      });
    }

    this.questionForm.clear();
    this.questionForm.$('category').resetValidation();
    this.questionForm.$('question').resetValidation();
  }

  @action.bound
  handleDeleteQuestion(question) {
    const { includeMyCompanyQuestionsForSubcontractors } = this.form.values();

    if (
      this.questionsTab === 'questions' &&
      includeMyCompanyQuestionsForSubcontractors
    ) {
      const values = this.form
        .$('collaboratorQuestions')
        .value.filter(
          collaboratorQuestion =>
            !(
              collaboratorQuestion.category === question.$('category').value &&
              collaboratorQuestion.question === question.$('question').value
            )
        );

      this.form.update({
        collaboratorQuestions: values
      });
    }

    this.form.$(this.questionsTab).del(question.key);
  }

  @action.bound
  handleChangeTab(event, newValue) {
    if (newValue === 'questions') {
      this.showQuestions();
    } else if (newValue === 'collaboratorQuestions') {
      this.showCollaboratorQuestions();
    }
  }

  @action.bound
  onSortEnd({ oldIndex, newIndex }) {
    const newValues = arrayMove(this.questionValues, oldIndex, newIndex);
    this.form.update({
      [this.questionsTab]: newValues
    });
  }
}

import { createContext } from 'react';
import SuperAdminRootStore from 'stores/SuperAdminRootStore';
import CompanyRootStore from 'stores/CompanyRootStore';

let store;

if (window.appConfig.isSuperAdmin) {
  store = new SuperAdminRootStore();
} else {
  store = new CompanyRootStore();
}

const RootStoreContext = createContext(store);

export { RootStoreContext, store };

import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { t } from 'utils/translate';

const scheduleTalkFormPlugins = { dvr: validatorjs };

const scheduleTalkFormOptions = {
  validateOnInit: true,
  validateOnChange: true,
  strictUpdate: false
};

const scheduleTalkFormFields = [
  'name',
  'date',
  'fromDate',
  'groups[]',
  'groups[].name',
  'groups[].uuid',
  'defaultGroup'
];

const scheduleTalkFormRules = {
  name: 'string',
  date: 'required',
  fromDate: 'string',
  groups: 'array',
  'groups[].name': 'string',
  'groups[].uuid': 'string',
  defaultGroup: 'string'
};

const scheduleTalkFormLabels = {
  name: t('Project'),
  date: t('Schedule date')
};

const scheduleTalkFormValues = {
  fromDate: ''
};

class ScheduleTalkForm extends MobxReactForm {}

export {
  ScheduleTalkForm,
  scheduleTalkFormOptions,
  scheduleTalkFormFields,
  scheduleTalkFormRules,
  scheduleTalkFormLabels,
  scheduleTalkFormPlugins,
  scheduleTalkFormValues
};

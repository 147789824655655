import moment from 'moment';
import request from 'axios';
import { computed, action, observable, reaction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import SuperAdminAuditEntries from 'stores/collections/superAdmin/SuperAdminAuditEntries';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import {
  TimeFrameForm,
  timeFrameFormOptions,
  timeFrameFormFields,
  timeFrameFormRules,
  timeFrameFormLabels,
  timeFrameFormPlugins
} from 'forms/timeFrame';

export default class SuperAdminIntegrationDebugUI extends UIStore {
  @observable fetchingAuditEntries;

  @observable activeAuditLogEntry;

  @observable auditEntryPage;
  @observable auditEntryPageSize;

  @observable replayingCommands;
  @observable commandReplaySuccessful;

  @observable timeFrameForm;
  @observable searchQuery;
  @observable lastUpdated;
  @observable lastUpdatedFromDate;
  @observable lastUpdatedToDate;

  @observable sortField;
  @observable sortDirection;

  constructor(options) {
    super(options);

    this.replayingCommands = false;
    this.commandReplaySuccessful = false;

    this.searchQuery = '';
    this.timeFrameForm = null;
    this.lastUpdated = null;
    this.lastUpdatedFromDate = null;
    this.lastUpdatedToDate = null;

    this.commandTypes = observable([]);
    this.statuses = observable([]);
    this.dataObjectCodes = observable([]);
    this.actionCodes = observable([]);
    this.entityTypes = observable([]);

    this.selectedAuditEntries = observable([]);

    this.fetchingAuditEntries = true;
    this.auditEntryPageSize = 20;
    this.auditEntryPage = 1;

    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';

    this.auditEntries = new SuperAdminAuditEntries(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.activeAuditLogEntry = null;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchAuditEntries();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.cancelReactToAuditTablePage = reaction(
      () => this.auditEntryPage,
      auditEntryPage => {
        this.fetchAuditEntries();
      }
    );

    this.cancelReactToAuditEntryFilterParams = reaction(
      () => this.auditEntryFilterParams,
      statuses => {
        this.fetchAuditEntries();
      }
    );

    this.cancelReactToAuditEntrySortParams = reaction(
      () => this.auditEntrySortParams,
      statuses => {
        this.fetchAuditEntries();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.cancelReactToAuditTablePage && this.cancelReactToAuditTablePage();
    this.cancelReactToAuditEntryFilterParams &&
      this.cancelReactToAuditEntryFilterParams();
    this.cancelReactToAuditEntrySortParams &&
      this.cancelReactToAuditEntrySortParams();
  }

  @computed get auditEntryFilterParams() {
    const params = [];

    if (this.searchQuery) {
      params.push({
        match: {
          'commandId.keyword': this.searchQuery
        }
      });
    }

    if (this.lastUpdated?.id && this.lastUpdatedRange) {
      params.push({
        range: {
          updatedTimestamp: this.lastUpdatedRange
        }
      });
    }

    if (this.commandTypes.length) {
      params.push({
        bool: {
          should: this.commandTypes.map(commandType => {
            const must = [
              {
                match: {
                  'command.type': commandType.id
                }
              }
            ];
            // action: SEND_REPORT/SEND_ATTACHMENT
            if (commandType.action !== undefined) {
              must.push({
                match: {
                  'command.action': commandType.action
                }
              });
            }
            // list of extra properties/filters on command level (data[*])
            ['Active', 'active', 'STATUS'].forEach(k => {
              if (commandType[k] !== undefined) {
                must.push({
                  match: {
                    [`command.data.${k}`]: commandType[k]
                  }
                });
              }
            });
            return {
              bool: {
                must
              }
            };
          })
        }
      });
    }

    if (this.dataObjectCodes.length) {
      params.push({
        match: {
          'command.dataObjectCode': this.dataObjectCodes
            .map(dataObjectCode => dataObjectCode.id)
            .join('|')
        }
      });
    }

    if (this.actionCodes.length) {
      params.push({
        match: {
          'command.actionCode': this.actionCodes
            .map(actionCode => actionCode.id)
            .join('|')
        }
      });
    }

    if (this.statuses.length) {
      params.push({
        match: {
          status: this.statuses.map(status => status.id).join('|')
        }
      });
    }

    if (this.entityTypes.length) {
      params.push({
        match: {
          'command.entityType': this.entityTypes
            .map(entityType => entityType.id)
            .join('|')
        }
      });
    }

    return params;
  }

  @computed get auditEntrySortParams() {
    return {
      [this.sortField]: {
        order: this.sortDirection
      }
    };
  }

  @action.bound async fetchAuditEntries() {
    if (
      this.lastUpdated?.id === 'CUSTOM' &&
      (!this.lastUpdatedFromDate || !this.lastUpdatedToDate)
    )
      return;

    this.fetchingAuditEntries = true;

    await this.auditEntries.search({
      size: 20,
      from: (this.auditEntryPage - 1) * 20,
      query: {
        bool: {
          must: [
            {
              term: { integrationId: this.activeIntegration.id }
            },
            {
              term: { businessId: this.activeCompany.companyId }
            },
            ...this.auditEntryFilterParams
          ]
        }
      },
      sort: this.auditEntrySortParams
    });

    this.fetchingAuditEntries = false;
  }

  @action.bound resetAuditEntries() {
    this.auditEntries.reset();
    this.fetchingAuditEntries = true;
    this.auditEntryPage = 1;
    this.activeAuditLogEntry = null;
    this.activeModal = null;
    this.statuses.replace([
      {
        id: 'FAILED',
        title: 'Failed'
      }
    ]);
  }

  @action.bound showCommandModal(auditEntry) {
    this.activeAuditLogEntry = auditEntry;
    this.showModal('CommandModal');
  }

  @action.bound hideCommandModal() {
    this.hideActiveModal().then(() => {
      this.activeAuditLogEntry = null;
      this.commandReplaySuccessful = false;
    });
  }

  @action.bound showReplayCommandsModal() {
    this.showModal('ReplayCommandsModal');
  }

  @computed get totalAuditEntryPages() {
    return Math.ceil(this.auditEntries.totalElements / this.auditEntryPageSize);
  }

  @action.bound setAuditEntryPage(e, page) {
    this.auditEntryPage = page;
    window.scrollTo(0, 0);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.auditEntryPage = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.auditEntryPage = 1;
  }

  @computed get commandTypeOptions() {
    if (this.activeIntegration.isRyvit) {
      return [
        {
          id: 'TimecardBatchSyncCommand',
          title: t('Time card batch synced')
        },
        {
          id: 'RyvitCommand',
          title: t('Ryvit action synced')
        },
        {
          id: 'RyvitActionPageCommand',
          title: t('Ryvit action polled')
        }
      ];
    }

    if (this.activeIntegration.isQBO) {
      return [
        {
          id: 'TimecardBatchSyncCommand',
          title: t('Time card batch synced')
        },
        {
          id: 'OnDemandSyncCommand',
          title: t('On Demand sync initiated')
        },
        {
          id: 'QBOCommand',
          title: t('QBO entity deleted'),
          Active: false
        },
        {
          id: 'QBOCommand',
          title: t('QBO entity synced'),
          Active: true
        }
      ];
    }

    if (this.activeIntegration.isQBD) {
      return [
        {
          id: 'TimecardBatchSyncCommand',
          title: t('Time card batch synced')
        },
        {
          id: 'QBDBatchCommand',
          title: t('QBD batch synced')
        }
      ];
    }

    if (this.activeIntegration.isSageIntacct) {
      return [
        {
          id: 'TimecardBatchSyncCommand',
          title: t('Time card batch synced')
        },
        {
          id: 'OnDemandSyncCommand',
          title: t('On Demand sync initiated')
        },
        {
          id: 'IntacctBatchCommand',
          title: t('Sage Intacct entity batch synced')
        },
        {
          id: 'IntacctCommand',
          title: t('Sage Intacct entity synced'),
          STATUS: 'active'
        },
        {
          id: 'IntacctCommand',
          title: t('Sage Intacct entity deleted'),
          STATUS: 'inactive'
        }
      ];
    }

    if (this.activeIntegration.isProcore) {
      return [
        {
          id: 'FileUploadCommand',
          action: 'SEND_ATTACHMENT',
          title: t('Attachment uploaded')
        },
        {
          id: 'FileUploadCommand',
          action: 'SEND_REPORT',
          title: t('Report signed')
        },
        {
          id: 'SyncWorklogCommand',
          title: t('Work log synced')
        },
        {
          id: 'SyncNoteCommand',
          title: t('Note synced')
        },
        {
          id: 'SyncChecklistCommand',
          title: t('Checklist synced')
        },
        {
          id: 'SyncTbtCommand',
          title: t('Toolbox Talk synced')
        }
      ];
    }

    if (this.activeIntegration.isComputerEaseDirectAPI) {
      return [
        {
          id: 'OnDemandSyncCommand',
          title: t('On Demand sync initiated')
        },
        {
          id: 'ComputerEaseBatchCommand',
          title: t('ComputerEase entity batch synced')
        },
        {
          id: 'ComputerEaseCommand',
          title: t('ComputerEase entity synced'),
          active: true
        },
        {
          id: 'ComputerEaseCommand',
          title: t('ComputerEase entity deleted'),
          active: false
        }
      ];
    }

    return [
      {
        id: 'FileUploadCommand',
        action: 'SEND_ATTACHMENT',
        title: t('Attachment uploaded')
      },
      {
        id: 'FileUploadCommand',
        action: 'SEND_REPORT',
        title: t('Report signed')
      },
      {
        id: 'SyncChecklistCommand',
        title: t('Checklist synced')
      },
      {
        id: 'SyncTbtCommand',
        title: t('Toolbox Talk synced')
      }
    ];
  }

  @computed get dataObjectCodeOptions() {
    return [
      {
        id: 'workers',
        title: t('Workers')
      },
      {
        id: 'classifications',
        title: t('Classifications')
      },
      {
        id: 'costcodes',
        title: t('CostCodes')
      },
      {
        id: 'projects',
        title: t('Projects')
      },
      {
        id: 'timeCards',
        title: t('TimeCards')
      },
      {
        id: 'timecards-batch',
        title: t('TimeCardsBatch')
      },
      {
        id: 'paytypes',
        title: t('PayTypes')
      },
      {
        id: 'shifts',
        title: t('Shifts')
      }
    ];
  }

  @computed get actionCodeOptions() {
    return [
      {
        id: 'create',
        title: t('Create')
      },
      {
        id: 'delete',
        title: t('Delete')
      },
      {
        id: 'update',
        title: t('Update')
      },
      {
        id: 'batch-closeout',
        title: t('BatchCloseout')
      }
    ];
  }

  @computed get lastUpdatedOptions() {
    return [
      {
        id: '',
        title: t('All time')
      },
      {
        id: 'LAST_10_MINUTES',
        title: t('Last 10 minutes')
      },
      {
        id: 'LAST_30_MINUTES',
        title: t('Last 30 minutes')
      },
      {
        id: 'LAST_2_HOURS',
        title: t('Last 2 hours')
      },
      {
        id: 'LAST_8_HOURS',
        title: t('Last 8 hours')
      },
      {
        id: 'LAST_24_HOURS',
        title: t('Last 24 hours')
      },
      {
        id: 'LAST_7_DAYS',
        title: t('Last 7 days')
      },
      {
        id: 'LAST_14_DAYS',
        title: t('Last 14 days')
      },
      {
        id: 'LAST_28_DAYS',
        title: t('Last 28 days')
      },
      {
        id: 'CUSTOM',
        title: t('Custom')
      }
    ];
  }

  @computed get entityTypeOptions() {
    if (this.activeIntegration.isComputerEaseDirectAPI) {
      return [
        {
          id: 'EMPLOYEE',
          title: t('EMPLOYEE')
        },
        {
          id: 'JOB',
          title: t('JOB')
        },
        {
          id: 'WORKER-CLASS',
          title: t('WORKER-CLASS')
        },
        {
          id: 'CATEGORY',
          title: t('CATEGORY')
        },
        {
          id: 'PAY-TYPE',
          title: t('PAY-TYPE')
        }
      ];
    } else if (this.activeIntegration.isQBO) {
      return [
        {
          id: 'EMPLOYEE',
          title: t('EMPLOYEE')
        },
        {
          id: 'CUSTOMER',
          title: t('CUSTOMER')
        }
      ];
    } else if (this.activeIntegration.isQBD) {
      return [
        {
          id: 'EMPLOYEE',
          title: t('EMPLOYEE')
        },
        {
          id: 'CUSTOMER',
          title: t('CUSTOMER')
        },
        {
          id: 'JOB',
          title: t('JOB')
        },
        {
          id: 'SERVICEITEM',
          title: t('SERVICEITEM')
        },
        {
          id: 'PAYROLLITEM',
          title: t('PAYROLLITEM')
        }
      ];
    } else if (this.activeIntegration.isSageIntacct) {
      return [
        {
          id: 'TASK',
          title: t('TASK')
        },
        {
          id: 'COSTTYPE',
          title: t('COSTTYPE')
        },
        {
          id: 'EMPLOYEE',
          title: t('EMPLOYEE')
        },
        {
          id: 'LABORCLASS',
          title: t('LABORCLASS')
        },
        {
          id: 'LABORUNION',
          title: t('LABORUNION')
        },
        {
          id: 'PROJECT',
          title: t('PROJECT')
        },
        {
          id: 'TIMETYPE',
          title: t('TIMETYPE')
        }
      ];
    }
    return [];
  }

  // https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-range-query.html#ranges-on-dates
  @computed get lastUpdatedRange() {
    const now = moment();

    if (
      this.lastUpdated.id === 'CUSTOM' &&
      this.lastUpdatedFromDate &&
      this.lastUpdatedToDate
    ) {
      return {
        gte: moment(this.lastUpdatedFromDate).valueOf(),
        lte: moment(this.lastUpdatedToDate).valueOf()
      };
    }

    switch (this.lastUpdated.id) {
      case 'LAST_10_MINUTES':
        return {
          gte: now.subtract(10, 'minutes').valueOf()
        };
      case 'LAST_30_MINUTES':
        return {
          gte: now.subtract(30, 'minutes').valueOf()
        };
      case 'LAST_2_HOURS':
        return {
          gte: now.subtract(2, 'hours').valueOf()
        };
      case 'LAST_8_HOURS':
        return {
          gte: now.subtract(8, 'hours').valueOf()
        };
      case 'LAST_24_HOURS':
        return {
          gte: now.subtract(24, 'hours').valueOf()
        };
      case 'LAST_7_DAYS':
        return {
          gte: now.subtract(7, 'days').valueOf()
        };
      case 'LAST_14_DAYS':
        return {
          gte: now.subtract(14, 'days').valueOf()
        };
      case 'LAST_28_DAYS':
        return {
          gte: now.subtract(28, 'days').valueOf()
        };
      default:
        return null;
    }
  }

  @action.bound setLastUpdated(event, value) {
    if (value.id === 'CUSTOM') {
      this.setCustomDuration();
    }

    this.lastUpdated = value;
    this.auditEntryPage = 1;
  }

  @action.bound clearLastUpdated() {
    this.lastUpdated = null;
    this.auditEntryPage = 1;
  }

  @action.bound setCommandTypes(event, commandTypes) {
    this.commandTypes.replace(commandTypes);
    this.auditEntryPage = 1;
  }

  @action.bound setDataObjectCodes(event, dataObjectCodes) {
    this.dataObjectCodes.replace(dataObjectCodes);
    this.auditEntryPage = 1;
  }

  @action.bound setActionCodes(event, actionCodes) {
    this.actionCodes.replace(actionCodes);
    this.auditEntryPage = 1;
  }

  @action.bound setEntityTypes(event, entityTypes) {
    this.entityTypes.replace(entityTypes);
    this.auditEntryPage = 1;
  }

  @computed get statusOptions() {
    return [
      {
        id: 'NEW',
        title: t('New')
      },
      {
        id: 'FAILED',
        title: t('Failed')
      },
      {
        id: 'SUCCESS',
        title: t('Success')
      },
      {
        id: 'IN_PROGRESS',
        title: t('In Progress')
      },
      {
        id: 'REPLAYED',
        title: t('Replayed')
      }
    ];
  }

  @action.bound setStatuses(event, statuses) {
    this.statuses.replace(statuses);
    this.auditEntryPage = 1;
  }

  @action.bound async replayCommands(commandIds) {
    this.replayingCommands = true;

    try {
      const { data } = await request.post(
        `${this.urlMicroService('integrations')}/integrations/command/replay`,
        {
          commandIds: commandIds
        }
      );

      this.commandReplaySuccessful = true;

      await this.hideActiveModal();

      const successfulCount = data.collection.filter(
        ({ result }) => result === true
      ).length;
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      const failedCount = data.collection.length - successfulCount;

      const title = formatIntegrationSyncMessage(
        commandIds.length > 1 ? 'Commands replay' : 'Command replay',
        successfulCount,
        failedCount
      );

      this.rootStore.notificationsUI.pushNotification({
        snackbar,
        icon: 'notification',
        title
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);

      this.commandReplaySuccessful = false;
    } finally {
      this.replayingCommands = false;
      this.selectedAuditEntries.clear();
    }
  }

  @action.bound showErrorModal(auditEntry) {
    this.activeAuditLogEntry = auditEntry;
    this.showModal('errorModal');
  }

  @action.bound hideErrorModal() {
    this.hideActiveModal().then(() => {
      this.activeAuditLogEntry = null;
    });
  }

  @action.bound clearUIState() {
    this.resetAuditEntries();
    this.statuses.clear();
    this.commandTypes.clear();
    this.dataObjectCodes.clear();
    this.actionCodes.clear();
    this.searchQuery = '';
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.timeFrameForm = null;
    this.lastUpdated = null;
    this.lastUpdatedFromDate = null;
    this.lastUpdatedToDate = null;
    this.replayingCommands = false;
    this.selectedAuditEntries.clear();
    this.auditEntryPage = 1;
  }

  findSelectedAuditEntry = auditEntry => {
    return this.selectedAuditEntries.find(
      selectedAuditEntry => selectedAuditEntry.id === auditEntry.id
    );
  };

  @computed get hasSelectedAuditEntries() {
    return this.selectedAuditEntries.length > 0;
  }

  @computed get allAuditEntriesSelected() {
    if (!this.hasSelectedAuditEntries || this.fetchingAuditEntries)
      return false;

    return (
      this.auditEntries.models.filter(auditEntry => {
        return this.findSelectedAuditEntry(auditEntry);
      }).length === this.auditEntries.models.length
    );
  }

  @action.bound toggleSelectAuditEntry(auditEntry) {
    const selectedAuditEntry = this.findSelectedAuditEntry(auditEntry);

    if (selectedAuditEntry) {
      this.selectedAuditEntries.remove(selectedAuditEntry);
    } else {
      this.selectedAuditEntries.push(auditEntry);
    }
  }

  @action.bound toggleSelectAllAuditEntries() {
    if (this.allAuditEntriesSelected) {
      this.auditEntries.models.forEach(auditEntry => {
        this.selectedAuditEntries.remove(
          this.findSelectedAuditEntry(auditEntry)
        );
      });
    } else {
      this.auditEntries.models.forEach(auditEntry => {
        if (!this.findSelectedAuditEntry(auditEntry)) {
          this.selectedAuditEntries.push(auditEntry);
        }
      });
    }
  }

  @action.bound
  sortByColumn(column) {
    if (this.sortField === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = column;
      this.sortDirection = 'asc';
    }
  }

  @action.bound setCustomDuration() {
    this.timeFrameForm = new TimeFrameForm(
      {
        fields: timeFrameFormFields,
        rules: timeFrameFormRules,
        values: {
          fromDate: this.lastUpdatedFromDate,
          toDate: this.lastUpdatedToDate
        },
        labels: timeFrameFormLabels
      },
      {
        options: timeFrameFormOptions,
        plugins: timeFrameFormPlugins
      }
    );

    this.showModal('CustomDuration');
  }

  @action.bound cancelCustomDuration() {
    this.hideActiveModal();
    this.timeFrameForm = null;
    this.lastUpdated = null;
    this.lastUpdatedFromDate = null;
    this.lastUpdatedToDate = null;
  }

  @action.bound submitTimeFrameForm(event) {
    event.preventDefault();

    this.timeFrameForm.submit({
      onSuccess: this.submitTimeFrameFormSuccess,
      onError: this.submitTimeFrameFormError
    });
  }

  @action.bound async submitTimeFrameFormSuccess() {
    const { fromDate, toDate } = this.timeFrameForm.values();

    this.lastUpdatedFromDate = moment(fromDate).format('YYYY-MM-DD');
    this.lastUpdatedToDate = moment(toDate).format('YYYY-MM-DD');
    this.timeFrameForm = null;

    this.hideActiveModal();
  }

  @action.bound submitTimeFrameFormError() {
    console.error(this.timeFrameForm.errors());
  }
}

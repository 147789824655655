import { RakenCollection } from 'stores/lib';
import User from 'stores/models/User';
import Worker from 'stores/models/Worker';

export default class DirectoryContacts extends RakenCollection {
  model(type) {
    if (type === 'WORKER') {
      return Worker;
    }

    return User;
  }

  parse(data) {
    return data.collection.map(member => {
      if (member.role === 'ROLE_WORKER') {
        return {
          ...member,
          type: 'WORKER'
        };
      } else {
        return {
          ...member,
          type: 'USER'
        };
      }
    });
  }

  url() {
    return `/ra/companies/${this.rootStore.me.company?.uuid}/members`;
  }
}

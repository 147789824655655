import { action, observable, computed } from 'mobx';
import PolicyChildEditUI from './PolicyChildEditUI';

import {
  AdvancedForm,
  advancedFormOptions,
  advancedFormFields,
  advancedFormRules,
  advancedFormLabels,
  advancedFormValues,
  advancedFormPlugins
} from 'forms/advanced';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class AdvancedUI extends PolicyChildEditUI {
  @observable form;
  @observable saving;

  constructor(options) {
    super(options);

    this.form = null;
    this.saving = false;
  }

  @action.bound async setup() {
    this.setupForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound setupForm() {
    const {
      dateFormat,
      showTimeCardsDivision,
      trackMemberTime,
      requireClassificationsOnTimeCards,
      restrictClassificationsEditsOnTimeCards
    } = this.company.preferences;

    this.form = new AdvancedForm(
      {
        fields: advancedFormFields,
        rules: advancedFormRules,
        labels: advancedFormLabels,
        values: {
          ...advancedFormValues,
          ...{
            dateFormat: dateFormat,
            showTimeCardsDivision: showTimeCardsDivision,
            trackMemberTime: trackMemberTime,
            requireClassificationsOnTimeCards: requireClassificationsOnTimeCards,
            restrictClassificationsEditsOnTimeCards: restrictClassificationsEditsOnTimeCards
          }
        }
      },
      {
        options: advancedFormOptions,
        plugins: advancedFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.form = null;
    this.saving = false;
  }

  @action.bound
  submitAdvancedForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitAdvancedFormSuccess,
      onError: e => {
        console.error(this.form.errors());
      }
    });
  }

  @action.bound
  async submitAdvancedFormSuccess() {
    this.saving = true;

    try {
      await this.company.save(
        { preferences: { ...this.form.values() } },
        {
          wait: true,
          url: this.company.url() + '/preferences'
        }
      );

      this.company.fetch();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Advanced settings updated')
      });
      this.unblockHistory();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get dateFormatOptions() {
    return [
      { title: 'MM/DD/YYYY', value: 'MM/dd' },
      { title: 'DD/MM/YYYY', value: 'dd/MM' }
    ];
  }

  @computed get dateFormatValue() {
    return this.dateFormatOptions.find(
      option => option.value === this.form?.$('dateFormat').value
    );
  }

  @computed get showTimeCardsDivisionOptions() {
    return [
      { title: 'Division-Code-Description', value: true },
      { title: 'Code-Description', value: false }
    ];
  }

  @computed get showTimeCardsDivisionValue() {
    return this.showTimeCardsDivisionOptions.find(
      option => option.value === this.form?.$('showTimeCardsDivision').value
    );
  }

  @computed get trackMemberTimeOptions() {
    return [
      {
        title: 'Assigned workers and team members',
        value: 'assignedWorkersAndAssignedTeamMembers'
      },
      {
        title: 'Assigned workers and all team members',
        value: 'assignedWorkersAndAllTeamMembers'
      },
      { title: 'Assigned workers only', value: 'assignedWorkersOnly' }
    ];
  }

  @computed get trackMemberTimeValue() {
    return this.trackMemberTimeOptions.find(
      option => option.value === this.form?.$('trackMemberTime').value
    );
  }
}

import omit from 'lodash.omit';
import once from 'lodash.once';
import { observable, action, computed } from 'mobx';
import UIStore from './UIStore';

import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import alertErrorHandler from 'utils/alertErrorHandler';
import isDataURL from 'utils/isDataURL';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class TeamWorkerInfoUI extends UIStore {
  @observable entryEditForm;

  constructor(options) {
    super(options);

    // Editing
    this.entryEditForm = null;

    this.fetchClassificationsOnce = once(
      this.classificationsUI.fetchClassifications
    );
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    this.fetchClassificationsOnce();
    this.groupSelectorUI.setup();
    this.setEntryEditForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.groupSelectorUI.tearDown();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryEditForm = null;
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new WorkerForm(
      {
        fields: workerFormFields,
        rules: this.workerFormRules,
        labels: workerFormLabels,
        values: Object.assign(this.entryForEdit.formValues, {
          companyUuid: this.company.uuid
        }),
        options: workerFormFieldOptions
      },
      {
        options: workerFormOptions,
        plugins: workerFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed get workerFormRules() {
    return Object.assign({}, workerFormRules, {
      email: `string|email|max:200|worker_email_available`
    });
  }

  @computed get workerExists() {
    return (
      this.entryEditForm.existingWorker &&
      this.entryEditForm.existingWorker.id !== this.entryForEdit.id
    );
  }

  @computed get workerExistsMessage() {
    if (!this.workerExists) return '';

    if (this.entryEditForm.$('employeeId').value) {
      return t('A worker with this name and Employee Id already exists.');
    }

    return t(
      'A worker with this name already exists. Use a unique Employee Id to differentiate.'
    );
  }

  @computed get entryEditFormInvalid() {
    return this.workerExists;
  }

  @computed get disableEntryEditSaveButton() {
    if (!this.authorization.canEditWorkers) return true;

    return this.entryForEdit.saving || this.entryEditFormInvalid;
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();

    if (this.entryEditForm.submitting) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    const values = this.entryEditForm.trimmedValues();

    if (isDataURL(values.avatar)) {
      values.avatar = {
        base64: values.avatar
      };
    } else {
      delete values.avatar;
    }

    values.groupUuids = values.groups.map(group => group.uuid);

    delete values.groups;

    // Todo classificationId to be deprecated
    delete values.classificationId;

    try {
      await this.entryForEdit.save(
        omit(values, [
          'id',
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]),
        {
          wait: true,
          stripNonRest: false
        }
      );

      this.resetEntryEditForm();

      // Used to get the hasWorkersWithoutContactDetails field when things change.
      this.company.fetch();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Worker info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }

  @computed get selectedGroupUuids() {
    return this.entryEditForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}

import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import omit from 'lodash.omit';
import UIStore from './UIStore';
import Workers from '../collections/Workers';

import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import {
  WorkersForm,
  workersFormOptions,
  workersFormFields,
  workersFormRules,
  workersFormPlugins
} from 'forms/workers';

import { t } from 'utils/translate';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class ProjectDirectoryWorkersAddUI extends UIStore {
  @observable workerForm;
  @observable workersForm;
  @observable step; // existing or new

  @observable phoneCountry;

  // Workers
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.step = 'existing';
    this.workerForm = null;
    this.workersForm = null;

    // Existing worker pagination
    this.selectedWorkers = observable([]);
    this.loading = true;
    this.sortField = 'firstName';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.groupsFilters = observable([]);

    // Worker
    this.paginatedWorkers = new Workers(null, {
      rootStore: this.rootStore
    });

    this.fetchWorkersDebounced = debounce(this.fetchWorkers, BASE_DEBOUNCE);

    this.fetchNextPageOfWorkers = throttle(
      this.fetchNextPageOfWorkers,
      BASE_DEBOUNCE
    );
  }

  @computed get workers() {
    return this.paginatedWorkers;
  }

  @action.bound async setStep(step) {
    if (step === 'new') {
      await this.authorization.checkFeatureAccess('EditWorkers');
    }

    this.step = step;
  }

  @action.bound setup(step = 'existing') {
    this.phoneCountry = this.defaultPhoneCountry;
    this.setStep(step);
    this.setupWorkerForm();
    this.setupWorkersForm();
    this.setupReactions();
    this.fetchWorkers();
    this.setupWorkerAttributes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.tearDownWorkerAttributes();
    this.clearUIState();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchWorkers();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchWorkersDebounced();
      }
    );

    this.cancelReactToWorkerFormRules = reaction(
      () => this.workerFormRules,
      workerFormRules => {
        this.workerForm.set('rules', this.workerFormRules);
        this.workerForm.validate();
      },
      {
        delay: 50
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
    this.cancelReactToWorkerFormRules && this.cancelReactToWorkerFormRules();
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownWorkerAttributes() {
    this.shiftSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  @computed get workerFormRules() {
    return Object.assign({}, workerFormRules, {
      email: `string|email|max:200|not_in:${this.workersFormEmails}|worker_email_available`,
      employeeId: `string|max:100|employeeId_available|not_in:${this.workersFormEmployeeIds}`,
      phoneNumber: `string|phone_available|max:30|not_in:${this.workersFormPhoneNumbers}`
    });
  }

  @action.bound setupWorkerForm() {
    this.workerForm = new WorkerForm(
      {
        fields: workerFormFields,
        rules: this.workerFormRules,
        labels: workerFormLabels,
        options: workerFormFieldOptions
      },
      {
        options: Object.assign({}, workerFormOptions, {
          validateOnInit: true,
          showErrorsOnInit: false
        }),
        plugins: workerFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound setupWorkersForm() {
    this.workersForm = new WorkersForm(
      {
        fields: workersFormFields,
        rules: workersFormRules
      },
      {
        options: workersFormOptions,
        plugins: workersFormPlugins
      }
    );
  }

  @action.bound cancelWorkersAdd() {
    history.push(`${this.project.viewUrl}/directory/workers`);
  }

  @action.bound submitWorkerForm(event) {
    event.preventDefault();

    if (this.workerForm.submitting) return;

    this.workerForm.submit({
      onSuccess: this.submitWorkerFormSuccess,
      onError: this.submitWorkerFormError
    });
  }

  @action.bound submitWorkerFormError() {
    console.error(this.workerForm.errors());
  }

  @action.bound submitWorkerFormSuccess() {
    const values = this.workersForm.$('workers').values();

    values.unshift(this.workerForm.trimmedValues());

    this.workersForm.update({
      workers: values
    });

    this.setupWorkerForm();

    setTimeout(() => {
      this.phoneCountry = '';
      this.phoneCountry = this.defaultPhoneCountry;
    }, 0);
  }

  @computed
  get workersFormEmails() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('email').value)
      .join(',');
  }

  @computed
  get workersFormEmployeeIds() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('employeeId').value)
      .join(',');
  }

  @computed
  get workersFormPhoneNumbers() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('phoneNumber').value)
      .join(',');
  }

  @computed
  get workerExistsInWorkersForm() {
    if (!this.workerForm) return false;

    const values = this.workerForm.trimmedValues();

    return this.workersForm.trimmedValues().workers.find(worker => {
      return (
        worker.firstName === values.firstName &&
        worker.lastName === values.lastName &&
        worker.employeeId === values.employeeId
      );
    });
  }

  @computed get workerExists() {
    return this.workerForm.existingWorker || this.workerExistsInWorkersForm;
  }

  @computed get workerExistsMessage() {
    if (!this.workerExists) return '';

    if (this.workerForm.$('employeeId').value) {
      return t('Another worker with this name and Employee Id already exists.');
    }

    return t(
      'Another worker with this name already exists. Use a unique Employee Id to differentiate.'
    );
  }

  @computed get workerFormIsValid() {
    return !this.workerExists && this.workerForm.check('isValid');
  }

  @computed get classificationOptions() {
    return this.parent.classificationOptions;
  }

  @computed get hasAddedWorkers() {
    return this.workersForm.$('workers').size > 0;
  }

  @action.bound removeWorker(worker) {
    this.workersForm.$('workers').del(worker.key);

    if (!this.workersForm.$('workers').size) {
      this.clearValidationDetails();
    }
  }

  @action.bound submitWorkersForm(event) {
    event.preventDefault();

    if (this.workersForm.submitting) return;

    this.workersForm.submit({
      onSuccess: this.submitWorkersFormSuccess,
      onError: this.submitWorkersFormError
    });
  }

  @action.bound submitWorkersFormSuccess() {
    this.setStep('defaults');
  }

  @action.bound skipDefaults() {
    this.workersForm.$('workers').map(worker => {
      worker.$('defaultShift').clear();
      worker.$('defaultCostCode').clear();
      worker.$('defaultCrewName').clear();
    });

    this.createWorkers();
  }

  @action.bound completeDefaults() {
    this.createWorkers();
  }

  @action.bound async createWorkers() {
    if (this.saving) return;

    this.saving = true;

    try {
      const workerFields = this.workersForm.trimmedValues().workers;

      workerFields.forEach(workerField => {
        workerField.role = 'ROLE_WORKER';
        workerField.projectUuids = [this.project.uuid];

        if (!workerField.classification.uuid) {
          delete workerField.classification;
        }
      });

      const payload = workerFields.map(workerField => {
        const workerFieldData = pickBy(
          workerField,
          value => value !== undefined && value !== '' && value !== null
        );

        workerFieldData.settings = {
          workerDefaultShift: workerField.defaultShift?.uuid,
          workerDefaultCostCode: workerField.defaultCostCode?.uuid,
          workerDefaultCrewName: workerField.defaultCrewName?.uuid
        };

        return omit(workerFieldData, [
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]);
      });

      await request.post(`${this.rootStore.users.url()}/batch`, payload);

      this.parent.sortByLastCreated();
      this.cancelWorkersAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Workers added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitWorkersFormError() {
    console.error(this.workersForm.errors());
  }

  @action.bound
  async fetchWorkers() {
    this.loading = true;

    await this.workers.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound fetchNextPageOfWorkers() {
    if (this.workers.fetching) return;

    if (this.workers.hasNextPage) {
      this.workers.fetchNextPage();
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      companyUuids: this.company.uuid,
      role: 'ROLE_WORKER',
      excludeProjectUuids: this.project?.uuid,
      projectStates: 'ACTIVE',
      groupUuids: this.groupsFilters.map(group => group.uuid).join(',')
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.workers.totalElements / this.pageSize);
  }

  @computed get hasSelectedWorkers() {
    return this.selectedWorkers.length > 0;
  }

  @action.bound
  toggleSelectWorker(workerUuid) {
    if (this.selectedWorkers.find(uuid => uuid === workerUuid)) {
      this.selectedWorkers.remove(workerUuid);
    } else {
      this.selectedWorkers.push(workerUuid);
    }
  }

  @computed
  get allWorkersSelected() {
    return (
      this.hasWorkers &&
      this.workers.models.every(worker =>
        this.selectedWorkers.includes(worker.uuid)
      )
    );
  }

  @action.bound
  toggleSelectAllWorkers() {
    if (this.allWorkersSelected) {
      this.selectedWorkers.replace(
        this.selectedWorkers.filter(uuid => {
          return !this.workers.models.map(worker => worker.uuid).includes(uuid);
        })
      );
    } else {
      this.workers.models.forEach(worker => {
        if (!this.selectedWorkers.includes(worker.uuid)) {
          this.selectedWorkers.push(worker.uuid);
        }
      });
    }
  }

  @action.bound async addExistingWorkersToProject() {
    this.saving = true;

    try {
      await request.post(
        `/ra/projects/${this.project.uuid}/members`,
        this.selectedWorkers.slice()
      );

      this.parent.sortByLastUpdated();
      this.cancelWorkersAdd();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Workers added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelAdd() {
    this.setStep('existing');
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.workerForm = null;
    this.workersForm = null;
    this.searchQuery = '';
    this.groupsFilters.clear();
    this.selectedWorkers.clear();
    this.workers.clear();
    this.page = 1;
    this.step = 'new';
    this.loading = true;
  }
}

import { action, computed, observable, reaction, runInAction } from 'mobx';

import UIStore from './UIStore';

import request from 'axios';
import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import TimeClockQuestionAnswers from 'stores/collections/TimeClockQuestionAnswers';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

export default class TimeClockIndividualQuestionUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable questionToView;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'completedCount';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    this.questionToView = null;

    // Time Clock Questions collection
    this.timeClockQuestionAnswers = new TimeClockQuestionAnswers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTimeClockQuestionsDebounced = debounce(
      this.fetchTimeClockQuestions,
      BASE_DEBOUNCE
    );
  }

  @action.bound async setup() {
    if (!this.questionToView) {
      history.push(
        `${this.parent.timesheetsPath}/time-clock-questions/questions`
      );
      return;
    }

    window.scrollTo(0, 0);
    this.loading = true;
    this.setupReactions();
    this.fetchTimeClockQuestionsDebounced();
  }

  @action.bound tearDown() {
    this.timeClockQuestionAnswers.clear();
    this.questionToView = null;
    this.reactToParams && this.reactToParams();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchTimeClockQuestionsDebounced();
        });
      }
    );
  }

  @computed
  get params() {
    return {
      questionUuids: this.questionToView?.questionUuids?.map(questionUuid => {
        return questionUuid;
      }),
      limit: this.pageSize,
      fromDate: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      toDate: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      offset: (this.page - 1) * this.pageSize
    };
  }

  @action.bound setDates(fromDate, toDate) {
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  @action.bound fetchTimeClockQuestions() {
    this.loading = true;

    request
      .post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/timecardchecklist/question/details`,
        this.params
      )
      .then(response => {
        runInAction(() => {
          this.timeClockQuestionAnswers.set(response.data);
        });
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @computed get hasTimeClockQuestionAnswers() {
    return this.timeClockQuestionAnswers.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound
  returnToAllQuestions() {
    history.push(
      `${this.parent.timesheetsPath}/time-clock-questions/questions`
    );
  }

  @action.bound
  viewTimeCard(question) {
    // Set timesheets redirectUrl
    this.rootStore.timesheetsUI.timesheetsEditUI.redirectUrl = `/timecards/time-clock-questions/questions`;

    history.push(
      `/timecards/${question.timesheetUuid}/timecard/${question.timeCardUuid}`
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.timeClockQuestionAnswers.totalElements / this.pageSize
    );
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }
}

import { RakenCollection } from '../lib';
import EquipmentSnapshotReport from '../models/EquipmentSnapshotReport';

export default class EquipmentSnapshotReports extends RakenCollection {
  model() {
    return EquipmentSnapshotReport;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${
      this.rootStore.me.company.uuid
    }/reports/equipment/snapshot/scheduled`;
  }
}

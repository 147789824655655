import { BASE_DEBOUNCE } from 'fixtures/constants';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import IncidentReportSubmissions from 'stores/collections/incidents/IncidentReportSubmissions';
import SubmissionSettings from 'stores/models/SubmissionSettings';
import IncidentExportUI from 'stores/ui/company/incidents/IncidentExportUI';
import IncidentSubmitUI from 'stores/ui/company/incidents/IncidentSubmitUI';
import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class IncidentsSubmittedUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    // submittedByName, submissionId, establishmentName, submissionYear, submissionDate
    this.sortField = 'submissionDate';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;
    this.loading = true;

    this.incidentExportUI = new IncidentExportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentSubmitUI = new IncidentSubmitUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchIncidentReportSubmissionsDebounced = debounce(
      this.fetchIncidentReportSubmissions,
      BASE_DEBOUNCE
    );

    this.incidentReportSubmissions = new IncidentReportSubmissions(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.submissionSettings = new SubmissionSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.submissionSettings.fetch();
    this.fetchIncidentReportSubmissions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchIncidentReportSubmissionsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: `${this.sortField}`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchIncidentReportSubmissions() {
    this.incidentReportSubmissions.cancelRequest();
    this.incidentReportSubmissions.reset();

    try {
      await this.incidentReportSubmissions.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasIncidentReportSubmissions() {
    return (
      this.incidentReportSubmissions && this.incidentReportSubmissions.hasModels
    );
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.incidentReportSubmissions.totalElements / this.pageSize
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.sortField = 'submissionDate';
    this.sortDirection = 'desc';

    this.incidentReportSubmissions.clear();
    this.submissionSettings.clear();
  }

  @computed get showEmptyState() {
    return (
      !this.loading && !this.searchQuery && !this.hasIncidentReportSubmissions
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading && this.searchQuery && !this.hasIncidentReportSubmissions
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDIncidents;
  }

  @computed get viewUrl() {
    return `/company/incidents/osha-submissions`;
  }
}

import { computed, action } from 'mobx';

import {
  SmallEquipmentLostStolenForm,
  smallEquipmentLostStolenFormOptions,
  smallEquipmentLostStolenFormFields,
  smallEquipmentLostStolenFormRules,
  smallEquipmentLostStolenFormLabels,
  smallEquipmentLostStolenFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentLostStolen';

import SmallEquipmentLostStolenUI from 'stores/ui/company/equipment/smallEquipment/SmallEquipmentLostStolenUI';

export default class ProjectSmallEquipmentLostStolenUI extends SmallEquipmentLostStolenUI {
  constructor(options) {
    super(options);
  }

  @computed get selectedSmallEquipment() {
    return this.parent.selectedSmallEquipment;
  }

  @action.bound async markLostStolenSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.setupForm();

    this.showModal('SmallEquipmentLostStolenModalPage');
  }

  @action.bound setupForm() {
    this.smallEquipmentLostStolenForm = new SmallEquipmentLostStolenForm(
      {
        fields: smallEquipmentLostStolenFormFields,
        rules: smallEquipmentLostStolenFormRules,
        labels: smallEquipmentLostStolenFormLabels,
        values: {
          lostStolen: this.selectedSmallEquipment.map(smallEquipment => ({
            equipmentUuid: smallEquipment.equipmentUuid,
            equipmentName: smallEquipment.equipmentName,
            projectName: smallEquipment.projectName,
            projectUuid: smallEquipment.projectUuid,
            projectDeployedUnits: smallEquipment.projectDeployedUnits,
            unitsLostStolen: 0
          }))
        }
      },
      {
        options: smallEquipmentLostStolenFormOptions,
        plugins: smallEquipmentLostStolenFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentLostStolenForm
      .$('lostStolen')
      .fields.forEach(lostStolen =>
        lostStolen
          .$('unitsLostStolen')
          .set(
            'rules',
            `numeric|min:0|max:${lostStolen.$('projectDeployedUnits').value}`
          )
      );
  }
}

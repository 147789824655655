import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentLostStolenFiltersFormPlugins = extendedPlugins;

const smallEquipmentLostStolenFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentLostStolenFiltersFormFields = ['smallEquipment', 'project'];

const smallEquipmentLostStolenFiltersFormLabels = {
  smallEquipment: t('small equipment'),
  projects: t('projects')
};

const smallEquipmentLostStolenFiltersFormValues = {
  smallEquipment: [],
  projects: []
};

const smallEquipmentLostStolenFiltersFormRules = {
  smallEquipment: '',
  projects: ''
};

class SmallEquipmentLostStolenFiltersForm extends MobxReactForm {}

export {
  SmallEquipmentLostStolenFiltersForm,
  smallEquipmentLostStolenFiltersFormOptions,
  smallEquipmentLostStolenFiltersFormFields,
  smallEquipmentLostStolenFiltersFormRules,
  smallEquipmentLostStolenFiltersFormValues,
  smallEquipmentLostStolenFiltersFormLabels,
  smallEquipmentLostStolenFiltersFormPlugins
};

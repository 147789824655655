import debounce from 'lodash.debounce';
import request from 'axios';
import { action, observable, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from './UIStore';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import {
  MESSAGING_FEATURE_VIEWED,
  MESSAGING_FEATURE_ENABLED
} from 'utils/segmentAnalytics/eventSpec';

import history from 'utils/history';

import MessagingChannelAddUI from './MessagingChannelAddUI';
import { hideIntercom, showIntercom } from 'utils/intercomControl';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class MessagingUI extends UIStore {
  @observable unreadMessageCount;
  @observable previousPath;

  @observable messagingOpen;

  // Sendbird UI
  @observable currentChannelUrl;
  @observable showChannelSettings;

  // Message Search
  @observable showChannelMessageSearch;
  @observable channelMessageQuery;
  @observable groupChannelMessageQuery;
  @observable highlightedMessage;

  constructor(options) {
    super(options);

    this.messagingOpen = false;

    // Going back
    this.previousPath = undefined;

    this.unreadMessageCount = 0;

    // Sendbird UI
    this.currentChannelUrl = null;
    this.showChannelSettings = false;

    this.highlightedMessage = null;

    // Channel message Search
    this.showChannelMessageSearch = false;
    this.channelMessageQuery = '';
    this.groupChannelMessageQuery = '';

    this.setGroupChannelMessageQueryDebounced = debounce(
      this.setGroupChannelMessageQuery,
      BASE_DEBOUNCE
    );

    // Channel creation UI
    this.messagingChannelAddUI = new MessagingChannelAddUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get sendbirdApplicationId() {
    return this.rootStore.sendbirdApplicationId;
  }

  @computed get sendbirdApiToken() {
    return this.rootStore.sendbirdApiToken;
  }

  @computed get sendbirdApiUrl() {
    return `https://api-${this.sendbirdApplicationId}.sendbird.com/v3/users/${this.me.uuid}`;
  }

  @computed get messagingEnabled() {
    return this.company?.preferences.messagingEnabled;
  }

  @computed get messagingTitle() {
    if (this.unreadMessageCount > 0) {
      return `${t('Messaging')} (${this.unreadMessageCount})`;
    }

    return t('Messaging');
  }

  @action.bound setup(previousPath) {
    this.messagingOpen = true;

    hideIntercom();
    this.setPreviousPath(previousPath);
    this.setupReactions();

    callTrack(MESSAGING_FEATURE_VIEWED, {
      messaging_enabled: this.messagingEnabled
    });
  }

  @action.bound tearDown() {
    this.messagingOpen = false;

    showIntercom();
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToChannelMessageQuery = reaction(
      () => this.channelMessageQuery,
      params => {
        this.setGroupChannelMessageQueryDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToChannelListQuery && this.reactToChannelListQuery();
    this.reactToChannelMessageQuery && this.reactToChannelMessageQuery();
  }

  @action.bound setPreviousPath(previousPath) {
    if (previousPath) {
      this.previousPath = previousPath;
    } else {
      this.previousPath = undefined;
    }
  }

  @action.bound clearUIState() {
    this.messagingChannelAddUI.clearUIState();
    this.previousPath = undefined;
    this.showChannelSettings = false;
    this.showChannelMessageSearch = false;
    this.channelMessageQuery = '';
    this.groupChannelMessageQuery = '';
    this.highlightedMessage = null;
    this.currentChannelUrl = null;
  }

  @action.bound closeMessaging() {
    this.goBack();
  }

  @computed get goBackUrl() {
    return this.previousPath || `/`;
  }

  @action.bound goBack() {
    history.push(this.goBackUrl);
  }

  @action.bound async enableMessaging() {
    try {
      this.company.preferences.messagingEnabled = true;

      await this.company.save(
        { preferences: this.company.preferences },
        { wait: true }
      );

      callTrack(MESSAGING_FEATURE_ENABLED);

      this.initializeUnreadMessageCountPoller();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound disableMessaging() {
    this.company.preferences.messagingEnabled = false;

    this.company.save(
      { preferences: this.company.preferences },
      { wait: false }
    );
  }

  @computed get hasUnreadMessages() {
    if (!this.messagingEnabled) return true;

    return this.unreadMessageCount > 0;
  }

  @computed get unreadMessageDisplay() {
    if (!this.messagingEnabled) return 1;

    return this.unreadMessageCount;
  }

  @computed get uiKitColorSet() {
    return {
      '--sendbird-light-primary-100': '#FFCA99',
      '--sendbird-light-primary-200': '#FF8A1D',
      '--sendbird-light-primary-300': '#B85900',
      '--sendbird-light-primary-400': '#994A00',
      '--sendbird-light-primary-500': '#5C2C00',
      '--sendbird-light-secondary-100': '#99CEFF',
      '--sendbird-light-secondary-200': '#4CA9FF',
      '--sendbird-light-secondary-300': '#0072DB',
      '--sendbird-light-secondary-400': '#0A5CA9',
      '--sendbird-light-secondary-500': '#074A88',
      '--sendbird-light-information-100': '#ADD7FF',
      '--sendbird-light-error-100': '#FF8098',
      '--sendbird-light-error-200': '#FF335A',
      '--sendbird-light-error-300': '#DB002A',
      '--sendbird-light-error-400': '#C20025',
      '--sendbird-light-error-500': '#800018',
      '--sendbird-light-background-100': '#EEEDEC',
      '--sendbird-light-background-200': '#E4E3E2',
      '--sendbird-light-background-300': '#B4B2B1',
      '--sendbird-light-background-400': '#393939',
      '--sendbird-light-background-500': '#2C2C2C',
      '--sendbird-light-background-600': '#161616',
      '--sendbird-add-reaction-button-border-hover': '#FF9533',
      '--sendbird-selected-reaction-button-border-hover': '#FF8A1D',
      '--sendbird-iconbutton-color': '#B85900',
      '--sendbird-message-input-border-active': '#994A00'
    };
  }

  @action.bound showMessagingChannelAdd() {
    history.push('/messaging/add-channel');
  }

  @action.bound hideMessagingChannelAdd() {
    history.push('/messaging');
  }

  @action.bound setCurrentChannelUrl(channelUrl) {
    this.currentChannelUrl = channelUrl;
    this.highlightedMessage = null;
  }

  @action.bound toggleShowChannelSettings() {
    if (this.showChannelSettings) {
      this.showChannelSettings = false;
    } else {
      this.showChannelSearch = false;
      this.showChannelSettings = true;
    }
  }

  @action.bound toggleShowChannelMessageSearch() {
    if (this.showChannelMessageSearch) {
      this.showChannelMessageSearch = false;
      this.channelMessageQuery = '';
      this.groupChannelMessageQuery = '';
      this.highlightedMessage = null;
    } else {
      this.showChannelSettings = false;
      this.showChannelMessageSearch = true;
    }
  }

  @action.bound clearChannelMessageQuery() {
    this.channelMessageQuery = '';
  }

  @action.bound setChannelMessageQuery(value) {
    this.channelMessageQuery = value;
  }

  @action.bound setGroupChannelMessageQuery() {
    this.groupChannelMessageQuery = this.channelMessageQuery;
  }

  @action.bound setHighlightedMessage(message) {
    this.highlightedMessage = message;
  }

  @action.bound initializeUnreadMessageCountPoller() {
    if (!this.messagingEnabled) return;

    this.fetchUnreadMessageCount();

    document.addEventListener(
      'visibilitychange',
      this.fetchUnreadMessageCount,
      false
    );

    // Get the message count every 5 minutes
    this.unreadMessageCountPoller = setInterval(
      this.fetchUnreadMessageCount,
      300000
    );
  }

  @action.bound async fetchUnreadMessageCount() {
    if (document.visibilityState === 'hidden') return;

    const response = await request.get(
      `ra/companies/${this.company.uuid}/members/${this.me.uuid}/unread-message-count`
    );

    this.setUnreadMessageCount(response.data.unread_count);
  }

  @action.bound setUnreadMessageCount(value) {
    this.unreadMessageCount = value;
  }
}

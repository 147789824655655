import moment from 'moment';
import request from 'axios';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import history from 'utils/history';
import Talks from '../../collections/Talks';
import ProjectChildUI from './ProjectChildUI';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  ScheduleTalkForm,
  scheduleTalkFormFields,
  scheduleTalkFormRules,
  scheduleTalkFormLabels,
  scheduleTalkFormOptions,
  scheduleTalkFormPlugins,
  scheduleTalkFormValues
} from 'forms/scheduleTalk';

import {
  RenameTalkForm,
  renameTalkFormFields,
  renameTalkFormRules,
  renameTalkFormLabels,
  renameTalkFormOptions,
  renameTalkFormPlugins
} from 'forms/renameTalk';

import { callTrack } from 'utils/segmentIntegration';
import { TBT_SCHEDULED } from 'utils/segmentAnalytics/eventSpec';

import crossOriginDownload from 'utils/crossOriginDownload';

export default class ToolboxTalksAllUI extends ProjectChildUI {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedTalk;
  @observable scheduleTalkForm;
  @observable renameTalkForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedTalk = null;
    this.scheduleTalkForm = null;
    this.renameTalkForm = null;

    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.page = 1;
    this.pageSize = 20;

    // Talks collection
    this.talks = new Talks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTalksDebounced = debounce(this.fetchTalks, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchTalks();
    this.groupSelectorUI.setup();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.groupSelectorUI.tearDown();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTalksDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      page: this.page - 1,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection.toUpperCase()
    };
  }

  @action.bound async fetchTalks() {
    this.talks.cancelRequest();
    this.talks.clear();

    try {
      await this.talks.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasTalks() {
    return this.talks.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.talks.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.talks.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'talk.name';
    this.sortDirection = 'asc';
    this.selectedTalk = null;
    this.scheduleTalkForm = null;
    this.renameTalkForm = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasTalks;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasTalks;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get deleteTalkText() {
    if (this.selectedTalk?.hasUpcomingTalks) {
      return t(
        'This talk has been scheduled on your projects for future dates. Deleting this talk will remove all future instances of this talk on your projects. Please confirm that you want to delete this talk.'
      );
    }

    return t('Please confirm that you want to delete this talk.');
  }

  @computed get deleteTalkTitle() {
    return t('Delete talk?');
  }

  @action.bound async deleteTalk(talk) {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    this.selectedTalk = talk;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTalk() {
    await this.hideActiveModal();

    this.selectedTalk = null;
  }

  @action.bound async confirmDeleteTalk() {
    this.saving = true;

    try {
      await request.patch(`${this.talks.url()}/batch`, {
        talkIds: [this.selectedTalk.uuid]
      });

      this.refetchTalks();

      await this.hideActiveModal();

      this.selectedTalk = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get scheduleTalkTitle() {
    return this.selectedTalk?.name;
  }

  @computed get scheduleTalkText() {
    return t(
      'Select a date to schedule this talk for the {projectName} project.',
      {
        templateStrings: {
          projectName: this.project?.name
        }
      }
    );
  }

  @computed get scheduleSubmitText() {
    if (this.saving) {
      return t('Saving');
    }

    return t('Schedule');
  }

  @computed get minimumScheduleDate() {
    return Date.now();
  }

  @action.bound async scheduleTalk(talk) {
    await this.authorization.checkFeatureAccess('ScheduleToolboxTalks');

    this.selectedTalk = talk;

    this.scheduleTalkForm = new ScheduleTalkForm(
      {
        fields: scheduleTalkFormFields,
        rules: scheduleTalkFormRules,
        labels: scheduleTalkFormLabels,
        values: Object.assign(scheduleTalkFormValues, {
          date: moment().format('YYYY-MM-DD')
        })
      },
      {
        options: scheduleTalkFormOptions,
        plugins: scheduleTalkFormPlugins
      }
    );

    this.showModal('ToolboxTalksScheduleModal');
  }

  @action.bound submitScheduleTalkForm(event) {
    event.preventDefault();

    this.scheduleTalkForm.submit({
      onSuccess: this.submitScheduleTalkFormSuccess,
      onError: this.submitScheduleTalkFormError
    });
  }

  @action.bound async submitScheduleTalkFormSuccess() {
    this.saving = true;

    try {
      const values = this.scheduleTalkForm.values();

      const payload = {
        scheduleDate: moment(values.date).format('YYYY-MM-DD'),
        projectUuid: this.project.uuid,
        talk: {
          uuid: this.selectedTalk.uuid
        },
        groupUuids: values.groups.map(g => g.uuid)
      };

      await request.post(
        `${this.rootStore.urlMicroService('toolbox')}/tbt/projects/${
          this.project.uuid
        }/project-talks-groups`,
        payload
      );

      this.hideActiveModal();

      this.selectedTalk = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk scheduled')
      });

      callTrack(TBT_SCHEDULED, {
        project_id: this.project.id,
        project_name: this.project.name,
        company_level: false,
        project_level: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitScheduleTalkFormError() {
    console.error(this.scheduleTalkForm.errors());
  }

  @action.bound async cancelScheduleTalk() {
    await this.hideActiveModal();

    this.scheduleTalkForm = null;
    this.selectedTalk = null;
  }

  @action.bound async renameTalk(talk) {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    this.selectedTalk = talk;

    this.renameTalkForm = new RenameTalkForm(
      {
        fields: renameTalkFormFields,
        rules: renameTalkFormRules,
        labels: renameTalkFormLabels,
        values: talk.formValues
      },
      {
        options: renameTalkFormOptions,
        plugins: renameTalkFormPlugins
      }
    );

    this.showModal('RenameModal');
  }

  @action.bound submitRenameTalkForm(event) {
    event.preventDefault();

    this.renameTalkForm.submit({
      onSuccess: this.submitRenameTalkFormSuccess,
      onError: this.submitRenameTalkFormError
    });
  }

  @action.bound async submitRenameTalkFormSuccess() {
    this.saving = true;

    try {
      const values = this.renameTalkForm.values();

      await this.selectedTalk.save(
        {
          name: values.name
        },
        {
          wait: true
        }
      );

      this.hideActiveModal();

      this.selectedTalk = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Talk renamed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitRenameTalkFormError() {
    console.error(this.renameTalkForm.errors());
  }

  @action.bound async cancelRenameTalk() {
    await this.hideActiveModal();

    this.renameTalkForm = null;
    this.selectedTalk = null;
  }

  @computed get renameTalkTitle() {
    return t('Rename talk');
  }

  @computed get renameSubmitText() {
    if (this.saving) {
      return t('Saving');
    }

    return t('Rename');
  }

  @action.bound downloadTalk(talk) {
    crossOriginDownload(talk.document.contentUrl, talk.name);
  }

  @action.bound sortByLastCreated() {
    this.loading = true;
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;

    this.fetchTalksDebounced();
  }

  @action.bound refetchTalks() {
    this.loading = true;

    if (!this.hasTalks) {
      this.setPage(null, 1);
      this.fetchTalks();
    } else {
      this.fetchTalks();
    }
  }

  getActions = talk => {
    return [
      {
        title: t('Schedule'),
        onClick: () => {
          this.scheduleTalk(talk);
        }
      },
      {
        title: t('Rename'),
        onClick: () => {
          this.renameTalk(talk);
        }
      },
      {
        title: t('Download'),
        onClick: () => {
          this.downloadTalk(talk);
        }
      },

      {
        title: t('Delete'),
        onClick: () => {
          this.deleteTalk(talk);
        }
      }
    ];
  };

  @action.bound async addToolboxTalk() {
    await this.authorization.checkFeatureAccess('UploadToolboxTalks');

    history.push({
      pathname: `${this.project.viewUrl}/toolbox-talks/all/add`,
      search: this.baseQueryParams
    });
  }

  @computed get selectedGroupUuids() {
    return this.scheduleTalkForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}

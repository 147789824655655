import moment from 'moment';
import User from '../User';
import { computed } from 'mobx';

export default class SuperAdminCompanyTeamMember extends User {
  constructor(data, options) {
    super(data, options);
  }

  get restAttributes() {
    return [
      'type',
      'firstName',
      'lastName',
      'uuid',
      'username',
      'phone',
      'lastLogin',
      'role',
      'status',
      'avatar',
      'approvalPending',
      'userId',
      'inviteId',
      'createdDate',
      'ssoBypass'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get formattedLastLogin() {
    if (this.lastLogin) {
      return moment(this.lastLogin).format(`h:mm A on YYYY-MM-DD`);
    }

    return null;
  }

  get isPendingApproval() {
    if (this.approvalPending) return true;

    return false;
  }

  @computed
  get createdDateFormatted() {
    return moment(this.createdDate).format('YYYY-MM-DD');
  }

  @computed
  get avatarImage() {
    return (this.avatar && this.avatar.thumbUrl) || null;
  }

  @computed get initials() {
    return `${this.firstName
      ?.toUpperCase()
      .charAt(0)} ${this.lastName?.toUpperCase().charAt(0)}`;
  }

  @computed
  get isWorker() {
    return this.role === 'ROLE_WORKER';
  }
}

import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { reaction, computed } from 'mobx';

const checklistsBatchExportFormPlugins = { dvr: validatorjs };

const checklistsBatchExportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const checklistsBatchExportFormFields = [
  'timeFrameType',
  'fromDate',
  'toDate',
  'projectIncludeOption',
  'projects',
  'groups',
  'checklistName',
  'emails',
  'email',
  'templates'
];

const checklistsBatchExportFormRules = {
  timeFrameType: 'required',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  groups: 'array|required_if:projectIncludeOption,SELECTED_GROUPS',
  emails: 'array|required',
  email: 'required_without:emails|email',
  templates: 'array|required'
};

const checklistsBatchExportFormLabels = {};

const checklistsBatchExportFormValues = {
  projectIncludeOption: '',
  projects: [],
  groups: [],
  checklistName: '',
  email: '',
  templates: []
};

class ChecklistsBatchExportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|string');
          this.$('toDate').set('rules', 'required|string');
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('timeFrameType').value === 'SPECTIMEFRAME';
  }
}

export {
  ChecklistsBatchExportForm,
  checklistsBatchExportFormRules,
  checklistsBatchExportFormFields,
  checklistsBatchExportFormLabels,
  checklistsBatchExportFormOptions,
  checklistsBatchExportFormPlugins,
  checklistsBatchExportFormValues
};

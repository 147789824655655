import { action, observable, computed } from 'mobx';
import PolicyChildEditUI from './PolicyChildEditUI';
import history from 'utils/history';
import {
  PolicyForm,
  policyFormPlugins,
  policyFormOptions,
  policyFormFields,
  policyFormRules,
  policyFormLabels,
  policyFormValues
} from 'forms/policy';

import Policy from 'stores/models/time/Policy';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class PolicyAddUI extends PolicyChildEditUI {
  @observable form;

  constructor(options) {
    super(options);

    this.form = null;
  }

  @action.bound async setup() {
    this.entryForEdit = new Policy(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound setupForm() {
    this.form = new PolicyForm(
      {
        fields: policyFormFields,
        rules: policyFormRules,
        labels: policyFormLabels,
        values: policyFormValues
      },
      {
        options: policyFormOptions,
        plugins: policyFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.activeModal = null;
    this.entryForEdit = null;
    this.form = null;
    this.saving = false;
  }

  @action.bound
  async submitPolicyAddSuccess() {
    this.saving = true;

    const { name, isDefault } = this.form.values();

    try {
      await this.entryForEdit.save(
        {
          name,
          default: isDefault
        },
        {
          wait: true
        }
      );

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Policy added')
      });
      this.unblockHistory();

      history.push({
        pathname: `/company/time/policies/${this.entryForEdit.uuid}/settings`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitPolicyAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitPolicyAddSuccess,
      onError: e => {
        console.error(this.form.errors());
      }
    });
  }

  @action.bound
  cancelPolicyEdit() {
    history.push({
      pathname: `/company/time/policies/manage`
    });
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `/company/time/policies/add`
        },
        label: t('Settings')
      }
    ];
  }
}

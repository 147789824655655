import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';

import User from 'stores/models/User';
import TeamMemberInfoUI from './TeamMemberInfoUI';
import TeamMemberDefaultsUI from './TeamMemberDefaultsUI';
import TeamMemberProjectsUI from './TeamMemberProjectsUI';
import TeamMemberCertificationsUI from './TeamMemberCertificationsUI';
import TeamMemberNotificationsUI from './TeamMemberNotificationsUI';
import TeamMemberTasksUI from './TeamMemberTasksUI';
import TeamMemberResetPasswordUI from './TeamMemberResetPasswordUI';
import TeamMemberEmailPreferencesUI from './TeamMemberEmailPreferencesUI';

export default class TeamMemberProfileUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable previousPath;

  constructor(options) {
    super(options);
    // Editing
    this.entryForEdit = null;

    // Going back
    this.previousPath = undefined;

    this.teamMemberInfoUI = new TeamMemberInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberDefaultsUI = new TeamMemberDefaultsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberProjectsUI = new TeamMemberProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberCertificationsUI = new TeamMemberCertificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberNotificationsUI = new TeamMemberNotificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberTasksUI = new TeamMemberTasksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberResetPasswordUI = new TeamMemberResetPasswordUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamMemberEmailPreferencesUI = new TeamMemberEmailPreferencesUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(previousPath, id) {
    this.setPreviousPath(previousPath);
    this.findOrFetchMember(id);
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @computed get teamMembersActionsUI() {
    return this.parent.teamMembersActionsUI;
  }

  @computed
  get members() {
    return this.parent.members;
  }

  @action.bound setPreviousPath(previousPath) {
    if (previousPath) {
      this.previousPath = previousPath;
    } else {
      this.previousPath = undefined;
    }
  }

  @action.bound async findOrFetchMember(memberUuid) {
    if (this.entryForEdit || this.fetchingEntryForEdit) return;

    let memberModel = this.members.models.find(
      member => member.uuid === memberUuid
    );

    if (memberModel) {
      this.fetchingEntryForEdit = true;

      await memberModel.fetch();

      this.setEntryForEdit(memberModel);

      this.fetchingEntryForEdit = false;
    } else {
      memberModel = new User(
        {
          uuid: memberUuid
        },
        {
          rootStore: this.rootStore
        }
      );

      try {
        this.fetchingEntryForEdit = true;

        await memberModel.fetch();

        this.setEntryForEdit(memberModel);
      } catch (error) {
        this.goBack(true);
      } finally {
        this.fetchingEntryForEdit = false;
      }
    }
  }

  @action.bound async setEntryForEdit(member) {
    this.entryForEdit = member;
  }

  @action.bound clearUIState() {
    this.nextUrl = null;
    this.entryForEdit = null;
    this.fetchingEntryForEdit = false;
    this.previousPath = undefined;
  }

  @computed get goBackUrl() {
    if (this.previousPath) return this.previousPath;

    if (this.authorization.canViewTeam) {
      return '/team';
    } else {
      return '/projects';
    }
  }

  @action.bound goBack(replaceHistory = false) {
    if (replaceHistory) {
      history.replace(this.goBackUrl);
    } else {
      history.push(this.goBackUrl);
    }
  }

  @computed
  get showProjectsNotificationsTasks() {
    return (
      (this.entryForEdit.isTeamMember &&
        !this.rootStore.me.isPending &&
        this.rootStore.me.isAdmin) ||
      this.entryForEdit.isMe
    );
  }

  @computed get profileLinks() {
    let links = [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      }
    ];

    if (this.showProjectsNotificationsTasks && !this.entryForEdit.inviteId) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/attributes`,
            state: { previousPath: this.previousPath }
          },
          label: t('Default attributes')
        }
      ]);
    }

    if (this.showProjectsNotificationsTasks) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/projects`,
            state: { previousPath: this.previousPath }
          },
          label: t('Projects')
        }
      ]);
    }

    if (!this.entryForEdit.invitedOrPending) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/certifications`,
            state: { previousPath: this.previousPath }
          },
          label: t('Certifications')
        }
      ]);
    }

    if (this.showProjectsNotificationsTasks && !this.entryForEdit.inviteId) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/notifications`,
            state: { previousPath: this.previousPath }
          },
          label: t('Notifications')
        },
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/tasks`,
            state: { previousPath: this.previousPath }
          },
          label: t('Tasks')
        }
      ]);
    }

    if (this.entryForEdit.id === this.me.id) {
      links = links.concat([
        {
          path: {
            pathname: `${this.entryForEdit.viewUrl}/email-preferences`,
            state: { previousPath: this.previousPath }
          },
          label: t('Email preferences')
        }
      ]);
    }

    return links;
  }
}

import { t } from 'utils/translate';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

export default class DirectoryNavigationUI extends UIStore {
  @computed get items() {
    return {
      id: 'team',
      icon: 'users',
      label: t('Directory'),
      link: '/team',
      subnav: [
        {
          id: 'users',
          label: t('Users'),
          icon: 'users',
          link: '/team'
        },
        {
          id: 'companies',
          label: t('Companies'),
          icon: 'building',
          link: '/companies'
        },
        {
          id: 'contacts',
          label: t('All contacts'),
          icon: 'default-attributes',
          link: '/contacts'
        }
      ]
    };
  }
}

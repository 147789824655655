import { computed } from 'mobx';
import { Model } from 'mobx-mc';

import moment from 'moment';

export default class SmallEquipmentHistory extends Model {
  get restAttributes() {
    return [
      'equipmentUuid',
      'projectUuid',
      'projectName',
      'deployedUnits',
      'withdrawnUnits',
      'writtenOffUnits',
      'totalDeployedUnits',
      'member',
      'createdTimestamp'
    ];
  }

  @computed get dateFormatted() {
    return moment(this.createdTimestamp).format('MMM D, h:mma');
  }
}

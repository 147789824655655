import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CERTIFICATION_TYPE_EDITED } from 'utils/segmentAnalytics/eventSpec';
import {
  CertificationTypeForm,
  certificationTypeFormFields,
  certificationTypeFormLabels,
  certificationTypeFormOptions,
  certificationTypeFormPlugins,
  certificationTypeFormRules
} from 'forms/certificationTypeForm';

import CertificationType from 'stores/models/CertificationType';
import CertificationTypes from 'stores/collections/CertificationTypes';

export default class CertificationTypeEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.certificationTypes = new CertificationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryForEdit = null;
    this.entryEditForm = null;
  }

  @action.bound async setup(id) {
    this.certificationTypes.fetch({
      params: {
        limit: 10000
      }
    });
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.certificationTypes.get(uuid);

      if (!model) {
        model = new CertificationType(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelCertificationTypeEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
    this.setEntryEditForm();
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new CertificationTypeForm(
      {
        fields: certificationTypeFormFields,
        rules: certificationTypeFormRules,
        labels: certificationTypeFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: certificationTypeFormOptions,
        plugins: certificationTypeFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.certificationTypes.clear();
    this.activeModal = null;
    this.entryForEdit = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @action.bound
  submitCertificationTypeEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitCertificationTypeEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  async submitCertificationTypeEditSuccess() {
    this.saving = true;
    const { name, description } = this.entryEditForm.trimmedValues();
    try {
      await this.entryForEdit.save(
        {
          name,
          description
        },
        {
          wait: true
        }
      );

      callTrack(CERTIFICATION_TYPE_EDITED, {
        certification_type_name: name,
        certification_type_description: description
      });

      this.resetEntryEditForm();

      this.parent.sortByLastUpdated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certification type saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @action.bound
  cancelCertificationTypeEdit() {
    history.push({
      pathname: `/company/certifications/types`,
      search: this.baseQueryParams
    });
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      }
    ];
  }

  @computed get showUI() {
    return this.entryForEdit && !this.entryForEdit.fetching;
  }
}

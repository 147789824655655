import omit from 'lodash.omit';
import flatten from 'lodash.flatten';
import orderBy from 'lodash.orderby';
import { action, observable, computed, toJS } from 'mobx';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import axiosDownload from 'utils/axiosDownload';

import UIStore from 'stores/ui/UIStore';

export default class IntegrationMappingUI extends UIStore {
  @observable selectedExternalCompany;
  @observable selectedExternalProject;
  @observable selectedRakenProject;
  @observable mappedProjectsSortDirection;
  @observable mappedProjectSortField;
  @observable page;

  constructor(options) {
    super(options);

    this.mappedProjectsSortDirection = null;
    this.mappedProjectSortField = null;

    this.selectedExternalCompany = null;
    this.selectedExternalProject = null;
    this.selectedRakenProject = null;

    //Pagination
    this.page = 1;
    this.pageSize = 10;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @action.bound fetchProjectOptions() {
    this.projectSelectorUI.setProjectStates(['ACTIVE']);
    this.projectSelectorUI.fetchProjectOptions();
  }

  @action.bound resetProjectOptions() {
    this.projectSelectorUI.resetProjectOptions();
  }

  @computed get disabledAddConfigurationButton() {
    if (this.saving) return true;

    return (
      !this.selectedRakenProject ||
      !this.selectedExternalCompany ||
      !this.selectedExternalProject
    );
  }

  @computed get sortedExternalCompanies() {
    return orderBy(
      this.activeIntegration.external,
      [external => external.company.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get projectAutoCompleteOptions() {
    if (this.projectSelectorUI.projectOptions.hasModels) {
      const projectOptions = this.projectSelectorUI.projectOptions.models.map(
        projectOption => ({
          uuid: projectOption.uuid, // TODO: Switch to only uuid when Integration Router supports it.
          value: projectOption.teamId,
          name: projectOption.name
        })
      );

      return orderBy(
        projectOptions.filter(option => {
          return !this.activeIntegration.mappings.find(mapping => {
            return mapping.projectId === option.value;
          });
        }),
        option => option.name
      );
    }

    return [];
  }

  @computed get projectAutoCompleteNoOptionsText() {
    if (this.projectSelectorUI.searchingForProject) {
      return t('Searching');
    }

    if (!this.projectAutoCompleteOptions.length) {
      return t('No results');
    }

    return false;
  }

  @action.bound toggleMappedProjectsSorting(sortField) {
    this.page = 1;
    if (this.mappedProjectsSortDirection === 'asc') {
      this.mappedProjectsSortDirection = 'desc';
      this.mappedProjectSortField = sortField;
    } else if (this.mappedProjectsSortDirection === 'desc') {
      this.mappedProjectsSortDirection = null;
      this.mappedProjectSortField = null;
    } else {
      this.mappedProjectsSortDirection = 'asc';
      this.mappedProjectSortField = sortField;
    }
  }

  @computed
  get notMappedExternalProjects() {
    const selectedExternal = this.activeIntegration.external.find(
      external => external.company.id === this.selectedExternalCompany?.id
    );

    if (selectedExternal) {
      const notMappedSelectedExternalProjects = selectedExternal.projects.reduce(
        (result, externalProject) => {
          const mapped = this.activeIntegration.mappings.find(map => {
            return (
              map.externalCompanyId === selectedExternal.company.id &&
              map.externalProjectId === externalProject.id
            );
          });
          if (mapped) {
            return result;
          }
          result.push(externalProject);
          return result;
        },
        []
      );
      return notMappedSelectedExternalProjects;
    }

    return [];
  }

  @computed get externalCompanyAutoCompleteOptions() {
    return this.externalCompanies.map(company => {
      return {
        id: company.id,
        title: company.name
      };
    });
  }

  @computed get externalProjectAutoCompleteOptions() {
    return this.notMappedExternalProjects.map(project => {
      return {
        id: project.id,
        title: project.name
      };
    });
  }

  @action.bound
  setExternalCompanyId(value) {
    this.selectedExternalCompany = value;
  }

  @action.bound
  setExternalProjectId(value) {
    this.selectedExternalProject = value;
  }

  @action.bound
  setRakenProject(option) {
    this.selectedRakenProject = option;
    this.projectSelectorUI.clearProjectOptionsSearchQuery();
  }

  @computed
  get externalCompanies() {
    return this.activeIntegration?.external.map(external => external.company);
  }

  @computed
  get externalProjects() {
    const selectedExternal = this.activeIntegration.external.find(
      external => external.company.id === this.selectedExternalCompany?.id
    );

    if (selectedExternal) {
      return selectedExternal.projects;
    }

    return [];
  }

  @action.bound
  removeMapping(externalProjectId) {
    const mappings = this.activeIntegration.mappings.slice().filter(mapping => {
      return mapping.externalProjectId !== externalProjectId;
    });

    this.activeIntegration.mappings = mappings;

    return this.activeIntegration.save(
      {
        mappings: toJS(mappings)
      },
      { wait: true }
    );
  }

  @action.bound
  async addMapping() {
    let payload;

    this.clearValidationDetails();

    payload = {
      externalProjectId: this.selectedExternalProject.id,
      externalCompanyId: this.selectedExternalCompany.id,
      projectId: this.selectedRakenProject.value,
      projectUuid: this.selectedRakenProject.uuid
    };

    const mappings = [
      payload,
      ...this.activeIntegration.mappings.slice().map(mapping => {
        return omit(mapping, 'rakenProject');
      })
    ];

    try {
      this.saving = true;

      await this.activeIntegration.save(
        {
          mappings: mappings
        },
        {
          wait: true
        }
      );

      this.resetProjectOptions();
      this.clearSelectionForMapping();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  clearSelectionForMapping() {
    this.selectedExternalProject = null;
    this.selectedExternalCompany = null;
    this.selectedRakenProject = null;
  }

  @computed
  get mappingCollection() {
    const results = [];

    const externalCompanies = this.activeIntegration.external.map(
      external => external.company
    );

    const externalProjects = flatten(
      this.activeIntegration.external.map(external => external.projects.slice())
    );

    this.activeIntegration.mappings.forEach(mapping => {
      const mappingCollectionEntry = {};

      if (mapping.externalCompanyId) {
        const externalCompany = (mappingCollectionEntry.company = externalCompanies.find(
          externalCompany => externalCompany.id === mapping.externalCompanyId
        ));

        if (externalCompany) {
          mappingCollectionEntry.company = externalCompany;
        } else {
          mappingCollectionEntry.company = {
            id: mapping.externalCompanyId,
            name: `Unknown: ${mapping.externalCompanyId}`
          };

          mappingCollectionEntry.externalCompanyHasError = true;
        }
      }

      if (mapping.externalProjectId) {
        const externalProject = externalProjects.find(
          externalProject => externalProject.id === mapping.externalProjectId
        );

        if (externalProject) {
          mappingCollectionEntry.project = externalProject;
        } else {
          mappingCollectionEntry.project = {
            id: mapping.externalProjectId,
            name: `Unknown: ${mapping.externalProjectId}`
          };

          mappingCollectionEntry.externalProjectHasError = true;
        }
      }

      if (mapping.projectId) {
        mappingCollectionEntry.rakenProject = mapping.rakenProject;
      }

      results.push(mappingCollectionEntry);
    });

    return results;
  }

  @computed get sortedMappingCollection() {
    if (!this.mappedProjectSortField) {
      return this.mappingCollection;
    }

    return orderBy(
      this.mappingCollection,
      [
        mapping => {
          return mapping[this.mappedProjectSortField].name?.toLowerCase();
        }
      ],
      [this.mappedProjectsSortDirection]
    );
  }

  //Pagination
  @computed
  get totalPages() {
    return Math.ceil(this.sortedMappingCollection.length / this.pageSize);
  }

  @action
  setPage = page => {
    this.page = page;
  };

  @computed
  get paginatedSortedMappingCollection() {
    return this.sortedMappingCollection.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.clearSelectionForMapping();
  }

  @action.bound async downloadRakenQWCFile() {
    this.clearValidationDetails();

    try {
      await axiosDownload(
        `${this.activeIntegration.url()}/raken.qwc`,
        'raken.qwc'
      );
    } catch (error) {
      alertErrorHandler(
        {
          response: {
            data: JSON.parse(await error.response.data.text())
          }
        },
        this.setValidationDetails
      );
    }
  }
}

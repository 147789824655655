import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { action, computed } from 'mobx';
import { Model } from 'mobx-mc';
import { t } from 'utils/translate';

class SuperAdminAuditEntry extends Model {
  get restAttributes() {
    return [
      'id',
      'businessId',
      'command',
      'commandId',
      'component',
      'createdTimestamp',
      'eventId',
      'integrationId',
      'module',
      'projectId',
      'status',
      'timestamp',
      'traceId',
      'ttl',
      'updatedTimestamp',
      'userId',
      'env',
      'error',
      'transitions'
    ];
  }

  @computed get action() {
    return this.command.action;
  }

  @computed get entityTypeFormatted() {
    return this.command.entityType?.toUpperCase() || t('Unknown');
  }

  @computed get actionFormatted() {
    switch (this.command.type) {
      case 'IntegrationConnectedCommand':
        return t('Integration connected');
      case 'SyncWorklogCommand':
        return t('Worklog synced');
      case 'DeleteWorklogCommand':
        return t('Worklog deleted');
      case 'SyncNoteCommand':
        return t('Note synced');
      case 'DeleteNoteCommand':
        return t('Note deleted');
      case 'SyncChecklistCommand':
        return t('Checklist synced');
      case 'SyncTbtCommand':
        return t('Toolbox Talk synced');
      case 'TimecardBatchSyncCommand':
        return t('Time card batch synced');
      case 'RyvitCommand':
        return `${t('Ryvit action synced')} (${this.command.actionCode ||
          t('Unknown')} ${this.command.dataObjectCode || t('Unknown')})`;
      case 'RyvitActionPageCommand':
        return `${t('Ryvit action polled')} (${
          this.command.actionIds?.length
        })`;
      case 'OnDemandSyncCommand':
        return `${t('On Demand sync initiated')} (${this.entityTypeFormatted})`;
      case 'QBOCommand':
        return `${
          this.command.data?.Active === false
            ? t('QBO entity deleted')
            : t('QBO entity synced')
        } (${this.entityTypeFormatted})`;
      case 'QBDBatchCommand':
        return `${t('QBD entity batch synced')} (${this.entityTypeFormatted})`;
      case 'IntacctCommand':
        return `${
          this.command.data?.STATUS === 'active'
            ? t('Sage Intacct entity synced')
            : t('Sage Intacct entity deleted')
        } (${this.entityTypeFormatted})`;
      case 'IntacctBatchCommand':
        return `${t('Sage Intacct entity batch synced')} (${
          this.entityTypeFormatted
        })`;
      case 'ComputerEaseCommand':
        return `${
          this.command.data?.active === false
            ? t('ComputerEase entity deleted')
            : t('ComputerEase entity synced')
        } (${this.entityTypeFormatted})`;
      case 'ComputerEaseBatchCommand':
        return `${t('ComputerEase entity batch synced')} (${
          this.entityTypeFormatted
        })`;
      case 'FileUploadCommand': {
        switch (this.action) {
          case 'SEND_ATTACHMENT':
            return t('Attachment uploaded');
          case 'SEND_REPORT':
          case 'SEND_SUPER_DAILY':
          case 'SEND_DAILY_SC_FOR_GC':
            return t('Report signed');
        }
      }
    }
    return t('Unknown');
  }

  @computed get timestampFormatted() {
    return this.formatTimestamp(this.timestamp);
  }

  @computed get updatedTimestampFormatted() {
    return this.formatTimestamp(this.updatedTimestamp);
  }

  @computed get statusText() {
    return capitalize(this.status.replace(/_/g, ' ').toLowerCase());
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'SUCCESS':
        return 'green';
      case 'FAILED':
        return 'red';
      case 'IN_PROGRESS':
        return 'orange';
      default:
        return 'orange';
    }
  }

  @computed get latestTraceId() {
    if (this.transitions.length > 0) {
      return this.transitions[this.transitions.length - 1].traceId;
    }

    return this.traceId;
  }

  @computed get traceUrl() {
    return `http://ui.honeycomb.io/raken/datasets/${this.env}/trace?trace_id=${
      this.latestTraceId
    }&trace_start_ts=${moment(this.createdTimestamp)
      .subtract(2, 'seconds')
      .unix()}&trace_end_ts=${moment(this.updatedTimestamp)
      .add(2, 'seconds')
      .unix()}`;
  }

  formatTimestamp(timestamp) {
    return moment(timestamp).format('YYYY-MM-DD, HH:mm:ss');
  }

  @action.bound parse(data) {
    return {
      id: data._id,
      ...data._source
    };
  }
}

export default SuperAdminAuditEntry;

import { action, computed, observable } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import history from 'utils/history';

import { t } from 'utils/translate';

export default class PolicyViewUI extends UIStore {
  @observable selectedPolicy;

  constructor(options) {
    super(options);

    this.selectedPolicy = null;
  }

  @action.bound async setup() {
    if (this.activePolicy) {
      this.activePolicy.fetch();
    }
  }

  @computed get activePolicy() {
    return this.selectedPolicy || this.defaultPolicy;
  }

  @computed get loading() {
    return this.parent.loading || this.activePolicy.fetching;
  }

  @computed get defaultPolicy() {
    return this.parent.defaultPolicy;
  }

  @computed get policySelectOptions() {
    return this.parent.policies.models?.slice();
  }

  @action.bound setSelectedPolicy(option) {
    this.selectedPolicy = option;
  }

  @computed get restBreakDisplayText() {
    const breakType = this.activePolicy.breakTypes.find(
      breakType => breakType.type === 'REST'
    );
    const compensation = breakType?.billable ? t('paid') : t('unpaid');

    return breakType
      ? `${breakType?.defaultDuration} ${t('minute')} ${compensation} ${t(
          'rest break'
        )}`
      : ``;
  }

  @computed get mealBreakDisplayText() {
    const breakType = this.activePolicy.breakTypes.find(
      breakType => breakType.type === 'MEAL'
    );
    const compensation = breakType?.billable ? t('paid') : t('unpaid');

    return breakType
      ? `${breakType?.defaultDuration} ${t('minute')} ${compensation} ${t(
          'meal break'
        )}`
      : ``;
  }

  @computed get weeklyOTRDisplayText() {
    const weeklyText = this.activePolicy.overtimeRuleSet.rules
      .map(rule => {
        const code = rule.payType.code;
        const limit = rule.weeklyLimit
          ? `for ${rule.weeklyLimit} hrs per week. `
          : null;

        return limit
          ? `${code} ${limit}`
          : `All additional hours will be allocated to ${code}.`;
      })
      .join('');

    return this.activePolicy.overtimeRuleSet.type === 'WEEKLY' ||
      this.activePolicy.overtimeRuleSet.type === 'BOTH'
      ? weeklyText
      : t(`None`);
  }

  @computed get dailyOTRDisplayText() {
    const dailyText = this.activePolicy.overtimeRuleSet.rules
      .map(rule => {
        const code = rule.payType.code;
        const limit = rule.dailyLimit
          ? `for ${rule.dailyLimit} hrs per day. `
          : null;

        return limit
          ? `${code} ${limit}`
          : `All additional hours will be allocated to ${code}.`;
      })
      .join('');

    return this.activePolicy.overtimeRuleSet.type === 'DAILY' ||
      this.activePolicy.overtimeRuleSet.type === 'BOTH'
      ? dailyText
      : t(`None`);
  }

  @action.bound managePolicies() {
    history.push({
      pathname: `/company/time/policies/manage`
    });
  }

  @action.bound async editPolicy() {
    await this.authorization.checkFeatureAccess('ReviewTime');

    this.parent.redirectToViewModeAfterEdit = true;

    history.push({
      pathname: `/company/time/policies/${this.activePolicy.uuid}/settings`
    });
  }
}

import { computed, action } from 'mobx';

import {
  SmallEquipmentWithdrawForm,
  smallEquipmentWithdrawFormOptions,
  smallEquipmentWithdrawFormFields,
  smallEquipmentWithdrawFormRules,
  smallEquipmentWithdrawFormLabels,
  smallEquipmentWithdrawFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentWithdraw';

import SmallEquipmentWithdrawUI from 'stores/ui/company/equipment/smallEquipment/SmallEquipmentWithdrawUI';

export default class ProjectSmallEquipmentWithdrawUI extends SmallEquipmentWithdrawUI {
  constructor(options) {
    super(options);
  }

  @computed get selectedSmallEquipment() {
    return this.parent.selectedSmallEquipment;
  }

  @action.bound async bulkWithdrawSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.setupForm();

    this.showModal('SmallEquipmentWithdrawModalPage');
  }

  @action.bound setupForm() {
    this.smallEquipmentWithdrawForm = new SmallEquipmentWithdrawForm(
      {
        fields: smallEquipmentWithdrawFormFields,
        rules: smallEquipmentWithdrawFormRules,
        labels: smallEquipmentWithdrawFormLabels,
        values: {
          withdraws: this.selectedSmallEquipment.map(smallEquipment => ({
            equipmentUuid: smallEquipment.equipmentUuid,
            equipmentName: smallEquipment.equipmentName,
            projectName: smallEquipment.projectName,
            projectUuid: smallEquipment.projectUuid,
            projectDeployedUnits: smallEquipment.projectDeployedUnits,
            numberOfUnits: 0
          }))
        }
      },
      {
        options: smallEquipmentWithdrawFormOptions,
        plugins: smallEquipmentWithdrawFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentWithdrawForm
      .$('withdraws')
      .fields.forEach(withdraw =>
        withdraw
          .$('numberOfUnits')
          .set(
            'rules',
            `numeric|min:0|max:${withdraw.$('projectDeployedUnits').value}`
          )
      );
  }
}

import moment from 'moment';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import { action, observable, computed, reaction } from 'mobx';
import UIStore from './UIStore';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import Tasks from '../collections/Tasks';
import MemberSelectorUI from './MemberSelectorUI';
import { callTrack } from 'utils/segmentIntegration';
import { TASK_COMPLETED } from 'utils/segmentAnalytics/eventSpec';
import {
  TasksFiltersForm,
  tasksFiltersFormRules,
  tasksFiltersFormFields,
  tasksFiltersFormOptions,
  tasksFiltersFormPlugins
} from 'forms/project/tasksFilters';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import { t } from 'utils/translate';

export default class TeamMemberNotificationsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable filterForm;
  @observable selectedTask;

  constructor(options) {
    super(options);

    this.tasks = new Tasks(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;
    this.selectedTask = null;
    this.sortField = 'dueDate';
    this.sortDirection = 'desc';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.assigneeFilters = observable([]);
    this.creatorFilters = observable([]);
    this.statusFilters = observable([{ id: 'OPEN', title: 'Open' }]);
    this.projectFilters = observable([]);
    this.assigneeSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.creatorSelectorUI = new MemberSelectorUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchTasksDebounced = debounce(this.fetchTasks, BASE_DEBOUNCE);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    await Promise.all([
      this.creatorSelectorUI.setup({
        role: [
          'ROLE_ACCOUNT_ADMIN',
          'ROLE_ADMIN',
          'ROLE_PROJECT_MEMBER',
          'ROLE_USER'
        ]
      }),
      this.assigneeSelectorUI.setup({
        role: [
          'ROLE_ACCOUNT_ADMIN',
          'ROLE_ADMIN',
          'ROLE_PROJECT_MEMBER',
          'ROLE_USER'
        ]
      }),
      this.projectSelectorUI.setup()
    ]);

    this.creatorFilters.replace([
      {
        uuid: this.me.uuid,
        name: this.me.fullName
      }
    ]);

    await this.fetchTasks();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.assigneeSelectorUI.tearDown();
    this.creatorSelectorUI.tearDown();
    this.projectSelectorUI.tearDown();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchTasks();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchTasksDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchTasks() {
    this.loading = true;

    await this.tasks.fetch({
      url: `ra/users/${this.entryForEdit.userId}/tasks`,
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      mc: 'full',
      mn: 'full',
      status: this.statusFilterParams,
      assigneeUuids: this.assigneeFilters
        .map(assignee => assignee.uuid)
        .join(','),
      createdByUuids: this.creatorFilters
        .map(creator => creator.uuid)
        .join(','),
      projectUuids: this.projectFiltersParams
    };
  }

  @computed get hasTasks() {
    return this.tasks.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.tasks.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.tasks.reset();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    // Filters
    this.assigneeFilters.clear();
    this.creatorFilters.clear();
    this.statusFilters.replace([
      { id: 'OPEN', title: 'Open' },
      { id: 'COMPLETED', title: 'Completed' }
    ]);
    this.selectedTask = null;
  }

  @action.bound
  async toggleTaskStatus(task) {
    await this.authorization.checkFeatureAccess('EditTasks');

    let newStatus = task.status === 'OPEN' ? 'COMPLETED' : 'OPEN';

    if (newStatus === 'COMPLETED') {
      callTrack(TASK_COMPLETED, {
        complete_date: moment().format('l'),
        project_id: task.project.id,
        project_name: task.project.name,
        task_name: task.desc
      });
    }

    try {
      task.save(
        {
          status: newStatus
        },
        {
          parse: false
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async editTask(task) {
    history.push({
      pathname: `${task.project.viewUrl}/tasks/${task.uuid}`,
      search: this.baseQueryParams
    });
  }

  getTaskActions = task => {
    let actions = [
      {
        title: task.canEdit ? t('Edit') : t('View'),
        onClick: () => {
          this.editTask(task);
        }
      }
    ];

    if (task.canEdit) {
      actions = actions.concat([
        {
          title: t('Delete'),
          onClick: () => {
            this.deleteTask(task);
          }
        },
        {
          title: task.status === 'OPEN' ? t('Mark as complete') : t('Reopen'),
          onClick: () => {
            this.toggleTaskStatus(task);
          }
        }
      ]);
    }

    return actions;
  };

  @action.bound refetchTasks() {
    this.loading = true;
    if (!this.hasTasks) {
      this.setPage(null, 1);
      this.fetchTasks();
    } else {
      this.fetchTasks();
    }
  }

  @action.bound async deleteTask(task) {
    await this.authorization.checkFeatureAccess('DeleteTasks');

    this.selectedTask = task;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteTask() {
    await this.hideActiveModal();

    this.selectedTask = null;
  }

  @action.bound async confirmDeleteTask() {
    this.saving = true;

    try {
      await this.selectedTask.destroy({ wait: true });

      this.refetchTasks();

      await this.hideActiveModal();

      this.selectedTask = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Task deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound showFilters() {
    this.showModal('filters');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.statusFilters.clear();
    this.assigneeFilters.clear();
    this.creatorFilters.clear();
    this.projectFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.statusFilters.replace(values.statusFilters);
    this.assigneeFilters.replace(values.assigneeFilters);
    this.creatorFilters.replace(values.creatorFilters);
    this.projectFilters.replace(values.projectFilters);
    this.hideFilters();
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (this.statusFilters.length > 0) {
      count++;
    }

    if (this.assigneeFilters.length > 0) {
      count++;
    }

    if (this.creatorFilters.length > 0) {
      count++;
    }

    if (this.projectFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.filterForm = new TasksFiltersForm(
      {
        fields: tasksFiltersFormFields,
        rules: tasksFiltersFormRules,
        values: {
          statusFilters: this.statusFilters.slice(),
          assigneeFilters: this.assigneeFilters.slice(),
          creatorFilters: this.creatorFilters.slice(),
          projectFilters: this.projectFilters.slice()
        }
      },
      {
        options: tasksFiltersFormOptions,
        plugins: tasksFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.filterForm = null;
  }

  @action.bound setStatusFilters(options) {
    this.statusFilters.replace(options);
  }

  @computed get statusFilterParams() {
    return this.statusFilters.map(filter => filter.id).join(',');
  }

  @computed get projectFiltersParams() {
    return this.projectFilters.map(project => project.value);
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'OPEN', title: 'Open' },
      { id: 'COMPLETED', title: 'Completed' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }
}

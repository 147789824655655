import request from 'axios';
import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';
import { action, computed, observable, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import UIStore from 'stores/ui/UIStore';

import Workers from 'stores/collections/Workers';
import ComputerEaseDirectAPIEmployees from 'stores/collections/integrations/ComputerEaseDirectAPIEmployees';

import IntegrationComputerEaseDirectAPIEmployeeImportUI from './IntegrationComputerEaseDirectAPIEmployeeImportUI';
import IntegrationComputerEaseDirectAPIEmployeeMatchesUI from './IntegrationComputerEaseDirectAPIEmployeeMatchesUI';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

import IntegrationIds from 'fixtures/integrationIds';

export default class IntegrationComputerEaseDirectAPIEmployeeMappingUI extends UIStore {
  @observable loading;
  // Internal
  @observable rakenWorkerOptionsQuery;
  @observable selectedRakenWorkerOption;
  // External
  @observable loadingComputerEaseDirectAPIEmployeeOptions;
  @observable selectedComputerEaseDirectAPIEmployeeOption;
  @observable computerEaseDirectAPIEmployeeOptionsQuery;
  // Mappings
  @observable searchQuery;
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  @observable showSelectionExpandedAlert;
  @observable selectionExpanded;

  @observable showImportAlert;

  constructor(options) {
    super(options);

    this.loading = false;

    // Internal Options
    this.rakenWorkerOptionsQuery = '';
    this.selectedRakenWorkerOption = null;

    // Bulk actions
    this.selectedMappings = observable([]);
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;

    this.showImportAlert = false;

    this.rakenWorkerOptions = new Workers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchRakenWorkerOptionsDebounced = debounce(
      this.fetchRakenWorkerOptions,
      BASE_DEBOUNCE
    );

    // External options
    this.computerEaseDirectAPIEmployeeOptionsQuery = '';
    this.selectedComputerEaseDirectAPIEmployeeOption = null;

    this.computerEaseDirectAPIEmployeeOptions = new ComputerEaseDirectAPIEmployees(
      null,
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.fetchComputerEaseDirectAPIEmployeeOptionsDebounced = debounce(
      this.fetchComputerEaseDirectAPIEmployeeOptions,
      BASE_DEBOUNCE
    );

    // Mappings
    this.searchQuery = '';
    this.sortField = 'rakenWorker.fullName';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.computerEaseDirectAPIEmployeeMappings = new ComputerEaseDirectAPIEmployees(
      null,
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.integrationComputerEaseDirectAPIEmployeeImportUI = new IntegrationComputerEaseDirectAPIEmployeeImportUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );

    this.integrationComputerEaseDirectAPIEmployeeMatchesUI = new IntegrationComputerEaseDirectAPIEmployeeMatchesUI(
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get company() {
    if (this.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound async setup() {
    this.loading = true;

    this.setupReactions();

    await Promise.all([
      this.fetchRakenWorkerOptions(),
      this.fetchComputerEaseDirectAPIEmployeeOptions(),
      this.fetchComputerEaseDirectAPIEmployeeMappings()
    ]);

    this.loading = false;
  }

  @action.bound setupReactions() {
    this.cancelSearchRakenWorkerOptionsReaction = reaction(
      () => this.rakenWorkerOptionsQuery,
      query => {
        this.fetchRakenWorkerOptionsDebounced();
      }
    );

    this.cancelSearchComputerEaseDirectAPIEmployeeOptionsReaction = reaction(
      () => this.computerEaseDirectAPIEmployeeOptionsQuery,
      query => {
        this.fetchComputerEaseDirectAPIEmployeeOptionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.cancelSearchRakenWorkerOptionsReaction();
    this.cancelSearchComputerEaseDirectAPIEmployeeOptionsReaction();
  }

  @action.bound setRakenWorkerOptionsQuery(value) {
    this.rakenWorkerOptionsQuery = value;
  }

  @action.bound
  async fetchRakenWorkerOptions(options) {
    try {
      await this.rakenWorkerOptions.fetch({
        params: {
          sortField: 'firstName,lastName',
          sortDirection: 'asc',
          limit: 50,
          externalId: 'null',
          companyUuids: this.company.uuid,
          status: 'ACTIVE,INVITED',
          query: this.rakenWorkerOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  fetchNextRakenWorkerOptions = async rakenWorkerOptionsAutocomplete => {
    const dropdown = rakenWorkerOptionsAutocomplete.current;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.rakenWorkerOptions.fetchNextPage()?.then(() => {
        rakenWorkerOptionsAutocomplete.current.scrollTop =
          scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get rakenWorkerOptionsForRender() {
    return this.rakenWorkerOptions.models.slice();
  }

  @action.bound selectRakenWorkerOption(option) {
    this.selectedRakenWorkerOption = option;
    this.rakenWorkerOptionsQuery = '';
  }

  @action.bound setComputerEaseDirectAPIEmployeeOptionsQuery(value) {
    this.computerEaseDirectAPIEmployeeOptionsQuery = value;
  }

  @action.bound clearComputerEaseDirectAPIEmployeeOptionsQuery() {
    this.computerEaseDirectAPIEmployeeOptionsQuery = '';
  }

  @computed get hasComputerEaseDirectAPIEmployeeOptions() {
    return this.computerEaseDirectAPIEmployeeOptions.hasModels;
  }

  @action.bound
  async fetchComputerEaseDirectAPIEmployeeOptions(options) {
    this.loadingComputerEaseDirectAPIEmployeeOptions = true;

    try {
      await this.computerEaseDirectAPIEmployeeOptions.fetch({
        params: {
          limit: 50,
          unlinkedOnly: true,
          query: this.computerEaseDirectAPIEmployeeOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loadingComputerEaseDirectAPIEmployeeOptions = false;
    }
  }

  fetchNextComputerEaseDirectAPIEmployeeOptions = async scrollRef => {
    const scrollableElement = scrollRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const dropdownHeight = scrollableElement.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.computerEaseDirectAPIEmployeeOptions.fetchNextPage()?.then(() => {
        scrollRef.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get computerEaseDirectAPIEmployeeOptionsForRender() {
    return this.computerEaseDirectAPIEmployeeOptions.models.slice();
  }

  @action.bound selectComputerEaseDirectAPIEmployeeOption(option) {
    this.selectedComputerEaseDirectAPIEmployeeOption = option;
    this.computerEaseDirectAPIEmployeeOptionsQuery = '';
  }

  @action.bound
  clearUIState() {
    this.selectedMappings.clear();
    this.rakenWorkersOptionsQuery = '';
    this.rakenWorkerOptions.reset();
    this.computerEaseDirectAPIEmployeeOptions.reset();
    this.computerEaseDirectAPIEmployeeMappings.reset();
    this.computerEaseDirectAPIEmployeeOptionsQuery = '';
    this.clearSelectionExpanded();
    this.selectedRakenWorkerOption = null;
    this.selectedComputerEaseDirectAPIEmployeeOption = null;
    this.showImportAlert = false;
    this.loading = false;
    this.page = 1;
  }

  @computed get disableAddConfigurationButton() {
    if (!this.isSuperAdmin) return true;

    if (this.saving || this.loading) return true;

    return (
      !this.selectedRakenWorkerOption ||
      !this.selectedComputerEaseDirectAPIEmployeeOption
    );
  }

  @action.bound async saveMapping() {
    this.saving = true;
    const {
      selectedRakenWorkerOption,
      selectedComputerEaseDirectAPIEmployeeOption
    } = this;

    try {
      await selectedRakenWorkerOption.save(
        {
          externalId: selectedComputerEaseDirectAPIEmployeeOption.id
        },
        {
          wait: true
        }
      );

      this.computerEaseDirectAPIEmployeeMappings.add(
        Object.assign(selectedComputerEaseDirectAPIEmployeeOption.toJSON(), {
          rakenRef: selectedRakenWorkerOption.toJSON(),
          isNew: true
        })
      );

      this.sortField = 'isNew';
      this.sortDirection = 'asc';
      this.page = 1;

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Employee added')
      });

      this.refetchOptionsAfterChange();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async removeMapping(mapping) {
    const computerEaseDirectAPIEmployee = this.computerEaseDirectAPIEmployeeMappings.get(
      mapping.id
    );

    const rakenWorker = computerEaseDirectAPIEmployee.rakenWorker;

    try {
      await rakenWorker.save(
        {
          externalId: ''
        },
        {
          url: `/ra/companies/${this.activeCompany.uuid}/members/${rakenWorker.uuid}`,
          wait: true
        }
      );

      this.computerEaseDirectAPIEmployeeMappings.remove(
        computerEaseDirectAPIEmployee
      );

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Employee removed')
      });

      if (this.paginatedMappings.length < 1 && this.page > 1) {
        this.page = this.page - 1;
      }

      this.refetchOptionsAfterChange();
    } catch (error) {
      this.computerEaseDirectAPIEmployeeMappings.add(
        computerEaseDirectAPIEmployee
      );
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async resyncMapping(mapping) {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      const { data } = await request.post(
        `${this.integrationComputerEaseDirectAPIEmployeeImportUI.employees.url()}/sync`,
        {
          externalIds: [mapping.id]
        }
      );

      const successfulCount = data.collection.find(
        syncResult =>
          syncResult.externalId === mapping.id && syncResult.successful === true
      )
        ? 1
        : 0;
      const title = formatIntegrationSyncMessage(
        'Employee resync',
        successfulCount,
        1 - successfulCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound importComputerEaseDirectAPIEmployees() {
    history.push(
      `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/employees/import`
    );
  }

  @action.bound confirmComputerEaseDirectAPIEmployeeMatches() {
    history.push(
      `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/employees/matches`
    );
  }

  @computed get hasSelectedMappings() {
    return this.selectedMappings.length > 0;
  }

  findSelectedMapping = mappingId => {
    return this.selectedMappings.find(
      selectedMapping => selectedMapping.id === mappingId
    );
  };

  @action.bound async refetchOptionsAfterChange() {
    this.selectedRakenWorkerOption = null;
    this.selectedComputerEaseDirectAPIEmployeeOption = null;

    this.rakenWorkerOptionsQuery = '';
    this.computerEaseDirectAPIEmployeeOptionsQuery = '';

    this.rakenWorkerOptions.reset();
    this.computerEaseDirectAPIEmployeeOptions.reset();

    this.fetchRakenWorkerOptions();
    this.fetchComputerEaseDirectAPIEmployeeOptions();
  }

  // Mappings
  @action.bound
  async fetchComputerEaseDirectAPIEmployeeMappings() {
    try {
      await this.computerEaseDirectAPIEmployeeMappings.fetch({
        params: {
          limit: 1000,
          linkedOnly: true
        }
      });

      if (this.computerEaseDirectAPIEmployeeMappings.nextOffset) {
        this.fetchNextComputerEaseDirectAPIEmployeeMappings();
      }
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async fetchNextComputerEaseDirectAPIEmployeeMappings() {
    await this.computerEaseDirectAPIEmployeeMappings.fetchNextPage();

    if (this.computerEaseDirectAPIEmployeeMappings.nextOffset) {
      this.fetchNextComputerEaseDirectAPIEmployeeMappings();
    }
  }

  @computed get mappingsLoading() {
    return this.computerEaseDirectAPIEmployeeMappings.fetching;
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get mappings() {
    return this.computerEaseDirectAPIEmployeeMappings.models;
  }

  @computed get sortedMappings() {
    if (this.sortField === 'isNew') {
      return orderBy(
        this.mappings,
        [this.sortField, 'rakenWorker.fullName'],
        [this.sortDirection]
      );
    }

    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed get searchedMappings() {
    const query = this.searchQuery.toLowerCase();

    if (query) {
      return this.sortedMappings.filter(
        mapping =>
          mapping.name.toLowerCase().indexOf(query) >= 0 ||
          mapping.rakenWorker.fullName.toLowerCase().indexOf(query) >= 0
      );
    }

    return this.sortedMappings;
  }

  @computed get hasSearchedMappings() {
    return this.searchedMappings.length > 0;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.searchedMappings.length / this.pageSize);
  }

  @computed
  get paginatedMappings() {
    return this.searchedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @computed get showTable() {
    return this.hasSearchedMappings || this.mappingsLoading;
  }

  @computed get showEmptySearchState() {
    return (
      this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @computed get showEmptyState() {
    return (
      !this.searchQuery && !this.hasSearchedMappings && !this.mappingsLoading
    );
  }

  @action.bound setSearchQuery(value) {
    this.clearSelectionExpanded();
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.clearSelectionExpanded();
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(page) {
    this.clearSelectionExpanded();
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound expandSelection() {
    this.selectionExpanded = true;
  }

  @action.bound clearSelectionExpanded() {
    this.showSelectionExpandedAlert = false;
    this.selectionExpanded = false;
  }

  @computed get mappingsNotOnPage() {
    return this.sortedMappings.length - this.paginatedMappings.length;
  }

  @computed get selectionExpandedAlertText() {
    if (this.selectionExpanded) {
      return t(
        `{mappingsCount} configurations are selected. Would you like to clear your selection?`,
        {
          templateStrings: {
            mappingsCount: this.sortedMappings.length
          }
        }
      );
    }

    return t(
      `All {mappingsOnPage} configurations on this page are selected. Would you like to expand the selection to include {mappingsNotOnPage} configurations not shown?`,
      {
        templateStrings: {
          mappingsOnPage: this.paginatedMappings.length,
          mappingsNotOnPage: this.mappingsNotOnPage
        }
      }
    );
  }

  @action.bound
  toggleSelectMapping(mapping) {
    const selectedMapping = this.findSelectedMapping(mapping.id);

    this.clearSelectionExpanded();

    if (selectedMapping) {
      this.selectedMappings.remove(selectedMapping);
    } else {
      this.selectedMappings.push(mapping);
    }
  }

  @computed
  get allMappingsSelected() {
    if (!this.hasMappings) return false;

    return (
      this.paginatedMappings.filter(mapping =>
        this.findSelectedMapping(mapping.id)
      ).length === this.paginatedMappings.length
    );
  }

  @action.bound
  toggleSelectAllMappings() {
    if (this.allMappingsSelected) {
      this.clearSelectionExpanded();

      this.selectedMappings.replace(
        this.selectedMappings.filter(
          selectedMapping =>
            !this.paginatedMappings.some(
              mapping => mapping.id === selectedMapping.id
            )
        )
      );
    } else {
      if (this.page === 1) {
        this.showSelectionExpandedAlert = true;
      }

      this.paginatedMappings.forEach(mapping => {
        this.selectedMappings.push(mapping);
      });
    }
  }

  @action.bound clearAllSelectedMappings() {
    this.clearSelectionExpanded();
    this.selectedMappings.clear();
  }

  @computed get bulkActions() {
    return [
      {
        title: t('Resync selected employees'),
        onClick: () => {
          this.bulkResyncSelectedMappings();
        }
      },
      {
        title: t('Remove selected employees'),
        onClick: () => {
          this.bulkRemoveSelectedMappings();
        }
      }
    ];
  }

  @action.bound bulkResyncSelectedMappings() {
    this.showModal('BulkResyncSelectedMappings');
  }

  @action.bound async confirmBulkResyncSelectedMappings() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      this.saving = true;

      let externalIds;

      if (this.selectionExpanded) {
        externalIds = ['*'];
      } else {
        externalIds = this.selectedMappings.map(mapping => mapping.id);
      }

      const { data } = await request.post(
        `${this.integrationComputerEaseDirectAPIEmployeeImportUI.employees.url()}/sync`,
        {
          externalIds
        }
      );

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Employees resync',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.selectedMappings.clear();
      this.clearSelectionExpanded();
      this.refetchOptionsAfterChange();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound bulkRemoveSelectedMappings() {
    this.showModal('BulkRemoveSelectedMappings');
  }

  @action.bound async confirmBulkRemoveSelectedMappings() {
    this.saving = true;

    try {
      let mappings;

      if (this.selectionExpanded) {
        mappings = this.sortedMappings;
      } else {
        mappings = this.selectedMappings;
      }

      const payload = mappings.map(mapping => {
        return {
          uuid: mapping.rakenWorker.id,
          externalId: ''
        };
      });

      await request.patch(
        `ra/companies/${this.activeCompany.uuid}/members/externalIds`,
        payload
      );

      if (this.selectionExpanded) {
        this.computerEaseDirectAPIEmployeeMappings.clear();
      } else {
        this.computerEaseDirectAPIEmployeeMappings.remove(mappings.slice());
      }

      this.selectedMappings.clear();
      this.clearSelectionExpanded();
      this.refetchOptionsAfterChange();

      await this.hideActiveModal();

      // Go to previous page if current page is empty after bulk delete
      if (this.paginatedMappings.length < 1 && this.page > 1) {
        this.page = this.page - 1;
      }

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Employees removed')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentProjectActionFormPlugins = extendedPlugins;

const smallEquipmentProjectActionFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentProjectActionFormFields = [
  'project',
  'numberOfUnits',
  'availableUnits'
];

const smallEquipmentProjectActionFormLabels = {
  project: t('deploy to'),
  numberOfUnits: t('no. of units')
};

const smallEquipmentProjectActionFormValues = {
  project: '',
  numberOfUnits: ''
};

const smallEquipmentProjectActionFormRules = {
  numberOfUnits: 'required|numeric|min:0'
};

class SmallEquipmentProjectActionForm extends MobxReactForm {}

export {
  SmallEquipmentProjectActionForm,
  smallEquipmentProjectActionFormOptions,
  smallEquipmentProjectActionFormFields,
  smallEquipmentProjectActionFormRules,
  smallEquipmentProjectActionFormValues,
  smallEquipmentProjectActionFormLabels,
  smallEquipmentProjectActionFormPlugins
};

import { action, computed } from 'mobx';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import groupBy from 'lodash.groupby';
import UIStore from 'stores/ui/UIStore';
import MyTimeTimeCardsByDateUI from './MyTimeTimeCardsByDateUI';

import history from 'utils/history';
import uniqBy from 'lodash.uniqby';
import abbreviateNumber from 'utils/abbreviateNumber';
import { t } from 'utils/translate';

const moment = extendMoment(Moment);

export default class MyTimeSummaryUI extends UIStore {
  constructor(options) {
    super(options);

    this.myTimeTimeCardsByDateUI = new MyTimeTimeCardsByDateUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get title() {
    return t('Summary');
  }

  @computed get myTimeTimeCardAddUI() {
    return this.parent.myTimeTimeCardAddUI;
  }

  @computed get myTimeTimeCardAddPoliciesUI() {
    return this.parent.myTimeTimeCardAddPoliciesUI;
  }

  @computed get loading() {
    return this.parent.loading;
  }

  @computed get timeCards() {
    return this.parent.timeCards;
  }

  @computed get hasTimeCards() {
    return this.parent.hasTimeCards;
  }

  @computed get showEmptyState() {
    return this.parent.showEmptyState;
  }

  @computed get showUI() {
    return this.parent.showUI;
  }

  @computed get selectedPayPeriodDates() {
    return this.parent.selectedPayPeriodDates;
  }

  @computed get groupedTimeCards() {
    return groupBy(this.parent.timeCards.models, 'date');
  }

  @computed get timeCardSummariesByDate() {
    return this.selectedPayPeriodDates.map(date => {
      const timeCards = this.groupedTimeCards[date];

      if (timeCards?.length > 0) {
        return {
          date: date,
          dateFormatted: moment(date).format('ddd, MMM DD'),
          dateInFuture: moment(date).isAfter(moment(), 'day'),
          projects: this.getProjects(timeCards),
          ...this.getBreaksSummary(timeCards),
          totalHours: this.getTotalHours(timeCards),
          payTypes: this.getPayTypes(timeCards),
          totalPayRollNotes: this.getTotalPayRollNotes(timeCards),
          dayStartEnd: this.getDayStartEnd(timeCards),
          timeCards
        };
      } else {
        return {
          date: date,
          dateFormatted: moment(date).format('ddd, MMM DD'),
          dateInFuture: moment(date).isAfter(moment(), 'day'),
          projects: [],
          breaks: [],
          totalHours: 0,
          payTypes: [],
          totalPayRollNotes: 0,
          dayStartEnd: null,
          timeCards: []
        };
      }
    });
  }

  @computed get timeCardSummaryTotals() {
    const payTypes = this.getPayTypes(this.timeCards.models);

    return {
      hours: this.getTotalHours(this.timeCards.models),
      payTypes:
        payTypes.length > 0
          ? payTypes.map((payType, index) => {
              return `${payType.code}  ${abbreviateNumber(payType.hours)}${
                index < payTypes.length - 1 ? ', ' : ''
              } `;
            })
          : '-'
    };
  }

  getProjects = timeCards => {
    return uniqBy(
      timeCards.map(timeCard => {
        return {
          uuid: timeCard.projectUuid,
          name: timeCard.projectName,
          projectNumber: timeCard.projectNumber
        };
      }),
      'uuid'
    );
  };

  getBreaksSummary = timeCards => {
    const breaks = this.getAllBreaks(timeCards);
    const totalBreaksDuration = this.calculateTotalBreaksDuration(breaks);

    return {
      totalBreaks: breaks.length,
      totalBreaksDuration,
      totalBreaksDurationFormatted: this.formatBreaksDuration(
        totalBreaksDuration
      )
    };
  };

  getAllBreaks = timeCards => {
    return timeCards.reduce(
      (acc, timeCard) => acc.concat(timeCard.breaks.models.slice()),
      []
    );
  };

  calculateTotalBreaksDuration = breaks => {
    return breaks.reduce((acc, breakModel) => acc + breakModel.duration, 0);
  };

  formatBreaksDuration = duration => {
    return moment.utc(duration * 60 * 1000).format('HH:mm');
  };

  getTotalHours = timeCards => {
    return abbreviateNumber(
      timeCards.reduce((acc, timeCard) => acc + timeCard.totalHours, 0)
    );
  };

  getPayTypes = timeCards => {
    const timeEntries = this.getAllTimeEntries(timeCards);
    return timeEntries.reduce((acc, timeEntry) => {
      const payType = acc.find(pt => pt.code === timeEntry.payType.code);
      if (payType) {
        payType.hours += timeEntry.hours;
      } else {
        acc.push({
          code: timeEntry.payType.code,
          hours: timeEntry.hours
        });
      }
      return acc;
    }, []);
  };

  getAllTimeEntries = timeCards => {
    return timeCards.reduce(
      (acc, timeCard) => acc.concat(timeCard.timeEntries.models.slice()),
      []
    );
  };

  getTotalPayRollNotes = timeCards => {
    return timeCards.reduce(
      (acc, timeCard) =>
        timeCard.hasNoteOrAttachments
          ? acc + timeCard.noteAndAttachmentsCount
          : acc,
      0
    );
  };

  parseTime = timeStr => {
    return moment(`${timeStr}`, 'HH:mm:ss');
  };

  getDayStartEnd = timeCards => {
    let useLatestStartDate = false;

    if (!timeCards.every(timeCard => timeCard.startTime && timeCard.endTime))
      return null;

    if (
      timeCards.length === 1 &&
      timeCards.some(timeCard =>
        this.parseTime(timeCard.startTime).isAfter(
          this.parseTime(timeCard.endTime)
        )
      )
    ) {
      useLatestStartDate = true;
    }

    const { minStart, maxEnd } = timeCards.reduce(
      (acc, timeCard) => {
        const start = this.parseTime(timeCard.startTime);
        const end = this.parseTime(timeCard.endTime);

        // Update the earliest or latest start time based on the flag
        if (
          !acc.minStart ||
          (useLatestStartDate
            ? start.isAfter(acc.minStart)
            : start.isBefore(acc.minStart))
        ) {
          acc.minStart = start;
        }

        // Update the latest end time
        if (!acc.maxEnd || end.isAfter(acc.maxEnd)) {
          acc.maxEnd = end;
        }

        return acc;
      },
      { minStart: null, maxEnd: null }
    );

    return {
      dayStart: minStart.format('hh:mm a'),
      dayEnd: maxEnd.format('hh:mm a')
    };
  };

  @action.bound
  viewDate(timeCardSummary) {
    history.push({
      pathname: `/my-time/summary/${timeCardSummary.date}`
    });
  }

  @action.bound async addTimeCard(event, date = moment().format('YYYY-MM-DD')) {
    event.stopPropagation();

    await this.authorization.checkFeatureAccess('EditMyTime');

    history.push({
      pathname: `/my-time/summary/add`,
      search: `?date=${date}`
    });
  }

  @action.bound refetchAfterAdd() {
    this.parent.refetchPayPeriods();
  }

  @action.bound cancelTimeCardAdd() {
    history.push({
      pathname: `/my-time/summary`
    });
  }
}

import UIStore from './UIStore';
import { computed, action, observable } from 'mobx';

export default class LiveViewUI extends UIStore {
  @observable loading;

  @action.bound setup() {
    this.fetchConfigurations();
  }

  @action.bound tearDown() {
    this.loading = true;
  }

  @action.bound async fetchConfigurations() {
    this.loading = true;

    try {
      await this.integration.fetch();

      if (!this.integration.isConnected) return;

      await this.integration.configurations.fetch();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @computed get companyLevelOverviewText() {
    if (!this.integration.isConnected) {
      return this.integration.overviewText;
    }

    return this.integration.connectedText;
  }

  @computed get projectLevelConfigurations() {
    return this.integration.configurations.projectConfigurations;
  }

  @computed get hasProjectLevelConfigurations() {
    return this.projectLevelConfigurations.length > 0;
  }

  @computed get projectLevelUrl() {
    if (!this.integration.isConnected || !this.hasProjectLevelConfigurations)
      return null;

    if (!this.project) return null;

    return this.projectLevelConfigurations.models.find(model => {
      return (
        model.projectUuid === this.project.uuid &&
        model.configuration.key === 'embeddedScript'
      );
    })?.configuration?.value;
  }

  @computed get companyLevelUrl() {
    if (
      !this.integration.isConnected ||
      !this.integration.configurations.length
    )
      return null;

    return this.integration.configurations.models.find(model => {
      return model.key === 'embeddedScript';
    })?.value;
  }

  @computed get settingsUrl() {
    if (!this.integration.isConnected) {
      return `/company/integrations/${this.integration.id}`;
    }

    return `/company/integrations/${this.integration.id}/settings`;
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentHistoryFiltersFormPlugins = extendedPlugins;

const smallEquipmentHistoryFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentHistoryFiltersFormFields = ['groups'];

const smallEquipmentHistoryFiltersFormLabels = {
  groups: t('groups')
};

const smallEquipmentHistoryFiltersFormValues = {
  groups: []
};

const smallEquipmentHistoryFiltersFormRules = {
  groups: ''
};

class SmallEquipmentHistoryFiltersForm extends MobxReactForm {}

export {
  SmallEquipmentHistoryFiltersForm,
  smallEquipmentHistoryFiltersFormOptions,
  smallEquipmentHistoryFiltersFormFields,
  smallEquipmentHistoryFiltersFormRules,
  smallEquipmentHistoryFiltersFormValues,
  smallEquipmentHistoryFiltersFormLabels,
  smallEquipmentHistoryFiltersFormPlugins
};

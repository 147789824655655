import { RakenCollection } from '../lib';
import Reminder from '../models/Reminder';
import { computed } from 'mobx';

export default class Reminders extends RakenCollection {
  model() {
    return Reminder;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/verification-reminder/${this.rootStore.me.uuid}`;
  }

  @computed
  get hasReminders() {
    return this.length > 0;
  }
}

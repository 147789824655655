import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';
import extendedPlugins from './utils/extendedPlugins';

const oshaSubmitSelectFormPlugins = extendedPlugins;

const oshaSubmitSelectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const oshaSubmitSelectFormFields = [
  'submission',
  'submission.id',
  'submission.year',
  'annualAverageEmployees',
  'totalHoursWorked',
  'enableForm300A',
  'enableForm300301',
  'resubmission'
];

const oshaSubmitSelectFormLabels = {
  annualAverageEmployees: t('average annual employees'),
  totalHoursWorked: t('total hours worked'),
  enableForm300A: t('form 300A'),
  enableForm300301: t('form 300/301'),
  resubmission: t('resubmission reason')
};

const oshaSubmitSelectFormRules = {
  'submission.year': 'string|required',
  annualAverageEmployees: 'integer|required|max:24999|min:1',
  totalHoursWorked: 'integer|required|totalHoursRatio:annualAverageEmployees',
  enableForm300A: 'boolean|atLeastOne:enableForm300301',
  enableForm300301: 'boolean|atLeastOne:enableForm300A',
  resubmission: 'string|max:100'
};

const oshaSubmitSelectFormValues = {
  annualAverageEmployees: 0,
  totalHoursWorked: 0,
  enableForm300A: true,
  enableForm300301: false,
  resubmission: ''
};

const oshaSubmitSelectFormRelated = {
  annualAverageEmployees: ['totalHoursWorked'],
  totalHoursWorked: ['annualAverageEmployees'],
  enableForm300A: ['enableForm300301'],
  enableForm300301: ['enableForm300A']
};

class OshaSubmitSelectForm extends MobxReactForm {}

export {
  OshaSubmitSelectForm,
  oshaSubmitSelectFormOptions,
  oshaSubmitSelectFormFields,
  oshaSubmitSelectFormValues,
  oshaSubmitSelectFormLabels,
  oshaSubmitSelectFormRelated,
  oshaSubmitSelectFormRules,
  oshaSubmitSelectFormPlugins
};

import pick from 'lodash.pick';
import omit from 'lodash.omit';
import request from 'axios';
import UIStore from './UIStore';
import geocodeAddress from 'utils/geocodeAddress';
import { observable, action, computed, reaction, runInAction } from 'mobx';
import Company from '../models/Company';

import history from 'utils/history';

import {
  UserForm,
  userFormOptions,
  userFormFields,
  userFormRules,
  userFormLabels,
  userFormPlugins
} from 'forms/user';

import {
  CompanyForm,
  companyFormOptions,
  companyFormFields,
  companyFormRules,
  companyFormLabels,
  companyFormPlugins,
  companyFormValues
} from 'forms/company';

import alertErrorHandler from 'utils/alertErrorHandler';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';

import {
  TRIAL_REGISTERED,
  USER_REGISTERED,
  REGISTRATION_VIEWED,
  TERMS_OF_SERVICE_VIEWED,
  TERMS_OF_SERVICE_ACCEPTED,
  TERMS_OF_SERVICE_DECLINED
} from 'utils/segmentAnalytics/eventSpec';

export default class extends UIStore {
  @observable companyQuery;
  @observable companyFormMode;
  @observable selectedCompany;
  @observable searchingCompanies;

  // Forms
  @observable userForm;
  @observable companyForm;
  @observable collaborator;

  constructor(props) {
    super(props);

    this.reactToGlobalData = reaction(
      () => this.rootStore.loadingGlobalData,
      loading => {
        if (!loading) {
          this.companyFormMode = this.company ? 'manual' : 'search';
          this.showNextScreen();
        }
      }
    );

    // Companies
    this.companies = observable([]);
    this.searchingCompanies = false;
    this.companyQuery = '';
    this.selectedCompany = null;

    // Fetch new companies
    this.reactToCompanyQuery = reaction(
      () => this.companyQuery,
      companyQuery => {
        if (companyQuery.length) {
          this.searchCompanies();
        } else {
          this.clearCompanies();
        }
      },
      {
        delay: 250
      }
    );

    // Forms
    this.userForm = null;
    this.companyForm = null;
    this.collaborator = false;
  }

  @action.bound
  showNextScreen() {
    if (!this.me.isTosAccepted && !this.isSuperAdmin && !this.isSudoing) {
      this.showTermsOfServiceModal();
    } else if (!this.me.requiredInfoComplete) {
      this.showPersonalInfoModal();
    } else if (
      !this.company ||
      (this.company && !this.company.requiredInfoComplete)
    ) {
      this.showCompanyInfoModal();
    } else if (
      this.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.company.preferences.timePolicyStatus === 'INITIAL' &&
      this.authorization.canEditPolicies &&
      !this.me.settings.hidePoliciesModal
    ) {
      this.showPoliciesNowAvailableModal();
    }
  }

  @action.bound async showPoliciesNowAvailableModal() {
    this.showModal('policiesNowAvailable');
  }

  @action.bound hidePoliciesNowAvailableModal() {
    this.hideActiveModal();

    this.settings.save({
      hidePoliciesModal: true
    });
  }

  @action.bound setupPoliciesNowAvailable() {
    this.hidePoliciesNowAvailableModal();
    history.push('/company/time/policies');
  }

  // Modals
  @action.bound
  showTermsOfServiceModal() {
    callTrack(TERMS_OF_SERVICE_VIEWED);

    this.showModal('termsOfService');
  }

  @action.bound async acceptTermsOfService() {
    try {
      this.saving = true;

      await this.me.save(
        {
          isTosAccepted: true
        },
        {
          wait: true,
          method: 'post',
          url: `ra/user/accept-tos`
        }
      );

      callTrack(TERMS_OF_SERVICE_ACCEPTED);

      this.hideFormModalAndMoveToNextScreen();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound declineTermsOfService() {
    callTrack(TERMS_OF_SERVICE_DECLINED);
    this.rootStore.logout();
  }

  // Modals
  @action.bound
  showPersonalInfoModal() {
    this.userForm = new UserForm(
      {
        fields: userFormFields,
        rules: userFormRules,
        values: Object.assign({}, this.rootStore.me.requiredInfoFormValues),
        labels: userFormLabels
      },
      {
        options: userFormOptions,
        plugins: userFormPlugins
      }
    );

    callTrack(REGISTRATION_VIEWED);

    this.showModal('personalInfo');
  }

  @action.bound
  submitPersonalInfoForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.userForm.submit({
      onSuccess: this.submitPersonalInfoFormSuccess,
      onError: this.submitPersonalInfoFormError
    });
  }

  @action.bound
  async submitPersonalInfoFormSuccess() {
    this.saving = true;

    const payload = this.userForm.values();

    return this.me
      .save(payload, {
        url: `ra/members/${this.me.uuid}`,
        wait: true
      })
      .then(() => {
        this.collaborator = this.me.isCollaborator;

        if (this.me.company) {
          if (this.collaborator) {
            callTrack(TRIAL_REGISTERED, {
              trial_varient: 'invite'
            });
          }

          callTrack(USER_REGISTERED, {
            is_collaborator: this.collaborator,
            role: this.me.role
          });
        }

        this.hideFormModalAndMoveToNextScreen('userForm');
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      });
  }

  @action.bound
  submitPersonalInfoFormError(values) {
    console.error(this.userForm.errors());
  }

  @action.bound
  setCompanyQuery(value) {
    this.companyQuery = value;
  }

  @action.bound
  clearCompanyQuery(value) {
    this.companyQuery = '';
  }

  @action.bound
  selectCompany(option) {
    if (option) {
      this.selectedCompany = {
        companyId: option.value,
        companyName: option.name,
        address: option.address
      };

      this.companyForm.update({
        companyId: option.value,
        name: option.name,
        address: option.address
      });

      this.companyFormMode = 'manual';
    }
  }

  @computed
  get hasSelectedCompany() {
    return Boolean(this.selectedCompany);
  }

  @action.bound
  clearSelectedCompany() {
    this.selectedCompany = null;
    this.companyQuery = '';
    this.companyForm = new CompanyForm(
      {
        fields: companyFormFields,
        rules: companyFormRules,
        values: companyFormValues,
        labels: companyFormLabels
      },
      {
        options: companyFormOptions,
        plugins: companyFormPlugins
      }
    );

    this.companyFormMode = 'search';
  }

  @action.bound
  setCompanyFormModeToSearch() {
    this.companyFormMode = 'search';
  }

  @action.bound
  setCompanyFormModeToManual() {
    this.companyFormMode = 'manual';
  }

  @action.bound
  searchCompanies() {
    this.searchingCompanies = true;

    return request
      .get(`${this.rootStore.apiURL}/ra/company/search`, {
        params: {
          query: this.companyQuery,
          limit: 5
        }
      })
      .then(response => {
        runInAction(() => {
          this.companies.replace(response.data.collection);
          this.searchingCompanies = false;
        });
      })
      .catch(error => {
        this.searchingCompanies = false;
      });
  }

  @action.bound
  clearCompanies() {
    this.companies.clear();
  }

  @computed get companyOptions() {
    return this.companies.map(company => {
      return {
        value: company.companyId,
        name: company.companyName,
        address: company.address
      };
    });
  }

  @computed get companyOptionsText() {
    if (this.searchingCompanies) {
      return t('Searching');
    }

    return t('No results');
  }

  @action.bound
  selectCountry(option) {
    this.companyForm.$('address.country').set(option.value);
  }

  @computed get countryOptions() {
    return this.countries.asOptions;
  }

  @computed get selectedCountry() {
    return this.countryOptions.find(country => {
      return country.value === this.companyForm.$('address.country').value;
    });
  }

  @action.bound
  showCompanyInfoModal() {
    let values = companyFormValues;

    if (this.company) {
      values = Object.assign(
        {},
        companyFormValues,
        this.company.requiredInfoFormValues
      );
    } else {
      this.searchCompanies();
    }

    this.companyForm = new CompanyForm(
      {
        fields: companyFormFields,
        rules: companyFormRules,
        values: values,
        labels: companyFormLabels
      },
      {
        options: companyFormOptions,
        plugins: companyFormPlugins
      }
    );

    this.showModal('companyInfo');
  }

  @action.bound
  submitCompanyInfoForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.companyForm.submit({
      onSuccess: this.submitCompanyInfoFormSuccess,
      onError: this.submitCompanyInfoFormError
    });
  }

  @action.bound
  submitCompanyInfoFormSuccess() {
    const values = this.companyForm.values();

    this.saving = true;

    if (this.company) {
      this.updateCompany(omit(values, 'companyId'));
    } else {
      if (values.companyId) {
        this.createOrAssociateCompany(pick(values, 'companyId'));
      } else {
        this.createOrAssociateCompany(omit(values, 'companyId'));
      }
    }
  }

  @action.bound
  async createOrAssociateCompany(values) {
    if (!values.companyId) {
      values.address.location = await geocodeAddress(values.address);
    }

    return request
      .post(`${this.rootStore.apiURL}/ra/company`, values)
      .then(response => {
        this.selectedCompany = null;
        this.me.company = new Company(response.data, {
          rootStore: this.rootStore
        });

        if (this.collaborator) {
          callTrack(TRIAL_REGISTERED, {
            trial_varient: 'invite'
          });

          callTrack(USER_REGISTERED, {
            is_collaborator: this.me.isCollaborator,
            role: this.me.role
          });
        }

        this.fetchInvitedUserData();

        this.hideFormModalAndMoveToNextScreen('company');
      })
      .catch(error => {
        alertErrorHandler(error, this.setValidationDetails);
      });
  }

  @action.bound
  async fetchInvitedUserData() {
    await this.rootStore.fetchGlobalData();

    this.rootStore.activityUI.fetchPageOne();
  }

  @action.bound
  updateCompany(values) {
    return this.company
      .save(
        {
          name: values.name,
          address: {
            postCode: values.address.postCode,
            country: values.address.country
          }
        },
        {
          wait: true
        }
      )
      .then(() => {
        this.hideFormModalAndMoveToNextScreen('company');
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  submitCompanyInfoFormError() {
    console.log(this.companyForm.errors());
  }

  @action.bound
  hideFormModalAndMoveToNextScreen(form) {
    return this.hideActiveModal().then(() => {
      this.saving = false;

      // Null out the active form
      if (this[`${form}Form`]) {
        this[`${form}Form`] = null;
      }

      this.showNextScreen();
    });
  }

  @action.bound
  showSuperDailyModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('InviteCollaborators', true)
      .then(() => {
        this.showModal('superDaily');
      });
  }
}

import { RakenCollection } from '../../lib';
import Policy from 'stores/models/time/Policy';

export default class Policies extends RakenCollection {
  model() {
    return Policy;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/timepolicies`;
  }
}

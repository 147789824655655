import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable } from 'mobx';

import {
  ApprovalsForm,
  ApprovalsFormFields,
  ApprovalsFormRules,
  ApprovalsFormOptions,
  ApprovalsFormPlugins
} from 'forms/approvals';

import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';
import convertTo24HourFormat, {
  parseTimeToObject
} from 'utils/convertTo24HourFormat';

import SettingsSignOff from 'stores/models/SettingsSignOff';

export default class ApprovalsUI extends PolicyChildEditUI {
  @observable form;
  @observable saving;
  @observable loading;

  constructor(options) {
    super(options);

    this.form = null;
    this.saving = false;
    this.loading = false;

    this.settingsSignOff = new SettingsSignOff(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound async setup() {
    await this.fetchSettingsSignOff();
    this.initApprovalsForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async fetchSettingsSignOff() {
    this.loading = true;

    try {
      const url = `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/signoff/rules`;

      await this.settingsSignOff.fetch({
        url
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  initApprovalsForm() {
    this.form = new ApprovalsForm(
      {
        fields: ApprovalsFormFields,
        rules: ApprovalsFormRules,
        values: {
          supervisorApprovals: this.settingsSignOff
            .approvalNotificationsEnabled,
          supervisorNotificationsTime: convertTo24HourFormat(
            this.settingsSignOff.approvalScheduleData
              ? this.settingsSignOff.approvalScheduleData.time
              : {
                  hour: 9,
                  minute: 0
                }
          ),
          supervisorNotificationsDayOfWeek: this.settingsSignOff
            .approvalScheduleData
            ? this.settingsSignOff.approvalScheduleData.actionDayOfWeek
            : 2,
          supervisorNotificationsDayOfMonth: this.settingsSignOff
            .approvalScheduleData
            ? this.settingsSignOff.approvalScheduleData.actionDayOfMonth
            : ``,

          employeeSignOff: this.settingsSignOff.enabled,
          employeeSignOffTime: convertTo24HourFormat(
            this.settingsSignOff.scheduleData
              ? this.settingsSignOff.scheduleData.time
              : {
                  hour: 9,
                  minute: 0
                }
          ),
          employeeSignOffDayOfWeek: this.settingsSignOff.scheduleData
            ? this.settingsSignOff.scheduleData.actionDayOfWeek
            : 2,
          employeeSignOffDayOfMonth: this.settingsSignOff.scheduleData
            ? this.settingsSignOff.scheduleData.actionDayOfMonth
            : ``,
          signOffDisclaimer: this.settingsSignOff.signOffDisclaimer
        }
      },
      {
        options: ApprovalsFormOptions,
        plugins: ApprovalsFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.form = null;
  }

  @action.bound submitApprovalsForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitApprovalsFormSuccess,
      onError: this.submitApprovalsFormError
    });
  }

  @action.bound submitApprovalsFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitApprovalsFormSuccess() {
    const formValues = this.form.values();
    this.saving = true;

    try {
      await this.settingsSignOff.save(
        {
          ...this.settingsSignOff.apiValues,
          ...{
            approvalNotificationsEnabled: formValues.supervisorApprovals,
            approvalScheduleData: {
              time: parseTimeToObject(formValues.supervisorNotificationsTime),
              actionDayOfWeek: formValues.supervisorNotificationsDayOfWeek,
              firstDueDate: this.settingsSignOff.apiValues.approvalScheduleData
                .firstDueDate,
              actionDayOfMonth: formValues.supervisorNotificationsDayOfMonth,
              lastDayNotification: false
            },
            enabled: formValues.employeeSignOff,
            scheduleData: {
              time: parseTimeToObject(formValues.employeeSignOffTime),
              actionDayOfWeek: formValues.employeeSignOffDayOfWeek,
              actionDayOfMonth: formValues.employeeSignOffDayOfMonth,
              firstDueDate: this.settingsSignOff.apiValues.scheduleData
                .firstDueDate,
              lastDayNotification: false
            },
            signOffDisclaimer: formValues.signOffDisclaimer
          }
        },
        {
          reset: true
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.initApprovalsForm();
    this.saving = false;

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Approvals updated')}`
    });
  }

  @computed get saveButtonDisabled() {
    return this.entryForEdit.saving;
  }

  @computed get timeTypeTitle() {
    const timeTypeOptions = [
      { title: t('Daily'), value: 'DAILY' },
      { title: t('Weekly'), value: 'WEEKLY' },
      { title: t('Bi-weekly'), value: 'BI_WEEKLY' },
      { title: t('Monthly'), value: 'MONTHLY' }
    ];

    const option = timeTypeOptions.find(
      option => option.value === this.settingsSignOff?.timeType
    );
    return option ? option.title : ``;
  }

  @computed get daysOfWeekOptions() {
    return [
      { title: t('Sunday'), value: 1 },
      { title: t('Monday'), value: 2 },
      { title: t('Tuesday'), value: 3 },
      { title: t('Wednesday'), value: 4 },
      { title: t('Thursday'), value: 5 },
      { title: t('Friday'), value: 6 },
      { title: t('Saturday'), value: 7 }
    ];
  }

  @computed get daysOfMonthOptions() {
    return [
      { title: t('Last day of the month'), value: 'LAST' },
      { title: t('1st day of the next month'), value: 1 },
      { title: t('2nd day of the next month'), value: 2 },
      { title: t('3rd day of the next month'), value: 3 },
      { title: t('4th day of the next month'), value: 4 },
      { title: t('5th day of the next month'), value: 5 },
      { title: t('6th day of the next month'), value: 6 },
      { title: t('7th day of the next month'), value: 7 },
      { title: t('8th day of the next month'), value: 8 },
      { title: t('9th day of the next month'), value: 9 },
      { title: t('10th day of the next month'), value: 10 },
      { title: t('11th day of the next month'), value: 11 },
      { title: t('12th day of the next month'), value: 12 },
      { title: t('13th day of the next month'), value: 13 },
      { title: t('14th day of the next month'), value: 14 },
      { title: t('15th day of the next month'), value: 15 },
      { title: t('16th day of the next month'), value: 16 },
      { title: t('17th day of the next month'), value: 17 },
      { title: t('18th day of the next month'), value: 18 },
      { title: t('19th day of the next month'), value: 19 },
      { title: t('20th day of the next month'), value: 20 },
      { title: t('21st day of the next month'), value: 21 },
      { title: t('22nd day of the next month'), value: 22 },
      { title: t('23rd day of the next month'), value: 23 },
      { title: t('24th day of the next month'), value: 24 },
      { title: t('25th day of the next month'), value: 25 },
      { title: t('26th day of the next month'), value: 26 },
      { title: t('27th day of the next month'), value: 27 },
      { title: t('28th day of the next month'), value: 28 },
      { title: t('29th day of the next month'), value: 29 },
      { title: t('30th day of the next month'), value: 30 },
      { title: t('31st day of the next month'), value: 31 }
    ];
  }
}

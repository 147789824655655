import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const timeCardFormPlugins = extendedPlugins;

const timeCardFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const timeCardFormFields = [
  'date',
  'project',
  'project.value',
  'project.name',
  'classificaiton',
  'classification.uuid',
  'classification.name',
  'costCode',
  'costCode.uuid',
  'costCode.code',
  'costCode.division',
  'costCode.description',
  'segment',
  'segment.uuid',
  'segment.name',
  'hoursWorked',
  'startTime',
  'endTime'
];

const timeCardFormValues = {};

const timeCardFormRules = {
  date: 'required|dateCustom:YYYY-MM-DD|beforeOrTodayDate',
  'project.value': 'required'
};

const timeCardFormLabels = {
  date: t('date'),
  'project.value': t('project'),
  'segment.uuid': t('segment'),
  'costCode.uuid': t('cost code'),
  'classification.uuid': t('classification'),
  hoursWorked: t('hours'),
  startTime: t('start time'),
  endTime: t('end time')
};

class TimeCardForm extends MobxReactForm {}

export {
  TimeCardForm,
  timeCardFormOptions,
  timeCardFormFields,
  timeCardFormLabels,
  timeCardFormValues,
  timeCardFormRules,
  timeCardFormPlugins
};

import { computed, reaction } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import { t } from 'utils/translate';
import extendedPlugins from './utils/extendedPlugins';

const productionReportFormPlugins = extendedPlugins;

const productionReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const productionReportFormFields = [
  'project',
  'projectIncludeOption',
  'costCodes[]',
  'includeDataWithoutCostCode',
  'timeFrameType',
  'fromDate',
  'toDate',
  'email',
  'emails'
];

const productionReportFormRules = {
  project: 'required',
  projectIncludeOption: 'string',
  costCodes: 'array',
  includeDataWithoutCostCode: 'boolean',
  timeFrameType: 'required',
  email: 'required_without:emails|email',
  emails: 'array|required'
};

const productionReportFormValues = {
  projectIncludeOption: 'SELECTED',
  costCodes: [],
  includeDataWithoutCostCode: false,
  timeFrameType: '',
  email: '',
  emails: []
};

const productionReportFormLabels = {
  project: t('Project'),
  includeDataWithoutCostCode: t('No cost code entries'),
  costCodes: t('Cost codes'),
  timeFrame: t('Time frame'),
  emails: t('Send download link to')
};

class ProductionReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);

    this.cancelReactToEnableDatePickers = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|string');
          this.$('toDate').set('rules', 'required|string');
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get enableFromToDate() {
    return this.$('timeFrameType').value === 'SPECTIMEFRAME';
  }
}

export {
  ProductionReportForm,
  productionReportFormOptions,
  productionReportFormFields,
  productionReportFormRules,
  productionReportFormValues,
  productionReportFormLabels,
  productionReportFormPlugins
};

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Box, Container, Sidebar } from '@raken/athens-web/lib';

import CompanyNavigationHeader from './CompanyNavigationHeader';
import CompanyNavigationFooter from './CompanyNavigationFooter';
import ProjectSelector from './ProjectSelector';
import ProjectWeather from './ProjectWeather';
import styled from 'styled-components';
import ViolatorBanner from 'athens-components/ViolatorBanner';

import media from 'utils/mediaTemplate';

const SidebarContainer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
`;

const StyledContainer = styled(Container)`
  left: 124px;
  width: calc(100% - 124px);
  ${media.desktop`
    left: ${props =>
      !props.$showSubNav ? '94px' : props.$isSidebarOpen ? '356px' : '124px'};
    width: ${props =>
      !props.$showSubNav
        ? 'calc(100% - 94px)'
        : props.$isSidebarOpen
        ? 'calc(100% - 356px)'
        : 'calc(100% - 124px)'};
  `};
  margin-left: -24px;
  margin-top: -24px;
  position: fixed;
  height: 100vh;
  background-color: transparent;
  border: 0;
`;

const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const CompanyNavigationActiveItemHeader = observer(
  ({ navigationUI, projectUI, projectSelectorUI }) => {
    if (navigationUI.hideProjectSelector) {
      return <Box style={{ marginBottom: '12px' }}></Box>;
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        width={'100%'}
        style={{ marginBottom: '24px' }}
      >
        <ProjectSelector
          projectUI={projectUI}
          projectSelectorUI={projectSelectorUI}
        />

        {navigationUI.hasProject && !navigationUI.isMobileScreen && (
          <ProjectWeather uiStore={projectUI} />
        )}
      </Box>
    );
  }
);

const CompanyNavigationSidebar = observer(
  ({ navigationUI, projectSelectorUI, projectUI }) => {
    const handleNavItemSelect = item => {
      navigationUI.handleNavItemSelect(item);
    };

    return (
      <Sidebar
        logo={<CompanyNavigationHeader />}
        info={
          <CompanyNavigationFooter
            navigationUI={navigationUI}
            location={location}
          />
        }
        items={navigationUI.navItems}
        isExpanded={navigationUI.isSidebarOpen}
        activeItem={navigationUI.activeItem}
        showSubNav={navigationUI.showSubNav}
        activeItemHeader={
          <CompanyNavigationActiveItemHeader
            projectSelectorUI={projectSelectorUI}
            navigationUI={navigationUI}
            projectUI={projectUI}
          />
        }
        expandedPanels={navigationUI.expandedPanels.slice()}
        setExpandedPanels={values => {
          navigationUI.setExpandedPanels(values);
        }}
        onNavItemSelect={handleNavItemSelect}
        onExpandChange={navigationUI.toggleSidebar}
      />
    );
  }
);

const CompanyNavigation = observer(({ location, navigationUI, children }) => {
  useEffect(() => {
    navigationUI.setup();
    return () => {
      navigationUI.tearDown();
    };
  }, [navigationUI]);

  useEffect(() => {
    navigationUI.setLocation(location);
  }, [navigationUI, location]);

  const { projectSelectorUI, projectUI } = navigationUI;

  return (
    <SidebarContainer display="flex" height="100vh" justifyContent="start">
      <CompanyNavigationSidebar
        navigationUI={navigationUI}
        projectSelectorUI={projectSelectorUI}
        projectUI={projectUI}
      />

      <>
        <StyledContainer
          $isSidebarOpen={navigationUI.isSidebarOpen}
          $showSubNav={navigationUI.showSubNav}
        >
          {navigationUI.me.isViolator && (
            <Box py={4} px={6} pb={0} mb={-4}>
              <ViolatorBanner />
            </Box>
          )}

          <ScrollableContent>{children}</ScrollableContent>
        </StyledContainer>
      </>
    </SidebarContainer>
  );
});

export default CompanyNavigation;

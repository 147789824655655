import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentWithdrawFormPlugins = extendedPlugins;

const smallEquipmentWithdrawFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentWithdrawFormFields = [
  'withdraws[].equipmentUuid',
  'withdraws[].equipmentName',
  'withdraws[].projectUuid',
  'withdraws[].projectName',
  'withdraws[].projectDeployedUnits',
  'withdraws[].numberOfUnits'
];

const smallEquipmentWithdrawFormLabels = {
  'withdraws[].numberOfUnits': t('withdraw units')
};

const smallEquipmentWithdrawFormValues = {
  'withdraws[].equipmentName': '',
  'withdraws[].projectName': '',
  'withdraws[].projectDeployedUnits': '',
  'withdraws[].numberOfUnits': ''
};

const smallEquipmentWithdrawFormRules = {
  'withdraws[].numberOfUnits': 'numeric|min:0'
};

class SmallEquipmentWithdrawForm extends MobxReactForm {}

export {
  SmallEquipmentWithdrawForm,
  smallEquipmentWithdrawFormOptions,
  smallEquipmentWithdrawFormFields,
  smallEquipmentWithdrawFormRules,
  smallEquipmentWithdrawFormValues,
  smallEquipmentWithdrawFormLabels,
  smallEquipmentWithdrawFormPlugins
};

import { observable, action, computed, reaction } from 'mobx';
import UIStore from '../../UIStore';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import EquipmentDeficiencies from 'stores/collections/EquipmentDeficiencies';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class EquipmentDeficienciesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Equipment deficiencies collection
    this.equipmentDeficiencies = new EquipmentDeficiencies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentDeficienciesDebounced = debounce(
      this.fetchEquipmentDeficiencies,
      BASE_DEBOUNCE
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipmentDeficiencies.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipmentDeficiencies();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEquipmentDeficienciesDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.sortField = 'date';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchEquipmentDeficiencies() {
    this.loading = true;
    this.equipmentDeficiencies.cancelRequest();
    this.equipmentDeficiencies.reset();

    try {
      await this.equipmentDeficiencies.fetch({
        url: `${this.entryForEdit.url()}/deficiencies`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get hasEquipmentDeficiencies() {
    return this.equipmentDeficiencies.hasModels;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasEquipmentDeficiencies;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasEquipmentDeficiencies;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }
}

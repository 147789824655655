import UIStore from './UIStore';

import { action, computed, observable, reaction, runInAction } from 'mobx';

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import PhotoIdSummaryFiltersUI from './PhotoIdSummaryFiltersUI';
import PhotoIdDetailUI from './PhotoIdDetailUI';
import VerificationReminderUI from './VerificationReminderUI';
import WorkerPhotoIdSummaryActivity from '../models/WorkerPhotoIdSummaryActivity';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

import { callTrack } from 'utils/segmentIntegration';
import { PHOTO_ID_REVIEW } from 'utils/segmentAnalytics/eventSpec';

export default class PhotoIdSummaryUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.sortField = 'failedMatches';
    this.sortDirection = 'desc';
    this.page = 1;
    this.pageSize = 20;
    this.searchQuery = '';

    // Filters
    this.workerPhotoIdSummaryFiltersUI = new PhotoIdSummaryFiltersUI({
      rootStore: this.rootStore,
      parent: this
    });

    // UI Stores
    this.workerPhotoIdDetailUI = new PhotoIdDetailUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.verificationReminderUI = new VerificationReminderUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Models
    this.workerPhotoIdSummaryActivity = new WorkerPhotoIdSummaryActivity(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Running
    this.loading = true;

    this.fetchWorkerPhotoIdSummaryDebounced = debounce(
      this.fetchWorkerPhotoIdSummaries,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.workerPhotoIdSummaryFiltersUI.syncWorkerPhotoIdSummaryWithFilters();
    this.setupReactions();
    this.fetchWorkerPhotoIdSummaries();
    callTrack(PHOTO_ID_REVIEW);
  }

  @action.bound
  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchWorkerPhotoIdSummaryDebounced();
        });
      }
    );
  }

  @computed get hasWorkerPhotoIdSummaries() {
    return this.workerPhotoIdSummaryActivity.workerPhotoIdSummaries?.length > 0;
  }

  @computed
  get workerPhotoIdSummaries() {
    return this.workerPhotoIdSummaryActivity.workerPhotoIdSummaries.models;
  }

  @action.bound fetchWorkerPhotoIdSummaries() {
    this.loading = true;

    if (!this.params.from || !this.params.to) return;

    if (this.hasWorkerPhotoIdSummaries) {
      this.workerPhotoIdSummaryActivity.clear();
    }

    const fetchParams = pickBy(
      { ...this.params, query: this.searchQuery },
      identity
    );

    try {
      return request
        .post(this.workerPhotoIdSummaryActivity.url(), fetchParams)
        .then(response => {
          runInAction(() => {
            this.workerPhotoIdSummaryActivity.set(response.data);
            this.loading = false;
          });
        });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get filterValues() {
    return {
      projectFilters: this.workerPhotoIdSummaryFiltersUI.projectFilters.map(
        project => project.value
      ),
      memberFilters: this.workerPhotoIdSummaryFiltersUI.memberFilters.map(
        worker => worker.value
      ),
      classificationFilters: this.workerPhotoIdSummaryFiltersUI.classificationFilters.map(
        classification => classification.value
      ),
      shiftFilters: this.workerPhotoIdSummaryFiltersUI.shiftFilters.map(
        shift => shift.value
      ),
      employeeGroupFilters: this.workerPhotoIdSummaryFiltersUI.employeeGroupFilters.map(
        group => group.value
      )
    };
  }

  @action.bound tearDown() {
    this.reactToParams && this.reactToParams();
    this.clearPage();
    this.resetParamsToDefault();
    this.workerPhotoIdSummaryActivity.clear();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      from: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      to: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      projects: this.filterValues.projectFilters,
      workers: this.filterValues.memberFilters,
      classifications: this.filterValues.classificationFilters,
      shifts: this.filterValues.shiftFilters,
      employeeGroups: this.filterValues.employeeGroupFilters,
      sortField: `${this.sortField}`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound
  resetParamsToDefault() {
    this.searchQuery = '';
    this.searchMode = false;
    this.workerPhotoIdSummaryFiltersUI.clearAllFilters();
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.workerPhotoIdSummaryActivity.page?.totalElements / this.pageSize
    );
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound setPage(event, page) {
    window.scrollTo(0, 0);
    this.page = page;
  }

  @action.bound viewWorkerPhotoIdDetail(timeClockPhotoId) {
    history.push({
      pathname: `${this.timesheetsPath}/photo-id/${timeClockPhotoId.workerUuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async showVerificationReminderModal() {
    this.verificationReminderUI.showVerificationReminderModal();
  }

  @computed
  get timesheetsPath() {
    return '/review';
  }
}

import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import Group from 'stores/models/groups/Group';
import GroupInfoUI from './GroupInfoUI';
import GroupProjectsUI from './GroupProjectsUI';
import GroupMembersUI from './GroupMembersUI';

import { t } from 'utils/translate';

export default class GroupEditUI extends UIStore {
  @observable entryForEdit;

  constructor(options) {
    super(options);

    this.groupInfoUI = new GroupInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.groupProjectsUI = new GroupProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.groupMembersUI = new GroupMembersUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.entryForEdit = null;
  }

  @action.bound async setup(id) {
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.groups.get(uuid);

      if (!model) {
        model = new Group(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );
      }

      await model.fetch();
      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelGroupEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.entryForEdit = null;
  }

  @action.bound
  cancelGroupEdit() {
    history.push({
      pathname: `/company/groups`,
      search: this.baseQueryParams
    });
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/projects`,
          state: { previousPath: this.previousPath }
        },
        label: t('Projects')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/employees`,
          state: { previousPath: this.previousPath }
        },
        label: t('Employees')
      }
    ];
  }

  @computed get showUI() {
    return this.entryForEdit && !this.entryForEdit.fetching;
  }
}

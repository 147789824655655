import { observable, action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

import SmallEquipment from 'stores/models/equipment/SmallEquipment';

import SmallEquipmentInfoUI from './SmallEquipmentInfoUI';
import SmallEquipmentProjectsUI from './SmallEquipmentProjectsUI';
import SmallEquipmentHistoryUI from './SmallEquipmentHistoryUI';

import history from 'utils/history';

import { t } from 'utils/translate';

export default class SmallEquipmentEditUI extends UIStore {
  @observable entryForEdit;

  constructor(options) {
    super(options);

    this.entryForEdit = null;

    this.saving = false;

    this.smallEquipmentInfoUI = new SmallEquipmentInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.smallEquipmentProjectsUI = new SmallEquipmentProjectsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.smallEquipmentHistoryUI = new SmallEquipmentHistoryUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(uuid) {
    this.fetchEntry(uuid);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.smallEquipment.get(uuid);

      if (!model) {
        model = new SmallEquipment(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );
      }

      await model.fetch();

      this.entryForEdit = model;
    } catch (error) {
      console.error(error);
      this.cancelGroupEdit();
    }
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action clearUIState() {
    this.entryForEdit = null;
  }

  @action.bound onClose() {
    history.push({
      pathname: `admin/equipment/small-equipment`
    });
  }

  @action.bound cancelSmallEquipmentEdit() {
    history.push({
      pathname: `/company/equipment/small-equipment`
    });
  }

  @computed get showUI() {
    return this.entryForEdit;
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/info`
        },
        label: t('Info')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/projects`
        },
        label: t('Projects')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/history`
        },
        label: t('History')
      }
    ];
  }
}

import { action, computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class IntegrationConfiguration extends Model {
  get restAttributes() {
    return [
      'key',
      'type',
      'value',
      'displayName',
      'description',
      'hidden',
      'disabled',
      'options',
      'optional'
    ];
  }

  @action.bound toggleBoolean() {
    this.value = !this.value;
  }

  @computed get formValue() {
    if (this.type === 'list') {
      return this.value.split(',').map(s => s.trim());
    }
    return this.value;
  }

  @computed get validationFormField() {
    if (this.type === 'list') {
      return this.key + '-input';
    }
    return undefined;
  }

  @computed get isList() {
    return this.type === 'list';
  }
  @computed get isText() {
    return (
      this.type === 'string' ||
      // fallback from "select" without any options
      (this.type === 'select' && !(this.options || []).length)
    );
  }
  @computed get isSwitch() {
    return this.type === 'boolean';
  }
  @computed get isSelect() {
    // only if we have list of options, otherwise it will be displayed as "string"
    return this.type === 'select' && (this.options || []).length;
  }
  @computed get asOptions() {
    return this.options.map(o => {
      return {
        id: o.value,
        title: o.display
      };
    });
  }
}

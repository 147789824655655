import moment from 'moment';
import omit from 'lodash.omit';
import capitalize from 'lodash.capitalize';
import kebabCase from 'lodash.kebabcase';

import { computed, action } from 'mobx';
import { Model } from 'mobx-mc';

import SuperAdminSubscription from './SuperAdminSubscription';
import Transactions from 'stores/collections/Transactions';

export default class SuperAdminCompany extends Model {
  constructor(data, options) {
    super(data, options);

    this.transactions = new Transactions(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  url() {
    return `ra/sadmin/companies/${this.id}`;
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'name',
      'status',
      'subscription',
      'address',
      'phone',
      'stripeId',
      'parentCompany',
      'childCompanies',
      'projects',
      'members',
      'subscriptionStart',
      'subscriptionEnd',
      'subscriptionPeriodType',
      'subscriptionStatus',
      'pricePerMonth',
      'logo',
      'preferences',
      'mergePermitted',
      'silentInvitePermitted',
      'companyId',
      'accountSubType',
      'accountType',
      'employeeRange',
      'industry',
      'subIndustries',
      'email',
      'ccEmails',
      'isInternal',
      'isPromo',
      'promoEndDate',
      'billingPlan',
      'seatsInUse',
      'salesRepresentative',
      'companyAddOns',
      'workerSeatsInUse',
      'salesforceAccountLink'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      workerSeatsInUse: 0,
      preferences: {
        primaryColor: '#FFFFFF'
      }
    };
  }

  @action.bound
  parse(attributes) {
    this.parseSubscription(attributes);

    return {
      ...omit(attributes, ['subscription'])
    };
  }

  @action.bound
  parseSubscription(attributes) {
    // If subscription exists set it or else create a new model.
    if (this.subscription) {
      this.subscription.set(attributes.subscription);
    } else {
      this.subscription = new SuperAdminSubscription(
        attributes.subscription ? attributes.subscription : null,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );
    }
  }

  @computed
  get infoFormValues() {
    return {
      name: this.name,
      phone: this.phone,
      address: this.address,
      vanityUrl: this.vanityUrl,
      stripeId: this.stripeId,
      parentCompany: this.parentCompany,
      status: this.status,
      employeeRange: this.employeeRange,
      industry: this.industry,
      subIndustries: this.subIndustries.slice(),
      email: this.email,
      ccEmails: this.ccEmails,
      isInternal: this.isInternal,
      salesforceAccountLink: this.salesforceAccountLink
    };
  }

  @computed
  get subscriptionStartFormatted() {
    return moment(this.subscriptionStart).format('YYYY-MM-DD');
  }

  @computed
  get pdfLogoFormValues() {
    return {
      primaryColor: this.preferences.primaryColor,
      companyLogo: this.logo && this.logo.contentUrl
    };
  }

  @computed get subscriptionStatusFormatted() {
    return capitalize(this.subscriptionStatus);
  }

  @computed get subscriptionPeriodTypeFormatted() {
    return capitalize(this.subscriptionPeriodType);
  }

  @computed get statusFormatted() {
    return capitalize(this.status);
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'INACTIVE':
        return 'red';
      default:
        return 'green';
    }
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed get totalProjects() {
    return this.projects.length;
  }

  @computed get totalMembers() {
    return this.members.length;
  }

  @computed get onPerformancePlan() {
    return this.billingPlan && this.billingPlan === 'performance';
  }
}

import { computed, action, observable, runInAction, reaction } from 'mobx';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';
import UIStore from 'stores/ui/UIStore';
import DirectoryContacts from 'stores/collections/directory/DirectoryContacts';
import DirectoryContactsActionsUI from './DirectoryContactsActionsUI';
import TeamMemberProfileUI from 'stores/ui/TeamMemberProfileUI';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { getMemberRoles } from 'utils/directoryRoles';
import {
  ContactFiltersForm,
  contactFiltersFormRules,
  contactFiltersFormFields,
  contactFiltersFormOptions,
  contactFiltersFormPlugins
} from 'forms/directory/contactFilters';
import { t } from 'utils/translate';

export default class DirectoryContactsUI extends UIStore {
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable filterForm;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = true;
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;
    this.sortField = 'firstName,lastName';
    this.sortDirection = 'asc';

    this.filterForm = null;

    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);

    this.roleFilters = observable([]);
    this.classificationFilters = observable([]);

    this.directoryContacts = new DirectoryContacts(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchContactsDebounced = debounce(this.fetchContacts, BASE_DEBOUNCE);

    this.directoryContactsActionsUI = new DirectoryContactsActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Todo (ENG-27362): Add necw contact
    // this.directoryContactAddUI = new DirectoryContactAddUI({
    //   parent: this,
    //   rootStore: this.rootStore
    // });

    this.contactProfileUI = new TeamMemberProfileUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get contacts() {
    return this.directoryContacts;
  }

  @computed get hasContacts() {
    return this.contacts.hasModels;
  }

  @action.bound setup() {
    this.fetchContacts();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      status: this.statusFilters.map(option => option.id).join(','),
      role:
        this.roleFilters.length === 0
          ? 'ROLE_ACCOUNT_ADMIN,ROLE_ADMIN,ROLE_PROJECT_MEMBER,ROLE_USER,ROLE_WORKER' // TODO (joe): Add ROLE_PROJECT_WORKER
          : this.roleFilters.map(option => option.value).join(','),
      classificationUuids: this.classificationFilters
        .map(option => option.uuid)
        .join(','),
      companyUuids: this.company?.uuid,
      withProjectMembershipLookup: 'false'
    };
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchContactsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchContacts() {
    this.contacts.cancelRequest();
    this.contacts.clear();

    await this.contacts.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.contacts.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound
  setSearchQuery(query) {
    this.page = 1;
    this.searchQuery = query;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchContactsDebounced();
  }

  @action.bound
  refetchSubscription() {
    // Refetch the subscription
    this.subscription.fetch({
      params: {
        mn: 'full',
        mc: 'full'
      }
    });
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      this.hasDefaultFilters &&
      !this.hasContacts
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || !this.hasDefaultFilters) &&
      !this.hasContacts
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound showFilters() {
    this.showModal('DirectoryContactsFiltersDialog');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.statusFilters.clear();
    this.roleFilters.clear();
    this.classificationFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.statusFilters.replace(values.statusFilters);
    this.roleFilters.replace(values.roleFilters);
    this.classificationFilters.replace(values.classificationFilters);

    this.hideFilters();
  }

  @computed get hasDefaultFilters() {
    return (
      this.statusFilters.map(option => option.id).join(',') === 'ACTIVE,INVITED'
    );
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (this.statusFilters.length > 0) {
      count++;
    }

    if (this.roleFilters.length > 0) {
      count++;
    }

    if (this.classificationFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.classificationSelectorUI.setup();
    this.groupSelectorUI.setup();

    this.filterForm = new ContactFiltersForm(
      {
        fields: contactFiltersFormFields,
        rules: contactFiltersFormRules,
        values: {
          statusFilters: this.statusFilters.slice(),
          roleFilters: this.roleFilters.slice(),
          classificationFilters: this.classificationFilters.slice()
        }
      },
      {
        options: contactFiltersFormOptions,
        plugins: contactFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.filterForm = null;
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @computed get roleFilterOptions() {
    return this.roleOptions;
  }

  @computed get roleOptions() {
    return getMemberRoles();
  }

  @computed get showBulkActions() {
    return !!this.directoryContactsActionsUI.showBulkActions;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.contacts.clear();
    this.page = 1;
    this.loading = true;
    this.sortField = 'firstName,lastName';
    this.sortDirection = 'asc';
    this.filterForm = null;
    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);
    this.roleFilters.clear();
    this.classificationFilters.clear();
  }

  getImportActions() {
    return [
      {
        title: t('Import'),
        onClick: () => {
          // TODO(ENG-27495): Implement import contacts
        }
      },
      {
        title: t('Export'),
        onClick: () => {
          this.directoryContactsActionsUI.exportContacts();
        }
      },
      {
        title: t('Manage employee'),
        onClick: () => {
          // TODO(ENG-27211): Implement manage employee
        }
      }
    ];
  }
}

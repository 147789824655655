import UIStore from './UIStore';
import { action, observable, runInAction, computed } from 'mobx';
import request from 'axios';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import moment from 'moment';

import ObservationTypes from '../collections/ObservationTypes';

import uniqBy from 'lodash.uniqby';
import {
  ObservationsReportForm,
  observationsReportFormRules,
  observationsReportFormFields,
  observationsReportFormOptions,
  observationsReportFormPlugins,
  observationsReportFormValues
} from 'forms/observationsReport';

import { callTrack } from 'utils/segmentIntegration';
import { OBSERVATION_DOWNLOAD_CSV } from 'utils/segmentAnalytics/eventSpec';

export default class ObservationsReportsUI extends UIStore {
  @observable recentPayPeriodOptions;
  @observable allPayPeriodOptions;
  @observable loading;

  constructor(options) {
    super(options);

    //Pay periods
    this.recentPayPeriodOptions = [];
    this.allPayPeriodOptions = [];

    this.loading = false;

    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound
  async setup() {
    await this.observationTypes.fetch();
    this.initReportForm();
  }

  @action.bound
  tearDown() {
    this.observationTypes.reset();
  }

  @action.bound
  initReportForm() {
    this.activeForm = new ObservationsReportForm(
      {
        fields: observationsReportFormFields,
        rules: observationsReportFormRules,
        values: observationsReportFormValues
      },
      {
        options: observationsReportFormOptions,
        plugins: observationsReportFormPlugins
      }
    );
  }

  @computed get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @action.bound
  openObservationsReport() {
    this.loading = true;

    this.authorization.checkFeatureAccess('ViewObservationReports').then(() => {
      runInAction(async () => {
        this.showModal('observationsReport');
        await this.setup();

        if (this.project) {
          this.setProjectIncludeOption('SELECTED');
          this.selectProjects(this.projectDefaultValue);
        } else {
          this.setProjectIncludeOption('ACTIVE');
        }

        this.loading = false;
      });
    });
  }

  @action.bound
  hideObservationsReport() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm?.cancelReactToEnableDatePickers();
        this.activeForm = null;

        this.saving = false;
      });
    });
  }

  @action.bound
  setSpecificStartDate(date) {
    this.activeForm.$('from').set(date?.format('YYYY-MM-DD'));

    // Clear out end date if it is before start date
    if (
      this.activeForm.$('to').value &&
      moment(this.activeForm.$('to').value).isBefore(
        this.activeForm.$('from').value
      )
    ) {
      this.activeForm.$('to').set(null);
    }
  }

  @action.bound
  setSpecificEndDate(date) {
    this.activeForm.$('to').set(date?.format('YYYY-MM-DD'));
  }

  @computed
  get showSpecificPeriod() {
    return this.activeForm.$('payPeriodFilterStatus').value === 'SPECIFIC';
  }

  @action.bound
  selectProjects(selectedProjects) {
    this.activeForm.update({
      projects: selectedProjects
    });
  }

  @action.bound
  selectGroups(selectedGroups) {
    this.activeForm.update({
      groups: selectedGroups
    });
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected projects'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      },
      {
        label: t('Selected project groups'),
        value: 'SELECTED_GROUPS',
        dataQA: 'selected-project-groups'
      }
    ];
  }

  @action.bound
  setProjectIncludeOption(value) {
    this.activeForm.$('projectIncludeOption').set('value', value);

    if (value === 'ACTIVE_AND_INACTIVE' || value === 'ACTIVE') {
      this.activeForm.update({
        projects: []
      });
    }
  }

  @computed
  get enableSend() {
    if (this.saving || !this.activeForm) return false;

    return this.activeForm.isValid;
  }

  @action.bound
  async submitObservationsReportForm(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitObservationsReportFormSuccess,
      onError: this.submitObservationsReportFormError
    });
  }

  @action.bound
  submitObservationsReportFormError() {
    console.log(this.activeForm?.errors());
  }

  @action.bound
  async submitObservationsReportFormSuccess() {
    const values = this.activeForm.values();

    const payload = {
      classes: values.classes.map(classOption => classOption.value),
      projectIncludeOption: values.projectIncludeOption,
      projectUuids:
        values.projectIncludeOption !== 'SELECTED'
          ? []
          : values.projects.map(project => project.value),
      emails: values.emails,
      fromDate: values.fromDate,
      toDate: values.toDate
    };

    payload.groupUuids =
      values.projectIncludeOption !== 'SELECTED_GROUPS'
        ? []
        : values.groups.map(group => group.uuid);

    const url = `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations/csv`;

    this.saving = true;

    try {
      await request.post(url, payload);
      this.hideObservationsReport();

      callTrack(OBSERVATION_DOWNLOAD_CSV);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    const timeFrames = [
      {
        id: 'DAILY',
        fromDate: moment(new Date()),
        toDate: moment(new Date()),
        title: t('Current day'),
        projectEntire: false
      },
      {
        id: 'WEEKLY',
        fromDate: this.weekFirstDay,
        toDate: this.weekLastDay,
        title: t('Current week'),
        projectEntire: false
      },
      {
        id: 'BI_WEEKLY',
        fromDate: this.biWeeklyFirstDay,
        toDate: this.weekLastDay,
        title: t('Bi-weekly'),
        projectEntire: false
      },
      {
        id: 'MONTHLY',
        fromDate: moment(new Date()).startOf('month'),
        toDate: moment(new Date()).endOf('month'),
        title: t('Current month'),
        projectEntire: false
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      }
    ];

    if (this.numberOfSelectedProjects === 1) {
      timeFrames.push({
        id: 'PROJECTENTIRE',
        title: t('Entire duration of the project')
      });
    }

    return timeFrames;
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate
      ? moment(timeFrame.fromDate).format('YYYY-MM-DD')
      : '';

    const toDate = timeFrame.toDate
      ? moment(timeFrame.toDate).format('YYYY-MM-DD')
      : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @computed get observationClassOptions() {
    const typesList = this.observationTypes.models.map(type => {
      return {
        value: type.typeClass,
        class: type.typeClass
      };
    });

    return uniqBy(typesList, 'value');
  }
}

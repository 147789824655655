import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import {
  observationTypeFormFields,
  observationTypeFormOptions,
  observationTypeFormPlugins,
  observationTypeFormRules,
  observationTypeFormValues,
  observationTypeFormLabels,
  observationTypeFormRelated,
  ObservationTypeForm
} from 'forms/observationType';

import ObservationTypes from 'stores/collections/ObservationTypes';
import uniqBy from 'lodash.uniqby';
import ObservationType from 'stores/models/ObservationType';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';
import {
  OBSERVATION_TYPE_ADDED,
  OBSERVATION_SUBTYPE_ADDED
} from 'utils/segmentAnalytics/eventSpec';

import overrideValidationMessages from 'forms/utils/overrideValidationMessages';

export default class ObservationTypeAddUI extends UIStore {
  @observable entryForAdd;
  @observable entryAddForm;
  @observable loading;

  constructor(options) {
    super(options);
    this.entryAddForm = null;
    this.observationTypes = new ObservationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup() {
    this.fetchObservations();
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForAdd = new ObservationType(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.entryAddForm = new ObservationTypeForm(
      {
        fields: observationTypeFormFields,
        rules: observationTypeFormRules,
        labels: observationTypeFormLabels,
        values: observationTypeFormValues,
        related: observationTypeFormRelated
      },
      {
        options: observationTypeFormOptions,

        plugins: overrideValidationMessages(observationTypeFormPlugins, {
          required_without: t('The :attribute field is required.')
        }),
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryForAdd = null;
    this.entryAddForm = null;
    this.saving = false;
    this.observationTypes.clear();
  }

  @action.bound async fetchObservations() {
    this.loading = true;
    this.observationTypes.cancelRequest();
    this.observationTypes.reset();
    try {
      await this.observationTypes.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasObservations() {
    return this.observationTypes.hasModels;
  }

  @computed get observationClassOptions() {
    const classesList = this.observationTypes.models.map(type => {
      return {
        value: type.typeClass,
        name: type.typeClass
      };
    });
    return uniqBy(classesList, 'name');
  }

  @computed get observationTypeOptions() {
    const typesList = this.observationTypes.models.map(type => {
      return {
        value: type.type,
        name: type.type,
        class: type.typeClass
      };
    });
    return uniqBy(typesList, 'value');
  }

  @computed get disableSave() {
    return this.saving;
  }

  @action.bound
  async submitObservationTypesAddSuccess() {
    const {
      type,
      typeName,
      typeClass,
      subType,
      negative,
      positive
    } = this.entryAddForm.trimmedValues();

    const creationType = typeClass ? 'TYPE_CREATION' : 'SUBTYPE_CREATION';

    this.clearValidationDetails();
    this.saving = true;

    try {
      await this.entryForAdd.save(
        {
          creationType,
          type: typeName || type.name,
          subType,
          negative,
          positive,
          typeClass: typeClass.name || type.class
        },
        {
          wait: true
        }
      );

      if (creationType === 'TYPE_CREATION') {
        callTrack(OBSERVATION_TYPE_ADDED, {
          observation_type_class: typeClass.name || type.class,
          observation_type_name: typeName || type.name,
          observation_subtype_name: subType
        });
      }

      if (creationType === 'SUBTYPE_CREATION') {
        callTrack(OBSERVATION_SUBTYPE_ADDED, {
          observation_type_class: typeClass.name || type.class,
          observation_type_name: typeName || type.name,
          observation_subtype_name: subType
        });
      }

      this.parent.sortByLastCreated();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Observation type added')
      });
      this.cancelObservationTypesAdd();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitObservationTypesAdd(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryAddForm.submit({
      onSuccess: this.submitObservationTypesAddSuccess,
      onError: e => {
        console.error(this.entryAddForm.errors());
      }
    });
  }

  @action.bound
  cancelObservationTypesAdd() {
    history.push({
      pathname: `/company/observations/types`,
      search: this.baseQueryParams
    });
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

import { t } from 'utils/translate';

const policyFormPlugins = extendedPlugins;

const policyFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const policyFormFields = [
  'name',
  'isDefault',
  'basicSupervisorEntries',
  'detailedSupervisorEntries',
  'kioskEnabled',
  'timeClockEnabled',
  'photoIdentificationMode',
  'clockInTemplateUuid',
  'clockOutTemplateUuid',
  'requireCostCodes',
  'allowEmployeeCostCodeSelection',
  'allowEmployeeCrewSelection',
  'breakSetting'
];

const policyFormValues = {
  isDefault: false,
  basicSupervisorEntries: false,
  detailedSupervisorEntries: true,
  kioskEnabled: false,
  timeClockEnabled: true,
  photoIdentificationMode: 'NOT_REQUIRED',
  clockInTemplateUuid: 'NOT_REQUIRED',
  clockOutTemplateUuid: 'NOT_REQUIRED',
  requireCostCodes: false,
  allowEmployeeCostCodeSelection: false,
  allowEmployeeCrewSelection: false,
  breakSetting: 'REQUIRE_START_AND_END_TIME'
};

const policyFormRules = {
  name: 'string|required|max:50'
};

const policyFormLabels = {
  name: t('policy name')
};

class PolicyForm extends MobxReactForm {}

export {
  PolicyForm,
  policyFormPlugins,
  policyFormOptions,
  policyFormFields,
  policyFormRules,
  policyFormLabels,
  policyFormValues
};

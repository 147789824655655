import request from 'axios';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import IntegrationIds from 'fixtures/integrationIds';
import {
  OshaSubmit300AForm,
  oshaSubmit300AFormFields,
  oshaSubmit300AFormLabels,
  oshaSubmit300AFormOptions,
  oshaSubmit300AFormPlugins,
  oshaSubmit300AFormRules,
  oshaSubmit300AFormValues
} from 'forms/oshaSubmit300A';
import {
  OshaSubmitSelectForm,
  oshaSubmitSelectFormFields,
  oshaSubmitSelectFormLabels,
  oshaSubmitSelectFormOptions,
  oshaSubmitSelectFormPlugins,
  oshaSubmitSelectFormRelated,
  oshaSubmitSelectFormRules,
  oshaSubmitSelectFormValues
} from 'forms/oshaSubmitSelect';
import debounce from 'lodash.debounce';
import { action, computed, observable, reaction } from 'mobx';
import moment from 'moment';
import IncidentReport300301s from 'stores/collections/incidents/IncidentReport300301s';
import IncidentReportSubmissions from 'stores/collections/incidents/IncidentReportSubmissions';
import IncidentReport300A from 'stores/models/incidents/IncidentReport300A';
import Integration from 'stores/models/integrations/Integration';
import SubmissionSettings from 'stores/models/SubmissionSettings';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';
import {
  OSHA_FORM_DATA_ADDED,
  OSHA_FORM_DATA_SUBMITTED,
  OSHA_SUBMIT_FLOW_STARTED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';
import { t } from 'utils/translate';
import UIStore from '../../UIStore';

export default class IncidentSubmitUI extends UIStore {
  @observable oshaSubmitSelect;
  @observable oshaSubmit300A;
  @observable previous;
  @observable step; // discover, select, form300a, form300301, ready
  @observable loading;
  @observable saving;

  constructor(options) {
    super(options);

    this.oshaSubmitSelect = null;
    this.oshaSubmit300A = null;
    this.previous = 'select';
    this.step = 'select';
    this.loading = true;
    this.saving = false;

    this.integration = new Integration(
      { id: IntegrationIds.OSHA },
      {
        rootStore: this.rootStore,
        collection: this.rootStore.integrations
      }
    );

    this.fetchIncidentReportSubmissionsDebounced = debounce(
      this.fetchIncidentReportSubmissions,
      BASE_DEBOUNCE
    );

    this.fetchIncidentReport300ADebounced = debounce(
      this.fetch300A,
      BASE_DEBOUNCE
    );

    this.fetchIncidentReport300301sDebounced = debounce(
      this.fetch300301,
      BASE_DEBOUNCE
    );

    this.incidentReport300A = new IncidentReport300A(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentReport300301s = new IncidentReport300301s(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.submissionSettings = new SubmissionSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.incidentReportSubmissions = new IncidentReportSubmissions(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound handleBack() {
    this.setStep(this.previous);
  }

  @action.bound async setup(step = 'select') {
    this.setStep(step);

    await this.submissionSettings.fetch();
    await this.fetchConfigurations();

    this.setupReactions();
    this.setupOshaSubmitSelectForm();
    this.setupOshaSubmit300AForm();
  }

  @action.bound setupReactions() {
    this.reactToYear = reaction(
      () =>
        this.oshaSubmitSelect &&
        this.oshaSubmitSelect.$('submission.year').value,
      async () => {
        const {
          enableForm300A,
          enableForm300301
        } = this.oshaSubmitSelect.values();
        if (enableForm300A) {
          this.fetch300A().then(() =>
            this.oshaSubmit300A.update({
              ...this.incidentReport300A.formValues
            })
          );
        }
        if (enableForm300301) {
          this.fetchIncidentReport300301sDebounced();
        }
        this.fetchIncidentReportSubmissionsDebounced();
      }
    );

    this.reactToForm300A = reaction(
      () =>
        this.oshaSubmitSelect &&
        this.oshaSubmitSelect.$('enableForm300A').value,
      () => {
        this.fetchIncidentReport300ADebounced();
      }
    );

    this.reactToForm300301 = reaction(
      () =>
        this.oshaSubmitSelect &&
        this.oshaSubmitSelect.$('enableForm300301').value,
      () => {
        this.fetchIncidentReport300301sDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToYear && this.reactToYear();
    this.reactToForm300A && this.reactToForm300A();
    this.reactToForm300301 && this.reactToForm300301();
  }

  @action.bound tearDown() {
    this.oshaSubmitSelect = null;
    this.oshaSubmit300A = null;
    this.previous = 'select';
    this.step = 'select';

    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.incidentReport300A.clear();
    this.incidentReport300301s.clear();
    this.incidentReportSubmissions.clear();
    this.submissionSettings.clear();
    this.loading = true;
    this.saving = false;
  }

  @action.bound async fetchConfigurations() {
    this.loading = true;

    try {
      await this.integration.fetch();

      if (!this.integration.isConnected) {
        this.setStep('discover');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchIncidentReportSubmissions() {
    this.loading = true;

    try {
      await this.incidentReportSubmissions.fetch({
        params: {
          limit: 1,
          offset: 0,
          submissionYear: this.year
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetch300A() {
    this.loading = true;

    try {
      await this.incidentReport300A.fetch({
        params: {
          year: this.year
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetch300301() {
    this.loading = true;

    try {
      await this.incidentReport300301s.fetch({
        params: {
          year: this.year
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound setupOshaSubmitSelectForm() {
    this.oshaSubmitSelect = new OshaSubmitSelectForm(
      {
        fields: oshaSubmitSelectFormFields,
        related: oshaSubmitSelectFormRelated,
        rules: oshaSubmitSelectFormRules,
        labels: oshaSubmitSelectFormLabels,
        values: Object.assign(oshaSubmitSelectFormValues, {
          submission: this.selectYearOptions.find(
            item => item.id === 'PREVIOUS'
          ),
          annualAverageEmployees: this.annualAverageEmployees,
          totalHoursWorked: this.totalHoursWorked
        })
      },
      {
        options: oshaSubmitSelectFormOptions,
        plugins: oshaSubmitSelectFormPlugins
      }
    );
  }

  @action.bound setupOshaSubmit300AForm() {
    this.oshaSubmit300A = new OshaSubmit300AForm(
      {
        fields: oshaSubmit300AFormFields,
        rules: oshaSubmit300AFormRules,
        labels: oshaSubmit300AFormLabels,
        values: oshaSubmit300AFormValues
      },
      { options: oshaSubmit300AFormOptions, plugins: oshaSubmit300AFormPlugins }
    );
  }

  @computed get establishmentName() {
    return this.submissionSettings.establishmentName;
  }

  @computed get hasWriteAccess() {
    return this.authorization.canCUDSubmitIncidentReports;
  }

  @computed get annualAverageEmployees() {
    return this.submissionSettings.annualAverageEmployees;
  }

  @computed get totalHoursWorked() {
    return this.submissionSettings.totalHoursWorked;
  }

  @computed get modalTitle() {
    const titleMap = {
      discover: t('Discover OSHA Submit'),
      select: t('Submit to OSHA'),
      form300a: t('Form 300A'),
      form300301: t('Form 300/301'),
      ready: t('Ready to Submit')
    };

    return titleMap[this.step];
  }

  @computed get navigationPath() {
    return 'company';
  }

  @action.bound async submitOshaSelect() {
    await this.authorization.checkFeatureAccess('CUDSubmitIncidentReports');

    history.push({
      pathname: `${this.parent.viewUrl}/osha-submit`
    });

    callTrack(OSHA_SUBMIT_FLOW_STARTED);
  }

  @action.bound async cancelOshaSubmit() {
    history.push({
      pathname: this.parent.viewUrl
    });
  }

  @action.bound submitOshaSelectForm(event) {
    event.preventDefault();

    this.oshaSubmitSelect.submit({
      onSuccess: this.submitOshaSelectFormSuccess,
      onError: () => console.error(this.oshaSubmitSelect.errors())
    });
  }

  @action.bound async submitOshaSelectFormSuccess() {
    const { enableForm300301 } = this.oshaSubmitSelect.values();

    if (enableForm300301) {
      this.validateForm300301();
    } else {
      this.delegateNextStep();
    }
  }

  @action.bound async validateForm300301() {
    this.saving = true;

    try {
      await request.get(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/incident-reports/submission/validate?year=${this.year}`
      );
      this.delegateNextStep();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.showModal('ExportWarningModal');
    } finally {
      this.saving = false;
    }
  }

  @action.bound delegateNextStep() {
    const { enableForm300A, enableForm300301 } = this.oshaSubmitSelect.values();

    if (enableForm300A) {
      this.fetch300A().then(() => {
        this.oshaSubmit300A.update({
          ...this.incidentReport300A.formValues
        });
        this.setStep('form300a');
      });
    } else if (enableForm300301) {
      this.setStep('form300301');
    } else {
      this.setStep('ready');
    }
  }

  @action.bound submitOsha300A() {
    this.oshaSubmit300A.submit({
      onSuccess: this.submitOsha300ASuccess,
      onError: () => console.error(this.oshaSubmit300A.errors())
    });
  }

  @action.bound async submitOsha300ASuccess() {
    this.saving = true;
    this.clearValidationDetails();

    try {
      await request.post(`${this.incidentReport300A.url()}?year=${this.year}`, {
        annualAverageEmployees: Number(
          this.oshaSubmitSelect.$('annualAverageEmployees').value
        ),
        totalHoursWorked: Number(
          this.oshaSubmitSelect.$('totalHoursWorked').value
        )
      });

      const { enableForm300301 } = this.oshaSubmitSelect.values();
      if (enableForm300301) {
        this.previous = 'form300a';
        this.setStep('form300301');
      } else {
        this.setStep('ready');
      }

      callTrack(OSHA_FORM_DATA_ADDED, {
        submission_year: this.year,
        osha_form_type: 'form300a'
      });

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${t('Form 300A has been successfully added to OSHA ITA')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async submitOsha300301() {
    this.saving = true;
    this.clearValidationDetails();

    try {
      await request.post(
        `${this.incidentReport300301s.url()}?year=${this.year}`
      );

      callTrack(OSHA_FORM_DATA_ADDED, {
        submission_year: this.year,
        osha_form_type: 'form300/301'
      });

      this.setStep('ready');

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${t('Form 300/301 has been successfully added to OSHA ITA')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async submitOshaReadyForm() {
    this.saving = true;
    this.clearValidationDetails();

    try {
      await request.post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/incident-reports/submission`,
        {
          yearFilingFor: Number(this.year),
          changeReason: this.oshaSubmitSelect.$('resubmission').value
        }
      );

      callTrack(OSHA_FORM_DATA_SUBMITTED, {
        submission_year: this.year
      });

      await this.cancelOshaSubmit();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: `${t('OSHA form(s) submitted successfully')}`
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound selectYear(id) {
    return this.selectYearOptions.find(option => option.id === id);
  }

  @action.bound camelCaseToSentence(item) {
    return (
      item
        // Insert a space before each uppercase letter
        .replace(/([A-Z])/g, ' $1')
        // Make the whole string lowercase
        .toLowerCase()
        // Capitalize the first letter of the first word
        .replace(/^./, match => match.toUpperCase())
        // Trim any leading or trailing spaces
        .trim()
    );
  }

  @action.bound
  async cancelExportWarningModal() {
    await this.hideActiveModal();
  }

  @action.bound
  async proceedExportWarningModal() {
    await this.hideActiveModal();
    this.delegateNextStep();
  }

  @computed get year() {
    return this.oshaSubmitSelect.$('submission.year').value;
  }

  @computed get selectYearOptions() {
    return [
      {
        id: 'CURRENT',
        year: moment()
          .endOf('year')
          .format('YYYY')
      },
      {
        id: 'PREVIOUS',
        year: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY')
      }
    ];
  }

  @computed get settingsUrl() {
    if (this.integration.isConnected) {
      return `/company/integrations/${IntegrationIds.OSHA}/settings`;
    }

    return `/company/integrations/${IntegrationIds.OSHA}`;
  }

  @computed get hasIncidentReportSubmissions() {
    return (
      this.incidentReportSubmissions && this.incidentReportSubmissions.hasModels
    );
  }

  @computed
  get enableSubmitSelect() {
    if (!this.oshaSubmitSelect || this.saving) {
      return false;
    }

    return this.oshaSubmitSelect.isValid;
  }

  @computed get readyToSubmit() {
    const { enableForm300A, enableForm300301 } = this.oshaSubmitSelect.values();
    let result = [];

    if (enableForm300A) {
      result.push(t(`Form 300A (${this.year})`));
    }

    if (enableForm300301) {
      result.push(t(`Form 300/301 (${this.year})`));
    }

    return result;
  }
}

import axios from 'axios';

export default (file, name, headers = {}) => {
  return axios({
    url: file,
    method: 'GET',
    responseType: 'blob', // important,
    headers: headers
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  });
};

import request from 'axios';
import { computed, action, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import SmallEquipmentWithdrawUI from './SmallEquipmentWithdrawUI';
import SmallEquipmentLostStolenUI from './SmallEquipmentLostStolenUI';

import {
  SmallEquipmentDeploymentForm,
  smallEquipmentDeploymentFormOptions,
  smallEquipmentDeploymentFormFields,
  smallEquipmentDeploymentFormRules,
  smallEquipmentDeploymentFormValues,
  smallEquipmentDeploymentFormLabels,
  smallEquipmentDeploymentFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentDeployment';

import {
  SmallEquipmentBulkDeploymentForm,
  smallEquipmentBulkDeploymentFormOptions,
  smallEquipmentBulkDeploymentFormFields,
  smallEquipmentBulkDeploymentFormRules,
  smallEquipmentBulkDeploymentFormLabels,
  smallEquipmentBulkDeploymentFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentBulkDeployment';

import { callTrack } from 'utils/segmentIntegration';
import {
  SMALL_EQUIPMENT_DEPLOYED,
  SMALL_EQUIPMENT_DELETED
} from 'utils/segmentAnalytics/eventSpec';

export default class SmallEquipmentActionsUI extends UIStore {
  @observable smallEquipmentToUpdate;
  @observable selectedSmallEquipmentAction;
  @observable smallEquipmentDeploymentForm;
  @observable saving;

  constructor(options) {
    super(options);

    this.saving = false;

    // Individual Actions
    this.smallEquipmentToUpdate = null;
    this.selectedSmallEquipmentAction = null;

    // Bulk Actions
    this.selectedSmallEquipment = observable([], { deep: false });

    //Deployment
    this.smallEquipmentDeploymentForm = null;

    // Withdraw
    this.smallEquipmentWithdrawUI = new SmallEquipmentWithdrawUI({
      parent: this,
      rootStore: this.rootStore
    });

    // Lost / stolen
    this.smallEquipmentLostStolenUI = new SmallEquipmentLostStolenUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get smallEquipment() {
    return this.parent.smallEquipment;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedSmallEquipment.clear();
    this.smallEquipmentToUpdate = null;
    this.selectedSmallEquipmentAction = null;
  }

  @computed get hasSelectedSmallEquipment() {
    return this.selectedSmallEquipment.length > 0;
  }

  @computed get hasNoSelectedSmallEquipment() {
    return this.selectedSmallEquipment.length === 0;
  }

  @computed get allSmallEquipmentSelected() {
    if (!this.hasSelectedSmallEquipment) return false;

    return (
      this.smallEquipment.models.filter(smallEquipment => {
        return this.findSelectedSmallEquipment(smallEquipment);
      }).length === this.smallEquipment.length
    );
  }

  findSelectedSmallEquipment = smallEquipment => {
    return this.selectedSmallEquipment.find(
      selectedSmallEquipment =>
        selectedSmallEquipment?.uuid === smallEquipment.uuid
    );
  };

  @action.bound toggleSelectAllSmallEquipment() {
    if (this.allSmallEquipmentSelected) {
      this.smallEquipment.models.forEach(smallEquipment => {
        this.selectedSmallEquipment.remove(
          this.findSelectedSmallEquipment(smallEquipment)
        );
      });
    } else {
      this.smallEquipment.models.forEach(smallEquipment => {
        if (!this.findSelectedSmallEquipment(smallEquipment)) {
          this.selectedSmallEquipment.push(smallEquipment);
        }
      });
    }
  }

  @action.bound toggleSelectSmallEquipment(smallEquipment) {
    const selectedSmallEquipment = this.findSelectedSmallEquipment(
      smallEquipment
    );

    if (selectedSmallEquipment) {
      this.selectedSmallEquipment.remove(selectedSmallEquipment);
    } else {
      this.selectedSmallEquipment.push(smallEquipment);
    }
  }

  @action.bound async deleteSmallEquipment(smallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    if (smallEquipment) {
      this.smallEquipmentToUpdate = smallEquipment;
    }

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteSmallEquipment() {
    await this.hideActiveModal();

    this.smallEquipmentToUpdate = null;
  }

  @action.bound async confirmDeleteSmallEquipment() {
    this.saving = true;

    try {
      if (this.smallEquipmentToUpdate) {
        await this.smallEquipmentToUpdate.destroy({ wait: true });

        callTrack(SMALL_EQUIPMENT_DELETED, {
          small_equipment_name: this.smallEquipmentToUpdate.name
        });
      } else {
        await request.delete(`${this.smallEquipment.url()}/batch`, {
          params: {
            equipmentUuids: this.selectedSmallEquipment
              .map(selectedSmallEquipment => selectedSmallEquipment.uuid)
              .join(',')
          }
        });

        this.selectedSmallEquipment.forEach(smallEquipment => {
          callTrack(SMALL_EQUIPMENT_DELETED, {
            small_equipment_name: smallEquipment.name
          });
        });

        this.selectedSmallEquipment.clear();
      }

      this.parent.refetchSmallEquipment();

      this.cancelDeleteSmallEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  getSmallEquipmentActions = smallEquipment => {
    return [
      {
        title: t('View'),
        onClick: () => {
          this.parent.editSmallEquipment(smallEquipment);
        }
      },
      {
        title: t('Deploy'),
        onClick: () => {
          this.deploySmallEquipment(smallEquipment);
        }
      },
      {
        title: t('Withdraw'),
        onClick: () => {
          this.smallEquipmentWithdrawUI.withdrawSmallEquipment(smallEquipment);
        }
      },
      {
        title: t('Report lost / stolen'),
        onClick: () => {
          this.smallEquipmentLostStolenUI.markLostStolenSmallEquipment(
            smallEquipment
          );
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteSmallEquipment(smallEquipment);
        }
      }
    ];
  };

  // Bulk Actions
  @computed get bulkSmallEquipmentActions() {
    let actions = [];

    if (this.hasSelectedSmallEquipment) {
      actions = actions.concat([
        {
          title: t('Deploy'),
          onClick: () => {
            this.bulkDeploySmallEquipment();
          }
        },
        {
          title: t('Withdraw'),
          onClick: () => {
            this.smallEquipmentWithdrawUI.bulkWithdrawSmallEquipment();
          }
        },

        {
          title: t('Report lost / stolen'),
          onClick: () => {
            this.smallEquipmentLostStolenUI.bulkMarkLostStolenSmallEquipment();
          }
        },

        {
          title: t('Delete'),
          onClick: () => {
            this.deleteSmallEquipment();
          }
        }
      ]);
    }

    return actions;
  }

  // Single Equipment Deployment
  @action.bound
  async deploySmallEquipment(smallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.smallEquipmentToUpdate = smallEquipment;

    this.smallEquipmentDeploymentForm = new SmallEquipmentDeploymentForm(
      {
        fields: smallEquipmentDeploymentFormFields,
        rules: {
          ...smallEquipmentDeploymentFormRules,
          numberOfUnits: `required|numeric|min:0|max:${smallEquipment.availableUnits}`
        },
        labels: smallEquipmentDeploymentFormLabels,
        values: smallEquipmentDeploymentFormValues
      },
      {
        options: smallEquipmentDeploymentFormOptions,
        plugins: smallEquipmentDeploymentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('SmallEquipmentDeploymentModal');
  }

  @action.bound async cancelDeploySmallEquipment() {
    await this.hideActiveModal();

    this.projectSelectorUI.tearDown();
    this.smallEquipmentToUpdate = null;
    this.smallEquipmentDeploymentForm = null;
  }

  @action.bound submitDeploySmallEquipmentForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.smallEquipmentDeploymentForm.submit({
      onSuccess: this.submitSmallEquipmentDeploymentFormSuccess,
      onError: this.submitSmallEquipmentDeploymentFormError
    });
  }

  @action.bound async submitSmallEquipmentDeploymentFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentDeploymentForm.values();

    try {
      await request.post(
        `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/projects/${values.project.value}/smallequipment/${this.smallEquipmentToUpdate.uuid}/deploy`,
        {
          quantity: values.numberOfUnits
        }
      );

      callTrack(SMALL_EQUIPMENT_DEPLOYED, {
        small_equipment_name: this.smallEquipmentToUpdate.name
          ? this.smallEquipmentToUpdate.name
          : this.smallEquipmentToUpdate.equipmentName,
        units_deployed: values.numberOfUnits
      });

      this.parent.refetchSmallEquipment();

      this.cancelDeploySmallEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment deployed.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitSmallEquipmentDeploymentFormError() {
    console.error(this.smallEquipmentDeploymentForm.errors());
  }

  // Bulk Equipment Deployment
  @action.bound async bulkDeploySmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.smallEquipmentBulkDeploymentForm = new SmallEquipmentBulkDeploymentForm(
      {
        fields: smallEquipmentBulkDeploymentFormFields,
        rules: smallEquipmentBulkDeploymentFormRules,
        labels: smallEquipmentBulkDeploymentFormLabels,
        values: {
          project: null,
          deployments: this.selectedSmallEquipment.map(smallEquipment => ({
            uuid: smallEquipment.uuid,
            name: smallEquipment.name,
            totalUnits: smallEquipment.totalUnits,
            availableUnits: smallEquipment.availableUnits,
            numberOfUnits: 0
          }))
        }
      },
      {
        options: smallEquipmentBulkDeploymentFormOptions,
        plugins: smallEquipmentBulkDeploymentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentBulkDeploymentForm
      .$('deployments')
      .fields.forEach(deployment =>
        deployment
          .$('numberOfUnits')
          .set(
            'rules',
            `required|numeric|min:0|max:${deployment.$('availableUnits').value}`
          )
      );

    this.showModal('SmallEquipmentBulkDeploymentModal');
  }

  @action.bound async cancelBulkDeploySmallEquipment() {
    await this.hideActiveModal();
    this.smallEquipmentBulkDeploymentForm = null;
    this.projectSelectorUI.tearDown();
  }

  @action.bound submitEquipmentBulkDeploymentForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.smallEquipmentBulkDeploymentForm.submit({
      onSuccess: this.submitSmallEquipmentBulkDeploymentFormSuccess,
      onError: this.submitSmallEquipmentBulkDeploymentFormError
    });
  }

  @action.bound async submitSmallEquipmentBulkDeploymentFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentBulkDeploymentForm.values();

    try {
      await request.post(
        `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/projects/${values.project.value}/smallequipment/deploy/batch`,
        values.deployments
          .filter(deployment => deployment.numberOfUnits > 0)
          .map(deployment => ({
            equipmentUuid: deployment.uuid,
            quantity: Number(deployment.numberOfUnits)
          }))
      );

      values.deployments.forEach(deployment => {
        callTrack(SMALL_EQUIPMENT_DEPLOYED, {
          small_equipment_name: deployment.name,
          units_deployed: deployment.numberOfUnits
        });
      });

      this.parent.refetchSmallEquipment();
      this.cancelBulkDeploySmallEquipment();

      this.clearUIState();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Small equipment deployed.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitSmallEquipmentBulkDeploymentFormError() {
    console.error(this.smallEquipmentBulkDeploymentForm.errors());
  }
}

import extendedPlugins from 'forms/utils/extendedPlugins';
import MobxReactForm from 'mobx-react-form';

import { t } from 'utils/translate';

const oshaConnectFormPlugins = extendedPlugins;

const oshaConnectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const oshaConnectFormFields = ['apiToken', 'establishment'];

const oshaConnectFormRules = {
  apiToken: 'string|required',
  establishment: 'required'
};

const oshaConnectFormLabels = {
  apiToken: t('api token'),
  establishment: t('establishment')
};

const oshaConnectFormValues = {
  apiToken: '',
  establishment: {}
};

class OshaConnectForm extends MobxReactForm {}

export {
  OshaConnectForm,
  oshaConnectFormOptions,
  oshaConnectFormFields,
  oshaConnectFormRules,
  oshaConnectFormLabels,
  oshaConnectFormValues,
  oshaConnectFormPlugins
};

import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';

import request from 'axios';

import {
  PolicyDuplicateForm,
  policyDuplicateFormOptions,
  policyDuplicateFormFields,
  policyDuplicateFormRules,
  policyDuplicateFormPlugins,
  policyDuplicateFormLabels
} from 'forms/policyDuplicate';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

export default class PolicyDuplicateUI extends UIStore {
  @observable originalPolicy;
  @observable form;
  @observable saving;

  constructor(options) {
    super(options);

    this.originalPolicy = '';
    this.form = null;
    this.saving = false;
  }

  @action.bound setup() {
    this.form = new PolicyDuplicateForm(
      {
        fields: policyDuplicateFormFields,
        rules: policyDuplicateFormRules,
        labels: policyDuplicateFormLabels,
        values: {
          policyName: this.createUniqTemplateName(this.originalPolicy.name)
        }
      },
      {
        options: policyDuplicateFormOptions,
        plugins: policyDuplicateFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.originalPolicy = null;
    this.form = null;
  }

  createUniqTemplateName(baseName) {
    const uniqueName = `Copy of ${baseName}`;

    return uniqueName;
  }

  @action.bound
  showDuplicateModal(originalPolicy) {
    this.originalPolicy = originalPolicy;
    this.setup();
    this.activeModal = 'policyDuplicateModal';
  }

  @action.bound
  async hideDuplicateModal() {
    await this.hideActiveModal();
    this.tearDown();
  }

  @computed get saveDisabled() {
    return this.saving;
  }

  @action.bound
  submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: () => {
        this.submitFormSuccess();
      },
      onError: () => {
        console.log(this.form.errors());
      }
    });
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;

    const { policyName } = this.form.values();
    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/${this.originalPolicy.uuid}/copy`,
        {
          name: policyName
        }
      );

      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
  }

  @action.bound
  handleSaveSuccess() {
    this.notifications.pushNotification({
      title: t('Policy created'),
      snackbar: 'warning',
      icon: 'notification'
    });

    this.parent.refetchPolicies();
    this.hideDuplicateModal();
  }
}

import { action, computed } from 'mobx';
import ProjectChildAddUI from './ProjectChildAddUI';
import history from 'utils/history';
import { t } from 'utils/translate';

import WorkLogAddStandardUI from './WorkLogAddStandardUI';
import WorkLogAddCrewUI from './WorkLogAddCrewUI';

export default class WorkLogAddUI extends ProjectChildAddUI {
  constructor(options) {
    super(options);

    this.workLogAddStandardUI = new WorkLogAddStandardUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogAddCrewUI = new WorkLogAddCrewUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  // This is so we can change routes and wording
  // to "Time sheets" from  the timesheets area
  @computed get timesheetsMode() {
    return this.parent.timesheetsMode;
  }

  // This is so we can change routes and wording
  // to "Time cards" from  the time cards tab
  @computed get timeCardsMode() {
    return this.parent.timeCardsMode;
  }

  @computed get workLogType() {
    if (
      this.settings.defaultWorklogType === 'timeCards' ||
      this.timesheetsMode
    ) {
      return 'CREW';
    }

    return 'STANDARD';
  }

  @action.bound async setup() {
    if (this.workLogType === 'CREW') {
      this.workLogAddCrewUI.setup();
    } else {
      this.workLogAddStandardUI.setup();
    }
  }

  @action.bound tearDown() {
    if (this.workLogType === 'STANDARD') {
      this.workLogAddStandardUI.tearDown();
    }

    if (this.workLogType === 'CREW') {
      this.workLogAddCrewUI.tearDown();
    }
  }

  @action.bound cancelWorkLogAdd() {
    if (this.timesheetsMode) {
      history.push({
        pathname: `/timesheets`
      });
    } else if (this.timeCardsMode) {
      history.push({
        pathname: `${this.project.viewUrl}/timecards`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `${this.project.viewUrl}/worklogs`,
        search: this.baseQueryParams
      });
    }
  }

  @computed get title() {
    if (this.workLogType === 'CREW') {
      return t('Add time card');
    }

    return t('Add work log');
  }

  @computed get backButtonText() {
    if (this.timesheetsMode) {
      return t('Back to time cards');
    }

    if (this.timeCardsMode) {
      return t('Back to time cards');
    }

    return t('Back to work logs');
  }
}

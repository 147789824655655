import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CERTIFICATION_DELETED } from 'utils/segmentAnalytics/eventSpec';

import Certifications from 'stores/collections/Certifications';
import CertificationAddUI from './CertificationAddUI';
import CertificationEditUI from './CertificationEditUI';

export default class CertificationsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedCertification;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedCertification = null;

    this.sortField = 'createdDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Certifications collection
    this.certifications = new Certifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationAddUI = new CertificationAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationEditUI = new CertificationEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchCertificationsDebounced = debounce(
      this.fetchCertifications,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchCertifications();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchCertificationsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: `${this.sortField},typeName`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchCertifications() {
    this.certifications.cancelRequest();
    this.certifications.reset();

    try {
      await this.certifications.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasCertifications() {
    return this.certifications.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.certifications.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.certifications.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdDate';
    this.sortDirection = 'desc';
    this.selectedCertification = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasCertifications;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasCertifications;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addCertification() {
    await this.authorization.checkFeatureAccess('CUDCompanyCertifications');
    history.push({
      pathname: `/company/certifications/certifications/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editCertification(certification) {
    await this.authorization.checkFeatureAccess('CUDCompanyCertifications');

    history.push({
      pathname: `/company/certifications/certifications/${certification.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteCertification(certification) {
    await this.authorization.checkFeatureAccess('CUDCompanyCertifications');
    this.selectedCertification = certification;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteCertification() {
    await this.hideActiveModal();

    this.selectedCertification = null;
  }

  @action.bound async confirmDeleteCertification() {
    this.saving = true;

    try {
      await this.selectedCertification.destroy({ wait: true });

      this.refetchCertifications();

      await this.hideActiveModal();

      callTrack(CERTIFICATION_DELETED, {
        certification_type_type: this.selectedCertification.type,
        certification_type_name: this.selectedCertification.name
      });

      this.selectedCertification = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Certification type deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCertificationsDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCertificationsDebounced();
  }

  @action.bound refetchCertifications() {
    this.loading = true;
    if (!this.hasCertifications) {
      this.setPage(null, 1);
      this.fetchCertifications();
    } else {
      this.fetchCertifications();
    }
  }

  getCertificationActions = certification => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editCertification(certification);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteCertification(certification);
        }
      }
    ];
  };
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const contactFiltersFormPlugins = extendedPlugins;

const contactFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const contactFiltersFormFields = [
  'statusFilters[]',
  'roleFilters[]',
  'classificationFilters[]'
];

const contactFiltersFormRules = {
  statusFilters: 'array',
  roleFilters: 'array',
  classificationFilters: 'array'
};

class ContactFiltersForm extends MobxReactForm {}

export {
  ContactFiltersForm,
  contactFiltersFormRules,
  contactFiltersFormFields,
  contactFiltersFormOptions,
  contactFiltersFormPlugins
};

import UIStore from './UIStore';
import CostCodes from 'stores/collections/CostCodes';
import { action, computed, observable, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class CostCodeSelectorUI extends UIStore {
  @observable costCodeSearchQuery;
  @observable searchingForCostCode;
  @observable requestParams;

  @observable projectUuid;
  @observable includeMaterials;

  constructor(options) {
    super(options);

    this.costCodesearchQuery = '';
    this.fetchCostCodesDebounced = debounce(this.fetchCostCodes, BASE_DEBOUNCE);
    this.searchingForCostCode = false;

    this.projectUuid = null;
    this.includeMaterials = false;

    this.selectorCostCodes = new CostCodes(
      (options.costCodesFixtureData = null),
      {
        rootStore: this.rootStore
      }
    );
  }

  @computed get costCodes() {
    return this.selectorCostCodes;
  }

  @computed get costCodesModels() {
    return this.costCodes.models.slice();
  }

  @computed get noOptionsTextCostCodes() {
    return this.searchingForProject
      ? t('Searching')
      : !this.costCodes.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setCostCodeSearchQuery(query) {
    this.costCodeSearchQuery = query;
    this.searchingForCostCode = true;
  }

  @action.bound
  clearCostCodeSearchQuery() {
    this.costCodeSearchQuery = '';
  }

  @computed get hasMoreCostCodes() {
    return this.costCodes.totalElements > this.costCodes.length;
  }

  setupCostCodeReactions() {
    this.cancelReactToCostCodeParams = reaction(
      () => this.costCodeParams,
      () => {
        this.fetchCostCodesDebounced();
      }
    );
  }

  @computed get costCodeParams() {
    if (this.requestParams) {
      return this.requestParams;
    }

    return {
      query: this.costCodeSearchQuery,
      projectUuids: this.projectUuid,
      limit: 80,
      includeMaterials: this.includeMaterials.toString(),
      sortField: 'division,code',
      sortDirection: 'ASC',
      projectStatus: ['ACTIVE', 'INACTIVE', 'DELETED']
    };
  }

  @action.bound
  fetchCostCodes(options) {
    if (this.costCodes.fetching) return;

    return this.costCodes
      .fetch({
        params: pickBy(this.costCodeParams, identity),
        ...options
      })
      .then(() => {
        this.searchingForCostCode = false;
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  fetchNextCostCodes = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfCostCodes().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfCostCodes() {
    if (this.costCodes.fetching || !this.hasMoreCostCodes) return;

    try {
      this.costCodes.fetch({
        params: Object.assign(pickBy(this.costCodeParams, identity), {
          offset: this.costCodes.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  async setup(options = {}) {
    if (options.includeMaterials) {
      this.includeMaterials = options.includeMaterials;
    }

    if (options.projectUuid) {
      this.projectUuid = options.projectUuid;
    } else {
      this.projectUuid = this.project?.uuid;
    }

    await this.fetchCostCodes();

    this.setupCostCodeReactions();
  }

  @action.bound
  tearDown() {
    this.cancelReactToCostCodeParams && this.cancelReactToCostCodeParams();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.costCodes.reset();
    this.includeMaterials = false;
    this.projectUuid = null;
  }
}

import React from 'react';
import { observer } from 'mobx-react';

import { Dialog, Button, Box, Text, Link } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

export default observer(({ uiStore }) => {
  return (
    <Dialog
      dataQA="terms-of-service"
      open={uiStore.activeModal === 'termsOfService'}
      fullWidth={true}
      maxWidth="md"
    >
      <Dialog.Title dataQA="terms-of-service">
        {t('Welcome to Raken')}
      </Dialog.Title>

      <Dialog.Content style={{ minHeight: 200 }}>
        <Box mb={6}>
          <Text>
            {t('Before you proceed, please review and accept the following:')}
          </Text>
        </Box>
        <Box mb={6}>
          <Text>
            {t(
              'By accessing and using Raken, you agree to comply with our Terms of Service and acknowledge that you have read and understood our Privacy Policy. These documents outline your responsibilities, how your data will be used, and your rights as a user.'
            )}
          </Text>
        </Box>
        <Box mb={6}>
          <Text>
            {t('For more information, you can access our full')}{' '}
            <Link
              href="https://www.rakenapp.com/terms-of-service"
              isExternal
              target="_blank"
              underline="always"
            >
              {t('Terms of Service')}
            </Link>{' '}
            {t('and')}{' '}
            <Link
              href=" https://www.rakenapp.com/privacy"
              isExternal
              target="_blank"
              underline="always"
            >
              {t('Privacy Policy.')}
            </Link>
          </Text>
        </Box>
      </Dialog.Content>

      <Dialog.Actions>
        <Button
          rakenColor="white"
          onClick={uiStore.declineTermsOfService}
          data-qa="decline"
        >
          {t('Decline and logout')}
        </Button>
        <Button
          disabled={uiStore.saving}
          onClick={uiStore.acceptTermsOfService}
          data-qa="accept"
        >
          {t('Accept')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
});

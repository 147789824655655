import { action, computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import FormTemplates from 'stores/collections/forms/FormTemplates';
import FormTemplate from 'stores/models/forms/FormTemplate';
import Attachment from 'stores/models/Attachment';

import history from 'utils/history';
import bytesToSize from 'utils/bytesToSize';
import fileToBase64 from 'utils/fileToBase64';
import alertErrorHandler from 'utils/alertErrorHandler';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import { t } from 'utils/translate';

export default class FormTemplateAddUI extends UIStore {
  constructor(options) {
    super(options);

    this.formTemplates = new FormTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound
  setup() {
    this.formTemplates.reset(
      this.formTemplates.models.filter(form => form.document.isNew)
    );
  }

  @action.bound
  tearDown() {}

  @computed
  get hasFormTemplatesUploading() {
    return this.formTemplates.hasModels;
  }

  @action.bound async handleFormTemplateUpload(uploadItem) {
    const file = uploadItem.file;

    if (file.size > 262144000) {
      this.rootStore.notificationsUI.pushNotification({
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 250MB.'
        )}`,
        snackbar: 'error',
        icon: 'notification'
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    const previewAttachment = new Attachment(
      {
        fileName: file.name,
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    const previewFormTemplate = new FormTemplate(
      { name: file.name, document: previewAttachment },
      {
        rootStore: this.rootStore
      }
    );

    this.formTemplates.add(previewFormTemplate);

    try {
      await this.formTemplates.upload({
        file: file,
        analytics: {
          module: 'FormTemplates'
        },
        previewForm: previewFormTemplate,
        progressCallback: percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewFormTemplate.document.setUploadProgress(percentCompleted);
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.parent.sortByLastCreated();
    }
  }

  @action.bound rejectFileType(fileName) {
    const extension = fileName.split('.').pop();

    this.notifications.pushNotification({
      title: `File of type .${extension} is not supported.`,
      snackbar: 'error',
      icon: 'notification'
    });
  }

  @action.bound cancelFormTemplateAdd() {
    history.push({
      pathname: `/company/forms/templates`
    });
  }
}

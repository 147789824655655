import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import QuickbooksEmployees from 'stores/collections/integrations/QuickbooksEmployees';

import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';

export default class IntegrationQBOEmployeeImportUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.quickbooksEmployees = new QuickbooksEmployees(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.selectedEmployees = observable([]);

    this.searchQuery = '';

    this.fetchEmployeesDebounced = debounce(this.fetchEmployees, BASE_DEBOUNCE);
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get employees() {
    return this.quickbooksEmployees;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEmployees();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEmployees();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchEmployeesDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchEmployees() {
    this.loading = true;

    await this.employees.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get params() {
    return {
      limit: 1000,
      unlinkedOnly: true
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.employees.totalElements / this.pageSize);
  }

  @computed get hasSelectedEmployees() {
    return this.selectedEmployees.length > 0;
  }

  @action.bound
  toggleSelectEmployee(employeeId) {
    if (this.selectedEmployees.find(id => id === employeeId)) {
      this.selectedEmployees.remove(employeeId);
    } else {
      this.selectedEmployees.push(employeeId);
    }
  }

  @computed get hasEmployees() {
    return this.employees.hasModels;
  }

  @computed
  get allEmployeesSelected() {
    return (
      this.hasEmployees &&
      this.employees.models.every(employee =>
        this.selectedEmployees.includes(employee.id)
      )
    );
  }

  @action.bound
  toggleSelectAllEmployees() {
    if (this.allEmployeesSelected) {
      this.selectedEmployees.replace(
        this.selectedEmployees.filter(id => {
          return !this.employees.models
            .map(employee => employee.id)
            .includes(id);
        })
      );
    } else {
      this.employees.models.forEach(employee => {
        if (!this.selectedEmployees.includes(employee.id)) {
          this.selectedEmployees.push(employee.id);
        }
      });
    }
  }

  @computed get someEmployeesSelected() {
    return this.hasSelectedEmployees && !this.allEmployeesSelected;
  }

  @action.bound clearAllSelectedEmployees() {
    this.selectedEmployees.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound async confirmImportQuickbooksEmployees() {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    try {
      const { data } = await request.post(`${this.employees.url()}/sync`, {
        externalIds: this.selectedEmployees.slice()
      });

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Employee import',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.cancelImportQuickbooksEmployees();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelImportQuickbooksEmployees() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1019/employees`
      );
    } else {
      history.push('/company/integrations/1019/employees');
    }
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.searchQuery = '';
    this.selectedEmployees.clear();
    this.employees.clear();
    this.clearValidationDetails();
  }
}

import { action, computed, observable, reaction, runInAction } from 'mobx';
import request from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import UIStore from 'stores/ui/UIStore';
import debounce from 'lodash.debounce';
import MyTimeSummaryUI from './MyTimeSummaryUI';
import MyTimeTimeCardsUI from './MyTimeTimeCardsUI';
import MyTimeTimeCardAddUI from './MyTimeTimeCardAddUI';
import MyTimeTimeCardAddPoliciesUI from './MyTimeTimeCardAddPoliciesUI';
import CostCode from 'stores/models/CostCode';

import TimeCards from 'stores/collections/workLogs/TimeCards';

import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

const moment = extendMoment(Moment);

export default class MyTimeUI extends UIStore {
  @observable loading;

  constructor(options) {
    super(options);

    this.loading = true;

    this.myTimeTimeCardAddUI = new MyTimeTimeCardAddUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.myTimeTimeCardAddPoliciesUI = new MyTimeTimeCardAddPoliciesUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.myTimeSummaryUI = new MyTimeSummaryUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.myTimeTimeCardsUI = new MyTimeTimeCardsUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.timeCards = new TimeCards(null, {
      rootStore: this.rootStore
    });

    this.defaultCostCode = new CostCode(null, { rootStore: this.rootStore });
    this.defaultTimePolicy = null;

    this.fetchTimeCardsDebounced = debounce(this.fetchTimeCards, BASE_DEBOUNCE);
  }

  @action.bound async setup() {
    if (!this.authorization.canViewTimeCards) return;

    this.setupReactions();
    this.fetchWorkerSettings();
    this.payPeriodSelectorUI.setup();
  }

  @action.bound tearDown() {
    this.payPeriodSelectorUI.tearDown();
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound async fetchWorkerSettings() {
    const response = await request.get(
      `ra/companies/${this.company.uuid}/members/${this.me.workerUuid}/settings`
    );

    this.defaultTimePolicy = response.data.settings.workerDefaultTimePolicy;

    this.defaultCostCode.id = response.data.settings.workerDefaultCostCode;
    this.defaultCostCode.fetch();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchTimeCardsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get hasRequiredParams() {
    return (
      this.params.fromDate && this.params.toDate && this.params.workerUuids
    );
  }

  @action.bound async fetchTimeCards() {
    if (!this.hasRequiredParams) return;

    this.timeCards.cancelRequest();
    this.timeCards.clear();

    try {
      await this.timeCards.fetch({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/timecards`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound refetchTimeCards() {
    this.loading = true;
    this.fetchTimeCards();
  }

  @action.bound refetchPayPeriods() {
    this.loading = true;
    this.payPeriodSelectorUI.setup();
  }

  @computed get payPeriodsEmpty() {
    return this.payPeriodSelectorUI.payPeriodsEmpty;
  }

  @computed get hasTimeCards() {
    return this.timeCards.hasModels;
  }

  @computed get showEmptyState() {
    return this.payPeriodsEmpty;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get params() {
    return {
      fromDate: this.payPeriodSelectorUI.selectedPeriod?.value?.from,
      toDate: this.payPeriodSelectorUI.selectedPeriod?.value?.to,
      workerUuids: this.me.workerUuid,
      sortField: 'date',
      sortDirection: 'asc',
      limit: 10000
    };
  }

  @computed get selectedPayPeriodDates() {
    const range = moment.range(
      moment(this.params.fromDate, 'YYYY-MM-DD'),
      moment(this.params.toDate, 'YYYY-MM-DD')
    );

    return Array.from(range.by('day')).map(moment =>
      moment.format('YYYY-MM-DD')
    );
  }

  @action.bound clearUIState() {
    this.timeCards.clear();
    this.loading = true;
    this.defaultTimePolicy = null;
  }
}

import styled from 'styled-components';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

import { Img, Container, Box, Text, Alert } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

import AppConnectForm from './AppConnectForm';
import AppConnectFormRyvit from './AppConnectFormRyvit';
import AppConnectFormOsha from './AppConnectFormOsha';
import AppConnectFormComputerEaseDirectAPI from './AppConnectFormComputerEaseDirectAPI';
import AppConnectFormSageIntacct from './AppConnectFormSageIntacct';

const StyledContainer = styled(Container)`
  min-height: 100vh;
`;

const AppConnectText = observer(({ uiStore }) => {
  if (uiStore.activeIntegration.isRyvit) {
    return (
      <Text align="center">
        {t(
          'Please enter a Ryvit Subscriber Code to sync this Raken account with Vista.'
        )}
      </Text>
    );
  }

  if (uiStore.activeIntegration.isSageIntacct) {
    return (
      <Text align="center">
        {t(
          'Please enter the Web Service User credentials provided by the client to connect this account to Sage Intacct.'
        )}
      </Text>
    );
  }

  if (uiStore.activeIntegration.isOsha) {
    return (
      <Text align="center">
        {t(
          'Please enter your OSHA Injury Tracking Application (ITA) API token and select an establishment to connect your account to OSHA. Upon connection, your incident submission settings will be updated to match the selected establishment info.'
        )}
      </Text>
    );
  }

  if (uiStore.activeIntegration.isComputerEaseDirectAPI) {
    return (
      <Text align="center">
        {t(
          'Please enter the ComputerEase Refresh Token provided by the client to connect the integration.'
        )}
      </Text>
    );
  }

  return (
    <Text align="center">
      {t(
        'Please enter {integrationName} credentials to sync your Raken account with your {integrationName} account.',
        {
          templateStrings: {
            integrationName: uiStore.activeIntegration.name
          }
        }
      )}
    </Text>
  );
});

const AppConnect = observer(({ store, location, match }) => {
  const uiStore = store.appConnectUI;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    uiStore.initializeConnect({
      integrationId: params.get('integrationId'),
      companyId: params.get('companyId'),
      companyUuid: params.get('companyUuid'),
      projectId: params.get('projectId'),
      projectUuid: params.get('projectUuid')
    });

    return () => {
      uiStore.teardown();
    };
  }, [uiStore, location]);
  if (uiStore.loading || !uiStore.activeIntegration || !uiStore.activeForm)
    return null;

  return (
    <ScopedCssBaseline>
      <StyledContainer mx="auto" height="100%">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box mb={2}>
            <Img
              src={uiStore.activeIntegration.logoLarge}
              alt={uiStore.activeIntegration.name}
              data-qa="image_active-integration-logo"
              height="55"
            />
          </Box>
          <Box mb={4}>
            <Text variant="h1" dataQA="raken" bold>
              {uiStore.activeIntegration.submitPlaceholder}
            </Text>
          </Box>
          <Box mb={6}>
            <AppConnectText uiStore={uiStore} />
          </Box>
          {uiStore.hasValidationDetails && (
            <Box mb={6} width="100%">
              <Alert>
                {uiStore.validationDetails.map(item => item.fieldMessage)}
              </Alert>
            </Box>
          )}
          <Box width="100%">
            {uiStore.activeIntegration.isRyvit ? (
              <AppConnectFormRyvit uiStore={uiStore} />
            ) : uiStore.activeIntegration.isSageIntacct ? (
              <AppConnectFormSageIntacct uiStore={uiStore} />
            ) : uiStore.activeIntegration.isOsha ? (
              <AppConnectFormOsha uiStore={uiStore} />
            ) : uiStore.activeIntegration.isComputerEaseDirectAPI ? (
              <AppConnectFormComputerEaseDirectAPI uiStore={uiStore} />
            ) : (
              <AppConnectForm uiStore={uiStore} />
            )}
          </Box>
        </Box>
      </StyledContainer>
    </ScopedCssBaseline>
  );
});

export default AppConnect;

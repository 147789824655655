import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import ComputerEaseDirectAPIEmployee from 'stores/models/integrations/ComputerEaseDirectAPIEmployee';

export default class ComputerEaseDirectAPIEmployees extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1030/employee`;
  }

  model() {
    return ComputerEaseDirectAPIEmployee;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}

import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { Model } from 'mobx-mc';
import { computed, observable } from 'mobx';

import Subscription from '../models/Subscription';
import RecurlySubscription from '../models/billing/Subscription';
import Transactions from '../collections/Transactions';

import moment from 'moment-timezone';

export default class Company extends Model {
  @observable subscription;

  constructor(attributes, options) {
    super(attributes, options);

    if (
      attributes.billingPlatform === 'RECURLY' &&
      !Boolean(attributes.isInternal)
    ) {
      this.subscription = new RecurlySubscription(null, {
        parent: this,
        rootStore: this.rootStore
      });
    } else {
      this.subscription = new Subscription(
        attributes.subscription ? attributes.subscription : null,
        {
          parent: this,
          rootStore: this.rootStore
        }
      );

      this.transactions = new Transactions(null, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  url() {
    return `/ra/company`;
  }

  get restAttributes() {
    return [
      'id',
      'uuid',
      'type',
      'name',
      'address',
      'phone',
      'primaryColor',
      'status',
      'images',
      'hasSuperDaily',
      'billingPlan',
      'billingPlatform',
      'seatsInUse',
      'workerSeatsInUse',
      'isInternal',
      'isPromo',
      'logo',
      'preferences',
      'applyTo',
      'projectIds',
      'hasWorkersWithoutContactDetails',
      'employeeRange',
      'industry',
      'subIndustries',
      'email',
      'ccEmails',
      'companyAddOns',
      'accountSubType'
    ];
  }

  get restAttributeDefaults() {
    return {
      workerSeatsInUse: 0,
      seatsInUse: 0,
      subIndustries: [],
      preferences: {
        primaryColor: '#FFFFFF',
        timeCardsStartAndEndTime: false,
        languageId: 1,
        messagingEnabled: false,
        showTimeCardsDivision: false
      }
    };
  }

  @computed
  get addressString() {
    const properties = pickBy(
      pick(this.address, [
        'streetAddress',
        'city',
        'state',
        'postCode',
        'country'
      ]),
      identity
    );

    return Object.keys(properties)
      .filter(k => {
        return properties[k] !== 'null';
      })
      .map(k => {
        return properties[k].replace('.', '');
      })
      .join(', ');
  }

  @computed
  get requiredInfoComplete() {
    return this.name;
  }

  @computed
  get requiredInfoFormValues() {
    return pickBy(
      {
        name: this.name,
        address: {
          postCode: this.address && this.address.postCode,
          country: this.address && this.address.country
        }
      },
      identity
    );
  }

  @computed
  get formValues() {
    return {
      id: this.id,
      name: this.name
    };
  }

  @computed
  get infoFormValues() {
    return {
      name: this.name,
      phone: this.phone,
      address: this.address,
      language: this.preferences.languageId,
      employeeRange: this.employeeRange,
      industry: this.industry,
      subIndustries: this.subIndustries.slice(),
      email: this.email,
      ccEmails: this.ccEmails
    };
  }

  @computed
  get pdfLogoFormValues() {
    return {
      primaryColor: this.preferences.primaryColor,
      companyLogo: this.logo && this.logo.contentUrl
    };
  }

  @computed
  get informationFormValues() {
    return {
      name: this.name,
      phone: this.phone,
      address: this.address,
      language: this.preferences.languageId,
      employeeRange: this.employeeRange,
      industry: this.industry,
      subIndustries: this.subIndustries.slice(),
      primaryColor: this.preferences.primaryColor,
      companyLogo: this.logo && this.logo.contentUrl,
      email: this.email,
      ccEmails: this.ccEmails
    };
  }

  @computed
  get timeCardsFormValues() {
    const {
      timeCardsStartAndEndTime,
      showTimeCardsDivision,
      trackMemberTime
    } = this.preferences;

    return {
      timeCardsStartAndEndTime: timeCardsStartAndEndTime,
      showTimeCardsDivision: showTimeCardsDivision,
      trackMemberTime: trackMemberTime || 'assignedWorkersAndAllTeamMembers'
    };
  }

  @computed
  get timezoneAbbr() {
    return moment.tz(this.address.timezone).zoneAbbr();
  }

  @computed
  get useImperialFormatting() {
    const imperialCountryCodes = ['US', 'GB', 'MM', 'LR', 'VI', 'PR']; // Must match fixtures/countries.js
    const currentCountryCode = this.address?.country || '';

    return imperialCountryCodes.includes(currentCountryCode);
  }

  @computed
  get hasGeoLocation() {
    return (
      this.address.geolocation &&
      this.address.geolocation.lat &&
      this.address.geolocation.lng
    );
  }

  @computed get policiesEnabled() {
    return this.preferences.timePolicyStatus === 'ENABLED';
  }

  @computed get hasSetupDefaultPolicy() {
    return this.preferences.timePolicyStatus === 'SETUP';
  }

  @computed get disableLegacySettings() {
    /**
     * TODO this can be tidied up with FF_SETUP_TIME_POLICIES
     * The policies it disables should also be removed at this point.
     */
    return this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES;
  }

  @computed get hideLegacySettings() {
    /**
     * TODO this can be tidied up with FF_SETUP_TIME_POLICIES
     * The policies it hides should also be removed at this point.
     */
    return (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES && this.policiesEnabled
    );
  }
}

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import omit from 'lodash.omit';

import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';

import UIStore from './UIStore';
import ProjectsMinimal from '../collections/ProjectsMinimal';

import history from 'utils/history';
import { t } from 'utils/translate';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import alertErrorHandler from 'utils/alertErrorHandler';

import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import {
  WorkersForm,
  workersFormOptions,
  workersFormFields,
  workersFormRules,
  workersFormPlugins
} from 'forms/workers';

export default class TeamWorkersAddUI extends UIStore {
  // Add
  @observable workerForm;
  @observable workersForm;
  @observable step;

  @observable phoneCountry;

  // Import
  @observable uploadProgress;

  // Projects
  @observable projectSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable isDefault;

  constructor(options) {
    super(options);

    this.step = 'workers';
    this.workerForm = null;
    this.workersForm = null;

    // Projects
    this.paginatedProjects = new ProjectsMinimal(null, {
      rootStore: this.rootStore
    });

    this.loading = true;
    this.projectSelectionMethod = 'ACTIVE';
    this.selectedProjects = observable([]);
    this.isDefault = true;

    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound setStep(step) {
    this.step = step;
  }

  @action.bound setup(step = 'workers') {
    this.phoneCountry = this.defaultPhoneCountry;
    this.setStep(step);
    this.setupWorkerForm();
    this.setupWorkersForm();
    this.setupReactions();
    this.fetchProjects();
    this.setupWorkerAttributes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.tearDownWorkerAttributes();
    this.clearUIState();
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownWorkerAttributes() {
    this.shiftSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        this.fetchProjects();
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchProjectsDebounced();
      }
    );

    this.cancelReactToWorkerFormRules = reaction(
      () => this.workerFormRules,
      workerFormRules => {
        this.workerForm.set('rules', this.workerFormRules);
        this.workerForm.validate();
      },
      {
        delay: 50
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
    this.cancelReactToWorkerFormRules && this.cancelReactToWorkerFormRules();
  }

  @computed get workerFormRules() {
    return Object.assign({}, workerFormRules, {
      email: `string|email|max:200|not_in:${this.workersFormEmails}|worker_email_available`,
      employeeId: `string|max:100|employeeId_available|not_in:${this.workersFormEmployeeIds}`,
      phoneNumber: `string|phone_available|max:30|not_in:${this.workersFormPhoneNumbers}`
    });
  }

  @action.bound setupWorkerForm() {
    this.workerForm = new WorkerForm(
      {
        fields: workerFormFields,
        rules: this.workerFormRules,
        labels: workerFormLabels,
        options: workerFormFieldOptions
      },
      {
        options: Object.assign({}, workerFormOptions, {
          validateOnInit: true,
          showErrorsOnInit: false
        }),
        plugins: workerFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound setupWorkersForm() {
    this.workersForm = new WorkersForm(
      {
        fields: workersFormFields,
        rules: workersFormRules
      },
      {
        options: workersFormOptions,
        plugins: workersFormPlugins
      }
    );
  }

  @action.bound cancelWorkersAdd() {
    history.push('/workers');
  }

  @action.bound submitWorkerForm(event) {
    event.preventDefault();

    if (this.workerForm.submitting) return;

    this.workerForm.submit({
      onSuccess: this.submitWorkerFormSuccess,
      onError: this.submitWorkerFormError
    });
  }

  @action.bound submitWorkerFormError() {
    console.error(this.workerForm.errors());
  }

  @action.bound submitWorkerFormSuccess() {
    const values = this.workersForm.$('workers').values();

    values.unshift(this.workerForm.trimmedValues());

    this.workersForm.update({
      workers: values
    });

    this.setupWorkerForm();

    setTimeout(() => {
      this.phoneCountry = '';
      this.phoneCountry = this.defaultPhoneCountry;
    }, 0);
  }

  @computed
  get workersFormEmails() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('email').value)
      .join(',');
  }

  @computed
  get workersFormEmployeeIds() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('employeeId').value)
      .join(',');
  }

  @computed
  get workersFormPhoneNumbers() {
    if (!this.workersForm) return [];

    return this.workersForm
      .$('workers')
      .map(worker => worker.$('phoneNumber').value)
      .join(',');
  }

  @computed
  get workerExistsInWorkersForm() {
    if (!this.workerForm) return false;

    const values = this.workerForm.trimmedValues();

    return this.workersForm.trimmedValues().workers.find(worker => {
      return (
        worker.firstName === values.firstName &&
        worker.lastName === values.lastName &&
        worker.employeeId === values.employeeId
      );
    });
  }

  @computed get workerExists() {
    return this.workerForm.existingWorker || this.workerExistsInWorkersForm;
  }

  @computed get workerExistsMessage() {
    if (!this.workerExists) return '';

    if (this.workerForm.$('employeeId').value) {
      return t('A worker with this name and Employee Id already exists.');
    }

    return t(
      'A worker with this name already exists. Use a unique Employee Id to differentiate.'
    );
  }

  @computed get workerFormIsValid() {
    return !this.workerExists && this.workerForm.check('isValid');
  }

  @computed get classificationOptions() {
    return this.parent.classificationOptions;
  }

  @computed get hasAddedWorkers() {
    return this.workersForm.$('workers').size > 0;
  }

  @action.bound removeWorker(worker) {
    this.workersForm.$('workers').del(worker.key);

    if (!this.workersForm.$('workers').size) {
      this.clearValidationDetails();
    }
  }

  @action.bound submitWorkersForm(event) {
    event.preventDefault();

    if (this.workersForm.submitting) return;

    this.workersForm.submit({
      onSuccess: this.submitWorkersFormSuccess,
      onError: this.submitWorkersFormError
    });
  }

  @action.bound submitWorkersFormSuccess() {
    this.setStep('defaults');
  }

  @action.bound skipDefaults() {
    this.workersForm.$('workers').map(worker => {
      worker.$('defaultShift').clear();
      worker.$('defaultCostCode').clear();
      worker.$('defaultCrewName').clear();
    });
    this.setStep('projects');
  }

  @action.bound completeDefaults() {
    this.setStep('projects');
  }

  @action.bound submitWorkersFormError() {
    console.error(this.workersForm.errors());
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: 'ACTIVE'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setProjectSelectionMethod(event) {
    this.selectedProjects.clear();

    if (event.target.value === 'ACTIVE') {
      this.selectAllProjects();
    }

    this.projectSelectionMethod = event.target.value;
    this.page = 1;
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @computed get someProjectsSelected() {
    return this.hasSelectedProjects && !this.allProjectsSelected;
  }

  @action.bound selectAllProjects() {
    this.projects.models.forEach(project => {
      if (!this.selectedProjects.includes(project.uuid)) {
        this.selectedProjects.push(project.uuid);
      }
    });
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @action.bound toggleIsDefault() {
    this.isDefault = !this.isDefault;
  }

  @action.bound cancelProjects() {
    this.setStep('workers');
  }

  @action.bound async createWorkers() {
    if (this.saving) return;

    try {
      this.saving = true;

      const workerFields = this.workersForm.trimmedValues().workers;

      if (this.projectSelectionMethod === 'SELECTED') {
        workerFields.forEach(workerField => {
          workerField.isDefault = this.isDefault;
          workerField.projectUuids = this.selectedProjects.slice();
          workerField.role = 'ROLE_WORKER';

          if (!workerField.classification.uuid) {
            delete workerField.classification;
          }
        });
      } else {
        workerFields.forEach(workerField => {
          delete workerField.projectUuids;
          workerField.isDefault = this.isDefault;
          workerField.role = 'ROLE_WORKER';
          workerField.addToActiveProjects = true;

          if (!workerField.classification.uuid) {
            delete workerField.classification;
          }
        });
      }

      const payload = workerFields.map(workerField => {
        const workerFieldData = pickBy(
          workerField,
          value => value !== undefined && value !== '' && value !== null
        );

        workerFieldData.settings = {
          workerDefaultShift: workerField.defaultShift?.uuid,
          workerDefaultCostCode: workerField.defaultCostCode?.uuid,
          workerDefaultCrewName: workerField.defaultCrewName?.uuid
        };

        return omit(workerFieldData, [
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]);
      });

      await request.post(`${this.rootStore.workers.url()}/batch`, payload);

      // Used to get the hasWorkersWithoutContactDetails field when things change.
      this.company.fetch();

      history.push('/workers');

      this.parent.sortByLastCreated();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Workers added')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound clearUIState() {
    this.workerForm = null;
    this.workersForm = null;
    this.searchQuery = '';
    this.isDefault = true;
    this.selectedProjects.clear();
    this.projectSelectionMethod = 'ACTIVE';
    this.projects.clear();
    this.page = 1;
    this.step = 'workers';
    this.clearValidationDetails();
    this.loading = false;
  }
}

import { observable, action, when, computed } from 'mobx';
import UIStore from './UIStore';

import {
  MemberDefaultsForm,
  memberDefaultsFormOptions,
  memberDefaultsFormFields,
  memberDefaultsFormFieldOptions,
  memberDefaultsFormLabels,
  memberDefaultsFormPlugins
} from 'forms/memberDefaults';

import alertErrorHandler from 'utils/alertErrorHandler';

import { t } from 'utils/translate';
import history from 'utils/history';

export default class TeamWorkerDefaultsUI extends UIStore {
  @observable entryEditForm;

  constructor(options) {
    super(options);

    // Editing
    this.entryEditForm = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup(id) {
    this.setupWorkerAttributes();
    this.setEntryEditForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
    this.tearDownWorkerAttributes();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();

    this.entryEditForm = null;
  }

  @action.bound setEntryEditForm() {
    when(
      () => !this.entryForEdit.settings.fetchingWorkerDefaults,
      () => {
        this.entryEditForm = new MemberDefaultsForm(
          {
            fields: memberDefaultsFormFields,
            labels: memberDefaultsFormLabels,
            values: this.entryForEdit.defaultAttributeFormValues,
            options: memberDefaultsFormFieldOptions
          },
          {
            options: memberDefaultsFormOptions,
            plugins: memberDefaultsFormPlugins
          }
        );

        this.blockHistoryIfFormChanged();
      }
    );
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed get disableEntryEditSaveButton() {
    if (!this.authorization.canEditWorkers) return true;

    return this.entryForEdit.saving;
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();

    if (this.entryEditForm.submitting) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    const values = this.entryEditForm.values();

    values.settings = {
      workerDefaultShift: values.defaultShift?.uuid,
      workerDefaultCostCode: values.defaultCostCode?.uuid,
      workerDefaultCrewName: values.defaultCrewName?.uuid
    };

    try {
      await this.entryForEdit.save(
        { settings: values.settings },
        {
          wait: true,
          stripNonRest: false
        }
      );

      if (this.entryForEdit.isMe) {
        this.rootStore.me.fetch({
          url: `ra/user`,
          reset: true
        });
      }

      this.resetEntryEditForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Worker default attributes saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    history.push(`${this.entryForEdit.viewUrl}/info`);
    console.error(this.entryEditForm.errors());
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound tearDownWorkerAttributes() {
    this.shiftSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.costCodeSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
  }
}

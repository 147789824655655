import { action, computed } from 'mobx';

import ChecklistTemplatesUI from './company/checklists/ChecklistTemplatesUI.js';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import TimeClockQuestionsTemplateEditUI from './TimeClockQuestionsTemplateEditUI';
import TimeClockQuestionsTemplateStartUI from './TimeClockQuestionsTemplateStartUI';
import TimeClockQuestionsTemplateAddUI from './TimeClockQuestionsTemplateAddUI';

import { t } from 'utils/translate';
import history from 'utils/history';

import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';

import { QUESTIONS_TEMPLATES } from 'utils/segmentAnalytics/eventSpec';

export default class TimeClockQuestionsTemplatesUI extends ChecklistTemplatesUI {
  constructor(options) {
    super(options);

    this.sortField = 'name';

    this.timeClockQuestionsLayout = true;

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateEditUI = new TimeClockQuestionsTemplateEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateStartUI = new TimeClockQuestionsTemplateStartUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.checklistTemplateAddUI = new TimeClockQuestionsTemplateAddUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    if (this.authorization.hasTimeClockAccess) {
      this.fetchChecklistTemplates();
      callTrack(QUESTIONS_TEMPLATES);
    }
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      scope: ['RAKEN', 'COMPANY']
    };
  }

  @action.bound async fetchChecklistTemplates() {
    this.checklistTemplates.cancelRequest();
    this.checklistTemplates.reset();

    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.checklistTemplates.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.selectedChecklistTemplate = null;
    this.duplicateTemplateForm = null;
  }

  // Override functions to change urls to timesheets
  @action.bound async startChecklistTemplate() {
    // This will be covered in an upcoming ticket.
    history.push({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates/start`,
      search: this.baseQueryParams
    });
  }

  @action.bound async previewChecklistTemplate(checklistTemplate) {
    this.checklistTemplateEditUI.togglePreviewMode();

    history.push({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates/${checklistTemplate.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editChecklistTemplate(checklistTemplate) {
    await this.authorization.checkFeatureAccess('CUDCompanyChecklistTemplates');

    history.push({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates/${checklistTemplate.uuid}`,
      search: this.baseQueryParams
    });
  }

  getChecklistTemplateActions = checklistTemplate => {
    /**
     * We over ride this function to remove the delete option for MVP.
     * Is we bring delete back we can remove this function
     */
    if (
      !this.authorization.canCUDCompanyChecklistTemplates ||
      checklistTemplate.scope === 'RAKEN'
    ) {
      return [
        {
          title: t('Preview'),
          onClick: () => {
            this.previewChecklistTemplate(checklistTemplate);
          }
        }
      ];
    }

    if (checklistTemplate.status === 'DRAFT') {
      return [
        {
          title: t('Resume editing'),
          onClick: () => {
            this.editChecklistTemplate(checklistTemplate);
          }
        }
      ];
    }

    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editChecklistTemplate(checklistTemplate);
        }
      },
      {
        title: t('Preview'),
        onClick: () => {
          this.previewChecklistTemplate(checklistTemplate);
        }
      },
      {
        title: t('Duplicate'),
        onClick: () => {
          this.duplicateChecklistTemplate(checklistTemplate);
        }
      }
    ];
  };

  @action.bound sortByLastUpdated() {
    this.sortField = 'modifyDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTemplatesDebounced();
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchChecklistTemplatesDebounced();
  }

  @computed
  get totalPages() {
    return Math.ceil(this.checklistTemplates.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed
  get timesheetsPath() {
    return this.parent.timesheetsPath;
  }
}

import {
  action,
  observable,
  computed,
  reaction,
  runInAction,
  toJS
} from 'mobx';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import WorkersMapLocations from 'stores/collections/WorkersMapLocations';
import Certifications from 'stores/collections/Certifications';
import UIStore from './UIStore';

import request from 'axios';
import errorHandler from 'utils/errorHandler';

import download from 'utils/download';

import debounce from 'lodash.debounce';

import {
  WorkerMapFiltersForm,
  workerMapFiltersFormRules,
  workerMapFiltersFormFields,
  workerMapFiltersFormOptions,
  workerMapFiltersFormPlugins
} from 'forms/workerMapFilters';

import { callTrack } from 'utils/segmentIntegration';

import {
  LABOR_MAP_OPENED,
  LABOR_MAP_FILTER,
  LABOR_MAP_DISPLAY_FILTERS,
  LABOR_MAP_VIEW_PROFILE,
  LABOR_MAP_EXPORT
} from 'utils/segmentAnalytics/eventSpec';
import { getAllRoles } from 'utils/roles';

export default class WorkersMapUI extends UIStore {
  @observable map;

  @observable mapNELat;
  @observable mapNELong;
  @observable mapSWLat;
  @observable mapSWLong;

  @observable sideNavOpen;
  @observable sideNavLocation;
  @observable workerExpandedUuid;

  @observable classificationFilters;
  @observable roleFilters;
  @observable certificationFilters;
  @observable shiftFilters;
  @observable groupFilters;

  constructor(options) {
    super(options);

    this.google = window.google;
    this.map = null;
    this.mapRef = null;

    this.clusterer = null;

    // WorkersMapLocation collection
    this.workersMapLocations = new WorkersMapLocations(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certifications = new Certifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.mapNELat = null;
    this.mapNELong = null;
    this.mapSWLat = null;
    this.mapSWLong = null;

    this.resizeMapForFilters = false;

    this.sideNavOpen = false;
    this.sideNavLocation = null;
    this.workerExpandedUuid = null;

    this.activeForm = null;

    this.classificationFilters = [];
    this.roleFilters = [];
    this.certificationFilters = [];
    this.shiftFilters = [];
    this.groupFilters = [];

    this.fetchWorkersMapLocations = debounce(
      this.fetchWorkersMapLocations,
      250
    );
  }

  @action.bound
  async setup(mapRef) {
    this.mapRef = mapRef;

    var styles = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      }
    ];

    this.map = new this.google.maps.Map(this.mapRef, {
      zoomControl: true,
      animatedZoom: false,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      center: this.defaultLocation,
      zoom: 7,
      zoomControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: this.google.maps.ControlPosition.LEFT_BOTTOM
      },
      gestureHandling: 'cooperative',
      styles
    });

    this.setupReactions();

    callTrack(LABOR_MAP_OPENED);

    this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      //Found a race condition where getBounds was returning Null fixed by waiting for bounds_changed once on first
      this.map.addListener('idle', () => {
        this.setMapCorners(this.map.getBounds());
      });
    });
  }

  @action.bound
  tearDown() {
    this.google.maps.event.clearListeners(this.map, 'bounds_changed');
    this.google.maps.event.clearListeners(this.map, 'idle');
    this.tearDownReactions();

    this.sideNavOpen = false;
    this.sideNavLocation = null;
    this.workerExpandedUuid = null;

    this.activeForm = null;

    this.clusterer = null;

    this.mapNELat = null;
    this.mapNELong = null;
    this.mapSWLat = null;
    this.mapSWLong = null;
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchWorkersMapLocations();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed get params() {
    return {
      mapNELat: this.mapNELat,
      mapNELong: this.mapNELong,
      mapSWLat: this.mapSWLat,
      mapSWLong: this.mapSWLong,
      classifications: this.classificationFilters.map(filter => filter.uuid),
      roles: this.roleFilters.map(filter => filter.value),
      certifications: this.certificationFilters.map(filter => filter.uuid),
      shifts: this.shiftFilters.map(filter => filter.uuid),
      groups: this.groupFilters.map(filter => filter.uuid),
      limit: 500
    };
  }

  @computed get defaultLocation() {
    return (
      this.rootStore.userLocation?.geolocation ||
      this.company.address.geolocation
    );
  }

  @computed get markers() {
    return this.clusterer?.markers?.map(marker => marker);
  }

  @computed get markerPositions() {
    return this.markers?.map(marker => {
      return {
        lat: parseFloat(marker.position.lat()),
        lng: parseFloat(marker.position.lng())
      };
    });
  }

  @action.bound
  setMapCorners(bounds) {
    this.mapNELat = bounds.getNorthEast().lat();
    this.mapNELong = bounds.getNorthEast().lng();
    this.mapSWLat = bounds.getSouthWest().lat();
    this.mapSWLong = bounds.getSouthWest().lng();
  }

  @action.bound async fetchWorkersMapLocations() {
    if (this.clusterer) {
      this.clearPins();
    } else {
      this.clusterer = new MarkerClusterer({
        map: this.map,
        renderer: {
          render({ markers, position }, stats) {
            // Set cluster to always stay as one color and override default changing color.
            const color = '#0000ff';

            const totalMarkers = markers.reduce(
              (acc, marker) => acc + Number(marker.label.text),
              0
            );
            // create svg url with fill color
            const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>`);
            // create marker using svg icon
            return new window.google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new window.google.maps.Size(45, 45)
              },
              label: {
                text: String(totalMarkers),
                color: 'rgba(255,255,255,0.9)',
                fontSize: '12px'
              },
              title: `Cluster of ${totalMarkers} workers`,
              // adjust zIndex to be above other markers
              zIndex:
                Number(window.google.maps.Marker.MAX_ZINDEX) + totalMarkers
            });
          }
        }
      });
    }

    this.workersMapLocations.fetching = false;

    return request
      .post(
        `/ra/companies/${this.rootStore.me.company.uuid}/members/recent/locations`,
        this.params
      )
      .then(
        response => {
          runInAction(() => {
            this.workersMapLocations.set(response.data);
            this.workersMapLocations.fetching = false;

            this.dropPins();

            if (this.resizeMapForFilters) {
              this.centralize();
            }

            this.resizeMapForFilters = false;
          });
        },
        error => {
          this.workersMapLocations.fetching = false;
          errorHandler(error, this.notifications.pushError);
        }
      );
  }

  clearPins(forceClear) {
    const bounds = this.map.getBounds();

    this.markers.forEach(marker => {
      if (!bounds.contains(marker.position) || forceClear) {
        this.clusterer.removeMarker(marker);
      }
    });
  }

  centralize() {
    const bounds = new this.google.maps.LatLngBounds();
    this.markerPositions.forEach(marker => {
      bounds.extend(new this.google.maps.LatLng(marker.lat, marker.lng));
    });
    this.map.fitBounds(bounds);

    // Make sure we don't zoom in to much
    if (this.map.getZoom() >= 12) {
      this.map.setZoom(12);
    }
  }

  dropPins() {
    this.workersMapLocations.models.forEach(workerLocation => {
      const pin = this.addPin(workerLocation);
      workerLocation.pin = pin;
    });
  }

  ifPinExists(position) {
    return this.markerPositions?.find(
      marker => marker.lat === position.lat && marker.lng === position.lng
    );
  }

  addPin(workerLocation) {
    const position = {
      lat: parseFloat(workerLocation.location.lat),
      lng: parseFloat(workerLocation.location.lng)
    };

    if (this.ifPinExists(position)) return workerLocation.pin;

    // Set cluster to always stay as one color and override default changing color.
    const color = '#ff2952';
    // create svg url with fill color
    const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
</svg>`);

    const pin = new this.google.maps.Marker({
      position,
      map: this.map,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(45, 45)
      },
      label: {
        text: String(workerLocation.count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '12px'
      },
      title:
        workerLocation.count === 1
          ? `${workerLocation.count} worker`
          : `${workerLocation.count} workers`,
      // adjust zIndex to be above other markers
      zIndex:
        Number(window.google.maps.Marker.MAX_ZINDEX) + workerLocation.count
    });

    pin.addListener('click', () => {
      this.sideNavOpen = true;
      this.sideNavLocation = workerLocation;
    });

    this.clusterer.addMarker(pin);

    return pin;
  }

  @action.bound
  clearWorkerFilters() {
    this.workerFilters.clear();
  }

  @action.bound
  updateWorkerFilters(filters) {
    this.workerFilters.replace(filters);
    this.clearPins(true);
    this.resizeMapForFilters = true;
  }

  @computed get stateOptions() {
    return this.parent.stateOptions;
  }

  get updateMapState() {
    return this.parent.updateMapState;
  }

  @action.bound
  toggleSideBarCollapsed() {
    if (this.sideNavOpen) {
      this.closeAllWorkersExpanded();
    }

    this.sideNavOpen = !this.sideNavOpen;
  }

  @action.bound
  closeAllWorkersExpanded() {
    this.sideNavLocation?.workers?.forEach(worker => {
      worker.expanded = false;
    });
  }

  @action.bound
  toggleWorkerExpanded(worker) {
    if (!this.sideNavOpen) {
      this.toggleSideBarCollapsed();
    }

    worker.expanded = !worker.expanded;
  }

  @action.bound
  async openWorkersMapFiltersModal() {
    this.activeModal = 'workersFilters';

    await Promise.all([
      this.shiftSelectorUI.setup(),
      this.groupSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.fetchCompanyCertifications()
    ]);

    callTrack(LABOR_MAP_DISPLAY_FILTERS);

    this.initFiltersForm();
  }

  @action.bound
  initFiltersForm() {
    this.activeForm = new WorkerMapFiltersForm(
      {
        fields: workerMapFiltersFormFields,
        rules: workerMapFiltersFormRules,
        values: {
          classificationFilters: toJS(this.classificationFilters),
          roleFilters: toJS(this.roleFilters),
          certificationFilters: toJS(this.certificationFilters),
          shiftFilters: toJS(this.shiftFilters),
          groupFilters: toJS(this.groupFilters)
        }
      },
      {
        options: workerMapFiltersFormOptions,
        plugins: workerMapFiltersFormPlugins
      }
    );
  }

  @action.bound
  async hideWorkersMapFiltersModal() {
    await this.hideActiveModal();
    this.shiftSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
  }

  @computed get roleOptions() {
    return getAllRoles();
  }

  @action.bound
  updateClassificationFilters(selectedClassifications) {
    this.activeForm.update({
      classificationFilters: selectedClassifications
    });
  }

  @action.bound
  updateRolesFilters(selectedRoles) {
    this.activeForm.update({
      roleFilters: selectedRoles
    });
  }

  @action.bound
  updateCertificationFilters(selectedCertifications) {
    this.activeForm.update({
      certificationFilters: selectedCertifications
    });
  }

  @action.bound
  updateShiftFilters(selectedShifts) {
    this.activeForm.update({
      shiftFilters: selectedShifts
    });
  }

  @action.bound
  updateGroupFilters(selectedGroups) {
    this.activeForm.update({
      groupFilters: selectedGroups
    });
  }

  @action.bound fetchCompanyCertifications() {
    return this.certifications.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @computed get certificationOptions() {
    return this.certifications.models.map(certification => {
      return {
        uuid: certification.uuid,
        name: certification.name
      };
    });
  }

  @action.bound applyFilters(e) {
    e.stopPropagation();
    e.preventDefault();

    this.clearPins(true);
    this.sideNavLocation = null;
    this.sideNavOpen = false;

    const formValues = this.activeForm.values();

    this.classificationFilters.replace(formValues.classificationFilters);
    this.roleFilters.replace(formValues.roleFilters);
    this.certificationFilters.replace(formValues.certificationFilters);
    this.shiftFilters.replace(formValues.shiftFilters);
    this.groupFilters.replace(formValues.groupFilters);

    this.hideWorkersMapFiltersModal();

    callTrack(LABOR_MAP_FILTER);
  }

  @action.bound
  async clearFilters() {
    await this.hideWorkersMapFiltersModal();
    this.clearPins(true);

    this.activeForm = null;
    this.sideNavLocation = null;
    this.sideNavOpen = false;

    this.classificationFilters.clear();
    this.roleFilters.clear();
    this.certificationFilters.clear();
    this.shiftFilters.clear();
    this.groupFilters.clear();
  }

  @action.bound
  exportWorkers() {
    const uuids = this.workersMapLocations.workerUuids;

    download({
      store: this.rootStore,
      url: `${this.rootStore.apiURL}/ra/companies/${this.rootStore.me.company.uuid}/members/csv`,
      xhttpOptions: {
        sendXApiKey: true
      },
      data: {
        uuids,
        type: 'LABOR'
      }
    });

    this.closeExportModal();
  }

  @action.bound
  openExportModal() {
    callTrack(LABOR_MAP_EXPORT);
    this.activeModal = 'WorkersMapExportDialog';
  }

  @action.bound
  closeExportModal() {
    this.hideActiveModal();
  }

  @computed
  get filtersCounter() {
    let counter = 0;
    if (this.classificationFilters?.length > 0) {
      counter++;
    }
    if (this.roleFilters?.length > 0) {
      counter++;
    }
    if (this.certificationFilters?.length > 0) {
      counter++;
    }
    if (this.shiftFilters?.length > 0) {
      counter++;
    }
    if (this.groupFilters?.length > 0) {
      counter++;
    }

    return counter;
  }

  viewProfile() {
    callTrack(LABOR_MAP_VIEW_PROFILE);
  }
}

import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';

import history from 'utils/history';

import ProjectTemplateInfoAddUI from './ProjectTemplateInfoAddUI';

import { t } from 'utils/translate';

export default class ProjectTemplateAddUI extends UIStore {
  @observable saving;
  @observable loading;
  constructor(options) {
    super(options);

    this.projectTemplateInfoUI = new ProjectTemplateInfoAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.saving = false;
  }

  @computed get projectTemplates() {
    return this.parent.projectTemplates;
  }

  @action.bound async setup() {
    this.saving = false;
  }

  @action.bound tearDown() {
    this.saving = false;
  }

  @action.bound
  goBack() {
    this.parent.fetchTemplates();

    history.push(`/company/project-templates`);
  }

  @computed get sideNavLinks() {
    return [
      {
        path: `/company/project-templates/add`,
        label: t('Info')
      }
    ];
  }
}

import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import { t } from 'utils/translate';
import moment from 'moment';

export default class WorkerPhotoIdDetail extends Model {
  get restAttributes() {
    return [
      'uuid',
      'projectUuid',
      'timeCardUuid',
      'timeSheetUuid',
      'date',
      'eventType',
      'fileName',
      'contentUrl',
      'thumbUrl',
      'photoIdStatus',
      'modifiedByUuid',
      'modifiedBy',
      'source'
    ];
  }

  @computed get dateFormatted() {
    return moment(this.date).format(`YYYY-MM-DD [${t('at')}] HH:mm A`);
  }

  @computed
  get photoIdStatusText() {
    switch (this.photoIdStatus) {
      case 'FAILED':
        return t('Failed');
      case 'VERIFIED':
        return t('Verified');
      case 'IN_PROGRESS':
        return t('In Progress');
      case 'APPROVED':
        return t('Approved');
      default:
        return '-';
    }
  }

  @computed
  get photoIdStatusColor() {
    switch (this.photoIdStatus) {
      case 'FAILED':
        return 'orange';
      case 'VERIFIED':
        return 'green';
      default:
        return 'orange';
    }
  }

  @computed
  get photoIdStatusAndModifiedText() {
    if (this.modifiedBy) {
      return t('{status} by {modifiedBy}', {
        templateStrings: {
          status: this.photoIdStatusText,
          modifiedBy: this.modifiedBy
        }
      });
    }

    return this.photoIdStatusText;
  }

  @computed
  get sourceText() {
    switch (this.source) {
      case 'KIOSK':
        return t('Kiosk');
      case 'TIME_CLOCK':
        return t('Time clock');
      default:
        return '-';
    }
  }

  @computed get timeCardUrl() {
    return `/${this.timeSheetUuid}/timecard/${this.timeCardUuid}`;
  }
}

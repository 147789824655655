import { createGlobalStyle } from 'styled-components';
import media from './utils/mediaTemplate';

export default createGlobalStyle`
  html {
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: #F5F5F7;
    color: #4a4a4a;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    transition: filter 0.35s ease-in-out;
    &.body--white {
      background-color: #fff;
    }
    ${media.smallDesktop`
       min-width: 992px;
       overflow: auto !important;
       padding: 0 !important;
    `};

    ${media.desktop`
      &.project--profile {
        padding: 0;
      }
      &.pdf-preview {
        padding: 32px 0 0 0;
      }
    `};
  }
  .fade {
    opacity: 0;
    transition: opacity 0.35s ease-in-out;
  }
  .in {
    opacity: 1;
  }
  .highlight {
    background-color: #f8e719;
  }
  // Hide X from inputs in IE11
  ::-ms-clear {
    display: none;
    width:0;
    height:0;
  }
  // Ensure GMaps overlay shows over marker clusters
  .gm-style-pbc {
    pointer-events: none;
    z-index: 5 !important;
  }

  /* override the intercom launcher color */
  .intercom-launcher,
  .intercom-launcher-active,
  .intercom-launcher-frame  {
    background: #2C2C2F !important;
  }

  .scrollContainer {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .k-pdf-export table, .k-pdf-export th, .k-pdf-export td {
    font-size: 13px !important;
  }

  .react-tel-input input[type=tel] {
    background: transparent;
  }

  .MuiAutocomplete-popper, .MuiTooltip-popper {
    z-index: 99999;
  }

  .link_integration-requirement {
    color: #29B7F9  
  }

  .sendbird-theme--light .sendbird-button--primary {
    background-color: #FF8A1D;
    border: none !important;

    &:hover {
      background-color: #FFCA99;
    }

    &:active {
      background-color: #D66700;
    }

    &:focus {
      box-shadow: none;
    }

    .sendbird-button__text {
       color: #2E1600;
    }
  }

  .sendbird_customized-app-wrapper {
    height: calc(100vh - 150px);
  }

  .sendbird_customized-app {
    height: 100%;
  }

  .sendbird-channel-list__header {
    display: none;
  }

  #sendbird-modal-root,
  #sendbird-dropdown-portal,
  #sendbird-emoji-list-portal {
    position: relative;
    z-index: 99999;
  }

  .sendbird-channel-header {
    padding: 8px !important;
  }

  .sendbird-create-channel--scroll {
    overflow-x: hidden;
  }

  .sendbird-app__wrap .sendbird-app__searchpanel-wrap {
    overflow: hidden !important;
  }

  .sendbird-message-search {
    overflow: auto !important;
  }

  .sendbird-message-search-pannel {
    width: auto !important;
  }

  .recurly-element,.recurly-hosted-field{
    background-color:#fff;
    border:1px solid #E5E5EA;
    margin-top: 0;
    height: 40px;
    border-radius: 6px;
    box-sizing:border-box;
    padding: '18.5px 14px';
  }

  .recurly-element-focus,.recurly-hosted-field-focus{
      border:2px solid #E5E5EA;
  }

  .recurly-element-invalid,.recurly-hosted-field-invalid{
      border:1px solid #ff2952;
  }
`;

import { action } from 'mobx';

import Countries from './collections/Countries';
import Regions from './collections/Regions';
import Notifications from './collections/Notifications';

import SuperAdminMe from './models/superAdmin/SuperAdminMe';
import SuperAdminCompanies from './collections/superAdmin/SuperAdminCompanies';
import SuperAdminIntegrations from './collections/superAdmin/SuperAdminIntegrations';
import SuperAdminUsers from './collections/superAdmin/SuperAdminUsers';
import SuperAdminProjects from './collections/superAdmin/SuperAdminProjects';
import SuperAdminSuperUsers from './collections/superAdmin/SuperAdminSuperUsers';
import SuperAdminSalesReps from './collections/superAdmin/SuperAdminSalesReps';
import SuperAdminDemoMasterAccounts from './collections/superAdmin/SuperAdminDemoMasterAccounts';
import SuperAdminDemoAccounts from './collections/superAdmin/SuperAdminDemoAccounts';

import NotificationsUI from './ui/NotificationsUI';
import AuthorizationUI from './ui/AuthorizationUI';
import SuperAdminUI from './ui/superAdmin/SuperAdminUI';
import TimesheetsUI from './ui/TimesheetsUI';
import ProjectSelectorUI from './ui/ProjectSelectorUI';
import PayPeriodSelectorUI from './ui/PayPeriodSelectorUI';
import AppConnectUI from './ui/AppConnectUI';

import RootStore from 'stores/RootStore';

class SuperAdminRootStore extends RootStore {
  constructor(options) {
    super(options);
    document.title = `Raken Admin`;
  }

  @action.bound
  instantiateCollections(fixtureData) {
    this.countries = new Countries(null, {
      rootStore: this
    });

    this.regions = new Regions(null, {
      rootStore: this
    });

    // Instantiate Collections for the Super Admin section.
    this.companies = new SuperAdminCompanies(
      fixtureData ? fixtureData.companiesData : null,
      {
        rootStore: this
      }
    );

    this.me = new SuperAdminMe(fixtureData ? fixtureData.meData : null, {
      rootStore: this
    });

    this.users = new SuperAdminUsers(
      fixtureData ? fixtureData.usersData : null,
      {
        rootStore: this
      }
    );

    this.projects = new SuperAdminProjects(
      fixtureData ? fixtureData.projectsData : null,
      {
        rootStore: this
      }
    );

    this.notifications = new Notifications(null, { rootStore: this });

    this.superUsers = new SuperAdminSuperUsers(null, { rootStore: this });

    this.superAdminDemoMasterAccounts = new SuperAdminDemoMasterAccounts(null, {
      rootStore: this
    });

    this.superAdminDemoAccounts = new SuperAdminDemoAccounts(null, {
      rootStore: this
    });

    this.superAdminSalesReps = new SuperAdminSalesReps(null, {
      parent: this,
      rootStore: this
    });

    this.integrations = new SuperAdminIntegrations(null, {
      parent: this,
      rootStore: this
    });
  }

  @action.bound
  instantiateUIStores() {
    this.superAdminUI = new SuperAdminUI({ rootStore: this });
    this.authorizationUI = new AuthorizationUI({ rootStore: this });
    this.notificationsUI = new NotificationsUI({ rootStore: this });
    this.projectSelectorUI = new ProjectSelectorUI({ rootStore: this });
    this.payPeriodSelectorUI = new PayPeriodSelectorUI({ rootStore: this });
    this.appConnectUI = new AppConnectUI({ rootStore: this });
    this.timesheetsUI = new TimesheetsUI({ rootStore: this });
  }

  @action.bound
  async fetchGlobalData() {
    this.loadingGlobalData = true;

    try {
      this.countries.fetch({
        params: {
          limit: 10000
        }
      });

      this.regions.fetch({
        params: {
          limit: 10000,
          country: 'US'
        }
      });

      await this.me.fetch({
        url: `ra/user`,
        params: {
          mn: 'full',
          mc: 'full'
        }
      });

      await this.superAdminUI.checkExtendedPermissions();

      this.configureRecurly();
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingGlobalData = false;
    }
  }
}

export default SuperAdminRootStore;

import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import request from 'axios';

import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import PolicyChildEditUI from './PolicyChildEditUI';
import Classifications from 'stores/collections/Classifications';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

import {
  ClassificationForm,
  classificationFormOptions,
  classificationFormFields,
  classificationFormRules,
  classificationFormPlugins
} from 'forms/classification';

import Classification from 'stores/models/Classification';

export default class TimeClassificationsUI extends PolicyChildEditUI {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable sortField;
  @observable sortDirection;
  @observable deleting;
  @observable saving;
  @observable loading;
  @observable classificationForAdd;
  @observable form;

  constructor(options) {
    super(options);

    this.loading = true;
    this.saving = false;

    this.classifications = new Classifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.selectedClassifications = observable([]);

    this.classificationForAdd = null;
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.sortField = 'name';
    this.sortDirection = 'asc';

    this.fetchClassificationsDebounced = debounce(
      this.fetchClassifications,
      BASE_DEBOUNCE
    );
  }

  @computed get hasClassifications() {
    return this.classifications.hasModels;
  }

  @action.bound async setup() {
    this.setupReactions();
    await this.fetchClassifications();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchClassificationsDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound
  async fetchClassifications() {
    this.loading = true;

    try {
      await this.classifications.fetch({
        params: pickBy(this.params, identity)
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed get params() {
    const params = {
      limit: this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery
    };

    return params;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.classifications.totalElements / this.pageSize);
  }

  @computed get hasSelectedClassifications() {
    return this.selectedClassifications.length > 0;
  }

  @action.bound
  async toggleSelectClassification(classificationUuid) {
    if (
      this.selectedClassifications.find(uuid => uuid === classificationUuid)
    ) {
      this.selectedClassifications.remove(classificationUuid);
    } else {
      this.selectedClassifications.push(classificationUuid);
    }
  }

  @computed
  get allClassificationsSelected() {
    return (
      this.hasClassifications &&
      this.classifications.models.every(classification =>
        this.selectedClassifications.includes(classification.uuid)
      )
    );
  }

  @action.bound
  async toggleSelectAllClassifications() {
    if (this.allClassificationsSelected) {
      this.selectedClassifications.replace(
        this.selectedClassifications.filter(uuid => {
          return !this.classifications.models
            .map(classification => classification.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.classifications.models.forEach(classification => {
        if (!this.selectedClassifications.includes(classification.uuid)) {
          this.selectedClassifications.push(classification.uuid);
        }
      });
    }
  }

  @action.bound clearAllSelectedClassifications() {
    this.selectedClassifications.clear();
  }

  @action.bound
  addClassification() {
    this.classificationForAdd = new Classification(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.initPolicyClassificationForm();
  }

  @action.bound cancelClassificationAdd() {
    this.form = null;
    this.classificationForAdd = null;
  }

  @action.bound
  initPolicyClassificationForm() {
    const { name } = this.classificationForAdd;

    this.form = new ClassificationForm(
      {
        fields: classificationFormFields,
        rules: classificationFormRules,
        values: {
          name
        }
      },
      {
        options: classificationFormOptions,
        plugins: classificationFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound submitClassificationForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitClassificationFormSuccess,
      onError: this.submitClassificationFormError
    });
  }

  @action.bound submitClassificationFormError() {
    console.log(this.form.errors());
  }

  @action.bound async submitClassificationFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    const payload = this.form.values();

    try {
      await this.classificationForAdd.save(payload, {
        wait: true
      });

      this.fetchClassifications();
      this.cancelClassificationAdd();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Classification saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.loading = true;
    this.classificationForAdd = null;
    this.searchQuery = '';
    this.selectedClassifications.clear();
    this.classifications.clear();
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasClassifications;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasClassifications;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async deleteClassification(classification) {
    this.classificationForDelete = classification;

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteClassification() {
    await this.hideActiveModal();

    this.classificationForDelete = null;
  }

  @action.bound async confirmDeleteClassification() {
    this.saving = true;

    try {
      await this.classificationForDelete.destroy({ wait: true });

      this.fetchClassifications();
      this.hideActiveModal();

      this.classificationForDelete = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Classification deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get bulkClassificationsActions() {
    let actions = [];

    if (this.hasSelectedClassifications) {
      actions = actions.concat([
        {
          title: t('Delete'),
          onClick: () => {
            this.bulkDeleteClassifications();
          }
        }
      ]);
    }

    return actions;
  }

  @action.bound bulkDeleteClassifications() {
    this.showModal('DeleteModal');
  }

  @action.bound async confirmBulkDeleteClassifications() {
    const classificationsToDelete = this.classifications.models
      .filter(classification =>
        this.selectedClassifications.includes(classification.uuid)
      )
      .map(classification => classification.uuid);

    try {
      await request.delete(
        `ra/companies/${this.rootStore.me.company.uuid}/classifications/bulk`,
        { data: classificationsToDelete }
      );

      this.fetchClassifications();
      this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Classifications deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }
}

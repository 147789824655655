import request from 'axios';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { action, observable, computed, reaction, when } from 'mobx';

import ProjectChildUI from './ProjectChildUI';
import CoverPhotos from 'stores/collections/Attachments';

import history from 'utils/history';
import { t } from 'utils/translate';
import crossOriginDownload from 'utils/crossOriginDownload';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';

import {
  REPORT_DAILY_DOWNLOADED,
  REPORT_SUPER_DAILY_DOWNLOADED,
  COVER_PHOTO_SELECT,
  COVER_PHOTO_SAVE
} from 'utils/segmentAnalytics/eventSpec';

export default class PreviewUI extends ProjectChildUI {
  @observable webViewerInstance;
  @observable previewType;
  @observable emptyState;

  constructor(options) {
    super(options);

    this.webViewerInstance = null;

    this.emptyState = false;
    this.reportUrls = observable([]);

    this.coverPhotos = new CoverPhotos(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(previewType) {
    this.previewType = previewType;

    when(
      () => !this.parent.fetchingReportSummary && this.webViewerInstance,
      () => {
        this.fetchOrEmptyState();
        this.setupReactions();
      }
    );
  }

  @computed get report() {
    return this.projectUI.reportSummary;
  }

  @computed get reportParams() {
    return {
      uuid: this.report.uuid,
      status: this.report.status
    };
  }

  @action.bound async fetchOrEmptyState() {
    if (this.previewType === 'super' && !this.project.hasSuperDaily) {
      // TODO: Super Daily Message
      this.setEmptyState();
    }

    if (this.report.uuid) {
      const { UI, Core } = this.webViewerInstance;
      const { documentViewer } = Core;

      this.clearEmptyState();

      UI.openElements(['loadingModal']);

      await this.fetchPdfUrls();

      await documentViewer.loadDocument(this.file);

      UI.closeElements(['loadingModal']);
    } else {
      this.setEmptyState();
    }
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToReportStatus = reaction(
      () => this.reportParams,
      async status => {
        this.fetchOrEmptyState();
      }
    );
  }

  @action.bound async setEmptyState() {
    const { Core } = this.webViewerInstance;
    const { documentViewer } = Core;

    this.reportUrls.clear();
    documentViewer.closeDocument();
    this.emptyState = true;
  }

  @action.bound clearEmptyState() {
    this.emptyState = false;
  }

  @action.bound tearDownReactions() {
    this.reactToReportStatus && this.reactToReportStatus();
  }

  @computed get file() {
    if (!this.reportUrls.length) return null;

    if (this.segmentUuid) {
      return this.reportUrls.find(item => item.segmentUuid === this.segmentUuid)
        ?.href;
    }

    return this.reportUrls[0].href;
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.webViewerInstance = null;
    this.reportUrls.clear();
    this.previewType = null;
    this.emptyState = false;
  }

  @action.bound async showPreviewModal(previewType = 'daily') {
    history.push({
      pathname: `${this.project.viewUrl}/preview/${previewType}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async cancelPreviewModal() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.baseQueryParams
    });
  }

  @computed get breadcrumbText() {
    if (this.previewType === 'super') {
      return t('Super daily report for {date} {segment}', {
        templateStrings: {
          date: this.report.reportDate,
          segment: this.report.segmentName ? `| ${this.report.segmentName}` : ''
        }
      });
    }

    return t('Daily report for {date} {segment}', {
      templateStrings: {
        date: this.report.reportDate,
        segment: this.report.segmentName ? `| ${this.report.segmentName}` : ''
      }
    });
  }

  getDownloadName = segmentName => {
    if (segmentName) {
      return `${this.project.name}_${this.date}_${segmentName}.pdf`;
    }

    return `${this.project.name}_${this.date}.pdf`;
  };

  @action.bound async download(file, segmentName) {
    try {
      await crossOriginDownload(file, this.getDownloadName(segmentName), () => {
        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Report downloaded')
        });

        if (this.previewType === 'super') {
          callTrack(REPORT_SUPER_DAILY_DOWNLOADED, {
            project_name: this.project.name,
            report_date: this.report.reportDate
          });
        } else {
          callTrack(REPORT_DAILY_DOWNLOADED, {
            project_name: this.project.name,
            report_date: this.report.reportDate
          });
        }
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get pdfUrl() {
    if (this.previewType === 'super') {
      return `/ra/projects/${this.project.id}/reports/${this.date}/super-pdfs`;
    } else {
      return `/ra/projects/${this.project.id}/reports/${this.date}/daily-pdfs`;
    }
  }

  @action.bound
  async fetchPdfUrls(previewType) {
    this.reportUrls.clear();

    try {
      const response = await request.get(this.pdfUrl);

      this.reportUrls.replace(response.data.collection);
    } catch (error) {
      console.error(error);
    }
  }

  @action.bound setWebViewerInstance(instance) {
    this.webViewerInstance = instance;
  }

  @action.bound clearWebViewerInstance() {
    this.webViewerInstance = null;
  }

  @computed get segmentDownloadActions() {
    return this.reportUrls.map(segment => {
      return {
        title: segment.name,
        onClick: () => {
          this.download(segment.href, segment.name);
        }
      };
    });
  }

  @computed get selectedCoverPhotos() {
    return this.coverPhotos.models.filter(model => Boolean(model.coverOrder));
  }

  @computed get hasSelectedCoverPhotos() {
    return this.selectedCoverPhotos.length > 0;
  }

  @action.bound async selectCoverPhotos() {
    await this.authorization.checkFeatureAccess('SelectCoverPhotos');

    callTrack(COVER_PHOTO_SELECT);

    this.showModal('CoverPhotosModal');

    this.coverPhotos.clear();

    this.coverPhotos.fetch({
      url: `${this.project.url()}/reports/${this.date}/cover-media`,
      params: pickBy(
        {
          limit: 10000,
          segmentUuid: this.segmentUuid
        },
        identity
      )
    });
  }

  @action.bound async cancelSelectCoverPhotos() {
    await this.hideActiveModal();
  }

  @action.bound
  toggleSelectCoverPhoto(coverPhoto) {
    const currentCoverOrder = coverPhoto.coverOrder;

    if (Boolean(currentCoverOrder)) {
      coverPhoto.coverOrder = null;

      this.selectedCoverPhotos.forEach(selectedCoverPhoto => {
        if (selectedCoverPhoto.coverOrder > currentCoverOrder) {
          selectedCoverPhoto.coverOrder -= 1;
        }
      });
    } else {
      if (this.selectedCoverPhotos.length < 4) {
        coverPhoto.coverOrder = this.selectedCoverPhotos.length + 1;
      }
    }
  }

  @action.bound clearSelectedCoverPhotos() {
    this.selectedCoverPhotos.forEach(coverPhoto => {
      coverPhoto.coverOrder = null;
    });
  }

  @action.bound async submitSelectedCoverPhotos(event) {
    event.preventDefault();

    this.saving = true;

    try {
      await request.post(
        `${this.project.url()}/reports/${this.date}/cover-media`,
        pickBy(
          {
            segmentUuid: this.segmentUuid,
            mediaOrders: this.selectedCoverPhotos.map(coverPhoto => {
              return {
                uuid: coverPhoto.uuid,
                coverOrder: coverPhoto.coverOrder
              };
            })
          },
          identity
        )
      );

      this.fetchOrEmptyState();

      await this.hideActiveModal();

      callTrack(COVER_PHOTO_SAVE);

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Cover photos updated')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @computed get coverPhotoText() {
    if (!this.hasCoverPhotos) {
      return t('No cover photos available for {date}.', {
        templateStrings: {
          date: this.date
        }
      });
    }

    if (!this.hasSelectedCoverPhotos) {
      return t('No cover photos selected for display.');
    }

    return t('{number} of 4 selected.', {
      templateStrings: {
        number: this.selectedCoverPhotos.length
      }
    });
  }

  @computed get loadingCoverPhotos() {
    return this.coverPhotos.fetching;
  }

  @computed get hasCoverPhotos() {
    return this.coverPhotos.hasModels;
  }

  @computed get showCoverPhotosEmptyState() {
    return !this.loadingCoverPhotos && !this.hasCoverPhotos;
  }
}

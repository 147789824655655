import { observable, action, computed } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';

import CompanyEquipment from 'stores/models/equipment/CompanyEquipment';

import { t } from 'utils/translate';

import EquipmentInfoUI from './EquipmentInfoUI';
import EquipmentDeploymentHistoryUI from './EquipmentDeploymentHistoryUI';
import EquipmentDeficienciesUI from './EquipmentDeficienciesUI';
import EquipmentNotificationsUI from './EquipmentNotificationsUI';
import EquipmentMaintenanceUI from './EquipmentMaintenanceUI';
import EquipmentPerformanceUI from './EquipmentPerformanceUI';

export default class EquipmentEditUI extends UIStore {
  @observable entryForEdit;
  @observable entryEditForm;
  @observable savingEquipmentCondition;

  constructor(options) {
    super(options);

    this.entryForEdit = null;
    this.entryEditForm = null;
    this.nextUrl = null;

    this.savingEquipmentCondition = false;

    this.equipmentInfoUI = new EquipmentInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentDeploymentHistoryUI = new EquipmentDeploymentHistoryUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentDeficienciesUI = new EquipmentDeficienciesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentNotificationsUI = new EquipmentNotificationsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentMaintenanceUI = new EquipmentMaintenanceUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.equipmentPerformanceUI = new EquipmentPerformanceUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound setup(uuid) {
    this.fetchEntry(uuid);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.equipment.get(uuid);

      if (!model) {
        model = new CompanyEquipment(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );
      }

      await model.fetch();

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelGroupEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action clearUIState() {
    this.entryForEdit = null;
  }

  @action.bound cancelEquipmentEdit() {
    history.push({
      pathname: `/company/equipment`
    });
  }

  @computed get showUI() {
    return this.entryForEdit && !this.entryForEdit.fetching;
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get ownershipOptions() {
    return this.parent.ownershipOptions;
  }

  @computed get yearOptions() {
    return this.parent.yearOptions;
  }

  @computed get conditionOptions() {
    return this.parent.conditionOptions;
  }

  @computed get equipmentTypeOptions() {
    return this.parent.equipmentTypeOptions;
  }

  @computed get equipmentMakeOptions() {
    return this.parent.equipmentMakeOptions;
  }

  @computed get odometerOptions() {
    return this.parent.odometerOptions;
  }

  @computed get fuelOptions() {
    return this.parent.fuelOptions;
  }

  @computed get frequencyOptions() {
    return this.parent.frequencyOptions;
  }

  @computed get refetchEquipment() {
    return this.parent.refetchEquipment;
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/maintenance`,
          state: { previousPath: this.previousPath }
        },
        label: t('Maintenance')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/performance`,
          state: { previousPath: this.previousPath }
        },
        label: t('Performance')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/history`,
          state: { previousPath: this.previousPath }
        },
        label: t('Log history')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/deficiencies`,
          state: { previousPath: this.previousPath }
        },
        label: t('Deficiencies')
      },
      {
        path: {
          pathname: `${this.entryForEdit?.viewUrl}/notifications`,
          state: { previousPath: this.previousPath }
        },
        label: t('Notifications')
      }
    ];
  }
}

import request from 'axios';

import { action, computed } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { WORKLOG_ROLLOVER } from 'utils/segmentAnalytics/eventSpec';

export default class WorkLogsRollOverUI extends ProjectChildEditUI {
  @computed get timeCardsMode() {
    return this.parent.timeCardsMode;
  }

  @computed get canRollOver() {
    return this.parent.workLogs.summary.get('canRollover');
  }

  @action.bound async showRollOverModal() {
    await this.authorization.checkFeatureAccess('RolloverWorklogs');

    this.showModal('WorkLogsRollOverModal');
  }

  @action.bound
  async confirmRollOver() {
    this.saving = true;

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.company.uuid}/worklogs/rollover`;

    try {
      const response = await request.post(url, this.reportParams);

      this.hideActiveModal();

      this.parent.refetchWorkLogs();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Work logs rolled over')
      });

      callTrack(WORKLOG_ROLLOVER, {
        rollover_count: response.data?.collection?.length
      });
    } catch (error) {
      if (error.response?.status === 400 && this.segmentUuid) {
        this.setValidationDetails([
          {
            field: 'message',
            fieldMessage: t(
              'Unable to rollover entries as the current segment does not exist on the previous day.'
            )
          }
        ]);
      } else {
        alertErrorHandler(error, this.setValidationDetails);
      }
    } finally {
      this.saving = false;
    }
  }
}

import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildUI from './ProjectChildUI';

import WorkLogs from 'stores/collections/workLogs/WorkLogs';
import WorkLogAddUI from './WorkLogAddUI';
import WorkLogEditUI from './WorkLogEditUI';
import WorkLogsRollOverUI from './WorkLogsRollOverUI';
import WorkLogsCopyUI from './WorkLogsCopyUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class WorkLogsUI extends ProjectChildUI {
  @observable workLogTypes;
  @observable defaultWorkLogType;

  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedWorkLog;

  @observable expandRows;

  constructor(options) {
    super(options);

    this.workLogTypes = 'STANDARD,CREW';

    this.loading = true;

    this.selectedWorkLog = null;

    this.sortField = 'createdTimestamp';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    this.expandRows = false;

    // Work logs collection
    this.workLogs = new WorkLogs(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogAddUI = new WorkLogAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogEditUI = new WorkLogEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogsRollOverUI = new WorkLogsRollOverUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.workLogsCopyUI = new WorkLogsCopyUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchWorkLogsDebounced = debounce(this.fetchWorkLogs, BASE_DEBOUNCE);
  }

  // This is so we can change routes and wording
  // to "Time cards" from  the time cards tab
  @computed get timesheetsMode() {
    return this.projectUI.mode === 'timesheets';
  }

  // This is so we can change routes and wording
  // to "Time cards" from  the time cards tab
  @computed get timeCardsMode() {
    return this.workLogTypes === 'CREW';
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchWorkLogs();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchWorkLogsDebounced();
        });
      }
    );

    this.reactToExpandRows = reaction(
      () => this.expandRows,
      expandRows => {
        this.expandOrCollapseRows();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToExpandRows && this.reactToExpandRows();
  }

  @computed
  get params() {
    return {
      fromDate: this.date,
      toDate: this.date,
      projectUuids: this.projectUuid,
      segmentUuids: this.segmentUuid,
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      workLogTypes: this.workLogTypes
    };
  }

  @computed get hasRequiredParams() {
    return (
      this.params.projectUuids && this.params.fromDate && this.params.toDate
    );
  }

  @action.bound async fetchWorkLogs() {
    if (!this.hasRequiredParams) return;

    this.workLogs.cancelRequest();
    this.workLogs.clear();

    this.expandRows = false;

    try {
      await this.workLogs.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasWorkLogs() {
    return this.workLogs.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.workLogs.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.workLogs.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.selectedWorkLog = null;
    this.expandRows = false;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasWorkLogs;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasWorkLogs;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addWorkLog() {
    if (this.timeCardsMode) {
      history.push({
        pathname: `${this.project.viewUrl}/timecards/add`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `${this.project.viewUrl}/worklogs/add`,
        search: this.baseQueryParams
      });
    }
  }

  @action.bound async editWorkLog(workLog) {
    if (this.timeCardsMode) {
      history.push({
        pathname: `${this.project.viewUrl}/timecards/${workLog.uuid}`,
        search: this.baseQueryParams
      });
    } else {
      history.push({
        pathname: `${this.project.viewUrl}/worklogs/${workLog.uuid}`,
        search: this.baseQueryParams
      });
    }
  }

  @computed get deleteWorkLogTitle() {
    if (!this.selectedWorkLog) return null;

    if (this.selectedWorkLog.workLogType === 'CREW') {
      return t('Delete time card?');
    }

    return t('Delete work log?');
  }

  @computed get deleteWorkLogText() {
    if (!this.selectedWorkLog) return null;

    if (this.selectedWorkLog.workLogType === 'CREW') {
      return t('Are you sure you want to delete this time card?');
    }

    return t('Are you sure you want to delete this work log?');
  }

  @action.bound async deleteWorkLog(workLog) {
    await this.authorization.checkFeatureAccess('DeleteWorkLog');

    this.selectedWorkLog = workLog;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteWorkLog() {
    await this.hideActiveModal();

    this.selectedWorkLog = null;
  }

  @action.bound async confirmDeleteWorkLog() {
    this.saving = true;

    try {
      await this.selectedWorkLog.destroy({ wait: true });

      this.refetchWorkLogs();

      await this.hideActiveModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title:
          this.selectedWorkLog.workLogType === 'CREW'
            ? t('Time card deleted')
            : t('Work log deleted')
      });

      this.selectedWorkLog = null;
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchWorkLogsDebounced();
    this.refetchReportSummary();
  }

  @action.bound refetchWorkLogs() {
    this.loading = true;
    if (!this.hasWorkLogs) {
      this.setPage(null, 1);
      this.fetchWorkLogs();
    } else {
      this.fetchWorkLogs();
    }

    this.refetchReportSummary();
  }

  getWorkLogActions = workLog => {
    return [
      {
        title: this.hasWriteAccess ? t('Edit') : t('View'),
        onClick: () => {
          this.editWorkLog(workLog);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteWorkLog(workLog);
        }
      }
    ];
  };

  @action.bound toggleExpandRows() {
    this.expandRows = !this.expandRows;
  }

  @action.bound expandOrCollapseRows() {
    if (!this.workLogs.hasModels) return;

    this.workLogs.models.forEach(workLog => {
      workLog.expandDescription = this.expandRows;
    });
  }

  @computed get defaultCreateWorkLogAction() {
    return this.workLogCreationActions.find(
      action => action.id === this.settings.defaultWorklogType
    );
  }

  @action.bound setDefaultWorklogType(type) {
    this.settings.save({
      defaultWorklogType: type
    });
  }
  @action.bound async createStandardWorkLog() {
    await this.authorization.checkFeatureAccess('CreateWorkLog');

    this.setDefaultWorklogType('standard');
    this.addWorkLog('STANDARD');
  }

  @action.bound async createCrewWorkLog() {
    await this.authorization.checkFeatureAccess('CreateCrewWorkLog');

    this.setDefaultWorklogType('timeCards');
    this.addWorkLog('CREW');
  }

  @computed get workLogCreationActions() {
    return [
      {
        id: 'standard',
        title: t('Work log'),
        action: this.createStandardWorkLog
      },
      {
        id: 'timeCards',
        title: t('Time card'),
        action: this.createCrewWorkLog
      }
    ];
  }
}

import request from 'axios';
import { action, computed } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import { t } from 'utils/translate';
import IntegrationIds from 'fixtures/integrationIds';

export default class IntegrationComputerEaseDirectAPICategoryImportUI extends UIStore {
  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @action.bound async confirmImportComputerEaseDirectAPICategories(
    options = { all: true }
  ) {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    try {
      const { data } = await request.post(
        `${this.rootStore.urlMicroService('integrations')}/companies/${
          this.activeCompany.uuid
        }/integrations/1030/category/sync`,
        {
          externalIds: options.all ? ['*'] : this.selectedCategories.slice()
        }
      );

      if (options.all) {
        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Category import started')
        });
      } else {
        const successfulCount = data.collection.filter(
          syncResult => syncResult.successful === true
        ).length;

        const failedCount = data.collection.length - successfulCount;

        const title = formatIntegrationSyncMessage(
          'Category import',
          successfulCount,
          failedCount
        );

        const snackbar = successfulCount > 0 ? 'warning' : 'error';

        this.notifications.pushNotification({
          snackbar,
          icon: 'checkmark',
          title
        });
      }

      this.parent.showImportAlert = true;

      this.cancelImportComputerEaseDirectAPICategories();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelImportComputerEaseDirectAPICategories() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/categories`
      );
    } else {
      history.push(
        `/company/integrations/${IntegrationIds.COMPUTER_EASE_DIRECT_API}/categories`
      );
    }
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }
}

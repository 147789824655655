import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentWithdrawFiltersFormPlugins = extendedPlugins;

const smallEquipmentWithdrawFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentWithdrawFiltersFormFields = ['smallEquipment', 'project'];

const smallEquipmentWithdrawFiltersFormLabels = {
  smallEquipment: t('small equipment'),
  projects: t('projects')
};

const smallEquipmentWithdrawFiltersFormValues = {
  smallEquipment: [],
  projects: []
};

const smallEquipmentWithdrawFiltersFormRules = {
  smallEquipment: '',
  projects: ''
};

class SmallEquipmentWithdrawFiltersForm extends MobxReactForm {}

export {
  SmallEquipmentWithdrawFiltersForm,
  smallEquipmentWithdrawFiltersFormOptions,
  smallEquipmentWithdrawFiltersFormFields,
  smallEquipmentWithdrawFiltersFormRules,
  smallEquipmentWithdrawFiltersFormValues,
  smallEquipmentWithdrawFiltersFormLabels,
  smallEquipmentWithdrawFiltersFormPlugins
};

import moment from 'moment';
import { computed, action, toJS, observable } from 'mobx';
import { Model } from 'mobx-mc';
import kebabCase from 'lodash.kebabcase';
import { t } from 'utils/translate';
import omit from 'lodash.omit';
import Settings from './Settings';
import { getRoleName as getDirectoryRoleName } from 'utils/directoryRoles';
import { getRoleName as getStandardRoleName } from 'utils/roles';

export default class Worker extends Model {
  @observable new;
  @observable settings;

  constructor(data, options) {
    super(data, options);

    this.new = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'role',
      'firstName',
      'lastName',
      'classification',
      'classificationId', // TODO: Deprecate
      'classificationName', // TODO: Deprecate
      'employeeId',
      'phoneNumber',
      'email',
      'status',
      'userId',
      'workerUuid',
      'projects',
      'lunchStartTime',
      'breakStartTime',
      'isDefault',
      'addToActiveProjects',
      'createdTimestamp',
      'updatedTimestamp',
      'projectUuids',
      'avatar',
      'externalId',
      'groupUuids',
      'lastLogin',
      'inviteId',
      'company',
      'rakenUserStatus'
    ];
  }

  // for worker, workerUuid = uuid(member)
  // for team members, workerUuid != uuid(member)
  idAttribute() {
    return 'uuid';
  }

  get restAttributeDefaults() {
    return {
      employeeId: '',
      projects: [],
      projectUuids: [],
      groupUuids: []
    };
  }

  @action.bound
  parse(attributes) {
    if (attributes.id) {
      attributes.userId = attributes.id;
    }

    this.parseSettings(attributes);

    return {
      ...omit(attributes, ['settings'])
    };
  }

  @action.bound
  parseSettings(attributes) {
    if (this.settings) {
      this.settings.set(attributes.settings);
    } else {
      this.settings = new Settings(attributes.settings, {
        parent: this,
        rootStore: this.rootStore
      });
    }
  }

  get urlRoot() {
    if (!this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.rootStore.me.company?.uuid}/members`;
    }

    return '';
  }

  @computed get statusColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'green';
      case 'DELETED':
        return 'red';
      case 'INACTIVE':
        return 'grey';
      case 'INVITED':
        return 'orange';
      default:
        return '';
    }
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName === 'null' ? '' : this.lastName}`;
  }

  @computed
  get roleTranslated() {
    const useDirectoryRoles = this.rootStore.featureFlags.FF_COMPANY_CONTACTS;
    const getRoleNameFn = useDirectoryRoles
      ? getDirectoryRoleName
      : getStandardRoleName;
    return getRoleNameFn(this.role) || getRoleNameFn('ROLE_USER');
  }

  @computed
  get formValues() {
    return {
      id: this.id,
      workerUuid: this.workerUuid,
      uuid: this.uuid,
      firstName: this.firstName,
      lastName: this.lastName,
      classificationId: this.classificationId,
      classificationName: this.classificationName,
      classification: this.classification,
      employeeId: this.employeeId,
      phoneNumber: this.phoneNumber,
      email: this.email,
      lunchStartTime: this.lunchStartTime,
      breakStartTime: this.breakStartTime,
      isDefault: this.isDefault,
      projects: toJS(this.projects),
      avatar: this.avatar && this.avatar.contentUrl,
      groups: this.groupUuids.map(groupUuid => {
        return {
          uuid: groupUuid
        };
      })
    };
  }

  @computed get defaultAttributeFormValues() {
    return {
      defaultClassification: this.defaultClassification,
      defaultShift: this.defaultShift,
      defaultCostCode: this.defaultCostCode,
      defaultCrewName: this.defaultCrewName
    };
  }

  @computed get slug() {
    return kebabCase(this.fullName);
  }

  @action.bound removeClassification() {
    this.classificationId = null;
    this.classificationName = null;
    this.classification = null;
  }

  @computed
  get workerFullName() {
    return `${this.lastName}, ${this.firstName}`;
  }

  @computed get viewUrl() {
    return `/workers/${this.uuid}`;
  }

  @computed
  get createdAtFormatted() {
    return (
      this.createdTimestamp &&
      moment(this.createdTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed
  get updatedAtFormatted() {
    return (
      this.updatedTimestamp &&
      moment(this.updatedTimestamp).format('YYYY-MM-DD')
    );
  }

  @computed
  get lastLoginFormatted() {
    return moment(this.lastLogin).format(`h:mm A on YYYY-MM-DD`);
  }

  @computed get updatedOrCreatedAt() {
    return this.updatedAtFormatted || this.createdAtFormatted;
  }

  @action.bound getProjectByUuid(uuid) {
    return this.projectUuids.includes(uuid);
  }

  @action.bound setAsNew() {
    this.new = true;
  }

  @computed get statusFormatted() {
    switch (this.status) {
      case 'DELETED':
        return t('Deleted');
      case 'INACTIVE':
        return t('Inactive');
      case 'INVITED':
        return t('Invited');
      default:
        return t('Active');
    }
  }

  @computed
  get defaultClassification() {
    return this.classification ? this.classification : null;
  }

  @computed
  get defaultShift() {
    if (this.settings.defaultShift?.name) {
      return {
        name: this.settings.defaultShift.name,
        uuid: this.settings.defaultShift.uuid
      };
    }

    return null;
  }

  @computed
  get defaultCostCode() {
    if (this.settings.defaultCostCode?.code) {
      return {
        code: this.settings.defaultCostCode.code,
        division: this.settings.defaultCostCode.division,
        description: this.settings.defaultCostCode.description,
        uuid: this.settings.defaultCostCode.uuid
      };
    }

    return null;
  }

  @computed
  get defaultCrewName() {
    if (!this.settings.defaultCrewName?.groupName) return null;

    return {
      name: this.settings.defaultCrewName.groupName,
      uuid: this.settings.defaultCrewName.uuid
    };
  }

  @computed get canLogin() {
    return this.rakenUserStatus === 'CAN_LOGIN';
  }

  @computed get timeClockBlocked() {
    return this.settings.blockTimeClock;
  }

  @computed
  get invitesDisabled() {
    return this.rakenUserStatus === 'CAN_LOGIN' || !this.email;
  }

  @computed get lastLoginText() {
    // This text will apply to the Team Members table last login  column
    if (
      this.rootStore.authorizationUI.hasTimeClockAccess &&
      this.settings.blockTimeClock
    ) {
      return { bold: false, text: t('Blocked') };
    }
    if (this.lastLogin) {
      return { bold: false, text: this.lastLoginFormatted };
    }
    return { bold: true, text: t('Never logged in') };
  }
}

import UIStore from 'stores/ui/UIStore';
import { action, computed, observable } from 'mobx';
import request from 'axios';
import moment from 'moment-timezone';
import {
  ChecklistsBatchExportForm,
  checklistsBatchExportFormLabels,
  checklistsBatchExportFormRules,
  checklistsBatchExportFormFields,
  checklistsBatchExportFormOptions,
  checklistsBatchExportFormPlugins,
  checklistsBatchExportFormValues
} from 'forms/checklistsBatchExport';
import ChecklistTemplateSelectUI from './ChecklistTemplateSelectUI';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import { callTrack } from 'utils/segmentIntegration';
import {
  COMPLETED_CHECKLISTS_EXPORT_STARTED,
  COMPLETED_CHECKLISTS_EXPORT_COMPLETED,
  COMPLETED_CHECKLISTS_EXPORT_DOWNLOADED
} from 'utils/segmentAnalytics/eventSpec';

export default class ChecklistBatchExportUI extends UIStore {
  @observable batchExportForm;
  @observable saving;
  @observable loading;

  constructor(options) {
    super(options);

    this.batchExportForm = null;
    this.saving = false;
    this.loading = false;
    this.checklistTemplateSelectUI = new ChecklistTemplateSelectUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed
  get projectIncludeOptions() {
    return [
      {
        label: t('Active projects'),
        value: 'ACTIVE',
        dataQA: 'active-projects'
      },
      {
        label: t('Active and inactive projects'),
        value: 'ACTIVE_AND_INACTIVE',
        dataQA: 'active-and-inactive-projects'
      },
      {
        label: t('Selected projects'),
        value: 'SELECTED',
        dataQA: 'selected-projects'
      },
      {
        label: t('Selected project groups'),
        value: 'SELECTED_GROUPS',
        dataQA: 'selected-project-groups'
      }
    ];
  }

  @computed get project() {
    return this.parent.project;
  }

  @computed get company() {
    return this.parent.company;
  }

  @computed get projectDefaultValue() {
    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name
        }
      ];
    }

    return [];
  }

  @computed get templateOptions() {
    return this.checklistTemplateSelectUI.checklistTemplates.models.map(
      template => ({
        title: template.name,
        value: template.uuid,
        type: template.typeString
      })
    );
  }

  @action.bound async showBatchExportModal() {
    this.loading = true;
    this.showModal('ChecklistBatchExportModal');
    this.setupBatchExport();
  }

  @action.bound async setupBatchExport() {
    callTrack(COMPLETED_CHECKLISTS_EXPORT_STARTED, {
      project_id: null
    });

    this.batchExportForm = new ChecklistsBatchExportForm(
      {
        fields: checklistsBatchExportFormFields,
        labels: checklistsBatchExportFormLabels,
        rules: checklistsBatchExportFormRules,
        values: checklistsBatchExportFormValues
      },
      {
        options: checklistsBatchExportFormOptions,
        plugins: checklistsBatchExportFormPlugins
      }
    );
    if (this.project) {
      this.setProjectIncludeOption('SELECTED');
      this.selectProjects(this.projectDefaultValue);
    } else {
      this.setProjectIncludeOption('ACTIVE');
    }

    await this.checklistTemplateSelectUI.fetchChecklistTemplates(
      'COMPANY,RAKEN',
      ['ACTIVE']
    );
    this.loading = false;
  }

  @action.bound
  setProjectIncludeOption(value) {
    this.batchExportForm.$('projectIncludeOption').set('value', value);

    if (value === 'ACTIVE_AND_INACTIVE' || value === 'ACTIVE') {
      this.batchExportForm.update({
        projects: []
      });
    }
  }

  @action.bound
  selectProjects(selectedProjects) {
    this.batchExportForm.update({
      projects: selectedProjects
    });
  }

  @action.bound
  selectGroups(selectedGroups) {
    this.batchExportForm.update({
      groups: selectedGroups
    });
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    const timeFrames = [
      {
        id: 'DAILY',
        fromDate: moment(new Date())
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(new Date())
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current day'),
        projectEntire: false
      },
      {
        id: 'WEEKLY',
        fromDate: this.weekFirstDay
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: this.weekLastDay.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current week'),
        projectEntire: false
      },
      {
        id: 'BI_WEEKLY',
        fromDate: this.biWeeklyFirstDay
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: this.weekLastDay.endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Bi-weekly'),
        projectEntire: false
      },
      {
        id: 'MONTHLY',
        fromDate: moment(new Date())
          .startOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(new Date())
          .endOf('month')
          .format('YYYY-MM-DDTHH:mm:ss'),
        title: t('Current month'),
        projectEntire: false
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      }
    ];

    if (this.numberOfSelectedProjects === 1) {
      timeFrames.push({
        id: 'PROJECTENTIRE',
        title: t('Entire duration of the project')
      });
    }

    return timeFrames;
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame =>
        timeFrame.id === this.batchExportForm?.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate ? timeFrame.fromDate : '';
    const toDate = timeFrame.toDate ? timeFrame.toDate : '';

    this.batchExportForm?.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  @action.bound setFromDate(value) {
    this.batchExportForm?.$('fromDate').set(
      moment(value)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  }

  @action.bound setToDate(value) {
    this.batchExportForm?.$('toDate').set(
      moment(value)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')
    );
  }

  @action.bound
  async hideBatchExportModal() {
    await this.hideActiveModal();
  }

  @computed
  get enableSend() {
    if (this.saving || !this.batchExportForm) {
      return false;
    }
    return this.batchExportForm.isValid;
  }

  @action.bound
  async submitBatchExportForm(event) {
    event.preventDefault();
    event.stopPropagation();
    this.batchExportForm.submit({
      onSuccess: this.submitBatchExportFormSuccess,
      onError: this.submitBatchExportFormError
    });
  }

  @action.bound async submitBatchExportFormSuccess() {
    callTrack(COMPLETED_CHECKLISTS_EXPORT_COMPLETED, {
      project_id: null
    });
    this.saving = true;

    try {
      const values = this.batchExportForm.values();
      const payload = {
        templateUuids: values.templates,
        projectIncludeOption: values.projectIncludeOption,
        projectUuids:
          values.projectIncludeOption !== 'SELECTED'
            ? []
            : values.projects.map(project => project.value),

        emails: values.emails,
        fromDate: moment(values.fromDate).utc(),
        toDate: moment(values.toDate).utc(),
        localTimezone: moment.tz.guess()
      };

      payload.groupUuids =
        values.projectIncludeOption !== 'SELECTED_GROUPS'
          ? []
          : values.groups.map(group => group.uuid);

      const url = `${this.rootStore.urlMicroService(
        'toolbox'
      )}/checklists-reports/completed/pdf/batch`;

      await request.post(url, payload);
      await this.hideBatchExportModal();
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'info',
        title: t(
          'Your download request has been sent. You will receive an email with a link to download your requested completed files later today.'
        )
      });

      callTrack(COMPLETED_CHECKLISTS_EXPORT_DOWNLOADED, {
        project_id: null
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitBatchExportFormError() {
    console.error(this.batchExportForm.errors());
  }
}

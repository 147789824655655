import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable } from 'mobx';

import {
  PolicyBreakForm,
  policyBreakFormPlugins,
  policyBreakFormOptions,
  policyBreakFormFields,
  policyBreakFormRules
} from 'forms/policyBreak';

import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';

import Break from 'stores/models/Break';

export default class PolicyBreaksUI extends PolicyChildEditUI {
  @observable form;

  constructor(options) {
    super(options);

    this.form = null;
    this.breakTypeForEdit = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed get breakTypes() {
    return this.entryForEdit.formattedBreakTypes;
  }

  @action.bound
  editPolicyBreak(breakType) {
    this.breakTypeForEdit = new Break(
      {
        ...breakType
      },
      {
        rootStore: this.rootStore
      }
    );

    this.initPolicyBreakForm();
  }

  @action.bound cancelBreakEdit() {
    this.form = null;
    this.breakTypeForEdit = null;
  }

  @action.bound
  initPolicyBreakForm() {
    const {
      defaultDuration,
      minimumBreakDuration,
      billable
    } = this.breakTypeForEdit;

    this.form = new PolicyBreakForm(
      {
        fields: policyBreakFormFields,
        rules: policyBreakFormRules,
        values: {
          defaultDuration,
          minimumBreakDuration,
          billable
        }
      },
      {
        options: policyBreakFormOptions,
        plugins: policyBreakFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @computed get breakEditModalTitle() {
    return this.breakTypeForEdit?.type === 'REST'
      ? `Rest break settings`
      : `Meal break settings`;
  }

  @computed get durationValues() {
    return [
      { title: 'None', value: 0 },
      { title: '5 mins', value: 5 },
      { title: '10 mins', value: 10 },
      { title: '15 mins', value: 15 },
      { title: '20 mins', value: 20 },
      { title: '25 mins', value: 25 },
      { title: '30 mins', value: 30 },
      { title: '35 mins', value: 35 },
      { title: '40 mins', value: 40 },
      { title: '45 mins', value: 45 },
      { title: '50 mins', value: 50 },
      { title: '55 mins', value: 55 },
      { title: '60 mins', value: 60 }
    ];
  }

  @computed get defaultDurationValue() {
    return this.durationValues.find(
      option => option.value === this.form?.$('defaultDuration').value
    );
  }

  @computed get minimumBreakDurationValue() {
    return this.durationValues.find(
      option => option.value === this.form?.$('minimumBreakDuration').value
    );
  }

  @computed get compensationOptions() {
    return [{ title: 'Paid', value: true }, { title: 'Unpaid', value: false }];
  }

  @computed get compensationValue() {
    return this.compensationOptions.find(
      option => option.value === this.form?.$('billable').value
    );
  }

  @action.bound submitBreakEditForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitBreakEditFormSuccess,
      onError: this.submitBreakEditFormError
    });
  }

  @action.bound submitBreakEditFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitBreakEditFormSuccess() {
    try {
      await this.breakTypeForEdit.save(
        { ...this.form.values() },
        {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/companies/${this.company.uuid}/breaktypes/${
            this.breakTypeForEdit.uuid
          }`,
          wait: true
        }
      );
      await this.entryForEdit.fetch();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.cancelBreakEdit();

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Break updated')}`
    });
  }

  @computed get disableSaveButton() {
    return this.breakTypeForEdit?.saving || this.entryForEdit.fetching;
  }

  @action.bound async toggleBreakEnabled(breakType) {
    breakType.status = breakType.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    this.breakTypeForToggle = new Break(
      {
        ...breakType
      },
      {
        rootStore: this.rootStore
      }
    );

    try {
      await this.breakTypeForToggle.save(
        {
          status: this.breakTypeForToggle.status
        },
        {
          url: `${this.rootStore.urlMicroService(
            'performanceTracking'
          )}/companies/${this.company.uuid}/breaktypes/${
            this.breakTypeForToggle.uuid
          }`,
          wait: true
        }
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.entryForEdit.fetch();

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Break updated')}`
    });
  }
}

import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import { List, Popover, Text, Box } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const ListItemText = styled(Text)``;

const ListItemLink = ({ children, handleClick, to, ...props }) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((props, ref) => (
        <NavLink innerRef={ref} to={to} {...props} />
      )),
    [to]
  );

  return (
    <List.Item onClick={handleClick} button component={CustomLink} {...props}>
      {children}
    </List.Item>
  );
};

const CompanyNavigationUserMenu = observer(({ uiStore, store, location }) => {
  if (!uiStore.me.id) return null;

  return (
    <Popover
      open={Boolean(uiStore.userMenuAnchorElement)}
      anchorEl={uiStore.userMenuAnchorElement}
      onClose={uiStore.closeUserMenu}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{ vertical: -20, horizontal: -20 }}
      elevation={3}
    >
      <Box width={280} p={2}>
        <List dataQA="user-profile">
          <ListItemLink
            dataQA="user-profile-my-profile"
            handleClick={uiStore.closeUserMenu}
            to={{
              pathname: `${uiStore.me.viewUrl}/info`,
              state: { previousPath: location.pathname }
            }}
          >
            <ListItemText>{t('My profile')}</ListItemText>
          </ListItemLink>

          <ListItemLink
            handleClick={uiStore.closeUserMenu}
            to={{
              pathname: `${uiStore.me.viewUrl}/projects`,
              state: { previousPath: location.pathname }
            }}
            dataQA="user-profile-my-projects"
          >
            <ListItemText>{t('My projects')}</ListItemText>
          </ListItemLink>
          <ListItemLink
            handleClick={uiStore.closeUserMenu}
            to={{
              pathname: `${uiStore.me.viewUrl}/notifications`,
              state: { previousPath: location.pathname }
            }}
            dataQA="user-profile"
          >
            <ListItemText>{t('Notifications')}</ListItemText>
          </ListItemLink>
          <ListItemLink
            handleClick={uiStore.closeUserMenu}
            to={{
              pathname: `${uiStore.me.viewUrl}/tasks`,
              state: { previousPath: location.pathname }
            }}
            dataQA="user-profile-tasks"
          >
            <ListItemText>{t('Tasks')}</ListItemText>
          </ListItemLink>
          <ListItemLink
            handleClick={uiStore.closeUserMenu}
            to={{
              pathname: `${uiStore.me.viewUrl}/email-preferences`,
              state: { previousPath: location.pathname }
            }}
            dataQA="user-profile-email-preference"
          >
            <ListItemText>{t('Email preferences')}</ListItemText>
          </ListItemLink>
          <List.Item
            button
            component="a"
            onClick={uiStore.closeUserMenu}
            href="https://help.rakenapp.com/en/"
            target="_blank"
            dataQA="user-profile-help"
          >
            <ListItemText>{t('Help')}</ListItemText>
          </List.Item>

          {store.showDebugSection && (
            <List.Item
              button
              onClick={() => {
                store.debugUI.showDebugModalPage();
                uiStore.closeUserMenu();
              }}
              dataQA="user-profile-debug"
            >
              <ListItemText>{t('Debug')}</ListItemText>
            </List.Item>
          )}

          <List.Item
            button
            component="a"
            onClick={() => {
              store.logout();
            }}
            dataQA="user-profile-log-out"
          >
            <ListItemText rakenColor="red">{t('Log out')}</ListItemText>
          </List.Item>
        </List>
      </Box>
    </Popover>
  );
});

export default CompanyNavigationUserMenu;

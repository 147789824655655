import { Model } from 'mobx-mc';

export default class PolicyMember extends Model {
  get restAttributes() {
    return ['workerEid', 'workerName', 'workerUuid', 'policyUuid'];
  }

  idAttribute() {
    return 'workerUuid';
  }
}

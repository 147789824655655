import { computed, action, observable, reaction, when } from 'mobx';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import debounce from 'lodash.debounce';
import UIStore from './UIStore';
import ProjectProductionTrendsUI from './ProjectProductionTrendsUI';
import MaterialStats from './../models/MaterialStats';
import MaterialStatuses from '../collections/MaterialStatuses';
import MaterialDivisions from '../collections/MaterialDivisions';
import errorHandler from 'utils/errorHandler';
import { callTrack } from 'utils/segmentIntegration';
import { PRODUCTION_INSIGHTS_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import history from 'utils/history';

export default class ProjectProductionInsightsUI extends UIStore {
  @observable isToggleSubMenu;
  @observable isOpenCalendar;
  @observable dates;
  @observable dayConfig;
  @observable loading;
  @observable searchQuery;

  @observable timeFrameSelection;
  @observable fromDate;
  @observable toDate;
  @observable month;
  @observable selectedDay;

  @observable sortField;
  @observable sortDirection;
  @observable page;
  @observable pageSize;

  @observable reactionsSet;

  @observable pathname;

  constructor(options) {
    super(options);

    this.isToggleSubMenu = false;
    this.isOpenCalendar = false;
    this.dates = [];
    this.dayConfig = {};
    this.loading = true;
    this.searchQuery = '';

    this.fromDate = null;
    this.toDate = null;
    this.month = null;
    this.selectedDay = null;

    this.searchQuery = '';
    this.sortField = 'division';
    this.sortDirection = 'asc';
    this.page = 0;
    this.pageSize = 10;

    this.reactionsSet = false;

    this.pathname = null;

    this.fetchGlobalDebounced = debounce(this.fetchGlobal, 750);

    this.status = new MaterialStatuses(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.divisions = new MaterialDivisions(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.stats = new MaterialStats(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.productionTrendsUI = new ProjectProductionTrendsUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get isMobileOverlayMode() {
    return this.pathname?.indexOf('/mobile') > -1;
  }

  @computed get projectDataFetching() {
    return this.project?.fetching || this.project?.costCodesWithBudget.fetching;
  }

  @action.bound setup(pathname) {
    this.pathname = pathname;

    this.month = moment();
    this.selectedDay = moment();
    when(() => !this.projectDataFetching, () => this.setupReactions());
  }

  @action.bound
  tearDown() {
    this.clearSearchQuery();
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound
  setupReactions() {
    when(() => !this.loading, () => this.sendEvent());

    this.reactionAutomaticProgressTracking = reaction(
      () => this.project && this.project.automaticProgressTracking,
      value => {
        if (!this.project) return;

        if (value) {
          this.fromDate = this.project.startDate;
          this.toDate = this.selectedDay.format('YYYY-MM-DD');
        } else {
          this.fromDate = this.project.startDate;
          this.toDate = moment().format('YYYY-MM-DD');
        }

        if (this.page !== 0) {
          this.page = 0;
        }

        this.fetchGlobal();
      },
      { fireImmediately: true }
    );

    this.reactionSelectedDay = reaction(
      () => this.selectedDay,
      value => {
        this.toDate = value.format('YYYY-MM-DD');
        this.month = value;

        if (this.page !== 0) {
          this.page = 0;
        }

        this.fetchGlobal();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      searchQuery => {
        this.fetchGlobalDebounced();

        if (this.productionTrendsUI) {
          this.productionTrendsUI.clearUIState();
        }
      }
    );

    this.reactToLoading = reaction(
      () => this.loading,
      loading => {
        if (loading) return;

        this.sortedDivisions.forEach(division => {
          if (division.hasCostCodes && this.searchQuery) {
            division.handleShow();
          } else {
            division.handleHide();
          }
        });
      }
    );

    if (this.productionTrendsUI) {
      this.productionTrendsUI.setupReactions();
    }

    this.reactionsSet = true;
  }

  @action.bound tearDownReactions() {
    if (!this.reactionsSet) return;

    this.reactionAutomaticProgressTracking();
    this.reactionSelectedDay();
    this.reactToSearchQuery;
    this.reactToLoading();

    if (this.productionTrendsUI) {
      this.productionTrendsUI.tearDownReactions();
    }

    this.reactionsSet = false;
  }

  @computed
  get sortedDivisions() {
    return orderBy(
      this.divisions.models,
      [this.sortField],
      [this.sortDirection]
    );
  }

  @computed get hasSortedDivisions() {
    return this.sortedDivisions.length > 0;
  }

  @computed
  get paginatedDivisions() {
    return this.sortedDivisions.slice(
      this.page * this.pageSize,
      this.page * this.pageSize + this.pageSize
    );
  }

  @computed
  get totalPages() {
    return Math.ceil(this.sortedDivisions.length / this.pageSize);
  }

  @action
  setPage = page => {
    this.page = page.selected;
  };

  @computed
  get earliestStartDate() {
    return this.project.startDate;
  }

  @action.bound
  setSearchQuery(value) {
    this.searchQuery = value;

    if (this.page !== 0) {
      this.page = 0;
    }
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed
  get project() {
    return this.parent.project;
  }

  @computed
  get countCompletedEntries() {
    return this.dates.filter(date => {
      const monthCurrent = this.month.month() + 1;
      const yearCurrent = this.month.years();
      const monthDate = moment(date.date).month() + 1;
      const yearDate = moment(date.date).years();

      return (
        monthCurrent === monthDate &&
        yearCurrent === yearDate &&
        date.status === 'COMPLETED'
      );
    }).length;
  }

  @action.bound
  fetchStatus() {
    this.dayConfig = {};

    return this.status
      .fetch({
        url: `${this.urlMicroService('performanceTracking')}/production/${
          this.project.uuid
        }/statsByCostCode/manual/status`,
        params: {
          fromDate: this.fromDate,
          toDate: moment().format('YYYY-MM-DD')
        }
      })
      .then(response => {
        this.dates = response.data.collection;
        const dayConfigLocal = {};

        this.dates.forEach(date => {
          if (date.status === 'MISSED') return; // In future can will remove: https://rakenapp.atlassian.net/browse/RCD-8545

          dayConfigLocal[moment(date.date).format('M/D/YYYY')] = {
            dot: {
              color: 'goGreen'
            },
            color: 'lightGrey20',
            data: date
          };
        });

        this.dayConfig = { ...dayConfigLocal };
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @computed get params() {
    return {
      startDay: this.fromDate,
      endDay: this.toDate,
      isManual: this.isManual,
      includeEmptyCostCodes: false,
      query: this.searchQuery,
      limit: 10000
    };
  }

  @computed get isFutureProject() {
    return this.fromDate > this.toDate;
  }

  @action.bound
  fetchStats() {
    if (this.isFutureProject) return;

    return this.stats.fetch({
      url: `${this.urlMicroService('performanceTracking')}/production/${
        this.project.uuid
      }/allStatsByCostCode`,
      params: this.params
    });
  }

  @action.bound
  fetchDivisions() {
    return this.divisions.fetch({
      url: `${this.urlMicroService('performanceTracking')}/production/${
        this.project.uuid
      }/statsByCostCode`,
      params: this.params
    });
  }

  @action.bound
  async fetchGlobal() {
    this.loading = true;

    const fetchPromises = [
      this.fetchStats(),
      this.fetchStatus(),
      this.fetchDivisions()
    ];

    if (this.productionTrendsUI) {
      fetchPromises.push(this.productionTrendsUI.fetchStatsByTimePeriod());
    }

    await Promise.all(fetchPromises);

    this.loading = false;
  }

  @computed
  get isManual() {
    if (this.project?.enabledOnlyTimeTracking) {
      return true;
    }

    return !this.project?.automaticProgressTracking;
  }

  @computed
  get projectName() {
    return this.project.name;
  }

  @action.bound
  setCalendarSelectedMonth(month) {
    this.month = month;
  }

  @action.bound
  setCalendarSelectedDate(day) {
    this.selectedDay = day;
  }

  @computed
  get calendarConfig() {
    return this.dayConfig;
  }

  @action.bound
  handleHideCalendar() {
    this.isOpenCalendar = false;
  }

  @action.bound
  handleShowCalendar() {
    this.isOpenCalendar = true;
  }

  @action.bound
  handleToggleEnableAutomaticProgressTracking() {
    this.project
      .save({
        automaticProgressTracking: !this.project.automaticProgressTracking
      })
      .catch(error => {
        errorHandler(error, this.notifications.pushError);
      });
  }

  @action.bound
  handleHideSubMenu() {
    this.isToggleSubMenu = false;
  }

  @action.bound
  handleShowSubMenu() {
    this.isToggleSubMenu = true;
  }

  @action.bound
  clearUIState() {
    if (this.productionTrendsUI) {
      this.productionTrendsUI.clearUIState();
    }

    this.isToggleSubMenu = false;
    this.isOpenCalendar = false;
    this.loading = true;
  }

  @action.bound
  sortByColumn(column) {
    if (this.sortField === column) {
      this.sortDirection === 'asc'
        ? (this.sortDirection = 'desc')
        : (this.sortDirection = 'asc');
    } else {
      this.sortField = column;
    }

    if (this.page !== 0) {
      this.page = 0;
    }
  }

  @action.bound
  sendEvent() {
    let budgetedCodes = 0;
    let budgetedHours = 0;
    let budgetedQuantities = 0;

    this.sortedDivisions.forEach(division => {
      division.costCodes.models.forEach(costCode => {
        if (costCode.budgetedHours > 0) {
          budgetedHours += 1;
        }

        if (costCode.material?.budgetedQuantity > 0) {
          budgetedQuantities += 1;
        }

        if (
          costCode.budgetedHours > 0 ||
          costCode.material?.budgetedQuantity > 0
        ) {
          budgetedCodes += 1;
        }
      });
    });

    const eventProps = {
      tracking_mode: this.project.automaticProgressTracking
        ? 'Automatic'
        : 'Manual',
      budgeted_codes: budgetedCodes,
      budgeted_hours: budgetedHours,
      budgeted_quantities: budgetedQuantities
    };

    callTrack(PRODUCTION_INSIGHTS_VIEWED, eventProps);
  }

  @action.bound cancelProductionInsights() {
    history.push({
      pathname: `${this.project.viewUrl}/worklogs`,
      search: this.parent.baseQueryParams
    });
  }
}

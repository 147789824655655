import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { computed } from 'mobx';
import moment from 'moment';
import { t } from 'utils/translate';

const verificationReminderFormPlugins = { dvr: validatorjs };

const verificationReminderFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const verificationReminderFormFields = [
  'uuid',
  'reminders',
  'timeFrameType',
  'emails',
  'email',
  'reportName',
  'questionsReminders',
  'photoIdReminders',
  'time',
  'startDate'
];

const verificationReminderFormRules = {
  timeFrameType: 'required',
  emails: 'array|required',
  email: 'required_without:emails|email',
  reportName: 'string|required'
};

const verificationReminderFormLabels = {
  reportName: t('Report name')
};

const verificationReminderFormValues = {
  uuid: '',
  reminders: [],
  email: '',
  emails: [],
  reportName: '',
  timeFrameType: '',
  questionsReminders: false,
  photoIdReminders: false,
  time: moment().format('HH:mm'),
  startDate: moment().format('YYYY-MM-DD')
};

class VerificationReminderForm extends MobxReactForm {
  @computed get enableDaily() {
    return this.$('timeFrameType').value === 'DAILY';
  }

  @computed get enableWeeklyBiweeklyMonthly() {
    return (
      this.$('timeFrameType').value === 'WEEKLY' ||
      this.$('timeFrameType').value === 'BI_WEEKLY' ||
      this.$('timeFrameType').value === 'MONTHLY'
    );
  }
}

export {
  VerificationReminderForm,
  verificationReminderFormRules,
  verificationReminderFormFields,
  verificationReminderFormLabels,
  verificationReminderFormOptions,
  verificationReminderFormPlugins,
  verificationReminderFormValues
};

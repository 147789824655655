import React from 'react';
import { observer } from 'mobx-react';

import { Box, Text, Skeleton, Tooltip } from '@raken/athens-web/lib';
import styled from 'styled-components';

const WeatherIcon = styled.img`
  display: block;
  width: 24px;
  max-height: 24px;
  margin: 0 10px 0 0;
  position: relative;
`;

const WeatherWrapper = styled(Box)`
  height: 44px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 0 16px;
  cursor: pointer;
  &:hover {
    h2,
    p {
      color: #b85900;
    }
  }
`;

const WeatherText = styled(Text)`
  font-size: 1.3rem;
  text-transform: uppercase;
  padding-left: 10px;
`;

const DegreeText = styled(Text)`
  color: #686664;
  font-weight: bold;
`;

export default observer(({ uiStore }) => {
  if (uiStore.weatherUI.fetchingDaily || uiStore.weatherUI.model.isNew)
    return (
      <WeatherWrapper>
        <Skeleton variant="text" height={35} width="100%" dataQA="weather" />
      </WeatherWrapper>
    );

  const day = uiStore.weatherUI.model.isToday
    ? uiStore.weatherUI.model.currentConditions
    : uiStore.weatherUI.model.selectedDay;

  if (!day) return null;

  return (
    <>
      <WeatherWrapper
        onClick={event => {
          event.stopPropagation();
          uiStore.openWeatherModal();
        }}
      >
        {day.icon && (
          <Tooltip
            arrow
            title={
              uiStore.sideBarOpen ? '' : uiStore.weatherUI.selectedDayTooltip
            }
            placement={'right'}
            dataQA="raken"
          >
            <Box mr={3}>
              <WeatherIcon
                src={`${uiStore.assetsURL}/svg/weather/dashboard-${day.icon}.svg`}
                data-qa="image_weather-icon"
              />
            </Box>
          </Tooltip>
        )}

        <Box display="flex" alignItems="center">
          <DegreeText variant="h2" dataQA="weather-low">
            {uiStore.weatherUI.model.selectedDay?.low}
            {'°'}
          </DegreeText>
          &nbsp;
          <DegreeText variant="h2"> {'/'} </DegreeText>
          &nbsp;
          <DegreeText variant="h2" dataQA="weather-high">
            {uiStore.weatherUI.model.selectedDay?.high}
            {'°'}
          </DegreeText>
          &nbsp;
          <WeatherText light dataQA="weather-summary">
            {uiStore.weatherUI.model.selectedDay?.summary}
          </WeatherText>
        </Box>
      </WeatherWrapper>
    </>
  );
});

import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateShiftsForm,
  projectTemplateShiftsFormOptions,
  projectTemplateShiftsFormFields,
  projectTemplateShiftsFormRules,
  projectTemplateShiftsFormPlugins,
  projectTemplateShiftsFormLabels
} from 'forms/projectTemplates/projectTemplateShifts';

import { t } from 'utils/translate';
import SettingsShiftsUI from 'stores/ui/project/SettingsShiftsUI';
import toggleArrayFieldValue from 'forms/utils/toggleArrayFieldValue';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class ProjectTemplateShiftsUI extends SettingsShiftsUI {
  @observable form;
  constructor(options) {
    super(options);
    this.form = null;
    this.saveTitle = t('Project template shifts saved');
  }
  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }
  @computed get showEmptyState() {
    return !this.Shifts.hasModels && !this.loading;
  }
  @action.bound async setup() {
    this.setupReactions();
    this.fetchShifts();
    this.setupForm();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateShiftsForm(
      {
        fields: projectTemplateShiftsFormFields,
        rules: projectTemplateShiftsFormRules,
        labels: projectTemplateShiftsFormLabels,
        values: this.selectedTemplate.shiftsValues
      },
      {
        options: projectTemplateShiftsFormOptions,
        plugins: projectTemplateShiftsFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.form = null;
    this.tearDownReactions();
    this.clearUIState();
  }
  @action.bound
  async toggleShiftForTemplate(shift) {
    toggleArrayFieldValue(this.form, 'hiddenShifts', shift.uuid);
    try {
      await this.selectedTemplate.save(this.form.values(), {
        wait: true,
        url: `/ra/project-templates/${this.selectedTemplate.id}`
      });
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: this.form
          .$('hiddenShifts')
          .value?.find(hiddenShift => hiddenShift === shift.uuid)
          ? t('Shift enabled')
          : t('Shift disabled')
      });
      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      this.notifications.pushNotification({
        snackbar: 'error',
        icon: 'close',
        title: t('Shift change cannot be saved')
      });
      toggleArrayFieldValue(this.form, 'hiddenShifts', shift.uuid);
    } finally {
      this.saving = false;
    }
  }
}

import request from 'axios';
import { reaction, action, computed, when } from 'mobx';
import debounce from 'lodash.debounce';
import { observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';
import omit from 'lodash.omit';
import Worker from '../stores/models/Worker';

const workerFormPlugins = extendedPlugins;

const workerFormOptions = {
  validateOnInit: false,
  strictUpdate: false,
  validateOnChange: true
};

const workerFormFields = [
  'id',
  'workerUuid',
  'firstName',
  'lastName',
  'classificationId', // TODO Deprecate
  'classification',
  'classification.uuid',
  'classification.name',
  'employeeId',
  'email',
  'phoneNumber',
  'workerType',
  'lunchStartTime',
  'breakStartTime',
  'photo',
  'avatar',
  'companyUuid', // Used to build URL to validation endpoint
  'defaultShift',
  'defaultShift.uuid',
  'defaultShift.name',
  'defaultCostCode',
  'defaultCostCode.uuid',
  'defaultCostCode.code',
  'defaultCostCode.division',
  'defaultCrewName',
  'defaultCrewName.uuid',
  'defaultCrewName.name',
  'groups[]',
  'groups[].uuid'
];

const workerFormRules = {
  workerUuid: 'string',
  firstName: 'string|max:255|required',
  lastName: 'string|max:255|required',
  classificationId: 'numeric', // TODO Deprecate
  'classification.uuid': 'string',
  'classification.name': 'string',
  employeeId: 'string|max:100|employeeId_available',
  email: 'email|max:200',
  phoneNumber: 'string|phone_available|max:30',
  avatar: 'string',
  groups: 'array'
};

const workerFormLabels = {
  firstName: t('first name'),
  lastName: t('last name'),
  classificationId: t('classif.'), // TODO Deprecate
  'classification.uuid': t('classif.'),
  classification: t('classifaction'),
  employeeId: t('employee ID'),
  email: t('email'),
  phoneNumber: t('phone'),
  groups: t('employee groups')
};

const workerFormFieldOptions = {
  email: {
    validationDebounceWait: 1000
  },
  employeeId: {
    validationDebounceWait: 1000
  }
};

class WorkerForm extends MobxReactForm {
  @observable existingWorker;

  constructor(settings, options) {
    super(settings, options);

    this.rootStore = options.rootStore;
    this.existingWorker = null;
    this.searchWorkers = debounce(this.searchWorkers, 250);

    this.reactToSearchParams = reaction(
      () => this.searchParams,
      searchParams => {
        if (searchParams) {
          this.searchWorkers();
        } else {
          this.existingWorker = null;
        }
      }
    );

    when(
      () => this.rootStore.me.company,
      () => {
        this.$('companyUuid').set(this.rootStore.me.company.uuid);
      }
    );
  }

  @computed get searchParams() {
    if (!this.$('firstName').value || !this.$('lastName').value) {
      return null;
    }

    const params = {
      firstName: this.$('firstName').value,
      lastName: this.$('lastName').value,
      employeeId: this.$('employeeId').value || 'null',
      companyUuids: this.rootStore.me.company.uuid
    };

    return params;
  }

  @action.bound searchWorkers() {
    if (!this.searchParams) return;

    this.existingWorker = null;

    request
      .get(this.rootStore.workers.url(), {
        params: this.searchParams
      })
      .then(response => {
        if (response.data?.collection?.length) {
          this.existingWorker = new Worker(response.data.collection[0]);
        } else {
          this.existingWorker = null;
        }
      })
      .catch(error => {
        this.existingWorker = null;
      });
  }

  @computed
  get fullName() {
    return `${this.$('firstName').value} ${this.$('lastName').value}`;
  }

  trimmedValues() {
    const values = this.values();

    // TODO remove with Phase 1 time cards
    if (values.phoneNumber === '+') {
      values.phoneNumber = '';
    } else if (values.phoneNumber) {
      values.phoneNumber = `+${values.phoneNumber.replace(/\D/g, '')}`;
    }

    return omit(trimObject(values), ['workerUuid', 'companyUuid']);
  }

  @computed get workerExistsMessage() {
    if (!this.existingWorker) return '';

    if (this.$('employeeId').value) {
      return t('A worker with this name and Employee Id already exists.');
    }

    return t(
      'A worker with this name already exists. Use a unique Employee Id to differentiate.'
    );
  }
}

export {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormLabels,
  workerFormFieldOptions,
  workerFormPlugins
};

import { observer } from 'mobx-react';
import React from 'react';

import { Form } from 'raken-ui';

import { Button, TextField, Select } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const AppConnectFormOsha = observer(({ uiStore }) => {
  return (
    <Form data-qa="form_app-connect" onSubmit={uiStore.submitActiveForm}>
      <TextField
        placeholder={t('API Token')}
        dataQA="apiToken"
        value={uiStore.activeForm.$('apiToken').value}
        onChange={uiStore.activeForm.$('apiToken').sync}
        error={uiStore.activeForm.$('apiToken').error}
        helperText={uiStore.activeForm.$('apiToken').error}
        fullWidth
        autoFocus
      />

      <Select
        fullWidth
        error={uiStore.activeForm.$('establishment').hasError}
        helperText={uiStore.activeForm.$('establishment').error}
        value={uiStore.activeForm.$('establishment').value}
        onChange={(e, option) => {
          uiStore.activeForm.$('establishment').set(option);
        }}
        placeholder={t('Select establishment')}
        disabled={false}
        optionTitle={'name'}
        options={uiStore.integrationOptions}
        dataQA="establishment"
      />

      <Button disabled={uiStore.saving} type="submit" fullWidth dataQA="submit">
        {uiStore.saving
          ? t('Authorizing connection')
          : t('Authorize connection')}
      </Button>
    </Form>
  );
});

export default AppConnectFormOsha;

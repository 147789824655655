import omit from 'lodash.omit';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

import {
  EmailPreferencesForm,
  emailPreferencesFormOptions,
  emailPreferencesFormFields,
  emailPreferencesFormLabels,
  emailPreferencesFormRules,
  emailPreferencesFormPlugins
} from 'forms/emailPreferences';

import EmailPreferences from '../models/EmailPreferences';

export default class TeamMemberEmailPreferencesUI extends UIStore {
  @observable loading;
  @observable emailPreferencesForm;

  constructor(options) {
    super(options);
    this.loading = true;

    this.initEmailPreferencesForm();

    this.emailPreferences = new EmailPreferences(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    this.loading = true;

    await this.fetchEmailPreferences();

    this.initEmailPreferencesForm(this.emailPreferences.preferencesValues);

    this.loading = false;
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound initEmailPreferencesForm(values = {}) {
    this.emailPreferencesForm = new EmailPreferencesForm(
      {
        fields: emailPreferencesFormFields,
        rules: emailPreferencesFormRules,
        labels: emailPreferencesFormLabels,
        values: values
      },
      {
        options: emailPreferencesFormOptions,
        plugins: emailPreferencesFormPlugins
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.emailPreferencesForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound fetchEmailPreferences() {
    return this.emailPreferences.fetch();
  }

  @computed get disableSaveButton() {
    return this.saving || this.emailPreferencesForm.check('hasError');
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @action.bound cancelEmailPreferences() {
    this.emailPreferencesForm.reset();
  }

  @action.bound resetEmailPreferencesForm() {
    this.clearValidationDetails();
    this.initEmailPreferencesForm();
  }

  @action.bound submitEmailPreferencesForm(event) {
    event.preventDefault();

    if (this.emailPreferencesForm.submitting) return;

    this.emailPreferencesForm.submit({
      onSuccess: this.submitEmailPreferencesFormSuccess,
      onError: this.submitEmailPreferencesFormError
    });
  }

  @action.bound async submitEmailPreferencesFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    const values = omit(this.emailPreferencesForm.values(), 'unsubscribeAll');

    // If the user has explicitly set a checkbox to true then set upsubscribe all to false
    if (Object.values(values).some(value => value === true)) {
      values.unsubscribeAll = false;
    } else {
      values.unsubscribeAll = this.emailPreferences.unsubscribeAll;
    }

    try {
      await this.emailPreferences.save(values, { wait: true });
      this.resetEmailPreferencesForm();
      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Email preferences saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEmailPreferencesFormError() {
    console.error(this.emailPreferencesForm.errors());
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.loading = true;
  }
}

import { computed, action, observable } from 'mobx';
import omit from 'lodash.omit';
import TimeCard from '../models/workLogs/TimeCard';
import moment from 'moment-timezone';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';
import abbreviateNumber from 'utils/abbreviateNumber';
import orderBy from 'lodash.orderby';

export default class TimeSheetTimeCard extends TimeCard {
  @observable selected;
  @observable addPayrollNote;

  constructor(attributes, options) {
    super(attributes, options);

    this.selected = this.collection?.parent.selected;
    this.addPayrollNote = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'dateTimestamp',
      'timezone',
      'projectName',
      'projectNo',
      'projectUuid',
      'classification',
      'classifications',
      'note',
      'split',
      'totalHours',
      'hasLunch',
      'hasBreak',
      'startTime',
      'endTime',
      'rawStartTime',
      'rawEndTime',
      'timeCardsStartAndEndTime',
      'crewName',
      'workLogType',
      'modifiedBy',
      'modifyTimestamp',
      'costCodes',
      'attachments',
      'approved',
      'approvedBy',
      'approvedTimestamp',
      'integrationSyncStatus',
      'integrationSyncMessage',
      'shift',
      'payTypeHours',
      'costCodeDisplayNames',
      'shifts',
      'breaks',
      'timeEntries',
      'timeCardDetails',
      'hasAttachments'
    ];
  }

  //Overwriting of parse from the TimeCard parent model
  @action.bound
  parse(attributes) {
    this.parseCostCodeEntries(attributes);
    this.parseAttachments(attributes.attachments);
    return {
      ...omit(attributes, ['attachments'])
    };
  }

  @computed
  get isClockedIn() {
    return Boolean(this.timeCardDetails.hasKioskActivity && !this.endTime);
  }

  @action.bound
  toggleSelected() {
    this.selected = !this.selected;
  }

  @action.bound
  setSelected(selected) {
    this.selected = selected;
  }

  @computed
  get crewNameAndDate() {
    return this.crewName
      ? `${this.crewName} | ${this.formattedDate}`
      : this.formattedDate;
  }

  @computed
  get classificationAndProjectNumber() {
    if (this.classification && this.projectNo) {
      return `${this.classification} | ${this.projectNo}`;
    }
    if (this.classification) {
      return `${this.classification}`;
    }
    if (this.projectNo) {
      return `${this.projectNo}`;
    }
    return '';
  }

  @computed
  get modifiedByFullName() {
    return this.modifiedBy
      ? `${this.modifiedBy.firstName} ${this.modifiedBy?.lastName}`
      : null;
  }

  @computed
  get approvedByFullName() {
    return this.approvedBy
      ? `${this.approvedBy.firstName} ${this.approvedBy.lastName}`
      : null;
  }

  @computed
  get formattedDate() {
    return this.formatTimestampDayWithDate(this.dateTimestamp);
  }

  @computed
  get formattedDateUniversalTime() {
    return moment(this.dateTimestamp)
      .tz(this.timezone)
      .format('YYYY-MM-DD');
  }

  @computed
  get formattedModifiedDate() {
    return this.formatTimestamp(this.modifyTimestamp);
  }

  @computed
  get formattedApprovedDate() {
    return this.formatTimestamp(this.approvedTimestamp);
  }

  formatTimestamp(timestamp) {
    const now = moment().tz(this.timezone);
    const then = moment(timestamp).tz(this.timezone);

    if (now.isSame(then, 'day')) {
      return t('Today at') + ' ' + then.format('h:mm A');
    }

    if (now.subtract(1, 'day').isSame(then, 'day')) {
      return t('Yesterday at') + ' ' + then.format('h:mm A');
    }

    if (now.isSame(then, 'year')) {
      return then.format('MMM D h:mm A');
    }

    return then.format('MMM D, YYYY h:mm A');
  }

  formatTimestampDayWithDate(timestamp) {
    const now = moment().tz(this.timezone);
    const then = moment(timestamp).tz(this.timezone);

    if (now.isSame(then, 'year')) {
      return then.format('ddd, MMM D');
    }

    return then.format('ddd, MMM D, YYYY');
  }

  @computed
  get tableCostCodeSummaries() {
    return this.costCodeEntries.models.filter(costCode => {
      return Boolean(
        costCode.regularTime || costCode.overTime || costCode.doubleTime
      );
    });
  }

  @computed
  get hasCostCodes() {
    return this.tableCostCodeSummaries.length > 0;
  }

  @computed get displayedTableCostCodeSummaries() {
    return this.hasCostCodes && this.tableCostCodeSummaries.slice(0, 9);
  }

  @computed
  get hasBreaks() {
    return this.breaks.length > 0;
  }

  @computed
  get displayedTotalTime() {
    return this.totalHours === 0 ? '-' : abbreviateNumber(this.totalHours);
  }

  @computed get isKioskTimeCardAndCannotBeSavedDueToBreakRules() {
    const breaksWithNoDuration = this.breaks.length
      ? this.breaks.filter(tcBreak => !tcBreak.duration)
      : [];

    /**
     * Kiosk time cards that have been completed (the end time is set) cannot be saved with 0-duration breaks. Even if the break allows no duration!
     */

    if (this.timeCardDetails.hasKioskActivity && this.endTime) {
      return breaksWithNoDuration.length > 0;
    }

    /**
     * Kiosk Time Cards that have no end time (in-flight Kiosk Time Cards) can be saved with one   break that has 0 duration. This is allowed even if the break requires a duration.
     * Kiosk time cards can’t be saved with 2 0-duration breaks. Even if the breaks don’t require a duration.
     */

    return (
      this.timeCardDetails.hasKioskActivity &&
      Boolean(breaksWithNoDuration.length > 1)
    );
  }

  @computed
  get parentTimesheet() {
    return this.collection.parent;
  }

  @computed
  get worker() {
    return this.collection.parent.worker;
  }

  @computed
  get slug() {
    return kebabCase(this.formattedDate);
  }

  @action.bound
  handleAddPayrollNoteButton() {
    this.addPayrollNote = true;
  }

  @action.bound
  handleClosePayrollNoteButton() {
    this.addPayrollNote = false;
    this.note = '';
  }

  @computed
  get showPayrollNote() {
    return Boolean(
      this.note || Boolean(this.hasAttachments) || this.addPayrollNote
    );
  }

  @computed get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @computed
  get formattedStartTime() {
    return this.startTime
      ? this.formatToTwelveHoursFormat(this.startTime)
      : null;
  }

  @computed
  get formattedRawStartTime() {
    return this.rawStartTime
      ? this.formatToTwelveHoursFormat(this.rawStartTime)
      : null;
  }

  @computed
  get rawStartTimeMessage() {
    return `${t('Clocked in at')} ${this.formattedRawStartTime}`;
  }

  @computed
  get formattedEndTime() {
    return this.endTime ? this.formatToTwelveHoursFormat(this.endTime) : null;
  }

  @computed
  get formattedRawEndTime() {
    return this.rawEndTime
      ? this.formatToTwelveHoursFormat(this.rawEndTime)
      : null;
  }

  @computed
  get rawEndTimeMessage() {
    return `${t('Clocked out at')} ${this.formattedRawEndTime}`;
  }

  formatToTwelveHoursFormat(time) {
    return moment(time, 'HH:mm:ss').format('hh:mm A');
  }

  createBreakMessage(timeCardBreak) {
    if (!timeCardBreak.rawStartTime) return ``;

    if (timeCardBreak.rawStartTime && !timeCardBreak.rawEndTime) {
      return `${t('Break started at')} ${this.formatToTwelveHoursFormat(
        timeCardBreak.rawStartTime
      )}`;
    }

    return `${t('Break started at')} ${this.formatToTwelveHoursFormat(
      timeCardBreak.rawStartTime
    )} ${t('and ended at')} ${this.formatToTwelveHoursFormat(
      timeCardBreak.rawEndTime
    )}.`;
  }

  @computed
  get breakTextFormatted() {
    return this.hasBreaks
      ? this.breaks.map((timeCardBreak, key) => {
          let breakInfo;
          if (
            !breakInfo &&
            timeCardBreak.name &&
            timeCardBreak.duration &&
            timeCardBreak.startTime &&
            timeCardBreak.endTime &&
            timeCardBreak.endTimeRequired
          ) {
            breakInfo = `${timeCardBreak.name} ${t('at')} ${
              timeCardBreak.startTime
            } - ${timeCardBreak.endTime} ${t('for')} ${Math.floor(
              timeCardBreak.duration
            )} ${t('minutes.')}`;
          }

          if (
            !breakInfo &&
            timeCardBreak.name &&
            timeCardBreak.duration &&
            timeCardBreak.startTime
          ) {
            breakInfo = `${timeCardBreak.name} ${t('at')} ${
              timeCardBreak.startTime
            } ${t('for')} ${Math.floor(timeCardBreak.duration)} ${t(
              'minutes.'
            )}`;
          }

          if (!breakInfo && timeCardBreak.name && timeCardBreak.duration) {
            breakInfo = `${timeCardBreak.name} ${t('for')} ${Math.floor(
              timeCardBreak.duration
            )} ${t('minutes.')}`;
          }

          if (!breakInfo && timeCardBreak.name && timeCardBreak.startTime) {
            breakInfo = `${timeCardBreak.name} ${t('at')} ${
              timeCardBreak.startTime
            }.`;
          }

          if (!breakInfo && timeCardBreak.name) {
            breakInfo = `${timeCardBreak.name}.`;
          }

          return {
            text: breakInfo ? breakInfo : `-`,
            breakMessage: this.createBreakMessage(timeCardBreak)
          };
        })
      : [`-`];
  }

  @computed
  get costCodeFromTimeEntries() {
    return this.timeEntries?.map(timeEntry => {
      if (!timeEntry.costCode) {
        return { costCodeDisplayName: '-', uuid: null };
      }
      return {
        code: timeEntry.costCode.code,
        description: timeEntry.costCode.description,
        division: timeEntry.costCode.division,
        isDefault: timeEntry.costCode.isDefault,
        uuid: timeEntry.costCode.uuid,
        costCodeDisplayName: timeEntry.costCode.costCodeDisplayName
      };
    });
  }

  @computed
  get classificationFromTimeEntries() {
    return this.timeEntries?.map(timeEntry => {
      if (!timeEntry.classification) {
        return { name: '-', uuid: null };
      }
      return {
        name: timeEntry.classification.name,
        uuid: timeEntry.classification.uuid
      };
    });
  }

  @computed
  get shiftsFromTimeEntries() {
    return this.timeEntries?.map(timeEntry => {
      if (!timeEntry.shift) {
        return { code: null, name: '-', uuid: null };
      }
      return {
        code: timeEntry.shift.code,
        name: timeEntry.shift.name,
        uuid: timeEntry.shift.uuid
      };
    });
  }

  @computed
  get roundedPayTypeHours() {
    const hours = this.timeEntries?.map(timeEntry => {
      return {
        code: timeEntry.payType?.code,
        hours: abbreviateNumber(timeEntry.hours)
      };
    });

    return orderBy(hours, ['order']);
  }

  @computed
  get status() {
    if (this.fromKioskOrTimeClock && !this.endTime) {
      return t('Clocked in');
    }

    return this.approved ? t('Approved') : t('Unapproved');
  }

  @computed
  get statusColor() {
    if (this.fromKioskOrTimeClock && !this.endTime) {
      return 'blue';
    }

    return this.approved ? 'green' : 'lightGrey';
  }

  @computed get timeCardHoursFromNow() {
    const now = moment().tz(this.timezone);
    const timeCardDateTime = `${this.formattedDateUniversalTime}T${this.startTime}`;

    return now.diff(timeCardDateTime, 'hours');
  }

  @computed
  get fromKioskOrTimeClock() {
    return (
      this.timeCardDetails?.hasKioskActivity ||
      this.timeCardDetails?.hasTimeClockActivity
    );
  }

  @computed
  get originColumnText() {
    if (
      this.timeCardDetails?.hasKioskActivity &&
      this.timeCardDetails?.hasTimeClockActivity
    ) {
      return t('Kiosk, Time clock');
    }

    if (this.timeCardDetails?.hasTimeClockActivity) {
      return t('Time clock');
    }

    if (this.timeCardDetails?.hasKioskActivity) {
      return t('Kiosk');
    }

    return `-`;
  }

  @computed get warningIconText() {
    if (
      this.kioskTimeClockStatuses.includes('OutOfProjectArea') &&
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    ) {
      return t('This time card has multiple potential issues');
    }

    if (this.kioskTimeClockStatuses.includes('OutOfProjectArea')) {
      return t(
        'This time card has event(s) that occurred outside of the job site'
      );
    }

    if (this.kioskTimeClockStatuses.includes('MoreThan24Hours')) {
      return t('This time card has no end time and spans over 24 hours');
    }

    return '';
  }
}

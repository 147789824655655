import _capitalize from 'lodash.capitalize';
import { computed, toJS } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment';
import numberWithCommas from 'utils/numberWithCommas';
import pickBy from 'lodash.pickby';

export default class Subscription extends Model {
  get restAttributes() {
    return [
      'companyId',
      'subscriptionState',
      'startDate',
      'endDate',
      'autoRenew',
      'seats',
      'seatsInUse',
      'trialEndDate',
      'active',
      'billingPlanName',
      'billingPlanKey',
      'billingPlatform',
      'periodType',
      'type',
      'upcomingBillingAmount',
      'upcomingBillingDate',
      'pricePerSeat',
      'pricePerSeatToday',
      'upcomingSubscriptionPlan',
      'upcomingBillingPlanKey',
      'upcomingPeriodType',
      'forceDowngrade',
      'promoEndDate',
      'promoLeftDaysCount',
      'isPromo',
      'accountType',
      'accountSubType'
    ];
  }

  url() {
    return '/ra/billing/subscription';
  }

  @computed
  get company() {
    return this.parent;
  }

  @computed
  get id() {
    return this.company.uuid;
  }

  @computed
  get seatsRemaining() {
    return this.seats - this.seatsInUse;
  }

  @computed
  get planName() {
    return this.billingPlanName;
  }

  /**
   * Shortcut to check if subscription is cancelled
   */
  @computed
  get isCancelled() {
    return this.subscriptionState === 'CANCELLED';
  }

  /**
   * On Trial Check
   */
  @computed
  get onTrial() {
    return this.subscriptionState === 'TRIAL';
  }

  /**
   * Past Due Check
   */
  @computed
  get pastDue() {
    return this.subscriptionState === 'PAST_DUE';
  }

  /**
   * On Hold check
   */
  @computed
  get onHold() {
    return this.subscriptionState === 'ON_HOLD';
  }

  /**
   * On Annual Billing Check
   */
  @computed
  get onAnnual() {
    return this.periodType === 'ANNUAL';
  }

  /**
   * Get the formatted start date
   */
  @computed
  get startDateFormat() {
    return moment(this.startDate).format('YYYY-MM-DD');
  }

  /**
   * Get the formatted end date
   */
  @computed
  get endDateFormat() {
    return moment(this.endDate).format('YYYY-MM-DD');
  }

  /**
   * Get the formatted end date
   */
  @computed
  get promoEndDateFormat() {
    return moment(this.promoEndDate).format('YYYY-MM-DD');
  }

  /**
   * Get the formatted end dateonAnnual
   */
  @computed
  get dayAfterEndDate() {
    return moment(this.endDate)
      .endOf('day')
      .add(24, 'hours')
      .format('YYYY-MM-DD');
  }

  /**
   * Set the bootstrap style based on subscription state
   */
  @computed
  get displayStyle() {
    switch (this.subscriptionState) {
      case 'ACTIVE':
        return 'success';
      case 'CANCELLED':
        return 'danger';
      case 'PAST_DUE':
        return 'warning';
      case 'ON_HOLD':
        return 'warning';
      default:
        return 'success';
    }
  }

  /**
   * Get the formatted upcoming billing date
   */
  @computed
  get upcomingBillingDateFormat() {
    return moment(this.upcomingBillingDate).format('YYYY-MM-DD');
  }

  /**
   * Get the formatted upcoming billing date
   */
  @computed
  get upcomingBillingAmountPrice() {
    return `$${numberWithCommas(this.upcomingBillingAmount / 100)}`;
  }

  /**
   * Calculates how many users in the subscription quantity are not yet in use
   */
  @computed
  get usersRemaining() {
    return this.seats - this.seatsInUse;
  }

  @computed
  get asFormValues() {
    const values = Object.assign({}, toJS(this.attributes), {
      seats: this.seats || 1,
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      trialEndDate: this.trialEndDate
        ? moment(this.trialEndDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      billingPlatform: this.billingPlatform
    });

    return pickBy(values, value => value !== undefined);
  }

  /**
   * Calculate the number of days remaining in the trial
   */
  @computed
  get trialDaysRemaining() {
    if (!this.trialEndDate) return 0;

    return Math.max(
      moment(this.trialEndDate)
        .startOf('day')
        .diff(moment(new Date()).startOf('day'), 'days'),
      0
    );
  }

  /**
   * Sales Representative
   */
  @computed
  get salesAccountType() {
    const { accountType, accountSubType } = this;

    switch (accountType) {
      case 'DEMO':
        return 'Demo';
      case 'DEVELOPER':
        return 'Developer';
      default:
        return _capitalize(accountSubType.toLowerCase());
    }
  }

  /**
   * Is checkAccount
   */
  @computed
  get checkAccount() {
    return this.billingPlatform === 'CHECK';
  }

  /**
   * Is checkAccount
   */
  @computed
  get inAppAccount() {
    return this.billingPlatform === 'IN_APP';
  }

  /**
   * Address String
   */
  @computed
  get addressString() {
    const { streetAddress, city, country } = this.company;

    return `${streetAddress}, ${city}, ${country}`;
  }
}

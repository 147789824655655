import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const policyDuplicateFormPlugins = extendedPlugins;

const policyDuplicateFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false,
  retrieveOnlyEnabledFields: false
};

const policyDuplicateFormFields = ['policyName'];

const policyDuplicateFormLabels = {
  policyName: t('policy name')
};

const policyDuplicateFormRules = {
  policyName: 'required|string'
};

const policyDuplicateFormValues = {
  policyName: ''
};

class PolicyDuplicateForm extends MobxReactForm {}

export {
  PolicyDuplicateForm,
  policyDuplicateFormOptions,
  policyDuplicateFormFields,
  policyDuplicateFormRules,
  policyDuplicateFormValues,
  policyDuplicateFormPlugins,
  policyDuplicateFormLabels
};

import MobxReactForm from 'mobx-react-form';

import extendedPlugins from './utils/extendedPlugins';

const PayPeriodFormPlugins = extendedPlugins;

const PayPeriodFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const PayPeriodFormFields = ['timeType', 'weekFirstDay', 'effectiveDate'];

const PayPeriodFormRules = {};

class PayPeriodForm extends MobxReactForm {}

export {
  PayPeriodForm,
  PayPeriodFormFields,
  PayPeriodFormRules,
  PayPeriodFormOptions,
  PayPeriodFormPlugins
};

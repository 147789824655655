import MobxReactForm from 'mobx-react-form';

import extendedPlugins from './utils/extendedPlugins';

const ApprovalsFormPlugins = extendedPlugins;

const ApprovalsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const ApprovalsFormFields = [
  'supervisorApprovals',
  'supervisorNotificationsTime',
  'supervisorNotificationsDayOfWeek',
  'supervisorNotificationsDayOfMonth',
  'employeeSignOff',
  'employeeSignOffTime',
  'employeeSignOffDayOfWeek',
  'employeeSignOffDayOfMonth',
  'signOffDisclaimer'
];

const ApprovalsFormRules = {};

class ApprovalsForm extends MobxReactForm {}

export {
  ApprovalsForm,
  ApprovalsFormFields,
  ApprovalsFormRules,
  ApprovalsFormOptions,
  ApprovalsFormPlugins
};

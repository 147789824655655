import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

import { t } from 'utils/translate';

const equipmentMaintenanceFormPlugins = extendedPlugins;

const equipmentMaintenanceFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentMaintenanceFormFields = [
  'type',
  'name',
  'step',
  'measurementType',
  'startingValue'
];

const equipmentMaintenanceFormRules = {
  type: 'string',
  name: 'string|required|max:1000000',
  step: 'numeric|required|max:1000000',
  measurementType: 'string',
  startingValue: 'numeric|required|max:1000000'
};

const equipmentMaintenanceFormLabels = {
  type: t('maintenance type'),
  name: t('maintenance name'),
  step: t('maintenance interval'),
  measurementType: t('maintenance interval type'),
  startingValue: t('start from')
};

class EquipmentMaintenanceForm extends MobxReactForm {}

export {
  EquipmentMaintenanceForm,
  equipmentMaintenanceFormRules,
  equipmentMaintenanceFormFields,
  equipmentMaintenanceFormOptions,
  equipmentMaintenanceFormLabels,
  equipmentMaintenanceFormPlugins
};

import React from 'react';
import { observer } from 'mobx-react';
import TermsOfServiceModal from './modals/TermsOfServiceModal';
import PersonalInfoModal from './modals/PersonalInfoModal';
import CompanyInfoModal from './modals/CompanyInfoModal';
import SuperDailyModal from './modals/SuperDaily';
import PoliciesNowAvailable from './modals/PoliciesNowAvailable';

export default observer(({ uiStore }) => {
  return (
    <div>
      <TermsOfServiceModal uiStore={uiStore} />

      <PersonalInfoModal uiStore={uiStore} />

      <CompanyInfoModal uiStore={uiStore} />

      <PoliciesNowAvailable uiStore={uiStore} />

      <SuperDailyModal uiStore={uiStore} />
    </div>
  );
});

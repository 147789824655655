import { action, computed } from 'mobx';
import ChecklistTemplateAddUI from './company/checklists/ChecklistTemplateAddUI.js';
import history from 'utils/history';

export default class TimeClockQuestionsTemplateAddUI extends ChecklistTemplateAddUI {
  constructor(options) {
    super(options);

    this.timeClockQuestionsLayout = true;
  }

  @action.bound
  cancelChecklistTemplateAdd() {
    history.push({
      pathname: `${this.parent.timesheetsPath}/time-clock-questions/templates`,
      search: this.baseQueryParams
    });
  }

  @action.bound
  startChecklistTemplate() {
    history.replace({
      pathname: `${this.timesheetsPath}/time-clock-questions/templates/start`,
      search: this.baseQueryParams
    });
  }

  @computed
  get timesheetsPath() {
    return '/review';
  }
}

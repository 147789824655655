import { Model } from 'mobx-mc';

export default class Reminder extends Model {
  get restAttributes() {
    return [
      'uuid',
      'timeFrameType',
      'emails',
      'reportName',
      'reminderOption',
      'questionsReminders',
      'photoIdReminders',
      'time',
      'startDate'
    ];
  }
}

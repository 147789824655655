import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';

import Certification from 'stores/models/Certification';
import CertificationInfoUI from './CertificationInfoUI';
import CertificationMembersUI from './CertificationMembersUI';

export default class CertificationEditUI extends UIStore {
  @observable entryForEdit;

  constructor(options) {
    super(options);

    this.entryForEdit = null;

    this.certificationInfoUI = new CertificationInfoUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationMembersUI = new CertificationMembersUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @action.bound async setup(id) {
    this.fetchEntry(id);
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.certifications.get(uuid);

      if (!model) {
        model = new Certification(
          {
            uuid: uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelCertificationEdit();
    }
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.activeModal = null;
    this.entryForEdit = null;
  }

  @action.bound
  cancelCertificationEdit() {
    history.push({
      pathname: `/company/certifications/certifications`,
      search: this.baseQueryParams
    });
  }

  @computed get tabs() {
    return [
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/info`,
          state: { previousPath: this.previousPath }
        },
        label: t('Info')
      },
      {
        path: {
          pathname: `${this.entryForEdit.viewUrl}/employees`,
          state: { previousPath: this.previousPath }
        },
        label: t('Employees')
      }
    ];
  }

  @computed get showUI() {
    return this.entryForEdit && !this.entryForEdit.fetching;
  }
}

import omit from 'lodash.omit';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';
import trimObject from 'utils/trimObject';

const userInfoFormPlugins = extendedPlugins;

const userInfoFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const userInfoFormFields = [
  'uuid', // Used in validation endpoint
  'companyUuid', // Used in validation endpoint
  'firstName',
  'lastName',
  'phone',
  'username',
  'role',
  'status',
  'ssoBypass'
];

const userInfoFormRules = {
  firstName: 'string|required|max:255',
  lastName: 'string|required|max:255',
  phone: 'string|phone_available|max:30',
  username: 'required|email'
};

const userInfoFormLabels = {
  firstName: t('First Name'),
  lastName: t('Last Name'),
  phone: t('Phone'),
  username: t('Email'),
  role: t('Member Role'),
  status: t('Active?'),
  ssoBypass: t('SSO Bypass')
};

class UserInfoForm extends MobxReactForm {
  trimmedValues() {
    return omit(trimObject(this.values()), ['companyUuid', 'uuid']);
  }
}

export {
  UserInfoForm,
  userInfoFormOptions,
  userInfoFormFields,
  userInfoFormRules,
  userInfoFormLabels,
  userInfoFormPlugins
};

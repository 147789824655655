import PolicyChildEditUI from './PolicyChildEditUI';
import { action, computed, observable } from 'mobx';

import {
  PayPeriodForm,
  PayPeriodFormFields,
  PayPeriodFormRules,
  PayPeriodFormOptions,
  PayPeriodFormPlugins
} from 'forms/payPeriod';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import SettingsSignOff from 'stores/models/SettingsSignOff';

import moment from 'moment-timezone';

export default class PayPeriodUI extends PolicyChildEditUI {
  @observable form;
  @observable saving;
  @observable loading;

  constructor(options) {
    super(options);

    this.form = null;
    this.saving = false;
    this.loading = false;

    this.settingsSignOff = new SettingsSignOff(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound async setup() {
    await this.fetchSettingsSignOff();
    this.initPayPeriodForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async fetchSettingsSignOff() {
    this.loading = true;

    try {
      const url = `${this.rootStore.urlMicroService(
        'performanceTracking'
      )}/signoff/rules`;

      await this.settingsSignOff.fetch({
        url
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  initPayPeriodForm() {
    this.form = new PayPeriodForm(
      {
        fields: PayPeriodFormFields,
        rules: PayPeriodFormRules,
        values: {
          timeType: this.settingsSignOff.timeType,
          weekFirstDay: this.settingsSignOff.weekFirstDay
        }
      },
      {
        options: PayPeriodFormOptions,
        plugins: PayPeriodFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.form = null;
  }

  @computed get timeTypeOptions() {
    return [
      { title: t('Daily'), value: 'DAILY' },
      { title: t('Weekly'), value: 'WEEKLY' },
      { title: t('Bi-weekly'), value: 'BI_WEEKLY' },
      { title: t('Monthly'), value: 'MONTHLY' }
    ];
  }

  @computed get timeTypeValue() {
    return this.timeTypeOptions.find(
      option => option.value === this.form?.$('timeType').value
    );
  }

  @computed get weekFirstDayOptions() {
    return [
      { title: t('Sunday'), value: 'SUNDAY' },
      { title: t('Monday'), value: 'MONDAY' },
      { title: t('Tuesday'), value: 'TUESDAY' },
      { title: t('Wednesday'), value: 'WEDNESDAY' },
      { title: t('Thursday'), value: 'THURSDAY' },
      { title: t('Friday'), value: 'FRIDAY' },
      { title: t('Saturday'), value: 'SATURDAY' }
    ];
  }

  @computed get weekFirstDayValue() {
    return this.weekFirstDayOptions.find(
      option => option.value === this.form?.$('weekFirstDay').value
    );
  }

  get weekFirstDayIndex() {
    const index = this.weekFirstDayOptions.findIndex(
      option => option.value === this.form?.$('weekFirstDay').value
    );
    return index !== -1 ? index : 0;
  }

  @action.bound submitPayPeriodForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitPayPeriodFormSuccess,
      onError: this.submitPayPeriodFormError
    });
  }

  @action.bound submitPayPeriodFormError() {
    console.log(this.form.errors());
  }

  @action.bound
  async submitPayPeriodFormSuccess() {
    const formValues = this.form.values();
    this.saving = true;

    const data = {
      ...this.settingsSignOff.apiValues,
      ...{
        timeType: formValues.timeType,
        weekFirstDay: formValues.weekFirstDay,
        fromDate: formValues.effectiveDate
      }
    };

    if (data.scheduleData) {
      /**
       * TODO look to tidy this up once we roll out policies.
       * The code in SettingsSignOff.js can be refactored as
       * the pay period and approvals screens witll get their own models.
       */
      data.scheduleData.actionDayOfWeek =
        this.weekFirstDayIndex === 0 ? 7 : this.weekFirstDayIndex;

      data.scheduleData.actionDayOfMonth = this.settingsSignOff.scheduleData.actionDayOfMonth;

      data.approvalScheduleData.actionDayOfMonth = this.settingsSignOff.approvalScheduleData?.actionDayOfMonth;
      data.approvalScheduleData.actionDayOfWeek = this.settingsSignOff.approvalScheduleData?.actionDayOfWeek;
    }

    try {
      await this.settingsSignOff.save(data, {
        reset: true
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.initPayPeriodForm();
    this.saving = false;

    this.rootStore.notificationsUI.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: `${t('Pay period updated')}`
    });
  }

  @computed get saveButtonDisabled() {
    return this.entryForEdit.saving;
  }

  @computed get effectiveDateOptions() {
    if (this.form?.$('timeType').value === 'DAILY') {
      return Array.from({ length: 8 }, (_, i) => {
        const date = moment().add(i, 'days');
        return {
          title: date.format('ddd, MMM DD, YYYY'),
          value: date.format('YYYY-MM-DD')
        };
      });
    }

    if (this.form?.$('timeType').value === 'WEEKLY') {
      return Array.from({ length: 4 }, (_, i) => {
        const date = moment()
          .startOf('day')
          .add(i, 'weeks');

        const currentDayOfWeek = date.day();

        if (this.weekFirstDayIndex <= currentDayOfWeek) {
          date.add(1, 'week'); // Move to next week's target day if it's already passed this week
        }

        date.day(this.weekFirstDayIndex);

        return {
          title: date.format('ddd, MMM DD, YYYY'),
          value: date.format('YYYY-MM-DD')
        };
      });
    }

    if (this.form?.$('timeType').value === 'BI_WEEKLY') {
      return Array.from({ length: 4 }, (_, i) => {
        const date = moment()
          .startOf('day')
          .add(i * 2, 'weeks');

        const currentDayOfWeek = date.day();

        if (this.weekFirstDayIndex <= currentDayOfWeek) {
          date.add(1, 'week'); // Move to next week's target day if it's already passed this week
        }

        date.day(this.weekFirstDayIndex);

        return {
          title: date.format('ddd, MMM DD, YYYY'),
          value: date.format('YYYY-MM-DD')
        };
      });
    }

    if (this.form?.$('timeType').value === 'MONTHLY') {
      return Array.from({ length: 4 }, (_, i) => {
        const date = moment()
          .startOf('day')
          .add(i, 'months');

        const currentDayOfWeek = date.day();

        if (this.weekFirstDayIndex <= currentDayOfWeek) {
          date.add(1, 'week'); // Move to next week's target day if it's already passed this week
        }

        date.day(this.weekFirstDayIndex);

        return {
          title: date.format('ddd, MMM DD, YYYY'), // Format the title
          value: date.format('YYYY-MM-DD') // Format the value
        };
      });
    }

    return [];
  }

  @computed get effectiveDateValue() {
    const date = this.effectiveDateOptions.find(
      option => option.value === this.form?.$('effectiveDate').value
    );

    return date ? date : this.effectiveDateOptions[0];
  }
}

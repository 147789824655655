import once from 'lodash.once';
import omit from 'lodash.omit';
import { action, observable, computed } from 'mobx';
import UIStore from './UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';

import alertErrorHandler from 'utils/alertErrorHandler';
import JobTitles from '../collections/JobTitles';
import isDataURL from 'utils/isDataURL';

import { getMemberRoles } from 'utils/roles';

import {
  JobTitleForm,
  jobTitleFormValues,
  jobTitleFormRules,
  jobTitleFormFields,
  jobTitleFormOptions,
  jobTitleFormPlugins
} from 'forms/jobTitle';

import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';

export default class TeamMemberInfoUI extends UIStore {
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.entryEditForm = null;

    this.jobTitles = new JobTitles(null, {
      rootStore: this.rootStore
    });

    this.fetchJobTitlesOnce = once(this.fetchJobTitles);

    this.fetchClassificationsOnce = once(
      this.classificationsUI.fetchClassifications
    );
  }

  @action.bound fetchJobTitles() {
    this.jobTitles.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    this.fetchClassificationsOnce();
    this.groupSelectorUI.setup();
    this.fetchJobTitlesOnce();
    this.setEntryEditForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.groupSelectorUI.tearDown();
    this.unblockHistory();
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: Object.assign(memberFormRules, {
          username: 'email|required|max:200'
        }),
        labels: memberFormLabels,
        values: Object.assign(this.entryForEdit.formValues, {
          companyUuid: this.company.uuid
        }),
        options: memberFormFieldOptions,
        disabled: {
          workerType: true,
          id: true
        }
      },
      {
        options: Object.assign(memberFormOptions, {
          retrieveOnlyEnabledFields: true
        }),
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.entryEditForm = null;
  }

  @computed get roleOptions() {
    return getMemberRoles();
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }

  @computed get jobTitleOptions() {
    return this.jobTitles.models.map(jobTitle => {
      return {
        value: jobTitle.name,
        name: jobTitle.name
      };
    });
  }

  @computed
  get canEditProfileInfo() {
    return (
      (this.me.isAdmin && this.entryForEdit.isTeamMember) ||
      this.entryForEdit.isMe
    );
  }

  @computed
  get canEditProfileRole() {
    return (
      this.me.isAccountAdmin &&
      !this.entryForEdit.isNew &&
      !this.entryForEdit.isMe
    );
  }

  @computed
  get canEditProfileSSO() {
    return (
      this.me.isAccountAdmin &&
      this.entryForEdit.isTeamMember &&
      !this.entryForEdit.invitedOrPending &&
      this.entryForEdit.status !== 'INACTIVE' &&
      this.entryForEdit.status !== 'DELETED'
    );
  }

  @computed
  get canViewProfileSSO() {
    return this.authorization.onPerformancePlan;
  }

  @computed
  get canEditProfileClassification() {
    return this.me.isAdmin && this.entryForEdit.isTeamMember;
  }

  @computed
  get canEditProfileGroups() {
    return this.me.isAdmin && this.entryForEdit.isTeamMember;
  }

  @computed get disableSaveButton() {
    return this.saving || !this.canEditProfileInfo;
  }

  @computed get disableCancelButton() {
    return this.saving || !this.canEditProfileInfo;
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @action.bound submitEntryEditForm(event) {
    event.preventDefault();

    if (this.entryEditForm.submitting) return;

    this.entryEditForm.submit({
      onSuccess: this.submitEntryEditFormSuccess,
      onError: this.submitEntryEditFormError
    });
  }

  @action.bound async submitEntryEditFormSuccess() {
    if (this.saving) return;

    this.clearValidationDetails();

    this.saving = true;

    const values = this.entryEditForm.trimmedValues();

    if (isDataURL(values.avatar)) {
      values.avatar = {
        base64: values.avatar
      };
    } else {
      delete values.avatar;
    }

    values.groupUuids = values.groups.map(group => group.uuid);

    delete values.groups;

    try {
      await this.entryForEdit.save(
        omit(values, [
          'username',
          'company',
          'workerUuid',
          'defaultShift',
          'defaultCostCode',
          'defaultCrewName'
        ]),
        {
          wait: true,
          stripNonRest: false
        }
      );

      if (this.entryForEdit.isMe) {
        this.rootStore.me.fetch({
          url: `ra/user`,
          reset: true
        });
      }

      this.resetEntryEditForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Team member info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEntryEditFormError() {
    console.error(this.entryEditForm.errors());
  }

  @action.bound
  initJobTitleForm() {
    this.jobTitleForm = new JobTitleForm(
      {
        fields: jobTitleFormFields,
        rules: jobTitleFormRules,
        values: jobTitleFormValues
      },
      {
        options: jobTitleFormOptions,
        plugins: jobTitleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  showAddNewJobTitleModal() {
    this.initJobTitleForm();
    this.showModal('NewJobTitle');
  }

  @action.bound
  async hideAddNewJobTitleModal() {
    await this.hideActiveModal();
    this.jobTitleForm = null;
  }

  @action.bound
  async submitAddNewJobTitleSuccess() {
    const payload = this.jobTitleForm.values();
    try {
      const newJobTitle = await this.jobTitles.create(payload, {
        wait: true
      });
      this.entryEditForm.update({
        title: newJobTitle.name
      });
      this.hideAddNewJobTitleModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  submitAddNewJobTitleForm(e) {
    e.preventDefault();
    this.jobTitleForm.submit({
      onSuccess: this.submitAddNewJobTitleSuccess,
      onError: e => {
        console.error(this.jobTitleForm.errors());
      }
    });
  }

  @computed
  get disableSaveNewJobTitleButton() {
    return this.jobTitles.saving || this.jobTitleForm.hasError;
  }

  @computed get selectedGroupUuids() {
    return this.entryEditForm
      .$('groups')
      .values()
      .map(value => value.uuid);
  }

  @computed get selectedGroupOptions() {
    return this.groupSelectorUI.options.filter(option =>
      this.selectedGroupUuids.includes(option.uuid)
    );
  }
}

import { t } from 'utils/translate';

const members = [
  {
    value: 'ROLE_ACCOUNT_ADMIN',
    name: t('Account admin')
  },
  {
    value: 'ROLE_ADMIN',
    name: t('Project admin')
  },
  {
    value: 'ROLE_PROJECT_MEMBER',
    name: t('Project member')
  },
  {
    value: 'ROLE_USER',
    name: t('View only')
  },
  {
    value: 'ROLE_PROJECT_WORKER',
    name: t('Project worker')
  }
];
export const getMemberRoles = () => members;

export const getAllRoles = () => [
  ...members,
  {
    value: 'ROLE_WORKER',
    name: t('Employee contact')
  }
];

export const getRoleName = role => {
  return getAllRoles().find(o => o.value === role)?.name;
};

import { RakenCollection } from 'stores/lib';

import SmallEquipmentProject from 'stores/models/equipment/SmallEquipmentProject';

export default class SmallEquipmentProjects extends RakenCollection {
  model() {
    return SmallEquipmentProject;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/smallequipment/${
      this.parent.entryForEdit.uuid
    }/deployed`;
  }
}

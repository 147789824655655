import { t } from 'utils/translate';
import { insertIf } from 'utils/insertIf';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

export default class DashboardNavigationUI extends UIStore {
  @computed get items() {
    return {
      id: 'dashboard',
      icon: 'dashboard',
      label: t('Dashboard'),
      link: '/',
      subnav: [
        {
          id: 'activity',
          label: t('Activity'),
          icon: 'activity',
          link: '/',
          exact: true
        },
        {
          id: 'reports',
          label: t('Reports'),
          icon: 'reports',
          link: '/report-activity'
        },
        {
          id: 'insights',
          label: t('Insights'),
          icon: 'insights',
          links: [
            {
              label: t('Daily logs'),
              path: {
                pathname: `/insights/daily-logs`
              }
            },
            {
              label: t('Safety'),
              path: {
                pathname: `/insights/safety`
              }
            },
            {
              label: t('Quality control'),
              path: {
                pathname: `/insights/quality-control`
              }
            },
            {
              label: t('Tasks'),
              path: {
                pathname: `/insights/tasks`
              }
            }
          ]
        },
        ...insertIf(!this.authorization.disableIntegrationsForTrialAccount, [
          {
            id: 'live',
            label: t('Live views'),
            icon: 'live-view',
            link: '/live-views'
          }
        ])
      ]
    };
  }
}

import { observable, action, computed, toJS } from 'mobx';
import moment from 'moment';
import omit from 'lodash.omit';
import isEmpty from 'lodash.isempty';
import pickBy from 'lodash.pickby';
import pick from 'lodash.pick';
import isEqual from 'lodash.isequal';
import errorHandler from 'utils/errorHandler';

import UIStore from './UIStore';
import CompanyCostCodesUI from './CompanyCostCodesUI';
import CompanyShiftsUI from './CompanyShiftsUI';
import CompanyOverTimeRulesUI from './CompanyOverTimeRulesUI';
import CompanyBreaksUI from './CompanyBreaksUI';
import CompanyPayTypesUI from './CompanyPayTypesUI';
import CompanyMaterialsUI from './CompanyMaterialsUI';
import CompanyClassificationsUI from './CompanyClassificationsUI';
import SettingsSignOff from './../models/SettingsSignOff';

import { t } from 'utils/translate';
import history from 'utils/history';
import formValueNotEmpty from 'utils/formValueNotEmpty';
import alertErrorHandler from 'utils/alertErrorHandler';

import {
  CompanyTimeCardsForm,
  companyTimeCardsFormOptions,
  companyTimeCardsFormFields,
  companyTimeCardsFormRules,
  companyTimeCardsFormPlugins,
  companyTimeCardsFormDisabled,
  companyTimeCardsFormLabels,
  companyTimeCardsFormValues
} from 'forms/companyTimeCards';

import {
  CompanyTimeCardsSignOffForm,
  companyTimeCardsSignOffFormOptions,
  companyTimeCardsSignOffFormFields,
  companyTimeCardsSignOffFormRules,
  companyTimeCardsSignOffFormPlugins,
  companyTimeCardsSignOffFormDisabled,
  companyTimeCardsSignOffFormLabels,
  companyTimeCardsSignOffFormValues
} from 'forms/companyTimeCardsSignOff';

import { TRACK_TIME_ADDED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

const trackTimeSettingsKeys = ['timeCardsStartAndEndTime'];

export default class CompanyProductionUI extends UIStore {
  @observable companyTimeCardsForm;
  @observable companyTimeCardsSignOffForm;
  @observable companyTimeCardsFormInitialValues;
  @observable tab;
  @observable nextTimeType;

  @observable savingCompanyTimeCardsForm;
  @observable savingCompanyTimeCardsSignOffForm;

  @observable exitModalShown;
  @observable nextURL;

  constructor(options) {
    super(options);

    this.companyClassificationsUI = new CompanyClassificationsUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.companyCostCodesUI = new CompanyCostCodesUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.companyShiftsUI = new CompanyShiftsUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.companyOverTimeRulesUI = new CompanyOverTimeRulesUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.companyBreaksUI = new CompanyBreaksUI({
      rootStore: this.rootStore,
      companyTimeCardsUI: this
    });

    this.companyPayTypesUI = new CompanyPayTypesUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.companyMaterialsUI = new CompanyMaterialsUI({
      rootStore: this.rootStore,
      companyProductionUI: this
    });

    this.settingsSignOff = new SettingsSignOff(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.companyTimeCardsForm = null;
    this.companyTimeCardsSignOffForm = null;
    this.companyTimeCardsFormInitialValues = null;
    this.savingCompanyTimeCardsForm = false;
    this.savingCompanyTimeCardsSignOffForm = false;

    this.tab = 'settings';
    this.nextTimeType = null;

    this.exitModalShown = false;
    this.nextURL = null;
  }

  @computed get hasActiveModal() {
    return this.activeModal;
  }

  @action.bound switchTabByLocation(tab = 'settings') {
    this.tab = tab;
  }

  @action.bound
  fetchSignOffProcess() {
    const url = `${this.rootStore.appConfig.tracking_api_url}/signoff/rules`;

    return this.settingsSignOff.fetch({
      url
    });
  }

  @action.bound
  initTimeCardsForm() {
    const weekFirstDay = this.company.preferences.weekFirstDay - 1; // 0 - 6 for momentjs

    this.companyTimeCardsFormInitialValues = Object.assign(
      {},
      companyTimeCardsFormValues,
      this.company.preferences,
      this.company.timeCardsFormValues,
      {
        weekFirstDay: weekFirstDay
      }
    );

    this.companyTimeCardsForm = new CompanyTimeCardsForm(
      {
        fields: companyTimeCardsFormFields,
        rules: companyTimeCardsFormRules,
        disabled: companyTimeCardsFormDisabled,
        labels: companyTimeCardsFormLabels,
        values: toJS(this.companyTimeCardsFormInitialValues)
      },
      {
        options: companyTimeCardsFormOptions,
        plugins: companyTimeCardsFormPlugins,
        parent: this
      }
    );
  }

  @action.bound
  initTimeCardsSignOffForm() {
    this.companyTimeCardsSignOffFormInitialValues = Object.assign(
      {},
      {
        ...companyTimeCardsSignOffFormValues
      },
      this.settingsSignOff.formValues
    );

    this.companyTimeCardsSignOffForm = new CompanyTimeCardsSignOffForm(
      {
        fields: companyTimeCardsSignOffFormFields,
        rules: companyTimeCardsSignOffFormRules,
        disabled: companyTimeCardsSignOffFormDisabled,
        labels: companyTimeCardsSignOffFormLabels,
        values: this.companyTimeCardsSignOffFormInitialValues
      },
      {
        options: companyTimeCardsSignOffFormOptions,
        plugins: companyTimeCardsSignOffFormPlugins
      }
    );
  }

  @computed
  get timeCardsWorkWeekEnd() {
    const value = this.companyTimeCardsForm.$('weekFirstDay').value;

    return moment()
      .day(value)
      .subtract(8, 'days')
      .day();
  }

  @action.bound
  async showTimeCardsForms() {
    try {
      const promises = [this.fetchSignOffProcess()];
      await Promise.all(promises);

      this.initTimeCardsForm();
      this.initTimeCardsSignOffForm();
      this.blockHistoryIfFormsChanged();
    } catch (error) {
      this.initTimeCardsForm();
      this.initTimeCardsSignOffForm();
      this.blockHistoryIfFormsChanged();
    }
  }

  @action.bound blockHistoryIfFormsChanged() {
    this.unblock = history.block((location, action) => {
      if (
        this.companyTimeCardsForm.check('isDirty') ||
        this.companyTimeCardsSignOffForm.check('isDirty')
      ) {
        this.showExitModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed
  get formIsValid() {
    return Boolean(
      (this.companyTimeCardsForm &&
        this.companyTimeCardsForm.check('isValid')) ||
        (this.companyTimeCardsSignOffForm &&
          this.companyTimeCardsSignOffForm.check('isValid'))
    );
  }

  @action.bound
  fetchClassifications() {
    return this.rootStore.classifications.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @action.bound
  cancelChanges(e, type) {
    e.preventDefault();

    if (type === 'signoff') {
      this.companyTimeCardsSignOffForm.reset();
      this.companyTimeCardsSignOffForm.each(field => field.resetValidation());
    } else {
      this.companyTimeCardsForm.reset();
      this.companyTimeCardsForm.each(field => field.resetValidation());
    }
  }

  @action.bound
  submitDirtyForms(e) {
    e.preventDefault();

    if (this.companyTimeCardsForm.check('isDirty')) {
      this.submitCompanyTimeCardsForm(e);
    }

    if (this.companyTimeCardsSignOffForm.check('isDirty')) {
      this.submitCompanyTimeCardsSignOffForm(e);
    }
  }

  @action.bound
  submitCompanyTimeCardsForm(e) {
    e.preventDefault();

    this.companyTimeCardsForm.submit({
      onSuccess: () => {
        this.submitCompanyTimeCardsFormSuccess();
      },
      onError: () => {
        console.log(this.companyTimeCardsForm.errors());
      }
    });
  }

  @computed get timeTrackingSettings() {
    return ['timeCardsStartAndEndTime'];
  }

  checkIfTimeTrackingChanged(values) {
    let changed = false;

    this.timeTrackingSettings.forEach(setting => {
      if (this.companyTimeCardsForm.$(setting).check('isDirty')) {
        changed = true;
      }
    });

    return changed;
  }

  @action.bound async cancelApplyTimeTracking() {
    await this.hideActiveModal();

    this.initTimeCardsForm();
    this.projectSelectorUI.resetProjectOptions();
  }

  @action.bound async confirmApplyTimeTracking() {
    await this.hideActiveModal();

    this.projectSelectorUI.resetProjectOptions();

    this.submitCompanyTimeCardsWithTracking();
  }

  @action.bound
  async submitCompanyTimeCardsFormSuccess() {
    const timeTrackingChanged = this.checkIfTimeTrackingChanged();

    if (timeTrackingChanged) {
      this.showModal('applyTimeTracking');
      this.projectSelectorUI.fetchProjectOptions();
    } else {
      this.submitCompanyTimeCardsWithoutTracking();
    }
  }

  @action.bound async submitCompanyTimeCardsWithoutTracking() {
    const formValues = this.companyTimeCardsForm.values();

    if (formValues.weekFirstDay !== undefined) {
      // revert format 1-7 for backend
      formValues.weekFirstDay = formValues.weekFirstDay + 1;
    }

    const values = {
      preferences: omit(pickBy(formValues, value => formValueNotEmpty(value)), [
        ...this.timeTrackingSettings,
        'applyTo',
        'projects'
      ])
    };

    if (isEmpty(values.preferences)) return;

    this.saveCompanyPreferences(values);
  }

  @action.bound async submitCompanyTimeCardsWithTracking() {
    const formValues = this.companyTimeCardsForm.values();

    let values = {
      applyTo: formValues.applyTo
    };

    if (formValues.weekFirstDay !== undefined) {
      // revert format 1-7 for backend
      formValues.weekFirstDay = formValues.weekFirstDay + 1;
    }

    if (formValues.projects.length > 0) {
      values.projectIds = formValues.projects.map(project => project.id);
    }

    values.preferences = omit(
      pickBy(formValues, value => formValueNotEmpty(value)),
      [
        'fahrenheit',
        'primaryColor',
        'languageId',
        'secondaryColor',
        'registration_completed',
        'projects',
        'projectIds',
        'applyTo'
      ]
    );

    await this.saveCompanyPreferences(values);
  }

  @action.bound async saveCompanyPreferences(values) {
    this.savingCompanyTimeCardsForm = true;

    try {
      await this.company
        .save(values, {
          wait: true,
          url: this.company.url() + '/preferences'
        })
        .then(() => {
          const pref = values.preferences;

          if (pref.timeCardsStartAndEndTime) {
            callTrack(TRACK_TIME_ADDED, {
              company_level: true
            });
          }
        });

      this.handleSaveTimecardsFormSuccess();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      return;
    } finally {
      this.savingCompanyTimeCardsForm = false;
    }
  }

  @action.bound
  async handleSaveTimecardsFormSuccess() {
    this.initTimeCardsForm();

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: t('Time Card Settings Updated')
    });

    if (this.nextURL && !this.savingCompanyTimeCardsSignOffForm) {
      this.moveToNextURL();
    }
  }

  @action.bound
  submitCompanyTimeCardsSignOffForm(e) {
    e.preventDefault();

    this.companyTimeCardsSignOffForm.submit({
      onSuccess: () => {
        this.submitCompanyTimeCardsSignOffFormSuccess();
      },
      onError: () => {
        console.log(this.companyTimeCardsSignOffForm.errors());
      }
    });
  }

  @action.bound
  async submitCompanyTimeCardsSignOffFormSuccess() {
    this.savingCompanyTimeCardsSignOffForm = true;

    const formValues = this.companyTimeCardsSignOffForm.values();

    // replace format
    if (formValues.scheduleData.firstDueDate) {
      formValues.scheduleData.firstDueDate = moment(
        formValues.scheduleData.firstDueDate,
        'dd, MMM DD, YYYY'
      )
        .locale('en')
        .format('YYYY-MM-DD');
    }

    if (formValues.approvalScheduleData?.firstDueDate) {
      formValues.approvalScheduleData.firstDueDate = moment(
        formValues.approvalScheduleData.firstDueDate,
        'dd, MMM DD, YYYY'
      )
        .locale('en')
        .format('YYYY-MM-DD');
    }

    formValues.weekFirstDay = moment()
      .locale('en')
      .weekday(this.companyTimeCardsForm.$('weekFirstDay').value + 1) // 0 - 6 for momentjs to server format 1-7
      .format('dddd')
      .toUpperCase();

    if (formValues.scheduleData) {
      // revert 1 - 7 for backend
      formValues.scheduleData.actionDayOfWeek =
        formValues.scheduleData.actionDayOfWeek === 6
          ? 1
          : formValues.scheduleData.actionDayOfWeek + 2;

      formValues.scheduleData.actionDayOfMonth =
        formValues.scheduleData.actionDayOfMonth + 1;
    }

    if (formValues.approvalScheduleData) {
      // revert 1 - 7 for backend
      formValues.approvalScheduleData.actionDayOfWeek =
        formValues.approvalScheduleData.actionDayOfWeek === 6
          ? 1
          : formValues.approvalScheduleData.actionDayOfWeek + 2;

      formValues.approvalScheduleData.actionDayOfMonth =
        formValues.approvalScheduleData.actionDayOfMonth + 1;
    }

    try {
      await this.settingsSignOff.save(formValues, {
        reset: true
      });
    } catch (error) {
      this.handleSaveTimeCardsSignOffFormError(error);
      return;
    }

    this.handleSaveTimeCardsSignOffFormSuccess();
  }

  @action.bound
  handleSaveTimeCardsSignOffFormError(error) {
    errorHandler(error, this.notifications.pushError);
    this.savingCompanyTimeCardsSignOffForm = false;
  }

  @action.bound
  handleSaveTimeCardsSignOffFormSuccess() {
    this.initTimeCardsSignOffForm();
    this.savingCompanyTimeCardsSignOffForm = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'notification',
      title: t('Time Card Approvals Settings Updated')
    });

    if (this.nextURL && !this.savingCompanyTimeCardsForm && !this.activeModal) {
      this.moveToNextURL();
    }
  }

  @computed
  get configDateSignOff() {
    const currentDay = moment();
    const enabledDaysConfig = {};

    for (let i = 0; i < 18; i++) {
      const nextDay = currentDay.clone().add(i, 'days');

      enabledDaysConfig[nextDay.format('M/D/YYYY')] = {
        color: 'lightGrey20'
      };
    }

    return enabledDaysConfig;
  }

  @action.bound
  handleChangeStartOfWeek(value) {
    this.companyTimeCardsForm.$('weekFirstDay').set(value);
    this.companyTimeCardsSignOffForm.set({
      'scheduleData.actionDayOfWeek': value
    });

    if (this.companyTimeCardsSignOffForm.$('scheduleData.firstDueDate').value) {
      this.companyTimeCardsSignOffForm.set({
        'scheduleData.firstDueDate': moment(
          this.companyTimeCardsSignOffForm.$('scheduleData.firstDueDate').value,
          'dd, MMM DD, YYYY'
        )
          .isoWeekday(value)
          .add(1, 'days')
          .format('dd, MMM DD, YYYY')
      });
    }
  }

  @action.bound
  handleChangeEndOfWeek(value) {
    const startDay = moment()
      .day(value)
      .add(8, 'days')
      .day();
    this.companyTimeCardsForm.$('weekFirstDay').set(startDay);

    if (this.companyTimeCardsSignOffForm.$('scheduleData.firstDueDate').value) {
      this.companyTimeCardsSignOffForm.set({
        'scheduleData.firstDueDate': moment(
          this.companyTimeCardsSignOffForm.$('scheduleData.firstDueDate').value,
          'dd, MMM DD, YYYY'
        )
          .isoWeekday(startDay)
          .add(1, 'days')
          .format('dd, MMM DD, YYYY')
      });
    }
  }

  @action.bound
  showSignOfConfirmModal(timeType) {
    this.showModal('signOffTimeTypeConfirm');

    this.nextTimeType = timeType;
  }

  @action.bound
  handleConfirm() {
    this.hideActiveModal().then(() => {
      this.companyTimeCardsSignOffForm.update({
        timeType: this.nextTimeType
      });

      this.companyTimeCardsSignOffForm.initValidationForTimeType();
    });
  }

  @action.bound
  handleTimeTypeText(timeType) {
    switch (timeType) {
      case 'DAILY':
        return t('Daily');
      case 'WEEKLY':
        return t('Weekly');
      case 'BI_WEEKLY':
        return t('Bi-Weekly');
      case 'MONTHLY':
        return t('Monthly');
      default:
        return '';
    }
  }

  @action.bound
  handleToggleLastDayNotification(toggle) {
    this.companyTimeCardsSignOffForm.set({
      'scheduleData.lastDayNotification': toggle
    });
    this.companyTimeCardsSignOffForm
      .$('scheduleData.firstDueDate')
      .set('rules', toggle ? 'string' : 'string|required');
    this.companyTimeCardsSignOffForm.validate();
  }

  @action.bound
  showExitModal(nextURL) {
    this.exitModalShown = true;
    this.nextURL = nextURL;
  }

  @action.bound
  cancelExitModal() {
    this.exitModalShown = false;
    this.nextURL = null;
  }

  @action.bound
  discardExitModal() {
    this.exitModalShown = false;
    this.moveToNextURL();
  }

  @action.bound
  saveExitModal(e) {
    this.exitModalShown = false;
    this.submitDirtyForms(e);
  }

  @action.bound moveToNextURL() {
    this.unblock();
    history.push(this.nextURL);
    this.nextURL = null;
  }

  @action.bound
  handleToggleApprovalLastDayNotification(toggle) {
    this.companyTimeCardsSignOffForm.set({
      'approvalScheduleData.lastDayNotification': toggle
    });
    this.companyTimeCardsSignOffForm
      .$('approvalScheduleData.firstDueDate')
      .set('rules', toggle ? 'string' : 'string|required');
    this.companyTimeCardsSignOffForm.validate();
  }

  @computed
  get trackTimeSettings() {
    if (this.companyTimeCardsForm) {
      return pick(this.companyTimeCardsForm.values(), trackTimeSettingsKeys);
    }
    return null;
  }

  @computed
  get initialTrackTimeSettings() {
    if (this.companyTimeCardsFormInitialValues) {
      return pick(
        this.companyTimeCardsFormInitialValues,
        trackTimeSettingsKeys
      );
    }
    return null;
  }

  @computed
  get updatedTrackTimeSettings() {
    return !isEqual(this.trackTimeSettings, this.initialTrackTimeSettings);
  }

  @computed get employeeHoursTrackingOptions() {
    return [
      {
        id: 'assignedWorkersAndAllTeamMembers',
        name: t('Assigned workers and all team members')
      },
      {
        id: 'assignedWorkersAndAssignedTeamMembers',
        name: t('Assigned workers and assigned team members')
      },
      {
        id: 'assignedWorkersOnly',
        name: t('Assigned workers only')
      }
    ];
  }
}

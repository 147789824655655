import { action, computed, observable, reaction } from 'mobx';
import UIStore from 'stores/ui/UIStore';
import { matchPath } from 'react-router';
import DashboardNavigationUI from './DashboardNavigationUI';
import ProjectNavigationUI from './ProjectNavigationUI';
import DirectoryNavigationUI from './DirectoryNavigationUI';
import TeamNavigationUI from './TeamNavigationUI';
import TimeNavigationUI from './TimeNavigationUI';
import MessagingNavigationUI from './MessagingNavigationUI';
import CompanyNavigationUI from './CompanyNavigationUI';
import ProjectSelectorUI from '../ProjectSelectorUI';

import history from 'utils/history';

export default class NavigationUI extends UIStore {
  @observable location;
  @observable isSidebarOpen;
  @observable hasUserToggled;

  @observable isSmallScreen;
  @observable isMobileScreen;

  @observable userMenuAnchorElement;

  constructor(options) {
    super(options);

    this.location = null;
    this.hasUserToggled = false;

    this.smallScreenMediaQueryListener = window.matchMedia(
      '(max-width: 1281px)'
    );

    this.mobileScreenMediaQueryListener = window.matchMedia(
      '(max-width: 768px)'
    );

    this.isSmallScreen = this.smallScreenMediaQueryListener?.matches;
    this.isMobileScreen = this.mobileScreenMediaQueryListener?.matches;

    this.isSidebarOpen = !this.isSmallScreen;

    this.userMenuAnchorElement = null;

    this.expandedPanels = observable([]);

    this.dashboardNavigationUI = new DashboardNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectNavigationUI = new ProjectNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamNavigationUI = new TeamNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.directoryNavigationUI = new DirectoryNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.timeNavigationUI = new TimeNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.messagingNavigationUI = new MessagingNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.companyNavigationUI = new CompanyNavigationUI({
      parent: this,
      rootStore: this.rootStore
    });

    // New project selector UI specific to the header
    this.navigationProjectSelectorUI = new ProjectSelectorUI({
      projectStates: ['ACTIVE'],
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get projectSelectorUI() {
    return this.navigationProjectSelectorUI;
  }

  @action.bound
  setup() {
    if (!this.hasUserToggled) {
      this.isSidebarOpen = !this.isSmallScreen;
    }

    if (this.company?.uuid) {
      this.projectSelectorUI.setup({
        projectStates: ['ACTIVE']
      });
    }

    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.projectSelectorUI.tearDown();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.userMenuAnchorElement = null;
    this.isSmallScreen = null;
    this.isMobileScreen = null;
  }

  @action.bound setupReactions() {
    this.smallScreenMediaQueryListener.addEventListener(
      'change',
      this.handleSmallScreenMediaQueryChange
    );

    this.mobileScreenMediaQueryListener.addEventListener(
      'change',
      this.handleMobileScreenMediaQueryChange
    );

    this.reactToSubNavItem = reaction(
      () => this.activeItem?.subnav.find(item => item.isActive),
      activeSubitem => {
        if (
          activeSubitem &&
          activeSubitem.links &&
          !this.expandedPanels.includes(activeSubitem.id)
        ) {
          this.expandedPanels.push(activeSubitem.id);
        }
      },
      {
        fireImmediately: true
      }
    );

    this.reactToIsMobileCompany = reaction(
      () => this.isMobileCompany,
      () => {
        if (this.isMobileCompany) {
          history.replace('/company/info');
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  @computed get isMobileCompany() {
    return this.isMobileScreen && this.location && !this.activeItem;
  }

  @action.bound tearDownReactions() {
    this.smallScreenMediaQueryListener.removeEventListener(
      'change',
      this.handleSmallScreenMediaQueryChange
    );

    this.mobileScreenMediaQueryListener.removeEventListener(
      'change',
      this.handleMobileScreenMediaQueryChange
    );

    this.reactToSubNavItem && this.reactToSubNavItem();
    this.reactToIsMobileCompany && this.reactToIsMobileCompany();
  }

  @action.bound toggleUserMenu(event) {
    if (this.userMenuAnchorElement) {
      this.userMenuAnchorElement = null;
    } else {
      this.userMenuAnchorElement = event.currentTarget;
    }
  }

  @action.bound closeUserMenu() {
    this.userMenuAnchorElement = null;
  }

  @action.bound setExpandedPanels(panel) {
    if (this.expandedPanels.includes(panel)) {
      this.expandedPanels.remove(panel);
    } else {
      this.expandedPanels.push(panel);
    }
  }

  @action.bound
  handleSmallScreenMediaQueryChange = event => {
    const wasSmallScreen = this.isSmallScreen;
    this.isSmallScreen = event.matches;

    if (!this.hasUserToggled && this.isSmallScreen !== wasSmallScreen) {
      this.isSidebarOpen = !this.isSmallScreen;
    }

    if (!this.isSmallScreen) {
      this.hasUserToggled = false;
    }
  };

  @action.bound
  handleMobileScreenMediaQueryChange = event => {
    this.isMobileScreen = event.matches;
  };

  @computed
  get hasProject() {
    return !!this.projectUI?.project;
  }

  @computed
  get navItems() {
    let items = [
      this.dashboardNavigationUI.items,
      this.projectNavigationUI.items,
      this.timeNavigationUI.items,
      this.messagingNavigationUI.items
    ];

    if (
      this.authorization.canViewTeam &&
      !this.featureFlags.FF_COMPANY_CONTACTS
    ) {
      items = items
        .slice(0, 2)
        .concat(this.teamNavigationUI.items, items.slice(2));
    }

    if (
      this.authorization.canViewTeam &&
      this.featureFlags.FF_COMPANY_CONTACTS
    ) {
      items = items
        .slice(0, 2)
        .concat(this.directoryNavigationUI.items, items.slice(2));
    }

    if (this.authorization.canViewCompany) {
      items = items.concat(this.companyNavigationUI.items);
    }

    return items.map(item => {
      return {
        ...item,
        subnav: item?.subnav?.map(subNavItem => {
          return {
            ...subNavItem,
            isActive: !!this.getSubNavItemIsActiveByPathname(
              subNavItem,
              this.location?.pathname
            )
          };
        })
      };
    });
  }

  @action.bound setLocation(location) {
    this.location = location;
  }

  @action.bound
  toggleSidebar(expanded) {
    this.isSidebarOpen = expanded;
    this.hasUserToggled = true;
  }

  @action.bound handleNavItemSelect() {
    this.expandedPanels.clear();
  }

  getNavItemIsActiveByPathname = (navItem, pathname) => {
    // https://v5.reactrouter.com/web/api/matchPath
    return !!matchPath(pathname, {
      // match with single subnav link
      path: navItem.link?.pathname || navItem.link,
      exact: !!navItem.exact,
      strict: false
    });
  };

  getSubNavItemIsActiveByPathname = (subNavItem, pathname) => {
    // https://v5.reactrouter.com/web/api/matchPath
    return (
      !!matchPath(pathname, {
        // match with single subnav link
        path: subNavItem.link,
        exact: !!subNavItem.exact,
        strict: false
      }) || // or match with second level links under subnav
      subNavItem.links?.some(
        link =>
          !!matchPath(pathname, {
            path: link.path.pathname,
            exact: !!link.exact,
            strict: false
          })
      )
    );
  };

  @computed get activeItem() {
    return this.navItems.find(navItem => {
      return (
        navItem.subnav?.some(subNavItem => subNavItem.isActive) ||
        (navItem.subnav?.length === 0 &&
          this.getNavItemIsActiveByPathname(navItem, this.location?.pathname))
      );
    });
  }

  @computed get showSubNav() {
    return this.activeItem?.subnav.length > 0;
  }

  @computed get projectViewUrl() {
    return this.projectUI.project ? this.projectUI.project.viewUrl : '';
  }

  @computed get hideProjectSelector() {
    return this.activeItem?.id === 'team';
  }
}

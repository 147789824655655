import UIStore from '../UIStore';
import { action, computed, observable } from 'mobx';

import uniq from 'lodash.uniq';
import omit from 'lodash.omit';

import { t } from 'utils/translate';

import {
  TimeEntriesTimeCardForm,
  timeEntriesTimeCardFormRules,
  timeEntriesTimeCardFormFields,
  timeEntriesTimeCardFormOptions,
  timeEntriesTimeCardFormPlugins
} from 'forms/timeEntriesTimeCard';

export default class TimeEntriesWorkLogBulkEditUI extends UIStore {
  @observable timeEntriesBulkEditForm = null;
  @observable policyToBulkEdit = null;

  constructor(options) {
    super(options);
  }

  @action.bound
  openTimeEntriesBulkEditModal() {
    this.initTimeEntriesBulkEditForm();
    this.activeModal = 'timeEntriesBulkEdit';
  }

  @action.bound
  closeTimeEntriesBulkEditModal() {
    this.hideActiveModal().then(() => {
      this.timeEntriesBulkEditForm = null;
      this.policyToBulkEdit = null;
    });
  }

  @computed get timeEntriesWorkLogFormTimeCards() {
    if (
      this.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.company.preferences.timePolicyStatus === 'ENABLED'
    ) {
      return observable(
        this.parent.timeEntriesWorkLogForm.timeCards.filter(
          timeCard => timeCard.policy.uuid === this.policyToBulkEdit.uuid
        )
      );
    } else {
      return this.parent.timeEntriesWorkLogForm.timeCards;
    }
  }

  @computed get workerCount() {
    return this.timeEntriesWorkLogFormTimeCards.length;
  }

  @computed
  get timeEntriesBulkEditFormValues() {
    let values = { startTime: '', endTime: '', hoursWorked: 0 };
    let timeEntries = [];

    const timeCard = this.timeEntriesWorkLogFormTimeCards[0];

    timeEntries = timeCard.orderedTimeEntries.map(timeEntry => {
      return {
        formValues: {
          hours: this.timeEntriesHours.every(hours => {
            return hours.every(
              (value, index) => value === this.timeEntriesHours[0][index]
            );
          })
            ? timeEntry.values().hours
            : 0,
          payType:
            timeEntry.values().payType ||
            this.rootStore.payTypeSelectorUI.defaultOption,
          classification: { name: t('<Worker Defaults>'), uuid: 'default' },
          shift: { name: t('<Worker Defaults>'), uuid: 'default' },
          costCode: {
            displayCode: t('<Worker Defaults>'),
            code: t('<Worker Defaults>'),
            uuid: 'default'
          }
        }
      };
    });

    // If there is one or more mismatching fields on the pay type entries and start time and end time or total hours have the exact match for all workers we should present the start/end time or total hours.
    if (this.hasTimeCardsWithAllTheSameStartEndTimeAndHours) {
      values = {
        startTime: timeCard.bulkEditFormValues.startTime,
        endTime: timeCard.bulkEditFormValues.endTime,
        hoursWorked: timeCard.totalHours
      };
    }

    // If we have an exact match for all fields on all time cards within a work log and the user selects Edit All then we should prefill the Edit All fields with the data on the time cards
    if (
      this.hasTimeCardsWithSameTimeEntries &&
      this.timeEntriesHours[0][0] !== 0
    ) {
      timeEntries = timeCard.orderedTimeEntries.map(timeEntry => {
        return {
          formValues: {
            hours: this.timeEntriesHours.every(hours => {
              return hours.every(
                (value, index) => value === this.timeEntriesHours[0][index]
              );
            })
              ? timeEntry.values().hours
              : 0,
            payType: timeEntry.values().payType,
            classification: timeEntry.values().classification
              ? timeEntry.values().classification
              : { name: t('<Worker Defaults>'), uuid: 'default' },
            shift: timeEntry.values().shift
              ? timeEntry.values().shift
              : { name: t('<Worker Defaults>'), uuid: 'default' },
            costCode: timeEntry.values().costCode
              ? timeEntry.values().costCode
              : {
                  displayCode: t('<Worker Defaults>'),
                  code: t('<Worker Defaults>'),
                  uuid: 'default'
                }
          }
        };
      });
    }

    return { values, timeEntries };
  }

  @action.bound
  initTimeEntriesBulkEditForm() {
    this.timeEntriesBulkEditForm = new TimeEntriesTimeCardForm(
      {
        fields: timeEntriesTimeCardFormFields,
        rules: timeEntriesTimeCardFormRules,
        values: this.timeEntriesBulkEditFormValues.values || {
          startTime: '',
          endTime: '',
          hoursWorked: 0
        },
        timeEntries: this.timeEntriesBulkEditFormValues.timeEntries || [],
        breaks: this.timeEntriesBulkEditBreaksFormValues,
        bulkEditMode: true
      },
      {
        options: timeEntriesTimeCardFormOptions,
        plugins: timeEntriesTimeCardFormPlugins,
        timeEntriesWorkLogUI: this,
        rootStore: this.rootStore
      }
    );

    if (
      this.parent.timeEntriesWorkLogForm.isPristine &&
      this.hasTimeCardsWithAllTheSameStartEndTimeAndHours &&
      this.timeEntriesBulkEditFormValues.values.hoursWorked === 0
    ) {
      this.timeEntriesBulkEditForm.hideTimeEntriesTable = false;
      return;
    }

    if (
      this.hasTimeCardsWithSameTimeEntries ||
      this.hasTimeCardsWithAllTheSameStartEndTimeAndHours
    ) {
      this.timeEntriesBulkEditForm.hideTimeEntriesTable = this.timeEntriesWorkLogFormTimeCards[0].hideTimeEntriesTable;
    }
  }

  @computed get timeEntriesBulkEditBreaksFormValues() {
    const filteredTimeCardBreaks = this.timeEntriesWorkLogFormTimeCards[0]?.breaks.map(
      ({ cleanedValues: { uuid, ...cleanedValuesWithoutUuid } }) =>
        cleanedValuesWithoutUuid
    );

    const allBreaksAreTheSame = this.timeEntriesWorkLogFormTimeCards?.every(
      timeCard =>
        JSON.stringify(filteredTimeCardBreaks) ===
        JSON.stringify(
          timeCard.breaks.map(
            ({ cleanedValues: { uuid, ...cleanedValuesWithoutUuid } }) =>
              cleanedValuesWithoutUuid
          )
        )
    );

    return allBreaksAreTheSame
      ? this.timeEntriesWorkLogFormTimeCards[0]?.breaks.map(timeCardBreak => {
          return { ...timeCardBreak, formValues: timeCardBreak.values() };
        })
      : [];
  }

  @action.bound
  async submitTimeEntriesBulkEditForm(e) {
    e.preventDefault();

    this.timeEntriesBulkEditForm.validate();
    if (!this.timeEntriesBulkEditForm.timeCardIsValid) return;

    //disable Save/Create/Close buttons until time cards updated
    this.parent.timeCardsLoading = true;
    await this.hideActiveModal();

    const bulkTimeEntries = this.timeEntriesBulkEditForm.bulkEditFormValues
      .timeEntries;
    const hoursWorked = this.timeEntriesBulkEditForm.bulkEditFormValues
      .hoursWorked;
    const bulkBreaks = this.timeEntriesBulkEditForm.bulkEditFormValues.breaks;
    const startTime = this.timeEntriesBulkEditForm.bulkEditFormValues.startTime;
    const endTime = this.timeEntriesBulkEditForm.bulkEditFormValues.endTime;
    const note = this.timeEntriesBulkEditForm.bulkEditFormValues.note;

    const timeCards = this.timeEntriesWorkLogFormTimeCards;

    timeCards.forEach(timeCardForm => {
      timeCardForm.timeEntries.clear();
      if (this.timeEntriesBulkEditForm.hideTimeEntriesTable) {
        timeCardForm.hideTimeEntriesTable = true;
        if (!this.timeEntriesBulkEditForm.showStartEndTimeTable) {
          timeCardForm.$('hoursWorked').set(hoursWorked);
        } else {
          timeCardForm.$('startTime').set(startTime);
          timeCardForm.$('endTime').set(endTime);
        }
      } else {
        timeCardForm.hideTimeEntriesTable = false;
        if (this.timeEntriesBulkEditForm.showStartEndTimeTable) {
          timeCardForm.$('startTime').set(startTime);
          timeCardForm.$('endTime').set(endTime);
        }
        timeCardForm.setTimeEntries(
          bulkTimeEntries.map(timeEntry => {
            /**
             * If the user selected worker default options from the drop down in the bulk edit
             * override the bulk edit values with the worker defaults.
             */
            const classification =
              timeEntry.formValues.classification?.uuid === 'default'
                ? timeCardForm.defaultClassification
                : timeEntry.formValues.classification;

            const shift =
              timeEntry.formValues.shift?.uuid === 'default'
                ? timeCardForm.worker.defaultShift
                : timeEntry.formValues.shift;

            const costCode =
              timeEntry.formValues.costCode?.uuid === 'default'
                ? timeCardForm.worker.defaultCostCode
                : timeEntry.formValues.costCode;

            return {
              formValues: {
                ...timeEntry.formValues,
                classification,
                shift,
                costCode
              }
            };
          })
        );
      }
      timeCardForm.$('note').set(note);
      timeCardForm.breaks.clear();
      timeCardForm.setBreaks(bulkBreaks);

      timeCardForm.timeEntries.forEach(timeEntry => timeEntry.validate());
    });

    this.parent.timeCardsLoading = false;
    this.timeEntriesBulkEditForm = null;
  }

  @computed get openManageBreaksModal() {
    return this.parent.openManageBreaksModal;
  }

  @computed
  get currentTimeEntriesWorkLogHasWorkerDefaults() {
    return this.timeEntriesWorkLogFormTimeCards.find(
      timeCard =>
        timeCard.worker.classification ||
        timeCard.worker.defaultShift ||
        timeCard.worker.defaultCostCode
    );
  }

  @computed
  get classificationOptionsWithDefault() {
    return [
      { name: t('<Worker Defaults>'), uuid: 'default' },
      ...this.classificationSelectorUI.options
    ];
  }

  @computed
  get shiftOptionsWithDefault() {
    return [
      { name: t('<Worker Defaults>'), uuid: 'default' },
      ...this.shiftSelectorUI.options
    ];
  }

  @computed
  get costCodeOptionsWithDefault() {
    return [
      {
        displayCode: t('<Worker Defaults>'),
        code: t('<Worker Defaults>'),
        uuid: 'default'
      },
      ...this.costCodeSelectorUI.costCodes.models
    ];
  }

  @computed get policies() {
    return this.parent.policies;
  }

  @computed get policy() {
    return this.policyToBulkEdit;
  }

  @action.bound setPolicyToBulkEdit(policy) {
    this.policyToBulkEdit = policy;

    this.openTimeEntriesBulkEditModal();
  }

  @computed
  get timeEntriesHours() {
    return this.timeEntriesWorkLogFormTimeCards.map(timeCard => {
      return timeCard.orderedTimeEntries.map(timeEntry => {
        return timeEntry.cleanedValues.hours;
      });
    });
  }

  @computed
  get hasTimeCardsWithSameTimeEntries() {
    return (
      uniq(
        this.timeEntriesWorkLogFormTimeCards.map(timeCard => {
          return JSON.stringify(
            timeCard.orderedTimeEntries.map(timeEntry => {
              const { uuid, ...values } = timeEntry.cleanedValues;
              return values;
            })
          );
        })
      ).length === 1
    );
  }

  @computed
  get hasTimeCardsWithAllTheSameStartEndTimeAndHours() {
    return (
      uniq(
        this.timeEntriesWorkLogFormTimeCards.map(timeCard =>
          JSON.stringify(omit(timeCard.bulkEditFormValues, 'timeEntries'))
        )
      ).length === 1
    );
  }
}

import React from 'react';
import { observer } from 'mobx-react';
import { Dialog, Button, Text } from '@raken/athens-web/lib';
import { t } from 'utils/translate';

const PoliciesNowAvailable = observer(({ uiStore }) => {
  return (
    <Dialog
      dataQA="policies-now-available"
      open={uiStore.activeModal === 'policiesNowAvailable'}
      maxWidth="sm"
      fullWidth={true}
    >
      <Dialog.Title dataQA="policies-now-available-header">
        {t('New Time Policies are here')}
      </Dialog.Title>

      <Dialog.Content>
        <Text>
          {t(
            'Configurable, employee time policies will replace your legacy project settings for overtime, breaks, rounding and time tracking rules. Manage a single policy for your entire company or create multiple policies for different employee groups. Review and enable your time tracking policy before Sunday, May 4, 2025.'
          )}
        </Text>
      </Dialog.Content>

      <Dialog.Actions>
        <Button
          rakenColor="white"
          onClick={uiStore.hidePoliciesNowAvailableModal}
          dataQA="hide-policies-modal"
        >
          {t('Do it later')}
        </Button>
        <Button
          onClick={uiStore.setupPoliciesNowAvailable}
          rakenColor="orange"
          dataQA="set-up-your-policies"
        >
          {uiStore.settings?.saving
            ? t('Saving...')
            : t('Set up your policies')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
});

export default PoliciesNowAvailable;

import { action, computed } from 'mobx';
import UIStore from '../UIStore';

export default class ProjectChildUI extends UIStore {
  @computed get project() {
    return this.parent.project;
  }

  @computed get projectUuid() {
    return this.parent.projectUuid;
  }

  @computed get date() {
    return this.parent.date;
  }

  @computed get hasActiveSegments() {
    return this.parent.hasActiveSegments;
  }

  @computed get segmentUuid() {
    return this.parent.segmentUuid;
  }

  @computed get selectedSegment() {
    return this.parent.selectedSegment;
  }

  @computed get baseQueryParams() {
    return this.parent.baseQueryParams;
  }

  @computed get hasBaseQueryParams() {
    return this.parent.hasBaseQueryParams;
  }

  @computed get reportSummary() {
    return this.parent.reportSummary;
  }

  @computed get hasReport() {
    return this.parent.hasReport;
  }

  @computed get reportParams() {
    return this.parent.reportParams;
  }

  @computed get enableSignRoute() {
    return this.parent.enableSignRoute;
  }

  @computed get enableUnsignRoute() {
    return this.parent.enableUnsignRoute;
  }

  @computed get enableEmailRoute() {
    return this.parent.enableEmailRoute;
  }

  // Override in extended store with feature specific rules
  @computed get hasWriteAccess() {
    return !this.me.isUser;
  }

  @action.bound refetchReportSummary() {
    return this.parent.fetchReportSummary();
  }
}

import omit from 'lodash.omit';
import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';

import ProjectMinimal from 'stores/models/Project';

export default class ComputerEaseDirectAPIJob extends Model {
  get restAttributes() {
    return ['id', 'name', 'data', 'isNew'];
  }

  parse(attributes) {
    this.parseRakenProject(attributes);

    return {
      ...omit(attributes, ['rakenRef'])
    };
  }

  parseRakenProject(attributes) {
    this.rakenProject = new ProjectMinimal(attributes.rakenRef, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get slug() {
    return kebabCase(this.name);
  }
}

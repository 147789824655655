import request from 'axios';
import {
  ProductionReportForm,
  productionReportFormFields,
  productionReportFormLabels,
  productionReportFormOptions,
  productionReportFormPlugins,
  productionReportFormRules,
  productionReportFormValues
} from 'forms/productionReport';
import pickBy from 'lodash.pickby';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import alertErrorHandler from 'utils/alertErrorHandler';
import {
  PRODUCTION_SUMMARY_DOWNLOADED,
  PRODUCTION_SUMMARY_STARTED,
  PRODUCTION_WORKSHEET_DOWNLOADED,
  PRODUCTION_WORKSHEET_STARTED
} from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

import { t } from 'utils/translate';
import UIStore from './UIStore';

export default class ProductionReportsUI extends UIStore {
  @observable reportType;

  constructor(options) {
    super(options);

    this.reportType = null;

    this.costCodeFilters = observable([]);
  }

  @action.bound
  showProductionSummaryReportModal() {
    this.reportType = 'SUMMARY';
    callTrack(PRODUCTION_SUMMARY_STARTED);
    this.showProductionReportModal();
  }

  @action.bound
  showProductionWorksheetReportModal() {
    this.reportType = 'WORKSHEET';
    callTrack(PRODUCTION_WORKSHEET_STARTED);
    this.showProductionReportModal();
  }

  @action.bound
  showProductionReportModal() {
    this.loading = true;
    this.authorization.checkFeatureAccess('ViewProductionReports').then(() => {
      runInAction(async () => {
        this.showModal('productionReport');
        this.setupModalForm();

        this.loading = false;
      });
    });
  }

  @action.bound
  setupModalForm() {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });
    this.costCodeSelectorUI.setup({
      ...(this.project && {
        projectUuid: this.project.uuid
      })
    });
    this.initReportForm();
    this.setupReactions();
  }

  @action.bound
  setupReactions() {
    this.reactToProjectSelected = reaction(
      () => this.activeForm.$('project').value?.uuid,
      async () => {
        await this.costCodeSelectorUI.setup({
          projectUuid: this.activeForm.$('project').value.uuid
        });
      }
    );
  }

  @action.bound
  tearDownReactions() {
    this.reactToProjectSelected && this.reactToProjectSelected();
  }

  @action.bound
  initReportForm() {
    this.activeForm = new ProductionReportForm(
      {
        fields: productionReportFormFields,
        rules: productionReportFormRules,
        values: Object.assign(productionReportFormValues, {
          costCodes: this.costCodeFilters.slice(),
          project: this.project && {
            uuid: this.project.uuid,
            name: this.project.name
          }
        }),
        labels: productionReportFormLabels
      },
      {
        options: productionReportFormOptions,
        plugins: productionReportFormPlugins
      }
    );
  }

  @action.bound
  async hideProductionReportModal() {
    await this.hideActiveModal().then(() => {
      runInAction(() => {
        this.reportType = null;
        this.activeForm = null;
        this.saving = false;
        this.costCodeFilters.clear();
        this.projectSelectorUI.tearDown();
        this.costCodeSelectorUI.tearDown();
        this.tearDownReactions();
      });
    });
  }

  @computed
  get title() {
    if (this.reportType === 'WORKSHEET') {
      return t('Production worksheet');
    }

    return t('Production summary');
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get biWeeklyFirstDay() {
    return moment(this.weekFirstDay).subtract(7, 'days');
  }

  @computed get timeFrames() {
    let timeFrames = [
      {
        id: 'DAILY',
        fromDate: moment(new Date()),
        toDate: moment(new Date()),
        title: t('Current day'),
        projectEntire: false
      },
      {
        id: 'WEEKLY',
        fromDate: this.weekFirstDay,
        toDate: this.weekLastDay,
        title: t('Current week'),
        projectEntire: false
      },
      {
        id: 'BI_WEEKLY',
        fromDate: this.biWeeklyFirstDay,
        toDate: this.weekLastDay,
        title: t('Bi-weekly'),
        projectEntire: false
      },
      {
        id: 'MONTHLY',
        fromDate: moment(new Date()).startOf('month'),
        toDate: moment(new Date()).endOf('month'),
        title: t('Current month'),
        projectEntire: false
      },
      {
        id: 'SPECTIMEFRAME',
        title: t('Specific time frame'),
        projectEntire: false
      }
    ];

    return timeFrames;
  }

  @computed get restrictSpecificTimeframe() {
    return this.activeForm.$('timeFrameType').value === 'SPECTIMEFRAME';
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @computed get minToDate() {
    return this.activeForm.$('fromDate').value
      ? moment(this.activeForm.$('fromDate').value).endOf('day')
      : undefined;
  }

  @computed get maximumToDate() {
    const fromDate = this.activeForm.$('fromDate').value;

    return moment(fromDate, 'YYYY-MM-DD').add(1, 'year');
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const fromDate = timeFrame.fromDate
      ? moment(timeFrame.fromDate).format('YYYY-MM-DD')
      : '';

    const toDate = timeFrame.toDate
      ? moment(timeFrame.toDate).format('YYYY-MM-DD')
      : '';

    const actionDate = timeFrame.actionDate
      ? moment(timeFrame.actionDate).format('YYYY-MM-DD')
      : '';

    const projectEntire = timeFrame.id === 'PROJECTENTIRE' ? true : false;

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      fromDate: fromDate,
      toDate: toDate,
      projectEntire: projectEntire,
      actionDate: actionDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setActionDate(value) {
    this.activeForm.$('actionDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound
  submitProductionReportForm(event) {
    event.preventDefault();

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitProductionReportFormSuccess,
      onError: this.submitProductionReportFormError
    });
  }

  @action.bound submitProductionReportFormSuccess() {
    this.submitProductionRunOnceReport();
  }

  @action.bound submitProductionReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitProductionRunOnceReport() {
    this.saving = true;

    let values = this.activeForm.values();

    if (values.project) {
      values.projects = [values.project];
      delete values.project;
    }

    values.costCodes = values.costCodes.map(costcode => costcode.uuid);
    values.reportType = this.reportType;
    values.timezone = momentTimezone.tz.guess();

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/reports/time-and-material/run-once`,
        cleanedValues
      );

      if (this.reportType === 'SUMMARY') {
        callTrack(PRODUCTION_SUMMARY_DOWNLOADED);
      } else {
        callTrack(PRODUCTION_WORKSHEET_DOWNLOADED);
      }

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      this.hideProductionReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed
  get enableCostCodes() {
    return this.activeForm.$('project').value;
  }

  @computed
  get enableSubmit() {
    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @computed
  get selectedCostCodes() {
    if (this.activeForm.$('costCodes').value) {
      return this.activeForm.$('costCodes').value.map(costCode => {
        return {
          uuid: costCode.value,
          code: costCode.code
        };
      });
    }

    return [];
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';

const userFiltersFormPlugins = extendedPlugins;

const userFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const userFiltersFormFields = [
  'statusFilters[]',
  'roleFilters[]',
  'classificationFilters[]',
  'groupFilters[]'
];

const userFiltersFormRules = {
  statusFilters: 'array',
  roleFilters: 'array',
  classificationFilters: 'array',
  groupFilters: 'array'
};

class UserFiltersForm extends MobxReactForm {}

export {
  UserFiltersForm,
  userFiltersFormRules,
  userFiltersFormFields,
  userFiltersFormOptions,
  userFiltersFormPlugins
};

import request from 'axios';
import debounce from 'lodash.debounce';
import orderBy from 'lodash.orderby';
import { action, computed, observable, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import UIStore from 'stores/ui/UIStore';

import Projects from 'stores/collections/Projects';
import QuickbooksCustomers from 'stores/collections/integrations/QuickbooksCustomers';

import IntegrationQBOProjectImportUI from './IntegrationQBOProjectImportUI';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';
import history from 'utils/history';

export default class IntegrationQBOProjectMappingUIUI extends UIStore {
  @observable loading;
  // Internal
  @observable rakenProjectOptionsQuery;
  @observable selectedRakenProjectOption;
  // External
  @observable loadingQuickbooksCustomerOptions;
  @observable selectedQuickbooksCustomerOption;
  @observable quickbooksCustomerOptionsQuery;
  // Mappings
  @observable sortField;
  @observable sortDirection;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    this.loading = false;

    // Internal Options
    this.rakenProjectOptionsQuery = '';
    this.selectedRakenProjectOption = null;

    // Bulk actions
    this.selectedMappings = observable([]);

    this.rakenProjectOptions = new Projects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchRakenProjectOptionsDebounced = debounce(
      this.fetchRakenProjectOptions,
      BASE_DEBOUNCE
    );

    // External options
    this.quickbooksCustomerOptionsQuery = '';
    this.selectedQuickbooksCustomerOption = null;

    this.quickbooksCustomerOptions = new QuickbooksCustomers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchQuickbooksCustomerOptionsDebounced = debounce(
      this.fetchQuickbooksCustomerOptions,
      BASE_DEBOUNCE
    );

    // Mappings
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.pageSize = 10;
    this.page = 1;

    this.rakenProjectMappings = new Projects(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.quickbooksCustomerMappings = new QuickbooksCustomers(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.integrationQBOProjectImportUI = new IntegrationQBOProjectImportUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get projectStatesParam() {
    if (this.isSuperAdmin) {
      return {
        projectStatuses: 'ACTIVE'
      };
    }

    return {
      projectStates: 'ACTIVE'
    };
  }

  @computed get integrations() {
    return this.parent.integrations;
  }

  @computed get activeIntegration() {
    return this.parent.activeIntegration;
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get baseUrl() {
    return this.parent.baseUrl;
  }

  @action.bound async setup() {
    this.loading = true;

    this.setupReactions();

    await Promise.all([
      this.fetchRakenProjectOptions(),
      this.fetchRakenProjectMappings(),
      this.fetchQuickbooksCustomerOptions(),
      this.fetchQuickbooksCustomerMappings()
    ]);

    this.loading = false;
  }

  @action.bound setupReactions() {
    this.cancelSearchRakenProjectOptionsReaction = reaction(
      () => this.rakenProjectOptionsQuery,
      query => {
        this.fetchRakenProjectOptionsDebounced();
      }
    );

    this.cancelSearchQuickbooksCustomerOptionsReaction = reaction(
      () => this.quickbooksCustomerOptionsQuery,
      query => {
        this.fetchQuickbooksCustomerOptionsDebounced();
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.cancelSearchRakenProjectOptionsReaction();
    this.cancelSearchQuickbooksCustomerOptionsReaction();
  }

  @action.bound setRakenProjectOptionsQuery(value) {
    this.rakenProjectOptionsQuery = value;
  }

  @action.bound
  async fetchRakenProjectOptions(options) {
    try {
      await this.rakenProjectOptions.fetch({
        params: {
          sortField: 'name',
          sortDirection: 'asc',
          limit: 50,
          externalId: 'null',
          query: this.rakenProjectOptionsQuery,
          projectTypes: 'PARENT',
          ...this.projectStatesParam
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  fetchNextRakenProjectOptions = async rakenProjectOptionsAutocomplete => {
    const dropdown = rakenProjectOptionsAutocomplete.current;
    const scrollTop = dropdown.scrollTop;
    const scrollHeight = dropdown.scrollHeight;
    const dropdownHeight = dropdown.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.rakenProjectOptions.fetchNextPage()?.then(() => {
        rakenProjectOptionsAutocomplete.current.scrollTop =
          scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get rakenProjectOptionsForRender() {
    return this.rakenProjectOptions.models.slice();
  }

  @action.bound selectRakenProjectOption(option) {
    this.selectedRakenProjectOption = option;
    this.rakenProjectOptionsQuery = '';
  }

  @action.bound setQuickbooksCustomerOptionsQuery(value) {
    this.quickbooksCustomerOptionsQuery = value;
  }

  @action.bound clearQuickbooksCustomerOptionsQuery() {
    this.quickbooksCustomerOptionsQuery = '';
  }

  @computed get hasQuickbooksCustomerOptions() {
    return this.quickbooksCustomerOptions.hasModels;
  }

  @action.bound
  async fetchQuickbooksCustomerOptions(options) {
    this.loadingQuickbooksCustomerOptions = true;

    try {
      await this.quickbooksCustomerOptions.fetch({
        params: {
          limit: 50,
          unlinkedOnly: true,
          query: this.quickbooksCustomerOptionsQuery
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loadingQuickbooksCustomerOptions = false;
    }
  }

  fetchNextQuickBooksCustomerOptions = async scrollRef => {
    const scrollableElement = scrollRef.current;
    const scrollTop = scrollableElement.scrollTop;
    const scrollHeight = scrollableElement.scrollHeight;
    const dropdownHeight = scrollableElement.clientHeight;

    if (scrollTop + dropdownHeight === scrollHeight) {
      this.quickbooksCustomerOptions.fetchNextPage()?.then(() => {
        scrollRef.current.scrollTop = scrollHeight - dropdownHeight;
      });
    }
  };

  @computed
  get quickbooksCustomerOptionsForRender() {
    return this.quickbooksCustomerOptions.models.slice();
  }

  @action.bound selectQuickbooksCustomerOption(option) {
    this.selectedQuickbooksCustomerOption = option;
    this.quickbooksCustomerOptionsQuery = '';
  }

  @action.bound
  clearUIState() {
    this.selectedMappings.clear();
    this.rakenProjectsOptionsQuery = '';
    this.rakenProjectOptions.reset();
    this.quickbooksCustomerOptions.reset();
    this.rakenProjectMappings.reset();
    this.quickbooksCustomerMappings.reset();
    this.quickbooksCustomerOptionsQuery = '';
    this.selectedRakenProjectOption = null;
    this.selectedQuickbooksCustomerOption = null;
    this.loading = false;
    this.page = 1;
  }

  @computed get disableAddConfigurationButton() {
    if (this.saving || this.loading) return true;

    return (
      !this.selectedRakenProjectOption || !this.selectedQuickbooksCustomerOption
    );
  }

  @action.bound async saveMapping() {
    this.saving = true;

    const {
      selectedRakenProjectOption,
      selectedQuickbooksCustomerOption
    } = this;

    try {
      await selectedRakenProjectOption.save(
        {
          externalId: selectedQuickbooksCustomerOption.id
        },
        {
          wait: true
        }
      );

      this.rakenProjectMappings.add(selectedRakenProjectOption.toJSON());

      this.quickbooksCustomerMappings.add(
        selectedQuickbooksCustomerOption.toJSON()
      );

      this.refetchOptionsAfterChange();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async removeMapping(mapping) {
    const quickbooksCustomer = this.quickbooksCustomerMappings.get(
      mapping.quickbooksCustomerId
    );

    const rakenProject = this.rakenProjectMappings.get(mapping.id);

    try {
      await rakenProject.save(
        {
          externalId: null
        },
        {
          wait: true
        }
      );

      if (quickbooksCustomer) {
        this.quickbooksCustomerMappings.remove(quickbooksCustomer);
      }

      this.rakenProjectMappings.remove(rakenProject);

      this.refetchOptionsAfterChange();
    } catch (error) {
      this.quickbooksCustomerMappings.add(quickbooksCustomer);
      this.rakenProjectMappings.add(rakenProject);
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async resyncMapping(mapping) {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      const { data } = await request.post(
        `${this.integrationQBOProjectImportUI.projects.url()}/sync`,
        {
          externalIds: [mapping.quickbooksCustomerId]
        }
      );

      const successfulCount = data.collection.find(
        syncResult =>
          syncResult.externalId === mapping.quickbooksCustomerId &&
          syncResult.successful === true
      )
        ? 1
        : 0;
      const title = formatIntegrationSyncMessage(
        'Project resync',
        successfulCount,
        1 - successfulCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';
      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound async importQuickbooksProjects() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1019/projects/import`
      );
    } else {
      history.push('/company/integrations/1019/projects/import');
    }
  }

  @computed get hasSelectedMappings() {
    return this.selectedMappings.length > 0;
  }

  @action.bound async refetchOptionsAfterChange() {
    this.selectedRakenProjectOption = null;
    this.selectedQuickbooksCustomerOption = null;

    this.rakenProjectOptionsQuery = '';
    this.quickbooksCustomerOptionsQuery = '';

    this.rakenProjectOptions.reset();
    this.quickbooksCustomerOptions.reset();

    this.fetchRakenProjectOptions();
    this.fetchQuickbooksCustomerOptions();
  }

  // Mappings
  @action.bound
  async fetchQuickbooksCustomerMappings() {
    try {
      await this.quickbooksCustomerMappings.fetch({
        params: {
          limit: 1000,
          linkedOnly: true
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  async fetchRakenProjectMappings() {
    try {
      await this.rakenProjectMappings.fetch({
        params: {
          limit: 1000,
          externalId: '*',
          ...this.projectStatesParam
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get mappingsLoading() {
    return (
      this.quickbooksCustomerMappings.fetching ||
      this.rakenProjectMappings.fetching
    );
  }

  @computed get hasMappings() {
    return this.mappings.length > 0;
  }

  @computed get mappings() {
    return this.rakenProjectMappings.models.map(rakenProject => {
      const quickbooksCustomer = this.quickbooksCustomerMappings.models.find(
        quickbooksCustomer => quickbooksCustomer.id === rakenProject.externalId
      );

      return {
        id: rakenProject.id,
        name: rakenProject.name,
        slug: rakenProject.slug,
        quickbooksCustomerName:
          quickbooksCustomer?.name || `Unknown: ${rakenProject.externalId}`,
        quickbooksCustomerId: quickbooksCustomer?.id || null,
        hasError: !quickbooksCustomer
      };
    });
  }

  @computed get sortedMappings() {
    return orderBy(this.mappings, [this.sortField], [this.sortDirection]);
  }

  @computed
  get totalPages() {
    return Math.ceil(this.sortedMappings.length / this.pageSize);
  }

  @computed
  get paginatedMappings() {
    return this.sortedMappings.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  @action.bound
  setPage(page) {
    this.page = page;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  findSelectedMapping = mappingId => {
    return this.selectedMappings.find(
      selectedMapping => selectedMapping.id === mappingId
    );
  };

  @action.bound
  toggleSelectMapping(mapping) {
    const selectedMapping = this.findSelectedMapping(mapping.id);

    if (selectedMapping) {
      this.selectedMappings.remove(selectedMapping);
    } else {
      this.selectedMappings.push(mapping);
    }
  }

  @computed
  get allMappingsSelected() {
    if (!this.hasMappings) return false;

    return (
      this.paginatedMappings.filter(mapping =>
        this.findSelectedMapping(mapping.id)
      ).length === this.paginatedMappings.length
    );
  }

  @action.bound
  toggleSelectAllMappings() {
    if (this.allMappingsSelected) {
      this.selectedMappings.replace(
        this.selectedMappings.filter(
          selectedMapping =>
            !this.paginatedMappings.some(
              mapping => mapping.id === selectedMapping.id
            )
        )
      );
    } else {
      this.paginatedMappings.forEach(mapping => {
        this.selectedMappings.push(mapping);
      });
    }
  }

  @action.bound clearAllSelectedMappings() {
    this.selectedMappings.clear();
  }

  @computed get bulkActions() {
    return [
      {
        title: t('Resync selected projects'),
        onClick: () => {
          this.bulkResyncSelectedMappings();
        }
      },
      {
        title: t('Remove selected projects'),
        onClick: () => {
          this.bulkRemoveSelectedMappings();
        }
      }
    ];
  }

  @action.bound bulkResyncSelectedMappings() {
    this.showModal('BulkResyncSelectedMappings');
  }

  @action.bound async confirmBulkResyncSelectedMappings() {
    await this.authorization.checkFeatureAccess('EditIntegrations');

    try {
      this.saving = true;

      const { data } = await request.post(
        `${this.integrationQBOProjectImportUI.projects.url()}/sync`,
        {
          externalIds: this.selectedMappings.map(
            mapping => mapping.quickbooksCustomerId
          )
        }
      );

      const successfulCount = data.collection.filter(
        syncResult => syncResult.successful === true
      ).length;
      const failedCount = data.collection.length - successfulCount;
      const title = formatIntegrationSyncMessage(
        'Projects resync',
        successfulCount,
        failedCount
      );
      const snackbar = successfulCount > 0 ? 'warning' : 'error';

      await this.hideActiveModal();

      this.notifications.pushNotification({
        snackbar,
        icon: 'checkmark',
        title
      });

      this.selectedMappings.clear();
      this.refetchOptionsAfterChange();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.saving = false;
    }
  }

  @action.bound bulkRemoveSelectedMappings() {
    this.showModal('BulkRemoveSelectedMappings');
  }

  @action.bound async confirmBulkRemoveSelectedMappings() {
    this.saving = true;

    this.selectedMappings.forEach(async (mapping, index) => {
      const quickbooksCustomer = this.quickbooksCustomerMappings.get(
        mapping.quickbooksCustomerId
      );

      const rakenProject = this.rakenProjectMappings.get(mapping.id);

      try {
        await rakenProject.save(
          {
            externalId: null
          },
          {
            wait: true
          }
        );

        if (quickbooksCustomer) {
          this.quickbooksCustomerMappings.remove(quickbooksCustomer);
        }

        this.rakenProjectMappings.remove(rakenProject);

        if (index == this.selectedMappings.length - 1) {
          this.selectedMappings.clear();
          this.refetchOptionsAfterChange();

          await this.hideActiveModal();

          this.notifications.pushNotification({
            snackbar: 'warning',
            icon: 'checkmark',
            title: t('Projects removed')
          });
        }
      } catch (error) {
        this.quickbooksCustomerMappings.add(quickbooksCustomer);
        this.rakenProjectMappings.add(rakenProject);
        alertErrorHandler(error, this.setValidationDetails);
      } finally {
        this.saving = false;
      }
    });
  }
}

import request from 'axios';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import orderBy from 'lodash.orderby';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import moment from 'moment';
import { Model } from 'mobx-mc';
import { computed, action } from 'mobx';
import Integrations from 'stores/collections/integrations/Integrations';
import ProjectTeams from '../collections/ProjectTeams';
import ProjectNotificationSettings from '../collections/ProjectNotificationSettings';
import ProjectTeam from './ProjectTeam';
import Talks from '../collections/Talks';
import ScheduledTalks from '../collections/ScheduledTalks';
import CostCodes from '../collections/CostCodes';
import errorHandler from 'utils/errorHandler';

export default class Project extends Model {
  get urlRoot() {
    if (this.rootStore.isSuperAdmin) {
      return `/ra/companies/${this.collection?.parent?.activeCompany?.uuid}/projects`;
    }

    return `/ra/companies/${this.rootStore.me.company?.uuid}/projects`;
  }

  idAttribute() {
    return 'uuid';
  }

  get restAttributes() {
    return [
      'uuid',
      '_links',
      'address',
      'automaticProgressTracking',
      'classificationsEditableOnTimeCards',
      'classificationsOnTimeCards',
      'collaboratorQuestions',
      'costCodesOnTimeCards',
      'createdTimestamp',
      'dateFormat',
      'defaultWorkLogType',
      'emailInterval',
      'enableCrewGrouping',
      'enableKiosk',
      'enableManualTimeCapture',
      'enableScSegments',
      'enableSegments',
      'enableTaskSwitching',
      'enableTimeClock',
      'enableWatermark',
      'endDate',
      'externalId',
      'externalMembersRecipients',
      'fahrenheit',
      'frequency',
      'hasSegmentedReports',
      'hideEmptySections',
      'hideEmptySubcontractors',
      'hideGrandTotal',
      'hideInactiveSubcontractors',
      'inches',
      'includeCompletedChecklists',
      'includeDailySurvey',
      'includeEquipment',
      'includeEquipmentForExternalMembers',
      'includeHours',
      'includeHoursToDate',
      'includeMaterialQuantity',
      'includeMaterialQuantityForExternalMembers',
      'includeMyCompanyQuestionsForSubcontractors',
      'includeNotes',
      'includeQualityControlObservations',
      'includeQuantity',
      'includeSafetyObservations',
      'includeStampsForEntries',
      'includeStampsForPhotos',
      'includeTimeCards',
      'includeTimeCardsForExternalMembers',
      'includeToolboxTalks',
      'includeWeather',
      'includeWorkLogs',
      'internalMembersRecipients',
      'intervals',
      'languageId',
      'lastActivity',
      'materialTrackingEnabled',
      'milesPerHour',
      'name',
      'overtimeRuleSetUuid',
      'parentProjectId',
      'primaryColor',
      'projectImage',
      'projectLogo',
      'projectNo',
      'projectRadius',
      'projectState',
      'projectType',
      'projectUuids',
      'parentProjectId',
      'groupUuids',
      'questions',
      'segmentSets',
      'sendBySign',
      'startDate',
      'stats',
      'superDailyIndex',
      'teamId',
      'templateId',
      'timeCardsStartAndEndTime',
      'requirePhotoId',
      'requirePhotoIdOnClockInOutAndBreaks',
      'clockInChecklistTemplateUuid',
      'clockOutChecklistTemplateUuid',
      'timeTrackingEnabled',
      'timezone',
      'type',
      'updatedTimestamp',
      'watermarkAlignment',
      'watermarkIncludeAutoGenLocation',
      'watermarkIncludeCompanyLogo',
      'watermarkIncludeCompanyName',
      'watermarkIncludeGPSCoords',
      'watermarkIncludeProjectName',
      'watermarkIncludeTimestamp',
      'weatherIntervals',
      'breakSetting'
    ];
  }

  get restAttributeDefaults() {
    return {
      dateFormat: 'MM/dd/YYYY',
      fahrenheit: true,
      inches: true,
      milesPerHour: true,
      stats: {
        hours: 0,
        workers: 0,
        totalProjectDays: 0,
        missedDailies: 0,
        dailiesCompliancePercent: 0,
        pendingReports: 0
      },
      timeCardsStartAndEndTime: false,
      requirePhotoId: false,
      requirePhotoIdOnClockInOutAndBreaks: 'NOT_REQUIRED',
      clockInChecklistTemplateUuid: '',
      clockOutChecklistTemplateUuid: '',
      includeMyCompanyQuestionsForSubcontractors: false,
      includeMaterialQuantity: false,
      includeMaterialQuantityForExternalMembers: false,
      automaticProgressTracking: false,
      materialTrackingEnabled: true,
      timeTrackingEnabled: true,
      includeEquipment: false,
      includeEquipmentForExternalMembers: false,
      groupUuids: []
    };
  }

  constructor(data, options) {
    super(data, options);

    this.notificationSettings = new ProjectNotificationSettings(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.integrations = new Integrations(null, {
      type: 'integrations',
      parent: this,
      rootStore: this.rootStore
    });

    this.talks = new Talks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    this.scheduledTalks = new ScheduledTalks(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // Cost Codes for Project that have budget
    this.costCodesWithBudget = new CostCodes(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound
  parse(attributes) {
    // Temp until we update legacy Insights APIs
    attributes.parentProjectId = attributes.id;

    this.parseParentProject(attributes);
    this.parseChildProject(attributes);

    return {
      ...omit(attributes, 'childProjects', 'parentProject')
    };
  }

  @action.bound
  parseParentProject(attributes) {
    if (this.parentProject) {
      this.parentProject.set(attributes.parentProject || null);
    } else {
      this.parentProject = new ProjectTeam(attributes.parentProject || null, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @action.bound
  parseChildProject(attributes) {
    if (this.childProjects) {
      this.childProjects.reset(attributes.childProjects);
    } else {
      this.childProjects = new ProjectTeams(attributes.childProjects || null, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @computed
  get hasPendingReports() {
    return this.stats.pendingReports > 0;
  }

  @computed
  get projectStarted() {
    return moment().isBefore(this.startDate, 'day');
  }

  @computed
  get materialAndTimeTrackingDisabled() {
    return !this.materialTrackingEnabled && !this.timeTrackingEnabled;
  }

  @computed
  get enabledOnlyTimeTracking() {
    return !this.materialTrackingEnabled && this.timeTrackingEnabled;
  }

  @computed
  get enabledOnlyMaterialTracking() {
    return this.materialTrackingEnabled && !this.timeTrackingEnabled;
  }

  @computed
  get startDateFormatted() {
    if (!this.startDate) return null;

    return moment(this.startDate).format('YYYY-MM-DD');
  }

  @computed
  get endDateFormatted() {
    if (!this.endDate) return null;

    return moment(this.endDate).format('YYYY-MM-DD');
  }

  @computed
  get postCode() {
    if (!this.address.postCode) return 'N/A';

    return this.address.postCode;
  }

  @computed
  get viewUrl() {
    return `/projects/${this.uuid}`;
  }

  @computed
  get workLogUrl() {
    return `${this.viewUrl}/worklogs?date=${moment().format('YYYY-MM-DD')}`;
  }

  @computed
  get addressString() {
    const properties = pickBy(
      pick(this.address, [
        'streetAddress',
        'city',
        'state',
        'postCode',
        'country'
      ]),
      identity
    );

    return Object.keys(properties)
      .filter(k => {
        return properties[k] !== 'null';
      })
      .map(k => {
        return properties[k].replace('.', '');
      })
      .join(', ');
  }

  @computed
  get incidentAddressString() {
    const { streetAddress, city, state, postCode } = this.address;

    const properties = [streetAddress, city, state, postCode];

    return properties.reduce((acc, prop) => {
      if (prop && prop !== 'null') {
        const cleaned = prop.replace(/[^\w\s]/g, '');
        return acc ? `${acc} ${cleaned}` : cleaned;
      }
      return acc;
    }, '');
  }

  @computed
  get hasGeoLocation() {
    return (
      this.address.geolocation &&
      this.address.geolocation.lat &&
      this.address.geolocation.lng
    );
  }

  @computed
  get firstLetterOfName() {
    let matches = this.name.match('[a-zA-Z]');
    return matches && matches[0].toUpperCase();
  }

  @computed
  get isActive() {
    return this.projectState === 'ACTIVE';
  }

  @computed
  get isInactive() {
    return this.projectState === 'INACTIVE';
  }

  @computed
  get isDeleted() {
    return this.projectState === 'DELETED';
  }

  @computed
  get isMember() {
    return this.rootStore.me.projectUuids.includes(this.uuid);
  }

  @computed
  get addressFormValues() {
    return {
      streetAddress: this.address.streetAddress,
      city: this.address.city,
      state: this.address.state,
      postCode: this.address.postCode,
      country: this.address.country,
      geolocation: this.address.geolocation
    };
  }

  @computed
  get infoFormValues() {
    return {
      name: this.name,
      projectNo: this.projectNo,
      projectState: this.projectState,
      address: this.addressFormValues,
      startDate: this.startDate,
      endDate: this.endDate,
      projectImage: this.projectImage && this.projectImage.contentUrl,
      projectRadius: this.projectRadius,
      groups: this.groupUuids.map(groupUuid => {
        return {
          uuid: groupUuid
        };
      })
    };
  }

  @computed
  get collaboratorQuestionFormValues() {
    return this.collaboratorQuestions
      .filter(question => question.question)
      .map(question => {
        return {
          uuid: question.id,
          ...question
        };
      });
  }

  @computed
  get questionFormValues() {
    let questions = this.questions
      .filter(question => question.question)
      .map(question => {
        return {
          uuid: question.id,
          ...question
        };
      });

    if (this.isChildProject && this.collaboratorQuestions.length) {
      questions.unshift(...this.collaboratorQuestionFormValues);
    }

    return questions;
  }

  @computed get segments() {
    let segments = [];

    this.segmentSets?.forEach(segmentSet => {
      segments = segments.concat(...segmentSet.segments);
    });

    return segments;
  }

  @computed get hasSegments() {
    return this.segments.length > 0;
  }

  @computed
  get sortedSegmentSetsDescending() {
    if (this.segmentSets) {
      return orderBy(
        this.segmentSets,
        [
          segmentSet => moment(segmentSet.applicationDate).valueOf(),
          segmentSet => moment(segmentSet.createDate).valueOf()
        ],
        ['desc', 'desc']
      );
    }

    return null;
  }

  @computed
  get latestSegmentSet() {
    if (
      this.sortedSegmentSetsDescending &&
      this.sortedSegmentSetsDescending.length > 0
    ) {
      return this.sortedSegmentSetsDescending[0];
    }

    return null;
  }

  @computed
  get latestSegments() {
    if (
      this.latestSegmentSet &&
      this.latestSegmentSet.segments &&
      this.latestSegmentSet.segments.length > 0
    ) {
      return this.latestSegmentSet.segments;
    }

    return null;
  }

  @computed
  get latestSegmentsCount() {
    if (
      this.latestSegmentSet &&
      this.latestSegmentSet.segments &&
      this.latestSegmentSet.segments.length > 0
    ) {
      return this.latestSegmentSet.segments.length;
    }

    return null;
  }

  @computed
  get combinedSettingsFormValues() {
    return Object.assign(
      {},
      this.settingsFormValues,
      this.segmentsSettingsFormValues
    );
  }

  @computed
  get segmentsDefaults() {
    return {
      segments: [
        {
          name: '',
          index: 0
        },
        {
          name: '',
          index: 1
        }
      ]
    };
  }

  @computed
  get segmentsSettingsFormValues() {
    if (this.latestSegmentsCount) {
      return {
        segments: this.latestSegments
      };
    }

    return this.segmentsDefaults;
  }

  @computed
  get settingsFormValues() {
    return {
      sendBySign: this.sendBySign,
      includeHours: this.includeHours,
      includeStampsForEntries: this.includeStampsForEntries,
      milesPerHour: this.milesPerHour,
      includeSafetyObservations: this.includeSafetyObservations,
      inches: this.inches,
      includeStampsForPhotos: this.includeStampsForPhotos,
      emailInterval: this.emailInterval,
      includeHoursToDate: this.includeHoursToDate,
      includeWeather: this.includeWeather,
      superDailyIndex: this.superDailyIndex,
      fahrenheit: this.fahrenheit,
      includeWorkLogs: this.includeWorkLogs,
      includeQualityControlObservations: this.includeQualityControlObservations,
      includeQuantity: this.includeQuantity,
      includeNotes: this.includeNotes,
      includeDailySurvey: this.includeDailySurvey,
      includeMyCompanyQuestionsForSubcontractors: this
        .includeMyCompanyQuestionsForSubcontractors,
      intervals: this.intervals.slice(),
      dateFormat: this.dateFormat,
      weatherIntervals: this.weatherIntervals.slice(),
      internalMembersRecipients: this.internalMembersRecipients.slice(),
      includeTimeCardsForExternalMembers: this
        .includeTimeCardsForExternalMembers,
      hideEmptySections: this.hideEmptySections,
      deleted: this.deleted,
      templateName: this.templateName,
      externalMembersRecipients: this.externalMembersRecipients.slice(),
      frequency: this.frequency,
      includeTimeCards: this.includeTimeCards,
      hideEmptySubcontractors: this.hideEmptySubcontractors,
      hideInactiveSubcontractors: this.hideInactiveSubcontractors,
      questions: this.questionFormValues,
      collaboratorQuestions: this.collaboratorQuestionFormValues,
      timeCardsStartAndEndTime: this.timeCardsStartAndEndTime,
      requirePhotoId: this.requirePhotoId,
      requirePhotoIdOnClockInOutAndBreaks: this
        .requirePhotoIdOnClockInOutAndBreaks,
      clockInChecklistTemplateUuid: this.clockInChecklistTemplateUuid,
      clockOutChecklistTemplateUuid: this.clockOutChecklistTemplateUuid,
      languageId: this.languageId,
      includeMaterialQuantity: this.includeMaterialQuantity,
      includeMaterialQuantityForExternalMembers: this
        .includeMaterialQuantityForExternalMembers,
      includeEquipment: this.includeEquipment,
      includeEquipmentForExternalMembers: this
        .includeEquipmentForExternalMembers,
      hideGrandTotal: this.hideGrandTotal,
      includeToolboxTalks: this.includeToolboxTalks,
      includeCompletedChecklists: this.includeCompletedChecklists,
      enableSegments: this.enableSegments,
      enableScSegments: this.enableScSegments,
      overtimeRuleSetUuid: this.overtimeRuleSetUuid,
      enableKiosk: this.enableKiosk,
      enableTimeClock: this.enableTimeClock,
      enableManualTimeCapture: this.enableManualTimeCapture,
      enableTaskSwitching: this.enableTaskSwitching,
      enableCrewGrouping: this.enableCrewGrouping,
      costCodesOnTimeCards: this.costCodesOnTimeCards,
      classificationsOnTimeCards: this.classificationsOnTimeCards,
      classificationsEditableOnTimeCards: this
        .classificationsEditableOnTimeCards,
      projectRadius: this.projectRadius,
      enableWatermark: this.enableWatermark,
      watermarkIncludeCompanyLogo: this.watermarkIncludeCompanyLogo,
      watermarkIncludeCompanyName: this.watermarkIncludeCompanyName,
      watermarkIncludeProjectName: this.watermarkIncludeProjectName,
      watermarkIncludeTimestamp: this.watermarkIncludeTimestamp,
      watermarkIncludeGPSCoords: this.watermarkIncludeGPSCoords,
      watermarkIncludeAutoGenLocation: this.watermarkIncludeAutoGenLocation,
      watermarkAlignment: this.watermarkAlignment,
      breakSetting: this.breakSetting
    };
  }

  @computed
  get pdfLogoFormValues() {
    const company = this.rootStore.me.company;

    const primaryColor = this.primaryColor || company.preferences.primaryColor;
    const projectLogo = this.projectLogo || company.logo;

    return {
      primaryColor: primaryColor,
      projectLogo: projectLogo && projectLogo.contentUrl
    };
  }

  @computed
  get notificationSettingsFormValues() {
    return this.notificationSettings.models.map(
      notificationSetting => notificationSetting.formValues
    );
  }

  @computed
  get internalProjectTeam() {
    if (this.isChildProject) {
      return this.childProjects.models.find(
        projectTeam => projectTeam.id === this.teamId
      );
    }

    return this.parentProject;
  }

  @computed
  get externalProjectTeams() {
    if (this.isChildProject) {
      let teams = this.childProjects.models.filter(
        projectTeam => projectTeam.id !== this.teamId
      );

      teams.push(this.parentProject);

      return teams;
    }

    return orderBy(
      this.childProjects.models.slice(),
      [projectTeam => projectTeam.name && projectTeam.name.toLowerCase()],
      ['asc']
    );
  }

  @computed
  get allProjectTeams() {
    if (!this.hasSuperDaily) {
      return [this.internalProjectTeam];
    }

    return [].concat([this.internalProjectTeam, ...this.externalProjectTeams]);
  }

  @computed
  get activeExternalProjectTeams() {
    return this.externalProjectTeams.filter(
      projectTeam => projectTeam.projectState === 'ACTIVE'
    );
  }

  @computed
  get projectTeamIds() {
    return this.externalProjectTeams
      .map(projectTeam => projectTeam.id)
      .concat([this.teamId]);
  }

  @computed
  get projectTeamUuids() {
    return this.externalProjectTeams
      .map(projectTeam => projectTeam.uuid)
      .concat([this.uuid]);
  }

  @computed
  get isChildProject() {
    return this.projectType === 'CHILD';
  }

  @computed
  get image() {
    if (this.projectImage) {
      return this.projectImage.thumbUrl || this.projectImage.contentUrl;
    }

    return `${this.rootStore.assetsURL}/images/project.png`;
  }

  @computed
  get hasSuperDaily() {
    return (
      this.projectType === 'PARENT' && this.externalProjectTeams.length > 0
    );
  }

  @computed get materials() {
    return this.rootStore.materials.models.filter(material => {
      return material.getProjectByUuid(this.uuid);
    });
  }

  @computed
  get hasMaterials() {
    return this.materials.length > 0;
  }

  @computed
  get memberDirectoryLink() {
    return this._links.projectDirectoryPdf.href;
  }

  @computed
  get scopedStats() {
    if (!this.internalProjectTeam) return {};

    if (this.isChildProject) {
      return this.internalProjectTeam.stats;
    }

    return this.stats;
  }

  @action.bound
  refetchProjectStats() {
    return request
      .get(`ra/project-teams/${this.parentProjectId}/compliance`)
      .then(response => {
        if (!response.data) return;

        this.stats = Object.assign({}, response.data.totalStats);
        this.internalProjectTeam.stats = Object.assign(
          this.internalProjectTeam.stats,
          response.data.teamStats[this.internalProjectTeam.id]
        );
        this.externalProjectTeams.forEach(projectTeam => {
          projectTeam.stats = Object.assign(
            projectTeam.stats,
            response.data.teamStats[projectTeam.id]
          );
        });
      })
      .catch(error => {
        errorHandler(error, this.rootStore.notificationsUI.pushError);
      });
  }

  getActiveSegmentSetForDate(date) {
    if (this.sortedSegmentSetsDescending) {
      return this.sortedSegmentSetsDescending.find(segmentSet => {
        return (
          moment(segmentSet.applicationDate).isBefore(moment(date)) ||
          moment(segmentSet.applicationDate).isSame(moment(date))
        );
      });
    }

    return null;
  }

  getActiveSegmentsForDate(date) {
    if (this.getActiveSegmentSetForDate(date)) {
      return this.getActiveSegmentSetForDate(date).segments.slice();
    }

    return null;
  }
}

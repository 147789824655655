import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import formatTimestamp from 'utils/formatTimestamp';
import kebabCase from 'lodash.kebabcase';

export default class CertificationType extends Model {
  get restAttributes() {
    return [
      'uuid',
      'businessUuid',
      'name',
      'description',
      'createdBy',
      'updatedBy',
      'updatedTimestamp',
      'createdTimestamp'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return `/ra/companies/${this.rootStore.me.company.uuid}/certification-types`;
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      businessUuid: this.businessUuid,
      name: this.name,
      description: this.description,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedTimestamp: this.updatedTimestamp,
      createdTimestamp: this.createdTimestamp
    };
  }

  @computed
  get updatedTimestampFormatted() {
    return formatTimestamp(this.updatedTimestamp);
  }

  @computed
  get createdTimestampFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed get viewUrl() {
    return `/company/certifications/types/${this.uuid}`;
  }

  @computed get certificationTypeSlug() {
    return kebabCase(this.name);
  }
}

import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class IncidentReport300A extends Model {
  url() {
    return `${this.rootStore.appConfig.safety_api_url}/companies/${this.rootStore.me.company.uuid}/incident-reports/submission/form-300a`;
  }

  get restAttributes() {
    return [
      'id',
      'establishmentId',
      'yearFilingFor',
      'noInjuriesIllnesses',
      'annualAverageEmployees',
      'totalHoursWorked',
      'totalDeaths',
      'totalDAFWCases',
      'totalDJTRCases',
      'totalOtherCases',
      'totalDAFWDays',
      'totalDJTRDays',
      'totalInjuries',
      'totalSkinDisorders',
      'totalRespiratoryConditions',
      'totalPoisonings',
      'totalHearingLoss',
      'totalOtherIllnesses',
      'submissionStatus',
      'totalExpectedCases',
      'totalDeathCasesCount',
      'totalDAFWCasesCount',
      'totalDJTRCasesCount',
      'totalOtherCasesCount',
      'combinedSubmissionStatus',
      'tcr',
      'dart',
      'createdTimestamp',
      'lastEditedTimestamp',
      'links'
    ];
  }

  @computed
  get formValues() {
    return {
      annualAverageEmployees: this.annualAverageEmployees,
      totalHoursWorked: this.totalHoursWorked,
      noInjuriesIllnesses: this.noInjuriesIllnesses,
      totalDeaths: this.totalDeaths,
      totalDAFWCases: this.totalDAFWCases,
      totalDJTRCases: this.totalDJTRCases,
      totalOtherCases: this.totalOtherCases,
      totalDAFWDays: this.totalDAFWDays,
      totalDJTRDays: this.totalDJTRDays,
      totalInjuries: this.totalInjuries,
      totalSkinDisorders: this.totalSkinDisorders,
      totalRespiratoryConditions: this.totalRespiratoryConditions,
      totalPoisonings: this.totalPoisonings,
      totalHearingLoss: this.totalHearingLoss,
      totalOtherIllnesses: this.totalOtherIllnesses
    };
  }
}

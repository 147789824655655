import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CERTIFICATION_EDITED } from 'utils/segmentAnalytics/eventSpec';

import {
  CertificationForm,
  certificationFormFields,
  certificationFormLabels,
  certificationFormOptions,
  certificationFormPlugins,
  certificationFormRules
} from 'forms/certificationForm';

import Certifications from 'stores/collections/Certifications';
import CertificationTypes from 'stores/collections/CertificationTypes';

export default class CertificationEditUI extends UIStore {
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.certifications = new Certifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationTypes = new CertificationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryEditForm = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    this.certifications.fetch({
      params: {
        limit: 10000
      }
    });

    // For dropdown selection
    this.certificationTypes.fetch({
      params: {
        limit: 10000,
        sortField: 'name'
      }
    });

    this.setEntryEditForm();
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new CertificationForm(
      {
        fields: certificationFormFields,
        rules: certificationFormRules,
        labels: certificationFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: certificationFormOptions,
        plugins: certificationFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.certifications.clear();
    this.certificationTypes.clear();
    this.activeModal = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @action.bound
  async submitCertificationEditSuccess() {
    this.saving = true;
    const {
      name,
      type,
      certificationType
    } = this.entryEditForm.trimmedValues();
    try {
      await this.entryForEdit.save(
        {
          name,
          certificationType
        },
        {
          wait: true
        }
      );

      callTrack(CERTIFICATION_EDITED, {
        certification_name: name,
        certification_type_name: type
      });

      this.parent.parent.sortByLastUpdated();

      this.resetEntryEditForm();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Certification info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitCertificationEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitCertificationEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @computed get certificationTypeOptions() {
    return this.certificationTypes.models.map(certificationType => ({
      name: certificationType.name,
      uuid: certificationType.uuid
    }));
  }
}

import React from 'react';
import { observer } from 'mobx-react';
import { t } from 'utils/translate';

import { Alert } from '@raken/athens-web/lib';

export default observer(({ uiStore, resourceType }) => {
  return (
    <Alert>
      {t(
        'Your Raken instance is integrated with {integrationName}, which may manage the {resourceType} for your account. If you proceed to add or modify {resourceType} in Raken it will result in records that may not be synced correctly with {integrationName}. If the records should be synced with the integration, please create them in your {integrationName} instead.',
        {
          templateStrings: {
            integrationName: uiStore.erpIntegrationName,
            resourceType: resourceType
          }
        }
      )}
    </Alert>
  );
});

import { computed, action, observable, reaction, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import request from 'axios';

import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  SmallEquipmentWithdrawForm,
  smallEquipmentWithdrawFormOptions,
  smallEquipmentWithdrawFormFields,
  smallEquipmentWithdrawFormRules,
  smallEquipmentWithdrawFormLabels,
  smallEquipmentWithdrawFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentWithdraw';

import {
  SmallEquipmentWithdrawFiltersForm,
  smallEquipmentWithdrawFiltersFormOptions,
  smallEquipmentWithdrawFiltersFormFields,
  smallEquipmentWithdrawFiltersFormRules,
  smallEquipmentWithdrawFiltersFormLabels,
  smallEquipmentWithdrawFiltersFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentWithdrawFilters';

import SmallEquipmentDeployments from 'stores/collections/equipment/SmallEquipmentDeployments';

import { callTrack } from 'utils/segmentIntegration';
import { SMALL_EQUIPMENT_WITHDRAWN } from 'utils/segmentAnalytics/eventSpec';

export default class SmallEquipmentWithdrawUI extends UIStore {
  @observable smallEquipmentWithdrawForm;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable showFiltersModal;
  @observable smallEquipmentWithdrawFiltersForm;

  constructor(options) {
    super(options);

    this.smallEquipmentWithdrawForm = null;

    this.loading = false;
    this.saving = false;

    this.sortField = 'projectName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.showFiltersModal = false;
    this.smallEquipmentWithdrawFiltersForm = null;
    this.equipmentFilters = observable([]);
    this.projectsFilters = observable([]);

    // Equipment Deployments collection
    this.smallEquipmentDeployments = new SmallEquipmentDeployments(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSmallEquipmentDeploymentsDebounced = debounce(
      this.fetchSmallEquipmentDeployments,
      BASE_DEBOUNCE
    );
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      equipmentUuids: this.equipmentFilters
        .map(smallEquipment => smallEquipment.uuid)
        .join(','),
      projectUuids: this.projectsFilters
        .map(smallEquipment => smallEquipment.value)
        .join(',')
    };
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async withdrawSmallEquipment(selectedSmallEquipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.equipmentFilters.replace([
      {
        name: selectedSmallEquipment.name,
        uuid: selectedSmallEquipment.uuid
      }
    ]);

    this.showModal('SmallEquipmentWithdrawModalPage');

    await this.fetchSmallEquipmentDeployments();

    this.setupReactions();

    this.setupForm();
  }

  @action.bound async bulkWithdrawSmallEquipment() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.equipmentFilters.replace(
      this.parent.selectedSmallEquipment.map(smallEquipment => {
        return {
          name: smallEquipment.name,
          uuid: smallEquipment.uuid
        };
      })
    );

    this.showModal('SmallEquipmentWithdrawModalPage');

    await this.fetchSmallEquipmentDeployments();

    this.setupReactions();

    this.setupForm();
  }

  @action.bound setupForm() {
    this.smallEquipmentWithdrawForm = new SmallEquipmentWithdrawForm(
      {
        fields: smallEquipmentWithdrawFormFields,
        rules: smallEquipmentWithdrawFormRules,
        labels: smallEquipmentWithdrawFormLabels,
        values: {
          withdraws: this.smallEquipmentDeployments.models?.map(value => {
            return { ...value.formValues, numberOfUnits: 0 };
          })
        }
      },
      {
        options: smallEquipmentWithdrawFormOptions,
        plugins: smallEquipmentWithdrawFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.smallEquipmentWithdrawForm
      .$('withdraws')
      .fields.forEach(withdraw =>
        withdraw
          .$('numberOfUnits')
          .set(
            'rules',
            `numeric|min:0|max:${withdraw.$('projectDeployedUnits').value}`
          )
      );
  }

  @action.bound async cancelWithdrawSmallEquipment() {
    await this.hideActiveModal();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchSmallEquipmentDeploymentsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound async fetchSmallEquipmentDeployments() {
    this.loading = true;
    this.smallEquipmentWithdrawForm = null;

    this.smallEquipmentDeployments.cancelRequest();
    this.smallEquipmentDeployments.reset();

    try {
      await this.smallEquipmentDeployments.fetch({
        params: this.params
      });

      this.setupForm();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasSmallEquipmentDeployments() {
    return this.smallEquipmentDeployments.hasModels;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasSmallEquipmentDeployments;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(
      this.smallEquipmentDeployments.totalElements / this.pageSize
    );
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.smallEquipmentWithdrawForm = null;
    this.tearDownReactions();
    this.searchQuery = '';
    this.page = 1;
    this.loading = false;
    this.saving = false;
    this.sortField = 'name';
    this.sortDirection = 'asc';
    this.showFiltersModal = false;
    this.smallEquipmentWithdrawFiltersForm = null;
  }

  @action.bound submitEquipmentWithdrawForm(event) {
    event.preventDefault();

    this.smallEquipmentWithdrawForm.submit({
      onSuccess: this.submitEquipmentWithdrawFormSuccess,
      onError: this.submitEquipmentWithdrawFormError
    });
  }

  @action.bound async submitEquipmentWithdrawFormSuccess() {
    this.saving = true;

    const values = this.smallEquipmentWithdrawForm.values();

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/smallequipment/withdraw/batch`,
        values.withdraws
          .map(withdraw => {
            return {
              equipmentUuid: withdraw.equipmentUuid,
              projectUuid: withdraw.projectUuid,
              quantity: Number(withdraw.numberOfUnits)
            };
          })
          .filter(withdraw => withdraw.quantity)
      );

      values.withdraws.forEach(withdraw => {
        callTrack(SMALL_EQUIPMENT_WITHDRAWN, {
          small_equipment_name: withdraw.equipmentName,
          units_withdrawn: withdraw.numberOfUnits
        });
      });

      this.parent.selectedSmallEquipment.clear();

      this.cancelWithdrawSmallEquipment();

      this.parent.parent.refetchSmallEquipment();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentWithdrawFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  // Filters
  @action.bound showFilters() {
    // Setup filter selectors
    this.smallEquipmentSelectorUI.setup();
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.showFiltersModal = true;

    this.smallEquipmentWithdrawFiltersForm = new SmallEquipmentWithdrawFiltersForm(
      {
        fields: smallEquipmentWithdrawFiltersFormFields,
        rules: smallEquipmentWithdrawFiltersFormRules,
        labels: smallEquipmentWithdrawFiltersFormLabels,
        values: {
          smallEquipment: this.equipmentFilters.slice(),
          projects: this.projectsFilters ? this.projectsFilters.slice() : []
        }
      },
      {
        options: smallEquipmentWithdrawFiltersFormOptions,
        plugins: smallEquipmentWithdrawFiltersFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound clearAllWithdrawFilters() {
    this.equipmentFilters.clear();
    this.projectsFilters.clear();

    this.cancelWithdrawFilters();
  }

  @action.bound async cancelWithdrawFilters() {
    this.showFiltersModal = false;

    this.smallEquipmentSelectorUI.tearDown();
    this.projectSelectorUI.tearDown();
  }

  @action.bound submitWithdrawFiltersSmallEquipmentForm(event) {
    event.preventDefault();

    this.smallEquipmentWithdrawFiltersForm.submit({
      onSuccess: this.submitWithdrawFiltersSmallEquipmentFormSuccess,
      onError: this.submitWithdrawFiltersSmallEquipmentFormError
    });
  }

  @action.bound submitWithdrawFiltersSmallEquipmentFormSuccess() {
    const values = this.smallEquipmentWithdrawFiltersForm.values();

    this.equipmentFilters.replace(values.smallEquipment);
    this.projectsFilters.replace(values.projects);

    this.cancelWithdrawFilters();
  }

  @action.bound submitWithdrawFiltersSmallEquipmentFormError() {
    console.error(this.equipmentFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.equipmentFilters.length) {
      counter += 1;
    }

    if (this.projectsFilters.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }
}

import { Model } from 'mobx-mc';
import TimeEntries from 'stores/collections/workLogs/TimeEntries';
import TimeCardBreaks from 'stores/collections/workLogs/TimeCardBreaks';
import Attachments from 'stores/collections/Attachments';
import CostCodeEntries from 'stores/collections/CostCodeEntries';
import { t } from 'utils/translate';

import Worker from '../Worker';
import omit from 'lodash.omit';
import moment from 'moment';

import { action, computed } from 'mobx';

class TimeCard extends Model {
  get urlRoot() {
    /**
     * Set a url for instances where we create a new model to edit.
     * Ie. for timesheets we do this when editing a time card
     */
    return this.parent?.url
      ? this.parent.url
      : `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/timecards`;
  }

  get restAttributes() {
    return [
      'uuid',
      'note',
      'startTime',
      'endTime',
      'date',
      'breaks',
      'timeCardStatus',
      'integrationSyncStatus',
      'totalHours',
      'manualTimeEntry',
      'projectUuid',
      'projectName',
      'projectNumber',
      'timeCardDetails'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(data) {
    this.parseAttachments(data.attachments);
    this.parseTimeEntries(data.timeEntries);
    this.parseBreaks(data.breaks);
    this.parseWorker(data.worker);
    this.parseCostCodeEntries(data);

    return {
      ...omit(data, ['attachments', 'timeEntries', 'worker', 'breaks'])
    };
  }

  @action.bound
  parseCostCodeEntries(attributes) {
    this.costCodeEntries = new CostCodeEntries(
      attributes.costCodes ? attributes.costCodes : null,
      {
        rootStore: this.rootStore,
        parent: this
      }
    );
  }

  @action.bound
  parseAttachments(attachments) {
    if (this.attachments) {
      this.attachments.reset(attachments);
    } else {
      this.attachments = new Attachments(attachments, {
        rootStore: this.rootStore,
        parent: this
      });
    }
  }

  @action.bound
  parseTimeEntries(timeEntries) {
    this.timeEntries = new TimeEntries(timeEntries, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound
  parseBreaks(breaks) {
    this.breaks = new TimeCardBreaks(breaks, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound
  parseWorker(worker) {
    //TO DO: delete when we update a time card `worker` field and it matches /members scheme
    //Create a computed classificationName after

    if (worker.classification) {
      worker.classificationName = worker.classification.name;
    }

    if (!worker.classification) {
      worker.classification = {
        name: worker.classificationName,
        uuid: worker.classificationUuid
      };
    }

    this.worker = new Worker(
      { ...worker },
      {
        parent: this,
        rootStore: this.rootStore
      }
    );
  }

  @computed
  get isApproved() {
    return this.timeCardStatus?.status === 'APPROVED';
  }

  @computed
  get isApprovedAndLockedForEditing() {
    return (
      !this.rootStore.authorizationUI.canEditApprovedTimeCards &&
      this.timeCardStatus?.status === 'APPROVED'
    );
  }

  @computed get isSynced() {
    return (
      this.integrationSyncStatus === 'PENDING' ||
      this.integrationSyncStatus === 'SENT' ||
      this.integrationSyncStatus === 'SUCCESS' ||
      this.integrationSyncStatus === 'PENDING_RETRIEVAL'
    );
  }

  @computed get isSyncedAndLockedForEditing() {
    return (
      this.integrationSyncStatus === 'PENDING' ||
      this.integrationSyncStatus === 'SENT' ||
      this.integrationSyncStatus === 'SUCCESS' ||
      this.integrationSyncStatus === 'PENDING_RETRIEVAL'
    );
  }

  @computed get isLockedForEditing() {
    return (
      !this.rootStore.authorizationUI.canCreateCrewWorkLog ||
      this.isSynced ||
      this.isApprovedAndLockedForEditing
    );
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      note: this.note,
      initialTotalHours: this.totalHours || 0,
      startTime: this.startTime,
      endTime: this.endTime,
      hoursWorked: 0,
      projectUuid: this.projectUuid
    };
  }

  @computed
  get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @computed get timeCardHoursFromNow() {
    const now = moment();

    const timeCardDateTime = `${this.date}T${this.startTime}`;

    return now.diff(timeCardDateTime, 'hours');
  }

  @computed
  get costCodeUuids() {
    return this.costCodeEntries.models.map(costCodeEntry => {
      return costCodeEntry.costCode.uuid;
    });
  }

  @computed
  get firstCostCodeEntry() {
    return this.costCodeEntries.length && this.costCodeEntries.at(0);
  }

  @computed
  get fullName() {
    return this.worker.fullName;
  }

  @computed
  get classificationName() {
    return this.classification && this.classification.name;
  }

  @computed
  get parsedTotalHours() {
    return parseFloat(
      (this.doubleTime + this.overTime + this.regularTime).toFixed(2)
    );
  }

  @computed get integrationSyncStatusColor() {
    switch (this.integrationSyncStatus) {
      case 'PENDING':
        return 'orange';
      case 'SENT':
      case 'PENDING_RETRIEVAL':
        return 'orange';
      case 'ERROR':
        return 'red';
      case 'SUCCESS':
        return 'green';
      default:
        return 'lightGrey';
    }
  }

  @computed get integrationSyncStatusText() {
    switch (this.integrationSyncStatus) {
      case 'PENDING':
        return t('Sync in progress');
      case 'SENT':
        return t('Pending confirmation');
      case 'ERROR':
        return t('Sync failed');
      case 'SUCCESS':
        return t('Synced');
      case 'PENDING_RETRIEVAL':
        return t('Pending retrieval');
      default:
        return t('Not synced');
    }
  }

  @computed
  get approvedByStatusFullName() {
    return this.timeCardStatus?.approvedBy?.fullName;
  }

  @computed get fromKioskOrTimeClock() {
    return (
      this.timeCardDetails?.hasKioskActivity ||
      this.timeCardDetails?.hasTimeClockActivity
    );
  }

  @computed
  get kioskTimeClockStatuses() {
    if (!this.fromKioskOrTimeClock) return [];

    let statuses = [];

    if (!this.endTime && this.timeCardHoursFromNow >= 24) {
      statuses.push('MoreThan24Hours');
    }

    if (this.timeCardDetails.outOfProjectArea) {
      statuses.push('OutOfProjectArea');
    }

    if (this.timeCardDetails.noGpsFix) {
      statuses.push('NoGpsFix');
    }

    return statuses;
  }

  @computed get showWarningIcon() {
    return (
      this.kioskTimeClockStatuses.includes('OutOfProjectArea') ||
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    );
  }

  @computed get warningIconText() {
    if (
      this.kioskTimeClockStatuses.includes('OutOfProjectArea') &&
      this.kioskTimeClockStatuses.includes('MoreThan24Hours')
    ) {
      return t(
        'This time card has no end time, spans over 24 hours, and has event(s) that occurred outside of the job site'
      );
    }

    if (this.kioskTimeClockStatuses.includes('OutOfProjectArea')) {
      return t(
        'This time card has event(s) that occurred outside of the job site'
      );
    }

    if (this.kioskTimeClockStatuses.includes('MoreThan24Hours')) {
      return t('This time card has no end time and spans over 24 hours');
    }

    return '';
  }

  @computed get showNoGpsIcon() {
    return this.kioskTimeClockStatuses.includes('NoGpsFix');
  }

  @computed get hasBreaks() {
    return this.breaks.length > 0;
  }

  @computed get totalBreaks() {
    return this.breaks.length;
  }

  @computed get totalBreaksDuration() {
    if (!this.hasBreaks) return null;

    const duration = this.breaks.models.reduce(
      (acc, breakModel) => acc + breakModel.duration,
      0
    );

    return moment.utc(duration * 60 * 1000).format('HH:mm');
  }

  @computed
  get uniqueCostCodes() {
    return this.timeEntries.models.reduce((acc, timeEntry) => {
      if (
        timeEntry.costCode?.uuid &&
        !acc.find(costCode => costCode.uuid === timeEntry.costCode.uuid)
      ) {
        return [...acc, timeEntry.costCode];
      }

      return acc;
    }, []);
  }

  @computed get costCodeDescription() {
    if (!this.uniqueCostCodes.length) return null;

    if (this.uniqueCostCodes.length === 1) {
      return this.uniqueCostCodes[0].costCodeKey;
    }

    return t('{costCodeCount} Cost codes', {
      templateStrings: {
        costCodeCount: this.uniqueCostCodes.length
      }
    });
  }

  @computed get dateFormatted() {
    return moment(this.date).format('ddd, MMM DD');
  }

  @computed get startTimeFormatted() {
    return moment(this.startTime, 'HH:mm:ss').format('hh:mm a');
  }

  @computed get endTimeFormatted() {
    return moment(this.endTime, 'HH:mm:ss').format('hh:mm a');
  }

  @computed get hasNoteOrAttachments() {
    return this.note || this.attachments.hasModels;
  }

  @computed get noteAndAttachmentsCount() {
    return this.attachments.length + Boolean(this.note);
  }
}

export default TimeCard;

import UIStore from './UIStore';

import { action, computed, observable, runInAction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import request from 'axios';
import sortBy from 'lodash.sortby';

import { t } from 'utils/translate';

export default class PayPeriodSelectorUI extends UIStore {
  @observable selectedPeriod;
  @observable recentPayPeriodOptions;
  @observable payPeriodsOptions;
  @observable loading;

  constructor(options) {
    super(options);

    this.selectedPeriod = {
      name: '',
      value: {
        from: '',
        to: ''
      }
    };

    // Pay periods
    this.recentPayPeriodOptions = [];
    this.payPeriodsOptions = [];
    this.loading = true;
  }

  @computed get hasPayPeriodsOptions() {
    return this.payPeriodsOptions.length > 0;
  }

  @computed get payPeriodsLoaded() {
    return Boolean(!this.loading && this.hasPayPeriodsOptions);
  }

  @computed get payPeriodsEmpty() {
    return Boolean(
      !this.loading &&
        this.payPeriodsOptions.length === 1 &&
        this.payPeriodsOptions[0].name === 'View All'
    );
  }

  @computed get payPeriodsUrl() {
    return this.rootStore.isSuperAdmin
      ? `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.superAdminUI.superAdminCompaniesUI.activeCompany.id
        }/payperiods`
      : `${this.rootStore.urlMicroService('performanceTracking')}/payperiods`;
  }

  @action.bound
  async setup() {
    this.loading = true;

    try {
      await this.fetchRecentPayPeriods();
      this.fetchAllPayPeriods();
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchRecentPayPeriods() {
    //catch last periods as a first step and unblock Select Period filter
    const responseRecent = await request.get(this.payPeriodsUrl, {
      params: {
        period: 'recent'
      }
    });

    runInAction(() => {
      this.transformRecentPeriodsIntoOptions(responseRecent.data);
      this.payPeriodsOptions = this.recentPayPeriodOptions;
      // Make first pay period currently selected if we don't already have a pay period
      if (!this.selectedPeriod?.value.from && !this.selectedPeriod?.value.to) {
        this.selectedPeriod = this.payPeriodsOptions[0];
      } else {
        this.selectedPeriod = { ...this.selectedPeriod };
      }
    });
  }

  @action.bound async fetchAllPayPeriods() {
    //fetch other periods after in background
    const responseAll = await request.get(this.payPeriodsUrl, {
      params: {
        period: 'ALL'
      }
    });

    runInAction(() => {
      const allPeriodOptions = this.transformAllPeriodsIntoOptions(
        responseAll.data
      );
      this.payPeriodsOptions = allPeriodOptions;
    });
  }

  @action.bound
  tearDown() {
    this.selectedPeriod = {
      name: '',
      value: {
        from: '',
        to: ''
      }
    };
  }

  @action.bound
  selectPeriod(period) {
    this.selectedPeriod = period;
    this.page = 1;
  }

  @computed get selectedPeriodIndex() {
    return this.payPeriodsOptions.findIndex(
      option => option.name === this.selectedPeriod?.name
    );
  }

  @computed get nextPeriod() {
    return this.payPeriodsOptions[this.selectedPeriodIndex - 1];
  }

  @computed get hasNextPeriod() {
    if (this.nextPeriod?.name === t('View All')) {
      return false;
    }

    return this.selectedPeriodIndex > 0;
  }

  @computed get previousPeriod() {
    return this.payPeriodsOptions[this.selectedPeriodIndex + 1];
  }

  @computed get hasPreviousPeriod() {
    if (this.previousPeriod?.name === t('View All')) {
      return false;
    }

    return this.selectedPeriodIndex < this.payPeriodsOptions.length - 1;
  }

  @action.bound selectNextPeriod() {
    this.selectPeriod(this.nextPeriod);
  }

  @action.bound selectPreviousPeriod() {
    this.selectPeriod(this.previousPeriod);
  }

  @computed get selectedPayPeriodOption() {
    if (this.loading) {
      return {
        name: t('Loading...')
      };
    }

    if (this.payPeriodsEmpty) {
      return {
        name: t('No pay periods')
      };
    }

    return this.selectedPeriod;
  }

  transformRecentPeriodsIntoOptions(data) {
    const rawRecentPayPeriodOptions = [];

    for (const year of data.years) {
      for (const month of year.months) {
        for (const period of month.periods) {
          rawRecentPayPeriodOptions.push({
            name: period.displayName,
            value: {
              from: period.fromDate,
              to: period.toDate
            },
            year: period.year,
            month: period.month
          });
        }
      }
    }

    const sortedRecentPayPeriodOptions = sortBy(rawRecentPayPeriodOptions, [
      'year',
      'month'
    ]).reverse();

    this.recentPayPeriodOptions = sortedRecentPayPeriodOptions;
  }

  transformAllPeriodsIntoOptions(data) {
    const payPeriodAllOptions = data.years.map(year => {
      const yearSubOptions = year.months.map(month => {
        const monthSubOptions = month.periods.map(period => {
          return {
            name: period.displayName,
            value: {
              from: period.fromDate,
              to: period.toDate
            }
          };
        });

        return {
          name: month.name,
          subOptions: monthSubOptions
        };
      });

      return {
        name: year.name.toString(),
        subOptions: yearSubOptions
      };
    });

    return [
      ...this.payPeriodsOptions,
      {
        name: t('View All'),
        subOptions: payPeriodAllOptions
      }
    ];
  }
}

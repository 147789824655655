import { reaction, action, computed, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentLogReportFormPlugins = extendedPlugins;

const equipmentLogReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const equipmentLogReportFormFields = [
  'uuid',
  'name',
  'projectIncludeOption',
  'projects',
  'groups',
  'projectEntire',
  'sendReport',
  'time',
  'timeFrameType',
  'fromDate',
  'toDate',
  'actionDate',
  'email',
  'emails'
];

const equipmentLogReportFormRules = {
  timeFrameType: 'required',
  emails: 'array|required',
  email: 'required_without:emails|email',
  projects: 'array|required_if:projectIncludeOption,SELECTED',
  groups: 'array|required_if:projectIncludeOption,SELECTED_GROUPS'
};

const equipmentLogReportFormValues = {
  uuid: '',
  name: '',
  projectIncludeOption: '',
  projects: [],
  groups: [],
  time: '',
  timeFrameType: '',
  projectEntire: false,
  fromDate: '',
  toDate: '',
  actionDate: '',
  sendReport: false,
  emails: [],
  email: ''
};

const equipmentLogReportFormLabels = {
  name: t('Report name'),
  projects: t('Projects to include'),
  timeFrame: t('Time frame'),
  recurringOn: t('Recurring on'),
  emails: t('Send download link to'),
  sendReport: t('Also send report immediately')
};

class EquipmentLogReportForm extends MobxReactForm {
  @observable reportType;

  constructor(settings, options) {
    super(settings, options);

    this.reportType = options.reportType;

    this.cancelReactToFromToDate = reaction(
      () => this.enableFromToDate,
      enableFromToDate => {
        if (enableFromToDate) {
          this.$('fromDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
          this.$('toDate').set(
            'rules',
            'required|dateCustom:YYYY-MM-DD|afterOrSameAsCustom:fromDate,YYYY-MM-DD'
          );
        } else {
          this.$('fromDate').set('rules', '');
          this.$('toDate').set('rules', '');
        }

        this.validate();
      },
      {
        fireImmediately: true
      }
    );

    this.cancelReactToActionDate = reaction(
      () => this.enableActionDate,
      enableActionDate => {
        if (enableActionDate) {
          this.$('actionDate').set('rules', 'required|dateCustom:YYYY-MM-DD');
        } else {
          this.$('actionDate').set('rules', '');
        }

        this.validate();
      },
      {
        fireImmediately: true
      }
    );
  }

  @action.bound
  clearTimeFrame() {
    this.update({
      timeFrameType: '',
      fromDate: '',
      toDate: ''
    });
  }

  @computed get enableFromToDate() {
    if (this.reportType === 'runOnce') {
      return this.$('timeFrameType').value === 'SPECTIMEFRAME';
    }

    return false;
  }

  @computed get enableSchedule() {
    return true;
  }

  @computed get enableActionDate() {
    if (this.reportType === 'scheduled') {
      return (
        this.$('timeFrameType').value === 'WEEKLY' ||
        this.$('timeFrameType').value === 'BI_WEEKLY' ||
        this.$('timeFrameType').value === 'MONTHLY'
      );
    }

    return false;
  }
}

export {
  EquipmentLogReportForm,
  equipmentLogReportFormOptions,
  equipmentLogReportFormFields,
  equipmentLogReportFormRules,
  equipmentLogReportFormValues,
  equipmentLogReportFormLabels,
  equipmentLogReportFormPlugins
};

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';
import trimObject from '../utils/trimObject';
import { t } from 'utils/translate';

const observationTypeFormPlugins = extendedPlugins;

const observationTypeFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const observationTypeFormFields = [
  'type',
  'typeClass',
  'typeName',
  'subType',
  'negative',
  'positive'
];

const observationTypeFormValues = {
  negative: false,
  positive: false
};

const observationTypeFormRules = {
  type: 'required_without:typeName',
  typeClass: 'required_without:type',
  typeName: 'string|required_without:type|max:250',
  subType: 'string|required|max:250',
  negative: 'boolean|positiveOrNegative',
  positive: 'boolean|positiveOrNegative'
};

const observationTypeFormLabels = {
  type: t('type'),
  typeName: t('type name'),
  typeClass: t('class'),
  subType: t('subtype')
};

const observationTypeFormRelated = {
  negative: ['positive'],
  positive: ['negative']
};

class ObservationTypeForm extends MobxReactForm {
  trimmedValues() {
    return trimObject(this.values());
  }
}

export {
  ObservationTypeForm,
  observationTypeFormPlugins,
  observationTypeFormOptions,
  observationTypeFormFields,
  observationTypeFormRules,
  observationTypeFormLabels,
  observationTypeFormValues,
  observationTypeFormRelated
};

import UIStore from './UIStore';
import { reaction, computed, action, observable } from 'mobx';
import crossOriginDownload from 'utils/crossOriginDownload';
import { callTrack } from 'utils/segmentIntegration';
import { FAVORITE_SELECTED } from 'utils/segmentAnalytics/eventSpec';

export default class MediaViewerUI extends UIStore {
  @observable items;
  @observable selectedIndex;
  @observable mediaViewerProps;
  @observable isOpen;
  @observable isEdit;
  @observable confirmDeleteModal;

  constructor(options) {
    super(options);

    this.reset();
    this.setupReactions();
  }

  @action.bound
  reset() {
    this.items = [];
    this.selectedIndex = 0;
    this.mediaViewerProps = {};
    this.isOpen = false;
    this.isEdit = false;
    this.confirmDeleteModal = false;
  }

  setupReactions() {
    this.reactToItemLength = reaction(
      () => this.items.length,
      count => {
        if (count < 1) {
          this.handleClose();
        }
      }
    );
  }

  /**
   * Call using `onClick = {() => rootStore.mediaViewerUI.open({ items, index, props })}`
   *
   * @param {Object} params Main function parameter
   * @param {Array} params.items Array of media items
   * @param {Number} params.index The index to open
   * @param {Object} params.props Props that will be passed on to the <MediaViewer> component.
   */
  @action.bound
  open(params = {}) {
    const { items = [], index = 0, props = {} } = params;

    this.items = items.slice() || [];
    this.selectedIndex = index || 0;
    this.mediaViewerProps = props || {};

    if (!this.isSupportedFile(this.currentMedia)) {
      window.open(this.currentMedia.contentUrl, '_blank');
      return;
    }

    this.isOpen = true;
  }

  isSupportedFile(media = this.currentMedia) {
    if (!media) console.error('Media is not available.');

    if (!Boolean(media.thumbnail)) {
      return false;
    }

    return true;
  }

  @computed
  get currentMedia() {
    return this.items[this.selectedIndex];
  }

  @action.bound
  handleClose() {
    this.reset();
  }

  @computed
  get hasItems() {
    return this.items.length > 0;
  }

  @computed
  get lastItem() {
    return this.selectedIndex === this.items.length - 1;
  }

  @computed
  get hasNext() {
    return this.selectedIndex < this.items.length - 1;
  }

  @computed
  get hasPrevious() {
    return this.selectedIndex > 0;
  }

  @computed get enableFavorite() {
    return this.authorization?.onPerformancePlan;
  }

  @computed
  get isFavorite() {
    return this.currentMedia?.isFavorite;
  }

  @action.bound
  handleNext() {
    if (this.isOpen && this.hasNext) {
      this.disableEdit();
      this.selectedIndex += 1;
    }
  }

  @action.bound
  handlePrevious() {
    if (this.isOpen && this.hasPrevious) {
      this.disableEdit();
      this.selectedIndex -= 1;
    }
  }

  @action.bound
  enableEdit() {
    this.isEdit = true;
  }

  @action.bound
  disableEdit() {
    this.isEdit = false;
    this.clearValidationDetails();
  }

  @action.bound
  async handleFavorite(media = this.currentMedia) {
    await this.authorization.checkFeatureAccess('FavoriteMedia');
    media.save({ isFavorite: !media.isFavorite });
    if (media.isFavorite) {
      callTrack(FAVORITE_SELECTED);
    }
  }

  @action.bound
  handleDownload(filename = this.currentMedia.fileName) {
    return new Promise((resolve, reject) => {
      if (this.currentMedia) {
        crossOriginDownload(
          `${this.currentMedia.contentUrl}?download`,
          filename,
          () => resolve()
        );
      } else {
        reject();
      }
    });
  }

  @action.bound
  openConfirmDeleteModal() {
    this.confirmDeleteModal = true;
  }

  @action.bound
  closeConfirmDeleteModal() {
    this.confirmDeleteModal = false;
  }

  @action.bound
  async handleConfirmDelete(media = this.currentMedia) {
    const originalIndex = this.selectedIndex;

    this.confirmDeleteModal = false;
    this.handleDeleteLastItem();

    this.items.splice(originalIndex, 1); // Removes said array

    await media.confirmDeleteAttachment(media);

    this.rootStore.notificationsUI.pushNotification({
      title: `File deleted.`,
      snackbar: 'warning',
      icon: 'notification'
    });
  }

  /**
   * So that it moves to the second last item after deleting the last one.
   */
  handleDeleteLastItem() {
    if (this.selectedIndex === this.items.length - 1) {
      this.handlePrevious();
    }
  }
}

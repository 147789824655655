import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import formatTimestamp from 'utils/formatTimestamp';

export default class ObservationType extends Model {
  get restAttributes() {
    return [
      'uuid',
      'creationType',
      'typeClass',
      'type',
      'subType',
      'negative',
      'positive',
      'createdTimestamp',
      'updatedTimestamp'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService('toolbox')}/companies/${
      this.rootStore.me.company.uuid
    }/observations/types`;
  }

  @computed
  get createdTimestampFormatted() {
    return formatTimestamp(this.createdTimestamp);
  }

  @computed
  get updatedTimestampFormatted() {
    return this.updatedTimestamp ? formatTimestamp(this.updatedTimestamp) : '-';
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      typeClass: this.typeClass,
      typeName: this.type,
      negative: this.negative,
      positive: this.positive,
      subType: this.subType
    };
  }
}

import React from 'react';
import { t } from 'utils/translate';

const contentStyle = {
  fontFamily: `-apple-system, BlinkMacSystemFont, sans-serif`,
  fontWeight: 500,
  lineHeight: 1.75,
  whiteSpace: 'normal'
};

const headingStyle = {
  width: '100%',
  textAlign: 'center'
};

const textLeftRightStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
};

const imageContainerStyle = {
  width: '50px',
  height: '50px',
  display: 'inline-flex',
  overflow: 'hidden',
  position: 'relative',
  fontSize: '2rem',
  alignItems: 'center',
  flexShrink: 0,
  lineHeight: 1,
  userSelect: 'none',
  borderRadius: '50%',
  justifyContent: 'center'
};

const imageStyle = {
  color: 'transparent',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  textAlign: 'center'
};

const buttonLink = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 16px',
  height: '14px',
  border: '1px solid #B85900',
  borderRadius: '6px',
  color: '#B85900',
  textDecoration: 'none',
  cursor: 'pointer'
};

const projectState = {
  padding: '1px 4px 3px',
  height: '22px',
  borderRadius: '6px'
};

const textStyle = { margin: '0' };

const ProjectMapInfoWindow = ({ project, idHash, showEventsButton }) => {
  let projectStateColor;
  if (project.projectState === 'ACTIVE') {
    projectStateColor = { background: '#A7ED97', border: '1px solid #339C1C' };
  } else if (project.projectState === 'INACTIVE') {
    projectStateColor = {
      background: '#E4E3E2',
      border: '1px solid #B4B3B1'
    };
  } else {
    projectStateColor = {
      background: '#272626',
      color: '#FFF',
      border: '1px solid #686664'
    };
  }

  return (
    <>
      <div id="content" style={contentStyle}>
        <div id="bodyContent">
          {project.projectLogo && (
            <div style={headingStyle}>
              <div style={imageContainerStyle}>
                <img
                  src={project.projectLogo}
                  style={imageStyle}
                  alt={'avatar'}
                />
              </div>
            </div>
          )}
          <h3
            id={`firstHeading-${idHash}`}
            className="firstHeading"
            style={{ ...textStyle, ...headingStyle }}
          >
            {project.name}
          </h3>
          <div
            style={{
              marginTop: '8px'
            }}
          >
            <p style={{ ...textStyle, ...headingStyle, color: '#686664' }}>
              <span
                style={{
                  marginRight:
                    project.projectNo && project.projectState ? '8px' : '0'
                }}
              >
                {project.projectNo}
              </span>
              {project.projectNo && project.projectState ? '|' : ''}
              <span
                style={{
                  ...projectState,
                  ...projectStateColor,
                  ...{
                    marginLeft:
                      project.projectNo && project.projectState ? '8px' : '0'
                  }
                }}
              >
                {project.projectState.toLowerCase()}
              </span>
            </p>
          </div>
          <div
            style={{
              marginTop: '8px'
            }}
          >
            <p style={{ ...textStyle, ...headingStyle }}>
              {project.address.streetAddress && (
                <>
                  {project.address.streetAddress}
                  {', '}
                </>
              )}
              <br />
              {project.address.state && (
                <>
                  {project.address.state}
                  {', '}
                </>
              )}
              {project.address.postCode}
            </p>
          </div>
          <div
            style={{
              padding: '8px',
              borderTop: ' 1px solid #E4E3E2',
              marginTop: '8px'
            }}
          >
            <div style={textLeftRightStyle}>
              <p style={{ margin: '0 30px 0 0', color: '#8F8C8A' }}>
                {t('Start date')}
              </p>
              <p style={{ ...textStyle }}>{project.startDate}</p>
            </div>
            {project.endDate && (
              <div style={textLeftRightStyle}>
                <p style={{ margin: '0 30px 0 0', color: '#8F8C8A' }}>
                  {t('End date')}
                </p>
                <p style={{ ...textStyle }}>{project.endDate}</p>
              </div>
            )}
            <div style={{ marginTop: '20px' }}>
              <div style={buttonLink} id={`view-project-${idHash}`}>
                {t('View project')}
              </div>
            </div>
            {showEventsButton && (
              <div style={{ marginTop: '10px' }}>
                <div style={buttonLink} id={`view-event-${idHash}`}>
                  {t('View events')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectMapInfoWindow;

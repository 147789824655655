import request from 'axios';
import { observable, action, computed, reaction } from 'mobx';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import Groups from 'stores/collections/groups/Groups';
import UIStore from './UIStore';
import ProjectMemberships from '../collections/ProjectMemberships';

import { BASE_DEBOUNCE } from 'fixtures/constants';

export default class TeamMembersActionsProjectsUI extends UIStore {
  // Projects
  @observable projectSelectionMethod;
  @observable loading;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;

  constructor(options) {
    super(options);

    // Projects
    this.paginatedProjects = new ProjectMemberships(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.paginatedGroups = new Groups(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;
    this.projectSelectionMethod = 'ACTIVE';
    this.selectedProjects = observable([]);
    this.selectedGroups = observable([]);
    this.sortField = 'name';
    this.sortDirection = 'ASC';
    this.searchQuery = '';
    this.pageSize = 10;
    this.page = 1;

    this.fetchProjectsDebounced = debounce(this.fetchProjects, BASE_DEBOUNCE);
    this.fetchGroupsDebounced = debounce(this.fetchGroups, BASE_DEBOUNCE);
  }

  // Used to fetch available project memberships for authenticated
  // user.  For workers we just use the projects endpoint
  @computed get memberUuid() {
    return this.me.uuid;
  }

  @computed get projects() {
    return this.paginatedProjects;
  }

  @computed get groups() {
    return this.paginatedGroups;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @computed get hasGroups() {
    return this.groups.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchProjects();
    this.fetchGroups();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.cancelReactToParams = reaction(
      () => this.params,
      params => {
        if (this.projectSelectionMethod === 'SELECTED_GROUPS') {
          this.fetchGroups();
        } else {
          this.fetchProjects();
        }
      }
    );

    this.cancelReactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        if (this.projectSelectionMethod === 'SELECTED_GROUPS') {
          this.fetchGroupsDebounced();
        } else {
          this.fetchProjectsDebounced();
        }
      }
    );
  }

  tearDownReactions() {
    this.cancelReactToParams && this.cancelReactToParams();
    this.cancelReactToSearchQuery && this.cancelReactToSearchQuery();
  }

  @action.bound
  async fetchProjects() {
    this.loading = true;

    await this.projects.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  async fetchGroups() {
    this.loading = true;

    await this.groups.fetch({
      params: pickBy(
        Object.assign(
          {
            limit: this.pageSize,
            offset: (this.page - 1) * this.pageSize
          },
          {
            query: this.searchQuery,
            status: 'ACTIVE'
          }
        ),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      projectStates: 'ACTIVE'
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }
  }

  @computed
  get totalPages() {
    if (this.projectSelectionMethod === 'SELECTED_GROUPS') {
      return Math.ceil(this.groups.totalElements / this.pageSize);
    }

    return Math.ceil(this.projects.totalElements / this.pageSize);
  }

  @action.bound setProjectSelectionMethod(event) {
    this.clearSearchQuery();

    if (this.projectSelectionMethod === 'SELECTED_GROUPS') {
      this.fetchGroups();
    } else {
      this.fetchProjects();
    }

    this.selectedProjects.clear();
    this.selectedGroups.clear();

    if (event.target.value === 'ACTIVE') {
      this.selectAllProjects();
    }

    this.projectSelectionMethod = event.target.value;
    this.page = 1;
  }

  @computed get hasSelectedProjects() {
    return this.selectedProjects.length > 0;
  }

  @computed get hasSelectedGroups() {
    return this.selectedGroups.length > 0;
  }

  @action.bound
  toggleSelectProject(projectUuid) {
    if (this.selectedProjects.find(uuid => uuid === projectUuid)) {
      this.selectedProjects.remove(projectUuid);
    } else {
      this.selectedProjects.push(projectUuid);
    }
  }

  @action.bound
  toggleSelectGroup(projectUuid) {
    if (this.selectedGroups.find(uuid => uuid === projectUuid)) {
      this.selectedGroups.remove(projectUuid);
    } else {
      this.selectedGroups.push(projectUuid);
    }
  }

  @computed
  get allProjectsSelected() {
    return (
      this.hasProjects &&
      this.projects.models.every(project =>
        this.selectedProjects.includes(project.uuid)
      )
    );
  }

  @computed
  get allGroupsSelected() {
    return (
      this.hasGroups &&
      this.groups.models.every(group =>
        this.selectedGroups.includes(group.uuid)
      )
    );
  }

  @action.bound selectAllProjects() {
    this.projects.models.forEach(project => {
      if (!this.selectedProjects.includes(project.uuid)) {
        this.selectedProjects.push(project.uuid);
      }
    });
  }

  @action.bound selectAllGroups() {
    this.groups.models.forEach(group => {
      if (!this.selectedGroups.includes(group.uuid)) {
        this.selectedGroups.push(group.uuid);
      }
    });
  }

  @action.bound
  toggleSelectAllProjects() {
    if (this.allProjectsSelected) {
      this.selectedProjects.replace(
        this.selectedProjects.filter(uuid => {
          return !this.projects.models
            .map(project => project.uuid)
            .includes(uuid);
        })
      );
    } else {
      this.projects.models.forEach(project => {
        if (!this.selectedProjects.includes(project.uuid)) {
          this.selectedProjects.push(project.uuid);
        }
      });
    }
  }

  @action.bound
  toggleSelectAllGroups() {
    if (this.allGroupsSelected) {
      this.selectedGroups.replace(
        this.selectedGroups.filter(uuid => {
          return !this.groups.models.map(group => group.uuid).includes(uuid);
        })
      );
    } else {
      this.groups.models.forEach(group => {
        if (!this.selectedGroups.includes(group.uuid)) {
          this.selectedGroups.push(group.uuid);
        }
      });
    }
  }

  @action.bound async addMembersToProjects() {
    let payload;

    if (this.projectSelectionMethod === 'SELECTED') {
      payload = this.parent.selectedMembers.map(member => {
        return {
          uuid: member.uuid,
          projectUuids: this.selectedProjects.slice(),
          allowSubscriptionUpgrade: true,
          projectMembershipUpdateMode: 'APPEND'
        };
      });
    } else if (this.projectSelectionMethod === 'SELECTED_GROUPS') {
      payload = this.parent.selectedMembers.map(member => {
        return {
          uuid: member.uuid,
          projectGroupUuids: this.selectedGroups.slice(),
          allowSubscriptionUpgrade: true,
          projectMembershipUpdateMode: 'APPEND'
        };
      });
    } else {
      payload = this.parent.selectedMembers.map(member => {
        return {
          uuid: member.uuid,
          addToActiveProjects: true,
          allowSubscriptionUpgrade: true
        };
      });
    }

    return await request.patch(`${this.rootStore.users.url()}/batch`, payload);
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.searchQuery = '';
    this.selectedProjects.clear();
    this.selectedGroups.clear();
    this.projectSelectionMethod = 'ACTIVE';
    this.projects.clear();
    this.groups.clear();
    this.page = 1;
    this.loading = true;
  }
}

import UIStore from 'stores/ui/UIStore';
import request from 'axios';
import moment from 'moment';
import { action, computed, observable } from 'mobx';
import {
  VerificationReminderForm,
  verificationReminderFormLabels,
  verificationReminderFormRules,
  verificationReminderFormFields,
  verificationReminderFormOptions,
  verificationReminderFormPlugins,
  verificationReminderFormValues
} from 'forms/verificationReminder';
import { t } from 'utils/translate';
import omit from 'lodash.omit';
import Reminders from '../collections/Reminders';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class VerificationReminderUI extends UIStore {
  @observable verificationReminderForm;
  @observable selectedReminder;
  @observable saving;
  @observable loading;

  constructor(options) {
    super(options);

    this.verificationReminderForm = null;
    this.saving = false;
    this.loading = false;
    this.reminders = new Reminders(null, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound
  fetchReminders() {
    return this.reminders.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @computed get project() {
    return this.parent.project;
  }

  @computed get company() {
    return this.parent.company;
  }

  @computed get showRemindersList() {
    return (
      !this.loading &&
      !!this.verificationReminderForm?.values().reminders?.length &&
      this.selectedReminder === undefined
    );
  }

  @computed get showEditing() {
    return (
      !this.loading &&
      !!this.verificationReminderForm?.values().reminders?.length &&
      this.selectedReminder != null
    );
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      (!this.verificationReminderForm?.values().reminders?.length ||
        this.selectedReminder === null)
    );
  }

  @action.bound async showVerificationReminderModal() {
    this.loading = true;
    this.showModal('VerificationReminderModal');
    await this.setupVerificationReminder();
    this.loading = false;
  }

  @action.bound async setupVerificationReminder() {
    this.verificationReminderForm = new VerificationReminderForm(
      {
        fields: verificationReminderFormFields,
        labels: verificationReminderFormLabels,
        rules: verificationReminderFormRules,
        values: verificationReminderFormValues
      },
      {
        options: verificationReminderFormOptions,
        plugins: verificationReminderFormPlugins
      }
    );

    await this.fetchReminders();
    if (this.reminders.hasReminders) {
      const initialReminders = this.reminders.models.map(
        ({
          uuid,
          emails,
          reportName,
          timeFrameType,
          time,
          reminderOption,
          startDate
        }) => {
          return {
            uuid,
            emails,
            reportName,
            timeFrameType,
            time: moment()
              .set({
                hour: time.hour,
                minute: time.minute
              })
              .format('HH:mm'),
            questionsReminders: reminderOption.includes(
              'CLOCK_IN_OUT_QUESTIONS'
            ),
            photoIdReminders: reminderOption.includes('PHOTO_ID_REMINDERS'),
            startDate
          };
        }
      );
      this.verificationReminderForm.update({
        reminders: initialReminders
      });
    }
  }

  @computed get timeFrames() {
    return [
      {
        id: 'DAILY',
        title: t('Daily')
      },
      {
        id: 'WEEKLY',
        title: t('Weekly')
      },
      {
        id: 'BI_WEEKLY',
        title: t('Bi-weekly')
      },
      {
        id: 'MONTHLY',
        title: t('Monthly')
      }
    ];
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame =>
        timeFrame.id === this.verificationReminderForm?.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    this.verificationReminderForm?.update({
      timeFrameType: timeFrame.id
    });
  }

  @action.bound
  async hideVerificationReminderModal() {
    await this.hideActiveModal();
    this.verificationReminderForm = null;
    this.selectedReminder = undefined;
  }

  @computed
  get enableSend() {
    if (this.saving || !this.verificationReminderForm) {
      return false;
    }

    if (
      !this.verificationReminderForm.$('questionsReminders').value &&
      !this.verificationReminderForm.$('photoIdReminders').value
    ) {
      return false;
    }

    return this.verificationReminderForm.isValid;
  }

  @action.bound
  async scheduleNewReminder(event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedReminder = null;
    const reminders = this.verificationReminderForm.values().reminders;
    this.verificationReminderForm.update({
      ...verificationReminderFormValues,
      reminders
    });
  }

  @action.bound
  async scheduleReminder(event) {
    event.preventDefault();
    event.stopPropagation();
    this.loading = true;
    const values = this.verificationReminderForm.values();
    const reminderOption = [];
    if (values.questionsReminders) {
      reminderOption.push('CLOCK_IN_OUT_QUESTIONS');
    }
    if (values.photoIdReminders) {
      reminderOption.push('PHOTO_ID_REMINDERS');
    }
    const time = moment(values.time, 'HH:mm');
    const payload = {
      reminderOption,
      reportName: values.reportName,
      timeFrameType: values.timeFrameType,
      emails: values.emails,
      startDate: values.startDate,
      time: {
        hour: time.hour(),
        minute: time.minute()
      }
    };
    try {
      const res = await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/verification-reminder`,
        payload
      );

      this.verificationReminderForm.update({
        reminders: [
          ...values.reminders,
          {
            ...omit(values, ['reminders']),
            uuid: res.data.uuid,
            startDate:
              values.startDate.format?.('YYYY-MM-DD') || values.startDate
          }
        ]
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.loading = false;
    this.selectedReminder = undefined;
  }

  @action.bound
  async updateReminder(event) {
    event.preventDefault();
    event.stopPropagation();
    this.loading = true;
    const values = this.verificationReminderForm.values();
    const reminders = values.reminders;
    const reminderUuid = reminders[this.selectedReminder].uuid;
    const reminderOption = [];
    if (values.questionsReminders) {
      reminderOption.push('CLOCK_IN_OUT_QUESTIONS');
    }
    if (values.photoIdReminders) {
      reminderOption.push('PHOTO_ID_REMINDERS');
    }
    const time = moment(values.time, 'HH:mm');
    const payload = {
      reminderOption,
      reportName: values.reportName,
      timeFrameType: values.timeFrameType,
      emails: values.emails,
      startDate: values.startDate,
      time: {
        hour: time.hour(),
        minute: time.minute()
      }
    };

    try {
      const res = await request.patch(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/verification-reminder/${reminderUuid}`,
        payload
      );

      reminders.splice(this.selectedReminder, 1, {
        ...omit(values, ['reminders']),
        uuid: res.data.uuid,
        startDate:
          values.startDate.format?.('YYYY-MM-DD') || values.startDate.format
      });
      this.verificationReminderForm.update({
        reminders
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }

    this.loading = false;
    this.selectedReminder = undefined;
  }

  @action.bound editReminder(index) {
    this.selectedReminder = index;
    const values = this.verificationReminderForm.values();
    const {
      reportName,
      emails,
      questionsReminders,
      photoIdReminders,
      timeFrameType,
      startDate,
      time
    } = values.reminders[index];
    this.verificationReminderForm.update({
      ...values,
      reportName,
      emails,
      questionsReminders,
      photoIdReminders,
      timeFrameType,
      startDate,
      time
    });
  }

  @action.bound async deleteReminder(index) {
    this.loading = true;
    const reminderUuid = this.verificationReminderForm.values().reminders[index]
      .uuid;
    const values = this.verificationReminderForm
      .values()
      .reminders.filter((r, i) => i !== index);
    this.verificationReminderForm.update({
      reminders: [...values]
    });
    try {
      await request.delete(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/verification-reminder/${reminderUuid}`
      );
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
    this.loading = false;
  }

  getActions = (reminder, index) => {
    const actions = [
      {
        title: t('Edit'),
        onClick: () => {
          this.editReminder(index);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteReminder(index);
        }
      }
    ];

    return actions;
  };
}

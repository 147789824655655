import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import { callTrack } from 'utils/segmentIntegration';
import omit from 'lodash.omit';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';
import {
  SettingsRecipientsForm,
  settingsRecipientsFormOptions,
  settingsRecipientsFormFields,
  settingsRecipientsFormRules,
  settingsRecipientsFormValues,
  settingsRecipientsFormPlugins,
  settingsRecipientsFormLabels,
  settingsRecipientsFormDisabled
} from 'forms/project/settingsRecipients';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

export default class SettingsRecipientsUI extends SettingsChildUI {
  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsRecipientsFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsRecipientsForm(
      {
        fields: settingsRecipientsFormFields,
        rules: settingsRecipientsFormRules,
        disabled: settingsRecipientsFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsRecipientsFormLabels
      },
      {
        options: settingsRecipientsFormOptions,
        plugins: settingsRecipientsFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.form.init(toJS(this.currentFormInitialValues));
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(projectData, {
        wait: true
      });
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Email recipients saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    this.navigationUI.projectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get headerTitle() {
    return t('Choose how you want to distribute your report');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditDailyReportRecipients;
  }

  @computed get title() {
    return t('Report recipients');
  }

  @computed get times() {
    const moment = extendMoment(Moment);
    return Array.from(
      moment.range(moment().startOf('day'), moment().endOf('day')).by('hour')
    ).map(time => {
      return {
        value: time.format('HH:mm'),
        name: time.format('h:mm A')
      };
    });
  }
}

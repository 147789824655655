import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentNotificationsFormPlugins = extendedPlugins;

const equipmentNotificationsFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentNotificationsFormFields = [
  'logMissed',
  'deficiencyLogged',
  'maintenanceDue',
  'rentalEnding'
];

const equipmentNotificationsFormLabels = {
  logMissed: t('log missed'),
  deficiencyLogged: t('deficiency logged'),
  maintenanceDue: t('due for maintenance'),
  rentalEnding: t('rental return due')
};

class EquipmentNotificationsForm extends MobxReactForm {}

export {
  EquipmentNotificationsForm,
  equipmentNotificationsFormOptions,
  equipmentNotificationsFormFields,
  equipmentNotificationsFormLabels,
  equipmentNotificationsFormPlugins
};

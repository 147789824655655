import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentLostStolenFormPlugins = extendedPlugins;

const smallEquipmentLostStolenFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentLostStolenFormFields = [
  'lostStolen[].equipmentUuid',
  'lostStolen[].equipmentName',
  'lostStolen[].projectUuid',
  'lostStolen[].projectName',
  'lostStolen[].projectDeployedUnits',
  'lostStolen[].unitsLostStolen'
];

const smallEquipmentLostStolenFormLabels = {
  'lostStolen[].unitsLostStolen': t('units lost / stolen ')
};

const smallEquipmentLostStolenFormValues = {
  'lostStolen[].equipmentName': '',
  'lostStolen[].projectName': '',
  'lostStolen[].projectDeployedUnits': '',
  'lostStolen[].unitsLostStolen': ''
};

const smallEquipmentLostStolenFormRules = {
  'lostStolen[].unitsLostStolen': 'numeric|min:0'
};

class SmallEquipmentLostStolenForm extends MobxReactForm {}

export {
  SmallEquipmentLostStolenForm,
  smallEquipmentLostStolenFormOptions,
  smallEquipmentLostStolenFormFields,
  smallEquipmentLostStolenFormRules,
  smallEquipmentLostStolenFormValues,
  smallEquipmentLostStolenFormLabels,
  smallEquipmentLostStolenFormPlugins
};

import { action, computed } from 'mobx';
import orderBy from 'lodash.orderby';
import UIStore from 'stores/ui/UIStore';
import errorHandler from 'utils/errorHandler';

import IntegrationUI from './IntegrationUI';

import { COMPANY_INTEGRATIONS_VIEWED } from 'utils/segmentAnalytics/eventSpec';
import { callTrack } from 'utils/segmentIntegration';

export default class IntegrationsUI extends UIStore {
  constructor(options) {
    super(options);

    this.integrationUI = new IntegrationUI({
      rootStore: this.rootStore,
      parent: this
    });
  }

  @action.bound setup() {
    this.fetchIntegrations();
    callTrack(COMPANY_INTEGRATIONS_VIEWED);
  }

  @computed get baseUrl() {
    return `/company/integrations`;
  }

  @computed get integrations() {
    return this.rootStore.integrations;
  }

  getCardUrl(integration) {
    return `${this.baseUrl}/${integration.id}`;
  }

  @computed
  get sortedIntegrations() {
    return orderBy(this.integrations.models, ['name'], ['asc']);
  }

  @action.bound
  async fetchIntegrations() {
    try {
      await this.integrations.fetch({
        params: {
          limit: 50
        }
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @computed get disableIntegrationsForTrialAccount() {
    return this.authorization.disableIntegrationsForTrialAccount;
  }
}

import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import {
  SettingsReportsForm,
  settingsReportsFormOptions,
  settingsReportsFormFields,
  settingsReportsFormRules,
  settingsReportsFormValues,
  settingsReportsFormPlugins,
  settingsReportsFormLabels,
  settingsReportsFormDisabled
} from 'forms/project/settingsReports';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import { PROJECT_UPDATED } from 'utils/segmentAnalytics/eventSpec';

export default class SettingsReportsUI extends SettingsChildUI {
  @action.bound setup() {
    this.setupForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsReportsFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsReportsForm(
      {
        fields: settingsReportsFormFields,
        rules: settingsReportsFormRules,
        disabled: settingsReportsFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsReportsFormLabels
      },
      {
        options: settingsReportsFormOptions,
        plugins: settingsReportsFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(
        projectData,
        {
          url: `ra/projects/${this.project.uuid}`
        },
        {
          wait: true
        }
      );
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Report settings saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    this.navigationUI.projectSelectorUI.resetProjectOptions();

    // Reinitialize form
    this.setupForm();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get headerTitle() {
    return t('Customize your report content');
  }

  @computed get hasWriteAccess() {
    return this.authorization.canEditDailyReportSettings;
  }

  @computed get hasWorkLogWriteAccess() {
    const { includeWorkLogs } = this.form.values();
    return this.authorization.canEditDailyReportSettings && includeWorkLogs;
  }

  @computed get hasWorkLogDetailsWriteAccess() {
    const { includeWorkLogs } = this.form.values();
    return this.authorization.canEditDailyReportSettings && includeWorkLogs;
  }

  @computed get hasHoursWriteAccess() {
    const { includeHours } = this.form.values();
    return this.authorization.canEditDailyReportSettings && includeHours;
  }

  @computed get title() {
    return t('Report settings');
  }
}

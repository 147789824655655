import { Model } from 'mobx-mc';

export default class EquipmentPerformanceProductivity extends Model {
  constructor(attributes, options) {
    super(attributes, options);
  }

  get restAttributes() {
    return ['availableHours', 'operatingHours', 'utilizationRate'];
  }
}

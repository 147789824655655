import { observer } from 'mobx-react';
import React from 'react';
import AlertBanner from './AlertBanner';

import { Text } from '@raken/athens-web/lib';

import { t } from 'utils/translate';

const ViolatorBanner = observer(() => {
  return (
    <AlertBanner
      textAlign="center"
      justifyContent="center"
      hideIcon={true}
      dataQA={'violator-banner'}
    >
      <Text bold>
        {t('User(s) on your account have violated our licensing policy.')}
      </Text>
      <Text>
        {t(
          'Please contact your account Admin or call Raken at 866-438-0646 for help.'
        )}
      </Text>
    </AlertBanner>
  );
});

export default ViolatorBanner;

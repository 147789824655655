import PolicyChildEditUI from './PolicyChildEditUI';
import { observable, action, computed } from 'mobx';

import {
  PolicyForm,
  policyFormPlugins,
  policyFormOptions,
  policyFormFields,
  policyFormRules,
  policyFormLabels
} from 'forms/policy';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

export default class PolicySettingsUI extends PolicyChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound
  async setup() {
    this.initSettingsForm();
  }

  @action.bound tearDown() {
    this.unblockHistory();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.form = null;
  }

  @action.bound initSettingsForm() {
    this.form = new PolicyForm(
      {
        fields: policyFormFields,
        rules: policyFormRules,
        labels: policyFormLabels,
        values: this.entryForEdit.settingsFormValues
      },
      {
        options: policyFormOptions,
        plugins: policyFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound
  submitPolicyEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.form.submit({
      onSuccess: this.submitPolicyEditSuccess,
      onError: e => {
        console.error(this.form.errors());
      }
    });
  }

  @action.bound
  async submitPolicyEditSuccess() {
    const {
      name,
      isDefault,
      basicSupervisorEntries,
      detailedSupervisorEntries,
      kioskEnabled,
      timeClockEnabled,
      photoIdentificationMode,
      clockInTemplateUuid,
      clockOutTemplateUuid,
      requireCostCodes,
      allowEmployeeCostCodeSelection,
      allowEmployeeCrewSelection,
      breakSetting
    } = this.form.values();
    try {
      await this.entryForEdit.save(
        {
          name,
          default: Boolean(isDefault),
          basicSupervisorEntries,
          detailedSupervisorEntries,
          kioskEnabled,
          timeClockEnabled,
          photoIdentificationMode,
          clockInTemplateUuid,
          clockOutTemplateUuid,
          requireCostCodes,
          allowEmployeeCostCodeSelection,
          allowEmployeeCrewSelection,
          breakSetting
        },
        {
          wait: true
        }
      );

      this.parent.parent.refetchPolicies();
      this.initSettingsForm();

      this.moveToNextStepIfSettingUpDefault('Settings');

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Policy info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @computed get disableSaveButton() {
    return this.entryForEdit.saving;
  }

  @action.bound setBasicSupervisorEntries(value) {
    if (value) {
      this.form.$('detailedSupervisorEntries').set(false);
      this.form.$('kioskEnabled').set(false);
      this.form.$('timeClockEnabled').set(false);
    } else {
      this.form.$('detailedSupervisorEntries').set(true);
      this.form.$('kioskEnabled').set(true);
      this.form.$('timeClockEnabled').set(true);
    }

    this.form.$('basicSupervisorEntries').set(value);
  }

  @action.bound setDetailedSupervisorEntries(value) {
    if (value) {
      this.form.$('basicSupervisorEntries').set(false);
      this.form.$('kioskEnabled').set(true);
      this.form.$('timeClockEnabled').set(true);
    } else {
      this.form.$('basicSupervisorEntries').set(true);
      this.form.$('kioskEnabled').set(false);
      this.form.$('timeClockEnabled').set(false);
    }

    this.form.$('detailedSupervisorEntries').set(value);
  }

  @computed get showVerification() {
    return (
      this.company.companyAddOns?.hasTimeClockAccess &&
      (this.form.$('kioskEnabled').value ||
        this.form.$('timeClockEnabled').value)
    );
  }

  @computed get photoIdOptions() {
    return [
      { title: t('No photo ID required'), value: 'NOT_REQUIRED' },
      { title: t('Clock in and out'), value: 'REQUIRED_ON_CLOCK_IN_AND_OUT' },
      {
        title: t('Clock in and out, Start and end breaks'),
        value: 'REQUIRED_ON_CLOCK_IN_AND_OUT_AND_BREAKS'
      }
    ];
  }

  @computed get photoIdValue() {
    return this.photoIdOptions.find(
      option => option.value === this.form?.$('photoIdentificationMode').value
    );
  }

  @action.bound async fetchChecklistTemplates() {
    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get clockInQuestionsOptions() {
    return [
      { title: t('Clock in questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockInQuestionsValue() {
    return this.clockInQuestionsOptions.find(
      option => option.value === this.form.$('clockInTemplateUuid').value
    );
  }
  @computed get clockOutQuestionsOptions() {
    return [
      { title: t('Clock out questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockOutQuestionsValue() {
    return this.clockOutQuestionsOptions.find(
      option => option.value === this.form.$('clockOutTemplateUuid').value
    );
  }

  @computed get breakSettingOptions() {
    return [
      {
        title: t('Require start and end time'),
        value: 'REQUIRE_START_AND_END_TIME'
      },
      {
        title: t('Require start and duration'),
        value: 'REQUIRE_START_TIME_AND_DURATION'
      },
      {
        title: t('Require duration only'),
        value: 'REQUIRE_DURATION_ONLY'
      }
    ];
  }

  @computed get breakSettingValue() {
    return this.breakSettingOptions.find(
      option => option.value === this.form?.$('breakSetting').value
    );
  }

  @action.bound disableCostCodesAndCrews() {
    // If kiosk and time clock are off we want to disable these settings.
    if (
      !this.form.$('kioskEnabled').value &&
      !this.form.$('timeClockEnabled').value
    ) {
      this.form.$('allowEmployeeCostCodeSelection').set(false);
      this.form.$('allowEmployeeCrewSelection').set(false);
    }
  }
}

import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import moment from 'moment';
import {
  IncidentExportForm,
  incidentExportFormFields,
  incidentExportFormLabels,
  incidentExportFormOptions,
  incidentExportFormPlugins,
  incidentExportFormRules,
  incidentExportFormValues
} from 'forms/incidentExport';
import IncidentExport from 'stores/models/IncidentExport';
import { callTrack } from 'utils/segmentIntegration';
import { COMPANY_INCIDENTS_REPORT_DOWNLOADED } from 'utils/segmentAnalytics/eventSpec';
import history from 'utils/history';

export default class IncidentExportUI extends UIStore {
  @observable incidentExportForm;
  @observable entryForExport;
  @observable exportType;

  constructor(options) {
    super(options);
    this.incidentExportForm = null;
    this.exportType = null;
  }

  @action.bound async setup() {
    this.setupForm();
  }

  @action.bound setupForm() {
    this.entryForExport = new IncidentExport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.incidentExportForm = new IncidentExportForm(
      {
        fields: incidentExportFormFields,
        rules: incidentExportFormRules,
        labels: incidentExportFormLabels,
        values: incidentExportFormValues
      },
      {
        options: incidentExportFormOptions,
        plugins: incidentExportFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.incidentExportForm = null;
    this.entryForExport = null;
    this.exportType = null;
    this.saving = false;
  }

  @action.bound
  async submitIncidentExportFormSuccess() {
    this.saving = true;
    const {
      year,
      emails,
      skipIncompleteRecordValidation
    } = this.incidentExportForm.trimmedValues();
    try {
      await this.entryForExport.save(
        {
          exportType: this.exportType,
          year,
          emails,
          skipIncompleteRecordValidation
        },
        {
          wait: true
        }
      );

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Incident reports exported')
      });

      callTrack(COMPANY_INCIDENTS_REPORT_DOWNLOADED, {
        incident_report_name: this.exportType
      });

      this.cancelIncidentsExport();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
      const isIncompleteRecord =
        this.validationDetails?.[0].field === 'answeredQuestionCount';
      if (isIncompleteRecord) {
        this.showModal('ExportWarningModal');
      }
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitIncidentExportForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.incidentExportForm.submit({
      onSuccess: this.submitIncidentExportFormSuccess,
      onError: e => {
        console.error(this.incidentExportForm.errors());
      }
    });
  }

  @action.bound
  async cancelExportWarningModal() {
    await this.hideActiveModal();
  }

  @action.bound
  async proceedExportWarningModal() {
    await this.hideActiveModal();
    this.incidentExportForm.$('skipIncompleteRecordValidation').set(true);
    this.incidentExportForm.submit({
      onSuccess: this.submitIncidentExportFormSuccess,
      onError: e => {
        console.error(this.incidentExportForm.errors());
      }
    });
  }

  @computed
  get exportActions() {
    return [
      {
        title: t('Incident reports (301)'),
        onClick: () => this.exportIncidentReports('INCIDENT_REPORT_301')
      },
      {
        title: t('Incident log (300)'),
        onClick: () => this.exportIncidentReports('INCIDENT_REPORT_300')
      },
      {
        title: t('Incident summary report (300A)'),
        onClick: () => this.exportIncidentReports('INCIDENT_REPORT_300A')
      },
      {
        title: t('Incident log submission (300/301)'),
        disabled: this.disableSubmissionExport,
        onClick: () => this.exportIncidentReports('INCIDENT_SUBMISSION_300_301')
      },
      {
        title: t('Incident summary submission (300A)'),
        disabled: this.disableSubmissionExport,
        onClick: () => this.exportIncidentReports('INCIDENT_SUBMISSION_300A')
      }
    ];
  }

  @computed
  get disableSubmissionExport() {
    return !this.parent.submissionSettings?.uuid;
  }

  @computed
  get enableSubmit() {
    if (
      !this.incidentExportForm ||
      this.incidentExportForm.isPristine ||
      this.saving
    ) {
      return false;
    }

    return this.incidentExportForm.isValid;
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get exportTitle() {
    const titles = {
      INCIDENT_REPORT_301: t('Export incident reports (301)'),
      INCIDENT_REPORT_300: t('Export incident log (300)'),
      INCIDENT_REPORT_300A: t('Export incident summary report (300A)'),
      INCIDENT_SUBMISSION_300_301: t(
        'Export incident log submission (300/301)'
      ),
      INCIDENT_SUBMISSION_300A: t('Export incident summary submission (300A)')
    };
    return titles[this.exportType] || t('Export incident reports (301)');
  }

  @computed get yearOptions() {
    const currentYear = moment().year();
    const startYear = 2022;
    const options = [];

    for (let year = startYear; year <= currentYear; year++) {
      options.push({ id: year.toString(), name: year.toString() });
    }

    return options;
  }

  @action.bound async exportIncidentReports(exportType) {
    this.exportType = exportType;
    await this.authorization.checkFeatureAccess('CUDIncidents');
    history.push({
      pathname: `${this.parent.viewUrl}/export-reports`
    });
  }

  @action.bound cancelIncidentsExport() {
    history.push({
      pathname: this.parent.viewUrl
    });
  }
}

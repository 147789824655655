import { computed } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import { t } from 'utils/translate';

import ProjectTemplateTimeTrackingUI from './ProjectTemplateTimeTrackingUI';
import ProjectTemplateProjectRadiusUI from './ProjectTemplateProjectRadiusUI';
import ProjectTemplateBreaksUI from './ProjectTemplateBreaksUI';
import ProjectTemplateTimeCaptureUI from './ProjectTemplateTimeCaptureUI';
import ProjectTemplateShiftsUI from './ProjectTemplateShiftsUI';
import ProjectTemplatePayTypesUI from './ProjectTemplatePayTypesUI';

import { insertIf } from 'utils/insertIf';

export default class ProjectTemplatePayrollAndTimeUI extends UIStore {
  constructor(options) {
    super(options);

    this.projectTemplateTimeTrackingUI = new ProjectTemplateTimeTrackingUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateShiftsUI = new ProjectTemplateShiftsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplatePayTypesUI = new ProjectTemplatePayTypesUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateProjectRadiusUI = new ProjectTemplateProjectRadiusUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateBreaksUI = new ProjectTemplateBreaksUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.projectTemplateTimeCaptureUI = new ProjectTemplateTimeCaptureUI({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @computed get payRollTimeLinks() {
    return [
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time/track-time`,
        label: t('Track time')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time/breaks`,
        label: t('Breaks')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time/shifts`,
        label: t('Shifts')
      },
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time/pay-types`,
        label: t('Pay types')
      },
      ...insertIf(!this.featureFlags.FF_SETUP_TIME_POLICIES, [
        {
          path: `${this.selectedTemplate.viewUrl}/payroll-and-time/radius`,
          label: t('Project radius')
        }
      ]),
      {
        path: `${this.selectedTemplate.viewUrl}/payroll-and-time/time-capture`,
        label: t('Time capture')
      }
    ];
  }
}

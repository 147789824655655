import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildEditUI from './../ProjectChildEditUI';

import EquipmentLog from 'stores/models/equipment/EquipmentLog';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRules,
  equipmentLogFormRelated
} from 'forms/equipmentLog';

export default class EquipmentLogEditUI extends ProjectChildEditUI {
  @computed get parentEquipmentDeployment() {
    return this.parent.entryForEdit;
  }

  @action.bound setup(id) {
    this.fetchEntry(id);
    this.memberSelectorUI.setup({
      projectUuids: [this.project.uuid]
    });
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
    this.memberSelectorUI.tearDown();
  }

  @action.bound async fetchEntry(uuid) {
    if (this.entryForEdit) return;

    try {
      let model = this.parent.equipmentLogs.get(uuid);

      if (!model) {
        model = new EquipmentLog(
          {
            type: 'EquipmentLog',
            uuid: uuid,
            equipmentDeploymentUuid: this.parentEquipmentDeployment.uuid
          },
          {
            rootStore: this.rootStore
          }
        );

        await model.fetch();
      }

      this.setEntryForEdit(model);
    } catch (error) {
      console.error(error);
      this.cancelEquipmentLogEdit();
    }
  }

  @computed get defaultOperatorValue() {
    return this.entryForEdit.formValues.operator?.uuid
      ? this.entryForEdit.formValues.operator
      : {
          uuid: this.me.uuid,
          name: this.me.fullName,
          workerUuid: this.me.workerUuid,
          company: {
            uuid: this.me.company.uuid,
            name: this.me.company.name
          },
          email: this.me.email,
          phoneNumber: this.me.phoneNumber
        };
  }

  @computed get defaultCostCodeValue() {
    return this.entryForEdit.formValues.costCode
      ? this.entryForEdit.formValues?.costCode
      : this.parentEquipmentDeployment.formValues?.costCode;
  }

  @action.bound setEntryForEdit(model) {
    this.entryForEdit = model;

    const values = {
      ...this.entryForEdit.formValues,
      costCode: this.defaultCostCodeValue,
      operator: this.defaultOperatorValue
    };

    this.entryEditForm = new EquipmentLogForm(
      {
        fields: equipmentLogFormFields,
        rules: equipmentLogFormRules,
        labels: equipmentLogFormLabels,
        values: values,
        related: equipmentLogFormRelated
      },
      {
        options: equipmentLogFormOptions,
        plugins: equipmentLogFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async submitEntryEditFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryEditForm.values();

      await this.entryForEdit.save(
        Object.assign(values, {
          date: moment(values.date).format('YYYY-MM-DD'),
          equipmentDeficiencies: this.entryEditForm.deficiencyValues
        }),
        {
          wait: true
        }
      );

      this.parent.sortByLastCreated();
      this.cancelEquipmentLogEdit();

      // Ensure any unsaved changed on the parent equipment log
      // form are prompted.
      this.parent.parent.blockHistoryIfFormChanged();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Log saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogEdit() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.parentEquipmentDeployment.uuid}`,
      search: this.baseQueryParams
    });
  }

  @computed get statusOptions() {
    return [
      {
        value: 'IN_USE',
        name: t('In use')
      },
      {
        value: 'NOT_IN_USE',
        name: t('Not in use')
      },
      {
        value: 'WITHDRAWN',
        name: t('Withdrawn')
      }
    ];
  }

  @computed get selectedStatusOption() {
    return this.statusOptions.find(
      option => option.value === this.entryEditForm?.$('logStatus').value
    );
  }

  @action.bound
  addDeficiency() {
    this.entryEditForm.$('deficiencies').add({
      faultCode: '',
      deficiencyDescription: ''
    });
  }

  @action.bound
  removeDeficiency(deficiencyPath) {
    this.entryEditForm.$('deficiencies').del(deficiencyPath);
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentFormPlugins = extendedPlugins;

const smallEquipmentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentFormFields = [
  'name',
  'quantity',
  'companyUuid',
  'initialName'
];

const smallEquipmentFormRules = {
  name: 'required|string|max:100|small_equipment_name_available',
  quantity: 'required|numeric|min:0|max:500000'
};

const smallEquipmentFormLabels = {
  name: t('equipment name'),
  quantity: t('total units')
};

const smallEquipmentFormValues = {
  name: '',
  quantity: ''
};

class SmallEquipmentForm extends MobxReactForm {}

export {
  SmallEquipmentForm,
  smallEquipmentFormLabels,
  smallEquipmentFormValues,
  smallEquipmentFormRules,
  smallEquipmentFormFields,
  smallEquipmentFormOptions,
  smallEquipmentFormPlugins
};

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from 'forms/utils/extendedPlugins';
import { t } from 'utils/translate';

const smallEquipmentDeploymentFormPlugins = extendedPlugins;

const smallEquipmentDeploymentFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const smallEquipmentDeploymentFormFields = [
  'project',
  'numberOfUnits',
  'availableUnits'
];

const smallEquipmentDeploymentFormLabels = {
  project: t('deploy to'),
  numberOfUnits: t('no. of units')
};

const smallEquipmentDeploymentFormValues = {
  project: '',
  numberOfUnits: ''
};

const smallEquipmentDeploymentFormRules = {
  project: 'required',
  numberOfUnits: 'required|numeric|min:0'
};

class SmallEquipmentDeploymentForm extends MobxReactForm {}

export {
  SmallEquipmentDeploymentForm,
  smallEquipmentDeploymentFormOptions,
  smallEquipmentDeploymentFormFields,
  smallEquipmentDeploymentFormRules,
  smallEquipmentDeploymentFormValues,
  smallEquipmentDeploymentFormLabels,
  smallEquipmentDeploymentFormPlugins
};

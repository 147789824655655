import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const insightsSafetyDisplayFiltersFormPlugins = extendedPlugins;

const insightsSafetyDisplayFiltersFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const insightsSafetyDisplayFiltersFormFields = [
  'checklistSummary',
  'checklistCompletion',
  'checklistCompletionByWorkUnit',
  'checklistFailedResponses',
  'insightsObservationsRaisedByType',
  'insightsObservationsRaisedByTeamMember',
  'insightsIssuesObservedHoursWorked',
  'insightsPositiveNegativeObservations',
  'toolBoxTalks'
];

const insightsSafetyDisplayFiltersFormRules = {
  checklistSummary: 'boolean',
  checklistCompletion: 'boolean',
  checklistCompletionByWorkUnit: 'boolean',
  checklistFailedResponses: 'boolean',
  insightsObservationsRaisedByType: 'boolean',
  insightsObservationsRaisedByTeamMember: 'boolean',
  insightsIssuesObservedHoursWorked: 'boolean',
  insightsPositiveNegativeObservations: 'boolean',
  toolBoxTalks: 'boolean'
};

const insightsSafetyDisplayFiltersFormLabels = {
  checklistSummary: t('HSE overview'),
  checklistCompletion: t('HSE checklist completion'),
  checklistCompletionByWorkUnit: t('HSE checklist completion by work unit'),
  checklistFailedResponses: t('Top 5 checklist deficiencies'),
  insightsObservationsRaisedByType: t('HSE issues raised by type'),
  insightsObservationsRaisedByTeamMember: t(
    'HSE observations raised by team member'
  ),
  insightsIssuesObservedHoursWorked: t('HSE issues observed / hours worked'),
  insightsPositiveNegativeObservations: t(
    'Positive vs. negative HSE observations by company'
  ),

  toolBoxTalks: t('Toolbox talks')
};

class InsightsSafetyDisplayFiltersForm extends MobxReactForm {}

export {
  InsightsSafetyDisplayFiltersForm,
  insightsSafetyDisplayFiltersFormRules,
  insightsSafetyDisplayFiltersFormFields,
  insightsSafetyDisplayFiltersFormLabels,
  insightsSafetyDisplayFiltersFormOptions,
  insightsSafetyDisplayFiltersFormPlugins
};

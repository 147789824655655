import moment from 'moment';
import TimeCards from 'stores/collections/workLogs/TimeCards';
import ReportEntryWithAttachments from 'stores/models/ReportEntryWithAttachments';
import Attachment from 'stores/models/Attachment';
import omit from 'lodash.omit';
import { action, computed, observable } from 'mobx';
import uniqby from 'lodash.uniqby';
import getFilePreviewIcon from 'utils/getFilePreviewIcon';
import fileToBase64 from 'utils/fileToBase64';
import bytesToSize from 'utils/bytesToSize';
import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';
import truncate from 'html-truncate';

class TimeEntriesWorkLog extends ReportEntryWithAttachments {
  @observable expandDescription;
  @observable assigningProject;

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/worklogs`;
  }

  constructor(attributes, options) {
    super(attributes, options);
    this.expandDescription = false;
    this.assigningProject = false;
  }

  get restAttributes() {
    return [
      'uuid',
      'type',
      'name',
      'workDesc',
      'totalHours',
      'projectUuid',
      'workerCount',
      'timeCards',
      'date', // Deprecate POST SD 2.0
      'reportDate',
      'createdTimestamp',
      'updatedTimestamp',
      'segmentUuid',
      'workLogType'
    ];
  }

  get restAttributeDefaults() {
    return {
      type: 'CrewWorklogWithTimeEntries'
    };
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound
  parse(data) {
    if (data.worklog) {
      data = Object.assign(
        {},
        data,
        omit(data.worklog, 'attachments', 'createdBy', 'updatedBy', 'type')
      );
    }

    this.parseAttachments(data);
    this.parseTimeCards(data.timeCards);

    this.parseCreatedBy(data);
    this.parseUpdatedBy(data);
    this.parseProject(data);
    this.parseCompany(data);

    return {
      ...omit(data, ['attachments', 'timeCards'])
    };
  }

  @action.bound
  parseTimeCards(timeCards) {
    this.timeCards = new TimeCards(timeCards, {
      rootStore: this.rootStore,
      parent: this
    });
  }

  @computed get fromKioskOrTimeClock() {
    return this.timeCards.models.find(
      timeCard =>
        timeCard.timeCardDetails.hasKioskActivity ||
        timeCard.timeCardDetails?.hasTimeClockActivity
    );
  }

  @computed get showWarningIcon() {
    return this.timeCards.models.find(
      timeCard => !timeCard.endTime && timeCard.timeCardHoursFromNow >= 24
    );
  }

  @computed get warningIconText() {
    return t(
      `This work log contains time card's that have no end time and span over 24 hours`
    );
  }

  @computed
  get truncatedDescription() {
    return this.workDesc ? truncate(this.workDesc, 70) : '';
  }

  @computed
  get fullDescription() {
    return this.workDesc;
  }

  @computed
  get uniqueCostCodes() {
    const costCodes = [];

    this.timeCards.models.forEach(timeCard => {
      timeCard.timeEntries.models.forEach(timeEntry => {
        if (timeEntry.costCode?.uuid) {
          costCodes.push(timeEntry.costCode);
        }
      });
    });

    return uniqby(costCodes, 'uuid');
  }

  @computed
  get uniqueActivityCostCodes() {
    const costCodes = [];

    this.timeCards.models.forEach(timeCard => {
      timeCard.timeEntries.models.forEach(timeEntry => {
        if (timeEntry.costCode?.code || timeEntry.costCode?.division) {
          costCodes.push(timeEntry.costCode);
        }
      });
    });

    return uniqby(costCodes, c => [c.code, c.division].join());
  }

  @computed
  get activityCostCodeCount() {
    return this.uniqueActivityCostCodes.length;
  }

  @computed
  get firstActivityCostCodeEntryTitle() {
    return this.uniqueActivityCostCodes
      ? `${this.uniqueActivityCostCodes[0].division} - ${this.uniqueActivityCostCodes[0].code}`
      : '';
  }

  @computed
  get costCodeCount() {
    return this.uniqueCostCodes.length;
  }

  @computed
  get firstCostCodeEntry() {
    if (this.costCodeCount === 1) {
      return this.uniqueCostCodes[0];
    }

    return null;
  }

  @computed
  get displayedCostCode() {
    return (
      this.firstCostCodeEntry &&
      `${this.firstCostCodeEntry.division}-${this.firstCostCodeEntry.code}`
    );
  }

  @computed
  get hasAttachments() {
    return this.attachments.hasModels;
  }

  @computed
  get attachmentsCount() {
    return this.hasAttachments ? this.attachments.length : 0;
  }

  @computed
  get attachmentsCountPreviewImage() {
    // Find the first attachment that has a thumbUrl
    const attachmentWithThumbUrl = this.attachments?.models.find(
      attachment => attachment.thumbUrl
    );

    return attachmentWithThumbUrl?.thumbUrl || null;
  }

  @computed
  get isLockedForEditing() {
    return (
      !this.rootStore.authorizationUI.canEditCrewWorkLog ||
      this.assigningProject
    );
  }

  @computed
  get hasOnlyOneTimeCard() {
    return this.timeCards.length === 1;
  }

  @computed
  get formValues() {
    return {
      name: this.name,
      workDesc: this.workDesc || null
    };
  }

  @computed
  get hasLockedTimeCards() {
    return Boolean(
      this.timeCards.models.find(timeCard => timeCard.isLockedForEditing)
    );
  }

  @action.bound
  async uploadAttachment(uploadItem) {
    await this.rootStore.authorizationUI.checkFeatureAccess(
      'UploadAttachments'
    );

    const file = uploadItem.file;

    if (file.size > 62914560) {
      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'error',
        icon: 'notification',
        title: `${t('File is too big ')} (${bytesToSize(file.size)}). ${t(
          'Limit is 60MB.'
        )}`
      });

      return;
    }

    file.preview = await fileToBase64(file);

    const filePreviewIcon = getFilePreviewIcon(this.rootStore.assetsURL, file);

    // Create a temporrary model to display the preview icon
    // in the carousel
    const previewAttachment = new Attachment(
      {
        thumbUrl: filePreviewIcon
      },
      {
        rootStore: this.rootStore
      }
    );

    // Add to the collection will remove after full upload finishes
    this.attachments.add(previewAttachment);

    return this.attachments
      .upload(
        uploadItem,
        percentCompleted => {
          // If we wanted to show a progress bar on the attachment
          // we can use previewAttachment.uploadProgress.
          previewAttachment.setUploadProgress(percentCompleted);
        },
        file,
        'WorkLog'
      )
      .then(model => {
        // Remove the preview model now that the new one is ready
        this.attachments.remove(previewAttachment);
      });
  }

  @computed
  get hasAttachmentsUploading() {
    return this.attachments.models.find(attachment => attachment.isNew);
  }

  @computed
  get hasApprovedTimeCard() {
    return Boolean(
      this.timeCards?.models.find(timeCard => timeCard.isApproved)
    );
  }

  @computed
  get hasSyncedTimeCard() {
    return Boolean(this.timeCards?.models.find(timeCard => timeCard.isSynced));
  }

  @computed get slug() {
    return kebabCase(this.name);
  }

  @computed
  get title() {
    return t('Time Card');
  }

  @computed get activityTitle() {
    return `${t('submitted a')} ${this.title}`;
  }

  @action.bound toggleExpandDescription() {
    this.expandDescription = !this.expandDescription;
  }

  @computed get tableDescription() {
    if (this.expandDescription) return this.fullDescription;

    return this.truncatedDescription;
  }

  @computed get reportDateFormatted() {
    return moment(this.reportDate).format('ddd D MMM YYYY');
  }
}

export default TimeEntriesWorkLog;

import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';

import { t } from 'utils/translate';
import kebabCase from 'lodash.kebabcase';
import GroupSelectorUI from 'stores/ui/GroupSelectorUI';
import { Select, Box, Text, Icon, Checkbox } from '@raken/athens-web/lib';

const StyledCheckbox = styled(Checkbox)`
  margin-left: 0px;
  margin-right: 5px;
`;

const GroupSelector = props => {
  const {
    groupSelectorUI,
    autocomplete,
    error,
    label,
    dataQA,
    onChange,
    onInputChange,
    multiple,
    placeholder,
    dropdownWidth,
    filterOptions,
    ...otherProps
  } = props;

  return (
    <Observer>
      {() => {
        return (
          <Select
            autocomplete={autocomplete}
            label={label}
            options={groupSelectorUI.options}
            filterOptions={
              filterOptions
                ? filterOptions
                : (options, { inputValue }) => {
                    return groupSelectorUI.filterOptions(inputValue);
                  }
            }
            error={error}
            loading={groupSelectorUI.groups.fetching}
            placeholder={placeholder}
            noOptionsText={groupSelectorUI.noOptionsText}
            getOptionSelected={(option, value) => {
              return option.uuid === value.uuid;
            }}
            getOptionLabel={option => option.name || ''}
            dataQA={dataQA}
            onChange={(event, value) => {
              onChange && onChange(event, value);
            }}
            optionTitle={'name'}
            multiple={multiple}
            dropdownWidth={dropdownWidth}
            renderOption={(option, { selected }) => {
              return (
                <Box
                  pt={1}
                  pb={1}
                  data-qa={`option_${dataQA}-${kebabCase(option.name)}`}
                  display="flex"
                  alignItems="center"
                  justifyContent={props.multiple ? 'inherit' : 'space-between'}
                  width="100%"
                >
                  {multiple && (
                    <Box>
                      <StyledCheckbox checked={selected} />
                    </Box>
                  )}

                  <Box>
                    <Box maxWidth={450}>
                      <Text dataQA={`${dataQA}-${kebabCase(option.name)}-name`}>
                        {option.name}
                      </Text>
                    </Box>
                  </Box>

                  {selected && !multiple && (
                    <Box ml="auto">
                      <Icon
                        dataQA={dataQA}
                        kind="checkmark"
                        rakenColor="grey"
                      />
                    </Box>
                  )}
                </Box>
              );
            }}
            {...otherProps}
          />
        );
      }}
    </Observer>
  );
};

GroupSelector.propTypes = {
  autocomplete: PropTypes.bool,
  groupSelectorUI: PropTypes.instanceOf(GroupSelectorUI).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  error: PropTypes.bool,
  label: PropTypes.string,
  dataQA: PropTypes.string,
  onChange: PropTypes.func
};

GroupSelector.defaultProps = {
  autocomplete: true,
  multiple: true,
  placeholder: t('Search groups')
};

export default GroupSelector;

import request from 'axios';
import once from 'lodash.once';
import { observable, action, computed } from 'mobx';
import omit from 'lodash.omit';
import pickBy from 'lodash.pickby';
import UIStore from 'stores/ui/UIStore';
import JobTitles from 'stores/collections/JobTitles';
import {
  MemberForm,
  memberFormOptions,
  memberFormFields,
  memberFormFieldOptions,
  memberFormLabels,
  memberFormRules,
  memberFormPlugins
} from 'forms/member';

import {
  JobTitleForm,
  jobTitleFormValues,
  jobTitleFormRules,
  jobTitleFormFields,
  jobTitleFormOptions,
  jobTitleFormPlugins
} from 'forms/jobTitle';

import { t } from 'utils/translate';
import history from 'utils/history';
import alertErrorHandler from 'utils/alertErrorHandler';

export default class directoryUserAddUI extends UIStore {
  @observable memberForm;
  @observable phoneCountry;
  @observable existingUser;

  constructor(options) {
    super(options);
    this.addSeatsModalShown = false;
    this.memberForm = null;

    this.activeModal = null;
    this.userToRevoke = null;
    this.existingUser = null;
    this.jobTitles = new JobTitles(null, {
      rootStore: this.rootStore
    });
    this.fetchJobTitlesOnce = once(this.fetchJobTitles);
  }

  @action.bound setup() {
    this.phoneCountry = this.defaultPhoneCountry;
    this.setupMemberForm();
    this.fetchJobTitlesOnce();
    this.setupWorkerAttributes();
  }

  @action.bound tearDown() {
    this.clearUIState();
  }

  @action.bound async setupWorkerAttributes() {
    const promises = [
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.groupSelectorUI.setup()
    ];

    try {
      await Promise.all(promises);
    } catch (error) {
      alertErrorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound fetchJobTitles() {
    this.jobTitles.fetch({
      params: {
        limit: 10000
      }
    });
  }

  @computed get jobTitleOptions() {
    return this.jobTitles.models.map(jobTitle => {
      return {
        value: jobTitle.name,
        name: jobTitle.name
      };
    });
  }

  @action.bound setupMemberForm() {
    this.memberForm = new MemberForm(
      {
        fields: memberFormFields,
        rules: memberFormRules,
        labels: memberFormLabels,
        options: memberFormFieldOptions
      },
      {
        options: memberFormOptions,
        plugins: memberFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound cancelUsersAdd() {
    history.push('/team');
  }

  @action.bound submitMemberForm(event) {
    event.preventDefault();
    if (this.memberForm.submitting) return;

    this.memberForm.submit({
      onSuccess: this.submitMemberFormSuccess,
      onError: this.submitMemberFormError
    });
  }

  @action.bound submitMemberFormError() {
    console.error(this.memberForm.errors());
  }

  @action.bound submitMemberFormSuccess() {
    this.createMember();
    setTimeout(() => {
      this.phoneCountry = '';
      this.phoneCountry = this.defaultPhoneCountry;
    }, 0);
  }

  @computed get roleOptions() {
    return this.parent.roleOptions;
  }

  @computed get memberFormIsValid() {
    return this.memberForm.check('isValid');
  }

  @action.bound async createMember() {
    if (this.saving) return;

    this.saving = true;

    try {
      const memberField = this.memberForm.values();

      memberField.company = {
        uuid: this.company.uuid,
        name: this.company.name
      };
      delete memberField.projectUuids;
      memberField.allowSubscriptionUpgrade = true;

      const memberFieldData = pickBy(
        memberField,
        value => value !== undefined && value !== '' && value !== null
      );

      memberFieldData.settings = {
        workerDefaultShift: memberField.defaultShift?.uuid,
        workerDefaultCostCode: memberField.defaultCostCode?.uuid,
        workerDefaultCrewName: memberField.defaultCrewName?.uuid
      };

      if (memberField.role === 'ROLE_PROJECT_WORKER') {
        this.company.fetch();

        if (
          this.company.workerSeatsInUse + 1 >=
          this.company.companyAddOns?.timeClockWorkerSeats
        ) {
          this.showModal('WorkerLicenseCheckModal');
          return;
        }
      }

      const payload = omit(memberFieldData, [
        'defaultShift',
        'defaultCostCode',
        'defaultCrewName'
      ]);
      await request.post(`${this.rootStore.users.url()}`, payload);

      this.parent.refetchSubscription();

      this.company.fetch();

      this.parent.sortByLastCreated();
      history.push('/team');

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('User invite sent')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.memberForm = null;
    this.existingUser = null;
  }

  @computed
  get modalTitle() {
    return t('Create user');
  }

  @action.bound async cancelInviteWorker() {
    await this.hideActiveModal();
  }

  @action.bound
  initJobTitleForm() {
    this.jobTitleForm = new JobTitleForm(
      {
        fields: jobTitleFormFields,
        rules: jobTitleFormRules,
        values: jobTitleFormValues
      },
      {
        options: jobTitleFormOptions,
        plugins: jobTitleFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  showAddNewJobTitleModal() {
    this.initJobTitleForm();
    this.showModal('NewJobTitle');
  }

  @action.bound
  async hideAddNewJobTitleModal() {
    await this.hideActiveModal();
    this.jobTitleForm = null;
  }

  @action.bound
  async submitAddNewJobTitleSuccess() {
    const payload = this.jobTitleForm.values();
    try {
      const newJobTitle = await this.jobTitles.create(payload, {
        wait: true
      });
      this.memberForm.update({
        title: newJobTitle.name
      });
      this.hideAddNewJobTitleModal();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  submitAddNewJobTitleForm(e) {
    e.preventDefault();
    this.jobTitleForm.submit({
      onSuccess: this.submitAddNewJobTitleSuccess,
      onError: e => {
        console.error(this.jobTitleForm.errors());
      }
    });
  }

  @computed
  get disableSaveNewJobTitleButton() {
    return this.jobTitles.saving || this.jobTitleForm.hasError;
  }

  @action.bound
  setExistingUser(value) {
    this.existingUser = value;
  }
}

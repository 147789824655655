import qs from 'querystringify';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import request from 'axios';
import { action, observable, computed, reaction } from 'mobx';
import UIStore from './UIStore';
import Integration from 'stores/models/integrations/Integration';
import errorHandler from 'utils/errorHandler';

import {
  AppConnectForm,
  appConnectFormOptions,
  appConnectFormFields,
  appConnectFormRules,
  appConnectFormLabels,
  appConnectFormPlugins
} from 'forms/appConnect';

import {
  OshaConnectForm,
  oshaConnectFormFields,
  oshaConnectFormLabels,
  oshaConnectFormOptions,
  oshaConnectFormPlugins,
  oshaConnectFormRules,
  oshaConnectFormValues
} from 'forms/superAdmin/integrations/oshaConnect';

import {
  RyvitConnectForm,
  ryvitConnectFormOptions,
  ryvitConnectFormFields,
  ryvitConnectFormRules,
  ryvitConnectFormLabels,
  ryvitConnectFormPlugins
} from 'forms/superAdmin/integrations/ryvitConnect';

import {
  SageIntacctConnectForm,
  sageIntacctConnectFormOptions,
  sageIntacctConnectFormFields,
  sageIntacctConnectFormRules,
  sageIntacctConnectFormLabels,
  sageIntacctConnectFormPlugins
} from 'forms/superAdmin/integrations/sageIntacctConnect';

import {
  ComputerEaseDirectAPIConnectForm,
  computerEaseDirectAPIConnectFormOptions,
  computerEaseDirectAPIConnectFormFields,
  computerEaseDirectAPIConnectFormRules,
  computerEaseDirectAPIConnectFormLabels,
  computerEaseDirectAPIConnectFormPlugins
} from 'forms/superAdmin/integrations/computerEaseDirectAPIConnect';

export default class AppConnectUI extends UIStore {
  @observable activeCompany;
  @observable activeIntegration;
  @observable loading;
  @observable projectId;
  @observable companyId;
  @observable companyUuid;
  @observable error;

  @observable integrationOptions;

  constructor(options) {
    super(options);
    this.loading = false;
    this.projectId = null;
    this.projectUuid = null;
    this.companyId = null;
    this.companyUuid = null;
    this.error = null;
    this.activeCompany = null;
    this.activeIntegration = null;

    this.integrationOptions = [];
  }

  @action.bound async initializeConnect(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);

    await this.setActiveIntegration(integrationId);

    this.initializeForm();

    this.loading = false;
  }

  @action.bound initializeForm() {
    if (this.activeIntegration.isRyvit) {
      this.initializeRyvitConnectForm();
      return;
    }

    if (this.activeIntegration.isSageIntacct) {
      this.initializeSageIntacctConnectForm();
      return;
    }

    if (this.activeIntegration.isOsha) {
      this.initializeOshaConnectForm();
      return;
    }

    if (this.activeIntegration.isComputerEaseDirectAPI) {
      this.initializeComputerEaseDirectAPIConnectForm();
      return;
    }

    this.initializeAppConnectForm();
  }

  @action.bound initializeRyvitConnectForm() {
    this.activeForm = new RyvitConnectForm(
      {
        fields: ryvitConnectFormFields,
        rules: ryvitConnectFormRules,
        labels: ryvitConnectFormLabels,
        values: {
          subscriberCode: this.activeIntegration.subscriberCode || ''
        }
      },
      {
        options: ryvitConnectFormOptions,
        plugins: ryvitConnectFormPlugins
      }
    );
  }

  @action.bound initializeSageIntacctConnectForm() {
    this.activeForm = new SageIntacctConnectForm(
      {
        fields: sageIntacctConnectFormFields,
        rules: sageIntacctConnectFormRules,
        labels: sageIntacctConnectFormLabels
      },
      {
        options: sageIntacctConnectFormOptions,
        plugins: sageIntacctConnectFormPlugins
      }
    );
  }

  @action.bound initializeOshaConnectForm() {
    this.activeForm = new OshaConnectForm(
      {
        fields: oshaConnectFormFields,
        rules: oshaConnectFormRules,
        labels: oshaConnectFormLabels,
        values: oshaConnectFormValues
      },
      {
        options: oshaConnectFormOptions,
        plugins: oshaConnectFormPlugins
      }
    );

    this.reactToForm = reaction(
      () => this.activeForm.$('apiToken').value,
      params => {
        this.fetchOshaSubmitEstablishments();
      }
    );
  }

  @action.bound initializeComputerEaseDirectAPIConnectForm() {
    this.activeForm = new ComputerEaseDirectAPIConnectForm(
      {
        fields: computerEaseDirectAPIConnectFormFields,
        rules: computerEaseDirectAPIConnectFormRules,
        labels: computerEaseDirectAPIConnectFormLabels
      },
      {
        options: computerEaseDirectAPIConnectFormOptions,
        plugins: computerEaseDirectAPIConnectFormPlugins
      }
    );
  }

  @action.bound initializeAppConnectForm() {
    this.activeForm = new AppConnectForm(
      {
        fields: appConnectFormFields,
        rules: appConnectFormRules,
        labels: appConnectFormLabels
      },
      {
        options: appConnectFormOptions,
        plugins: appConnectFormPlugins
      }
    );
  }

  @action.bound tearDown() {
    this.tearDownReactions();
  }

  @action.bound tearDownReactions() {
    this.reactToForm && this.reactToForm();
  }

  @action.bound async initializeSuccess(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);

    await this.setActiveIntegration(integrationId);

    this.loading = false;
  }

  @action.bound async initializeFailure(options) {
    this.loading = true;

    const {
      projectId,
      projectUuid,
      companyId,
      companyUuid,
      integrationId,
      error
    } = options;

    this.setProjectId(projectId);
    this.setProjectUuid(projectUuid);
    this.setCompanyId(companyId);
    this.setCompanyUuid(companyUuid);
    this.setError(error);

    await this.setActiveIntegration(integrationId);

    this.loading = false;
  }

  @action.bound setCompanyId(companyId) {
    this.companyId = companyId || null;
  }

  @action.bound setCompanyUuid(companyUuid) {
    this.companyUuid = companyUuid || null;
  }

  @action.bound setProjectId(projectId) {
    this.projectId = projectId || null;
  }

  @action.bound setProjectUuid(projectUuid) {
    this.projectUuid = projectUuid || null;
  }

  @action.bound setError(error) {
    this.error = error;
  }

  @action.bound async setActiveIntegration(integrationId) {
    if (this.activeIntegration && this.activeIntegration.id === integrationId)
      return;

    let url;

    if (this.projectUuid) {
      url = `${this.rootStore.urlMicroService('integrations')}/projects/${
        this.projectUuid
      }/integrations/${integrationId}`;
    } else {
      url = `${this.rootStore.urlMicroService('integrations')}/companies/${
        this.companyUuid
      }/integrations/${integrationId}`;
    }

    try {
      const response = await request.get(url);

      this.activeIntegration = new Integration(response.data, {
        rootStore: this.rootStore
      });
    } catch (error) {
      errorHandler(error, this.rootStore.notificationsUI.pushError);
    }
  }

  @action.bound submitActiveForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.activeForm.submit({
      onSuccess: this.submitActiveFormSuccess,
      onError: this.submitActiveFormError
    });
  }

  @action.bound submitActiveFormSuccess() {
    if (this.activeIntegration.isRyvit) {
      this.submitRyvitConnectFormSuccess();
      return;
    }

    if (this.activeIntegration.isOsha) {
      this.submitOshaConnectFormSuccess();
      return;
    }

    this.submitConnectFormSuccess();
  }

  @action.bound submitActiveFormError() {
    console.log(this.activeForm.errors());
  }

  @action.bound async submitConnectFormSuccess() {
    this.clearValidationDetails();

    const payload = {};

    payload.credentials = this.activeForm.values();

    if (this.projectUuid) {
      payload.projectUuid = this.projectUuid;
    }

    if (
      this.activeIntegration.isSageIntacct ||
      this.activeIntegration.isComputerEaseDirectAPI
    ) {
      payload.businessUuid = this.companyUuid;
    }

    if (this.activeIntegration.isOsha) {
      const establishment = payload.credentials.establishment;
      payload.credentials = {
        apiToken: payload.credentials.apiToken,
        establishmentId: establishment.id,
        establishmentName: establishment.name
      };
    }

    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('integrations')}/apps/${
          this.activeIntegration.id
        }/connect`,
        payload
      );

      this.saving = false;

      window.location = this.successUrl;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }

      this.saving = false;
    }
  }

  @computed get successUrl() {
    return `connect/success${qs.stringify(
      pickBy({
        integrationId: this.activeIntegration.id,
        companyUuid: this.companyUuid,
        projectUuid: this.projectUuid,
        projectId: this.projectId
      }),
      identity
    )}`;
  }

  @action.bound
  async submitRyvitConnectFormSuccess() {
    this.clearValidationDetails();

    const values = this.activeForm.values();

    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('integrations')}/ryvit/connect`,
        {
          applicationId: this.activeIntegration.id,
          businessId: this.companyId,
          companyUuid: this.companyUuid,
          subscriberCode: values.subscriberCode
        }
      );

      this.saving = false;

      window.location = this.successUrl;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }
    }

    this.saving = false;
  }

  @action.bound async submitOshaConnectFormSuccess() {
    this.clearValidationDetails();

    const { apiToken, establishment } = this.activeForm.values();

    const payload = {
      credentials: {
        apiToken: apiToken,
        establishmentId: establishment.id,
        establishmentName: establishment.name
      }
    };

    this.saving = true;

    try {
      await request.post(
        `${this.rootStore.urlMicroService('integrations')}/apps/${
          this.activeIntegration.id
        }/connect`,
        payload
      );

      await request.get(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/incident-reports/submission/connect`
      );

      this.saving = false;

      window.location = this.successUrl;
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }

      this.saving = false;
    }
  }

  @action.bound async fetchOshaSubmitEstablishments() {
    if (!this.activeForm.$('apiToken').value) return;

    this.clearValidationDetails();

    try {
      const response = await request.post(
        `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/incident-reports/submission/token-validation`,
        {
          token: this.activeForm.$('apiToken').value
        }
      );

      this.integrationOptions = response.data.map(item => {
        return {
          id: item.id,
          name: item.establishmentName
        };
      });
    } catch (error) {
      if (error.response) {
        const { data } = error.response;

        if (data.validationDetails) {
          this.setValidationDetails(data.validationDetails);
        } else {
          this.setValidationDetails([
            { field: 'credentials', fieldMessage: data.message }
          ]);
        }
      }
    }
  }
}

import { t } from 'utils/translate';

import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

export default class TeamNavigationUI extends UIStore {
  @computed get items() {
    return {
      id: 'team',
      icon: 'users',
      label: t('Team'),
      link: '/team',
      subnav: [
        {
          id: 'team-members',
          label: t('Team members'),
          icon: 'employees',
          link: '/team'
        },
        {
          id: 'workers',
          label: t('Workers'),
          icon: 'user',
          link: '/workers'
        }
      ]
    };
  }
}

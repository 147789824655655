import { t } from 'utils/translate';
import { computed } from 'mobx';
import UIStore from 'stores/ui/UIStore';

export default class MessagingNavigationUI extends UIStore {
  @computed
  get previousPath() {
    const { location } = this.parent || {};
    return location?.pathname || '/';
  }

  @computed
  get items() {
    return {
      id: 'messages',
      icon: 'message',
      label: t('Messages'),
      link: {
        pathname: '/messaging',
        state: { previousPath: this.previousPath }
      },
      subnav: []
    };
  }
}

import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';
import { action, computed, reaction } from 'mobx';
import InsightsUIModel from './InsightsUIModel';
import ProjectMeasures from '../collections/ProjectMeasures';
import rankCollectionByValue from '../../utils/rankCollectionByValue';

export default class InsightsComplianceBySubContractor extends InsightsUIModel {
  constructor(attributes, options) {
    super(attributes, options);

    this.projectMeasures = new ProjectMeasures(null, {
      rootStore: this.rootStore,
      parent: this
    });

    // React to selection changes
    this.reactToSelection = reaction(
      () => this.params,
      params => {
        if (params?.projectIds || params?.projectStatuses) {
          this.fetchStats();
        } else {
          this.cancelRequest();
          this.projectMeasures.reset();
        }
      },
      { fireImmediately: true }
    );
  }

  url() {
    return '/ra/insights/complianceBySubcontractor';
  }

  @action.bound
  parse(attributes) {
    this.parseProjectMeasures(attributes.projectMeasures);

    return {
      ...omit(attributes, ['projectMeasures'])
    };
  }

  @computed
  get rolledUpTeamMeasuresByProject() {
    let projectTeamMeasures = [];

    this.projectMeasures.models
      .filter(model => model.teamMeasures && model.teamMeasures.length)
      .forEach(projectMeasure => {
        projectTeamMeasures = projectTeamMeasures.concat(
          projectMeasure.teamMeasures.models.filter(teamMeasure => {
            return (
              teamMeasure.projectTeamId !== projectMeasure.relatedProject.teamId
            );
          })
        );
      });

    return rankCollectionByValue(
      projectTeamMeasures,
      `stats.${this.rankField}`
    );
  }

  @computed get filteredRolledUpTeamMeasuresByProject() {
    return this.rolledUpTeamMeasuresByProject.filter(teamMeasure => {
      return this.insightsUI.filteredProjects.find(project => {
        return project.id === teamMeasure.relatedProject.id;
      });
    });
  }

  @computed
  get sortedRolledUpTeamMeasuresByProject() {
    switch (this.sortField) {
      case 'ranking':
        return orderBy(
          this.filteredRolledUpTeamMeasuresByProject,
          ['ranking'],
          [this.sortDirection]
        );
      case 'name':
        return orderBy(
          this.filteredRolledUpTeamMeasuresByProject,
          [
            projectTeamMeasure =>
              projectTeamMeasure.stats.company.name.toLowerCase()
          ],
          [this.sortDirection]
        );
      default:
        return orderBy(
          this.filteredRolledUpTeamMeasuresByProject,
          [projectTeamMeasure => projectTeamMeasure.stats[this.sortField]],
          [this.sortDirection]
        );
    }
  }

  @computed
  get hasRolledUpTeamMeasuresByProject() {
    return this.filteredRolledUpTeamMeasuresByProject.length > 0;
  }
}

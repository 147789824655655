import { RakenCollection } from 'stores/lib';
import IncidentReport300301 from 'stores/models/incidents/IncidentReport300301';

export default class IncidentReport300301s extends RakenCollection {
  model() {
    return IncidentReport300301;
  }

  url() {
    return `${this.rootStore.appConfig.safety_api_url}/companies/${this.rootStore.me.company.uuid}/incident-reports/submission/form-300-301`;
  }
}

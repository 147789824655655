import moment from 'moment';
import { action, computed } from 'mobx';
import ProjectChildAddUI from './../ProjectChildAddUI';

import EquipmentLog from 'stores/models/equipment/EquipmentLog';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import history from 'utils/history';

import {
  EquipmentLogForm,
  equipmentLogFormOptions,
  equipmentLogFormFields,
  equipmentLogFormLabels,
  equipmentLogFormPlugins,
  equipmentLogFormRules,
  equipmentLogFormRelated
} from 'forms/equipmentLog';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_LOG_ADDED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentLogAddUI extends ProjectChildAddUI {
  @computed get parentEquipmentDeployment() {
    return this.parent.entryForEdit;
  }

  @computed get defaultOperatorValue() {
    return {
      uuid: this.me.uuid,
      name: this.me.fullName,
      workerUuid: this.me.workerUuid,
      company: {
        uuid: this.me.company.uuid,
        name: this.me.company.name
      },
      email: this.me.email,
      phoneNumber: this.me.phoneNumber
    };
  }

  @action.bound setup() {
    this.memberSelectorUI.setup({
      projectUuids: [this.project.uuid]
    });

    this.entryForAdd = new EquipmentLog(
      {},
      {
        rootStore: this.rootStore
      }
    );

    const values = {
      ...this.entryForAdd.formValues,
      costCode: this.parentEquipmentDeployment.formValues?.costCode, // Add parent default cost code to logs
      operator: this.defaultOperatorValue,
      logStatus: 'IN_USE'
    };

    if (this.parentEquipmentDeployment?.equipment?.trackMileage) {
      values.odometerReading = this.parentEquipmentDeployment?.equipment.odometerReading;
    }

    this.entryAddForm = new EquipmentLogForm(
      {
        fields: equipmentLogFormFields,
        rules: Object.assign(equipmentLogFormRules, {
          date: `required|dateCustom:YYYY-MM-DD|equipmentLogDate:YYYY-MM-DD,${this.parentEquipmentDeployment.startDate}`
        }),
        labels: equipmentLogFormLabels,
        values,
        related: equipmentLogFormRelated
      },
      {
        options: equipmentLogFormOptions,
        plugins: equipmentLogFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async submitEntryAddFormSuccess() {
    this.saving = true;

    try {
      const values = this.entryAddForm.values();

      const attachments = this.entryForAdd.attachments.models.map(
        attachment => {
          return {
            uuid: attachment.uuid
          };
        }
      );

      await this.entryForAdd.save(
        Object.assign(values, {
          date: moment(values.date).format('YYYY-MM-DD'),
          attachments: attachments,
          equipmentDeficiencies: this.entryAddForm.deficiencyValues
        }),
        {
          url: `${this.parentEquipmentDeployment.url()}/logs`,
          wait: true
        }
      );

      callTrack(EQUIPMENT_LOG_ADDED, {
        cost_code_selected: this.entryForAdd.costCode,
        status: this.entryForAdd.logStatus,
        total_hours_entered: this.entryForAdd.hours,
        deficiency_added: Boolean(
          this.entryForAdd.equipmentDeficienciesCount > 0
        ),
        idle_hours_entered: this.entryForAdd.idleHours,
        note_entered: Boolean(this.entryForAdd.description > 0),
        mileage_added: Boolean(this.entryForAdd.odometerReading > 0),
        fuel_added: Boolean(this.entryForAdd.fuelConsumption > 0)
      });

      // Manually increment the  count field
      this.parentEquipmentDeployment.logCount++;

      this.parent.sortByLastCreated();
      this.cancelEquipmentLogAdd();

      // Ensure any unsaved changed on the parent equipment log
      // form are prompted.
      this.parent.parent.blockHistoryIfFormChanged();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Log created')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound cancelEquipmentLogAdd() {
    history.push({
      pathname: `${this.project.viewUrl}/equipment/${this.parentEquipmentDeployment.uuid}`,
      search: this.baseQueryParams
    });
  }

  @computed get statusOptions() {
    return [
      {
        value: 'IN_USE',
        name: t('In use')
      },
      {
        value: 'NOT_IN_USE',
        name: t('Not in use')
      },
      {
        value: 'WITHDRAWN',
        name: t('Withdrawn')
      }
    ];
  }

  @computed get selectedStatusOption() {
    return this.statusOptions.find(
      option => option.value === this.entryAddForm?.$('logStatus').value
    );
  }

  @action.bound
  addDeficiency() {
    this.entryAddForm.$('deficiencies').add({
      faultCode: '',
      deficiencyDescription: ''
    });
  }

  @action.bound
  removeDeficiency(deficiencyPath) {
    this.entryAddForm.$('deficiencies').del(deficiencyPath);
  }
}

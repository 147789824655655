import { action, computed } from 'mobx';

import ActivityUI from './ActivityUI';

export default class ProjectDashboardActivityUI extends ActivityUI {
  @action.bound
  setup() {
    this.projectFilters.replace([
      {
        value: this.project.uuid,
        name: this.project.name
      }
    ]);

    this.setupReactions();
    this.fetchPageOne();
  }

  @action.bound
  tearDown() {
    this.clearAll();
    this.tearDownReactions();
  }

  @computed get appliedFiltersCount() {
    return this.activityFilters.length > 0 ? 1 : 0;
  }

  @action.bound clearFilters() {
    this.activityFilters.clear();
    this.hideFilters();
  }
}

import { computed } from 'mobx';
import { Model } from 'mobx-mc';

export default class SmallEquipment extends Model {
  get urlRoot() {
    return `${this.rootStore.appConfig.tracking_api_url}/companies/${this.rootStore.me.company.uuid}/smallequipment`;
  }

  get restAttributes() {
    return [
      'uuid',
      'createdBy',
      'businessUuid',
      'name',
      'totalUnits',
      'totalDeployedUnits',
      'availableUnits',
      'quantity'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed get formValues() {
    return {
      name: this.name,
      quantity: this.totalUnits
    };
  }

  @computed get viewUrl() {
    return `/company/equipment/small-equipment/${this.uuid}`;
  }
}

import { observable, action, computed, reaction } from 'mobx';
import UIStore from '../../UIStore';
import debounce from 'lodash.debounce';
import request from 'axios';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import EquipmentNotifications from 'stores/collections/EquipmentNotifications';

import alertErrorHandler from 'utils/alertErrorHandler';

import { EQUIPMENT_NOTIFICATIONS_ADDED } from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

import {
  EquipmentNotificationsForm,
  equipmentNotificationsFormOptions,
  equipmentNotificationsFormFields,
  equipmentNotificationsFormLabels,
  equipmentNotificationsFormPlugins
} from 'forms/equipment/equipmentNotifications';

import { t } from 'utils/translate';

export default class EquipmentNotificationsUI extends UIStore {
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable form;
  @observable entry;
  @observable saving;
  @observable searchQuery;
  @observable equipmentNotificationsForm;

  constructor(options) {
    super(options);
    this.equipmentNotificationsForm = null;

    this.loading = true;

    this.page = 1;
    this.pageSize = 20;

    this.form = null;
    this.entry = null;

    this.searchQuery = '';

    this.saving = false;

    this.groupsFilters = observable([]);

    // Equipment notifications collection
    this.equipmentNotifications = new EquipmentNotifications(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchEquipmentNotificationsDebounced = debounce(
      this.fetchEquipmentNotifications,
      BASE_DEBOUNCE
    );
  }

  @computed get equipment() {
    return this.parent.equipment;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.equipmentNotifications.totalElements / this.pageSize);
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchEquipmentNotifications();
    this.groupSelectorUI.setup();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEquipmentNotificationsDebounced();
      }
    );
  }

  @action.bound
  async showEquipmentNotificationsModal() {
    this.showModal('EquipmentNotificationsModal');

    this.equipmentNotificationsForm = new EquipmentNotificationsForm(
      {
        fields: equipmentNotificationsFormFields,
        labels: equipmentNotificationsFormLabels
      },
      {
        options: equipmentNotificationsFormOptions,
        plugins: equipmentNotificationsFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async hideEquipmentNotificationsModal() {
    await this.hideActiveModal();
    this.equipmentNotificationsForm = null;
  }

  @action.bound submitNotificationsForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.equipmentNotificationsForm.submit({
      onSuccess: this.submitNotificationsFormSuccess,
      onError: this.submitNotificationsFormError
    });
  }

  @action.bound async submitNotificationsFormSuccess() {
    this.saving = true;

    const values = this.equipmentNotificationsForm.values();

    try {
      await request.post(
        `${this.equipment.url()}/notifications/batch`,
        this.parent.equipmentActionsUI.selectedEquipment.map(
          selectedEquipment => {
            return {
              equipmentUuid: selectedEquipment.uuid,
              logMissed: Boolean(values.logMissed),
              maintenanceDue: Boolean(values.maintenanceDue),
              deficiencyLogged: Boolean(values.deficiencyLogged),
              rentalEnding: Boolean(
                !selectedEquipment.owned && values.rentalEnding
              )
            };
          }
        )
      );

      this.hideEquipmentNotificationsModal();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Notifications updated.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentDeploymentFormError() {
    console.error(this.equipmentDeploymentForm.errors());
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
    this.groupSelectorUI.tearDown();
  }

  @action.bound clearUIState() {
    this.page = 1;
    this.entry = null;
    this.saving = false;
    this.searchQuery = '';
    this.clearValidationDetails();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      groupUuids: this.groupsFilters.map(group => group.value)
    };
  }

  @action.bound async fetchEquipmentNotifications() {
    this.loading = true;
    this.equipmentNotifications.cancelRequest();
    this.equipmentNotifications.reset();

    try {
      await this.equipmentNotifications.fetchWithPost({
        url: `${this.entryForEdit.url()}/notifications/search`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasEquipmentNotifications() {
    return this.equipmentNotifications.hasModels;
  }

  @computed get hasAppliedFilters() {
    return Boolean(this.groupsFilters.length);
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      !this.hasAppliedFilters &&
      !this.hasEquipmentNotifications
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || this.hasAppliedFilters) &&
      !this.hasEquipmentNotifications
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound updateCheckbox(notification, field) {
    notification[field] = !notification[field];

    notification.save(notification.toggleValues, {
      url: notification.uuid
        ? `${this.entryForEdit.url()}/notifications/${notification.uuid}`
        : `${this.entryForEdit.url()}/notifications`
    });

    callTrack(EQUIPMENT_NOTIFICATIONS_ADDED);
  }

  @action.bound updateGroupFilters(selectedGroups) {
    this.groupsFilters.replace(
      selectedGroups.map(group => {
        return {
          value: group.uuid,
          name: group.name
        };
      })
    );
  }

  @computed get allSelectedEquipmentIsOwned() {
    return this.parent.equipmentActionsUI.selectedEquipment.every(
      selectedEquipment => selectedEquipment.owned
    );
  }
}

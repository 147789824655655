import omit from 'lodash.omit';
import { Model } from 'mobx-mc';
import { computed } from 'mobx';
import { t } from '../../utils/translate';
import kebabCase from 'lodash.kebabcase';

export default class ProjectTemplate extends Model {
  urlRoot = `/ra/project-templates`;

  get restAttributes() {
    return [
      'id',
      'classificationsEditableOnTimeCards',
      'classificationsOnTimeCards',
      'collaboratorQuestions',
      'costCodesOnTimeCards',
      'breakSetting',
      'dateFormat',
      'emailInterval',
      'enableCrewGrouping',
      'enableKiosk',
      'enableManualTimeCapture',
      'enableTaskSwitching',
      'enableTimeClock',
      'enableWatermark',
      'externalMembersRecipients',
      'fahrenheit',
      'frequency',
      'hiddenBreakTypes',
      'hiddenPayTypes',
      'hiddenShifts',
      'hideEmptySections',
      'hideEmptySubcontractors',
      'hideGrandTotal',
      'hideInactiveSubcontractors',
      'inches',
      'includeCompletedChecklists',
      'includeDailySurvey',
      'includeEquipment',
      'includeEquipmentForExternalMembers',
      'includeHours',
      'includeHoursToDate',
      'includeMaterialQuantity',
      'includeMaterialQuantityForExternalMembers',
      'includeMyCompanyQuestionsForSubcontractors',
      'includeNotes',
      'includeQualityControlObservations',
      'includeQuantity',
      'includeSafetyObservations',
      'includeStampsForEntries',
      'includeStampsForPhotos',
      'includeTimeCards',
      'includeTimeCardsForExternalMembers',
      'includeToolboxTalks',
      'includeWeather',
      'includeWorkLogs',
      'internalMembersRecipients',
      'intervals',
      'isDefault',
      'languageId',
      'milesPerHour',
      'overtimeRuleSetUuid',
      'projectRadius',
      'questions',
      'sendBySign',
      'superDailyIndex',
      'templateName',
      'timeCardsStartAndEndTime',
      'clockInChecklistTemplateUuid',
      'clockOutChecklistTemplateUuid',
      'requirePhotoId',
      'requirePhotoIdOnClockInOutAndBreaks',
      'type',
      'watermarkAlignment',
      'watermarkIncludeAutoGenLocation',
      'watermarkIncludeCompanyLogo',
      'watermarkIncludeCompanyName',
      'watermarkIncludeGPSCoords',
      'watermarkIncludeProjectName',
      'watermarkIncludeTimestamp',
      'weatherIntervals'
    ];
  }

  get restAttributeDefaults() {
    return {
      requirePhotoIdOnClockInOutAndBreaks: 'NOT_REQUIRED'
    };
  }

  @computed
  get formValues() {
    return {
      classificationsEditableOnTimeCards: this
        .classificationsEditableOnTimeCards,
      classificationsOnTimeCards: this.classificationsOnTimeCards,
      costCodesOnTimeCards: this.costCodesOnTimeCards,
      breakSetting: this.breakSetting,
      dateFormat: this.dateFormat,
      deleted: this.deleted,
      emailInterval: this.emailInterval,
      enableCrewGrouping: this.enableCrewGrouping,
      enableKiosk: this.enableKiosk,
      enableManualTimeCapture: this.enableManualTimeCapture,
      enableTaskSwitching: this.enableTaskSwitching,
      enableTimeClock: this.enableTimeClock,
      enableWatermark: this.enableWatermark,
      externalMembersRecipients: this.externalMembersRecipients.slice(),
      fahrenheit: this.fahrenheit,
      frequency: this.frequency,
      hiddenBreakTypes: this.hiddenBreakTypes?.slice(),
      hiddenPayTypes: this.hiddenPayTypes?.slice(),
      hiddenShifts: this.hiddenShifts?.slice(),
      hideEmptySections: this.hideEmptySections,
      hideEmptySubcontractors: this.hideEmptySubcontractors,
      hideGrandTotal: this.hideGrandTotal,
      hideInactiveSubcontractors: this.hideInactiveSubcontractors,
      inches: this.inches,
      includeCompletedChecklists: this.includeCompletedChecklists,
      includeDailySurvey: this.includeDailySurvey,
      includeEquipment: this.includeEquipment,
      includeEquipmentForExternalMembers: this
        .includeEquipmentForExternalMembers,
      includeHours: this.includeHours,
      includeHoursToDate: this.includeHoursToDate,
      includeMaterialQuantity: this.includeMaterialQuantity,
      includeMaterialQuantityForExternalMembers: this
        .includeMaterialQuantityForExternalMembers,
      includeMyCompanyQuestionsForSubcontractors: this
        .includeMyCompanyQuestionsForSubcontractors,
      includeNotes: this.includeNotes,
      includeQualityControlObservations: this.includeQualityControlObservations,
      includeQuantity: this.includeQuantity,
      includeSafetyObservations: this.includeSafetyObservations,
      includeStampsForEntries: this.includeStampsForEntries,
      includeStampsForPhotos: this.includeStampsForPhotos,
      includeTimeCards: this.includeTimeCards,
      includeTimeCardsForExternalMembers: this
        .includeTimeCardsForExternalMembers,
      includeToolboxTalks: this.includeToolboxTalks,
      includeWeather: this.includeWeather,
      includeWorkLogs: this.includeWorkLogs,
      internalMembersRecipients: this.internalMembersRecipients.slice(),
      intervals: this.intervals.slice(),
      isDefault: this.isDefault,
      languageId: this.languageId,
      milesPerHour: this.milesPerHour,
      overtimeRuleSetUuid: this.overtimeRuleSetUuid,
      projectRadius: this.projectRadius,
      questions: this.questions.map(question => {
        return {
          uuid: question.id,
          ...omit(question, 'id')
        };
      }),
      collaboratorQuestions: this.collaboratorQuestions.map(
        collaboratorQuestion => {
          return {
            uuid: collaboratorQuestion.id,
            ...omit(collaboratorQuestion, 'id')
          };
        }
      ),
      sendBySign: this.sendBySign,
      superDailyIndex: this.superDailyIndex,
      templateName: this.templateName,
      timeCardsStartAndEndTime: this.timeCardsStartAndEndTime,
      clockInChecklistTemplateUuid: this.clockInChecklistTemplateUuid,
      clockOutChecklistTemplateUuid: this.clockOutChecklistTemplateUuid,
      requirePhotoId: this.requirePhotoId,
      requirePhotoIdOnClockInOutAndBreaks: this
        .requirePhotoIdOnClockInOutAndBreaks,
      watermarkAlignment: this.watermarkAlignment,
      watermarkIncludeAutoGenLocation: this.watermarkIncludeAutoGenLocation,
      watermarkIncludeCompanyLogo: this.watermarkIncludeCompanyLogo,
      watermarkIncludeCompanyName: this.watermarkIncludeCompanyName,
      watermarkIncludeGPSCoords: this.watermarkIncludeGPSCoords,
      watermarkIncludeProjectName: this.watermarkIncludeProjectName,
      watermarkIncludeTimestamp: this.watermarkIncludeTimestamp,
      weatherIntervals: this.weatherIntervals.slice()
    };
  }

  @computed
  get displayName() {
    if (this.isDefault) {
      return `${this.templateName} | ${t('Company default template')}`;
    }

    return this.templateName;
  }

  @computed get slug() {
    return kebabCase(this.templateName);
  }

  @computed get infoFormValues() {
    return {
      templateName: this.templateName,
      isDefault: this.isDefault
    };
  }

  @computed get generalFormValues() {
    return {
      dateFormat: this.dateFormat,
      milesPerHour: this.milesPerHour,
      inches: this.inches,
      intervals: this.intervals,
      fahrenheit: this.fahrenheit,
      weatherIntervals: this.weatherIntervals,
      frequency: this.frequency,
      languageId: this.languageId,
      projectRadius: this.projectRadius
    };
  }

  @computed get dailyReportFormValues() {
    return {
      includeWeather: this.includeWeather,
      includeHours: this.includeHours,
      includeQuantity: this.includeQuantity,
      includeWorkLogs: this.includeWorkLogs,
      includeHoursToDate: this.includeHoursToDate,
      includeNotes: this.includeNotes,
      includeDailySurvey: this.includeDailySurvey,
      includeStampsForPhotos: this.includeStampsForPhotos,
      includeStampsForEntries: this.includeStampsForEntries,
      hideEmptySections: this.hideEmptySections,
      includeSafetyObservations: this.includeSafetyObservations,
      includeQualityControlObservations: this.includeQualityControlObservations,
      includeToolboxTalks: this.includeToolboxTalks,
      includeCompletedChecklists: this.includeCompletedChecklists,
      includeTimeCards: this.includeTimeCards,
      includeMaterialQuantity: this.includeMaterialQuantity,
      includeEquipment: this.includeEquipment,
      hideInactiveSubcontractors: this.hideInactiveSubcontractors,
      hideEmptySubcontractors: this.hideEmptySubcontractors,
      hideGrandTotal: this.hideGrandTotal
    };
  }

  @computed get surveyFormValues() {
    return {
      includeMyCompanyQuestionsForSubcontractors: this
        .includeMyCompanyQuestionsForSubcontractors,
      questions: this.questions.slice(),
      collaboratorQuestions: this.collaboratorQuestions.slice()
    };
  }

  @computed get recipientsFormValues() {
    return {
      sendBySign: this.sendBySign,
      emailInterval: this.emailInterval,
      superDailyIndex: this.superDailyIndex,
      internalMembersRecipients: this.internalMembersRecipients,
      internalMembersRecipient: this.internalMembersRecipient,
      includeTimeCardsForExternalMembers: this
        .includeTimeCardsForExternalMembers,
      externalMembersRecipients: this.externalMembersRecipients,
      externalMembersRecipient: this.externalMembersRecipient,
      includeMaterialQuantityForExternalMembers: this
        .includeMaterialQuantityForExternalMembers,
      includeEquipmentForExternalMembers: this
        .includeEquipmentForExternalMembers
    };
  }

  @computed get timeTrackingValues() {
    return {
      timeCardsStartAndEndTime: this.timeCardsStartAndEndTime,
      costCodesOnTimeCards: this.costCodesOnTimeCards,
      breakSetting: this.breakSetting,
      classificationsOnTimeCards: this.classificationsOnTimeCards,
      classificationsEditableOnTimeCards: this
        .classificationsEditableOnTimeCards,
      overtimeRuleSetUuid: this.overtimeRuleSetUuid
    };
  }

  @computed get watermarkValues() {
    return {
      enableWatermark: this.enableWatermark,
      watermarkIncludeCompanyLogo: this.watermarkIncludeCompanyLogo,
      watermarkIncludeCompanyName: this.watermarkIncludeCompanyName,
      watermarkIncludeProjectName: this.watermarkIncludeProjectName,
      watermarkIncludeTimestamp: this.watermarkIncludeTimestamp,
      watermarkIncludeGPSCoords: this.watermarkIncludeGPSCoords,
      watermarkIncludeAutoGenLocation: this.watermarkIncludeAutoGenLocation,
      watermarkAlignment: this.watermarkAlignment
    };
  }

  @computed get projectRadiusValues() {
    return {
      projectRadius: this.projectRadius
    };
  }

  @computed get breaksValues() {
    return {
      hiddenBreakTypes: this.hiddenBreakTypes.slice()
    };
  }

  @computed get timeCaptureValues() {
    return {
      enableKiosk: this.enableKiosk,
      enableTimeClock: this.enableTimeClock,
      enableTaskSwitching: this.enableTaskSwitching,
      enableCrewGrouping: this.enableCrewGrouping,
      enableSegments: this.enableSegments,
      timeCardsStartAndEndTime: this.timeCardsStartAndEndTime,
      requirePhotoId: this.requirePhotoId,
      requirePhotoIdOnClockInOutAndBreaks: this
        .requirePhotoIdOnClockInOutAndBreaks,
      clockInChecklistTemplateUuid: this.clockInChecklistTemplateUuid,
      clockOutChecklistTemplateUuid: this.clockOutChecklistTemplateUuid
    };
  }

  @computed get shiftsValues() {
    return {
      hiddenShifts: this.hiddenShifts.slice()
    };
  }

  @computed get payTypesValues() {
    return {
      hiddenPayTypes: this.hiddenPayTypes.slice()
    };
  }

  @computed get viewUrl() {
    return `/company/project-templates/${this.id}`;
  }
}

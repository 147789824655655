import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { CERTIFICATION_TYPE_DELETED } from 'utils/segmentAnalytics/eventSpec';
import CertificationTypes from 'stores/collections/CertificationTypes';
import CertificationTypeAddUI from './CertificationTypeAddUI';
import CertificationTypeEditUI from './CertificationTypeEditUI';

export default class CertificationTypesUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedCertificationType;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedCertificationType = null;

    this.sortField = 'createdDate';
    this.sortDirection = 'desc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // CertificationTypes collection
    this.certificationTypes = new CertificationTypes(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationTypeAddUI = new CertificationTypeAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.certificationTypeEditUI = new CertificationTypeEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchCertificationTypesDebounced = debounce(
      this.fetchCertificationTypes,
      BASE_DEBOUNCE
    );
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchCertificationTypes();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchCertificationTypesDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: `${this.sortField},typeName`,
      sortDirection: this.sortDirection
    };
  }

  @action.bound async fetchCertificationTypes() {
    this.certificationTypes.cancelRequest();
    this.certificationTypes.reset();

    try {
      await this.certificationTypes.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasCertificationTypes() {
    return this.certificationTypes.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.certificationTypes.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.searchQuery = '';
    this.certificationTypes.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'createdDate';
    this.sortDirection = 'desc';
    this.selectedCertificationType = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasCertificationTypes;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasCertificationTypes;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addCertificationType() {
    await this.authorization.checkFeatureAccess('CUDCompanyCertificationTypes');
    history.push({
      pathname: `/company/certifications/types/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editCertificationType(certificationType) {
    await this.authorization.checkFeatureAccess('CUDCompanyCertificationTypes');

    history.push({
      pathname: `/company/certifications/types/${certificationType.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteCertificationType(certificationType) {
    await this.authorization.checkFeatureAccess('CUDCompanyCertificationTypes');
    this.selectedCertificationType = certificationType;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteCertificationType() {
    await this.hideActiveModal();

    this.selectedCertificationType = null;
  }

  @action.bound async confirmDeleteCertificationType() {
    this.saving = true;

    try {
      await this.selectedCertificationType.destroy({ wait: true });

      this.refetchCertificationTypes();

      await this.hideActiveModal();

      callTrack(CERTIFICATION_TYPE_DELETED, {
        certification_type_name: this.selectedCertification.name,
        certification_type_description: this.selectedCertification.description
      });

      this.selectedCertificationType = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Certification type deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCertificationTypesDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchCertificationTypesDebounced();
  }

  @action.bound refetchCertificationTypes() {
    this.loading = true;
    if (!this.hasCertificationTypes) {
      this.setPage(null, 1);
      this.fetchCertificationTypes();
    } else {
      this.fetchCertificationTypes();
    }
  }

  getCertificationTypeActions = certificationType => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editCertificationType(certificationType);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteCertificationType(certificationType);
        }
      }
    ];
  };
}

export default urls => {
  let newWindow = null;

  const openNextUrl = index => {
    if (index >= urls.length) return;

    const { pathName, interval = 0, target = '_self' } = urls[index];

    setTimeout(() => {
      if (index === 0 && target === '_blank') {
        newWindow = window.open(pathName);
      } else if (newWindow) {
        newWindow.location.href = pathName;
      } else {
        window.location.href = pathName;
      }
      openNextUrl(index + 1);
    }, interval);
  };

  openNextUrl(0);
};

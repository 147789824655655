import { observable, action, computed } from 'mobx';

import UIStore from '../UIStore';

// Observations
import InsightsPositiveNegativeObservations from 'stores/models/InsightsPositiveNegativeObservations';
import InsightsObservationsRaisedByType from 'stores/models/InsightsObservationsRaisedByType';
import InsightsObservationsRaisedByTeamMember from 'stores/models/InsightsObservationsRaisedByTeamMember';
import InsightsIssuesObservedHoursWorked from 'stores/models/InsightsIssuesObservedHoursWorked';

import { callTrack } from 'utils/segmentIntegration';

import {
  COMPANY_QUALITY_INSIGHTS_VIEWED,
  COMPANY_QUALITY_INSIGHTS_PDF
} from 'utils/segmentAnalytics/eventSpec';

// Checklists
import InsightsChecklistSummary from 'stores/models/InsightsChecklistSummary';
import InsightsChecklistCompletion from 'stores/models/InsightsChecklistCompletion';
import InsightsChecklistCompletionByWorkUnit from 'stores/models/InsightsChecklistCompletionByWorkUnit';
import InsightsChecklistFailedResponses from 'stores/models/InsightsChecklistFailedResponses';

import {
  InsightsQualityControlDisplayFiltersForm,
  insightsQualityControlDisplayFiltersFormRules,
  insightsQualityControlDisplayFiltersFormLabels,
  insightsQualityControlDisplayFiltersFormFields,
  insightsQualityControlDisplayFiltersFormOptions,
  insightsQualityControlDisplayFiltersFormPlugins
} from 'forms/insights/insightsQualityControlDisplayFilters';

export default class InsightsQualityUI extends UIStore {
  @observable loading;

  @observable modelsSet;

  // PDF download
  @observable pdfDownload;

  // Display Filters
  @observable displayFiltersForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.modelsSet = false;

    // Mobile Nav
    this.mobileNavOpen = false;

    // PDF
    this.pdfDownload = false;

    // Filters
    this.displayFiltersForm = null;

    this.displayFilters = observable.map({
      checklistSummary: true,
      checklistCompletion: true,
      checklistCompletionByWorkUnit: true,
      checklistFailedResponses: true,
      insightsPositiveNegativeObservations: true,
      insightsObservationsRaisedByType: true,
      insightsObservationsRaisedByTeamMember: true,
      insightsIssuesObservedHoursWorked: true
    });
  }

  @computed get projects() {
    return this.parent.projects;
  }

  @computed get hasProjects() {
    return this.projects.hasModels;
  }

  @action.bound async setup() {
    this.setupModels();
    this.setupDisplayFilters();
    callTrack(COMPANY_QUALITY_INSIGHTS_VIEWED);
  }

  @action.bound tearDown() {
    this.tearDownModels();
    this.clearUIState();
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.mobileNavOpen = false;
    this.pdfDownload = false;
  }

  @action.bound setPdfDownload(value) {
    this.pdfDownload = value;

    callTrack(COMPANY_QUALITY_INSIGHTS_PDF);
  }

  @action.bound clearPdfDownload(value) {
    this.pdfDownload = null;
  }

  @action.bound
  setupModels() {
    if (this.modelsSet) return;

    this.checklistSummary = new InsightsChecklistSummary(null, {
      typeClasses: 'Commissioning,Quality',
      rootStore: this.rootStore,
      parent: this
    });

    this.checklistCompletion = new InsightsChecklistCompletion(null, {
      typeClasses: 'Commissioning,Quality',
      rootStore: this.rootStore,
      parent: this
    });

    this.checklistCompletionByWorkUnit = new InsightsChecklistCompletionByWorkUnit(
      null,
      {
        typeClasses: 'Commissioning,Quality',
        workUnitTypes: 'WorkerClassification',
        rootStore: this.rootStore,
        parent: this
      }
    );

    this.checklistFailedResponses = new InsightsChecklistFailedResponses(null, {
      typeClasses: 'Commissioning,Quality',
      rootStore: this.rootStore,
      parent: this
    });

    this.insightsPositiveNegativeObservations = new InsightsPositiveNegativeObservations(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'QUALITY'
      }
    );

    this.insightsObservationsRaisedByType = new InsightsObservationsRaisedByType(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'QUALITY'
      }
    );

    this.insightsObservationsRaisedByTeamMember = new InsightsObservationsRaisedByTeamMember(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'QUALITY'
      }
    );

    this.insightsIssuesObservedHoursWorked = new InsightsIssuesObservedHoursWorked(
      null,
      {
        parent: this,
        rootStore: this.rootStore,
        typeClasses: 'QUALITY'
      }
    );

    this.modelsSet = true;
  }

  @action.bound tearDownModels() {
    // Checklists
    this.checklistSummary = null;
    this.checklistCompletion = null;
    this.checklistCompletionByWorkUnit = null;
    this.checklistFailedResponses = null;

    // Observations
    this.insightsPositiveNegativeObservations = null;
    this.insightsObservationsRaisedByType = null;
    this.insightsObservationsRaisedByTeamMember = null;
    this.insightsIssuesObservedHoursWorked = null;

    this.modelsSet = false;
  }

  @action.bound setupDisplayFilters() {
    if (localStorage.getItem('insightsQualityControlDisplayFilters')) {
      const displayFilters = JSON.parse(
        localStorage.getItem('insightsQualityControlDisplayFilters')
      );

      this.displayFilters.replace(displayFilters);
    }
  }

  @computed get displayFiltersAsJSON() {
    return JSON.stringify(this.displayFilters.toJSON());
  }

  @action.bound showDisplayFiltersModal() {
    this.showModal('InsightsDisplayFiltersModal');

    this.displayFiltersForm = new InsightsQualityControlDisplayFiltersForm(
      {
        fields: insightsQualityControlDisplayFiltersFormFields,
        rules: insightsQualityControlDisplayFiltersFormRules,
        labels: insightsQualityControlDisplayFiltersFormLabels,
        values: this.displayFilters.toJSON()
      },
      {
        options: insightsQualityControlDisplayFiltersFormOptions,
        plugins: insightsQualityControlDisplayFiltersFormPlugins
      }
    );
  }

  @action.bound async submitDisplayFiltersForm(event) {
    event.preventDefault();

    const values = this.displayFiltersForm.values();

    this.displayFilters.replace(values);

    localStorage.setItem(
      'insightsQualityControlDisplayFilters',
      this.displayFiltersAsJSON
    );

    await this.hideDisplayFiltersModal();

    this.displayFiltersForm = null;
  }

  @action.bound async hideDisplayFiltersModal() {
    await this.hideActiveModal();
    this.displayFiltersForm = null;
  }

  @action.bound async clearDisplayFilters() {
    await this.hideDisplayFiltersModal();

    this.displayFilters.replace({
      checklistSummary: true,
      checklistCompletion: true,
      checklistCompletionByWorkUnit: true,
      checklistFailedResponses: true,
      insightsPositiveNegativeObservations: true,
      insightsObservationsRaisedByType: true,
      insightsObservationsRaisedByTeamMember: true,
      insightsIssuesObservedHoursWorked: true
    });

    localStorage.setItem(
      'insightsQualityControlDisplayFilters',
      this.displayFiltersAsJSON
    );
  }

  @computed get appliedDisplayFiltersCount() {
    let filterCount = 0;
    const filters = this.displayFilters.toJSON();

    Object.keys(filters).forEach(key => {
      if (filters[key] === false) {
        filterCount++;
      }
    });

    return filterCount;
  }

  @computed get hasAppliedDisplayFilters() {
    return this.appliedDisplayFiltersCount > 0;
  }

  @computed get hasAllDisplayFiltersApplied() {
    return this.appliedDisplayFiltersCount === 8;
  }
}

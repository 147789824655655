import MobxReactForm from 'mobx-react-form';
import extendedPlugins from '../utils/extendedPlugins';
import { t } from 'utils/translate';

const equipmentConditionFormPlugins = extendedPlugins;

const equipmentConditionFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const equipmentConditionFormFields = ['condition', 'endDate'];

const equipmentConditionFormLabels = {
  condition: t('condition'),
  endDate: t('end date')
};

const equipmentConditionFormRules = {
  endDate: 'dateCustom:YYYY-MM-DD'
};

const equipmentConditionFormValues = {};

class EquipmentConditionForm extends MobxReactForm {}

export {
  EquipmentConditionForm,
  equipmentConditionFormOptions,
  equipmentConditionFormFields,
  equipmentConditionFormRules,
  equipmentConditionFormLabels,
  equipmentConditionFormPlugins,
  equipmentConditionFormValues
};

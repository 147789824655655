import debounce from 'lodash.debounce';
import request from 'axios';
import moment from 'moment';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import ProjectChildEditUI from './ProjectChildEditUI';
import WorkLogs from 'stores/collections/workLogs/WorkLogs';
import CalendarUI from './CalendarUI';

import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';

import { callTrack } from 'utils/segmentIntegration';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { WORKLOG_COPY } from 'utils/segmentAnalytics/eventSpec';

export default class WorkLogsCopyUI extends ProjectChildEditUI {
  @observable loading;

  @observable selectedDate;
  @observable selectedSegmentUuid;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedWorkLogs = observable([]);

    this.selectedDate = null;
    this.selectedSegmentUuid = null;

    this.workLogs = new WorkLogs(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.calendarUI = new CalendarUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchWorkLogsDebounced = debounce(this.fetchWorkLogs, BASE_DEBOUNCE);
  }

  @computed get date() {
    return this.selectedDate;
  }

  @computed get segmentUuid() {
    return this.selectedSegmentUuid;
  }

  @computed get timeCardsMode() {
    return this.parent.timeCardsMode;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.workLogs.hasModels;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get copyText() {
    if (this.showUI && !this.loading) {
      const availableToCopy = this.workLogs.length;
      const plural = Boolean(this.workLogs.length > 1);

      return t('{selected} of {available} {type} selected.', {
        templateStrings: {
          selected: this.selectedWorkLogs.length,
          available: availableToCopy,
          type: `${this.timeCardsMode ? 'time card' : 'work log'}${
            plural ? 's' : ''
          }`
        }
      });
    }

    return '';
  }

  @action.bound async showCopyModal() {
    await this.authorization.checkFeatureAccess('RolloverWorklogs');

    this.setup();

    this.showModal('WorkLogsCopyModal');
  }

  @action.bound setup() {
    this.navigateToDate(
      moment(this.projectUI.date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    );

    this.setDefaultSegmentUuid();
    this.setupReactions();
    this.fetchWorkLogs();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.selectedWorkLogs.clear();
          this.fetchWorkLogsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound async cancelCopyModal() {
    await this.hideActiveModal();
    this.tearDown();
  }

  @computed get maxDate() {
    return moment(this.parent.date).subtract(1, 'day');
  }

  @action.bound setDefaultSegmentUuid() {
    if (!this.hasActiveSegments) return null;

    if (
      this.activeSegments.find(
        segment => segment.uuid === this.projectUI.segmentUuid
      )
    ) {
      this.selectedSegmentUuid = this.projectUI.segmentUuid;
    } else {
      this.selectedSegmentUuid = this.activeSegments[0].uuid;
    }
  }

  @action.bound navigateToSegment(segmentUuid) {
    this.selectedSegmentUuid = segmentUuid;
  }

  @action.bound setSelectedDate(date) {
    this.selectedDate = date;
  }

  @action.bound navigateToDate(date) {
    this.setSelectedDate(date);
  }

  @action.bound navigateToPreviousDay() {
    const date = moment(this.selectedDate).subtract(1, 'day');

    this.navigateToDate(date);
  }

  @action.bound navigateToNextDay() {
    const date = moment(this.selectedDate).add(1, 'day');

    this.navigateToDate(date);
  }

  @computed get disableNavigateToNextDay() {
    return moment(this.selectedDate).isSameOrAfter(this.maxDate, 'd');
  }

  @computed
  get activeSegments() {
    return this.project.getActiveSegmentsForDate(this.selectedDate);
  }

  @computed
  get hasActiveSegments() {
    return this.activeSegments?.length > 0;
  }

  @computed get segmentOptions() {
    return this.activeSegments?.map(segment => {
      return {
        value: segment.uuid,
        name: segment.name
      };
    });
  }

  @computed get selectedSegmentOption() {
    return this.segmentOptions.find(
      option => option.value === this.segmentUuid
    );
  }

  @computed get params() {
    return {
      offset: 0,
      limit: 10000,
      workLogTypes: this.parent.workLogTypes,
      sortDirection: 'desc',
      sortField: 'date',
      projectUuids: this.projectUuid,
      fromDate: moment(this.selectedDate).format('YYYY-MM-DD'),
      toDate: moment(this.selectedDate).format('YYYY-MM-DD'),
      segmentUuids: this.selectedSegmentUuid,
      filterTimeClockOrKioskActivity: true
    };
  }

  @computed get includeHours() {
    return this.settings.includeHours;
  }

  @action.bound async fetchWorkLogs() {
    this.loading = true;

    await this.workLogs.fetch({
      params: this.params
    });

    this.loading = false;
  }

  @action.bound clearUIState() {
    this.workLogs.clear();
    this.selectedWorkLogs.clear();
    this.selectedDate = null;
    this.selectedSegmentUuid = null;
    this.loading = false;
  }

  @action.bound toggleIncludeHours() {
    this.settings.save({ includeHours: !this.includeHours });
  }

  @computed get hasSelectedWorkLogs() {
    return this.selectedWorkLogs.length > 0;
  }

  @action.bound async toggleSelectWorkLog(workLog) {
    if (workLog.workLogType === 'CREW') {
      await this.authorization.checkFeatureAccess('CreateCrewWorkLog');
    }

    if (this.selectedWorkLogs.includes(workLog)) {
      this.selectedWorkLogs.remove(workLog);
    } else {
      this.selectedWorkLogs.push(workLog);
    }
  }

  @computed get allWorkLogsSelected() {
    return (
      this.workLogs.hasModels &&
      this.selectedWorkLogs.length === this.workLogs.length
    );
  }

  @computed get someWorkLogsSelected() {
    return this.selectedWorkLogs.length > 0 && !this.allWorkLogsSelected;
  }

  @action.bound toggleSelectAllWorklogs() {
    if (this.allWorkLogsSelected || this.someWorkLogsSelected) {
      this.selectedWorkLogs.clear();
    } else {
      this.selectedWorkLogs.replace(this.workLogs.models);
    }
  }

  @action.bound
  async confirmCopy() {
    this.saving = true;

    const url = `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.company.uuid}/worklogs/copy`;

    try {
      await request.post(url, {
        ...this.reportParams,
        workLogUuids: this.selectedWorkLogs.map(workLog => workLog.uuid),
        includeHours: this.includeHours
      });

      callTrack(WORKLOG_COPY, {
        copy_count: this.selectedWorkLogs.length
      });

      await this.cancelCopyModal();

      this.parent.refetchWorkLogs();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: this.timeCardsMode
          ? t('Time cards copied')
          : t('Work logs copied')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }
}

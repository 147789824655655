import { action, computed, observable } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import {
  groupFormFields,
  groupFormOptions,
  groupFormPlugins,
  groupFormRules,
  groupFormLabels,
  GroupForm
} from 'forms/group';

import GroupClasses from 'stores/collections/groups/GroupClasses';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { GROUP_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class GroupInfoUI extends UIStore {
  @observable entryEditForm;

  constructor(options) {
    super(options);

    this.groupClasses = new GroupClasses(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.entryEditForm = null;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound async setup() {
    this.groupClasses.fetch({
      params: {
        limit: 10000
      }
    });

    this.setEntryEditForm();
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new GroupForm(
      {
        fields: groupFormFields,
        rules: groupFormRules,
        labels: groupFormLabels,
        values: this.entryForEdit.formValues
      },
      {
        options: groupFormOptions,
        plugins: groupFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.groupClasses.clear();
    this.activeModal = null;
    this.entryEditForm = null;
    this.saving = false;
  }

  @computed get groupClassOptions() {
    return this.groupClasses.models.map(groupClass => ({
      name: groupClass.name,
      uuid: groupClass.uuid
    }));
  }

  @action.bound
  async submitGroupEditSuccess() {
    this.saving = true;
    const { groupName, groupClass } = this.entryEditForm.trimmedValues();
    try {
      await this.entryForEdit.save(
        {
          groupName,
          groupClass: {
            uuid: groupClass.uuid
          }
        },
        {
          wait: true
        }
      );

      callTrack(GROUP_EDITED, {
        group_class: groupClass.name,
        group_name: groupName
      });

      this.resetEntryEditForm();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Group info saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  submitGroupEdit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.entryEditForm.submit({
      onSuccess: this.submitGroupEditSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  resetEntryEditForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }
}

import { computed, action, observable, reaction, runInAction } from 'mobx';
import debounce from 'lodash.debounce';

import UIStore from 'stores/ui/UIStore';
import alertErrorHandler from 'utils/alertErrorHandler';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import {
  SmallEquipmentHistoryFiltersForm,
  smallEquipmentHistoryFiltersFormOptions,
  smallEquipmentHistoryFiltersFormFields,
  smallEquipmentHistoryFiltersFormRules,
  smallEquipmentHistoryFiltersFormLabels,
  smallEquipmentHistoryFiltersFormPlugins
} from 'forms/equipment/smallEquipment/smallEquipmentHistoryFilters';

import SmallEquipmentHistory from 'stores/collections/equipment/SmallEquipmentHistory';

export default class SmallEquipmentHistoryUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable saving;
  @observable showFiltersModal;
  @observable smallEquipmentHistoryFiltersForm;

  constructor(options) {
    super(options);

    this.loading = true;
    this.saving = false;

    this.sortField = 'projectName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Filters
    this.showFiltersModal = false;
    this.smallEquipmentHistoryFiltersForm = null;
    this.filtersGroups = observable([]);

    // Equipment History collection
    this.smallEquipmentHistory = new SmallEquipmentHistory(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchSmallEquipmentHistoryDebounced = debounce(
      this.fetchSmallEquipmentHistory,
      BASE_DEBOUNCE
    );
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: [this.sortField],
      sortDirection: this.sortDirection,
      projectGroupUuids: this.filtersGroups.map(group => group.uuid).join(',')
    };
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @action.bound async setup() {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');
    await this.fetchSmallEquipmentHistory();

    this.setupReactions();
  }

  @action.bound async tearDown() {
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.fetchSmallEquipmentHistoryDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound async fetchSmallEquipmentHistory() {
    this.loading = true;

    this.smallEquipmentHistory.cancelRequest();
    this.smallEquipmentHistory.reset();

    try {
      await this.smallEquipmentHistory.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasSmallEquipmentHistory() {
    return this.smallEquipmentHistory.hasModels;
  }

  @computed get showEmptySearchState() {
    return !this.loading && !this.hasSmallEquipmentHistory;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.smallEquipmentHistory.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.tearDownReactions();
    this.searchQuery = '';
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'projectName';
    this.sortDirection = 'asc';
    this.showFiltersModal = false;
    this.smallEquipmentHistoryFiltersForm = null;
    this.smallEquipmentHistory.clear();
  }

  // Filters
  @action.bound showFilters() {
    this.groupSelectorUI.setup();

    this.showFiltersModal = true;

    this.smallEquipmentHistoryFiltersForm = new SmallEquipmentHistoryFiltersForm(
      {
        fields: smallEquipmentHistoryFiltersFormFields,
        rules: smallEquipmentHistoryFiltersFormRules,
        labels: smallEquipmentHistoryFiltersFormLabels,
        values: {
          groups: this.filtersGroups ? this.filtersGroups.slice() : []
        }
      },
      {
        options: smallEquipmentHistoryFiltersFormOptions,
        plugins: smallEquipmentHistoryFiltersFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound async cancelHistoryFilters() {
    this.showFiltersModal = false;

    this.groupSelectorUI.tearDown();
  }

  @action.bound clearAllHistoryFilters() {
    this.filtersGroups.clear();

    this.cancelHistoryFilters();
  }

  @action.bound submitHistoryFiltersSmallEquipmentForm(event) {
    event.preventDefault();

    this.smallEquipmentHistoryFiltersForm.submit({
      onSuccess: this.submitHistoryFiltersSmallEquipmentFormSuccess,
      onError: this.submitHistoryFiltersSmallEquipmentFormError
    });
  }

  @action.bound submitHistoryFiltersSmallEquipmentFormSuccess() {
    const values = this.smallEquipmentHistoryFiltersForm.values();

    this.filtersGroups.replace(values.groups);

    this.cancelHistoryFilters();
  }

  @action.bound submitHistoryFiltersSmallEquipmentFormError() {
    console.error(this.smallEquipmentHistoryFiltersForm.errors());
  }

  @computed
  get appliedFiltersCount() {
    let counter = 0;

    if (this.filtersGroups.length) {
      counter += 1;
    }

    return counter;
  }

  @computed get hasAppliedFilters() {
    return !!this.appliedFiltersCount;
  }
}

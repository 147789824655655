import { computed } from 'mobx';

import { Model } from 'mobx-mc';

import moment from 'moment';

export default class EquipmentPerformanceUtilization extends Model {
  constructor(attributes, options) {
    super(attributes, options);
  }

  get restAttributes() {
    return ['collection'];
  }

  @computed get utilizationData() {
    return this.collection.map(data => {
      return [
        moment(data[0]).valueOf(),
        Number(data[1]) - (Number(data[2]) + Number(data[3])),
        data[2],
        data[3]
      ];
    });
  }

  @computed get availableData() {
    return this.utilizationData.map(data => {
      return [data[0], data[1]];
    });
  }

  @computed get inUseData() {
    return this.utilizationData.map(data => [data[0], data[2]]);
  }

  @computed get inIdleData() {
    return this.utilizationData.map(data => [data[0], data[3]]);
  }
}

import { action, computed, toJS } from 'mobx';
import SettingsChildUI from './SettingsChildUI';
import omit from 'lodash.omit';
import { callTrack } from 'utils/segmentIntegration';
import alertErrorHandler from 'utils/alertErrorHandler';
import { t } from 'utils/translate';
import {
  PROJECT_UPDATED,
  WATERMARKS_ENABLED
} from 'utils/segmentAnalytics/eventSpec';
import {
  SettingsWatermarkForm,
  settingsWatermarkFormOptions,
  settingsWatermarkFormFields,
  settingsWatermarkFormRules,
  settingsWatermarkFormValues,
  settingsWatermarkFormLabels,
  settingsWatermarkFormPlugins,
  settingsWatermarkFormDisabled
} from 'forms/project/settingsWatermark';

export default class SettingsWatermarkUI extends SettingsChildUI {
  @computed
  get watermarkAlignments() {
    return [
      {
        id: 'TOP_LEFT',
        name: t('Top Left')
      },
      {
        id: 'TOP_RIGHT',
        name: t('Top Right')
      },
      {
        id: 'BOTTOM_LEFT',
        name: t('Bottom Left')
      },
      {
        id: 'BOTTOM_RIGHT',
        name: t('Bottom Right')
      }
    ];
  }

  @computed get selectedWatermarkAlignment() {
    return this.watermarkAlignments.find(
      option => option.id === this.form.$('watermarkAlignment').value
    );
  }

  @action.bound setup() {
    this.currentFormInitialValues = Object.assign(
      {},
      settingsWatermarkFormValues,
      this.project.settingsFormValues
    );

    this.form = new SettingsWatermarkForm(
      {
        fields: settingsWatermarkFormFields,
        rules: settingsWatermarkFormRules,
        disabled: settingsWatermarkFormDisabled,
        values: toJS(this.currentFormInitialValues),
        labels: settingsWatermarkFormLabels
      },
      {
        options: settingsWatermarkFormOptions,
        plugins: settingsWatermarkFormPlugins,
        parent: this
      }
    );

    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
  }

  @action.bound
  async submitFormSuccess() {
    this.saving = true;
    const values = this.form.trimmedValues();
    const ommitedValues = ['default', 'questions', 'collaboratorQuestions'];
    const projectData = omit(values, ommitedValues);
    try {
      await this.project.save(projectData, {
        wait: true
      });
      this.handleSaveSuccess();
    } catch (error) {
      this.handleSaveError(error);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  handleSaveSuccess() {
    this.unblockHistory();
    this.saving = false;

    this.notifications.pushNotification({
      snackbar: 'warning',
      icon: 'checkmark',
      title: t('Watermark settings saved')
    });

    callTrack(PROJECT_UPDATED, {
      project_id: this.project?.id,
      project_name: this.project?.name
    });

    this.navigationUI.projectSelectorUI.resetProjectOptions();
    // Reinitialize form
    this.setup();
  }

  @action.bound
  handleSaveError(error) {
    alertErrorHandler(error, this.setValidationDetails);
    this.saving = false;
  }

  @computed get enableWatermark() {
    return !this.form.$('enableWatermark').value;
  }

  @action.bound
  toggleWaterMark(value) {
    if (value) {
      callTrack(WATERMARKS_ENABLED);
    }
    this.form.$('enableWatermark').set(value);
  }
}

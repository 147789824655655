import request from 'axios';
import moment from 'moment';
import { computed, action, observable } from 'mobx';

import UIStore from 'stores/ui/UIStore';

import {
  EquipmentDeploymentForm,
  equipmentDeploymentFormOptions,
  equipmentDeploymentFormFields,
  equipmentDeploymentFormRules,
  equipmentDeploymentFormLabels,
  equipmentDeploymentFormPlugins,
  equipmentDeploymentFormValues
} from 'forms/equipment/equipmentDeployment';

import {
  EquipmentConditionForm,
  equipmentConditionFormOptions,
  equipmentConditionFormFields,
  equipmentConditionFormRules,
  equipmentConditionFormLabels,
  equipmentConditionFormPlugins,
  equipmentConditionFormValues
} from 'forms/equipment/equipmentCondition';

import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';
import overrideValidationMessages from 'forms/utils/overrideValidationMessages';

export default class EquipmentActionsUI extends UIStore {
  @observable equipmentDeploymentForm;
  @observable equipmentConditionForm;
  @observable equipmentToUpdate;
  @observable selectedEquipmentAction;

  constructor(options) {
    super(options);

    this.equipmentDeploymentForm = null;
    this.equipmentConditionForm = null;

    // Individual Actions
    this.equipmentToUpdate = null;
    this.selectedEquipmentAction = null;

    // Bulk Actions
    this.selectedEquipment = observable([], { deep: false });
  }

  @computed get equipment() {
    return this.parent.equipment;
  }

  @action.bound submitFormError() {
    console.log(this.form.errors());
  }

  @action.bound clearUIState() {
    this.clearValidationDetails();
    this.saving = false;
    this.selectedEquipment.clear();
    this.equipmentToUpdate = null;
    this.selectedEquipmentAction = null;
    this.equipmentDeploymentForm = null;
    this.equipmentConditionForm = null;
  }

  @computed get hasSelectedEquipment() {
    return this.selectedEquipment.length > 0;
  }

  @computed get hasNoSelectedEquipment() {
    return this.selectedEquipment.length === 0;
  }

  @computed get allEquipmentSelected() {
    if (!this.hasSelectedEquipment) return false;

    return (
      this.equipment.models.filter(equipment => {
        return this.findSelectedEquipment(equipment);
      }).length === this.equipment.length
    );
  }

  findSelectedEquipment = equipment => {
    return this.selectedEquipment.find(
      selectedEquipment => selectedEquipment.uuid === equipment.uuid
    );
  };

  @computed get conditionOptions() {
    return this.parent.conditionOptions.filter(
      option => option.value !== 'DEPLOYED'
    );
  }

  @computed get selectedConditionOption() {
    return this.conditionOptions.find(
      option =>
        option.value === this.equipmentConditionForm.$('condition').value
    );
  }

  @action.bound updateCondition(event, option) {
    this.equipmentConditionForm.$('condition').set(option.value);
  }

  @computed get hideConditionEndDateField() {
    return (
      this.equipmentConditionForm?.$('condition').value !== 'UNDER_MAINTENANCE'
    );
  }

  @action.bound toggleSelectAllEquipment() {
    if (this.allEquipmentSelected) {
      this.equipment.models.forEach(equipment => {
        this.selectedEquipment.remove(this.findSelectedEquipment(equipment));
      });
    } else {
      this.equipment.models.forEach(equipment => {
        if (!this.findSelectedEquipment(equipment)) {
          this.selectedEquipment.push(equipment);
        }
      });
    }
  }

  @action.bound toggleSelectEquipment(equipment) {
    const selectedEquipment = this.findSelectedEquipment(equipment);

    if (selectedEquipment) {
      this.selectedEquipment.remove(selectedEquipment);
    } else {
      this.selectedEquipment.push(equipment);
    }
  }

  @action.bound
  async deployEquipment(equipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    if (equipment) {
      this.equipmentToUpdate = equipment;
    }

    this.equipmentDeploymentForm = new EquipmentDeploymentForm(
      {
        fields: equipmentDeploymentFormFields,
        rules: equipmentDeploymentFormRules,
        labels: equipmentDeploymentFormLabels,
        values: equipmentDeploymentFormValues
      },
      {
        options: equipmentDeploymentFormOptions,
        plugins: equipmentDeploymentFormPlugins,
        rootStore: this.rootStore
      }
    );

    this.showModal('EquipmentDeploymentModal');
  }

  @action.bound async cancelDeployEquipment() {
    await this.hideActiveModal();

    this.projectSelectorUI.tearDown();
    this.equipmentToUpdate = null;
    this.equipmentDeploymentForm = null;
  }

  @action.bound submitEquipmentDeploymentForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.equipmentDeploymentForm.submit({
      onSuccess: this.submitEquipmentDeploymentFormSuccess,
      onError: this.submitEquipmentDeploymentFormError
    });
  }

  @action.bound async submitEquipmentDeploymentFormSuccess() {
    this.saving = true;

    const values = this.equipmentDeploymentForm.values();

    try {
      if (this.equipmentToUpdate) {
        await this.equipmentToUpdate.save(
          {
            condition: 'DEPLOYED',
            deployment: {
              projectUuid: values.projectUuid,
              startDate: values.startDate,
              endDate: values.endDate
            }
          },
          {
            wait: true
          }
        );
      } else {
        await request.post(
          `${this.equipment.url()}/deployments/batch`,
          this.selectedEquipment.map(selectedEquipment => {
            return {
              projectUuid: values.projectUuid,
              startDate: values.startDate,
              endDate: values.endDate,
              equipment: {
                uuid: selectedEquipment.uuid
              }
            };
          })
        );
        this.selectedEquipment.clear();
      }

      this.parent.refetchEquipment();

      this.cancelDeployEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Equipment deployed.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentDeploymentFormError() {
    console.error(this.equipmentDeploymentForm.errors());
  }

  @action.bound
  async updateEquipmentCondition(equipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    if (equipment) {
      this.equipmentToUpdate = equipment;
    }

    this.equipmentConditionForm = new EquipmentConditionForm(
      {
        fields: equipmentConditionFormFields,
        rules: equipmentConditionFormRules,
        labels: equipmentConditionFormLabels,
        values: equipmentConditionFormValues
      },
      {
        options: equipmentConditionFormOptions,
        plugins: overrideValidationMessages(equipmentConditionFormPlugins, {
          required_if: t(
            'An end date is required when scheduling equipment for maintenance.'
          )
        }),
        rootStore: this.rootStore
      }
    );

    this.showModal('EquipmentConditionModal');
  }

  @action.bound async cancelUpdateEquipmentCondition() {
    await this.hideActiveModal();

    this.equipmentToUpdate = null;
    this.equipmentConditionForm = null;
  }

  @action.bound submitEquipmentConditionForm(event) {
    event.preventDefault();

    if (this.saving) return false;

    this.equipmentConditionForm.submit({
      onSuccess: this.submitEquipmentConditionFormSuccess,
      onError: this.submitEquipmentConditionFormError
    });
  }

  @action.bound async submitEquipmentConditionFormSuccess() {
    this.saving = true;

    const values = this.equipmentConditionForm.values();

    try {
      if (this.equipmentToUpdate) {
        if (values.condition === 'UNDER_MAINTENANCE') {
          await request.post(`${this.equipmentToUpdate.url()}/maintenance`, {
            endDate: values.endDate
              ? moment(values.endDate).format('YYYY-MM-DD')
              : ``
          });
        } else {
          await this.equipmentToUpdate.save(
            {
              condition: values.condition
            },
            {
              wait: true
            }
          );
        }
      } else {
        if (values.condition === 'UNDER_MAINTENANCE') {
          await request.post(
            `${this.equipment.url()}/maintenance/batch`,
            this.selectedEquipment.map(selectedEquipment => {
              return {
                equipmentUuid: selectedEquipment.uuid,
                endDate: values.endDate
                  ? moment(values.endDate).format('YYYY-MM-DD')
                  : ``
              };
            })
          );
        } else {
          await request.patch(
            `${this.equipment.url()}/batch`,
            this.selectedEquipment.map(selectedEquipment => {
              return {
                equipmentUuid: selectedEquipment.uuid,
                condition: values.condition
              };
            })
          );
        }
      }

      this.parent.refetchEquipment();
      this.selectedEquipment.clear();

      this.cancelUpdateEquipmentCondition();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Equipment condition updated.')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound submitEquipmentConditionFormError() {
    console.error(this.equipmentConditionForm.errors());
  }

  @action.bound async deleteEquipment(equipment) {
    await this.authorization.checkFeatureAccess('CRUDOwnedEquipment');

    if (equipment) {
      this.equipmentToUpdate = equipment;
    }

    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteEquipment() {
    await this.hideActiveModal();

    this.equipmentToUpdate = null;
  }

  @action.bound async confirmDeleteEquipment() {
    this.saving = true;

    try {
      if (this.equipmentToUpdate) {
        await this.equipmentToUpdate.destroy({ wait: true });
      } else {
        await request.delete(`${this.equipment.url()}/batch`, {
          data: this.selectedEquipment.map(
            selectedEquipment => selectedEquipment.uuid
          )
        });

        this.selectedEquipment.clear();
      }

      this.parent.refetchEquipment();

      this.cancelDeleteEquipment();

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Equipment deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  getEquipmentActions = equipment => {
    return [
      {
        title: t('View'),
        onClick: () => {
          this.parent.editEquipment(equipment);
        }
      },
      {
        title: t('Deploy'),
        onClick: () => {
          this.deployEquipment(equipment);
        }
      },
      {
        title: t('Update condition'),
        onClick: () => {
          this.updateEquipmentCondition(equipment);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteEquipment(equipment);
        }
      }
    ];
  };

  // Bulk Actions
  @computed get bulkEquipmentActions() {
    let actions = [];

    if (this.hasSelectedEquipment) {
      actions = actions.concat([
        {
          title: t('Deploy'),
          onClick: () => {
            this.bulkEquipmentAction('DEPLOY');
          }
        },
        {
          title: t('Update condition'),
          onClick: () => {
            this.bulkEquipmentAction('UPDATE_CONDITION');
          }
        },
        {
          title: t('Notifications'),
          onClick: () => {
            this.bulkEquipmentAction('NOTIFICATIONS');
          }
        },

        {
          title: t('Delete'),
          onClick: () => {
            this.bulkEquipmentAction('DELETE');
          }
        }
      ]);
    }

    return actions;
  }

  @action.bound async bulkEquipmentAction(action) {
    switch (action) {
      case 'DEPLOY':
        this.deployEquipment();
        break;
      case 'UPDATE_CONDITION':
        this.updateEquipmentCondition();
        break;
      case 'NOTIFICATIONS':
        this.openNotificationsModal();
        break;
      default:
        this.deleteEquipment();
        break;
    }
  }

  @action.bound openNotificationsModal() {
    this.parent.equipmentNotificationsUI.showEquipmentNotificationsModal();
  }
}

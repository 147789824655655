import { computed } from 'mobx';
import { RakenCollection } from 'stores/lib';
import ComputerEaseDirectAPIJobMatch from 'stores/models/integrations/ComputerEaseDirectAPIJobMatch';

export default class ComputerEaseDirectAPIEmployeeMatches extends RakenCollection {
  url() {
    return `${this.rootStore.urlMicroService('integrations')}/companies/${
      this.company.uuid
    }/integrations/1030/job/matches`;
  }

  model() {
    return ComputerEaseDirectAPIJobMatch;
  }

  parse(data) {
    return data.matches;
  }

  @computed get company() {
    if (this.rootStore.isSuperAdmin) {
      return this.parent.activeCompany;
    }

    return this.rootStore.me.company;
  }
}

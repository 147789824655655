import publicPath from './publicPath';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import history from './utils/history';

import { ThemeProvider } from 'styled-components';
import { Router, Route } from 'react-router-dom';
import theme from './themes/main';
import GlobalStyles from './global-styles';
import ErrorBoundary from './components/ErrorBoundary';

import { RootStoreContext, store } from 'contexts/storeContext';

if (process.env.NODE_ENV === 'production') {
  publicPath();
}

if (document.getElementById('root')) {
  ReactDOM.render(
    <BrowserRouter>
      <RootStoreContext.Provider value={store}>
        <ThemeProvider theme={theme}>
          <>
            <ErrorBoundary store={store}>
              <Router history={history}>
                <Route component={App} />
              </Router>
            </ErrorBoundary>
            <GlobalStyles />
          </>
        </ThemeProvider>
      </RootStoreContext.Provider>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

import { observable, action, computed } from 'mobx';
import UIStore from '../../UIStore';

import EquipmentPerformanceProductivity from 'stores/models/EquipmentPerformanceProductivity';
import EquipmentPerformanceFuelConsumption from 'stores/models/EquipmentPerformanceFuelConsumption';
import EquipmentPerformanceUtilization from 'stores/models/EquipmentPerformanceUtilization';

export default class EquipmentPerformanceUI extends UIStore {
  @observable loading;
  @observable timeFrameOption;
  @observable daysInWeekOption;

  constructor(options) {
    super(options);

    this.timeFrameOption = { title: 'This month', value: 'THIS_MONTH' };

    // Retrieve the daysInWeekOption from localStorage
    const storedOption = localStorage.getItem('daysInWeekOption');

    storedOption !== null
      ? (this.daysInWeekOption = JSON.parse(storedOption))
      : (this.daysInWeekOption = { title: '7 day work week', value: 'DAYS_7' });

    this.loading = true;

    this.equipmentPerformanceProductivity = new EquipmentPerformanceProductivity(
      { parent: this, rootStore: this.rootStore }
    );

    this.equipmentPerformanceFuelConsumption = new EquipmentPerformanceFuelConsumption(
      { parent: this, rootStore: this.rootStore }
    );

    this.equipmentPerformanceUtilization = new EquipmentPerformanceUtilization({
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup() {
    this.fetchModels();
  }

  @action.bound
  async fetchModels() {
    this.loading = true;

    await Promise.all([
      this.equipmentPerformanceProductivity.fetch({
        url: `${this.entryForEdit.url()}/performance/productivity`,
        params: {
          timePeriod: this.timeFrameOption.value
        }
      }),
      this.equipmentPerformanceFuelConsumption.fetch({
        url: `${this.entryForEdit.url()}/performance/fuel`,
        params: {
          timePeriod: this.timeFrameOption.value
        }
      }),
      this.equipmentPerformanceUtilization.fetch({
        url: `${this.entryForEdit.url()}/performance/utilization`,
        params: {
          timePeriod: this.timeFrameOption.value,
          daysInWorkWeek: this.daysInWeekOption.value
        }
      })
    ]);

    this.loading = false;
  }

  @computed get timeFrameOptions() {
    return [
      { title: 'Last 7 days', value: 'LAST_7' },
      { title: 'Last 14 days', value: 'LAST_14' },
      { title: 'Last 4 weeks', value: 'LAST_4_WEEKS' },
      { title: 'This month', value: 'THIS_MONTH' },
      { title: 'Last month', value: 'LAST_MONTH' },
      { title: 'Last 3 months', value: 'LAST_3_MONTHS' }
    ];
  }

  @action.bound updateTimeFrameOption(option) {
    this.timeFrameOption = option;

    this.fetchModels();
  }

  @computed get daysInWorkWeekOptions() {
    return [
      { title: '5 day work week', value: 'DAYS_5' },
      { title: '6 day work week', value: 'DAYS_6' },
      { title: '7 day work week', value: 'DAYS_7' }
    ];
  }

  @action.bound updateDaysInWeekOption(option) {
    this.daysInWeekOption = option;

    // Persist this option
    localStorage.setItem('daysInWeekOption', JSON.stringify(option));

    this.fetchModels();
  }
}

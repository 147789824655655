import UIStore from './UIStore';
import Members from 'stores/collections/Users';
import { action, observable, computed, reaction } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class MemberSelectorUI extends UIStore {
  @observable searchQuery;
  @observable loading;
  @observable invitePending;

  constructor(options) {
    super(options);

    this.loading = false;

    this.searchQuery = '';
    this.invitePending = true;
    this.fetchMembersDebounced = debounce(this.fetchMembers, BASE_DEBOUNCE);

    this.projectUuids = observable([]);
    this.role = observable([]);

    this.paginatedMembers = new Members(null, {
      rootStore: this
    });

    this.companySettingsTrackMemberTime = false;

    this.sortField = false;
    this.elevateFn = null;
  }

  @computed get members() {
    return this.paginatedMembers;
  }

  @action.bound
  async setup(options = {}) {
    // Override the projects uuids for custom filtering
    // otherwise default to the selected project in ProjectUI || ProjectProfileUI
    if (options.projectUuids?.length) {
      this.projectUuids.replace(options.projectUuids);
    } else {
      this.projectUuids.replace([this.project?.uuid]);
    }

    if (options.invitePending) {
      this.invitePending = options.invitePending;
    } else {
      this.invitePending = false;
    }

    // Override the Roles for custom filtering
    // otherwise default to all roles
    if (options.role?.length) {
      this.role.replace(options.role);
    } else {
      this.role.replace([
        'ROLE_ACCOUNT_ADMIN',
        'ROLE_ADMIN',
        'ROLE_PROJECT_MEMBER',
        'ROLE_USER',
        'ROLE_WORKER'
      ]);
    }

    if (options.sortField) {
      this.sortField = options.sortField;
    } else {
      this.sortField = 'firstName,lastName';
    }

    if (options.elevateFn) {
      this.elevateFn = options.elevateFn;
    }

    if (options.companySettingsTrackMemberTime) {
      this.companySettingsTrackMemberTime = true;
    }

    await this.fetchMembers();
    this.setupReactions();
  }

  @action.bound
  tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  @action.bound setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchMembersDebounced();
      }
    );
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.projectUuids.clear();
    this.role.clear();
    this.members.reset();
    this.loading = false;
    this.searchQuery = '';
    this.invitePending = true;
    this.companySettingsTrackMemberTime = false;
  }

  @computed get options() {
    const options = this.members.models.map(member => {
      return {
        uuid: member.uuid,
        name: member.fullName,
        workerUuid: member.workerUuid,
        company: {
          uuid: member.company.uuid,
          name: member.company.name
        },
        email: member.email,
        phoneNumber: member.phoneNumber
      };
    });

    if (this.elevateFn) {
      return options.sort((a, b) => {
        if (this.elevateFn(a)) return -1;
        if (this.elevateFn(b)) return 1;
        return 0;
      });
    }

    return options;
  }

  @computed get noOptionsText() {
    return this.loading
      ? t('Searching')
      : !this.members.hasModels
      ? t('No results')
      : false;
  }

  @action.bound
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  @action.bound
  clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get hasMoreMembers() {
    return this.members.totalElements > this.members.length;
  }

  @computed get params() {
    let loadAllTeamMembers;
    let loadOnlyWorkers;

    const params = {
      query: this.searchQuery,
      projectUuids: this.projectUuids.join(','),
      limit: 80,
      sortField: this.sortField,
      sortDirection: 'asc',
      role: this.role.join(','),
      invitePending: this.invitePending?.toString()
    };

    if (this.companySettingsTrackMemberTime) {
      if (this.company?.preferences?.trackMemberTime) {
        loadAllTeamMembers = Boolean(
          this.company.preferences.trackMemberTime ===
            'assignedWorkersAndAllTeamMembers'
        );

        loadOnlyWorkers = Boolean(
          this.company.preferences.trackMemberTime === 'assignedWorkersOnly'
        );
      } else {
        loadAllTeamMembers = true;
        loadOnlyWorkers = false;
      }

      if (loadOnlyWorkers) {
        params.role = 'ROLE_WORKER';
      }

      params.loadAllTeamMembersForTimecards = loadAllTeamMembers;
    }

    return params;
  }

  @action.bound
  async fetchMembers(options) {
    this.loading = true;

    try {
      await this.members.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }

  fetchNextPage = async (e, autoCompleteRef) => {
    const menu = e.target;

    const scrollTop = menu.scrollTop;
    const scrollHeight = menu.scrollHeight;
    const clientHeight = menu.clientHeight;

    if (scrollTop + clientHeight === scrollHeight) {
      this.fetchNextPageOfMembers().then(() => {
        autoCompleteRef.current.scrollTop = scrollHeight;
      });
    }
  };

  @action.bound async fetchNextPageOfMembers() {
    if (this.members.fetching || !this.hasMoreMembers) return;

    try {
      this.members.fetch({
        params: Object.assign(pickBy(this.params, identity), {
          offset: this.members.length
        }),
        add: true,
        remove: false,
        update: false
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }
}

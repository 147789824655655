import MobxReactForm from 'mobx-react-form';

import { t } from 'utils/translate';

import extendedPlugins from 'forms/utils/extendedPlugins';

const computerEaseDirectAPIConnectFormPlugins = extendedPlugins;

const computerEaseDirectAPIConnectFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const computerEaseDirectAPIConnectFormFields = ['refreshToken'];

const computerEaseDirectAPIConnectFormRules = {
  refreshToken: 'string|required'
};

const computerEaseDirectAPIConnectFormLabels = {
  refreshToken: t('refresh token')
};

const computerEaseDirectAPIConnectFormValues = {};

class ComputerEaseDirectAPIConnectForm extends MobxReactForm {}

export {
  ComputerEaseDirectAPIConnectForm,
  computerEaseDirectAPIConnectFormOptions,
  computerEaseDirectAPIConnectFormFields,
  computerEaseDirectAPIConnectFormRules,
  computerEaseDirectAPIConnectFormLabels,
  computerEaseDirectAPIConnectFormValues,
  computerEaseDirectAPIConnectFormPlugins
};

import UIStore from './UIStore';
import Groups from 'stores/collections/groups/Groups';
import { action, observable, computed } from 'mobx';
import errorHandler from 'utils/errorHandler';

import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';

export default class GroupSelectorUI extends UIStore {
  @observable loading;
  @observable invitePending;

  constructor(options) {
    super(options);

    this.loading = false;

    this.fetchGroups = debounce(this.fetchGroups, BASE_DEBOUNCE);

    this.paginatedGroups = new Groups(null, {
      rootStore: this
    });
  }

  @computed get groups() {
    return this.paginatedGroups;
  }

  @action.bound
  async setup() {
    await this.fetchGroups();
  }

  @action.bound
  tearDown() {
    this.clearUIState();
  }

  @action.bound tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound clearUIState() {
    this.groups.reset();
    this.loading = false;
  }

  @computed get options() {
    const options = this.groups.models.map(group => {
      return {
        uuid: group.uuid,
        name: group.groupName,
        groupClass: group.groupClass
      };
    });

    return options;
  }

  filterOptions = inputValue => {
    return this.options.filter(option => {
      return (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option?.groupClass?.name
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );
    });
  };

  @computed get crewOptions() {
    return this.options.filter(option => option.groupClass.name === 'Crews');
  }

  @computed get noOptionsText() {
    return t('No results');
  }

  @computed get params() {
    const params = {
      limit: 10000,
      sortField: this.sortField,
      sortDirection: 'asc',
      status: ['ACTIVE']
    };

    return params;
  }

  @action.bound
  async fetchGroups(options) {
    this.loading = true;

    try {
      await this.groups.fetch({
        params: pickBy(this.params, identity),
        ...options
      });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    } finally {
      this.loading = false;
    }
  }
}

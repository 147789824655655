import kebabCase from 'lodash.kebabcase';
import { computed } from 'mobx';
import { Model } from 'mobx-mc';
import moment from 'moment-timezone';

export default class IncidentReportSubmission extends Model {
  get restAttributes() {
    return [
      'uuid',
      'submissionId',
      'submissionDate',
      'submittedByName',
      'establishmentName',
      'submissionYear',
      'data',
      'linkToSubmission'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @computed
  get formValues() {
    return {
      uuid: this.uuid,
      submissionId: this.submissionId,
      submissionDate: this.submissionDate,
      submittedByName: this.submittedByName,
      establishmentName: this.establishmentName,
      submissionYear: this.submissionYear,
      data: this.data,
      linkToSubmission: this.linkToSubmission
    };
  }

  @computed get locationTimezone() {
    return (
      this.rootStore.companyUI.company?.address?.timezone || moment.tz.guess()
    );
  }

  @computed get submissionDateFormatted() {
    return moment
      .utc(this.submissionDate)
      .tz(this.locationTimezone)
      .format('YYYY-MM-DD');
  }

  @computed get slug() {
    return kebabCase(this.establishmentName);
  }
}

import { action, computed } from 'mobx';
import { RakenCollection } from '../lib';
import CompanyEquipment from '../models/equipment/CompanyEquipment';

export default class EquipmentCollection extends RakenCollection {
  model() {
    return CompanyEquipment;
  }

  url() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/equipment`;
  }

  @action.bound clearNew() {
    this.models.forEach(model => {
      model.clearNew();
    });
  }

  @computed get ownedEquipment() {
    return this.models.filter(model => model.owned);
  }

  @computed get rentedEquipment() {
    return this.models.filter(model => !model.owned);
  }
}

import request from 'axios';
import moment from 'moment';
import { action, runInAction, observable, computed } from 'mobx';
import UIStore from './UIStore';
import pickBy from 'lodash.pickby';

import { t } from 'utils/translate';

import errorHandler from 'utils/errorHandler';
import alertErrorHandler from 'utils/alertErrorHandler';

import SmallEquipmentSnapshotReport from '../models/SmallEquipmentSnapshotReport';

import {
  EquipmentSnapshotReportForm,
  equipmentSnapshotReportFormOptions,
  equipmentSnapshotReportFormFields,
  equipmentSnapshotReportFormRules,
  equipmentSnapshotReportFormValues,
  equipmentSnapshotReportFormLabels,
  equipmentSnapshotReportFormPlugins
} from 'forms/equipmentSnapshotReport';

import { callTrack } from 'utils/segmentIntegration';
import { SMALL_EQUIPMENT_REPORT_DOWNLOADED } from 'utils/segmentAnalytics/eventSpec';

export default class SmallEquipmentSnapshotReportsUI extends UIStore {
  @observable activeReportTab;
  @observable scheduledReportToEdit;

  constructor(options) {
    super(options);

    this.activeReportTab = 'runOnce';
    this.scheduledReportToEdit = null;
  }

  @action.bound showEquipmentSnapshotReportModal() {
    this.rootStore.authorizationUI
      .checkFeatureAccess('ScheduleSmallEquipmentReports', true)
      .then(() => {
        this.showModal('SmallEquipmentSnapshotReportModal');

        this.fetchScheduled();
        this.setupModalForm();
      });
  }

  @action.bound
  fetchScheduled() {
    return this.smallEquipmentSnapshotReports.fetch({
      params: {
        reportType: 'SMALL_EQUIPMENT_SNAPSHOT'
      }
    });
  }

  @computed
  get projectDefaultValue() {
    if (this.scheduledReportToEdit?.projects.length) {
      return this.scheduledReportToEdit.projects.map(project => {
        return {
          value: project.uuid,
          name: project.name
        };
      });
    }

    if (this.project) {
      return [
        {
          value: this.project.uuid,
          name: this.project.name,
          startDate: this.project.startDate,
          endDate: this.project.endDate
        }
      ];
    }

    return [];
  }

  @action.bound
  setupModalForm() {
    this.initReportForm();
  }

  @action.bound
  initReportForm() {
    const rules =
      this.activeReportTab === 'scheduled'
        ? Object.assign({}, equipmentSnapshotReportFormRules, {
            name: 'required|string|max:255',
            time: 'required'
          })
        : equipmentSnapshotReportFormRules;

    const values =
      this.activeReportTab === 'scheduled' && this.scheduledReportToEdit
        ? Object.assign(
            {},
            equipmentSnapshotReportFormValues,
            this.scheduledReportToEdit.formValues
          )
        : equipmentSnapshotReportFormValues;

    this.activeForm = new EquipmentSnapshotReportForm(
      {
        fields: equipmentSnapshotReportFormFields,
        rules: rules,
        values: values,
        labels: equipmentSnapshotReportFormLabels
      },
      {
        options: equipmentSnapshotReportFormOptions,
        plugins: equipmentSnapshotReportFormPlugins,
        reportType: this.activeReportTab
      }
    );
  }

  @action.bound
  hideEquipmentReportModal() {
    this.hideActiveModal().then(() => {
      runInAction(() => {
        this.activeForm = null;
        this.activeReportTab = 'runOnce';
        this.scheduledReportToEdit = null;
        this.saving = false;
      });
    });
  }

  @action.bound
  switchTab(e, tab) {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.activeReportTab = tab;
    this.setupModalForm();
  }

  @computed
  get scheduledReportNewOrSaveButtonText() {
    if (this.saving) return t('Saving...');

    return this.scheduledReportToEdit
      ? this.scheduledReportToEdit?.isNew
        ? t('Schedule report')
        : t('Save changes')
      : t('New report');
  }

  @computed get smallEquipmentSnapshotReports() {
    return this.rootStore.smallEquipmentSnapshotReports;
  }

  @computed
  get hasNoScheduledReports() {
    return (
      !this.fetchingScheduledReports &&
      !this.smallEquipmentSnapshotReports.fetching &&
      !this.smallEquipmentSnapshotReports.hasModels
    );
  }

  @computed
  get weekFirstDay() {
    return moment(new Date()).add(
      (this.company.preferences.weekFirstDay -
        moment(new Date()).isoWeekday() -
        7) %
        7,
      'days'
    );
  }

  @computed
  get weekLastDay() {
    return moment(this.weekFirstDay).add(6, 'days');
  }

  @computed
  get monthFirstDay() {
    return moment().startOf('month');
  }

  @computed get timeFrames() {
    return [
      {
        id: 'DAILY',
        title: t('Daily'),
        projectEntire: true
      },
      {
        id: 'WEEKLY',
        title: t('Weekly'),
        actionDate: this.weekLastDay,
        projectEntire: true
      },
      {
        id: 'BI_WEEKLY',
        title: t('Bi-weekly'),
        actionDate: this.weekLastDay,
        projectEntire: true
      },
      {
        id: 'MONTHLY',
        title: t('Monthly'),
        actionDate: this.monthFirstDay,
        projectEntire: true
      }
    ];
  }

  @computed get selectedTimeFrameOption() {
    return this.timeFrames.find(
      timeFrame => timeFrame.id === this.activeForm.$('timeFrameType').value
    );
  }

  @action.bound
  setTimeFrame(timeFrame) {
    const actionDate = timeFrame.actionDate
      ? moment(timeFrame.actionDate).format('YYYY-MM-DD')
      : '';

    this.activeForm.update({
      timeFrameType: timeFrame.id,
      actionDate: actionDate
    });
  }

  @action.bound setFromDate(value) {
    this.activeForm.$('fromDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setToDate(value) {
    this.activeForm.$('toDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound setActionDate(value) {
    this.activeForm.$('actionDate').set(moment(value).format('YYYY-MM-DD'));
  }

  @action.bound
  submitSmallEquipmentReportForm(event) {
    event.preventDefault();

    if (this.activeReportTab === 'scheduled' && !this.scheduledReportToEdit) {
      this.createNewScheduledReport();
      return;
    }

    if (this.saving) return;

    this.clearValidationDetails();

    this.activeForm.submit({
      onSuccess: this.submitSmallEquipmentReportFormSuccess,
      onError: this.submitSmallEquipmentReportFormError
    });
  }

  @action.bound submitSmallEquipmentReportFormSuccess() {
    if (this.activeReportTab === 'runOnce') {
      this.submitSmallEquipmentRunOnceReport();
    } else {
      this.submitSmallEquipmentScheduledReport();
    }
  }

  @action.bound submitSmallEquipmentReportFormError() {
    console.error(this.activeForm.errors());
  }

  @action.bound async submitSmallEquipmentRunOnceReport() {
    this.saving = true;

    const values = this.activeForm.values();

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    try {
      await request.post(
        `${this.rootStore.urlMicroService('performanceTracking')}/companies/${
          this.rootStore.me.company.uuid
        }/smallequipment/snapshot-report`,
        cleanedValues.emails
      );

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Your download request has been sent.')
      });

      callTrack(SMALL_EQUIPMENT_REPORT_DOWNLOADED);

      this.hideEquipmentReportModal();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound async submitSmallEquipmentScheduledReport() {
    this.saving = true;

    const values = this.activeForm.values();
    const hoursMins = values.time.split(':');

    values.time = {
      hour: parseInt(hoursMins[0], 10),
      minute: parseInt(hoursMins[1], 10)
    };

    const cleanedValues = pickBy(
      values,
      value => value !== null && (value !== undefined) & (value !== '')
    );

    const method = this.scheduledReportToEdit.isNew ? 'post' : 'put';

    const notificationMessage = this.scheduledReportToEdit.isNew
      ? t('Scheduled report created.')
      : t('Scheduled report updated.');

    try {
      await this.scheduledReportToEdit.save(
        {
          ...cleanedValues,
          projectIncludeOption: 'NONE',
          reportType: 'SMALL_EQUIPMENT_SNAPSHOT'
        },
        {
          method,
          wait: true
        }
      );

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: notificationMessage
      });

      this.fetchScheduled();
      this.cancelEditingReport();
    } catch (error) {
      this.saving = false;
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  createNewScheduledReport() {
    // Use a new model to hold our report when creating a new one.
    this.scheduledReportToEdit = new SmallEquipmentSnapshotReport(
      {},
      {
        rootStore: this.rootStore
      }
    );

    this.setupModalForm();
  }

  @action.bound
  cancelEditingReport() {
    this.activeForm = null;
    this.scheduledReportToEdit = null;
    this.saving = false;
  }

  @computed get fromDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('Start of week');
      case 'BI_WEEKLY':
        return t('Start of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get toDateLabel() {
    if (this.activeReportTab === 'runOnce') return null;

    switch (this.activeForm.$('timeFrameType').value) {
      case 'WEEKLY':
        return t('End of week');
      case 'BI_WEEKLY':
        return t('End of bi-weekly period');
      default:
        return t('From date');
    }
  }

  @computed get scheduleText() {
    switch (this.activeForm.$('timeFrameType').value) {
      case 'MONTHLY':
        return t('This report will be sent monthly starting:');
      case 'WEEKLY':
        return t('This report will be sent weekly starting:');
      case 'BI_WEEKLY':
        return t('This report will be sent bi-weekly starting:');
      default:
        return t('This report will be sent daily at:');
    }
  }

  @computed
  get enableSubmit() {
    if (!this.scheduledReportToEdit && this.activeReportTab === 'scheduled') {
      return true;
    }

    if (!this.activeForm || this.activeForm.isPristine || this.saving) {
      return false;
    }

    return this.activeForm.isValid;
  }

  @action.bound
  editScheduledReport(report) {
    this.scheduledReportToEdit = report;

    this.initReportForm();
  }

  @action.bound
  async deleteScheduledReport(report) {
    try {
      await report.destroy({ wait: true });
    } catch (error) {
      errorHandler(error, this.notifications.pushError);
    }
  }

  @action.bound
  handleScheduledReportCancelButton() {
    this.scheduledReportToEdit
      ? this.cancelEditingReport()
      : this.hideEquipmentReportModal();
  }

  @computed get restrictSpecificTimeframe() {
    return this.activeForm.$('timeFrameType').value === 'SPECTIMEFRAME';
  }

  @computed
  get maximumToDate() {
    const fromDate = this.activeForm.$('fromDate').value;

    if (fromDate && this.numberOfSelectedProjects > 1) {
      return moment(fromDate, 'YYYY-MM-DD').add(1, 'year');
    }

    return undefined;
  }
}

import omit from 'lodash.omit';
import isEqual from 'lodash.isequal';
import { Model } from 'mobx-mc';
import { action, computed, observable } from 'mobx';
import toSnakeCase from 'utils/toSnakeCase';

import { t } from 'utils/translate';
import ProjectMinimal from 'stores/models/ProjectMinimal';

import IntegrationConfigurations from 'stores/collections/integrations/IntegrationConfigurations';

import IntegrationIds from 'fixtures/integrationIds';

export default class Integration extends Model {
  @observable newExternalCompanyId;
  @observable newExternalProjectId;

  constructor(attributes, options) {
    super(attributes, options);

    this.configurations = new IntegrationConfigurations(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  get restAttributes() {
    return [
      'id',
      'service',
      'external',
      'hasExternal',
      'subscriberCode',
      'type',
      'tokenActive',
      'mapping',
      'mappings',
      'config'
    ];
  }

  parse(attributes) {
    if (attributes.mapping) {
      attributes.mappings = attributes.mapping;
    }

    this.parseMappings(attributes);

    return {
      ...omit(attributes, ['status', 'providerRef', 'companyRef', 'mappings'])
    };
  }

  @action.bound parseMappings(attributes) {
    if (this.mappings) {
      if (
        isEqual(
          attributes.mappings,
          this.mappings.map(mapping => {
            return omit(mapping, 'rakenProject');
          })
        )
      ) {
        return this.mappings;
      }
    }

    if (attributes.mappings) {
      attributes.mappings.forEach(async mapping => {
        mapping.rakenProject = new ProjectMinimal({
          id: mapping.projectId,
          uuid: mapping.projectUuid,
          name: 'Loading...'
        });

        try {
          if (this.rootStore.isSuperAdmin) {
            await mapping.rakenProject.fetch({
              url: `ra/sadmin/projects/${mapping.projectUuid}`
            });
          } else {
            await mapping.rakenProject.fetch({
              url: `/ra/companies/${this.rootStore.me.company.uuid}/projects/${mapping.projectUuid}`
            });
          }
        } catch (error) {
          mapping.rakenProject.name = t('Unknown');
          mapping.rakenProject.hasError = true;
        }
      });

      this.mappings = attributes.mappings;
    }
  }

  @computed get popupUrl() {
    return this.service._links.authUrl.href;
  }

  @computed
  get name() {
    return this.service.providerRef.name;
  }

  @computed
  get title() {
    return this.name;
  }

  @computed
  get projectDisplayName() {
    return this.name;
  }

  @computed
  get companyDisplayName() {
    return this.name;
  }

  @computed
  get dataQA() {
    return toSnakeCase(this.name);
  }

  @computed
  get providerId() {
    return this.service?.providerRef.id;
  }

  @computed
  get providerUrl() {
    return this.service?._links.providerUrl.href;
  }

  @computed
  get projectRef() {
    return this.service?.projectRef;
  }

  @computed
  get companyRef() {
    return this.service?.companyRef;
  }

  @computed
  get isConnected() {
    return this.service?.status === 'CONNECTED';
  }

  @computed
  get hasError() {
    return this.service?.status === 'ERROR';
  }

  @computed
  get logoFile() {
    switch (this.id) {
      case IntegrationIds.BOX:
        return 'box.svg';
      case IntegrationIds.DROPBOX:
        return 'dropbox.svg';
      case IntegrationIds.GDRIVE:
        return 'google-drive.svg';
      case IntegrationIds.EGNYTE:
        return 'egnyte.svg';
      case IntegrationIds.PROCORE:
        return 'procore.svg';
      case IntegrationIds.ONE_DRIVE:
        return 'microsoft-onedrive.svg';
      case IntegrationIds.BIM_360:
        return 'autodesk-bim-360.svg';
      case IntegrationIds.FOUNDATION:
        return 'foundation-software.svg';
      case IntegrationIds.POINTS_NORTH:
        return 'points-north.svg';
      case IntegrationIds.COMPUTER_EASE:
      case IntegrationIds.COMPUTER_EASE_DIRECT_API:
        return 'computerease.svg';
      case IntegrationIds.INEIGHT:
        return 'ineight.svg';
      case IntegrationIds.RYVIT_VISTA:
        return 'vista.svg';
      case IntegrationIds.RYVIT_SAGE_300:
        return 'sage-300-cre.svg';
      case IntegrationIds.RYVIT_SAGE_100:
        return 'sage-100-contractor.svg';
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return 'viewpoint-spectrum.svg';
      case IntegrationIds.QUICKBOOKS_ONLINE:
      case IntegrationIds.QUICKBOOKS_DESKTOP:
        return 'quickbooks-online.svg';
      case IntegrationIds.EARTHCAM:
        return 'earthcam.svg';
      case IntegrationIds.HOLO_BUILDER:
        return 'holobuilder.svg';
      case IntegrationIds.JD_EDWARDS:
        return 'jd-edwards.svg';
      case IntegrationIds.DRONE_DEPLOY:
        return 'dronedeploy.svg';
      case IntegrationIds.SAGE_INTACCT:
        return 'sage-intacct.svg';
      case IntegrationIds.PAYCHEX:
        return 'paychex.svg';
      case IntegrationIds.TRUELOOK:
        return 'truelook.svg';
      case IntegrationIds.RAKEN_MEDIA_AI:
        return 'raken-media-ai.svg';
      case IntegrationIds.OSHA:
        return 'osha.svg';
      default:
        return null;
    }
  }

  @computed
  get logo() {
    return `${this.rootStore.assetsURL}/svg/integrations/small/${this.logoFile}`;
  }

  @computed
  get logoLarge() {
    return `${this.rootStore.assetsURL}/svg/integrations/large/${this.logoFile}`;
  }

  @computed get logoHeight() {
    switch (this.id) {
      case IntegrationIds.RYVIT_VISTA:
        return 45;
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return 45;
      case IntegrationIds.POINTS_NORTH:
        return 90;
      case IntegrationIds.HOLO_BUILDER:
        return 65;
      case IntegrationIds.DRONE_DEPLOY:
        return 50;
      case IntegrationIds.JD_EDWARDS:
        return 55;
      case IntegrationIds.EGNYTE:
        return 50;
      case IntegrationIds.PAYCHEX:
        return 30;
      case IntegrationIds.TRUELOOK:
        return 40;
      case IntegrationIds.OSHA:
        return 40;
      default:
        return 'auto';
    }
  }

  @computed
  get description() {
    switch (this.id) {
      case IntegrationIds.BIM_360:
        return t(`Project Management`);
      case IntegrationIds.BOX:
        return t(`Document Management`);
      case IntegrationIds.DROPBOX:
        return t(`Document Management`);
      case IntegrationIds.EGNYTE:
        return t(`Document Management`);
      case IntegrationIds.FOUNDATION:
        return t(`Accounting`);
      case IntegrationIds.POINTS_NORTH:
        return t(`Accounting`);
      case IntegrationIds.COMPUTER_EASE:
      case IntegrationIds.COMPUTER_EASE_DIRECT_API:
        return t(`Accounting`);
      case IntegrationIds.JD_EDWARDS:
        return t(`Accounting`);
      case IntegrationIds.GDRIVE:
        return t(`Document Management`);
      case IntegrationIds.ONE_DRIVE:
        return t(`Document Management`);
      case IntegrationIds.PROCORE:
        return t(`Project Management`);
      case IntegrationIds.INEIGHT:
        return t(`Project Management`);
      case IntegrationIds.RYVIT_VISTA:
        return t(`Accounting`);
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return t(`Accounting`);
      case IntegrationIds.RYVIT_SAGE_300:
        return t(`Accounting`);
      case IntegrationIds.RYVIT_SAGE_100:
        return t(`Accounting`);
      case IntegrationIds.QUICKBOOKS_ONLINE:
      case IntegrationIds.QUICKBOOKS_DESKTOP:
        return t(`Accounting`);
      case IntegrationIds.EARTHCAM:
        return t(`Reality Capture`);
      case IntegrationIds.HOLO_BUILDER:
        return t(`Reality Capture`);
      case IntegrationIds.DRONE_DEPLOY:
        return t(`Reality Capture`);
      case IntegrationIds.TRUELOOK:
        return t(`Reality Capture`);
      case IntegrationIds.SAGE_INTACCT:
        return t(`Accounting`);
      case IntegrationIds.PAYCHEX:
        return t(`Accounting`);
      case IntegrationIds.RAKEN_MEDIA_AI:
        return t(`Job Site Media`);
      case IntegrationIds.OSHA:
        return t(`Safety Management`);
      default:
        return '';
    }
  }

  @computed get appConnectSuccessText() {
    switch (this.id) {
      case IntegrationIds.BIM_360:
        return t(
          "You have successfully connected Autodesk Construction Cloud to your Raken Account. Please close this window and proceed to the 'Projects' tab to continue setting up the integration."
        );
      case IntegrationIds.INEIGHT:
        return t(
          "You have successfully connected InEight to your Raken Account. Please close this window and proceed to the 'Projects' tab to continue setting up the integration."
        );
      case IntegrationIds.PROCORE:
        return t(
          "You have successfully connected Procore to your Raken Account. Please close this window and proceed to the 'Projects' tab to continue setting up the integration."
        );
      case IntegrationIds.QUICKBOOKS_ONLINE:
        return t(
          "You have successfully connected QuickBooks Online to your Raken Account. Please close this window and proceed to the 'Settings' tab to continue setting up the integration."
        );
      case IntegrationIds.RYVIT_SAGE_100:
        return t(
          'Sage 100 Contractor has been successfully connected to Raken. Please close this window to proceed with the integration onboarding.'
        );
      case IntegrationIds.RYVIT_SAGE_300:
        return t(
          'Sage 300 CRE has been successfully connected to Raken. Please close this window to proceed with the integration onboarding.'
        );
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return t(
          'Viewpoint Spectrum has been successfully connected to Raken. Please close this window to proceed with the integration onboarding.'
        );
      case IntegrationIds.RYVIT_VISTA:
        return t(
          'Viewpoint Vista has been successfully connected to Raken. Please close this window to proceed with the integration onboarding.'
        );
      case IntegrationIds.SAGE_INTACCT:
        return t(
          'Sage Intacct has been successfully connected to Raken. Please close this window to proceed with the integration onboarding.'
        );
      case IntegrationIds.OSHA:
        return t(
          'Your OSHA ITA account has been connected to Raken. To submit data to OSHA go to Company > Incidents > All incidents. Your incident submission settings have been updated to match your selected Establishment info.'
        );
      default:
        return t(
          'Your {integrationName} is now connected to Raken. Raken will now automatically upload all your daily reports into your {integrationName} account.',
          {
            templateStrings: {
              integrationName: this.title
            }
          }
        );
    }
  }

  @computed
  get overviewText() {
    switch (this.id) {
      case IntegrationIds.FOUNDATION:
        return t(
          `Process payroll faster with the Raken + Foundation Software integration. Save time and eliminate errors caused by manual data entry by simply exporting time card data from Raken into a pre-formatted CSV file and uploading it into your Foundation Software account.`
        );
      case IntegrationIds.POINTS_NORTH:
        return t(
          `Process payroll faster with the Raken + Points North Software integration. Save time and eliminate errors caused by manual data entry by simply exporting time card data from Raken into a pre-formatted CSV file and uploading it into your Points North account.`
        );
      case IntegrationIds.COMPUTER_EASE:
        return t(
          `Process payroll faster with the Raken + ComputerEase CSV Export integration. Save time and eliminate errors caused by manual data entry by simply exporting time card data from Raken into a pre-formatted CSV file and uploading it into your ComputerEase account.`
        );
      case IntegrationIds.JD_EDWARDS:
        return t(
          `Process payroll faster with the Raken + JD Edwards integration. Save time and eliminate errors caused by manual data entry by simply exporting time card data from Raken into a pre-formatted CSV file and uploading it into your JD Edwards Instance.`
        );
      case IntegrationIds.GDRIVE:
        return t(
          'Automatically share files with your project team in Google Drive to improve communication and save time. Simply click the Connect button to activate the Raken & Google Drive integration.'
        );
      case IntegrationIds.ONE_DRIVE:
        return t(
          'Automatically share files with your project team in Microsoft OneDrive to improve communication and save time. Simply click the Connect button to activate the Raken & Microsoft OneDrive integration.'
        );
      case IntegrationIds.PROCORE:
        return t(
          `Share files with your project team and eliminate time-consuming application switching and double entry by connecting the Raken + Procore integration. Your Raken daily reports, subcontractor reports, photos, and videos will be automatically synced to the Procore Project Documents module for connected projects. You may also choose to sync completed Checklists, standard Work Logs, and Notes.`
        );
      case IntegrationIds.INEIGHT:
        return t(
          `Keep your entire project team on the same page about jobsite progress with the Raken + InEight integration. Seamlessly transfer your Raken daily reports, subcontractor reports, photos, videos, and other PDF attachments into your InEight Documents Module.`
        );
      case IntegrationIds.RYVIT_VISTA:
        return t(
          `Process payroll faster with the Raken + Viewpoint Vista integration. Save time and eliminate errors caused by manual data entry by importing your Projects, Members, Cost Codes and other payroll data from Vista in real time. When you’re ready to process payroll, sync your team’s completed timecards to Viewpoint Vista with the click of a button.`
        );
      case IntegrationIds.RYVIT_SAGE_300:
        return t(
          `Process payroll faster with the Raken + Sage 300 CRE integration. Save time and eliminate errors caused by manual data entry by importing your Projects, Members, Cost Codes and other payroll data from Sage 300 CRE in real time. When you’re ready to process payroll, sync your team’s completed timecards to Sage 300 CRE with the click of a button.`
        );
      case IntegrationIds.RYVIT_SAGE_100:
        return t(
          `Process payroll faster with the Raken + Sage 100 Contractor integration. Save time and eliminate errors caused by manual data entry by importing your Projects, Members, Cost Codes and other payroll data from Sage 100 Contractor in real time. When you’re ready to process payroll, sync your team’s completed timecards to Sage 100 Contractor with the click of a button.`
        );
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return t(
          `Process payroll faster with the Raken + Viewpoint Spectrum integration. Save time and eliminate errors caused by manual data entry by importing your Projects, Members, Cost Codes and other payroll data from Viewpoint Spectrum in real time. When you’re ready to process payroll, sync your team’s completed timecards to Viewpoint Spectrum with the click of a button.`
        );
      case IntegrationIds.QUICKBOOKS_ONLINE:
        return t(
          `Process payroll faster with the Raken + QuickBooks Online integration. Save time and eliminate errors caused by manual data entry by importing your Projects and Members from QuickBooks Online in real time. When you’re ready to process payroll, sync your team’s completed timecards to QuickBooks Online with just a couple of clicks.`
        );
      case IntegrationIds.EARTHCAM:
        return t(
          `Connect your EarthCam Public view to Raken to get job site visibility right from your Raken Dashboard. With the integration enabled, your team can view the latest updates from your EarthCam devices, switch between different EarthCam projects and cameras, and download EarthCam images to your device.`
        );
      case IntegrationIds.HOLO_BUILDER:
        return t(
          `Embed your HoloBuilder projects directly into Raken for easy access and to improve your workflow. Once configured, you’ll be able to access your HoloBuilder walkthroughs to take measurements, create scenes, and markup your 360° images without leaving Raken.`
        );
      case IntegrationIds.DRONE_DEPLOY:
        return t(
          `Connect your DroneDeploy projects to Raken to get job site visibility right from your Raken Dashboard. With the integration enabled, your team can view the latest details from your DroneDeploy projects.`
        );
      case IntegrationIds.BOX:
        return t(
          'Automatically share files with your project team in Box to improve communication and save time. Simply click the Connect button to activate the Raken & Box integration.'
        );
      case IntegrationIds.DROPBOX:
        return t(
          'Automatically share files with your project team in Dropbox to improve communication and save time. Simply click the Connect button to activate the Raken & Dropbox integration.'
        );
      case IntegrationIds.BIM_360:
        return t(
          'Automatically share files with your project team in Autodesk to improve communication and save time. Simply click the Connect button to activate the Raken & Autodesk Construction Cloud integration.'
        );
      case IntegrationIds.EGNYTE:
        return t(
          'Automatically share files with your project team in Egnyte to improve communication and save time. Simply click the Connect button to activate the Raken & Egnyte integration.'
        );
      case IntegrationIds.PAYCHEX:
        return t(
          'Process payroll faster with the Raken + Paychex integration. Save time and eliminate errors caused by manual data entry by simply exporting time card data from Raken into a pre-formatted CSV file and uploading it into your Paychex account.'
        );
      case IntegrationIds.TRUELOOK:
        return t(
          'Connect your TrueLook links to Raken to get job site visibility right from your Raken Dashboard. With the integration enabled, your team can view your TrueLook live feeds, switch between different TrueLook cameras, review historical footage, operate TrueLook PTZ cameras, and more.'
        );
      case IntegrationIds.QUICKBOOKS_DESKTOP:
        return t(
          "Process payroll faster with the Raken + QuickBooks Desktop integration. Save time and eliminate errors caused by manual data entry by importing your Customers, Customer Jobs, Employees, Payroll Items, and Service Items from QuickBooks Desktop. When you're ready to process payroll, sync your team's completed time cards to QuickBooks Desktop with just a couple of clicks."
        );
      case IntegrationIds.COMPUTER_EASE_DIRECT_API:
        return t(
          `Process payroll faster with the Raken + ComputerEase Direct API integration. Save time and eliminate errors caused by manual data entry by importing your Jobs, Phases, Categories, Employees, Classes, and Pay Types from ComputerEase. When you're ready to process payroll, sync your team's completed time cards to ComputerEase with just a couple of clicks.`
        );
      default:
        return '';
    }
  }

  @computed get connectedText() {
    if (this.type === 'CLOUD' || this.showMappingControls) {
      return t(
        'This integration has been enabled. Your Raken daily reports, subcontractor reports, photos, videos, and other project documents will now automatically transfer into your {integrationName} project folders.',
        {
          templateStrings: {
            integrationName: this.projectDisplayName
          }
        }
      );
    }

    if (this.isPaychex) {
      return t(
        `You can now export Raken time card reports from Timesheets in a CSV format  compatible with Paychex. Please make sure that the configurations on the Settings tab are set appropriately for your company prior to exporting time.`
      );
    }

    if (this.isFlatFile) {
      return t(
        `Your {integrationName} accounting software integration is enabled. You can now export Raken time card reports into a CSV file that matches the format needed to efficiently import into {integrationName}.`,
        {
          templateStrings: { integrationName: this.name }
        }
      );
    }

    if (this.isQBO) {
      return t(
        `To get started with the QuickBooks integration, you'll first need to configure how you'd like to sync your data. We'll guide you through the process to make sure everything goes smoothly and we're here to help if anything comes up.`
      );
    }

    if (this.isEarthCam) {
      return t(
        `EarthCam has been connected. To complete the setup, make sure to enter your EarthCam sources in the “Settings” tab on the left. Your sources can be found in the Public Outreach tool in EarthCam.`
      );
    }

    if (this.isHoloBuilder) {
      return t(
        `HoloBuilder has been connected. To complete the setup, make sure to enter your HoloBuilder projects in the “Settings” tab on the left.`
      );
    }

    if (this.isDroneDeploy) {
      return t(
        `DroneDeploy has been connected. To complete the setup, make sure to enter your DroneDeploy sources in the “Settings” tab on the left. You can generate the DroneDeploy source link from the DroneDeploy 'share' menu by clicking the 'View-Only' button.`
      );
    }

    if (this.isTrueLook) {
      return t(
        `TrueLook has been connected. To complete setup, make sure to enter your TrueLook sources in the “Settings” tab on the left. You can generate TrueLook source links in TrueLook by going to Settings > Sharing Center.`
      );
    }

    if (this.isQBD) {
      return t(
        `Your QuickBooks Desktop  Customers, Customer Jobs, Employees, Payroll Items, and Service Items from QuickBooks Desktop can now be synced from the QuickBooks Web Connector on demand or automatically. When you’re ready to process payroll, sync your team’s completed time cards to QuickBooks Desktop with just a couple of clicks.`
      );
    }

    if (this.isOsha) {
      return t(
        `Add and submit your company's OSHA Forms 300A and 300/301 data directly to OSHA's Injury Tracking Application (ITA) with the OSHA Submit integration. Your Raken recordable incidents will be compiled and sent to OSHA in minutes with no need to manually add data to ITA or format and upload CSVs. To confirm your company's OSHA submission requirements, please refer to the OSHA ITA Coverage Application.`,
        {
          templateStrings: { integrationName: this.name }
        }
      );
    }

    if (this.isComputerEaseDirectAPI) {
      return t(
        'Your ComputerEase Jobs, Phases, Categories, Employees, Classes, and Pay Types are now being synced to Raken directly every 30 minutes. To sync Raken time data to ComputerEase, visit the Timesheets page.'
      );
    }

    return '';
  }

  @computed
  get requirements() {
    const rakenRequirement = `An active Raken Performance or Enterprise subscription with admin access`;
    const contactUsOnly = `This integration is currently available by request only. To request access, please click the 'Contact Us' button`;
    switch (this.id) {
      case IntegrationIds.BIM_360:
        return [
          t(rakenRequirement),
          t(
            `Permission to add documents to one or more Autodesk BIM 360 or ACC Build projects`
          )
        ];
      case IntegrationIds.BOX:
        return [t(rakenRequirement), t(`A Box account`)];
      case IntegrationIds.DROPBOX:
        return [t(rakenRequirement), t(`A Dropbox account`)];
      case IntegrationIds.EGNYTE:
        return [t(rakenRequirement), t(`An Egnyte account`)];
      case IntegrationIds.FOUNDATION:
        return [t(rakenRequirement), t(`A paid Foundation Software account`)];
      case IntegrationIds.POINTS_NORTH:
        return [t(rakenRequirement)];
      case IntegrationIds.COMPUTER_EASE:
        return [t(rakenRequirement), t(`An active ComputerEase account`)];
      case IntegrationIds.JD_EDWARDS:
        return [t(rakenRequirement), t(`An active JD Edwards instance`)];
      case IntegrationIds.GDRIVE:
        return [t(rakenRequirement), t(`A Google Drive account`)];
      case IntegrationIds.ONE_DRIVE:
        return [t(rakenRequirement), t(`A Microsoft OneDrive account`)];
      case IntegrationIds.PROCORE:
        return [t(rakenRequirement), t(`A Procore account`)];
      case IntegrationIds.INEIGHT:
        return [
          t(rakenRequirement),
          t(`An InEight Account with API access enabled`)
        ];
      case IntegrationIds.RYVIT_VISTA:
        return [
          t(),
          t(rakenRequirement),
          t(`An active Viewpoint Vista account`),
          t(`Completed Viewpoint Vista Integration onboarding with our team`)
        ];
      case IntegrationIds.RYVIT_SAGE_300:
        return [
          t(contactUsOnly),
          t(rakenRequirement),
          t(`An active Sage 300 CRE account`),
          t(`Completed Sage 300 CRE Integration onboarding with our team`)
        ];
      case IntegrationIds.RYVIT_SAGE_100:
        return [
          t(contactUsOnly),
          t(rakenRequirement),
          t(`An active Sage 100 Contractor account`),
          t(
            `Completed Sage 100 Contractor Integration onboarding with our team`
          )
        ];
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return [
          t(contactUsOnly),
          t(rakenRequirement),
          t(`An active Viewpoint Spectrum account`),
          t(`Completed Viewpoint Spectrum Integration onboarding with our team`)
        ];
      case IntegrationIds.QUICKBOOKS_ONLINE:
        return [
          t(rakenRequirement),
          t(`An active QuickBooks Online subscription (Plus or Advanced)`),
          t(
            `Completed integration setup - Don't worry, we'll guide you through the necessary steps`
          )
        ];
      case IntegrationIds.EARTHCAM:
        return [
          t(rakenRequirement),
          t(`An EarthCam account with Public Outreach access`)
        ];
      case IntegrationIds.HOLO_BUILDER:
        return [
          t(rakenRequirement),
          t(`An active HoloBuilder account with access to projects`)
        ];
      case IntegrationIds.DRONE_DEPLOY:
        return [
          t(rakenRequirement),
          t(`A DroneDeploy account with one or more projects`)
        ];
      case IntegrationIds.TRUELOOK:
        return [
          t(rakenRequirement),
          t(
            `An active TrueLook account with one or more Sharing Links available (must have Account Admin access to create new Sharing Links)`
          )
        ];
      case IntegrationIds.SAGE_INTACCT:
        return [
          t(rakenRequirement),
          t(
            `An active Sage Intacct instance with the Construction and Real Estate package`
          ),
          t(
            `Credentials for an active Web Service user in your Sage Intacct instance`
          ),
          t(`Authorization added for Raken to access your Sage Intacct data`),
          t(`A completed integration onboarding questionnaire`)
        ];
      case IntegrationIds.PAYCHEX:
        return [t(rakenRequirement), t(`An active Paychex account`)];
      case IntegrationIds.QUICKBOOKS_DESKTOP:
        return [
          t(rakenRequirement),
          t(
            `A Windows PC running a compatible version of QuickBooks Desktop versions: Enterprise, Premier, Pro`
          ),
          t(
            `The QuickBooks Web Connector should be installed on the same PC as your QuickBooks instance. You can download the latest version <a class="link_integration-requirement" href="https://quickbooks.intuit.com/learn-support/en-us/help-article/install-products/set-quickbooks-web-connector/L4Vp7VI44_US_en_US" target="_blank" rel="noopener noreferrer">here</a>.`,
            {
              hasHtml: true
            }
          ),
          t(
            'The Raken Web Connector file should be loaded into the QuickBooks Web Connector. We will guide you through this during the integration activation.'
          )
        ];
      case IntegrationIds.RAKEN_MEDIA_AI:
        return [t(contactUsOnly), t(rakenRequirement)];
      case IntegrationIds.OSHA:
        return [
          t(rakenRequirement),
          t(`An active OSHA ITA account with one or more Establishments`)
        ];
      case IntegrationIds.COMPUTER_EASE_DIRECT_API:
        return [
          t(rakenRequirement),
          t(`An active instance of ComputerEase`),
          t(
            `A valid API key and refresh token created for the above ComputerEase instance`
          )
        ];
      default:
        return [];
    }
  }

  @computed get hasRequirements() {
    return this.requirements.length > 0;
  }

  @computed
  get helpfulResources() {
    switch (this.id) {
      case IntegrationIds.BIM_360:
        return {
          linkText: t(
            `Integrating Autodesk BIM 360 and ACC Build projects with Raken`
          ),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177280-integrating-autodesk-bim-360-build-with-raken'
        };
      case IntegrationIds.BOX:
        return {
          linkText: t(`Integrating Box with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177388-integrating-box-with-raken'
        };
      case IntegrationIds.DROPBOX:
        return {
          linkText: t(`Integrating Dropbox with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177413-integrating-dropbox-with-raken'
        };
      case IntegrationIds.EGNYTE:
        return {
          linkText: t(`Integrating Egnyte with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177468-integrating-egnyte-with-raken'
        };
      case IntegrationIds.FOUNDATION:
        return {
          linkText: t(`Integrating Foundation Software with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4180435-integrating-foundation-with-raken'
        };
      case IntegrationIds.POINTS_NORTH:
        return {
          linkText: t(`Integrating Points North Software with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4508324-integrating-points-north-with-raken'
        };
      case IntegrationIds.COMPUTER_EASE:
        return {
          linkText: t(`Integrating ComputerEase with Raken - CSV Export`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4773549-integrating-computerease-with-raken'
        };
      case IntegrationIds.GDRIVE:
        return {
          linkText: t(`Integrating Google Drive with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177898-integrating-google-drive-with-raken'
        };
      case IntegrationIds.ONE_DRIVE:
        return {
          linkText: t(`Integrating Microsoft OneDrive with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4177913-integrating-one-drive-with-raken'
        };
      case IntegrationIds.PROCORE:
        return {
          linkText: t(`Integrating Procore with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4178039-integrating-procore-with-raken'
        };
      case IntegrationIds.INEIGHT:
        return {
          linkText: t(`Integrating InEight with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/5214738-integrating-ineight-with-raken'
        };
      case IntegrationIds.EARTHCAM:
        return {
          linkText: t(`Integrating EarthCam with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/6266577-integrating-earthcam-with-raken'
        };
      case IntegrationIds.QUICKBOOKS_ONLINE:
        return {
          linkText: t(`Integrating QuickBooks Online with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4180521-integrating-quickbooks-online-with-raken'
        };
      case IntegrationIds.HOLO_BUILDER:
        return {
          linkText: t(`Integrating HoloBuilder with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/6578113-integrating-holobuilder-with-raken'
        };
      case IntegrationIds.DRONE_DEPLOY:
        return {
          linkText: t(`Integrating DroneDeploy with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/8638889-integrating-dronedeploy-with-raken'
        };
      case IntegrationIds.PAYCHEX:
        return {
          linkText: t(`Integrating Paychex with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/9242637-integrating-paychex-with-raken'
        };
      case IntegrationIds.TRUELOOK:
        return {
          linkText: t(`Integrating TrueLook with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/9709420-integrating-truelook-with-raken'
        };
      case IntegrationIds.RAKEN_MEDIA_AI:
        return {
          linkText: t(`Raken Media AI`),
          linkHref: 'https://help.rakenapp.com/en/articles'
        };
      case IntegrationIds.QUICKBOOKS_DESKTOP:
        return {
          linkText: t(`QuickBooks Desktop`),
          linkHref:
            'http://help.rakenapp.com/en/articles/10055563-integrating-quickbooks-desktop-with-raken'
        };
      case IntegrationIds.OSHA:
        return {
          linkText: t(`Integrating OSHA ITA with Raken`),
          linkHref:
            'https://help.rakenapp.com/en/articles/10004301-integrating-osha-ita-with-raken'
        };
      case IntegrationIds.COMPUTER_EASE_DIRECT_API:
        return {
          linkText: t(`Integrating ComputerEase with Raken - Direct API`),
          linkHref:
            'https://help.rakenapp.com/en/articles/4773549-integrating-computerease-with-raken'
        };
      default:
        return { linkText: '', linkHref: '' };
    }
  }

  @computed
  get noAuthenticationRequired() {
    return !this.service._links.authUrl && !this.isRyvit;
  }

  /**
   * Beta integration will have " - Early Access" at the end of the integration name on the integration hub page, e.g. "Viewpoint Spectrum - Early Access"
   *
   * @return {boolean}
   */
  @computed get isBeta() {
    return [].includes(this.id);
  }

  @computed get isAccounting() {
    const accountingIntegrations = [
      IntegrationIds.FOUNDATION,
      IntegrationIds.POINTS_NORTH,
      IntegrationIds.COMPUTER_EASE,
      IntegrationIds.PAYCHEX,
      IntegrationIds.JD_EDWARDS,
      IntegrationIds.RYVIT_VISTA,
      IntegrationIds.RYVIT_SAGE_300,
      IntegrationIds.RYVIT_SAGE_100,
      IntegrationIds.SAGE_INTACCT,
      IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM,
      IntegrationIds.QUICKBOOKS_ONLINE,
      IntegrationIds.QUICKBOOKS_DESKTOP,
      IntegrationIds.COMPUTER_EASE_DIRECT_API
    ];

    return accountingIntegrations.includes(this.id);
  }

  @computed get isRyvit() {
    return [
      IntegrationIds.RYVIT_VISTA,
      IntegrationIds.RYVIT_SAGE_300,
      IntegrationIds.RYVIT_SAGE_100,
      IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM
    ].includes(this.id);
  }

  @computed get isFlatFile() {
    return [
      IntegrationIds.FOUNDATION,
      IntegrationIds.POINTS_NORTH,
      IntegrationIds.COMPUTER_EASE,
      IntegrationIds.JD_EDWARDS,
      IntegrationIds.PAYCHEX
    ].includes(this.id);
  }

  @computed get showDefaultSyncConfigurations() {
    return (
      this.isProcore ||
      this.isBox ||
      this.isDropbox ||
      this.isOneDrive ||
      this.isGDrive
    );
  }

  @computed get isBox() {
    return this.id === IntegrationIds.BOX;
  }

  @computed get isOneDrive() {
    return this.id === IntegrationIds.ONE_DRIVE;
  }

  @computed get isDropbox() {
    return this.id === IntegrationIds.DROPBOX;
  }

  @computed get isBim360() {
    return this.id === IntegrationIds.BIM_360;
  }

  @computed get isEgnyte() {
    return this.id === IntegrationIds.EGNYTE;
  }

  @computed get isProcore() {
    return this.id === IntegrationIds.PROCORE;
  }

  @computed get isEarthCam() {
    return this.id === IntegrationIds.EARTHCAM;
  }

  @computed get isHoloBuilder() {
    return this.id === IntegrationIds.HOLO_BUILDER;
  }

  @computed get isDroneDeploy() {
    return this.id === IntegrationIds.DRONE_DEPLOY;
  }

  @computed get isTrueLook() {
    return this.id === IntegrationIds.TRUELOOK;
  }

  @computed get isSageIntacct() {
    return this.id === IntegrationIds.SAGE_INTACCT;
  }

  @computed get isQBO() {
    return this.id === IntegrationIds.QUICKBOOKS_ONLINE;
  }

  @computed get isQBD() {
    return this.id === IntegrationIds.QUICKBOOKS_DESKTOP;
  }

  @computed get isGDrive() {
    return this.id === IntegrationIds.GDRIVE;
  }

  @computed get isPaychex() {
    return this.id === IntegrationIds.PAYCHEX;
  }

  @computed get isRakenMediaAI() {
    return this.id === IntegrationIds.RAKEN_MEDIA_AI;
  }

  @computed get isOsha() {
    return this.id === IntegrationIds.OSHA;
  }

  @computed get isComputerEaseDirectAPI() {
    return this.id === IntegrationIds.COMPUTER_EASE_DIRECT_API;
  }

  @computed get isLiveView() {
    return (
      this.isEarthCam ||
      this.isHoloBuilder ||
      this.isDroneDeploy ||
      this.isTrueLook
    );
  }

  @computed get showMappingControls() {
    if (!this.isConnected) return false;

    // Egnyte supports mapping to project folders at the company level
    if (this.isEgnyte && this.companyRef) return true;

    if (
      this.type === 'CLOUD' ||
      this.isAccounting ||
      this.isLiveView ||
      this.isRakenMediaAI ||
      this.isOsha
    )
      return false;

    return true;
  }

  @computed get hasMappedProjects() {
    return this.mappings?.length > 0;
  }

  @computed get showConnect() {
    if (
      this.isRyvit ||
      this.isSageIntacct ||
      this.isComputerEaseDirectAPI ||
      this.isRakenMediaAI ||
      this.isQBD
    ) {
      return this.rootStore.isSuperAdmin && !this.isConnected && !this.hasError;
    }

    return !this.isConnected && !this.hasError;
  }

  @computed get showDisconnect() {
    if (
      this.isRyvit ||
      this.isSageIntacct ||
      this.isComputerEaseDirectAPI ||
      this.isRakenMediaAI ||
      this.isQBD
    ) {
      return this.rootStore.isSuperAdmin && (this.isConnected || this.hasError);
    }

    return this.isConnected || this.hasError;
  }

  @computed get disableConnect() {
    if (this.rootStore.isSuperAdmin || this.rootStore.isSudoing) {
      return (
        this.service._links.authUrl &&
        !this.isRyvit &&
        !this.isSageIntacct &&
        !this.isComputerEaseDirectAPI &&
        !this.isOsha
      );
    }

    return false;
  }

  @computed get showReauthorize() {
    if (this.isRyvit) {
      return false;
    }

    if (this.isSageIntacct || this.isComputerEaseDirectAPI || this.isOsha) {
      return this.showDisconnect;
    }

    return this.showDisconnect && !this.noAuthenticationRequired;
  }

  @computed get showTokenSwitch() {
    if (!this.isConnected) return false;

    return Boolean(
      this.isQBO ||
        this.isRyvit ||
        (this.isSageIntacct && this.rootStore.isSuperAdmin) ||
        (this.isComputerEaseDirectAPI && this.rootStore.isSuperAdmin)
    );
  }

  @computed get showContact() {
    if (
      this.isRyvit ||
      this.isSageIntacct ||
      this.isComputerEaseDirectAPI ||
      this.isRakenMediaAI ||
      this.isQBD
    ) {
      return !this.rootStore.isSuperAdmin;
    }

    return false;
  }

  @computed get showHelp() {
    return !this.isRyvit && !this.isSageIntacct;
  }

  @computed get showSetup() {
    return this.isRyvit && this.isConnected && this.rootStore.isSuperAdmin;
  }

  @computed get showConfigurationSettings() {
    if (!this.isConnected) return false;

    return [
      IntegrationIds.PROCORE,
      IntegrationIds.RYVIT_SAGE_300,
      IntegrationIds.RYVIT_SAGE_100,
      IntegrationIds.EARTHCAM,
      IntegrationIds.HOLO_BUILDER,
      IntegrationIds.DRONE_DEPLOY,
      IntegrationIds.QUICKBOOKS_ONLINE,
      IntegrationIds.QUICKBOOKS_DESKTOP,
      IntegrationIds.RYVIT_VISTA,
      IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM,
      IntegrationIds.BIM_360,
      IntegrationIds.EGNYTE,
      IntegrationIds.BOX,
      IntegrationIds.DROPBOX,
      IntegrationIds.ONE_DRIVE,
      IntegrationIds.SAGE_INTACCT,
      IntegrationIds.GDRIVE,
      IntegrationIds.PAYCHEX,
      IntegrationIds.TRUELOOK,
      IntegrationIds.RAKEN_MEDIA_AI,
      IntegrationIds.OSHA,
      IntegrationIds.COMPUTER_EASE_DIRECT_API
    ].includes(this.id);
  }

  @computed get showDebugSection() {
    return (
      !this.isFlatFile &&
      !this.isEarthCam &&
      !this.isHoloBuilder &&
      !this.isDroneDeploy &&
      !this.isTrueLook &&
      !this.isRakenMediaAI &&
      this.rootStore.isSuperAdmin
    );
  }

  @computed get websiteUrl() {
    return this.rootStore.appConfig.website_url;
  }

  @computed get contactLink() {
    switch (this.id) {
      case IntegrationIds.RYVIT_SAGE_100:
        return `${this.websiteUrl}/sage-100-contractor`;
      case IntegrationIds.RYVIT_VIEWPOINT_SPECTRUM:
        return `${this.websiteUrl}/viewpoint-spectrum`;
      case IntegrationIds.RYVIT_VISTA:
        return `${this.websiteUrl}/viewpoint-vista-raken`;
      case IntegrationIds.RYVIT_SAGE_300:
        return `${this.websiteUrl}/sage-300-cre-integration`;
      default:
        return null;
    }
  }

  @computed get sourceValidationRules() {
    const basicRule = 'url';
    switch (this.id) {
      case IntegrationIds.HOLO_BUILDER:
        return `${basicRule}|domainCustom:.holobuilder.com`;
      case IntegrationIds.EARTHCAM:
        return `${basicRule}|domainCustom:.earthcam.net`;
      case IntegrationIds.DRONE_DEPLOY:
        return `${basicRule}|domainCustom:.dronedeploy.com`;
      case IntegrationIds.TRUELOOK:
        return `${basicRule}|domainCustom:.truelook.cloud`;
      default:
        return basicRule;
    }
  }

  @computed get token() {
    return this.service.id;
  }

  @computed get connectionInfo() {
    return this.service.connectionInfo;
  }

  @computed get connectionInfoLength() {
    return this.connectionInfo?.attributes.length;
  }

  @computed get hasConnectionInfo() {
    return this.connectionInfoLength > 0;
  }

  @computed get showQBOMappings() {
    return this.isQBO && this.isConnected;
  }

  @computed get showQBDMappings() {
    return this.isQBD && this.isConnected;
  }

  @computed get showSageIntacctMappings() {
    return this.isSageIntacct && this.isConnected;
  }

  @computed get showComputerEaseDirectAPIMappings() {
    return this.isComputerEaseDirectAPI && this.isConnected;
  }

  @computed get connectPlaceholders() {
    if (this.isSageIntacct) {
      return {
        companyId: t('Company ID'),
        userId: t('Web Service User ID'),
        password: t('Password')
      };
    } else {
      return {
        company: `${this.name} ${t('Company name')}`,
        user: t('Username'),
        password: t('Password')
      };
    }
  }

  @computed get submitPlaceholder() {
    switch (this.id) {
      case IntegrationIds.OSHA:
        return t('Authorize Connection');
      default:
        return t('Sign In');
    }
  }

  @computed get overviewBullets() {
    switch (this.id) {
      case IntegrationIds.BOX:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to Box for all projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      case IntegrationIds.DROPBOX:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to Dropbox for all projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      case IntegrationIds.ONE_DRIVE:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to Microsoft OneDrive for all projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      case IntegrationIds.BIM_360:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to the ACC Docs module for connected projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      case IntegrationIds.EGNYTE:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to Egnyte for all projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      case IntegrationIds.GDRIVE:
        return [
          t(
            'Your Raken daily reports, subcontractor reports, photos, and videos will automatically sync to Google Drive for all projects.'
          ),
          t('Completed checklists may also be synced if you choose.')
        ];
      default:
        return null;
    }
  }
}

import MobxReactForm from 'mobx-react-form';
import extendedPlugins from './utils/extendedPlugins';

import { t } from 'utils/translate';

const policyBreakFormPlugins = extendedPlugins;

const policyBreakFormOptions = {
  validateOnInit: false,
  validateOnChange: true,
  strictUpdate: false
};

const policyBreakFormFields = [
  'defaultDuration',
  'minimumBreakDuration',
  'billable'
];

const policyBreakFormValues = {
  defaultDuration: '',
  minimumBreakDuration: '',
  billable: ''
};

const policyBreakFormRules = {
  defaultDuration: 'numeric',
  minimumBreakDuration: 'numeric',
  billable: 'boolean'
};

const policyBreakFormLabels = {
  defaultDuration: t('default duration'),
  minimumBreakDuration: t('minimum duration'),
  billable: t('compensation')
};

class PolicyBreakForm extends MobxReactForm {}

export {
  PolicyBreakForm,
  policyBreakFormPlugins,
  policyBreakFormOptions,
  policyBreakFormFields,
  policyBreakFormRules,
  policyBreakFormLabels,
  policyBreakFormValues
};

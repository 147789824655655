import { action, computed, observable } from 'mobx';
import moment from 'moment-timezone';

import { Model } from 'mobx-mc';

import { t } from 'utils/translate';

import omit from 'lodash.omit';
import orderBy from 'lodash.orderby';

export default class Policy extends Model {
  get restAttributes() {
    return [
      'uuid',
      'name',
      'default',
      'basicSupervisorEntries',
      'detailedSupervisorEntries',
      'kioskEnabled',
      'timeClockEnabled',
      'photoIdentificationMode',
      'clockInTemplateUuid',
      'clockOutTemplateUuid',
      'requireCostCodes',
      'allowEmployeeCostCodeSelection',
      'allowEmployeeCrewSelection',
      'overtimeRuleSet',
      'roundingRule',
      'teamMemberCount',
      'createdBy',
      'updatedBy',
      'createdTimestamp',
      'updatedTimestamp',
      'workerUuids',
      'breakTypes',
      'payTypes',
      'shifts',
      'breakSetting'
    ];
  }

  idAttribute() {
    return 'uuid';
  }

  @action.bound parse(attributes) {
    if (attributes.overtimeRuleSet) {
      this.parseRules(attributes);
    }

    return {
      ...omit(attributes, 'overtimeRuleSet')
    };
  }

  parseRules(attributes) {
    this.overtimeRuleSet = {
      ...attributes.overtimeRuleSet,
      ...{ rules: orderBy(attributes.overtimeRuleSet.rules, ['order']) }
    };
  }

  @computed get formValues() {
    return {
      uuid: this.uuid,
      name: this.name,
      default: Boolean(this.default),
      basicSupervisorEntries: this.basicSupervisorEntries,
      detailedSupervisorEntries: this.detailedSupervisorEntries,
      kioskEnabled: this.kioskEnabled,
      timeClockEnabled: this.timeClockEnabled,
      photoIdentificationMode: this.photoIdentificationMode,
      clockInTemplateUuid: this.clockInTemplateUuid,
      clockOutTemplateUuid: this.clockOutTemplateUuid,
      requireCostCodes: this.requireCostCodes,
      allowEmployeeCostCodeSelection: this.allowEmployeeCostCodeSelection,
      allowEmployeeCrewSelection: this.allowEmployeeCrewSelection,
      overtimeRuleSet: this.overtimeRuleSet,
      roundingRule: this.roundingRule,
      teamMemberCount: this.teamMemberCount,
      breakSetting: this.breakSetting
    };
  }

  @computed get settingsFormValues() {
    return {
      name: this.name,
      isDefault: Boolean(this.default),
      basicSupervisorEntries: this.basicSupervisorEntries,
      detailedSupervisorEntries: this.detailedSupervisorEntries,
      kioskEnabled: this.kioskEnabled,
      timeClockEnabled: this.timeClockEnabled,
      photoIdentificationMode: this.photoIdentificationMode,
      clockInTemplateUuid: this.clockInTemplateUuid,
      clockOutTemplateUuid: this.clockOutTemplateUuid,
      requireCostCodes: this.requireCostCodes,
      allowEmployeeCostCodeSelection: this.allowEmployeeCostCodeSelection,
      allowEmployeeCrewSelection: this.allowEmployeeCrewSelection,
      breakSetting: this.breakSetting
    };
  }

  get urlRoot() {
    return `${this.rootStore.urlMicroService(
      'performanceTracking'
    )}/companies/${this.rootStore.me.company.uuid}/timepolicies`;
  }

  @computed get viewUrl() {
    return `/company/time/policies/${this.uuid}`;
  }

  @computed get createdByFullName() {
    return `${this.createdBy.firstName} ${this.createdBy.lastName}`;
  }

  @computed get updatedByFullName() {
    return `${this.updatedBy.firstName} ${this.updatedBy.lastName}`;
  }

  @computed get createdDateFormatted() {
    return moment(this.createdTimestamp).format('MMM D, h:mm A');
  }

  @computed get updatedDateFormatted() {
    return moment(this.updatedTimestamp).format('MMM D, h:mm A');
  }

  @computed get enabledPayTypes() {
    return this.payTypes.filter(payType => {
      return payType.status === 'ACTIVE';
    });
  }

  @computed get enabledShifts() {
    return this.shifts.filter(shift => {
      return shift.status === 'ACTIVE';
    });
  }

  @computed get hasEnabledShifts() {
    return this.enabledShifts.length > 0;
  }

  @computed get formattedBreakTypes() {
    return observable(
      this.breakTypes.map(breakType => {
        let formattedName = ``;

        if (breakType.type === 'REST') {
          formattedName = t(`Rest break`);
        }

        if (breakType.type === 'MEAL') {
          formattedName = t(`Meal break`);
        }

        return { ...breakType, formattedName };
      })
    );
  }
}

import moment from 'moment-timezone';

// Function to format input into 'HH:mm'
export default input => {
  let formattedTime;

  if (typeof input === 'object' && input !== null) {
    // Handle object case
    const { hour, minute, period } = input;
    formattedTime = moment(`${hour}:${minute} ${period}`, 'hh:mm A').format(
      'HH:mm'
    );
  } else if (typeof input === 'string') {
    // Handle string case
    formattedTime = moment(input, 'hh:mmA').format('HH:mm');
  } else {
    throw new Error('Invalid input format. Expected an object or string.');
  }

  return formattedTime;
};

// Function to parse a formatted time string ('HH:mm') into an object
export const parseTimeToObject = timeString => {
  if (typeof timeString !== 'string' || !/^\d{2}:\d{2}$/.test(timeString)) {
    throw new Error('Invalid time string format. Expected "HH:mm".');
  }

  // Split the time string into hour and minute
  const [hour, minute] = timeString.split(':').map(Number);

  return {
    hour: hour,
    minute: String(minute).padStart(2, '0')
  };
};

import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { reaction, computed } from 'mobx';

const timesheetsReportFormPlugins = { dvr: validatorjs };

const timesheetsReportFormOptions = {
  validateOnInit: true,
  validateOnChange: true
};

const timesheetsReportFormFields = [
  'projects',
  'projectIncludeOption',
  'emails',
  'email',
  'includeCostCode',
  'name',
  'time',
  'from',
  'to',
  'actionDayOfWeek',
  'actionDayOfMonth',
  'projectFilters',
  'recentPayPeriodFilter',
  'specificPayPeriodFilter',
  'payPeriodFilterStatus',
  'specificPayYear',
  'specificPayYear',
  'specificPayMonth'
];

const timesheetsReportFormRules = {
  emails: 'array|required',
  email: 'required_without:emails|email',
  from: 'required|string',
  to: 'required|string|maxOneYearAfterCustom:from,YYYY-MM-DD'
};

const timesheetsReportFormValues = {
  projectIncludeOption: 'ACTIVE_AND_INACTIVE',
  projects: [],
  emails: [],
  email: '',
  includeCostCode: true,
  payPeriodFilterStatus: 'RECENT',
  specificPayYear: '',
  specificPayMonth: '',
  specificPayPeriodFilter: { name: '', key: '', value: { from: '', to: '' } }
};

class TimesheetsReportForm extends MobxReactForm {
  constructor(settings, options) {
    super(settings, options);
    this.rootStore = options.rootStore;

    this.reactionProjectFilters = reaction(
      () => this.$('projectFilters').value,
      projectFilters => {
        this.update({
          projects: projectFilters.map(project => {
            return { uuid: project.value };
          })
        });
      }
    );

    this.reactToRecentPeriodFilter = reaction(
      () => this.$('recentPayPeriodFilter').value,
      recentPayPeriodFilter => {
        const payPeriodFilterStatus =
          recentPayPeriodFilter.key === 'SPECIFIC' ? 'SPECIFIC' : 'RECENT';

        this.update({
          payPeriodFilterStatus: payPeriodFilterStatus,
          from: recentPayPeriodFilter.value.from,
          to: recentPayPeriodFilter.value.to,
          ...(payPeriodFilterStatus === 'RECENT' && {
            specificPayYear: '',
            specificPayMonth: '',
            specificPayPeriodFilter: { name: '', value: { from: '', to: '' } }
          })
        });
      }
    );

    this.reactToSpecificPeriodFilter = reaction(
      () => this.$('specificPayPeriodFilter').value,
      specificPayPeriodFilter => {
        const payPeriodFilterStatus = this.$('payPeriodFilterStatus').value;
        if (payPeriodFilterStatus !== 'RECENT') {
          this.update({
            from: specificPayPeriodFilter.value.from,
            to: specificPayPeriodFilter.value.to
          });
        }
      }
    );

    this.reactToProjectIncludeOption = reaction(
      () => this.$('projectIncludeOption').value,
      projectIncludeOption => {
        if (projectIncludeOption === 'ALL')
          this.update({
            projects: []
          });
      }
    );
  }

  tearDownReactions() {
    this.reactToSpecificPeriodFilter && this.reactToSpecificPeriodFilter();
    this.reactToRecentPeriodFilter && this.reactToRecentPeriodFilter();
    this.reactionProjectFilters && this.reactionProjectFilters();
  }

  @computed
  get hasSelectedProjects() {
    return this.$('projects').value.length > 0;
  }

  @computed
  get projectFiltersIsValid() {
    if (this.$('projectIncludeOption').value === 'ACTIVE_AND_INACTIVE')
      return true;
    if (
      this.$('projectIncludeOption').value === 'SELECTED' &&
      this.hasSelectedProjects
    )
      return true;

    return false;
  }
}

export {
  TimesheetsReportForm,
  timesheetsReportFormRules,
  timesheetsReportFormFields,
  timesheetsReportFormOptions,
  timesheetsReportFormPlugins,
  timesheetsReportFormValues
};

import { observable, action, computed } from 'mobx';
import UIStore from '../../UIStore';
import history from 'utils/history';
import moment from 'moment-timezone';

import request from 'axios';

import { t } from 'utils/translate';

import {
  CompanyEquipmentForm,
  companyEquipmentFormOptions,
  companyEquipmentFormFields,
  companyEquipmentFormRules,
  companyEquipmentFormLabels,
  companyEquipmentFormPlugins
} from 'forms/companyEquipment';

import overrideValidationMessages from 'forms/utils/overrideValidationMessages';

import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { EQUIPMENT_EDITED } from 'utils/segmentAnalytics/eventSpec';

export default class EquipmentInfoUI extends UIStore {
  @observable entryEditForm;
  @observable savingEquipmentCondition;

  constructor(options) {
    super(options);

    this.entryEditForm = null;
    this.nextUrl = null;

    this.savingEquipmentCondition = false;
  }

  @computed get entryForEdit() {
    return this.parent.entryForEdit;
  }

  @action.bound setup(uuid) {
    this.projectSelectorUI.setup({
      projectStates: ['ACTIVE']
    });

    this.setEntryEditForm();
  }

  @action.bound setEntryEditForm() {
    this.entryEditForm = new CompanyEquipmentForm(
      {
        fields: companyEquipmentFormFields,
        rules: companyEquipmentFormRules,
        labels: companyEquipmentFormLabels,
        values: {
          ...this.entryForEdit.formValues,
          deployedStartDate: this.entryForEdit.formValues.deployment?.startDate,
          deployedEndDate: this.entryForEdit.formValues.deployment?.endDate,
          projectUuid: this.entryForEdit.formValues.deployment?.projectUuid,
          deploymentUuid: this.entryForEdit.formValues.deployment?.uuid
        }
      },
      {
        options: companyEquipmentFormOptions,
        plugins: overrideValidationMessages(companyEquipmentFormPlugins, {
          required_if: t('A :attribute is required when deploying equipment.')
        }),
        rootStore: this.rootStore
      }
    );

    this.entryEditForm.$('projectUuid').observe(({ form, field, change }) => {
      /**
       * If the project is changed from the existing one we want to set deployedStartDate to todays date
       * and clear deployedEndDate
       */

      form.$('deployedStartDate').set(moment().format(`YYYY-MM-DD`));
      form.$('deployedEndDate').clear();
      form.$('deploymentUuid').clear();
    });

    this.blockHistoryIfFormChanged();
  }

  @action.bound resetForm() {
    this.clearValidationDetails();
    this.setEntryEditForm();
  }

  @action.bound tearDown() {
    this.clearUIState();
    this.unblockHistory();
    this.projectSelectorUI.tearDown();
  }

  @action clearUIState() {
    this.clearValidationDetails();
    this.entryEditForm = null;
    this.savingEquipmentCondition = false;
    this.saving = false;
  }

  @action.bound blockHistoryIfFormChanged() {
    this.unblock = history.block((location, action) => {
      if (this.entryEditForm.check('isDirty')) {
        this.showDiscardModal(location.pathname);
        return 'Blocked';
      }
    });
  }

  @computed get hasWriteAccess() {
    return this.parent.hasWriteAccess;
  }

  @computed get ownershipOptions() {
    return this.parent.ownershipOptions;
  }

  @computed get yearOptions() {
    return this.parent.yearOptions;
  }

  @computed get selectedYearOption() {
    return this.yearOptions.find(
      option => option.value === String(this.entryEditForm.$('year').value)
    );
  }

  @computed get conditionOptions() {
    return this.parent.conditionOptions;
  }

  @computed get selectedConditionOption() {
    return this.conditionOptions.find(
      option => option.value === this.entryEditForm.$('condition').value
    );
  }

  @computed get equipmentTypeOptions() {
    return this.parent.equipmentTypeOptions;
  }

  @computed get selectedEquipmentTypeOption() {
    return this.equipmentTypeOptions.find(option => {
      return option.value === this.entryEditForm.$('type').value;
    });
  }

  @computed get equipmentMakeOptions() {
    return this.parent.equipmentMakeOptions;
  }

  @computed get selectedEquipmentMakeOption() {
    return this.equipmentMakeOptions.find(option => {
      return option.value === this.entryEditForm.$('make').value;
    });
  }

  @computed get odometerOptions() {
    return this.parent.odometerOptions;
  }

  @computed get selectedOdometerOption() {
    return this.odometerOptions.find(
      option => option.value === this.entryEditForm.$('odometerType').value
    );
  }

  @computed get fuelOptions() {
    return this.parent.fuelOptions;
  }

  @computed get selectedFuelOption() {
    return this.fuelOptions.find(
      option => option.value === this.entryEditForm.$('fuelGaugeType').value
    );
  }

  @computed get frequencyOptions() {
    return this.parent.frequencyOptions;
  }

  @computed get selectedFrequencyOption() {
    return this.frequencyOptions.find(
      option => option.value === this.entryEditForm.$('frequency').value
    );
  }

  @computed get refetchEquipment() {
    return this.parent.refetchEquipment;
  }

  @action.bound
  setOwnershipOption(value) {
    this.entryEditForm.$('owned').set('value', value === 'owned');
  }

  @action.bound
  async submitEquipmentForm(e) {
    e.preventDefault();

    await this.rootStore.authorizationUI.checkFeatureAccess(
      'CRUDOwnedEquipment'
    );

    this.entryEditForm.submit({
      onSuccess: this.submitEquipmentEditFormSuccess,
      onError: e => {
        console.error(this.entryEditForm.errors());
      }
    });
  }

  @action.bound
  async submitEquipmentEditFormSuccess() {
    this.saving = true;
    this.clearValidationDetails();
    try {
      const {
        deployedStartDate,
        deployedEndDate,
        projectUuid,
        deploymentUuid,
        photo,
        supplier,
        rate,
        returnDate,
        frequency,
        owned,
        ...equipmentValues
      } = this.entryEditForm.values();

      const rentalDetails = {
        supplier: supplier || null,
        rate: rate || null,
        returnDate: returnDate || null,
        frequency: frequency || null
      };

      const deployment = projectUuid
        ? {
            projectUuid,
            uuid: deploymentUuid || null,
            startDate: deployedStartDate || '',
            endDate: deployedEndDate || ''
          }
        : null;

      await this.entryForEdit.save(
        {
          ...equipmentValues,
          deployment,
          owned,
          photo:
            photo && this.entryEditForm.$('photo').isDirty
              ? { base64: photo }
              : null,
          ...(!owned
            ? rentalDetails
            : {
                supplier: null,
                rate: null,
                returnDate: null,
                frequency: null
              })
        },
        {
          wait: true
        }
      );

      callTrack(EQUIPMENT_EDITED, {
        equipment_type: equipmentValues.owned ? 'Owned' : 'Rented',
        supplier_entered: Boolean(supplier),
        mileage_tracking_on: equipmentValues.trackMileage,
        fuel_tracking_on: equipmentValues.trackFuel
      });

      this.refetchEquipment();
      this.resetForm();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Equipment saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound
  onChangeCondition(e, option) {
    this.entryEditForm.update({
      condition: option?.value || ''
    });

    if (option?.value === 'UNDER_MAINTENANCE') {
      this.showModal('EquipmentConditionModal');
    }
  }

  @action.bound
  async saveEquipmentConditionModal(e) {
    e?.preventDefault();

    this.savingEquipmentCondition = true;

    await request.post(`${this.entryForEdit.url()}/maintenance`, {
      endDate: this.entryEditForm.$('endDate').value
        ? moment(this.entryEditForm.$('endDate').value).format(`YYYY-MM-DD`)
        : ''
    });

    await this.hideActiveModal();

    this.savingEquipmentCondition = false;
  }

  @action.bound
  cancelEquipmentConditionModal() {
    this.entryEditForm.$('condition').reset();
    this.entryEditForm.$('endDate').reset();

    this.hideActiveModal();
  }

  @action.bound
  onChangeDeployedProject(e, projectOption) {
    this.entryEditForm.update({
      projectUuid: projectOption?.value
    });
    if (projectOption?.value) {
      this.entryEditForm.$('condition').set('DEPLOYED');
    } else {
      this.entryEditForm.$('condition').reset();
      this.entryEditForm.$('deployedStartDate').reset();
      this.entryEditForm.$('deployedEndDate').reset();
    }
  }
}

import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateTimeCaptureForm,
  projectTemplateTimeCaptureFormOptions,
  projectTemplateTimeCaptureFormFields,
  projectTemplateTimeCaptureFormRules,
  projectTemplateTimeCaptureFormPlugins,
  projectTemplateTimeCaptureFormLabels
} from 'forms/projectTemplates/projectTemplateTimeCapture';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

import alertErrorHandler from 'utils/alertErrorHandler';

import ChecklistTemplates from 'stores/collections/checklists/ChecklistTemplates';

import { COMPANY_PROJECT_TEMPLATES_VIEWED } from 'utils/segmentAnalytics/eventSpec';

import { callTrack } from 'utils/segmentIntegration';

export default class ProjectTemplateTimeCaptureUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template time capture saved');

    // ChecklistTemplates collection
    this.checklistTemplates = new ChecklistTemplates(null, {
      parent: this,
      rootStore: this.rootStore
    });
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound async setup() {
    this.setupForm();
    this.blockHistoryIfFormChanged();

    if (this.authorization.hasTimeClockAccess) {
      await this.fetchChecklistTemplates();
      callTrack(COMPANY_PROJECT_TEMPLATES_VIEWED);
    }
  }

  @action.bound tearDown() {
    this.form = null;
    this.checklistTemplates.clear();
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateTimeCaptureForm(
      {
        fields: projectTemplateTimeCaptureFormFields,
        rules: projectTemplateTimeCaptureFormRules,
        labels: projectTemplateTimeCaptureFormLabels,
        values: this.selectedTemplate.timeCaptureValues
      },
      {
        options: projectTemplateTimeCaptureFormOptions,
        plugins: projectTemplateTimeCaptureFormPlugins
      }
    );
  }

  @computed get mapCenter() {
    if (this.company.address?.streetAddress && this.company.hasGeoLocation) {
      return this.company.address.geolocation;
    }

    return this.rootStore.userLocation?.geolocation;
  }

  @computed
  get timeCaptureEnabled() {
    return this.selectedTemplate.timeCardsStartAndEndTime;
  }

  @action.bound async fetchChecklistTemplates() {
    try {
      await this.checklistTemplates.fetch({
        url: `${this.rootStore.urlMicroService('toolbox')}/companies/${
          this.rootStore.me.company.uuid
        }/checklist-templates/checklist-types/${
          this.rootStore.appConfig.timeClockChecklistType
        }`,
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed
  get disableFeatureCheckBoxes() {
    if (this.disableTimeClock && this.disableKiosk) return true;
    if (
      !this.form.$('enableKiosk').value &&
      !this.form.$('enableTimeClock').value
    )
      return true;

    return false;
  }

  @computed get requirePhotoIdOptions() {
    return [
      { title: t('No photo ID required'), value: 'NOT_REQUIRED' },
      { title: t('Clock in and out'), value: 'REQUIRED_ON_CLOCK_IN_AND_OUT' },
      {
        title: t('Clock in and out; Start and end breaks'),
        value: 'REQUIRED_ON_CLOCK_IN_AND_OUT_AND_BREAKS'
      }
    ];
  }

  @computed get requirePhotoIdOptionValue() {
    return this.requirePhotoIdOptions.find(
      option =>
        option.value ===
        this.form.$('requirePhotoIdOnClockInOutAndBreaks').value
    );
  }

  @computed get requireClockInQuestionsOptions() {
    return [
      { title: t('Clock in questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockInQuestionsValue() {
    return this.requireClockInQuestionsOptions.find(
      option =>
        option.value === this.form.$('clockInChecklistTemplateUuid').value
    );
  }
  @computed get requireClockOutQuestionsOptions() {
    return [
      { title: t('Clock out questions not required'), value: '' },
      ...this.checklistTemplates.models.map(template => {
        return { title: template.name, value: template.uuid };
      })
    ];
  }
  @computed get clockOutQuestionsValue() {
    return this.requireClockOutQuestionsOptions.find(
      option =>
        option.value === this.form.$('clockOutChecklistTemplateUuid').value
    );
  }

  @computed get hasWriteAccessKiosk() {
    return this.authorization.canModifyKioskSettings && this.timeCaptureEnabled;
  }

  @computed get disableKiosk() {
    return !this.hasWriteAccessKiosk;
  }

  @computed get hasWriteAccessTimeClock() {
    return (
      this.authorization.canModifyTimeClockSettings && this.timeCaptureEnabled
    );
  }

  @computed get disableTimeClock() {
    return !this.hasWriteAccessTimeClock;
  }
}

import request from 'axios';
import debounce from 'lodash.debounce';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, reaction } from 'mobx';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import UIStore from 'stores/ui/UIStore';
import SageIntacctEmployees from 'stores/collections/integrations/SageIntacctEmployees';

import alertErrorHandler from 'utils/alertErrorHandler';

import history from 'utils/history';
import formatIntegrationSyncMessage from 'utils/formatIntegrationSyncMessage';

import { t } from 'utils/translate';

export default class IntegrationSageIntacctEmployeeImportUI extends UIStore {
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;

  constructor(options) {
    super(options);

    this.sageIntacctEmployees = new SageIntacctEmployees(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.loading = true;

    this.selectedEmployees = observable([]);

    this.searchQuery = '';

    this.fetchEmployeesDebounced = debounce(this.fetchEmployees, BASE_DEBOUNCE);
  }

  @computed get activeCompany() {
    return this.parent.activeCompany;
  }

  @computed get employees() {
    return this.sageIntacctEmployees;
  }

  @computed get hasEmployees() {
    return this.employees.hasModels;
  }

  @action.bound setup() {
    this.setupReactions();
    this.fetchEmployees();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        this.fetchEmployees();
      }
    );

    this.reactToSearchQuery = reaction(
      () => this.searchQuery,
      params => {
        this.fetchEmployeesDebounced();
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
    this.reactToSearchQuery && this.reactToSearchQuery();
  }

  @action.bound
  async fetchEmployees() {
    this.loading = true;

    await this.employees.fetch({
      params: pickBy(
        Object.assign(this.params, {
          query: this.searchQuery
        }),
        identity
      )
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
  }

  @computed get params() {
    return {
      limit: 50,
      unlinkedOnly: true
    };
  }

  @computed
  get totalPages() {
    return Math.ceil(this.employees.totalElements / this.pageSize);
  }

  @computed get hasSelectedEmployees() {
    return this.selectedEmployees.length > 0;
  }

  @action.bound
  toggleSelectEmployee(projectId) {
    if (this.selectedEmployees.find(id => id === projectId)) {
      this.selectedEmployees.remove(projectId);
    } else {
      this.selectedEmployees.push(projectId);
    }
  }

  @computed
  get allEmployeesSelected() {
    return (
      this.hasEmployees &&
      this.employees.models.every(employee =>
        this.selectedEmployees.includes(employee.id)
      )
    );
  }

  @action.bound
  toggleSelectAllEmployees() {
    if (this.allEmployeesSelected) {
      this.selectedEmployees.replace(
        this.selectedEmployees.filter(id => {
          return !this.employees.models
            .map(employee => employee.id)
            .includes(id);
        })
      );
    } else {
      this.employees.models.forEach(employee => {
        if (!this.selectedEmployees.includes(employee.id)) {
          this.selectedEmployees.push(employee.id);
        }
      });
    }
  }

  @computed get someEmployeesSelected() {
    return this.hasSelectedEmployees && !this.allEmployeesSelected;
  }

  @action.bound clearAllSelectedEmployees() {
    this.selectedEmployees.clear();
  }

  @computed get disableCancelButton() {
    return this.saving;
  }

  @computed get disableSaveButton() {
    return this.saving;
  }

  @action.bound async confirmImportSageIntacctEmployees(
    options = { all: false }
  ) {
    if (this.saving) return;

    this.saving = true;

    this.clearValidationDetails();

    try {
      const { data } = await request.post(`${this.employees.url()}/sync`, {
        externalIds: options.all ? ['*'] : this.selectedEmployees.slice()
      });

      if (options.all) {
        this.notifications.pushNotification({
          snackbar: 'warning',
          icon: 'checkmark',
          title: t('Employee import started')
        });
      } else {
        const successfulCount = data.collection.filter(
          syncResult => syncResult.successful === true
        ).length;

        const failedCount = data.collection.length - successfulCount;

        const title = formatIntegrationSyncMessage(
          'Employee import',
          successfulCount,
          failedCount
        );

        const snackbar = successfulCount > 0 ? 'warning' : 'error';

        this.notifications.pushNotification({
          snackbar,
          icon: 'checkmark',
          title
        });
      }

      this.parent.showImportAlert = true;

      this.cancelImportSageIntacctEmployees();
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound async cancelImportSageIntacctEmployees() {
    if (this.isSuperAdmin) {
      history.push(
        `/companies/${this.activeCompany.uuid}/integrations/1024/employees`
      );
    } else {
      history.push('/company/integrations/1024/employees');
    }
  }

  @action.bound clearUIState() {
    this.loading = true;
    this.searchQuery = '';
    this.selectedEmployees.clear();
    this.employees.clear();
    this.clearValidationDetails();
  }
}

import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';

const scheduledTalksFormPlugins = { dvr: validatorjs };

const scheduledTalksFormOptions = {
  validateOnInit: false,
  validateOnChange: true
};

const scheduledTalksFormFields = [
  'scheduledTalks[]',
  'scheduledTalks[].projectName',
  'scheduledTalks[].uuid',
  'scheduledTalks[].scheduleDate',
  'scheduledTalks[].fromDate',
  'scheduledTalks[].groups[]'
];

const scheduledTalksFormRules = {
  'scheduledTalks[].projectName': 'string|required',
  'scheduledTalks[].scheduleDate': 'string|required|afterOrTodayDate:YYYY-MM-DD'
};

class ScheduledTalksForm extends MobxReactForm {}

export {
  ScheduledTalksForm,
  scheduledTalksFormOptions,
  scheduledTalksFormFields,
  scheduledTalksFormRules,
  scheduledTalksFormPlugins
};

import UIStore from '../UIStore';
import { action, computed, runInAction, observable } from 'mobx';
import WorkLogWorkersUI from './WorkLogWorkersUI';
import escaperegexp from 'lodash.escaperegexp';
import TimeCard from 'stores/models/workLogs/TimeCard';

import {
  TimeEntriesWorkLogForm,
  timeEntriesWorkLogFormRules,
  timeEntriesWorkLogFormFields,
  timeEntriesWorkLogFormLabels,
  timeEntriesWorkLogFormOptions,
  timeEntriesWorkLogFormPlugins
} from 'forms/timeEntriesWorkLog';

import OverTimeRule from 'stores/models/OverTimeRule';
import Policies from 'stores/collections/time/Policies';

import debounce from 'lodash.debounce';
import { BASE_DEBOUNCE } from 'fixtures/constants';

import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import alertErrorHandler from 'utils/alertErrorHandler';
import request from 'axios';
import { t } from 'utils/translate';

import TimeEntriesWorkLogBulkEditUI from './TimeEntriesWorkLogBulkEditUI';

export default class TimeEntriesWorkLogUI extends UIStore {
  @observable timeEntriesWorkLogForCreation = null;
  @observable timeEntriesWorkLogForEdit = null;
  @observable timeEntriesWorkLogForm = null;
  @observable timeCardForDeleting = null;
  @observable timeCardForm;
  @observable workersWeekTotalHours = [];
  @observable workerTotalHoursAreFetching = true;

  //Pagination
  @observable workLogLoading = false;
  @observable workLogPage = 1;
  @observable timeCardsLoading = false;
  @observable timeCardSearchQuery = '';
  @observable timeCardSearchInputValue = '';
  @observable savingTimeCards = false;

  constructor(options) {
    super(options);

    //Default settings
    this.worklogPageSize = 20;

    this.workLogWorkersUI = new WorkLogWorkersUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.overtimeRule = new OverTimeRule(null, {
      rootStore: this.rootStore
    });

    this.policies = new Policies(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.timeEntriesWorkLogBulkEditUI = new TimeEntriesWorkLogBulkEditUI({
      rootStore: this.rootStore,
      parent: this
    });

    this.setTimeCardSearchQuery = debounce(
      this.setTimeCardSearchQuery,
      BASE_DEBOUNCE
    );
  }

  pushTimeCardUrl(workLog) {
    return this.parent.pushTimeCardUrl(workLog);
  }

  @computed
  get workLogs() {
    return this.parent.workLogs;
  }

  @computed
  get currentTimeEntriesWorkLog() {
    return this.timeEntriesWorkLogForCreation || this.timeEntriesWorkLogForEdit;
  }

  @computed
  get currentTimeEntriesWorkLogDate() {
    return this.workLogParams.date;
  }

  @action.bound
  initTimeEntriesWorkLogForm() {
    const workLogFormValues = this.currentTimeEntriesWorkLog.formValues;
    //Set default new work log name === worker name if there is only one time card
    if (
      this.selectedWorkers.length === 1 &&
      this.timeEntriesWorkLogForCreation
    ) {
      workLogFormValues.name = this.selectedWorkers[0].fullName;
    }

    this.timeEntriesWorkLogForm = new TimeEntriesWorkLogForm(
      {
        fields: timeEntriesWorkLogFormFields,
        rules: timeEntriesWorkLogFormRules,
        labels: timeEntriesWorkLogFormLabels,
        values: workLogFormValues,
        timeCards: this.currentTimeEntriesWorkLog.timeCards.models
      },
      {
        options: timeEntriesWorkLogFormOptions,
        plugins: timeEntriesWorkLogFormPlugins,
        rootStore: this.rootStore,
        timeEntriesWorkLogUI: this,
        currentTimeEntriesWorkLogDate: this.currentTimeEntriesWorkLogDate
      }
    );
  }

  @action.bound
  async cancelTimeEntriesWorkLogCreationOrEditing() {
    await this.hideActiveModal();

    this.activeModalPage = null;
    this.timeEntriesWorkLogForm = null;
    this.timeEntriesWorkLogForCreation = null;
    this.timeEntriesWorkLogForEdit = null;
    this.workLogPage = 1;
    this.timeCardSearchQuery = '';
    this.timeCardSearchInputValue = '';
    this.workersWeekTotalHours.clear();
    this.policies.clear();
    this.savingTimeCards = false;
    this.workLogLoading = false;

    // Remove the last url segment if it is the worklog id
    this.parent.removeLastUrlSegment();

    // Tear down the selectors
    this.tearDownSelectors();
  }

  @action.bound
  closeWorkLog() {
    //prevent closing when data updating or saving
    if (
      this.workLogLoading ||
      this.timeCardsLoading ||
      this.uploadRunning ||
      this.savingTimeCards
    )
      return;

    if (!this.timeEntriesWorkLogForm.workLogIsPristine) {
      this.activeModal = 'DiscardChangesModal';
    } else {
      this.cancelTimeEntriesWorkLogCreationOrEditing();
    }
  }

  @computed
  get uploadRunning() {
    return (
      this.currentTimeEntriesWorkLog?.hasAttachmentsUploading ||
      this.currentTimeEntriesWorkLog?.timeCards.models.find(
        timeCard => timeCard.hasAttachmentsUploading
      ) ||
      false
    );
  }

  @computed
  get currentTimeCardPolicyUuids() {
    const timeCards = this.currentTimeEntriesWorkLog?.timeCards;

    if (!timeCards?.models) {
      return [];
    }

    const uuids = timeCards.models
      .filter(model => model?.worker?.settings?.workerDefaultTimePolicy)
      .map(model => model.worker.settings.workerDefaultTimePolicy);

    // Remove duplicates using Set
    return [...new Set(uuids)];
  }

  @action.bound
  async fetchTimePolicies(uuids = []) {
    const unFetchedUuids = [...this.currentTimeCardPolicyUuids, ...uuids];

    if (
      unFetchedUuids.length === 0 &&
      this.policies.models.find(model => model.default)
    )
      return;

    try {
      await this.policies.fetchWithPost({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${
          this.rootStore.me.company.uuid
        }/timepolicies/byUuids?includeDefault=true`,
        params: unFetchedUuids
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  fetchOvertimeRules(projectUuid) {
    return new Promise((resolve, reject) => {
      // get overtime rule uuid by project uuid
      request
        .get(`/ra/projects/${projectUuid}/overtimerules`)
        .then(response => {
          // if there is no rules assigned to the project, fetch a default rule uuid
          if (!response.data.uuid) {
            request
              .get(
                `${this.rootStore.urlMicroService(
                  'performanceTracking'
                )}/overtimerules/default`
              )
              // if there the rule by default rule uuid
              .then(response => {
                this.overtimeRule
                  .fetchByRuleSetId(response.data.uuid)
                  .then(() => {
                    resolve();
                  });
              })
              .catch(error => reject(error));
          } else {
            // if there the rule by assigned rule uuid
            this.overtimeRule
              .fetchByRuleSetId(response.data.uuid)
              .then(() => {
                resolve();
              })
              .catch(error => reject(error));
          }
        })
        .catch(error => reject(error));
    });
  }

  @action.bound
  showWorkLogLoadingState(delay = 100, initial) {
    const requests = [
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      })
    ];

    if (initial) {
      this.workLogLoading = true;
      requests.push(this.setupSelectors());
      if (
        this.featureFlags.FF_SETUP_TIME_POLICIES &&
        this.company.preferences.timePolicyStatus === 'ENABLED'
      ) {
        requests.push(this.fetchTimePolicies());
      } else {
        requests.push(this.fetchOvertimeRules(this.project.uuid));
      }
    } else {
      this.timeCardsLoading = true;
    }

    return Promise.all(requests).then(() => {
      if (initial) {
        this.workLogLoading = false;
      } else {
        this.timeCardsLoading = false;
      }
    });
  }

  @computed
  get sortedTimeCards() {
    return orderBy(
      this.timeEntriesWorkLogForm.timeCards,
      ['workerFullName'],
      ['asc']
    );
  }

  @computed get hasTimeCards() {
    return this.timeEntriesWorkLogForm?.timeCards.length > 0;
  }

  @computed
  get searchedTimeCards() {
    if (!this.timeCardSearchQuery) {
      return this.sortedTimeCards;
    }

    const searchExpression = new RegExp(
      escaperegexp(this.timeCardSearchQuery.trim()),
      'i'
    );

    const timeCardFilter = timeCard => {
      const workerFullName = timeCard.workerFullName;
      const classificationNames = timeCard.timeEntriesClassificationNames;

      return (
        (workerFullName && workerFullName.search(searchExpression) !== -1) ||
        (classificationNames?.length &&
          classificationNames.find(
            classificationName =>
              classificationName?.search(searchExpression) !== -1
          ))
      );
    };
    return this.sortedTimeCards.filter(timeCardFilter);
  }

  @computed
  get noSearchedTimeCards() {
    return this.searchedTimeCards.length === 0;
  }

  @computed
  get paginatedTimeCards() {
    if (this.timeEntriesWorkLogForm) {
      if (
        this.featureFlags.FF_SETUP_TIME_POLICIES &&
        this.company.preferences.timePolicyStatus === 'ENABLED'
      ) {
        const grouped = groupBy(
          this.searchedTimeCards.slice(
            (this.workLogPage - 1) * this.worklogPageSize,
            this.workLogPage * this.worklogPageSize
          ),
          'policy.name'
        );

        const orderedKeys = Object.keys(grouped).sort();

        return orderedKeys.reduce((acc, key) => {
          acc[key] = grouped[key];
          return acc;
        }, {});
      } else {
        return this.searchedTimeCards.slice(
          (this.workLogPage - 1) * this.worklogPageSize,
          this.workLogPage * this.worklogPageSize
        );
      }
    }
    return [];
  }

  @computed
  get workLogTotalPages() {
    return Math.ceil(this.searchedTimeCards.length / this.worklogPageSize);
  }

  @computed
  get showTimeCardsPagination() {
    return this.workLogTotalPages > 1;
  }

  @action.bound
  setWorklogPage(page) {
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.workLogPage = page;
        window.scrollTo(0, 0);
      });
    });
  }

  @computed
  get selectedWorkers() {
    return this.workLogWorkersUI.selectedWorkers;
  }

  @action.bound
  addWorkersToWorkLog() {
    if (this.currentTimeEntriesWorkLog) {
      this.addTimeCardsToTimeEntriesWorkLogForm();
    } else {
      this.startTimeEntriesWorkLog();
    }
  }

  @action.bound async fetchTimePoliciesForAddingWorkers() {
    const selectedWorkerPolcies = this.selectedWorkers
      .filter(worker => worker?.settings?.workerDefaultTimePolicy)
      .map(worker => worker.settings.workerDefaultTimePolicy);

    const timeCards = this.timeEntriesWorkLogForm?.timeCards;

    const timeCardUuids = timeCards
      .filter(timeCard => timeCard?.worker?.settings?.workerDefaultTimePolicy)
      .map(timeCard => timeCard.worker.settings.workerDefaultTimePolicy);

    const uuids = [
      ...new Set(selectedWorkerPolcies),
      ...new Set(timeCardUuids)
    ];

    await this.fetchTimePolicies(uuids);
  }

  @action.bound
  async addTimeCardsToTimeEntriesWorkLogForm() {
    this.timeCardsLoading = true;
    this.workLogWorkersUI.closeSearchAndSelectWorkerModal();

    this.workLogPage = 1;

    if (
      this.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.company.preferences.timePolicyStatus === 'ENABLED'
    ) {
      await this.fetchTimePoliciesForAddingWorkers();
    }

    const workerUuids = this.selectedWorkers.map(worker => worker.workerUuid);
    this.fetchWorkersWeekTotalHours(workerUuids, this.workLogParams.date);

    //Set default new work log name === worker name if there is only one time card
    if (
      this.selectedWorkers.length === 1 &&
      this.timeEntriesWorkLogForCreation
    ) {
      this.timeEntriesWorkLogForm
        .$('name')
        .set(this.selectedWorkers[0].fullName);
    }

    this.selectedWorkers.forEach(worker => {
      const timeCard = new TimeCard(
        {
          worker: worker
        },
        { rootStore: this.rootStore }
      );

      this.timeEntriesWorkLogForm.addTimeCard(timeCard);
    });
    this.workLogWorkersUI.selectedWorkers.clear();

    this.timeCardsLoading = false;
  }

  @action.bound
  deleteTimeCardRequest(timeCardLog) {
    this.activeModal = 'confirmDeleteTimeCard';
    this.timeCardForDeleting = timeCardLog;
  }

  @action.bound
  confirmDeleteTimeCard() {
    this.hideActiveModal().then(() => {
      this.timeEntriesWorkLogForm.removeTimeCard(this.timeCardForDeleting);
      this.timeCardForDeleting = null;
    });
  }

  @action.bound
  cancelDeleteTimeCard() {
    this.hideActiveModal().then(() => {
      this.timeCardForDeleting = null;
    });
  }

  @computed
  get workLogParams() {
    return this.parent.workLogParams;
  }

  @action.bound
  startSavingProcess() {
    this.savingTimeCards = true;
    this.workLogLoading = true;

    setTimeout(() => {
      this.saveTimeEntriesWorkLog();
    }, 50);
  }

  @computed
  get disableSaveOrCreateButton() {
    return (
      !this.hasTimeCards ||
      !this.timeEntriesWorkLogForm ||
      !this.timeEntriesWorkLogForm.workLogIsValid ||
      this.workLogLoading ||
      this.timeCardsLoading ||
      this.uploadRunning ||
      this.savingTimeCards ||
      this.timeEntriesWorkLogForm
        .hasKioskTimeCardsThatCannotBeSavedDueToBreakRules
    );
  }

  @computed
  get saveButtonText() {
    if (this.uploadRunning) return t('Uploading files');
    if (this.savingTimeCards) return t('Saving ...');
    if (this.timeEntriesWorkLogForEdit) return t('Save');
    return t('Create');
  }

  @action.bound
  openManageBreaksModal(timeCardForm) {
    this.timeCardForm = timeCardForm;
    // save initial break values in case of cancel changes
    this.initialBreaks = [
      ...this.timeCardForm.breaks.map(breaksForm => {
        return { formValues: breaksForm.values() };
      })
    ];
    if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
      this.hideActiveModal().then(() => {
        this.activeModal = 'manageBreaks';
      });
    } else {
      this.activeModal = 'manageBreaks';
    }
  }

  @action.bound
  closeManageBreaksForm() {
    this.hideActiveModal().then(() => {
      //cancel breaks changes
      this.timeCardForm.breaks.clear();
      this.timeCardForm.setBreaks(this.initialBreaks);
      this.initialBreaks = null;
      this.timeCardForm = null;
      if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
        this.activeModal = 'timeEntriesBulkEdit';
      }
    });
  }

  @action.bound
  submitManageBreaksForm(e) {
    e.preventDefault();

    if (!this.timeCardForm.hasEmptyBreaks) {
      for (let breakEntryForm of this.timeCardForm.breaks) {
        breakEntryForm.validate();
      }
    }

    if (this.timeCardForm.breaksAreInvalid) return;

    this.hideActiveModal().then(() => {
      this.initialBreaks = null;
      this.timeCardForm = null;
      if (this.timeEntriesWorkLogBulkEditUI.timeEntriesBulkEditForm) {
        this.activeModal = 'timeEntriesBulkEdit';
      }
    });
  }

  @action.bound
  async fetchWorkersWeekTotalHours(workerUuids, date) {
    this.workerTotalHoursAreFetching = true;
    try {
      const response = await request.post(
        `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/worklogs/weekHours/${date}?type=BOTH`,
        workerUuids
      );

      this.workersWeekTotalHours.push(...response.data);
      this.workerTotalHoursAreFetching = false;
    } catch (error) {
      this.workerTotalHoursAreFetching = false;

      alertErrorHandler(error, this.setValidationDetails);
    }
  }

  @action.bound
  setSearchTimeCardsInput(query) {
    this.timeCardSearchInputValue = query;
    this.setTimeCardSearchQuery(query);
  }

  @action.bound
  setTimeCardSearchQuery(query) {
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.timeCardSearchQuery = query;
        this.workLogPage = 1;
      });
    });
  }

  @action.bound
  clearTimeCardSearchQuery() {
    this.timeCardSearchInputValue = '';
    this.showWorkLogLoadingState().then(() => {
      runInAction(() => {
        this.timeCardSearchQuery = '';
        this.workLogPage = 1;
      });
    });
  }

  @computed
  get showSyncedOrApprovedAlert() {
    if (!this.timeEntriesWorkLogForEdit) return false;
    return (
      this.timeEntriesWorkLogForEdit.hasApprovedTimeCard ||
      this.timeEntriesWorkLogForEdit.hasSyncedTimeCard
    );
  }

  @computed
  get worklogDetailsDisplayedText() {
    return `${this.currentTimeEntriesWorkLogDate} | ${this.project.name} ${
      this.project.projectNo ? `| ${this.project.projectNo}` : ''
    }`;
  }

  @computed
  get classificationsNotEditableOnTimeCards() {
    if (
      this.rootStore.featureFlags.FF_SETUP_TIME_POLICIES &&
      this.company.preferences.timePolicyStatus === 'ENABLED'
    ) {
      return (
        !this.me.isAdmin &&
        this.company.preferences.restrictClassificationsEditsOnTimeCards
      );
    }

    return !this.project?.classificationsEditableOnTimeCards;
  }

  @action.bound setupSelectors() {
    return Promise.all([
      this.costCodeSelectorUI.setup(),
      this.shiftSelectorUI.setup(),
      this.groupSelectorUI.setup(),
      this.classificationSelectorUI.setup(),
      this.payTypeSelectorUI.setup(),
      this.breakSelectorUI.setup()
    ]);
  }

  @action.bound tearDownSelectors() {
    this.costCodeSelectorUI.tearDown();
    this.shiftSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.classificationSelectorUI.tearDown();
    this.payTypeSelectorUI.tearDown();
    this.breakSelectorUI.tearDown();
  }

  @computed get timeCardsAreAllOnTheSamePolicy() {
    return this.policies.models.length === 1;
  }

  @computed get editAllPoliciesSelectOptions() {
    const actions = this.policies.models
      .filter(policy => this.paginatedTimeCards[policy.name])
      .map(policy => {
        return {
          title: `${t('Edit')} ${policy.name} (${
            this.paginatedTimeCards[policy.name]?.length
          })`,
          onClick: () => {
            this.timeEntriesWorkLogBulkEditUI.setPolicyToBulkEdit(policy);
          }
        };
      });

    return actions;
  }

  @action.bound bulkEditSinglePolicy() {
    this.timeEntriesWorkLogBulkEditUI.setPolicyToBulkEdit(
      this.policies.models[0]
    );
  }
}

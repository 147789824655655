import { action, computed, observable } from 'mobx';

import {
  ProjectTemplateInfoForm,
  projectTemplateInfoFormOptions,
  projectTemplateInfoFormFields,
  projectTemplateInfoFormRules,
  projectTemplateInfoFormPlugins,
  projectTemplateInfoFormLabels
} from 'forms/projectTemplates/projectTemplateInfo';

import { t } from 'utils/translate';

import ProjectTemplateChildEditUI from './ProjectTemplateChildEditUI';

export default class ProjectTemplateInfoUI extends ProjectTemplateChildEditUI {
  @observable form;
  constructor(options) {
    super(options);

    this.form = null;

    this.saveTitle = t('Project template info saved');
  }

  @computed get selectedTemplate() {
    return this.parent.selectedTemplate;
  }

  @action.bound setup() {
    this.setupForm();
    this.blockHistoryIfFormChanged();
  }

  @action.bound tearDown() {
    this.form = null;
    this.unblockHistory();
  }

  @action.bound
  setupForm() {
    this.form = new ProjectTemplateInfoForm(
      {
        fields: projectTemplateInfoFormFields,
        rules: Object.assign(projectTemplateInfoFormRules, {
          templateName: `required|string|not_in:${this.existingTemplateNames.join(
            ','
          )}`
        }),
        labels: projectTemplateInfoFormLabels,
        values: this.selectedTemplate.infoFormValues
      },
      {
        options: projectTemplateInfoFormOptions,
        plugins: projectTemplateInfoFormPlugins
      }
    );
  }

  @computed get projectTemplates() {
    return this.parent.projectTemplates;
  }

  @computed get existingTemplateNames() {
    if (this.selectedTemplate) {
      return this.projectTemplates.models
        .filter(template => {
          return template.templateName !== this.selectedTemplate.templateName;
        })
        .map(template => {
          // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
          return `${template.templateName.replace('|', '7C')}`;
        });
    }

    return this.projectTemplates.models.map(template => {
      // Convert Pipe to Hex code as it will break rules when passed to dvr in initCompanyProjectTemplateForm
      return `${template.templateName.replace('|', '7C')}`;
    });
  }
}

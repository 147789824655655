import request from 'axios';

import { action, computed } from 'mobx';

import { t } from 'utils/translate';
import history from 'utils/history';

import TimesheetsEditUI from '../TimesheetsEditUI';
import TimeEntriesWorkLog from 'stores/models/workLogs/TimeEntriesWorkLog';

import alertErrorHandler from 'utils/alertErrorHandler';
import {
  TIMECARD_DELETED,
  TIMECARD_EDITED
} from 'utils/segmentAnalytics/eventSpec';
import sendTimeCardEvent from 'utils/segmentAnalytics/sendTimeCardEvent';

export default class MyTimeTimeCardsEditUI extends TimesheetsEditUI {
  @computed get title() {
    return t('Edit time card');
  }

  @action.bound
  async saveTimeCard(event) {
    event.preventDefault();

    this.savingTimeCards = true;

    if (
      this.timeEntriesForm.showHoursWorked &&
      this.timeEntriesForm.hoursWorked > 0
    ) {
      this.timeEntriesForm.allocateHoursWorked();
    }

    try {
      const url =
        this.rootStore.appConfig.tracking_api_url +
        `/worklogs/timecards/${this.timeCardToEdit.uuid}`;

      const response = await request.patch(
        url,
        this.timeEntriesForm.cleanedValues
      );

      sendTimeCardEvent(
        TIMECARD_EDITED,
        new TimeEntriesWorkLog(
          Object.assign(response.data, {
            timeCards: [response.data.timeCard] // Needed because ths API returns a single timeCard
          }),
          {
            rootStore: this.rootStore
          }
        ),
        'my_time'
      );

      this.parent.parent.refetchTimeCards();

      this.unblockHistory();

      this.handleCloseEditRedirects();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'checkmark',
        title: t('Time card saved')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.savingTimeCards = false;
    }
  }

  @action.bound async confirmDeleteTimeCard() {
    this.saving = true;

    try {
      await this.timeCardToEdit.destroy({
        url: `${this.rootStore.urlMicroService(
          'performanceTracking'
        )}/companies/${this.company.uuid}/timecards/${
          this.timeCardToEdit.uuid
        }`,
        wait: true
      });

      sendTimeCardEvent(TIMECARD_DELETED, this.timeCardToEdit, 'my_time');

      await this.hideActiveModal();

      this.parent.parent.refetchTimeCards();

      this.closeTimeSheetsEdit();

      this.notifications.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Time card deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound handleCloseEditRedirects() {
    history.push(`/my-time/entries`);
  }
}

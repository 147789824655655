import once from 'lodash.once';
import pickBy from 'lodash.pickby';
import identity from 'lodash.identity';
import { observable, action, computed, runInAction, reaction } from 'mobx';

import history from 'utils/history';
import debounce from 'lodash.debounce';

import { BASE_DEBOUNCE } from 'fixtures/constants';

import TeamWorkersAddUI from './TeamWorkersAddUI';
import TeamWorkersActionsUI from './TeamWorkersActionsUI';
import TeamWorkersImportUI from './TeamWorkersImportUI';
import TeamWorkerProfileUI from './TeamWorkerProfileUI';

import UIStore from './UIStore';

import {
  WorkerForm,
  workerFormOptions,
  workerFormFields,
  workerFormRules,
  workerFormFieldOptions,
  workerFormLabels,
  workerFormPlugins
} from 'forms/worker';

import {
  MembersFiltersForm,
  membersFiltersFormRules,
  membersFiltersFormFields,
  membersFiltersFormOptions,
  membersFiltersFormPlugins
} from 'forms/team/membersFilters';

export default class TeamWorkersUI extends UIStore {
  @observable workerForm;
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable fetchingEntryForEdit;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable filterForm;

  constructor(options) {
    super(options);

    this.loading = true;

    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.searchQuery = '';
    this.pageSize = 20;
    this.page = 1;

    this.filterForm = null;

    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);

    this.classificationFilters = observable([]);
    this.groupFilters = observable([]);
    this.fetchWorkersDebounced = debounce(this.fetchWorkers, BASE_DEBOUNCE);

    this.teamWorkersAddUI = new TeamWorkersAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkersActionsUI = new TeamWorkersActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkersImportUI = new TeamWorkersImportUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.teamWorkerProfileUI = new TeamWorkerProfileUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchClassificationsOnce = once(
      this.classificationsUI.fetchClassifications
    );
  }

  @action.bound setup() {
    this.fetchClassificationsOnce();
    this.setupForm();
    this.fetchWorkers();
    this.companyProductionUI.fetchSignOffProcess();
    this.setupReactions();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions(listRef) {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchWorkersDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @action.bound setupForm() {
    this.workerForm = new WorkerForm(
      {
        fields: workerFormFields,
        rules: workerFormRules,
        labels: workerFormLabels,
        options: workerFormFieldOptions
      },
      {
        options: workerFormOptions,
        plugins: workerFormPlugins,
        rootStore: this.rootStore
      }
    );
  }

  @action.bound
  async fetchWorkers() {
    this.members.cancelRequest();
    this.workers.cancelRequest();
    this.workers.clear();

    await this.workers.fetch({
      params: pickBy(this.params, identity)
    });

    this.loading = false;
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @computed get additionalSortFields() {
    if (this.sortField === 'updatedTimestamp') {
      return 'createdTimestamp,firstName,lastName';
    }

    if (this.sortField === 'lastName') {
      return 'firstName';
    } else if (this.sortField === 'firstName') {
      return `lastName`;
    }

    return 'firstName,lastName';
  }

  @computed get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      sortField: `${this.sortField},${this.additionalSortFields}`,
      sortDirection: this.sortDirection,
      query: this.searchQuery,
      companyUuids: this.company?.uuid,
      withProjectMembershipLookup: 'false',
      role: 'ROLE_WORKER',
      status: this.statusFilters.map(option => option.id).join(','),
      classificationUuids: this.classificationFilters
        .map(option => option.uuid)
        .join(','),
      groupUuids: this.groupFilters.map(option => option.uuid).join(',')
    };
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @action sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchWorkersDebounced();
  }

  @computed
  get totalPages() {
    return Math.ceil(this.workers.totalElements / this.pageSize);
  }

  @action.bound clearUIState() {
    this.teamWorkersActionsUI.clearUIState();
    this.clearValidationDetails();
    this.searchQuery = '';
    this.workers.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'firstName';
    this.sortDirection = 'asc';
    this.filterForm = null;
    this.statusFilters = observable([
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' }
    ]);

    this.classificationFilters.clear();
    this.groupFilters.clear();
  }

  @computed get showEmptyState() {
    return (
      !this.loading &&
      !this.searchQuery &&
      this.hasDefaultFilters &&
      !this.hasWorkers
    );
  }

  @computed get showEmptySearchState() {
    return (
      !this.loading &&
      (this.searchQuery || !this.hasDefaultFilters) &&
      !this.hasWorkers
    );
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @computed get showTable() {
    return !this.showEmptyState && !this.showEmptySearchState;
  }

  @action.bound showFilters() {
    this.showModal('MembersFiltersDialog');
    this.setupFiltering();
  }

  @action.bound async hideFilters() {
    await this.hideActiveModal();
    this.tearDownFiltering();
  }

  @action.bound clearFilters() {
    this.statusFilters.clear();
    this.classificationFilters.clear();
    this.groupFilters.clear();
    this.hideFilters();
  }

  @action.bound async applyFilters(event) {
    event.preventDefault();

    const values = this.filterForm.values();

    this.statusFilters.replace(values.statusFilters);
    this.classificationFilters.replace(values.classificationFilters);
    this.groupFilters.replace(values.groupFilters);

    this.hideFilters();
  }

  @computed get hasDefaultFilters() {
    return (
      this.statusFilters.map(option => option.id).join(',') === 'ACTIVE,INVITED'
    );
  }

  @computed get appliedFiltersCount() {
    let count = 0;

    if (this.statusFilters.length > 0) {
      count++;
    }

    if (this.classificationFilters.length > 0) {
      count++;
    }

    if (this.groupFilters.length > 0) {
      count++;
    }

    return count;
  }

  @computed get hasAppliedFilters() {
    return this.appliedFiltersCount > 0;
  }

  @action.bound setupFiltering() {
    this.classificationSelectorUI.setup();
    this.groupSelectorUI.setup();

    this.filterForm = new MembersFiltersForm(
      {
        fields: membersFiltersFormFields,
        rules: membersFiltersFormRules,
        values: {
          statusFilters: this.statusFilters.slice(),
          classificationFilters: this.classificationFilters.slice(),
          groupFilters: this.groupFilters.slice()
        }
      },
      {
        options: membersFiltersFormOptions,
        plugins: membersFiltersFormPlugins
      }
    );
  }

  @action.bound tearDownFiltering() {
    this.classificationSelectorUI.tearDown();
    this.groupSelectorUI.tearDown();
    this.filterForm = null;
  }

  @computed get statusFilterOptions() {
    return [
      { id: 'ACTIVE', title: 'Active' },
      { id: 'INVITED', title: 'Invited' },
      { id: 'INACTIVE', title: 'Inactive' },
      { id: 'DELETED', title: 'Deleted' }
    ];
  }

  @action.bound async showImportWorkersModal() {
    await this.authorization.checkFeatureAccess('EditWorkers');

    history.push('/workers/import');
  }

  @action.bound async showAddWorkersModal() {
    await this.authorization.checkFeatureAccess('EditWorkers');

    history.push('/workers/add');
  }

  @computed get companyProductionUI() {
    return this.rootStore.companyUI.companyProductionUI;
  }

  @computed get showMissingWorkerContactInformationAlert() {
    return (
      this.companyProductionUI.settingsSignOff?.enabled &&
      this.company.hasWorkersWithoutContactDetails
    );
  }

  @computed get classificationOptions() {
    return this.classificationsUI.selectableClassifications.map(
      classification => {
        return {
          uuid: classification.uuid,
          name: classification.name
        };
      }
    );
  }
}

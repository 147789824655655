import debounce from 'lodash.debounce';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import UIStore from '../../UIStore';

import Groups from 'stores/collections/groups/Groups';
import GroupAddUI from './GroupAddUI';
import GroupEditUI from './GroupEditUI';
import GroupsActionsUI from './GroupsActionsUI';

import history from 'utils/history';
import { BASE_DEBOUNCE } from 'fixtures/constants';
import { t } from 'utils/translate';
import alertErrorHandler from 'utils/alertErrorHandler';

import { callTrack } from 'utils/segmentIntegration';
import { GROUP_DELETED } from 'utils/segmentAnalytics/eventSpec';

export default class GroupsUI extends UIStore {
  @observable sortField;
  @observable sortDirection;
  @observable searchQuery;
  @observable pageSize;
  @observable page;
  @observable loading;
  @observable selectedGroup;

  constructor(options) {
    super(options);

    this.loading = true;

    this.selectedGroup = null;

    this.sortField = 'groupClassName';
    this.sortDirection = 'asc';
    this.page = 1;
    this.searchQuery = '';
    this.pageSize = 20;

    // Groups collection
    this.groups = new Groups(null, {
      parent: this,
      rootStore: this.rootStore
    });

    this.groupAddUI = new GroupAddUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.groupEditUI = new GroupEditUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.groupsActionsUI = new GroupsActionsUI({
      parent: this,
      rootStore: this.rootStore
    });

    this.fetchGroupsDebounced = debounce(this.fetchGroups, BASE_DEBOUNCE);
  }

  @action.bound setup() {
    window.scrollTo(0, 0);
    this.setupReactions();
    this.fetchGroups();
  }

  @action.bound tearDown() {
    this.tearDownReactions();
    this.clearUIState();
  }

  setupReactions() {
    this.reactToParams = reaction(
      () => this.params,
      params => {
        runInAction(() => {
          this.loading = true;
          this.fetchGroupsDebounced();
        });
      }
    );
  }

  tearDownReactions() {
    this.reactToParams && this.reactToParams();
  }

  @computed
  get params() {
    return {
      limit: this.pageSize,
      offset: (this.page - 1) * this.pageSize,
      query: this.searchQuery,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      status: ['ACTIVE']
    };
  }

  @action.bound async fetchGroups() {
    this.groups.cancelRequest();
    this.groups.reset();

    try {
      await this.groups.fetch({
        params: this.params
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.loading = false;
    }
  }

  @computed get hasGroups() {
    return this.groups.hasModels;
  }

  @action.bound
  sortByColumn(sortField) {
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = sortField;
      this.sortDirection = 'asc';
    }

    this.page = 1;
  }

  @computed
  get totalPages() {
    return Math.ceil(this.groups.totalElements / this.pageSize);
  }

  @action.bound setSearchQuery(value) {
    this.searchQuery = value;
    this.page = 1;
  }

  @action.bound clearSearchQuery() {
    this.searchQuery = '';
    this.page = 1;
  }

  @action.bound
  setPage(event, page) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  @action.bound clearPage() {
    this.page = 1;
  }

  @action.bound clearUIState() {
    this.groupsActionsUI.clearUIState();
    this.searchQuery = '';
    this.groups.clear();
    this.page = 1;
    this.loading = true;
    this.saving = false;
    this.sortField = 'groupClassName';
    this.sortDirection = 'asc';
    this.selectedGroup = null;
  }

  @computed get showEmptyState() {
    return !this.loading && !this.searchQuery && !this.hasGroups;
  }

  @computed get showEmptySearchState() {
    return !this.loading && this.searchQuery && !this.hasGroups;
  }

  @computed get showUI() {
    return !this.showEmptyState;
  }

  @action.bound async addGroup() {
    await this.authorization.checkFeatureAccess('CUDGroups');

    history.push({
      pathname: `/company/groups/add`,
      search: this.baseQueryParams
    });
  }

  @action.bound async editGroup(group) {
    await this.authorization.checkFeatureAccess('CUDGroups');

    history.push({
      pathname: `/company/groups/${group.uuid}`,
      search: this.baseQueryParams
    });
  }

  @action.bound async deleteGroup(group) {
    await this.authorization.checkFeatureAccess('CUDGroups');

    this.selectedGroup = group;
    this.showModal('DeleteModal');
  }

  @action.bound async cancelDeleteGroup() {
    await this.hideActiveModal();

    this.selectedGroup = null;
  }

  @action.bound async confirmDeleteGroup() {
    this.saving = true;

    try {
      await this.selectedGroup.destroy({ wait: true });

      this.refetchGroups();

      await this.hideActiveModal();

      callTrack(GROUP_DELETED, {
        group_class: this.selectedGroup.groupClass.name,
        group_name: this.selectedGroup.groupName
      });

      this.selectedGroup = null;

      this.rootStore.notificationsUI.pushNotification({
        snackbar: 'warning',
        icon: 'notification',
        title: t('Group deleted')
      });
    } catch (error) {
      alertErrorHandler(error, this.setValidationDetails);
    } finally {
      this.saving = false;
    }
  }

  @action.bound sortByLastCreated() {
    this.sortField = 'createdTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchGroupsDebounced();
  }

  @action.bound sortByLastUpdated() {
    this.sortField = 'updatedTimestamp';
    this.sortDirection = 'desc';
    this.page = 1;
    this.loading = true;
    this.fetchGroupsDebounced();
  }

  @action.bound refetchGroups() {
    this.loading = true;
    if (!this.hasGroups) {
      this.setPage(null, 1);
      this.fetchGroups();
    } else {
      this.fetchGroups();
    }
  }

  getGroupActions = group => {
    return [
      {
        title: t('Edit'),
        onClick: () => {
          this.editGroup(group);
        }
      },
      {
        title: t('Delete'),
        onClick: () => {
          this.deleteGroup(group);
        }
      }
    ];
  };
}
